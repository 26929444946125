import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedIn = new BehaviorSubject<boolean>(false);

  // Hardcoded credentials
  private readonly correctUsername = 'kraken';
  private readonly correctPassword = 'Des1gn$yst3m';

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(private cookieService: CookieService) {}

  public checkLoginOnStartup(): boolean {
    const getLoginCookie = this.cookieService.get('isLoggedIn') === 'true';
    if(getLoginCookie) {
      this.loggedIn.next(true);
      return true;
    } else {
      this.loggedIn.next(false);
      return false;
    }
  }
  
  login(username: string, password: string): boolean {
    if (username === this.correctUsername && password === this.correctPassword) {
      this.loggedIn.next(true);
      this.cookieService.set('isLoggedIn', 'true', 1); // Expires in 1 day
      return true;
    } else {
      this.loggedIn.next(false);
      return false;
    }
  }

  logout(): void {
    this.loggedIn.next(false);
    this.cookieService.delete('isLoggedIn');
  }
}
