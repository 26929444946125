import { NgModule } from '@angular/core';
import { KrakenIconButtonComponent } from './kraken-icon-button.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [KrakenIconButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [KrakenIconButtonComponent]
})
export class KrakenIconButtonModule { }
