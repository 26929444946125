<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text">The <span class="kn-bold"> Spreadsheet </span> component organizes, analyzes, and manipulates data in a pre-defined tabular format.</div>
        </section>
      
        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <section>
            <div class="text">A spreadsheet includes two main sections: the spreadsheet container and the outer container. The spreadsheet container consists of the Syncfusion-based spreadsheet, while the outer container includes all spreadsheet-related functionality (including an error handling legend and row counter).</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_01.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Outer container</div>      

                <div class="text"><span class="kn-bold">A. Row counter: </span>A numerical indicator that displays the number of rows in a spreadsheet for the current dataset.</div>
                <div class="text"><span class="kn-bold">B. Error handling legend: </span>A guide to the specific symbols, colors, and annotations used within the grid spreadsheet to convey additional information. This includes highlighting certain cells based on error state conditions.</div>
                <div class="text"><span class="kn-bold">C. Container </span></div>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_02.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Spreadsheet container</div>      

                <div class="text"><span class="kn-bold">A. Column header: </span>A label assigned to a column in a spreadsheet, which describes the category or type of data contained in a column.</div>
                <div class="text"><span class="kn-bold">B. Column divider </span></div>
                <div class="text"><span class="kn-bold">C. Data row: </span>A horizontal arrangement of related data elements, which represents a single record or entry and contains various attributes or fields associated with that specific data point.</div>
                <div class="text"><span class="kn-bold">D. Row cell: </span>An individual intersection point within a row and column grid that contains a specific piece of data. Each cell holds a unique value or content, which contributes to the overall representation of information in the dataset.</div>
                <div class="text"><span class="kn-bold">E. Row divider </span></div>
                <div class="text"><span class="kn-bold">F. Sheets bar: </span>The sheets bar provides a convenient way to manage the sheets.</div>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">Usage</div>
        </section>

        <section>
            <div class="kn-subtitle-16">When to use a spreadsheet</div>      

            <ul class="bullets">
                <li>Use a spreadsheet to import and organize large amounts of data efficiently. Spreadsheets provide a structured way to arrange information in rows and columns.</li>
                <li>Spreadsheets support real-time data validation and formatting.</li>
            </ul>
        </section>


        <section>
            <div class="kn-headline-24-med">Content</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_03.png">

        <section>
            <div class="kn-subtitle-16">Column header</div>
            <div class="text"> Column header labels define the type of content to be displayed in the respective column. Ensure headers are clear, concise, and descriptive.</div>

            <ul class="bullets">
                <li>Ensure column header titles are short and clear. Use one or two words that describe the data in that column.</li>
                <li>Ensure header label text is always uppercase.</li>
                <li>Don’t leave a column header cell blank.</li>
                <li>Text overflow is not supported.</li>
            </ul>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_04.png">

        <section>
            <div class="kn-subtitle-16">Cells</div>
            <div class="text"> The contents of the spreadsheet cell may include any combination of static display text comprised of alpha numeric characters and symbols.</div>

            <section>
                <div class="text"> <span class="kn-bold">Alignment</span></div>
                <div class="text"> Align text and numerical data appropriately within cells (left or right), ensuring a neat and organized appearance.</div>
            </section>
            
            <div class="text"><span class="kn-bold">1. Text formatting: </span>Cell values with text are left-aligned by default.</div>
            <div class="text"><span class="kn-bold">2. Numerical formatting: </span>Apply a right-aligned format for suitable number formats. This includes date-related information, file size, and currency.</div>
        
            <section>
                <div class="text"> <span class="kn-bold">Truncation</span></div>
                <div class="text"> In general, text content displayed within a spreadsheet should display in its entirety and not be truncated.</div>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_05.png">

        <section>
            <div class="kn-subtitle-16">Sheets</div>
            <div class="text"> A sheet refers to an individual tab or page within a workbook. A workbook is the entire file or document, which can contain one or more sheets. Sheets help organize and segregate different sets of data or related information within the same file.</div>

            <section>
                <div class="text"> In cases when multiple sheets are supported, the sheets bar offers a convenient way to manage the sheets. It is always available at the bottom of the spreadsheet. The sheets bar allows the user to perform actions such as changing the active sheet and modifying a sheet.</div>
            </section>
        
            <div class="text"><span class="kn-bold">1. Single sheet </span></div>
            <div class="text"><span class="kn-bold">2. Multiple sheets </span></div>
        </section>

        <section>        
            <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_06.png">

            <section>
                <div class="kn-subtitle-16">Row counter</div>
                <div class="text">Row counters provide users with the current number of rows within the spreadsheet. It starts from 0 and increments by 1 for each added row.</div>
            </section>
        
            <div class="text"><span class="kn-bold">1. Zero state</span></div>
            <div class="text"><span class="kn-bold">2. With row count </span></div>
        </section>

        <section>
            <div class="kn-subtitle-16">Density</div>
            <div class="text">Spreadsheet rows are displayed with a standard unconfigurable height of <span class="kn-bold">20px</span>.</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_07.png">
            </section>

            <div class="kn-subtitle-16">Size</div>
            <div class="text">Spreadsheets are displayed at fixed outer container size of <span class="kn-bold">1000px x 448px</span>, which remains the same regardless of the number of displayed sheets.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Sorting</div>
            <div class="text">Sorting arranges the data in a specific order for a group of selected cells. In an active spreadsheet , select a range of cells to sort by cell value. Right-click highlighted cells, select the sort item in the content menu, and choose the ascending/descending item.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_09.png">

            <section>
                <div class="kn-subtitle-16">Scrolling</div>      

                <div class="text"><span class="kn-bold">A. Vertical scrolling: </span>The vertical scroll bar moves the viewable data grid area up or down to display additional content.</div>
                <div class="text"><span class="kn-bold">B. Horizontal scrolling: </span>The horizontal scroll bar moves the viewable data grid left or right when a grid that contains more columns than can be displayed.</div>
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Elevation</div>
            <div class="text">Spreadsheets don’t express elevation since they do not move in front of or behind other surfaces.</div>
        </section>
        
        
        <section>
            <div class="kn-headline-24-med">Cell formatting</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_10.png">
            </section>

            <div class="kn-subtitle-16">Editing a cell</div>
            <div class="text">To focus on a cell for text entry/editing, click on the desired cell to highlight it, and immediately start typing. A focused cell is highlighted with a light blue stroke.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Saving a cell</div>
            <div class="text">To save changes to a cell after editing the content, press the Enter or Tab key to confirm the modifications and automatically save changes. Alternatively, click and focus on another cell.</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_08.png">
            </section>

            <div class="kn-subtitle-16">Copying a cell</div>
            <div class="text">To copy a cell, select the target cell, right-click and choose ‘Copy’ from the content menu, then navigate to the destination cell, right-click and choose ‘Paste’, ensuring seamless and accurate data replication. A focused copied cell is displayed with an animated light blue dashed stroke.</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_11.png">
            </section>

            <div class="kn-subtitle-16">Data validation</div>
            <div class="text">Data validation restricts users from entering invalid data. Invalid data is displayed with a highlighted data cell and cell text displayed with a heavier weight. The cell background color for each error validation is tied to the spreadsheet legend.</div>
        
            <section>
                <div class="text">To clear the highlighted invalid data, click on the cell containing the invalid data and either enter or paste valid data into the cell. To re-run the data validation, press the Enter or Tab key to confirm the modifications or click on another cell.</div>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">Legend</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_12.png">

        <section>
            <div class="text">The spreadsheet legend helps users to quickly identify and resolve issues based on cell data validation rules. The legend is displayed horizontally and begins with the validation rule that is highest in importance on the far left.</div>
               
            <section>
                <div class="text"><span class="kn-bold">A. Color </span></div>
                <div class="text"><span class="kn-bold">B. Legend label </span></div>
                <div class="text"><span class="kn-bold">C. Error count </span></div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">Note: </span>Ensure the legend is persistently displayed so that all possible cell data errors and real-time validation details are always visible.</div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/spreadsheet/KrakenDSM_Components_Spreadsheet_13.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Categorical colors</div>
            <div class="text">The Kraken DS categorial palette consists of five colors that represent a different validation rule for users to correct in their data. Cells with validation errors are highlighted using the following colors in order of validation error importance:</div>
        </section>
        
        <section>
            <ul class="bullets">
                <li>Red</li>
                <li>Orange</li>
                <li>Yellow</li>
                <li>Indigo</li>
                <li>Purple</li>
            </ul>
        </section>

        <section>
            <div class="text">Not all validation error colors might appear in a spreadsheet. For example, when a spreadsheet includes only two validation rules, just the red and orange colors are used to highlight validation errors.</div>
        </section>

        <section>
            <div class="text">When a single cell includes multiple validation errors, that cell is highlighted with the validation error color of the highest importance. Once the validation error of highest importance is resolved, the cell color changes to the color of the next highest validation error.</div>
        </section>

        <section>
            <div class="text">For example, if a cell includes validation errors that correspond to the orange and indigo colors, the cell is first highlighted in orange since it represents the highest validation error. Once the highest validation error is resolved in that cell, then the cell highlight changes to the color of the next highest validation error (indigo).</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Error count</div>
            <div class="text">The error count provides users with the current number of cells within the dataset that are affected by the error validation. Once a user corrects and validates the error, the error count is adjusted.</div>
        
            <section>
                <div class="text">Since the error validation legend is persistent, the error count defaults to (0) when no data cells contain error validations or if all errors are resolved.</div>
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Truncation</div>
            <div class="text">Do not truncate legend labels.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Poor naming conventions</div>

            <div class="text">Using unclear and inconsistent cell naming conventions can make it challenging to understand and reference specific cells. Establish a clear naming convention for cells.</div>
        </section>
        
        <section>
            <div class="kn-subtitle-16">Not using data validation</div>

            <div class="text">Errors and inaccuracies can occur when data is not validated. Implement data validation rules to ensure that entered data meets specific criteria.</div>
        </section>
    </div>
</div>
