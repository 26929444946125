import { NgModule } from '@angular/core';
import { KrakenMenuButtonComponent } from './kraken-menu-button.component';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [KrakenMenuButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [KrakenMenuButtonComponent]
})
export class KrakenMenuButtonModule { }
