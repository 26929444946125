<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">UI Design Kit</div>
        
        <section>
            <div class="kn-headline-24-med">Sketch & plugins</div>
        </section>

        <section>
            <div class="text">We use Sketch to create designs to illustrate the visual design and interaction behaviors for our software.  Sketch software is a native Mac OS application that is integrated with Sketch Cloud for sharing, collaboration, and backup.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/getting started/for designers/ui design kit/KrakenDSM_GettingStarted_UIKit_01.png">
        </section>

        <section>
            <div class="text">Website: <a class="kn-link" href="https://www.sketch.com/home/" target="_blank"> https://www.sketch.com/home/ </a> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Plugins</div>
        </section>

        <section>
            <div class="text">Sketch plugins may be installed to add extra functionality or customize existing Sketch features.  The following plugins are used by some members of the Lighthouse UX Team:</div>
        </section>

        <section>
            <ul class="bullets">
                <li><span class="kn-bold">Shared Style Finder</span> - Find instances of a shared layer or text style.</li>
                <li><span class="kn-bold">Sort Me</span> - Sort artboards and layers by name.</li>
                <li><span class="kn-bold">Sketch Measure</span> - Easily creates specs by automatically generating design details and measurements.</li>
                <li><span class="kn-bold">Stark</span> - Stark helps you design and build products that are accessible, ethical, and inclusive.</li>
                <li><span class="kn-bold">Symbol Instance Locator</span> - Locate all instances of a selected symbol or instance.</li>
                <li><span class="kn-bold">Symbol Organizer</span> - Organize your symbols page, and layer list, alphabetically and into groupings determined by your symbol names.</li>
            </ul>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">Note:</span>  Not all plugins are created equally.  Some individual plugins may cause significant performance issues.  Keeping Sketch lean is important. Using too many plugins can also slow down Sketch.</div>
        </section>


        <section>
            <div class="kn-subtitle-16">Learning sketch</div>
        </section>  

        <section>
            <div class="text">Sketch's own documentation provides a lot of good information to help you get up to speed or refresh your skills.  They provide both overviews and walkthroughs with embedded videos.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Sketch - The interface</span></div>
            <div class="text"> <a class="kn-link" href="https://www.sketch.com/docs/designing/the-interface/" target="_blank"> https://www.sketch.com/docs/designing/the-interface/ </a></div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Sketch - Creating a symbol</span></div>
            <div class="text"> <a class="kn-link" href="https://www.sketch.com/docs/designing/symbols/creating-a-symbol/" target="_blank"> https://www.sketch.com/docs/designing/symbols/creating-a-symbol/ </a></div>
        </section>

        <section>
            <div class="text">In addition to Sketch's documentation, several training courses are available on LinkedIn Learning. If you find one you like, please let the UX team know so we can add it here.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Subscribing to UI kit</div>
        </section>
        
        <section>
            <div class="text">The components in the <span class="kn-bold">Kraken DS</span> are available in a Sketch symbol library called <span class="kn-bold">Lighthouse Kraken Components</span>.</div>
        </section>


        <section>
            <div class="kn-subtitle-16">Lighthouse UX team members</div>
        </section>  

        <section>
            <div class="text">If you are a member of the Lighthouse UX team, this library is automatically available as one of the Lighthouse UX Libraries in the Libraries tab in your Sketch Preferences.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/getting started/for designers/ui design kit/KrakenDSM_GettingStarted_UIKit_02.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Outside the team</div>
        </section>  

        <section>
            <div class="text">Not on the Lighthouse UX Team?  No problem. You can request to subscribe to the <span class="kn-bold">Lighthouse Kraken Components</span> symbol library, and we'll send you an invitation.  After adding the Kraken symbol library to your Sketch Cloud account, you will be notified whenever we make an update.</div>
        </section>

        <section>
            <div class="text">Here's how to subscribe to the <span class="kn-bold">Lighthouse Kraken Components</span> symbol library:</div>
        </section>

        <section>
            <div class="text">1. Contact the design system crew at <a class="kn-link" >KrakenDS@lighthouseglobal.com</a> and request an invitation to the Lighthouse Kraken Components symbol library.</div>
            <div class="text">2. You'll receive an email invitation from Sketch Cloud inviting you to check out the Lighthouse Kraken Components on Sketch</div>
            <div class="text">3. Click the <span class="kn-bold">Accept Invitation</span> button in the email</div>
            <div class="text">4. You will be directed to log in to your Sketch Cloud account</div>
            <div class="text">5. Follow the on-screen instructions to add the library to your Sketch Cloud</div>
        </section>
    </div>
</div>