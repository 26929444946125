import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor() { }

accordionNavigation(elm, group, sub) {
    const accordion = elm;
    const groupInput = accordion.querySelectorAll('#group-' + group)[0];
    const subGroupInput = accordion.querySelectorAll('#sub-group-' + sub)[0];

    groupInput.checked = true;
    if(subGroupInput) {
      subGroupInput.checked = true;
    }
    
  }
}