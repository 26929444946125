<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">An <span class="kn-bold"> Accordion </span> menu is a vertically stacked list of headers that when clicked by the user reveal (expand) or hide (collapse) content associated with them. It delivers large amounts of content in a small space through progressive disclosure. The header title gives the user a high-level overview of the content allowing the user to decide which sections to read.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_01.png">
        
        <section>
            <div class="kn-subtitle-16">Filled or line rule</div>
            <div class="text">There are two configuration options available to visually style the accordion.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Filled: </span> This presents each header row filled with a color value for the full width of the content.</div>
            <div class="text"><span class="kn-bold">2.	Line rule: </span> This presentation is subtle and employs a simple line rule positioned below each accordion header row for the full width of the content.</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_02.png">

        <section>
            <div class="kn-subtitle-16">Controller position</div>
            <div class="text">The controller triggers the open (expand) and close (collapse) states of the accordion header row. When the accordion is in the closed state, the caret points downward. When the accordion is in the open state, the caret points upward. Horizontal positioning of the caret may be on the outer left or right of the accordion header row.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Accordion with caret on left </span> </div>
            <div class="text"><span class="kn-bold">2.	Accordion with caret on right </span> </div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_03.png">

        <section>
            <div class="kn-subtitle-16">Single-level or Multi-Level</div>
            <div class="text">The default accordion is the single-level. However, with the use of the multi-level accordion, a depth of three levels: primary, secondary, and tertiary is supported. </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Single-level </span> </div>
            <div class="text"><span class="kn-bold">2.	Multi-level </span> </div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_04.png">

        <section>
            <div class="kn-subtitle-16">Icons</div>
            <div class="text">Icons may be combined with accordion title labels to further reinforce the explanation of the content or metadata to be exposed when the accordion is opened or expanded. </div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_05.png">

        <section>
            <div class="kn-subtitle-16">Subtitle</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Subtitle right-aligned </span> </div>
            <div class="text"><span class="kn-bold">2.	Subtitle stacked </span> </div>
        </section>


        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_06.png">

        <section>
            <div class="kn-subtitle-16">Subtitle</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Enabled: </span> The default style before the user interacts with the accordion.</div>
            <div class="text"><span class="kn-bold">2.	Hover: </span> The header background change color to let the user know the accordion is interactive.</div>
            <div class="text"><span class="kn-bold">3.	Selected: </span> An accordion in a selected state displays the accordion expanded with the controller icon button pointing up.</div>
            <div class="text"><span class="kn-bold">4.	Disabled: </span> Communicates that an accordion exists, but isn’t available in that scenario.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_07.png">

        <section>
            <div class="text"><span class="kn-bold">COLLAPSED </span> </div>
        <ul class="no-bullets">
            <li><span class="kn-bold">A.	Header label</span></li>
            <li><span class="kn-bold">B.	Header row background</span></li>
            <li><span class="kn-bold">C.	Controller icon button:</span> Indicates which state the accordion is in. It is the control for opening and closing the panel and is displayed as a caret icon.</li>
            <li><span class="kn-bold">D.	Divider line:</span> horizontal rule between expand or collapse function and the accordion.</li>
            <li><span class="kn-bold">E.	Subtitle</span></li>
            <li><span class="kn-bold">F.	Icon</span></li>
        </ul>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_08.png">

        <section>
            <div class="text"><span class="kn-bold">EXPANDED </span> </div>
        <ul class="no-bullets">
            <li><span class="kn-bold">A.	Panel content</span></li>
            <li><span class="kn-bold">B.	Panel background</span></li>
            <li><span class="kn-bold">C.	Divider line</span></li>
        </ul>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_09.png">

        <section>
            <div class="text"><span class="kn-bold">SUB-NAVIGATION </span> </div>
            <div class="text">The accordion may be used to nest navigation content, allowing the user to expand and contract sub-navigation options associated with each primary option. </div>
        <ul class="no-bullets">
            <li><span class="kn-bold">A.	Primary</span></li>
            <li><span class="kn-bold">B.	Sub-navigation – Secondary </span> </li>
            <li><span class="kn-bold">C.	Sub-navigation – Tertiary</span></li>
        </ul>
        </section>

  
        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Content</div>
            <div class="text">Accordions should only be used when there is enough content to condense – they shouldn’t be used as an organizational tool, or for navigation purposes.</div>
        </section>

        <section>
            <div class="kn-bold">Header label</div>
            <div class="text">The header label should be as brief as possible while still being clear and descriptive. Titles should be displayed in sentence case and not have the first letter of every word capitalized.</div>
        </section>

        <section>
            <div class="kn-bold">Label overflow</div>
            <div class="text">When the label is too long for the available horizontal space, it wraps to form another line.</div>
        </section>

        <section>
            <div class="kn-bold">Body copy</div>
            <div class="text">Content inside of a section may be split into paragraphs.</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_10.png">

        <section>
            <div class="kn-subtitle-16">Placement</div>
            <div class="text">Accordions can be placed with main page content or inside a container, such as a panel or card.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_11.png">

        <section>
            <div class="kn-subtitle-16">Scrolling content</div>
            <div class="text">When the accordion content is larger than the viewport, the whole accordion should vertically scroll. Content should not scroll inside an individual panel. Content should never scroll horizontally in an accordion.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_12.png">


        <section>
            <div class="kn-subtitle-16">Size</div>
            <div class="text">The display of the accordion is suggested not to exceed 576px and no less than 245px.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Default button minimum width: 132px, Default button maximum width: 244px (25 characters) </span> </div>
            <div class="text"><span class="kn-bold">2.	Small button minimum width: 108px, Small button maximum width: 206px (25 characters) </span> </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_13.png">

        <section>
            <div class="kn-subtitle-16">Expand/collapse trigger</div>
            <div class="text">Allow users to click anywhere in the header row background to open the panel, rather than only the controller icon button.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_14.png">

        <section>
            <div class="kn-bold">States</div>
            <div class="text">The accordion has two states: </div>
        </section>

        <section>
            <div class="kn-bold">Collapsed with the panel closed</div>
            <div class="kn-bold">Expanded with the panel open</div>
        </section>

        <section>
            <div class="text">Accordions begin by default in the collapsed state, with all content panels closed. The controller caret icon points down when the accordion panel is collapsed and points up when the accordion panel is expanded.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_15.png">

        <section>
            <div class="kn-subtitle-16">Open one or more at a time</div>
            <div class="text">The accordion component may be configured to constrain the user experience allowing only one section to be expanded or opened at any given time. When a second accordion control is engaged, the previous accordion section automatically contracts as the new one expands.</div>
        </section>

        <section>
            <div class="text">If the configuration is set to open multiple accordion sections, the user can open or expand multiple accordion rows simultaneously. Each section will then close when the user chooses to re-engage the accordion control, causing that single section to close or contract while the remaining open sections persist.</div>
        </section>

        <section>
            <div class="kn-bold">1.	Accordion with single section opened</div>
            <div class="kn-bold">2.	Accordion with multiple sections opened</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_16.png">

        <section>
            <div class="kn-subtitle-16">Pointing controller to the side</div>
            <div class="text">The caret icon for the accordion controller should point in the direction of the action to take place when the user engages it.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_17.png">

        <section>
            <div class="kn-subtitle-16">Mixing configurations</div>
            <div class="text">Once a configuration for accordions has been selected, use this configuration throughout the application user experience.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_18.png">

        <section>
            <div class="kn-subtitle-16">Unproductive use of icons</div>
            <div class="text">Avoid using icons in the accordion title for merely aesthetic purposes or that do not intuitively reinforce the explanation of the content or metadata to be revealed by opening the accordion section.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/accordion/KrakenDSM_Components_Accordion_19.png">


        <section>
            <div class="kn-subtitle-16">Don’t use for important information</div>
            <div class="text">Critical information should not be hidden from users. If the content is critical for the page’s function, it should always be visible and shouldn’t be hidden by an accordion.</div>
        </section>    
    </div>
</div>