import { Component } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';

@Component({
  selector: 'date-range-picker-accessibility',
  templateUrl: './date-range-picker-accessibility.component.html',
  styleUrls: ['./date-range-picker-accessibility.component.sass']
})
export class DateRangePickerAccessibilityComponent {
  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;

  constructor(private templateService: TemplateUpdateService) { }
}
