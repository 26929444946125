<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">A <span class="kn-bold">Progress Indicator </span> is a visual representation of a system operation such as loading content or completing a process. It offers real-time feedback to users about the status of ongoing processes, the estimated time for how long a process takes, or if a request is executed using an animated progress bar. Progress indicators represent determinate progression – conveying exact and qualitative information. </div>
            </section>
       
        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/progressindicator/KrakenDSM_ProgressIndicator_01.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Default </span> </div>
            <div class="text"><span class="kn-bold">2.	Inline: </span>Reserved for use cases where it is either not necessary to display a label or with size restraints such as within the data grid. </div>
        </section>


        <section>
            <div class="kn-headline-24-med">States </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/progressindicator/KrakenDSM_ProgressIndicator_02.png">

        <section>
            <div class="text"><span>After a process completes successfully or unsuccessfully, a progress indicator fill can either remain persistent as a confirmation or validation, depending on what is most suitable for the use case. </span> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Enabled: </span>Represented by an animated primary blue fill, which conveys that the action is still in progress. </div>
            <div class="text"><span class="kn-bold">2.	Disabled: </span>A progress indicator in a disabled state shows that the operation exists but is unavailable in that circumstance. This can be used to maintain layout continuity and communicate that an action may become available later. </div>
            <div class="text"><span class="kn-bold">3.	Error: </span>The error progress state indicates that the action did not successfully complete. As soon as a process fails, the progress indicator’s fill stops at the point of failure, changes to the color red, and then remains visible with an inline error icon in association with text. </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/progressindicator/KrakenDSM_ProgressIndicator_03.png">

        <section>
            <div class="text"><span class="kn-bold">A.	Label: </span>A concise description of the process to which the progress indicator refers. </div>
            <div class="text"><span class="kn-bold">B.	Helper text: </span>Assistive or contextual information about the process taking place. </div>
            <div class="text"><span class="kn-bold">C.	Value label: </span>Progress indicators have a value label that gives detailed information about the progress (for example, percentage, fraction, ratio or numeric value showing progression). This value works alongside the label. Do not display the value if the label itself is not displayed. </div>
            <div class="text"><span class="kn-bold">D.	Fill: </span>Progress indicators are tracked and translated into a bar indicator which fills the track from 0% to 100% and never decreases in value or resets. </div>
            <div class="text"><span class="kn-bold">E.	Track: </span>The static area that the bar indicator moves on top of and acts as a fixed visual reference of the approximate total length and duration of the process. </div>
            <div class="text"><span class="kn-bold">F.	Error icon: </span>Displayed as a flat icon button, upon hover a tooltip provides helper text with greater detail specific to the error. </div>
            <div class="text"><span class="kn-bold">G.	Error text: </span>A progress indicator that reaches an error state must convey the error with an error text displayed to the left of the value label. </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>
 
        <section>
            <section>
                <div class="kn-subtitle-16">When to use</div>

                <ul class="bullets">
                    <li>For a long operation or a process that can take a considerable or unknown amount of time.</li>
                    <li>When the process can be described with quantitative information, such as a percentage.</li>
                    <li>To visually show the progression of a system operation such as downloading, uploading, loading content, or completing a process.</li>
                    <li>To convey that data is being requested, transferred, or processed.</li>
                </ul>
            </section>
        </section>

        <section>
            <section>
                <div class="kn-subtitle-16">When not to use</div>

                <ul class="bullets">
                    <li>If the process takes less than 5 seconds to load.</li>
                    <li>When the progress is determined by user actions such as steps completed.</li>
                </ul>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/progressindicator/KrakenDSM_ProgressIndicator_04.png">
            
            <section>
                <div class="kn-subtitle-16">Label</div>

                <div class="text">Progress indicators include a label that gives context about the operation being performed. </div>
            </section>

            <section>
            <ul class="bullets">
                <li>Keep the label short and concise by limiting it to a few words or a single line of text.</li>
                <li>Truncate the label text when it is too long for the available horizontal space.</li>
                <li>Use sentence case in display of the label.</li>
                <li>Ensure the label is left-aligned.</li>
                <li>Never change the label text as the progress bar is loading. It also does not need to be updated when the process ends.</li>
            </ul>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/progressindicator/KrakenDSM_ProgressIndicator_05.png">
            
            <section>
                <div class="kn-subtitle-16">Value label</div>

                <ul class="bullets">
                    <li>When indicating a loading percentage, the percentage number count up in value as the bar indicator progresses.</li>
                    <li>When a progress indicator is evaluated as a NULL value, it is represented as “- -“ instead of displaying as “0%”.</li>
                </ul>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/progressindicator/KrakenDSM_ProgressIndicator_06.png">

            <section>
                <div class="kn-subtitle-16">Width</div>

                <div class="text">The width of a progress indicator can be customized appropriately for its context. The default width is <span class="kn-bold">264px</span>. </div>
            </section>

            <section>
                <div class="text">The minimum width of a default progress indicator is <span class="kn-bold">160px</span> and <span class="kn-bold">100px </span>for inline, with a suggested maximum width of <span class="kn-bold">576px</span>. </div>
            </section>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/progressindicator/KrakenDSM_ProgressIndicator_07.png">

            <section>
                <div class="kn-subtitle-16">External conditions</div>
                
                <div class="text">External controls are a set of additional conditions for the progress indicator. Ranging from cancel, retry, pause and play flat icon buttons, these external controls should be clear and accessible for interacting with the progress indicator. </div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">Cancel operation </span> </div>

                <div class="text">Allows the user to manually abort the operation prior to its completion. When available, this option displays as a close button positioned at the right end of the progress indicator. </div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">Retry operation </span> </div>

                <div class="text">During the event of a failed process, in some cases a retry button can aid the user in retrying the task. </div>
            </section>
        
            <section>
                <div class="text"><span class="kn-bold">Pause/play operation </span> </div>

                <div class="text">Use a pause/play operation with a progress indicator to allow users to control the pace of an ongoing process, ensuring they can interact with and manage their experience effectively. When paused, the progress indicator is displayed in the disabled state. </div>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Use with short operations</div>

            <div class="text">If the calculated time for an operation to complete is less than five seconds, then do not use a progress indicator. Displaying a progress indicator for a short duration can be distracting and add little value to the user experience.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Use for data visualization</div>

            <div class="text">Do not use the progress indicator for data visualization. Use a custom component instead.</div>
        </section>
    </div>
</div>