<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">The <span class="kn-bold">Footer</span> is the bottom-most element of the application interface and is persistently displayed across all screens of the application. The footer can serve as the primary container for key application-wide tools such as:</div>
            </section>
            
        <section>
            <ul class="bullets">
                <li>Utility navigation </li>
                <li>Legal and terms of use links </li>
                <li>Copyright </li>
                <li>Access to support resources </li>
                <li>Reinforcing the branding of the application </li>
            </ul>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/footer/KrakenDSM_Components_Footer_01.png">

        <section>
            <div class="text"><span class="kn-bold">A.	Copyright text </span></div>   
            <div class="text"><span class="kn-bold">B.	Secondary navigation: </span>(optional) When displayed, the utility navigation links open a new browser window. </div>    
        </section>
        
        <img class="kn-responsive-img" src="assets/usage guidelines/footer/KrakenDSM_Components_Footer_02.png">

        <section>
            <div class="kn-subtitle-16">Display use cases </div>
            <div class="text"><span class="kn-bold">1.	Base footer </span></div>   
            <div class="text"><span class="kn-bold">2.	Base footer and secondary navigation </span></div>    
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/footer/KrakenDSM_Components_Footer_03.png">

        <section>
            <div class="kn-subtitle-16">Base footer </div>

            <div class="text">The base footer an be used to visually indicate the bottom (end) of a scrolling application screen along with displaying pertinent copyright information, which is positioned on the left-hand side. </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/footer/KrakenDSM_Components_Footer_04.png">

        <section>
            <div class="kn-subtitle-16">Secondary navigation </div>

            <div class="text">In addition to copyright, the footer can also display persistent utility navigations on each screen of the application. </div>
        </section>

        <section>
            <ul class="bullets">
                <li>The utility navigation is positioned to the right-hand side of the footer. </li>
                <li>(Recommended) Limit the number of utility navigation options to 4. </li>
            </ul>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/footer/KrakenDSM_Components_Footer_05.png">

        <section>
            <div class="kn-subtitle-16">Hyperlinks</div>
            <div class="text">The utility navigation is presented as a set of secondary variant style of hyperlinks (presented with a subdued appearance compared to the primary variant). Additionally, the footer hyperlinks are presented in the quiet style (without an underline). An underline is revealed when hovering over the quiet style. </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/footer/KrakenDSM_Components_Footer_06.png">

        <section>
            <div class="kn-subtitle-16">Position</div>

            <div class="text">The footer is displayed persistently at the bottom of every page on a website and , below the main body content. </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Sizing</div>

            <div class="text">The width of the footer is fully responsive--the display is determined by the web browser's display area. Ensure that all content is visible within the body of the footer. The height of the footer remains at a fixed height of <span class="kn-bold">50px</span>, and the copyright and utility navigation links do not wrap to a second line. </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/footer/KrakenDSM_Components_Footer_07.png">

        <section>
            <div class="kn-subtitle-16">Responsive breakpoints</div>

            <div class="text">Responsive breakpoints refer to the specific screen widths at which the layout and design of a footer adapts for optimal viewing and functionality. </div>
        </section>

        <section>
            <ul class="bullets">
                <li><span class="kn-bold">Large (1440px +) </span> </li>
                <li><span class="kn-bold">Medium (1280px - 1439px) </span> </li>
                <li><span class="kn-bold">Small (1024px - 1279px) </span> </li>
            </ul>
        </section>

        <section>
            <div class="kn-subtitle-16">Display styles</div>

            <div class="text">Footers are displayed in two common styles: sticky or bottom. The sticky style remains visible at the bottom of the viewport even when the user scrolls through the page content. This ensures that the footer is always visible, regardless of the amount of content on the page. </div>
        </section>

        <section>
            <div class="text">The bottom footer style displays the footer at the bottom of the scrolled page following the content flow. The footer is positioned after all other content and appearing at the end of the page. This style is commonly used for longer pages with multiple sections. </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>            
            <img class="kn-responsive-img" src="assets/usage guidelines/footer/KrakenDSM_Components_Footer_08.png">

            <section>
                <div class="kn-subtitle-16">Primary navigation in base footer</div>

                <div class="text">Do not display primary application navigation links within the base footer. </div>
            </section>
        </section>
    </div>
</div>