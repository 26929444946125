<div class="page-container">
    <div class="section-container contact-us">
        <div class="kn-page-title">Contact</div>
        <section>
            <div class="text">The <span class="kn-bold">Kraken DS</span> is an ever-expanding and evolving product that benefits from receiving your input. If you have anything to share, we would love to hear from you!</div>
        </section>

        <section>
            <div class="text">Please submit your feedback using the below form if you:</div>
           <ul>
                <li>Have questions about or require assistance with consuming the design system components</li>
                <li>Discover any bug issues with design system components, design specs, usage guidelines, or the Kraken DS website</li>
                <li>Desire to collaborate with the UX team on developing custom components or modifications</li>
                <li>Would like to request design system training for your team</li>
                <li>Have suggestions or requests for component and UX pattern additions</li>
           </ul>
        </section>

        <section>
            <kn-spinner [isLoading]="!isLoaded"></kn-spinner>
            <iframe id="contactUsForm" width="900" height="600" src="https://app.smartsheet.com/b/form/a69fe37882914909b660665746d6dfe0"></iframe>
        </section>
    </div>
</div>