import { Component, Input, AfterViewInit, ElementRef, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'kn-autocomplete',
  templateUrl:'./kraken-autocomplete.component.html',
  styleUrls: ['./kraken-autocomplete.component.sass'],
})
export class KrakenAutocompleteComponent implements AfterViewInit, OnInit {
  @Input() data: any;
  @Input() keyWord = "";
  @Input() fields: any;
  @Input() hideDropdown = false;
  @Input() searchboxId: string;
  @Input() type = "text";
  @Input() placeholder = "Search";
  @Input() name = 'searchbox';
  @Input() dropdownWidth: string;
  @Input() dropdownHeight = 'auto';
  @Input() dropdownRightAligned = false;
  @Input() compressed = false;
  @Input() setDisabled = false;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onKeyUp = new EventEmitter<any>();
  @Output() onClear = new EventEmitter<any>();

  public showDropdown = false;
  public topPos: string;
  public width: string;
  public height = 'auto';
  public dataSearched: any = [];
  private _searchTerm = '';
  public noResults = ""
  public source: object[] = [];
  public length = 0;
  public dropdown: HTMLElement;
  public input;
  public rightAligned;
  public leftPos;
  public dropdownElm;

  get searchTerm(): string {
    return this._searchTerm;
  }
  set searchTerm(value: string) {
    this._searchTerm = value;
    this.dataSearched = value ? this.onFiltering(value) : [];
    if(this.fields) {
      this.fieldExists(this.dataSearched);
    } else {
      this.source = this.dataSearched;
    }
    this.itemListEventListener();

    setTimeout(() => {
      this.showDropdown = value ? true : false;
      if(this.showDropdown) {
        this.popupCalc();
      }
    }, 0);
    
  }

  constructor(public element: ElementRef) { }

  ngOnInit() {
    this.searchboxId = this.searchboxId ? this.searchboxId : 'knAutoComplete_' + Math.floor(Math.random()*90000) + 10000;
  }

  ngAfterViewInit(): void {
    this.input = this.element.nativeElement.querySelector('#' + this.searchboxId);
    this.dropdown = this.element.nativeElement.querySelector('.kn-autocomplete-dropdown');
    
    document.addEventListener('click', (e) => {
      this.dropdownElm = this.element.nativeElement.querySelector('.kn-dropdown');
        
      // show or hide the dropdown 
      if (e.target !== this.dropdownElm && e.target !== this.input) {
          this.showDropdown = false;
         } else {
          if(this._searchTerm) {
            this.showDropdown = true;
          }
         }
      });

      window.addEventListener('scroll', () => {
        this.popupCalc();
      },true);
  
      window.addEventListener('resize', () => {
        this.popupCalc();
      });
  }

  onFiltering(value) {
    let results;
    if(this.keyWord) {
      results = this.data.filter(i => {
        const keywords = i[this.keyWord];
        const keywordList = keywords.split(",");
        const isKeywordMatch = keywordList.filter(k => k.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        return isKeywordMatch.length > 0 ? true : false;
      });
      
    } else if(this.fields && !this.keyWord) {
      results = this.data.filter(k => k[this.fields.text].toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      results = this.data.filter(i => i.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }
    return results;
  }

  itemSelect(item) {
    this.searchTerm = '';
    this.onSelect.emit(item);
  }

  fieldExists(data) {
    this.source = [];
    for (const key in data)
    {
      //check if object array has the required fields
      const indexedItem = data[key];
      if (Object.prototype.hasOwnProperty.call(indexedItem, this.fields.text) ) {
        const item : object[] = [];
        item['text'] = indexedItem[this.fields.text];
        item['value'] = indexedItem[this.fields.value];
        item['origin'] = indexedItem;
        this.source.push(item);
      } 
    }
  }

  keyUp(e){
      this.onKeyUp.emit({data: this.dataSearched, value: this.searchTerm});
      if(e.key == "ArrowDown") {
        const items = this.dropdown.children;
        const itemList = Array.from(items);
        this.itemListEventListener();
        (itemList[0] as HTMLElement).focus();
      }
  }

  clear() {
    this.onClear.emit(this.data);
    this.searchTerm = '';
    this.input.focus();
  }

  //highlight the search term
  searchTermHighlight(result, index) {
    const elm = this.element.nativeElement.querySelector('#result_' + index + ' .item');
    const text = result.replace(new RegExp(this.searchTerm,"gi"), '<span class="kn-bold">$&</span>');
    elm.innerHTML = text;
  }

  navigateItemList(e){
    const nextElm = (e.currentTarget.nextElementSibling as  HTMLElement);
    const prevElm = (e.currentTarget.previousElementSibling as  HTMLElement);
    const items = this.dropdown.children;
    const itemList = Array.from(items);

    if(e.key == "ArrowDown" && nextElm) {
      nextElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });

      nextElm.classList.add('focus');
    } 

    if(e.key == "ArrowUp" && prevElm) {
      prevElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });
      prevElm.classList.add('focus');
    }

    if(e.key == "Enter") {
      const row = e.currentTarget.querySelector('.target');
      row.click();
      this.searchTerm = '';
      e.stopImmediatePropagation();
    }
    e.preventDefault();
  }

  itemListEventListener() {
    const items = this.dropdown.children;
    const itemList = Array.from(items);
    let index = 0;
    itemList.forEach(i => {
      index = index + 1;
      i.classList.remove('focus');
      i.setAttribute('tabindex', '' + index + '');
      i.addEventListener('keydown', (e) => {
        this.navigateItemList(e);
      });
    });
  }

  popupCalc() {
    if(this.dropdownElm) {
      const y = this.input.getBoundingClientRect().top; 
      this.width = this.input.offsetWidth + 'px';
      this.topPos = (this.input.offsetHeight + y) + 'px';
      const bottom = window.innerHeight  - y;
      const dropdownHeight = this.dropdownElm.getBoundingClientRect().height;
      if(bottom < dropdownHeight) {
        this.topPos = (y - dropdownHeight - 10) + 'px';
      } else {
        this.topPos = (this.input.offsetHeight + y) + 'px';
      }
      if(this.dropdownRightAligned) {
        this.leftPos = Math.abs(this.input.getBoundingClientRect().right - this.dropdownElm.offsetWidth) + 'px';
      } else {
        this.leftPos = this.input.getBoundingClientRect().left + 'px';
      }
    }
  }
}
