<div 
    [ngClass]="{'active': active}" 
    class="kn-tab-content" 
    [ngStyle]="
    {
        'width': width,
        'max-width': maxWidth,
        'min-width': minWidth

    }">
    <ng-content></ng-content>
</div>