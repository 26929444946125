<app-canvas 
    [js]="true" 
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef">
    <div title>Checkbox <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
       <kn-checkbox
            [checkboxId]="'checkboxExample'"
            [label]="label"
            [(ngModel)]="checkboxDefault"
            [defaultValue]="checkboxDefault"
            [disabled]="disabled"
            [name]="'checkbox'"
            [required]="required"
            (onSelect)="checkboxSelection($event)"
            [customError]="customError"
            [customWarning]="customWarning"
            [customMess]="customMess"
            [customLabel]="customLabelTemp">
            <ng-template #labelTemplate>
                <div>Custom label with <span class="kn-bold">HTML</span></div>
            </ng-template>
        </kn-checkbox>
    </div>
   
    <div javascript>
        <span class="code-comment">//Initialize the component</span>
        {{initalizeComponentWeb}}
        <span class="code-comment">//Note the addition of id="{{componentId}}" to the component container. This is needed for adding and removing properties and event listeners</span>
        <span class="code-comment">//All properties with a boolean value can be set in code like this</span>
        {{webSetProperties}}
        <span class="code-comment">//Remove a boolean property like this</span>
        {{webRemoveProperties}}
        <span class="code-comment">//Set a custom error message in code</span>
        {{webErrorMessage}}
    </div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onSelect():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">id:</span> "{{checkboxEvent?.id}}"</div>
                    <div class="obj-item"><span class="label">label:</span> "{{checkboxEvent?.label}}"</div>
                    <div class="obj-item"><span class="label">name:</span> "{{checkboxEvent?.name}}"</div>
                    <div class="obj-item"><span class="label">value:</span> "{{checkboxEvent?.value}}"</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-row">
            <kn-textbox [label]="'Checkbox label'" [value]="label" (onValueChange)="labelText($event)" [clearIcon]="true" [setDisabled]="customLabelTemp"></kn-textbox>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle label="Custom label (HTML)" [(ngModel)]="customLabelTemp" (value)="customTemplateEvent($event);"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled State" (value)="controlEvent($event, '[disabled]=', true, true);" [(ngModel)]="disabled" [disabled]="setCustomMessage"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Error / warning state'" 
                (value)="customMessToggle($event);" [(ngModel)]="setCustomMessage" [disabled]="disabled">
            </kn-toggle>
        </div>
        <div class="item-row" *ngIf="addCustomMessage">
            <kn-radio-button [id]="'errorRadio'" [defaultValue]="messageType" [(ngModel)]="messageType" [value]="'radioError'" [name]="'custom-message'" [label]="'Error'" (onSelect)="customMessage($event);" [disabled]="customError"></kn-radio-button>
        </div>
        <div class="item-row" *ngIf="addCustomMessage">
            <kn-radio-button [id]="'warningRadio'" [defaultValue]="messageType" [(ngModel)]="messageType" [value]="'radioWarning'" [name]="'custom-message'" [label]="'Warning'" (onSelect)="customMessage($event)" [disabled]="customWarning"></kn-radio-button>
        </div>
        <div *ngIf="addCustomMessage" class="action-input">
            <kn-textbox 
                [label]="'Custom message'" 
                [value]="customMess" 
                [setPlaceholder]="'Provide a custom message'"
                [setDisabled]="!addCustomMessage && !inputRequired && !emailValidator || messageType == ''" 
                (onValueChange)="message($event)" 
                [clearIcon]="true">
            </kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle label="Select event" (value)="controlEvent($event, '(onSelect)=', 'onSelect($event)', false);" [(ngModel)]="checkboxSelectEvent"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">checkboxId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"checkboxExample"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customLabel</div>
                <div class="item"><i>bool</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">name</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"checkbox"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">label</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Checkbox example"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">disbled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">defaultValue</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">required</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customError</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customWarning</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customMess</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"invalid text"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onSelect</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <checkbox-usage></checkbox-usage>
    </div>
    <div designSpec>
        <checkbox-specs></checkbox-specs>
    </div>
    <div accessibility>
        <div class="canvas-inner-container">
            <checkbox-accessibility></checkbox-accessibility>
        </div>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.4</div>
                <div class="item">
                    <ul>
                        <li>Added addition unit testing after component was published</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">June 15, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - remove validation container space if no validation is applied</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the checkbox component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

