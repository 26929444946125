<div class="login-page">
    <!-- <div class="title">Kraken UI</div> -->
    <div class="login-container">
        <div class="kn-tile">
            <div class="kn-row">
                <div class="kn-col">
                    <kn-textbox 
                        [label]="'Username'" 
                        [(ngModel)]="username"
                        [name]="'username'">
                    </kn-textbox>
                    <div class="kn-spacer"></div>
                    <kn-textbox 
                        [label]="'Password'" 
                        [type]="'password'"
                        [(ngModel)]="password"
                        [name]="'passord'"
                        [password]="true">
                    </kn-textbox>
                </div>
            </div>
            <div class="kn-row">
                <div class="kn-col login-btn">
                    <kn-button 
                        [buttonType]="'kn-primary'" 
                        [buttonText]="'Login'" 
                        (btnClick)="onLogin()"
                        [disabled]="!username || !password">
                    </kn-button>
                    <div class="login-error" *ngIf='error'><i class="fa-solid fa-triangle-exclamation kn-icon-sm"></i> {{loginError}}</div>
                </div>
            </div>
    </div>
    </div>
</div>