<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Elevation</div>
        
        <section>
            <div class="kn-headline-24-med">Establishing depth</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Elevation</span> is the relative distance between two surfaces along the z-axis. The strategic utilization of elevation establishes a sense of depth and layers in the application UI experience. This, in turn, visually supports the communication of the information hierarchy and assists with focusing user attention where needed.</div>
        </section>
       
        <div class="kn-headline-24-med">Shadows</div>

        <section>
            <div class="text">Elevation is depicted by the consistent use of shadow.</div>
        </section>
       
        <img class="kn-responsive-img" src="assets/style guide/elevation/KrakenDSM_StyleGuide_Elevation_01.png">

        <section>
            <div class="text">
                <span class="kn-bold">2 px z:</span><br/>
                x0, y1, blur 2, spread 1 #000000 10%<br/>
                <span class="codesnippet">Theme variable: <pre><code>$box-shadow-elv-2</code></pre></span>
                <span class="codesnippet">CSS variable: <pre><code>--box-shadow-elv-2</code></pre></span>
            </div>
        </section>
        <section>
            <div class="text">
                <span class="kn-bold">4 px z:</span><br/>
                x0, y1, blur 8, spread 2 #000000 16%<br/>
                <span class="codesnippet">Theme variable: <pre><code>$box-shadow-elv-4</code></pre></span>
                <span class="codesnippet">CSS variable: <pre><code>--box-shadow-elv-4</code></pre></span>
            </div>
        </section>
        <section>
            <div class="text">
                <span class="kn-bold">8 px z:</span><br/>
                x0, y4, blur 8, spread 4 #000000 18%<br/>
                <span class="codesnippet">Theme variable: <pre><code>$box-shadow-elv-8</code></pre></span>
                <span class="codesnippet">CSS variable: <pre><code>--box-shadow-elv-8</code></pre></span>
            </div>
        </section>
        <section>
            <div class="text">
                <span class="kn-bold">12 px z:</span><br/>
                x0, y12, blur 12, spread 4<br/>
                <span class="codesnippet">Theme variable: <pre><code>$box-shadow-elv-12</code></pre></span>
                <span class="codesnippet">CSS variable: <pre><code>--box-shadow-elv-12</code></pre></span>
            </div>
        </section>
            <!-- <div class="text"><span class="kn-bold">4 px z:</span> x0, y1, blur 8, spread 2 #000000 16%</div> 
            <div class="text"><span class="kn-bold">8 px z:</span> x0, y4, blur 8, spread 4 #000000 18%</div>
            <div class="text"><span class="kn-bold">12 px z:</span> x0, y12, blur 12, spread 4 #000000 20%</div> -->


        <div class="kn-headline-24-med">Measuring elevation</div>

        <section>
            <div class="text">Elevation in the Kraken DS is measured as the distance between surfaces and adopts a similar approach to the Material Design guideline. The distance from the front of one surface to the front of another is measured along the z-axis in density-independent pixels (dps) and depicted using shadows.</div>
        </section>
      
        <img class="kn-responsive-img" src="assets/style guide/elevation/KrakenDSM_StyleGuide_Elevation_02.png">

        <section>
            <div class="text">Surfaces at the same elevation can appear differently when other surfaces are behind them. Both surfaces <span class="kn-bold">1</span> and <span class="kn-bold">3</span> are at the same elevation of 8 px z (as viewed from the side). They cast different shadows because surface <span class="kn-bold">3</span> is on top of another surface <span class="kn-bold">(2)</span> that has elevation.</div>
        </section>

        <div class="kn-headline-24-med">Elevation in use</div>

        <section>
            <div class="text"><span class="kn-bold">Resting elevation</span>
                <br/>
                Resting elevations are starting elevation values given to components by default. Components move from resting elevations in response to the user or a system event.</div>
        </section>

        <img class="kn-responsive-img" src="assets/style guide/elevation/KrakenDSM_StyleGuide_Elevation_03.png">

        <section>
            <div class="text"><span class="kn-bold">GROUPING A</span></div>
            <div class="text">1. Resting Elevation (Page Background)</div>
            <div class="text">2. Resting Elevation (Page Table)</div>
            <div class="text">3. 4 px z Elevation (Page Navigation)</div>
            <div class="text">4. 4 px z Elevation (Matters)</div>
        </section>

        <img class="kn-responsive-img" src="assets/style guide/elevation/KrakenDSM_StyleGuide_Elevation_04.png">

        <section>
            <div class="text">When a modal dialog is triggered, the page background behind the modal dialog is grayed out (50% opacity, #6A757E Ink, Lighter), focusing the user away from the page-level interactions. In this case, the page elevation is split into two groups: Group A with the standard page-level interactions and Group B with modal dialog interactions.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">GROUPING A</span></div>
            <div class="text">1. Resting Elevation (Page Background)</div>
            <div class="text">2. Resting Elevation (Page Table)</div>
            <div class="text">3. 4 px z Elevation (Page Navigation)</div>
            <div class="text">4. 4 px z Elevation (Matters)</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">GROUPING B</span></div>
            <div class="text">5. Resting Elevation (Lightbox Background)</div>
            <div class="text">6. 4 px z Elevation (Modal Dialog)</div>
            <div class="text">7. 4 px z Elevation (Component Dropdown)</div>
        </section>
    </div>
</div>