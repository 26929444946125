import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'kraken-multi-select-validation',
  templateUrl: `./kraken-field-validation.component.html`,
  styles: [
  ]
})
export class KrakenFieldValidationComponent implements OnInit {
  @Input() field: FormControl;
  @Input() fieldName: string;
  @Input() errorPlacementFloating = true;
  @Input() customError = false;
  @Input() customMess: string;
  @Input() customWarning = false;

  constructor() { }

  ngOnInit(): void {
  }

}
