import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';
@Component({
  selector: 'text-area-accessibility',
  templateUrl: './text-area-accessibility.component.html',
  styleUrls: ['./text-area-accessibility.component.sass']
})
export class TextAreaAccessibilityComponent implements OnInit {
  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;

  constructor(private templateService: TemplateUpdateService) { }

  ngOnInit(): void {
  }

}
