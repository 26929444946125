import { Component, OnInit } from '@angular/core';
import  packageInfo from '../../../../../kraken-chip/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.sass']
})
export class ChipComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public event1 = true;
  public elementTag = 'kn-chip';
  public componentVersion = packageInfo.version;
  public label = 'This is a chip';
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenChipModule'};
  public componentId = 'chipComponentId';
  public dismissible = false;
  public hideTooltip = false;
  public showCustomTooltip = false;
  public customTooltip;
  public dismissEvent = false;
  public disabled = false;
  public compressed = false;
  public alwaysShowTooltipText = `Tooltip will always display on hover, whether the content is truncated or not`;
  public filled = 'standard';
  public fields = {text: 'color', value: 'code'}
  public fillColorObj = [
    {color: 'Standard', hexCode: '#434C54', code: 'default'},
    {color: 'Primary, Light', hexCode: '#B2D3E7', code: 'primaryLight'},
    {color: 'Secondary, Light', hexCode: '#FBCC94', code: 'secondaryLight'},
    {color: 'Danger, Light', hexCode: '#FA9A88', code: 'dangerLight'},
    {color: 'Green, Light', hexCode: '#DDECBC', code: 'greenLight'},
    {color: 'Yellow, Light', hexCode: '#FFEA8A', code: 'yellowLight'},
    {color: 'Teal, Light', hexCode: '#B7ECEC', code: 'tealLight'},
    {color: 'Indigo, Light', hexCode: '#B3BCF5', code: 'indigoLight'},
    {color: 'Purple, Light', hexCode: '#E3D0FF', code: 'purpleLight'},
    {color: 'Primary, Lighter', hexCode: '#C6DEED', code: 'primaryLighter'},
    {color: 'Secondary, Lighter', hexCode: '#FDEAD4', code: 'secondaryLighter'},
    {color: 'Danger, Lighter', hexCode: '#FDE4DD', code: 'dangerLighter'},
    {color: 'Green, Lighter', hexCode: '#EBF4D7', code: 'greenLighter'},
    {color: 'Yellow, Lighter', hexCode: '#FCF1CD', code: 'yellowLighter'},
    {color: 'Teal, Lighter', hexCode: '#CDF2F2', code: 'tealLighter'},
    {color: 'Indigo, Lighter', hexCode: '#E1E4FB', code: 'indigoLighter'},
    {color: 'Purple, Lighter', hexCode: '#EBDEFF', code: 'purpleLighter'}
  ]
  public curentFillColor = this.fillColorObj[0].color;
  public fillCode = '';
  public angularComponentOptions: any = [
    { option: '[label]=', id: 0, value: '"\''+ this.label +'\'"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"exampleChip"'},
    { option: 'label=', id: 0, value: '"'+ this.label +'"'}
  ];

  public items = [
    {label: 'Oldies', hideTooltip: false, dismissible: true},
    {label: 'Blues', hideTooltip: false, dismissible: true, customTooltip: `<div class="kn-bold">this is a test/</div>
    new/<br/>
    improved/`},
    {label: '90s', hideTooltip: false, dismissible: false}
  ]

  public alwaysShowTooltipWeb =
  `chip.alwaysShowTooltip = true;`
  public dismissWebEvent = 
  `let chip = document.querySelector('#exampleChip');

chip.addEventListener('onDismiss', (e) => {
  console.log(e.detail)
});
`

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);

  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  labelText(event) {
    this.label = event;

    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[label]='));
    this.angularComponentOptions[index].option = '[label]=';
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'label='));
    this.webComponentOptions[webIndex].option = 'label=';
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  close(event, index) {
    this.items.splice(index, 1);
  }

  customTootipEvent(event) {
    this.controlEvent(event, '[customTooltip]=', '', true);

    if(event) {
      this.customTooltip = `This is a custom <span class="kn-bold">tooltip</span>`;
      //angular
      const index = this.angularComponentOptions.findIndex((obj => obj.option == '[customTooltip]='));
      this.angularComponentOptions[index].option = '[customTooltip]=';
      this.angularComponentOptions[index].value = '"\'' + this.customTooltip +'\'"';
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
      //web
      const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'custom-tooltip='));
      this.webComponentOptions[webIndex].option = 'custom-tooltip=';
      this.webComponentOptions[webIndex].value = '"' + this.customTooltip + '"';
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    } else {
      this.customTooltip = "";
    }
  }

  toggleFilledEvent(event) {
    const state = event.value == 'filled' ? true : false;
    this.controlEvent(state, '[fillColor]=', '', true);
  
    if(event.value == 'standard') {
      this.fillCode = '';
    }

    if(event.value == 'filled') {
      this.fillCode = 'default';
      const fillColorIndex = this.angularComponentOptions.findIndex((obj => obj.option == '[fillColor]='));
      this.angularComponentOptions[fillColorIndex].value = '"\'default\'"';
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

      const fillColorIndexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'fill-color='));
      this.webComponentOptions[fillColorIndexWeb].value = '"default"';
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    }
  }

  selectFillColorEvent(item) {
    this.fillCode = item.code;
    //angular
    const fillColorIndex = this.angularComponentOptions.findIndex((obj => obj.option == '[fillColor]='));
    this.angularComponentOptions[fillColorIndex].value = '"\'' + item.code + '\'"';

    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

    //web
    const fillColorIndexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'fill-color='));
    this.webComponentOptions[fillColorIndexWeb].value = '"' + item.code +'"';
    
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  disabledEvent(event) {
    if(event) {
      this.controlEvent(false, '[dismissible]=', '', true);
      this.dismissible = false;
    }
  }
}
