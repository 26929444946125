import { Component, OnInit } from '@angular/core';
import  packageInfo from '../../../../../kraken-tooltip/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';

@Component({
  selector: 'app-tool-tip',
  templateUrl: './tool-tip.component.html',
  styleUrls: ['./tool-tip.component.sass']
})
export class ToolTipComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public angularComponentRef2;
  public webComponentRef;
  public tooltipDirection = 'top';
  public elementTag = 'i';
  public webElementTag = 'kn-tooltip';
  public label = 'This is a tooltip';
  public htmlLabel = "Example tooltip with <span class='kn-bold'>HTML</span></span>";
  public labelNoHtml;
  public componentVersion = packageInfo.version;
  public webContent = '<span slot="content"><i class="fa-solid fa-circle-info"></i></span>';
  public isLabelHtml = false;
  public hideTooltip = false;
  public showTooltip = true;
  public hideTooltipText = `You can toggle the tooltip on or off by setting the "showTooltip" property`;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenTooltipModule'
  };
  public angularComponentOptions: any = [
    { option: 'class=', id: 0, value: '"fa-solid fa-circle-info kn-icon"'},
    { option: 'tooltip=', id: 1, value: '"' + this.label + '"'},
    { option: '[tooltipDirection]=', id: 2, value: '"\''+ this.tooltipDirection +'\'"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 1, value: '"tooltipExample"' },
    { option: 'tooltip=', id: 1, value: '"'+ this.label +'"' },
    { option: 'direction=', id: 2, value: '"'+ this.tooltipDirection +'"'}
  ];

  public webExample = 
`
let tooltipExample = document.querySelector('#tooltipExample');
tooltipExample.tooltip = "This is a <span class='kn-bold'>tooltip</span>";
tooltipExample.direction = "top";`

  constructor(public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.labelNoHtml = this.label;
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer, this.webContent);
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  direction(event) {
    this.tooltipDirection = event.value;
    
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[tooltipDirection]='));
    this.angularComponentOptions[index].value = '"\'' + this.tooltipDirection +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'direction='));
    this.webComponentOptions[webIndex].value = '"' + this.tooltipDirection + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer, this.webContent);
  }

  textChange(event) {
    this.label = event;
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == 'tooltip='));
    this.angularComponentOptions[index].value = '"' + event +'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'tooltip='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer, this.webContent);
  }

  setHtmlLabel(event) {
    let label;
    if(event) {
      label = this.htmlLabel;
    } else {
      label = this.labelNoHtml;
    }
    this.textChange(label);
  }

  hideTooltipEvent(event) {
    this.showTooltip = event ? false : true;
  }

}
