import { Component, ElementRef, AfterViewInit } from '@angular/core';
import { NavigationService } from '../app/services/navigation.service';
import { SitemapService } from '../app/services/sitemap.service';
import { Router, NavigationEnd, NavigationStart  } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { AuthService } from './services/auth.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass']
})
export class AppComponent implements AfterViewInit {
  isLoggedIn: Observable<boolean>;

  constructor(
    public elm: ElementRef, 
    public nav: NavigationService,
    public siteMapService: SitemapService,
    public router: Router,
    public GAService: GoogleAnalyticsService,
    private authService: AuthService) {
      this.isLoggedIn = this.authService.isLoggedIn;
    }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        // Check if the route is not the login page to avoid infinite loop
        const loginCookie = this.authService.checkLoginOnStartup()
        if (event.url !== '/login' && !loginCookie) {
          // User is not logged in, redirect to login page
          this.router.navigate(['/login']);
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      let path = window.location.hash; // Gets the hash fragment in the URL
      // if (path) {
      //   path = path.slice(2); // Removes the '#/' part to get the route path
      //   const formattedPath = this.formatPathForTitle(path);
      //   this.titleService.setTitle(`Kraken DSM - ${formattedPath}`);
      //   //GA tracking
      //   this.GAService.trackPageView(event.url);
      // } else {
      //   // Default title if no specific hash fragment is found
      //   this.titleService.setTitle("Kraken DSM");
      //   //GA tracking
      //   this.GAService.trackPageView(event.url);
      // }
      const url = event.url.split('?')
      path = path.slice(2); 
      const formattedPath = this.formatPathForTitle(path);
      this.GAService.trackEvent('Kraken Page View', {
        'event_category': 'Navigation',
        'event_label': 'Header tab clicked',
        'value': formattedPath
      });
      
      const route:any = this.siteMapService.siteMapData().filter(i => i.route === url[0]);
      //open side accordion to the current page
      setTimeout(() => {
        const accordion = document.querySelector('.kn-accordion');
        if(route.length > 0) {
          this.nav.accordionNavigation(accordion, route[0].group, route[0].subGroup);
        }
      }, 500);
      
    });
  }

  onActivate() {
    const page = this.elm.nativeElement.querySelector('#cnContainer');
    page.scrollTop = 0;
  }

  formatPathForTitle(path: string): string {
    // Format the path as needed, e.g., replace dashes with spaces, capitalize, etc.
    // Here's a simple example that replaces hyphens with spaces and capitalizes words
    return path.split('-').map(part => part.charAt(0).toUpperCase() + part.slice(1)).join(' ');
  }

}
