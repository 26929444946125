<div 
    id="{{componentId}}"
    class="kn-chip" 
    [ngClass]="{
        'solid': fillColor,
        'deafult': fillColor == 'default',
        'compressed': compressed,
        'primaryLight': fillColor == 'primaryLight',
        'secondaryLight': fillColor == 'secondaryLight',
        'dangerLight': fillColor == 'dangerLight',
        'greenLight': fillColor == 'greenLight',
        'yellowLight': fillColor == 'yellowLight',
        'tealLight': fillColor == 'tealLight',
        'indigoLight': fillColor == 'indigoLight',
        'purpleLight': fillColor == 'purpleLight',
        'primaryLighter': fillColor == 'primaryLighter',
        'secondaryLighter': fillColor == 'secondaryLighter',
        'dangerLighter': fillColor == 'dangerLighter',
        'greenLighter': fillColor == 'greenLighter',
        'yellowLighter': fillColor == 'yellowLighter',
        'tealLighter': fillColor == 'tealLighter',
        'indigoLighter': fillColor == 'indigoLighter',
        'purpleLighter': fillColor == 'purpleLighter',
        'default': fillColor == 'default',
        'disabled': disabled
    }"
>
    <div class="kn-chip-body">
        <div 
            class="kn-chip-label"
            tooltip="{{customTooltip ? customTooltip : label}}" 
            [tooltipDirection]="'top'" 
            [showTooltip]="istooltipShown">{{label}}</div>
        <i class="fa-solid fa-circle-xmark kn-icon-sm close" *ngIf="dismissible && !disabled" (click)="close()"></i>
    </div>
</div>