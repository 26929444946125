import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import  packageInfo from '../../../../../kraken-toggle/package.json';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.sass']
})
export class ToggleComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public webClickEvent;
  public componentId = 'toggleComponentId';
  public webSetProperties;
  public webRemoveProperties;
  public elementTag = 'kn-toggle';
  public label = "Example toggle switch";
  public toggleChecked = false;
  public toggleDisabled = false;
  public valueEvent = false;
  public toggleValue = false;
  public event1 = true;
  public toggleDemo = false;
  public customLabel = false;
  public customLableTemplate: any;
  public customLabelTemp = false;
  public labelLeft = false;
  public initializeComponentWeb: any;
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenToggleModule', 
    form: true};
  public angularComponentOptions: any = [
    { option: '[toggleId]=', id: 0, value: '"\''+ this.componentId +'\'"'},
    { option: '[label]=', id: 1, value: '"\'' + this.label + '\'"'},
    { option: '[(ngModel)]=', id: 1, value: '"toggleValue"'},
    { option: '[name]=', id: 3, value: '"\'toggle\'"'},
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"'+ this.componentId +'"'},
    { option: 'toggle-id=', id: 0, value: '"exampleToggle"'},
    { option: 'label=', id: 1, value: '"' + this.label + '"'},
    { option: 'name=', id: 3, value: '"toggle"'}
  ];

  public webChecked =
  `
  toggleElm.val = true;`
  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);

    this.initializeComponentWeb = `
const toggleElm = document.querySelector('#${this.componentId}');
    `
    this.webClickEvent = 
`
toggleElm.addEventListener('value', (event) => console.log(event.detail));
`

    this.webSetProperties = 
`
toggleElm.disabled = true;
`
this.webRemoveProperties = 
`
toggleElm.disabled = false;
`
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  labelText(event) {
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[label]='));
    this.label = event;
    //angular
    this.angularComponentOptions[index].option = '[label]=';
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    this.webComponentOptions[index].option = 'label=';
    this.webComponentOptions[index].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  valueChange(event) {
    this.toggleValue = event;
  }

  customTemplateEvent(event) {
    if(event) {
      this.controlEvent(true, '[customLabel]=', 'true', false);
      this.controlEvent(false, '[label]=', 'true', false);
      //custom template
      this.customLableTemplate = 
`
  <ng-template #labelTemplate>
    <div class="toggle-label">Custom template with <span class="kn-bold">HTML</span></div>
  </ng-template>
`
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.customLableTemplate);
    } else {
      this.controlEvent(false, '[customLabel]=', 'false', false);
      this.controlEvent(true, '[label]=', ''+ this.label +'', false);
      this.customLableTemplate = "";
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.customLableTemplate);
    }
  }

}
