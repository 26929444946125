import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'kn-tooltip',
  templateUrl: './kraken-web-tooltip.component.html',
  styleUrls: ['./kraken-web-tooltip.component.sass'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class KrakenWebTooltipComponent implements OnInit {
  @Input() tooltip: string;
  @Input() direction: string;

  constructor() { }

  ngOnInit(): void {
  }

}
