import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-capitalization',
  templateUrl: './capitalization.component.html',
  styleUrls: ['./capitalization.component.sass']
})
export class CapitalizationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
