<div class="kn-header" 
  id="{{id}}"
  [ngClass]="{
    'light': style == 'light',
    'dark': style == 'dark',
    'kn-compressed': compressed == true
  }">
    <nav>
      <div class="branding">
        <div class="logo">
          <ng-content select="[logo]"></ng-content>
        </div>
        <div class="version">
          <ng-content select="[version]"></ng-content>
        </div>
      </div>
      <div class="main-nav">
        <div class="header-masthead-nav">
          <div class="kn-nav-container">
            <!--Main navigation section-->
            <ng-content select="[leftNav]"></ng-content>
            <!--Overflow menu-->
            <div 
              class="kn-masthead-overflow-container" 
              [ngClass]="{'hide': !hiddenItems.length}">
              <div class="link" [ngClass]="{'active-link': isHiddenLinkSelected}">
                <kn-icon-menu
                  [icon]="'fa-regular fa-ellipsis-vertical'"
                  [template]="true"
                  [dropdownCustomWidth]="'max-content'">
                  <ng-template  #customTemplate>
                    <div class="kn-dropdown-item" 
                      id="{{link.id}}"
                      [ngClass]="{'active': link.active, 'disabled': link.disabled}"
                      (click)="overflowItemSection(link)"
                      *ngFor="let link of linkListObject; let i = index">
                        {{link.name}}
                  </div>
                  </ng-template>
                </kn-icon-menu>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--Right side nav-->
      <div class="right-col">
        <ng-content select="[rightNav]"></ng-content>
      </div>
    </nav>
</div>