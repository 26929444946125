<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Notifications</div>
        
        <section>
            <div class="kn-headline-24-med">Toast confirmations</div>
        </section>
        
        <section>
            <img class="kn-responsive-img" src="assets/style guide/content/notifications/KrakenDSM_StyleGuide_Content_Notifications_01.png">
        </section>

        <section>
            <div class="text">Toasts inform users about events in the application with brief, temporary notifications that appear at the top center of the application viewport window. Toasts are designed to be noticed without disrupting a user's experience or requiring an action to be taken. Ensure messages are concise and informative with an instructive tone that appear for a short time.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Emphasized text </span> </div>

            <div class="text">Use emphasized text sparingly to highlight critical information that relates to performed application actions. </div>
        </section>

        <section>
            <div class="text">"<span class="kn-bold">Carl Hanson</span> created"</div>
            <div class="text">"<span class="kn-bold">4</span> users assigned to <span class="kn-bold">Rivers v Lakes</span> matters"</div>
            <div class="text">"<span class="kn-bold">Erin Hanon</span> permanently deleted"</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Spectra-based toast examples</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Matters</div>

            <div class="text">"<span class="kn-bold">(mattername)</span> created" (Green)</div>
            <div class="text">"<span class="kn-bold">(#)</span> users assigned to <span class="kn-bold">(mattername)</span>" (Green)</div>
            <div class="text">"Assignments updated for <span class="kn-bold">(mattername)</span>" (Green)</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Matter details (Imports & processing)</div>

            <div class="text">"<span class="kn-bold">(#)</span> files imported" (Green)</div>
            <div class="text">"<span class="kn-bold">(#)</span> of <span class="kn-bold">(#)</span> files imported" (Green)</div>
            <div class="text">"<span class="kn-bold">(Volumename)</span> submitted for processing" (Green)</div>
            <div class="text">"Imaging is active" (Green)</div>
            <div class="text">"<span class="kn-bold">(#)</span> files permanently deleted" (Gray)</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Matter details (Exports, custodians & matter settings)</div>
            
            <div class="text">"<span class="kn-bold">(Exportname)</span> created" (Green)</div>
            <div class="text">"<span class="kn-bold">(Exportname)</span> submitted" (Green)</div>
            <div class="text">"<span class="kn-bold">(Exportname)</span> updated" (Green)</div>
            <div class="text">"<span class="kn-bold">(Custodianname)</span> created" (Green)</div>
            <div class="text">"<span class="kn-bold">(#)</span> custodians created" (Green)</div>
            <div class="text">"Custodian information updated for <span class="kn-bold">(Custodianname)</span>" (Green)</div>
            <div class="text">"<span class="kn-bold">(Mattername)</span> permanently deleted" (Gray)</div>
            <div class="text">"<span class="kn-bold">(Custodianname)</span> permanently deleted" (Gray)</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Users</div>
            
            <div class="text">"Invitation sent to <span class="kn-bold">(Username)</span>" (Green)</div>
            <div class="text">"Invitation sent to <span class="kn-bold">(#)</span> users" (Green)</div>
            <div class="text">"Invitation re-sent to <span class="kn-bold">(Username)</span>" (Green)</div>
            <div class="text">"Invitation to <span class="kn-bold">(Username)</span> currently in process. Please wait a few minutes to resend again" (Green)</div>
            <div class="text">"<span class="kn-bold">(Username)</span> assigned to <span class="kn-bold">(#)</span> matters" (Green)</div>
            <div class="text">"<span class="kn-bold">(#)</span> users assigned to <span class="kn-bold">(#)</span> matters" (Green)</div>
            <div class="text">"User information updated for <span class="kn-bold">(Username)</span>" (Green)</div>
            <div class="text">"<span class="kn-bold">(Username)</span> unassigned from (Mattername)" (Green)</div>
            <div class="text">"<span class="kn-bold">(Username)</span> permanently deleted" (Gray)</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Clients</div>
            
            <div class="text">"<span class="kn-bold">(Clientname)</span> created" (Green)</div>
            <div class="text">"Client information updated for <span class="kn-bold">(Clientname)</span>" (Green)</div>
        </section>
    </div>
</div>