<app-canvas 
    [ts]="onValidate" 
    [js]="true" 
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [reactUrl]="'https://stackblitz.com/edit/github-rqclvg-gh18fa?embed=1&file=src%2FApp.js'">
    <div title>Text Area <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-textarea 
            [name]="'textareaName'" 
            [textareaId]="'textareaExample'" 
            [label]="label"
            [customError]="customError"
            [customMess]="customMess"
            [customWarning]="customWarning"
            [rows]="rows"
            [cols]="cols"
            [(ngModel)]="exampleText"
            [setRequired]="inputRequired"
            (onValueChange)="valueChange($event)"
            [setReadOnly]="readOnly"
            [setDisabled]="disabled"
            [setPlaceholder]="placeholder"
            [showCharCount]="showCounter"
            [maxChars]="maxChars"
            [optional]="optionalLabel"
            (onValidate)="textareaName = $event"
            [hideErrorMessage]="hideErrorMessage">
        </kn-textarea>
    </div> 
    <div angular>
        <br/>
        <span *ngIf="customDirective" class="code-comment">&lt;!--Custom directives can be added directly on the component as shown in the example above.<br/>
            If you want a custom error or warning message to bind with your directive, you can add the following:<br/>
            [customError]="yourInputName.invalid" [customMess]="yourInputName.errors?.errorMsg"--><br/><br/></span>
        
        <span *ngIf="onValidate">
            <br/>
            <span class="code-comment">&lt;!--You can bind directly to the validation event like this--></span>
            <span class="html-option">(onValidate)=</span>"textareaName = $event"
            <br/><br/>
            <span class="code-comment">&lt;!--This example will disabled the submit button when the textarea is invalid. Notice the [disabled] property has the validation check. You can set it in code like the example below --></span>
            <span class="html-tags">&lt;kn-button </span> <span class="html-option">[buttonId]=</span>"'exampleButton'" <span class="html-option">[buttonType]=</span>"'kn-primary'" <span class="html-option">[buttonText]=</span>"'Submit'" <span class="html-option">[disabled]=</span>"textareaName?.invalid" <span class="html-tags">&gt;</span><span class="html-tags">&lt;/kn-button&gt;</span>
        </span>
    </div>
    <div web>{{webComponentRef}}</div>
    <div javascript>
        <span class="code-comment">//Initialize the component</span>
        {{initializeComponentWeb}}
        <span class="code-comment">//Value change event</span>
        {{webClickEvent}}
        <span class="code-comment">//All properties with a boolean value can be set in code like this</span>
        {{webSetProperties}}
        <span class="code-comment">//Remove a boolean property like this</span>
        {{webRemoveProperties}}
    </div>
    <div typeScript>
        <span>{{validationTS}}</span>
    </div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onValueChange():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">{{eventValue}}</span></div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-row">
            <kn-textbox [label]="'Textarea label'" [value]="label" (onValueChange)="textChange($event, 1)" [clearIcon]="true"></kn-textbox>
            <div class="kn-spacer"></div>
            <kn-checkbox (onSelect)="controlEvent($event.value, '[optional]=', true, true); optionalLabel = !optionalLabel" [label]="'Optional field'" [disabled]="label == '' || inputRequired"></kn-checkbox>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle [label]="'Placeholder'" (value)="showHidePlaceholder($event)"></kn-toggle>
        </div>
        <div class="action-input" *ngIf="showPlaceholder">
            <kn-textbox [label]="'Placeholder text'" [value]="Placeholder" (onValueChange)="placeholderValue($event)" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Character counter'" 
                (value)="controlEvent($event, '[showCharCount]=', true, true); controlEvent($event, '[maxChars]=', 0, true); showCharCount($event)"
                [disabled]="readOnly || disabled">
            </kn-toggle>
        </div>
        <div class="action-input" *ngIf="showCounter">
            <kn-textbox [label]="'Enter max characters'"  [type]="'number'" [minNumber]="0" [value]="maxChars" (onValueChange)="charCount($event)" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle [label]="'Set size'" (value)="controlEvent($event, '[cols]=', cols, true); controlEvent($event, '[rows]=', rows, true); setSize = !setSize"></kn-toggle>
        </div>
        <div class="action-input" *ngIf="setSize">
            <kn-textbox [label]="'Column size'"  [type]="'number'" [value]="cols" (onValueChange)="textareaSize($event, 'cols')" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="action-input" *ngIf="setSize">
            <kn-textbox [label]="'Row size'"  [type]="'number'" [value]="rows" (onValueChange)="textareaSize($event, 'rows')" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle label="Read only" [(ngModel)]="readOnly" (value)="controlEvent($event, '[setReadOnly]=', 'true', true);" [disabled]="addCustomMessage || disabled || showCounter"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled state" [(ngModel)]="disabled" (value)="controlEvent($event, '[setDisabled]=', 'true', true);" [disabled]="addCustomMessage || readOnly || showCounter"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle [label]="'Required validation'" (value)="controlEvent($event, '[setRequired]=', true, true); customMessage($event); inputRequired = !inputRequired" [disabled]="optionalLabel"></kn-toggle>
        </div>
        <div class="action-input" *ngIf="inputRequired">
            <kn-textbox [label]="'Custom message'" [value]="customMess" (onValueChange)="addCustomessage($event, 1)" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Custom directive example'" 
                [(ngModel)]="customDirective"
                (value)="controlEvent($event, 'yourCustomDirective','--', false);controlEvent($event, '#yourInputName=', 'ngModel', false);"
                [disabled]="inputRequired || readOnly || disabled">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Error / warning state'" 
                (value)="customMessToggle($event); setCustomMessage = !setCustomMessage" 
                [disabled]="inputRequired || readOnly || disabled">
            </kn-toggle>
        </div>
        <div class="item-row" *ngIf="addCustomMessage">
            <kn-radio-button [id]="'errorRadio'" [defaultValue]="messageType" [(ngModel)]="messageType" [value]="'radioError'" [name]="'custom-message'" [label]="'Error'" (onSelect)="customMessage($event);" [disabled]="customError"></kn-radio-button>
        </div>
        <div class="item-row" *ngIf="addCustomMessage">
            <kn-radio-button [id]="'warningRadio'" [defaultValue]="messageType" [(ngModel)]="messageType" [value]="'radioWarning'" [name]="'custom-message'" [label]="'Warning'" (onSelect)="customMessage($event)" [disabled]="customWarning"></kn-radio-button>
        </div>
        <div *ngIf="addCustomMessage" class="action-input">
            <kn-textbox 
                [label]="'Custom message'" 
                [value]="customMess" 
                [setPlaceholder]="'Provide a custom message'"
                [setDisabled]="!addCustomMessage && !inputRequired && !emailValidator || messageType == ''" 
                (onValueChange)="message($event)" 
                [clearIcon]="true">
            </kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Hide error message'" 
                (value)="controlEvent($event, '[hideErrorMessage]=', 'true', true); hideErrorMessage = !hideErrorMessage" >
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Value change event"  (value)="controlEvent($event, '(onValueChange)=', 'valueChange($event)', false); onValueChange = !onValueChange"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Validation event"  (value)="controlEvent($event, '(onValidate)=', 'validate($event)', false); onValidate = !onValidate"></kn-toggle>
        </div>
        
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">textareaId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"textboxExample"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">name</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"textareaName"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">label</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"textarea example"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">rows</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>5</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">cols</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>50</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">maxLength</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>20</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">showCharCount</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">maxChars</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>50</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setDisabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setRequired</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">optional</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setReadOnly</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setPlaceholder</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Add text here"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customError</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customWarning</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">hideErrorMessage</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customMess</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"invalid text"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onValueChange</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">Directive</div>
                <div class="item">[(ngModel)]</div>
                <div class="item"><i>two-way binding</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <text-area-usage></text-area-usage>
    </div>
    <div designSpec>
        <text-area-specs></text-area-specs>
    </div>
    <div accessibility>
        <text-area-accessibility></text-area-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">March 14, 2024</div>
                <div class="item">1.1.2</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed function that calculates the positioning of the error container</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Feburary 9, 2024</div>
                <div class="item">1.1.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed issue with the error message styling when going to a second line</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">November 14, 2023</div>
                <div class="item">1.1.0</div>
                <div class="item">
                    <ul>
                        <li>added a property to allow you to hide the error/warning message if needed. This will still display a red outline around the textarea</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">June 15, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - set a height for the validation section to avoid pushing other elements around</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the textarea component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

