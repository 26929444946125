import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KrakenSliderComponent } from './kraken-slider.component';
import { KrakenTextboxModule } from '@kraken-ui/kraken-textbox';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    KrakenSliderComponent
  ],
  imports: [
    CommonModule,
    KrakenTextboxModule,
    FormsModule
  ],
  exports: [
    KrakenSliderComponent
  ]
})
export class KrakenSliderModule { }
