import { Component, OnInit, Input, Output, ElementRef, AfterViewInit, OnChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'kn-chip',
  templateUrl: './kraken-chip.component.html'
})
export class KrakenChipComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() componentId: any;
  @Input() label: string;
  @Input() dismissible = true;
  @Input() alwaysShowTooltip = false;
  @Input() customTooltip: string;
  @Input() fillColor: string;
  @Input() compressed = false;
  @Input() disabled = false;
  @Output() onDismiss = new EventEmitter<any>();

  public chip;
  public chipLabel;
  public containerMaxWidth = 332;
  public chipCloseIcon;
  public istooltipShown = false;
  public dismissibleWidth = 26;

  constructor(public elm: ElementRef) { }

  ngOnInit(): void {
    this.componentId = this.componentId ? this.componentId : 'knChip_' + Math.floor(Math.random()*90000) + 10000;
  }

  ngOnChanges(changes) {
    if(changes && this.chip) {
      this.isOverflow();
    }
  }

  ngAfterViewInit(): void {
    this.chip = this.elm.nativeElement.querySelector('#' + this.componentId);
    this.chipLabel = this.elm.nativeElement.querySelector('#' + this.componentId +' > .kn-chip-body > .kn-chip-label');

    setTimeout(() => {
      this.isOverflow();
    }, 100);
  }
  

  isOverflow() {
    const labelWidth = this.displayTextWidth(this.label, '14px Avenir-Heavy');

    const closeIconWidth = this.dismissible ? this.dismissibleWidth : 0;
    
    const containerWidth = this.dismissible ? this.containerMaxWidth - closeIconWidth : this.containerMaxWidth;

    if(labelWidth > containerWidth) {
      //show tooltip
      this.istooltipShown = true;

    } else {
      //hide tooltip unless use sets tooltip to always show
      if(this.alwaysShowTooltip == true) {
        this.istooltipShown = true;
      } else {
        this.istooltipShown = false;
      }
    }
  }

  displayTextWidth(text, font) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    const formattedWidth = Math.ceil(metrics.width)
    return formattedWidth;
  }

  close() {
    this.chip.remove();
    this.onDismiss.emit(
      {
        label: this.label,
        id: this.componentId
      }
    );
  }

}
