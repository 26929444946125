import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';
@Component({
  selector: 'button-accessibility',
  templateUrl: './button-accessibility.component.html',
  styleUrls: ['./button-accessibility.component.sass']
})
export class ButtonAccessibilityComponent implements OnInit {
  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;
  
  constructor(private templateService: TemplateUpdateService) { }

  ngOnInit(): void {
    
  }

}
