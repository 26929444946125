<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Development Resources</div>
        
        <section>
            <div class="text">This website offers several methods for supporting the consumption of Kraken DS code components in your development project. As a Developer you have access to the following for each individual component:</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Live component demo</div>
        </section>

        <section>
            <div class="text">Each component page includes a live demonstration of the functional component for reference. Within this demonstration space, the Developer is able to view an Angular or Web Component source code example for the component, associated Javascript where applicable, as well as any Events that are fired by the component. The component demo display may be altered to exhibit custom labeling, different states, and available configurations through iteration with a selection of controls. As the demo component is modified, the live working example dynamically updates to reflect these changes as does the visible source code example.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Properties</div>
        </section>
        
        <section>
            <div class="text">To assist the Developer, a summary of component Properties is provided. It contains a definition of the Decorator, Property, Type and an Example Value for reference.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Installation guidelines</div>
        </section>
        
        <section>
            <div class="text">The Installation Guide for the component gives the Developer simple instructions for downloading and installing the Kraken DS code component NPM package for use in their project and all related setup information.</div>
        </section>
    </div>
</div>