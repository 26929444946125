<div class="page-container">
    <div class="section-container palettes">
        <div class="kn-page-title">Palettes</div>
        <section>
            <div class="text">Color plays a key component in the Lighthouse visual identity. Colors are carefully selected and implemented in a way that is deliberate, precise, and meaningful. </div>
        </section>
       
        <div class="kn-headline-24-med">Light theme</div>

        <section>
            <div class="kn-subtitle-16">Grayscale</div>
            <div class="text">The grayscale color palette is used for text, backgrounds, dividers and iconography. The palette is used to support hierarchy on the page and communicates the importance of content to our users.</div>
        </section>

        <div class="color-palette">
            <div class="palette-header">
                <div class="title">Color</div>
                <div class="title">Theme variable</div>
                <div class="title">CSS variable</div>
            </div>
            <div class="palette">
                <div *ngFor="let item of gray">
                    <div class="code-block" [ngStyle]="{'background-color': item.hexCode, 'color': item.textColor}">
                        <div class="color-info">
                            <div class="color"><span class="value">{{item.colorName}} {{item.hexCode}}</span></div>
                            <div class="color"><span class="value">{{item.themeVar}}</span></div>
                            <div class="color"><span class="value">{{item.CSSVar}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section>
            <div class="kn-subtitle-16">Primary color palette</div>
            <div class="text">The cornerstone of the color palette is the Lighthouse Primary blue, variations of which comprise the primary color palette. Values from this palette are utilized to establish the hierarchy and emphasis for key elements displayed in the application UI.</div>
        </section>

        <div class="color-palette">
            <div class="palette-header">
                <div class="title">Color</div>
                <div class="title">Theme variable</div>
                <div class="title">CSS variable</div>
            </div>
            <div class="palette">
                <div *ngFor="let item of primary">
                    <div class="code-block" [ngStyle]="{'background-color': item.hexCode, 'color': item.textColor}">
                        <div class="color-info">
                            <div class="color"><span class="value">{{item.colorName}} {{item.hexCode}}</span></div>
                            <div class="color"><span class="value">{{item.themeVar}}</span></div>
                            <div class="color"><span class="value">{{item.CSSVar}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <section>
            <div class="kn-subtitle-16">Secondary color palette</div>
            <div class="text">The secondary color palette represents a complementary hue to the primary values. The values of this alternate hue are used to provide emphasis and highlight content where needed.</div>
        </section>

        <div class="color-palette">
            <div class="palette-header">
                <div class="title">Color</div>
                <div class="title">Theme variable</div>
                <div class="title">CSS variable</div>
            </div>
            <div class="palette">
                <div *ngFor="let item of secondary">
                    <div class="code-block" [ngStyle]="{'background-color': item.hexCode, 'color': item.textColor}">
                        <div class="color-info">
                            <div class="color"><span class="value">{{item.colorName}} {{item.hexCode}}</span></div>
                            <div class="color"><span class="value">{{item.themeVar}}</span></div>
                            <div class="color"><span class="value">{{item.CSSVar}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section>
            <div class="kn-subtitle-16">Red color palette</div>
        </section>

        <div class="color-palette">
            <div class="palette-header">
                <div class="title">Color</div>
                <div class="title">Theme variable</div>
                <div class="title">CSS variable</div>
            </div>
            <div class="palette">
                <div *ngFor="let item of danger">
                    <div class="code-block" [ngStyle]="{'background-color': item.hexCode, 'color': item.textColor}">
                        <div class="color-info">
                            <div class="color"><span class="value">{{item.colorName}} {{item.hexCode}}</span></div>
                            <div class="color"><span class="value">{{item.themeVar}}</span></div>
                            <div class="color"><span class="value">{{item.CSSVar}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section>
            <div class="kn-subtitle-16">Green color palette</div>
        </section>

        <div class="color-palette">
            <div class="palette-header">
                <div class="title">Color</div>
                <div class="title">Theme variable</div>
                <div class="title">CSS variable</div>
            </div>
            <div class="palette">
                <div *ngFor="let item of green">
                    <div class="code-block" [ngStyle]="{'background-color': item.hexCode, 'color': item.textColor}">
                        <div class="color-info">
                            <div class="color"><span class="value">{{item.colorName}} {{item.hexCode}}</span></div>
                            <div class="color"><span class="value">{{item.themeVar}}</span></div>
                            <div class="color"><span class="value">{{item.CSSVar}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section>
            <div class="kn-subtitle-16">Extended color palette</div>
        </section>

        <div class="color-palette">
            <div class="palette-header">
                <div class="title">Color</div>
                <div class="title">Theme variable</div>
                <div class="title">CSS variable</div>
            </div>
            <div class="palette">
                <div *ngFor="let item of yellow">
                    <div class="code-block" [ngStyle]="{'background-color': item.hexCode, 'color': item.textColor}">
                        <div class="color-info">
                            <div class="color"><span class="value">{{item.colorName}} {{item.hexCode}}</span></div>
                            <div class="color"><span class="value">{{item.themeVar}}</span></div>
                            <div class="color"><span class="value">{{item.CSSVar}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <div class="color-palette">
            <div class="palette-header">
                <div class="title">Color</div>
                <div class="title">Theme variable</div>
                <div class="title">CSS variable</div>
            </div>
            <div class="palette">
                <div *ngFor="let item of teal">
                    <div class="code-block" [ngStyle]="{'background-color': item.hexCode, 'color': item.textColor}">
                        <div class="color-info">
                            <div class="color"><span class="value">{{item.colorName}} {{item.hexCode}}</span></div>
                            <div class="color"><span class="value">{{item.themeVar}}</span></div>
                            <div class="color"><span class="value">{{item.CSSVar}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <div class="color-palette">
            <div class="palette-header">
                <div class="title">Color</div>
                <div class="title">Theme variable</div>
                <div class="title">CSS variable</div>
            </div>
            <div class="palette">
                <div *ngFor="let item of indigo">
                    <div class="code-block" [ngStyle]="{'background-color': item.hexCode, 'color': item.textColor}">
                        <div class="color-info">
                            <div class="color"><span class="value">{{item.colorName}} {{item.hexCode}}</span></div>
                            <div class="color"><span class="value">{{item.themeVar}}</span></div>
                            <div class="color"><span class="value">{{item.CSSVar}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <br/>
        <div class="color-palette">
            <div class="palette-header">
                <div class="title">Color</div>
                <div class="title">Theme variable</div>
                <div class="title">CSS variable</div>
            </div>
            <div class="palette">
                <div *ngFor="let item of purple">
                    <div class="code-block" [ngStyle]="{'background-color': item.hexCode, 'color': item.textColor}">
                        <div class="color-info">
                            <div class="color"><span class="value">{{item.colorName}} {{item.hexCode}}</span></div>
                            <div class="color"><span class="value">{{item.themeVar}}</span></div>
                            <div class="color"><span class="value">{{item.CSSVar}}</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>