import { Component } from '@angular/core';

@Component({
  selector: 'slider-specs',
  templateUrl: './slider-specs.component.html',
  styleUrls: ['./slider-specs.component.sass']
})
export class SliderSpecsComponent {
  public component = window.location.protocol + '//' + window.location.host + '/design-specs-measure/slider/';
  constructor() { }

  ngOnInit(): void {
  }

  clickEvent(route) {
    if(route == 'light') {
      window.open(this.component, "_blank");
    }
  }
}
