import { Component } from '@angular/core';

@Component({
  selector: 'spin-box-usage',
  templateUrl: './spin-box-usage.component.html',
  styleUrls: ['./spin-box-usage.component.sass']
})
export class SpinBoxUsageComponent {

}
