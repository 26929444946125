<div 
  id="{{componentId}}"
  class="kn-slider"
  [ngClass]="{
    'multi-thumb': multiSlider,
    'disabled': disabled,
    'read-only': readOnly,
    'hide-textbox': !showTextbox
  }"
  >
  <div class="kn-slider-label">{{label}}</div>
  <div class="slider-inner-container">
    <div 
      class="kn-range-textbox" 
      *ngIf="showTextbox && steps <= 0"
      [ngStyle]="{'order': !multiSlider ? 1 : 0}"
    >
      <kn-textbox 
        [textboxId]="componentId + '_minValue'" 
        [(ngModel)]="minTextboxValue"
        (onValueChange)="minTextboxEvent($event)"
        [name]="'minvalue'"
        [maxLength]="4"
        [minLength]="1"
        [customError]="minError"
        [setDisabled]="disabled"
        [setReadOnly]="readOnly"
        [hideErrorMessage]="hideErrorMess"
        [numericCharsOnly]="true"
        [ariaLabel]="'min textbox'">
      </kn-textbox>
    </div>
    <div class="kn-slider-container" *ngIf="!multiSlider">
      <div class="tooltip min top"></div>
      <div 
        class="range" 
        [ngClass]="{
          'with-steps': steps > 0
        }"
        [ngStyle]="{
          '--step' : steps, 
          '--min' : minRange, 
          '--max' : maxRange,
          '--thumbPos': minValue,
          '--sliderWidth': sliderWidth
        }"
      >
        <input 
          class="slider min" 
          [ngStyle]="
          {
            'width': width + 'px'
          }" 
          aria-label="min-slider-input"
          type="range" 
          min="{{minRange}}" 
          max="{{maxRange}}" 
          [step]="steps" 
          value="{{minValue}}" 
          [(ngModel)]="minValue"
          (change)="progressEvent()"
          (input)="progressEvent()"
          [disabled]="disabled || errorMess || readOnly"/>
      </div>
    </div>

    <!--Multi thumb slider-->
    <div 
      class="kn-multi-range-container" 
      *ngIf="multiSlider"
    >
      <div class="tooltip min top"></div>
      <div class="tooltip max top"></div>
      <div class="range-slider">
        <span 
          class="range-selected"
        >
        </span>
      </div>
      <div 
        class="range-input"
        [ngStyle]="{
          '--step' : steps, 
          '--min' : minRange, 
          '--max' : maxRange,
          'width': width + 'px'
        }"
      >
        <div class="ticks" *ngIf=" steps > 0"></div>
        <input 
          type="range" 
          class="min" 
          aria-label="min-slider-input"
          min="{{minRange}}" 
          max="{{maxRange}}"  
          value="{{minValue}}"
          (change)="multiSliderProgressEvent()"
          (input)="multiSliderProgressEvent()"
          step="{{steps}}"
          [disabled]="disabled || errorMess || readOnly">
        <input 
          type="range" 
          class="max" 
          aria-label="max-slider-input"
          min="{{minRange}}" 
          max="{{maxRange}}" 
          value="{{maxValue}}"
          (change)="multiSliderProgressEvent()"
          (input)="multiSliderProgressEvent()"
          [step]="steps"
          [disabled]="disabled || errorMess || readOnly">
      </div>
    </div> 
    <div class="kn-range-textbox" *ngIf="showTextbox && multiSlider && steps <= 0">
      <kn-textbox 
        [textboxId]="componentId + '_maxValue'" 
        [(ngModel)]="maxTextboxValue" 
        (onValueChange)="maxTextboxEvent($event)"
        [name]="'maxvalue'"
        [minLength]="1"
        [maxLength]="4"
        [customError]="maxError"
        [setDisabled]="disabled"
        [setReadOnly]="readOnly"
        [hideErrorMessage]="hideErrorMess"
        [numericCharsOnly]="true"
        [ariaLabel]="'max textbox'">
      </kn-textbox>
    </div>
  </div>
  <div class="kn-error" *ngIf="minError || maxError"><i class="fa-solid fa-triangle-exclamation kn-icon-sm"></i>{{errorMess}}</div>
</div>