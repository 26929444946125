import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fonts',
  templateUrl: './fonts.component.html',
  styleUrls: ['./fonts.component.sass']
})
export class FontsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
