<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">UI Framework</div>
       
        <section>
            <div class="kn-headline-24-med">Angular </div>
        </section>

        <section>
            <div class="text">Kraken DS components are custom developed for Lighthouse utilizing the Angular UI framework. Working with Angular enables the Kraken DS team to produce an entire library of lightweight UI components that are robust, modular, reusable, and designed to specifically meet the UX and business needs of the Lighthouse product portfolio.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Web components </div>
        </section>

        <section>
            <div class="text">For Lighthouse teams that have chosen an alternate UI framework for their project other than Angular, the Kraken DS also produces agnostic Web Component (HTML5) code. These encapsulated and reusable HTML elements enable identical functionality and styling as the Angular component in a format that can be implemented in any SASS web application project, even if running an alternate UI framework such as REACT, VUE, etc. </div>
        </section>

        <section>
            <div class="kn-headline-24-med">Syncfusion </div>
        </section>

        <section>
            <div class="text">While the Kraken DS aims to produce a comprehensive library of customized UI components to specifically support Lighthouse product UX patterns, there does exist a curated selection of components for which the strategic decision was made to leverage a licensed 3rd party library. By doing so, we were able to save thousands of potential development hours and greatly escalate the release of this needed functionality to Lighthouse product development teams via the Kraken DS.</div>
        </section>

        <section>
            After rigorous research and due diligence, the <a class="kn-link" href="https://www.syncfusion.com/" target="_blank"> Syncfusion UI </a> framework library (www.syncfusion.com) was chosen to support these select components. The Kraken DS team has successfully leveraged the Syncfusion (Angular) library components and extended them to align with Kraken DS styles and interaction patterns. To date, the select Kraken DS components that are built on top of Syncfusion include: 
        </section>

        <section>
            <ul class="bullets">
                <li><span class="kn-bold">Data grid</span></li>
                <li><span class="kn-bold">WYSIWYG editor</span></li>
            </ul>
        </section>
       
    </div>
</div>