
<div class="canvas-inner-container" id="designSpec">
    <div class="action-btns">
        <div class="action-item">
            <kn-button 
                [id]="'knActionButtonDesignSpecLightTheme'"
                [buttonType]="'kn-primary'" 
                [buttonText]="'PRODUCTION REDLINES'" 
                (btnClick)="clickEvent('light')">
            </kn-button></div>
        <!-- <div class="action-item">
            <kn-button 
                [id]="'knActionButtonDesignSpecLightTheme'"
                [buttonType]="'kn-primary'" 
                [buttonText]="'Dark theme redlines'" 
                (btnClick)="clickEvent('dark')">
            </kn-button>
        </div> -->
    </div>
    <img class="kn-responsive-img" src="assets/design-specs/progressindicator/Progress Indicator-LightTheme.png">
</div>
