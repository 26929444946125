import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef, AfterViewInit, ElementRef } from '@angular/core';

@Component({
  selector: 'kn-spin-box',
  templateUrl: './kraken-spin-box.component.html'
})
export class KrakenSpinBoxComponent implements OnChanges, AfterViewInit {
  @Input() spinBoxId: string;
  @Input() name: string;
  @Input() disabled = false;
  @Input() label: string;
  @Input() hasError = false;
  @Input() value: any = 0;
  @Input() required = false;
  @Input() readOnly = false;
  @Input() customErrorMessage: string;
  @Input() compressed = false;
  @Input() layout = 'vertical right';
  @Input() increment = 1;
  @Input() controls = 'default';
  @Input() minValue: any;
  @Input() maxValue: any;
  @Input() valueAlignRight = false;
  @Output() onValueChange = new EventEmitter<any>();

  public incrementInterval;
  public disableDown = false;
  public disableUp = false;
  public error = false;
  public errorMessage = '';
  public spinbox;
  public spinboxLeftPos;
  public spinboxBottomPos;
  public spinboxWidth;

  constructor(public ref: ChangeDetectorRef, public elm: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasError) {
      this.error = changes.hasError.currentValue;
      this.errorMessage = this.customErrorMessage ? this.customErrorMessage : this.errorMessage;
      this.ref.detectChanges();
    }
  }

  ngAfterViewInit(): void {
    this.spinbox = this.elm.nativeElement.querySelector('INPUT');
    this.arrowBtnHandler();

    window.addEventListener('scroll', (e) => {
      this.spinboxPosHandler();
    },true);

    window.addEventListener('resize', () => {
      this.spinboxPosHandler();
    });
  }

  valueChangeEvent(event: any) {
    this.errorHandler();
    this.onValueChange.emit(event);
  }

  startIncrement(direction) {
    if (this.incrementInterval) {
      clearInterval(this.incrementInterval);
    }
    this.incrementInterval = setInterval(() => {
      this.value = Number(this.value);
      direction === 'up' ? this.value += Number(this.increment) : this.value -= Number(this.increment);
      //this.value = Number(this.value.toFixed(2));
      this.arrowBtnHandler();
  
      // Determine if the value is within the min and max boundaries or if there are no boundaries
      const isWithinMinBoundary = this.minValue === undefined || this.minValue <= this.value;
      const isWithinMaxBoundary = this.maxValue === undefined || this.maxValue >= this.value;
  
      // Update the error flag based on the boundaries
      if (isWithinMinBoundary && isWithinMaxBoundary) {
        this.error = false;
      }
  
      // Stop the interval if the value reaches or exceeds min/max
      if ((this.disableUp && direction === 'up') || (this.disableDown && direction === 'down')) {
        this.stopIncrement();
      }
  
      // Emit the value
      this.onValueChange.emit(this.value);
    }, 80);
  }
  

  stopIncrement() {
    if (this.incrementInterval) {
      clearInterval(this.incrementInterval);
      this.incrementInterval = null;
    }
  }

  // Function to check if the input is a valid number considering '-' and '.'
  isValidNumber(input) {
    // A regex to validate a number (integer or decimal, positive or negative)
    return /^-?\d*(\.\d+)?$/.test(input);
  }

  errorHandler() {
    // Initialize error and disable flags as false
    this.error = false;
    this.disableDown = false;
    this.disableUp = false;

    // Validate inputs
    const isMinValueValid = this.minValue === undefined || this.isValidNumber(this.minValue);
    const isMaxValueValid = this.maxValue === undefined || this.isValidNumber(this.maxValue);
    const isValueValid = this.isValidNumber(this.value);

    if (isMinValueValid && isMaxValueValid && isValueValid) {
        // Parse numbers
        const minValueNumber = this.minValue !== undefined ? parseFloat(this.minValue) : undefined;
        const maxValueNumber = this.maxValue !== undefined ? parseFloat(this.maxValue) : undefined;
        const valueNumber = parseFloat(this.value);

        // Check against minValue
        if (minValueNumber !== undefined && minValueNumber > valueNumber) {
            this.error = true;
            this.disableDown = true;
        } else if (minValueNumber !== undefined && minValueNumber < valueNumber) {
            this.disableDown = false;
        }

        // Check against maxValue
        if (maxValueNumber !== undefined && maxValueNumber < valueNumber) {
            this.error = true;
            this.disableUp = true;
        } else if (maxValueNumber !== undefined && maxValueNumber > valueNumber) {
            this.disableUp = false;
        }
    } else {
        // Handle invalid input case, maybe keep error and disable flags as is or set them to a default state
        this.error = true; // Set error to true if any input is invalid
    }

    // Handle empty value case
    if (this.value === '') {
        this.error = true;
        this.errorMessage = 'A value is required'; 
    } else {
        // Reset the error message to default value
        this.errorMessage = 'Invalid value'; 
    }
  }

  arrowBtnHandler() {
    const minValueNumber = this.minValue !== undefined ? parseFloat(this.minValue) : undefined;
    const maxValueNumber = this.maxValue !== undefined ? parseFloat(this.maxValue) : undefined;
    const valueNumber = parseFloat(this.value);
    // Disable button if value is at min or max
    this.disableUp = valueNumber >= maxValueNumber;
    this.disableDown = valueNumber <= minValueNumber;
  }

  spinboxPosHandler() {
    this.spinboxLeftPos = this.spinbox.getBoundingClientRect().left + 'px';
    this.spinboxBottomPos = this.spinbox.getBoundingClientRect().bottom + 'px';
    this.spinboxWidth = this.spinbox.getBoundingClientRect().width + 'px';
  }
}
