<app-canvas 
    [js]="true"  
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component">
    <div title>Slider <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
       <kn-slider 
            [label]="sliderLabel"
            [minRange]="minRange"
            [maxRange]="maxRange"
            [minValue]="minValue"
            [maxValue]="maxValue"
            [value]="value"
            [showTextbox]="showTextbox"
            [multiSlider]="multiSlider"
            [rangeThreshold]="rangeThreshold"
            [steps]="steps"
            [disabled]="disabled"
            [readOnly]="readOnly"
            (minValueEvent)="minValueEvent($event)"
            (maxValueEvent)="maxValueEvent($event)">
        </kn-slider>
    </div>
    <div angular>
        
    </div>
    <div typeScript>
       
    </div>
    <div javascript>
        <span class="code-comment">//You bind to the minVale and maxValue events as shown below</span>
        {{webSliderEvent}}
    </div>
    <div web></div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">minValueEvent():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">value:</span> {{minEventValue}}</div>
                </div>
            </div>
            <div class="event-item" *ngIf="multiSlider" [ngClass]="{'active': event2}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event2 = !event2"></i> 
                <div class="obj">maxValueEvent():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">value:</span> {{maxEventValue}}</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-input">
            <kn-textbox
                [label]="'Slider Label'" 
                [(ngModel)]="sliderLabel" 
                [clearIcon]="true"
                (onValueChange)="labelText($event)">
            </kn-textbox>
        </div>
        <div class="section-title">Modifiers</div>
        <div class="kn-spacer"></div>
        <div class="action-input">
            <kn-textbox 
                [label]="'Slider Increments'" 
                [value]="steps" 
                [type]="'number'"
                [(ngModel)]="steps"
                (onValueChange)="stepsEvent($event)" 
                [minNumber]="0"
                [clearIcon]="true"
                (onClear)="onClear()">
            </kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Show textbox'" 
                (value)="controlEvent($event, '[showTextbox]=', true, true);showTextbox = $event" 
                [(ngModel)]="showTextbox"
                name="hideTexbox"
                [disabled]="steps > 1">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Multi handle'" 
                (value)="multiRangeEvent($event)" 
                [(ngModel)]="multiSlider"
                name="dismissible">
            </kn-toggle>
        </div>
        <!-- <div class="action-input">
            <kn-textbox 
                [label]="'Range threshold'" 
                [value]="rangeThreshold" 
                [type]="'number'"
                [(ngModel)]="rangeThreshold"
                (onValueChange)="thresholdEvent($event)" 
                [minNumber]="0"
                [clearIcon]="true"
                [setDisabled]="!multiSlider">
            </kn-textbox>
        </div> -->
        <div class="item-row">
            <kn-toggle 
                [label]="'Disabled state'" 
                (value)="controlEvent($event, '[disabled]=', true, true);" 
                [(ngModel)]="disabled"
                [disabled]="readOnly"
                name="disabled">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Read-only state'" 
                (value)="controlEvent($event, '[readOnly]=', true, true);" 
                [(ngModel)]="readOnly"
                [disabled]="disabled"
                name="readonly">
            </kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">componentId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"exampleSlider"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">label</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"This is a label"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">minRange</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>0</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">maxRange</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>100</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">minValue</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>30</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">maxValue</div>
                <div class="item"><i>number</i></div>
                <div class="item">80</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">steps</div>
                <div class="item"><i>number</i></div>
                <div class="item">10</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">width</div>
                <div class="item"><i>string</i></div>
                <div class="item">400</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">multiSlider</div>
                <div class="item"><i>boolean</i></div>
                <div class="item">true</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">showTextbox</div>
                <div class="item"><i>boolean</i></div>
                <div class="item">true</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">rangeThreshold</div>
                <div class="item"><i>number</i></div>
                <div class="item">10</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">disabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item">true</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">readOnly</div>
                <div class="item"><i>boolean</i></div>
                <div class="item">true</div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">minValueEvent</div>
                <div class="item"><i>event</i></div>
                <div class="item">{{'{}'}}</div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">maxValueEvent</div>
                <div class="item"><i>event</i></div>
                <div class="item">{{'{}'}}</div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <slider-usage></slider-usage>
    </div>
    <div designSpec>
        <slider-specs></slider-specs>
    </div>
    <div accessibility>
        <slider-accessibility></slider-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">March 14, 2024</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>Fixed linting errors</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">December 6, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed issue with textbox error message showing under the textbox.</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">November 17, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed issue with using decimal values for steps.</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">November 14, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created Slider component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>