<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Iconography</div>
        
        <section>
            <div class="kn-headline-24-med">On light background</div>
        </section>
        
        <section>
            <div class="text">When icon graphics are presented directly on the application UI canvas or in panels and cards with light backgrounds, the following values are utilized for the icon fill color based on the specific use case being supported by the icon.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/iconography/KrakenDSM_StyleGuide_Color_Iconography_01.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/iconography/KrakenDSM_StyleGuide_Color_Iconography_02.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Enabled</span></div>
            <div class="text"><span class="kn-bold">04. Ink, Light #434C54 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$ink-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--ink-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Hover</span></div>
            <div class="text"><span class="kn-bold">03. Primary #205D86 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Disabled</span></div>
            <div class="text"><span class="kn-bold">07. Gray, Darker #A5B2BD </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$gray-darker</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--gray-darker</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Error</span></div>
            <div class="text"><span class="kn-bold">03. Danger #A50D08 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$knDanger</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-danger</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Warning</span></div>
            <div class="text"><span class="kn-bold">03. Secondary #B8551E </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$secondary_one</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--secondary_one</code></pre></span>
        </section>

        <section>
            <div class="kn-headline-24-med">On dark background</div>
        </section>

        <section>
            <div class="text">Alternatively, when the application icons are displayed on a panel or card with a dark value background, the following light grayscale values may be used to ensure sufficient contrast and legibility.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/iconography/KrakenDSM_StyleGuide_Color_Iconography_03.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/iconography/KrakenDSM_StyleGuide_Color_Iconography_04.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Enabled</span></div>
            <div class="text"><span class="kn-bold">14. White #FFFFFF </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$white</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--white</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Hover</span></div>
            <div class="text"><span class="kn-bold">14. White #FFFFFF </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$white</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--white</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Disabled</span></div>
            <div class="text"><span class="kn-bold">07. Gray, Darker #A5B2BD </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$gray-darker</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--gray-darker</code></pre></span>
        </section>

    </div>
</div>