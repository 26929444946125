
<input kn-input-group="first" type="checkbox" hidden [attr.id]="id" />
<label 
    #label
    class="active" 
    [ngClass]="{
        'disabled': disabled,
        'no-child': disabled
    }"
    [attr.for]="id"
    kn-group="first" 
    (click)="select($event)" 
    [routerLinkActive]="['kn-acc-active-link']" 
    [routerLink]="ngRouterLink ? ngRouterLink : undefined">
    <i class="fa fa-chevron-down kn-icon" *ngIf="hasContent != false"></i>
    <div class="kn-title-container">
        <div class="kn-acc-title">
            <i *ngIf="icon" class="{{icon}}"></i>
            <ng-content select="[title]"></ng-content>
        </div>
        <div class="sub-title">
            <ng-content select="[sub-title]"></ng-content>
        </div>
    </div>
</label>
<div #contentContainer class="kn-tier-one-list kn-acc-item" id="{{id}}_item" *ngIf="hasContent != false">
    <ng-content select="[content]"></ng-content>
</div>


