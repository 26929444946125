import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'searchbox-usage',
  templateUrl: './searchbox-usage.component.html',
  styleUrls: ['./searchbox-usage.component.sass']
})
export class SearchboxUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
