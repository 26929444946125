<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text"><span class="kn-bold">Toasts </span> inform users about events in the application with brief, temporary notifications that appear at the top center of the application viewport window. Toasts are designed to be noticed without disrupting a user's experience or requiring an action to be taken. </div>
            </section>
       
        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/toast/KrakenDSM_Toast_01.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Confirmation variant: </span>Informs users about a successful action or completion of a task. The confirmation variant uses the positive semantic color (green) and includes a checkmark icon.</div>
            <div class="text"><span class="kn-bold">2.	Destructive variant: </span>Displays a gray background and checkmark icon. The destructive variant confirms the success of a destructive action, which can include acknowledging actions such as deleting a user or deleting a file. </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/toast/KrakenDSM_Toast_02.png">

        <section>
            <div class="text"><span class="kn-bold">A.	Icon: </span>Informs users the kind of notification at a glance. </div>
            <div class="text"><span class="kn-bold">B.	Emphasized text: </span>Highlights critical information. </div>
            <div class="text"><span class="kn-bold">C.	Message text: </span>Includes text to communicate a message. </div>
            <div class="text"><span class="kn-bold">D.	Background </span> </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>
 
        <section>
            <section>
                <div class="kn-subtitle-16">When to use</div>

                <ul class="bullets">
                    <li>To confirm that a user successfully triggers an action.</li>
                    <li>To avoid interrupting users while they are performing an action.</li>
                </ul>
            </section>
        </section>

        <section>
            <section>
                <div class="kn-subtitle-16">When not to use</div>

                <ul class="bullets">
                    <li>Displaying error or warning messages.</li>
                    <li>Interrupting users when performing actions.</li>
                    <li>Confirming that a user reads the message before leaving the page.</li>
                </ul>
            </section>
        </section>


        <section>
            <div class="kn-subtitle-16">Main elements</div>
            
            <section>
                <div class="text"><span class="kn-bold">Message text </span> </div>

                <div class="text">All toast notifications must include message text to communicate the context of the notification. Ensure messages are concise and informative with an instructive tone. </div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">Emphasized text </span> </div>

                <div class="text">Use emphasized text sparingly to highlight critical information that relates to performed application actions. </div>
            </section>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/toast/KrakenDSM_Toast_03.png">

            <section>
                <div class="kn-subtitle-16">Size</div>
                
                <ul class="bullets">
                    <li>The minimum toast width is <span class="kn-bold">200px</span> (regardeless of how few characters are used in the toast label).</li>
                    <li>The maximum toast width is <span class="kn-bold">440px</span>. When the toast label exceeds the maximum horizontal width, it wraps to form another line.</li>
                </ul>
            </section>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/toast/KrakenDSM_Toast_04.png">

            <section>
                <div class="kn-subtitle-16">Position</div>

                <div class="text">Display toast notifications in a fixed position <span class="kn-bold">6px</span> beneath the application masthead and vertically aligned to the top. This location minimizes interference with the user's current task while ensuring the notifications remain noticeable. </div>
            </section>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/toast/KrakenDSM_Toast_05.png">

            <section>
                <div class="kn-subtitle-16">Stacking</div>

                <div class="text">When displaying multiple toast notifications on a page, whether consecutively or simultaneously triggered, they are stacked using consistent spacing and timing for each notification. As each subsequent notification displays, it takes over the first (top) position, which transitions older notifications to the next lower position. </div>
            </section>
        </section>


        <section>
            <section>
                <div class="kn-subtitle-16">Behavior</div>
                

                <div class="text">Toast notifications fade in and fade out and are dismissed automatically after a short duration of 4 seconds on the screen. The timing increases slightly for concurrent toasts. </div>
            </section>
                
            <section>    
                <ul class="bullets">
                    <li><span class="kn-bold">Single toast:</span> Fades in 400ms, persists for 5000ms, fades out 400ms</li>
                    <li><span class="kn-bold">Concurrent toasts:</span> Fades in 400ms, persists for 7000ms, fades out 400ms</li>
                </ul>
            </section>
        </section>


        <section>
            <section>
                <div class="kn-subtitle-16">Rounded corners</div>

                <div class="text">Toast notifications are displayed with a <span class="kn-bold">4px</span> rounded corner. </div>
            </section>
        </section>



        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Use for form validation</div>

            <div class="text">Avoid using system notifications to indicate errors associated with validating form fields when submitted.</div>
        </section>
    </div>
</div>