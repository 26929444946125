<div class="kn-input-contain kn-dropdown-container" [ngClass]="{'disabled': setDisabled, 'readonly': setReadOnly, 'kn-sm' : compressed, 'no-label': !label && !compressed}">
    <div class="inner">
        <!--Input box-->
        <div class="kn-field-validation" 
            *ngIf="!setDisabled && !setReadOnly"
            [style.left]="dropdownLeftPos" 
            [style.top]="dropdownBottomPos"
            [style.width]="dropdownWidth"
        >
            <kraken-field-validation 
                [field]="inputField" 
                [fieldName]="name" 
                [customWarning]="customWarning" 
                [customError]="customError" 
                [customMess]="customMess"
                [compressed]="compressed">
            </kraken-field-validation>
        </div>
        <div class="kn-dropdown-wrapper" [ngStyle]="{'width': width}">
            <input 
                #inputField="ngModel" 
                id="{{dropdownId}}"
                class="kn-form-control kn-select" 
                [ngClass]="{'input-error': 
                inputField.invalid && (inputField.dirty || inputField.touched) ||
                customError,
                'input-warning': customWarning}"
                [value]="value" 
                [(ngModel)]="value"  
                type="text" 
                (keydown)="keydown($event)"
                [attr.aria-label]="label ? label : placeholder" 
                placeholder="{{placeholder}}"
                required="{{setRequired}}"
                [readonly]="setReadOnly"
                (focusout)="onValidation()"
                autocomplete="off"
                tabindex="0"
                name="{{name}}"/>
            <!--Dropdown icon-->
            <div class="dropdown-icon" 
                [ngClass]="{'rotate': showDropdown, 'kn-sm': compressed}"
                tabindex="0"
                (keydown)="keydown($event)">
                <i class="fa-solid fa-chevron-down kn-icon kn-dp-icon kn-icon" aria-hidden="true"></i>
            </div>
        
        <!--Dropdown section-->
        <div class="kn-dropdown" 
            [ngClass]="
            {
                'show': showDropdown
            }" 
            [ngStyle]="
            {
            'top': topPos, 
            'width': dropdownCustomWidth ? dropdownCustomWidth : drpdnWidth,
            'height': dropdownCustomHeight ? dropdownCustomHeight : drpdnHeight,
            'left': dropdownRightAligned ? rightAligned : 'unset'
            }">
             <!--Object arrays-->
            <ng-container *ngIf="fields">
                <ng-container  *ngFor="let item of source; let i = index">
                    <div class="kn-dropdown-item" id="{{knDropdownItemId}}_{{i}}" (click)="valueChange(item.origin)" [ngClass]="{'disabled': item.origin.disabled}">
                        <div *ngIf="!template">{{item.text}}</div>
                        <!--custom row template-->
                        <div *ngIf="template">
                            <ng-container [ngTemplateOutlet]="customTemplate" [ngTemplateOutletContext]="{data: item.origin}"></ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <!--Non Object arrays-->
            <ng-container *ngIf="!fields">
                <ng-container *ngFor="let item of items; let i = index">
                    <div class="kn-dropdown-item" id="{{knDropdownItemId}}_{{i}}" (click)="valueChange(item)">
                        <div *ngIf="!template">{{item}}</div>
                        <div *ngIf="template">
                            <ng-container [ngTemplateOutlet]="customTemplate" [ngTemplateOutletContext]="{data: item}"></ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </div>
        <label 
            *ngIf="!compressed" 
            class="label"
            [ngClass]="{'input-label-error':
                inputField.invalid && (inputField.dirty || inputField.touched) ||
                customError,
                'input-label-warning': customWarning }">
                {{label}}<span *ngIf="optional && !setRequired" class="optional-label">(Optional)</span>
        </label>
    </div>
</div>