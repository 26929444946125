import { Component, OnInit } from '@angular/core';
import  colorDataSet from '../../../services/theme-colors.json';
import  packageInfo from '../../../../../../kraken-theme/package.json';
import { DocumentationService } from '../../../services/documentation.service';
@Component({
  selector: 'app-theming',
  templateUrl: './theming.component.html',
  styleUrls: ['./theming.component.sass']
})
export class ThemingComponent implements OnInit {
  public colorList: any;
  public component = packageInfo;
  public domain = window.location.protocol + '//' + window.location.host;
  public copyText = 'Copy code snippet';
  constructor(public docService: DocumentationService) { }

  ngOnInit(): void {
    this.colorList = colorDataSet;
  }

  colorDataSet() {
    return colorDataSet;
  }

  copy() {
    navigator.clipboard.writeText(`npm install ${this.component.name}@${this.component.version}`);
    this.copyText = 'Copied!';
  }

  resetText() {
    this.copyText = 'Copy code snippet';
  }
}
