import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, ElementRef } from '@angular/core';
import {KrakenModalService} from './kraken-modal.service'

@Component({
  selector: 'kn-modal',
  templateUrl: './kraken-modal-component.html'
})
export class KrakenModalComponent implements OnInit, OnDestroy, OnChanges {
  @Input() id: string;
  @Input() size: string;
  @Input() type: string;
  @Input() title: string;
  @Input() subText: string;
  @Input() modalError: string;
  @Input() customWidth: string;
  @Output() onOpen = new EventEmitter<any>();
  @Output() onClose = new EventEmitter<any>();

  private element: any;
  private backdrop: any;

  constructor(private el: ElementRef, private modalService: KrakenModalService) { 
    this.element = el.nativeElement;
  }

  ngOnInit(): void {
    // ensure id attribute exists
    if (!this.id) {
        console.error('modal must have an id');
        return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', el => {
        if (el.target.className === 'kn-modal') {
            this.close();
        }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  ngOnChanges(): void {
   
  }

  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
    if(this.backdrop) {
      this.backdrop.remove();
    }
  }

  open(): void {
    this.backdrop = document.createElement('div');
    this.backdrop.classList.add('kn-modal-backdrop');
    document.body.appendChild(this.backdrop);
    const modal = this.element.querySelector('.kn-modal');
    modal.classList.add('kn-modal-open');
    this.onOpen.emit(true);
  }

  close(): void {
    const backdrop = document.querySelector('.kn-modal-backdrop');
    backdrop.parentNode.removeChild(backdrop);
    const modal = this.element.querySelector('.kn-modal');
    modal.classList.remove('kn-modal-open');
    this.onClose.emit(true);
  }

}
