import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'progress-indicator-specs',
  templateUrl: './progress-indicator-specs.component.html',
  styleUrls: ['./progress-indicator-specs.component.sass']
})
export class ProgressIndicatorSpecsComponent implements OnInit {
  public component = window.location.protocol + '//' + window.location.host + '/design-specs-measure/progressindicator/';
  constructor() { }

  ngOnInit(): void {
  }

  clickEvent(route) {
    if(route == 'light') {
      window.open(this.component, "_blank");
    }
  }

}
