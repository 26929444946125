<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Design Resources</div>
        <section>
        <div class="text">This website offers detailed information explaining the design of each Kraken DS component. As a Designer you have access to the following for each individual component:</div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Usage guidelines </div>
        </section>

        <section>
            <div class="text">The Usage Guidelines clearly outline the various states of the component, break down the component anatomy, describe best practices for when and how to use the component, as well as illustrate any specific cases of misuse that should be avoided.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Design specifications </div>
        </section>

        <section>
            <div class="text">Each component includes a contact sheet illustrating all approved use cases, variations, and states. The Designer may also access Production Redlines via an interactive inspection interface that details pixel sizing, spacing, color values, and typography specs for each element on the component contact sheet.</div>
        </section>
       
    </div>
</div>