<kn-masthead 
    id="'krakenMasthead'"
    [style]="'dark'"
    [compressed]="true">
    <div logo>
        <div class="company-logo" routerLink="home"></div>
    <!-- <img class="logo" src="../../assets/images/kraken_header_logo.png" routerLink="home"> -->
    </div>
    <div version>
        <span class="sitename">Kraken Design System <span class="version">v{{version}}</span></span>
    </div>
    <div rightNav>
        <!-- <kn-button 
            [buttonId]="'contactUs'" 
            [buttonType]="'kn-tertiary kn-btn-sm'" 
            [buttonText]="'Contact US'" 
            [buttonIconLeft]="'fa-solid fa-messages'"
            [routerLinkActive]="['acc-active-link']" 
            routerLink="/support/contact"
            (btnClick)="contactEvent($event)">
        </kn-button> -->
        <kn-button 
            [buttonId]="'logout'" 
            [buttonType]="'kn-tertiary kn-btn-sm'" 
            [buttonText]="'Logout'" 
            [buttonIconLeft]="'fa-solid fa-messages'" 
            (btnClick)="logout()">
        </kn-button>
    </div>
</kn-masthead>
<!-- <kn-masthead [style]="'dark'">
    <div logo>
        <img class="logo" src="../../assets/KrakenDS_LighthouseLogo_White.svg" routerLink="/">
    </div>
    <div siteName>
        <span class="sitename">Kraken Design System <span class="version">v{{version}}</span></span>
    </div> -->
    <!-- <div leftNav>
        <div class="link">Test Link 1</div>
        <div class="link">Test Link 2</div>
        <div class="link">Test Link 3</div>
        <div class="link">Test Link 4</div>
    </div> -->
    <!-- <div rightNav>
        <kn-button 
            [buttonId]="'contactUs'" 
            [buttonType]="'kn-tertiary kn-btn-sm'" 
            [buttonText]="'Contact US'" 
            [buttonIconLeft]="'fa-solid fa-messages'"
            [routerLinkActive]="['acc-active-link']" 
            routerLink="/support/contact">
        </kn-button> -->
        <!-- <kn-split-button 
            [button1Type]="'tertiary'" 
            [button2Type]="'primary'"
            [button1Icon]="'fa-solid fa-certificate'"
            [button2Icon]="'fa-regular fa-circle-half-stroke'"
            (btnClick)="selectedTheme($event)">
        </kn-split-button> -->
        <!-- <div class="link">Test Link 5</div>
        <div class="link">Test Link 6</div> -->
    <!-- </div>
</kn-masthead> -->
