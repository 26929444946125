import { Component, OnInit, ViewChild, ElementRef, ViewContainerRef } from '@angular/core';
import {
  PageService,
  ToolbarService,
  ColumnChooserService,
  SortService,
  FilterService,
  EditService,
  ExcelExportService,
  ReorderService,
  ResizeService,
  FreezeService,
  DetailRowService,
  ToolbarItems
} from '@syncfusion/ej2-angular-grids';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import  packageInfo from '../../../../../kraken-theme/package.json';
import  spinnerPackageInfo from '../../../../../kraken-spinner/package.json';
import { ChangeEventArgs } from '@syncfusion/ej2-inputs';
import { L10n } from '@syncfusion/ej2-base';

L10n.load({
  'en-US': {
    pager: {
      pagerDropDown: 'Items per page (1 page)',
      currentPageInfo: 'Showing {0} - {1}',
      totalItemsInfo: 'of {0}',
    },
  },
});

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.sass'],
  providers: [
    PageService,
    ToolbarService,
    ColumnChooserService,
    SortService,
    FilterService,
    EditService,
    ExcelExportService,
    ResizeService,
    FreezeService,
    DetailRowService,
    ReorderService
  ]
})
export class GridComponent implements OnInit {
  public angularComponentRef: any;
  public filterSettings:any;
  public pageSize = 5;
  public pageSettings: object;
  public elementTag = 'div';
  public webComponentRef;
  public angularContainer;
  public showCompressedGrid = false;
  public dropdownData = ["test", "test2", "test3"]
  public rowHightlight;
  public react = false;
  public language = 'angular';
  public columnChooserActive = false;
  public clearAllBtnDisabled = true;
  public basicGrid = true;
  public multiSelectGrid = false;
  public gridType = 'standard';
  public isLoading = false;

  public childData: any;
  public compressedGrid = "";
  public gridTemplate;
  public componentVersion = packageInfo.version;
  public selectOptions;
  public totalPages;
  public detailPanelData: any = [];
  public registeredTemplate;
  public panelTrigger: string;
  public formatoptions: object;
  public gridFilter = "CheckBox";
  public toolbarOptions?: ToolbarItems[];

  @ViewChild('detailPanel') detailPanel: any;  

  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    install: false,
    peerDependencies: [
      {"@kraken-ui/kraken-spinner": spinnerPackageInfo.version},
      {"@kraken-ui/kraken-theme": packageInfo.version}
    ], 
    module: '',
    installWarning: `This is a third party component provided by Syncfusion, please click <a class="kn-link" href="https://ej2.syncfusion.com/angular/documentation/grid/getting-started#adding-syncfusion-grid-package" target="_blank">here</a> for installation instructions`
  };
  public custodian = [
    {name:'Michael Scott'}, 
    {name:'Jim Halpert'},
    {name:'Dwight Schrute'},
    {name:'Stanley Hudson'},
    {name:'Oscar Martinez'},
    {name:'Ryan Howard'},
    {name:'Kimberly Ricci'},
    {name:'Charles Miner'}
  ]
  public custodianFields = {text: 'name', value: 'id'}
  public angularComponentOptions: any = [
    { option: 'class=', id: 0, value: '"kn-rim"'},
  ];

  public stackedHeaderRows: any;

  public angularImports =
  `import {
    PageService,
    ToolbarService,
    ColumnChooserService,
    SortService,
    FilterService,
    EditService,
    ExcelExportService,
    ReorderService,
    ResizeService,
    FreezeService,
    DetailRowService
  } from '@syncfusion/ej2-angular-grids';
import { L10n } from '@syncfusion/ej2-base';

L10n.load({
  'en-US': {
    pager: {
      pagerDropDown: 'Items per page (1 page)',
      currentPageInfo: 'Showing {0} - {1}',
      totalItemsInfo: 'of {0}',
    },
  },
});`

  public angularProviders =
  `providers: [
    PageService,
    ToolbarService,
    ColumnChooserService,
    SortService,
    FilterService,
    EditService,
    ExcelExportService,
    ResizeService,
    FreezeService,
    DetailRowService,
    ReorderService
  ]`

  public newRowFunction = 
  `newRow(event) {
  let rows = this.elm.nativeElement.querySelectorAll('.e-row');
  rows[0].classList.add('row-added');
}`
  public standardTooltip = 
`This example grid demonstration includes the following features:
<ul>
<li>single-line column header</li>
<li>standard row layout</li>
<li>detail panel</li>
<li>grid search</li>
<li>frozen column</li>
</ul>`;
  public multiSelectTooltip = 
`This example grid demonstration includes the following features:
<ul>
<li>two-line column header</li>
<li>selectable row layout</li>
</ul>`;

  public nestedTooltip = 
`This example grid demonstration includes the following features:
<ul>
<li>expandable row layout</li>
</ul>`;

public groupedTooltip =
`This example grid demonstration includes the following features:
<ul>
<li> grouped column headers</li>
</ul>`;

  public gridViewChild;


public multiSelectRowSettings = 
`this.selectOptions = { checkboxOnly: true };`

public intialize =
`ngOnInit(): void {
  let pageSizes = [25, 50, 100, 150, 200, 250, 500, 'All'];
  this.filterSettings = { type: 'CheckBox' };
  this.pageSettings = { pageSizes: pageSizes, pageSize: 25 };
}`

public webSpinner = 
`let spinner = document.querySelector('#gridSpinner');
spinner.isLoading = true;`

  public refreshGridFunction = 
`refreshGrid() {
  this.isLoading = true;
  setTimeout(() => {
    this.isLoading = false;
  }, 500);
}`
  public clearAllFunction =
`clearFilters() {
  this.grid.clearFiltering();
  this.grid.clearSorting();
}`

  public clearAllButtonDisableFunction =
`activeSortingAndFiltering(): void {
  let filterCount = this.grid.filterSettings.columns.length;
  let sortCount = this.grid.sortSettings.columns.length;
  
  if (filterCount > 0 || sortCount > 0) {
    //set clear all button to enabled state
    this.clearAllBtnDisabled = false;
  } else {
    this.clearAllBtnDisabled = true;
  }
}`

public columnChooser = 
`document.addEventListener('click', (e) => {
  let chooser = document.querySelector('.e-ccdlg');
  let target = (e.target as any);
  
  if (target.id == 'columnChooser') {
    this.columnChooserActive = true;
    var pos = target.getClientRects()[0];
    var left = ~~pos.left;
    this.grid.openColumnChooser(left);
  } 

  //check if clicking inside of the column chooser, if not, then close the chooser
  if(chooser) {
    if (!chooser.querySelector("." + target.classList[0]) && target.id != 'columnChooser') {
      let columnChooserEle = document.getElementsByClassName('e-ccdlg')[0];
      if (columnChooserEle) {
        this.columnChooserActive = false;
        let colchooser = (columnChooserEle as any).ej2_instances[0];
        colchooser.hide();
      }
    }

    //confirm or cancel buttons within the chooser
    if(target.classList.contains('e-cc_okbtn') || target.classList.contains('e-cc-cnbtn')) {
      this.columnChooserActive = false;
    }
  }
});`

public clearAllBtnUsage =
`actionComplete() {
  this.activeSortingAndFiltering();
}`

public webGridCode =
`<div class="kn-grid">
  <kn-spinner id="gridSpinner"></kn-spinner>
  <div class="kn-grid-nav">
    <div class="inner">
      <div class="item">
        <kn-button 
          button-id="refreshGridBtn" 
          button-type="kn-flat kn-btn-sm" 
          button-text="Refresh" 
          button-icon-left="fa-regular fa-arrows-rotate">
        </kn-button>
      </div>
      <div class="kn-pipe"></div>
      <div class="item">
        <kn-button 
          button-id="clearAllBtn" 
          button-type="kn-flat kn-btn-sm"  
          button-text="Clear all" 
          button-icon-left="fa-regular fa-filter-slash">
        </kn-button>
      </div>
      <div class="item">
        <kn-button 
          button-id="columnChooser" 
          active="columnChooserActive" 
          button-type="kn-flat kn-btn-sm" 
          button-text="Columns" 
          button-icon-left="fa-regular fa-gear">
        </kn-button>
      </div>
    </div>
  </div>
  <!--Refer to your language's SyncFusion documentation for adding the grid here...-->
</div>`

public gridData = 
`this.data = [
  {id: 1, FileName: 'RC0826_KYR_0921.xls', ImportDate: 'Feb 22, 2021 9:31 AM', SourceSize: '2.1 MB', Custodian: 'Jim Halpert', docs: '23,243', size: '2GB'},
  {id: 2, FileName: 'Lorem ipsum dolor sit amet consect dolore.xlsx', ImportDate: 'Feb 22, 2021 9:31 AM', SourceSize: '1.5 MB', Custodian: 'Jim Halpert', docs: '22,243', size: '4GB'},
  {id: 3, FileName: 'jim_halpert_001.pst', ImportDate: 'Feb 22, 2021 9:31 AM', SourceSize: '4.85 MB', Custodian: 'Jim Halpert', docs: '2,243', size: '6GB'},
  {id: 4, FileName: 'Examplefile1.pdf', ImportDate: 'Feb 22, 2021 9:31 AM', SourceSize: '8.3 MB', Custodian: 'Dwight Schrute', docs: '243', size: '3GB'},
  {id: 5, FileName: 'SalesInvoice_Europe_Q3.pdf', ImportDate: 'Nov 01, 2020 12:05 PM', SourceSize: '2.03 MB', Custodian: 'Stanley Hudson', docs: '23,243', size: '2GB'},
  {id: 6, FileName: 'EarningsForecast_2020.doc', ImportDate: 'Nov 01, 2020 12:05 PM', SourceSize: '2.44 MB', Custodian: 'Stanley Hudson', docs: '25,243', size: '5GB'},
  {id: 7, FileName: 'ReferenceFile_01.zip', ImportDate: 'Oct 10, 2020 3:21 PM', SourceSize: '0.98 MB', Custodian: 'Angela Martin', docs: '23,663', size: '6GB'},
  {id: 8, FileName: 'meredith_palmer_metadata.pdf', ImportDate: 'Oct 10, 2020 3:21 PM', SourceSize: '3.5 MB', Custodian: 'Meredith Palmer', docs: '23,243', size: '2GB'},
  {id: 9, FileName: 'native_doc_1.docx', ImportDate: 'Sep 28, 2020 1:05 PM', SourceSize: '2.24 MB', Custodian: 'Phyllis Vance', docs: '23,343', size: '1GB'},
  {id: 10, FileName: 'test022020.zip', ImportDate: 'Sep 26, 2020 07:42 AM', SourceSize: '4.59 MB', Custodian: 'Oscar Martinez', docs: '22,243', size: '2GB'},
  {id: 11, FileName: 'ryanhoward_01.xls', ImportDate: 'Jul 29, 2020 4:32 PM', SourceSize: '1.33 MB', Custodian: 'Ryan Howard', docs: '23,223', size: '2GB'},
  {id: 12, FileName: 'JMN73_091134.xls', ImportDate: 'Jun 19, 2020 4:10 PM', SourceSize: '8.1 MB', Custodian: 'Kelly Kapoor', docs: '13,243', size: '2GB'},
  {id: 13, FileName: 'NewProductSalesPitch.doc', ImportDate: 'Jun 19, 2020 4:10 PM', SourceSize: '1.03 MB', Custodian: 'Andy Bernard', docs: '13,211', size: '1GB'},
  {id: 14, FileName: '4409510.msg', ImportDate: 'Jun 17, 2020 1:35 PM', SourceSize: '5.9 MB', Custodian: 'Tody Flenderson', docs: '23,243', size: '2GB'},
  {id: 15, FileName: 'Presentation_DPhilbin_01.pptx', ImportDate: 'Jun 15, 2020 11:03 AM', SourceSize: '4.87 MB', Custodian: 'Darryl Philbin', docs: '23,243', size: '2GB'},
  {id: 16, FileName: 'Darryl_Test.exe', ImportDate: 'Jun 15, 2020 11:03 AM', SourceSize: '3.6 MB', Custodian: 'Darryl Philbin', docs: '21,243', size: '2GB'},
  {id: 17, FileName: 'E975433289FBN88UIJ.msg', ImportDate: 'Jun 02, 2020 3:10 PM', SourceSize: '1.98 MB', Custodian: 'Gabe Lewis', docs: '23,243', size: '2GB'},
  {id: 18, FileName: 'E99297573FBY73UKA.msg', ImportDate: 'Jun 02, 2020 3:10 PM', SourceSize: '4.21 MB', Custodian: 'Gabe Lewis', docs: '21,243', size: '2GB'},
  {id: 19, FileName: 'Example_file-01.pdf', ImportDate: 'Apr 18, 2020 5:40 PM', SourceSize: '6.2 MB', Custodian: 'David Wallace', docs: '3,243', size: '1GB'},
  {id: 20, FileName: 'Naming.wpd', ImportDate: 'Apr 16, 2020 2:01 PM', SourceSize: '7.6 MB', Custodian: 'Roy Anderson', docs: '23,243', size: '2GB'},
  {id: 21, FileName: '6554821.msg', ImportDate: 'Apr 06, 2020 10:31 AM', SourceSize: '1.9 MB', Custodian: 'Karen Filippelli', docs: '4,243', size: '2GB'},
  {id: 22, FileName: '6554001.msg', ImportDate: 'Apr 06, 2020 10:31 AM', SourceSize: '5.4 MB', Custodian: 'Karen Filippelli', docs: '23,243', size: '2GB'},
  {id: 23, FileName: '6552903.msg', ImportDate: 'Apr 06, 2020 10:31 AM', SourceSize: '5.02 MB', Custodian: 'Karen Filippelli', docs: '43,243', size: '4GB'},
  {id: 24, FileName: 'JoBennett_FilePasswordProtected.zip', ImportDate: 'May 30, 2020 11:13 AM', SourceSize: '7.8 MB', Custodian: 'Jo Bennett', docs: '23,243', size: '2GB'},
  {id: 25, FileName: 'Miner_Presentation_032117.ppx', ImportDate: 'May 27, 2020 3:14 PM', SourceSize: '4.91 MB', Custodian: 'Charles Miner', docs: '3,243', size: '2GB'},
  {id: 26, FileName: 'CorruptFile.exe', ImportDate: 'Mar 4, 2020 6:10 PM', SourceSize: '2.4 MB', Custodian: 'Jay Barmann', docs: '23,243', size: '2GB'},
  {id: 27, FileName: 'tk-220168.nsf', ImportDate: 'Mar 2, 2020 2:48 PM', SourceSize: '4.4 MB', Custodian: 'Tony Kim', docs: '23,243', size: '2GB'},
  {id: 28, FileName: 'EmployeeList_File2019.doc', ImportDate: 'Jan 16, 2020 3:53 PM', SourceSize: '3.07 MB', Custodian: 'Benjamin Rojers', docs: '2,243', size: '2GB'},
  {id: 29, FileName: 'bill_williams_001.pst', ImportDate: 'Jan 15, 2020 10:38 AM', SourceSize: '8.05 MB', Custodian: 'Bill Williams', docs: '4,243', size: '4GB'},
  {id: 30, FileName: 'Invoice_B2756.pdf', ImportDate: 'Jan 10, 2020 9:18 AM', SourceSize: '5.8 MB', Custodian: 'Kimberly Ricci', docs: '4,243', size: '2GB'}
]`

public nestedGridData =
`public dataSet = [
  {
    id: 1,
    connectionId: 1,
    setName: 'Relationship Identification', 
    analysisStatus: 'Completed', 
    analysisCompleted: '01-02-2023 11:32 AM', 
    publishStatus: 'Wating to Queue', 
    analyzed: { 
      docs: '12,244,455', 
      size: 36.90 
    }
  },
  {
    id: 2,
    connectionId: 2,
    setName: 'Modified Docs', 
    analysisStatus: 'Queued', 
    analysisCompleted: '01-02-2023 11:32 AM', 
    publishStatus: 'Wating to Queue', 
    analyzed: { 
      docs: '12,244,455', 
      size: 36.90 
    },
  },
  {
    id: 3,
    connectionId: 3,
    setName: 'Rolling Collection Devilery', 
    analysisStatus: 'Completed', 
    analysisCompleted: '01-02-2023 11:32 AM', 
    publishStatus: 'Wating to Queue', 
    analyzed: { 
      docs: '12,244,455', 
      size: 36.90 
    }
  }
];`

public nestedChildGridData =
`public childGridData =  [
  {
    id: 1,
    connectionId: 1,
    setName: 'Email Threading',
    analysisStatus: 'Completed',
    overlayStatus: 'Completed',
    docs: '24,555', 
    size: 6.56 
  },
  {
    id: 2,
    connectionId: 1,
    setName: 'Email Threading',
    analysisStatus: 'Completed',
    overlayStatus: 'Not Overlaid',
    docs: '24,555', 
    size: 6.56 
  },
  {
    id: 3,
    connectionId: 1,
    setName: 'Email Threading',
    analysisStatus: 'Completed',
    overlayStatus: 'Completed',
    docs: '24,555', 
    size: 6.56 
  },
  {
    id: 4,
    connectionId: 2,
    setName: 'Email Threading',
    analysisStatus: 'Completed',
    overlayStatus: 'Not Overlaid',
    docs: '24,555', 
    size: 6.56 
  }
]`

public childGridModel =
`this.childGrid = {
  dataSource:this.childGridData,
  queryString: 'connectionId',
  columns: [
      { field: 'setName', headerText: 'analysisStatus', width: 230 },
      { field: 'analysisStatus', headerText: 'analysisStatus', width: 170 },
      { field: 'overlayStatus', headerText: 'analysisStatus', width: 170 },
      { field: 'docs', headerText: 'docs', width: 120 },
      { field: 'size', headerText: 'size', width: 120 }
  ],
};`

public detailPanelFunction =
`@ViewChild('detailPanel') detailPanel: any; 

detailPanelEvent(data) {
  this.panelTrigger = '#details_'+ data.id;
  this.detailPanelData.FileName = data.FileName;
  this.detailPanelData.ImportDate = data.ImportDate;
  this.detailPanelData.SourceSize = data.SourceSize;
  this.detailPanel.open();
}
`

public data: object[] = [
  {id: 1, FileName: 'RC0826_KYR_0921.xls', ImportDate: new Date('2023-12-14T08:30:45Z'), SourceSize: '2.1 MB', Custodian: 'Jim Halpert', docs: '23,243', size: '2GB'},
  {id: 2, FileName: 'Lorem ipsum dolor sit amet consect dolore.xlsx', ImportDate: new Date('2024-12-14T08:30:45Z'), SourceSize: '1.5 MB', Custodian: 'Jim Halpert', docs: '22,243', size: '4GB'},
  {id: 3, FileName: 'jim_halpert_001.pst', ImportDate: new Date('2023-11-14T08:30:45Z'), SourceSize: '4.85 MB', Custodian: 'Jim Halpert', docs: '2,243', size: '6GB'},
  {id: 4, FileName: 'Examplefile1.pdf', ImportDate: new Date('2023-07-14T08:30:45Z'), SourceSize: '8.3 MB', Custodian: 'Dwight Schrute', docs: '243', size: '3GB'},
  {id: 5, FileName: 'SalesInvoice_Europe_Q3.pdf',ImportDate: new Date('2022-10-14T08:30:45Z'), SourceSize: '2.03 MB', Custodian: 'Stanley Hudson', docs: '23,243', size: '2GB'},
  {id: 6, FileName: 'EarningsForecast_2020.doc', ImportDate: new Date('2023-10-14T08:30:45Z'), SourceSize: '2.44 MB', Custodian: 'Stanley Hudson', docs: '25,243', size: '5GB'},
  {id: 7, FileName: 'ReferenceFile_01.zip', ImportDate: new Date('2023-05-14T08:30:45Z'), SourceSize: '0.98 MB', Custodian: 'Angela Martin', docs: '23,663', size: '6GB'},
  {id: 8, FileName: 'meredith_palmer_metadata.pdf', ImportDate: new Date('2023-06-14T08:30:45Z'), SourceSize: '3.5 MB', Custodian: 'Meredith Palmer', docs: '23,243', size: '2GB'},
  {id: 9, FileName: 'native_doc_1.docx', ImportDate: new Date('2023-10-14T08:30:45Z'), SourceSize: '2.24 MB', Custodian: 'Phyllis Vance', docs: '23,343', size: '1GB'},
  {id: 10, FileName: 'test022020.zip', ImportDate: new Date('2023-04-14T08:30:45Z'), SourceSize: '4.59 MB', Custodian: 'Oscar Martinez', docs: '22,243', size: '2GB'},
  {id: 11, FileName: 'ryanhoward_01.xls', ImportDate: new Date('2023-11-14T08:30:45Z'), SourceSize: '1.33 MB', Custodian: 'Ryan Howard', docs: '23,223', size: '2GB'},
  {id: 12, FileName: 'JMN73_091134.xls', ImportDate: new Date('2021-12-14T08:30:45Z'), SourceSize: '8.1 MB', Custodian: 'Kelly Kapoor', docs: '13,243', size: '2GB'},
  {id: 13, FileName: 'NewProductSalesPitch.doc', ImportDate: new Date('2023-12-14T08:30:45Z'), SourceSize: '1.03 MB', Custodian: 'Andy Bernard', docs: '13,211', size: '1GB'},
  {id: 14, FileName: '4409510.msg', ImportDate: new Date('2021-10-14T08:30:45Z'), SourceSize: '5.9 MB', Custodian: 'Tody Flenderson', docs: '23,243', size: '2GB'},
  {id: 15, FileName: 'Presentation_DPhilbin_01.pptx', ImportDate: new Date('2023-12-14T08:30:45Z'), SourceSize: '4.87 MB', Custodian: 'Darryl Philbin', docs: '23,243', size: '2GB'},
  {id: 16, FileName: 'Darryl_Test.exe', ImportDate: new Date('2021-10-14T08:30:45Z'), SourceSize: '3.6 MB', Custodian: 'Darryl Philbin', docs: '21,243', size: '2GB'},
  {id: 17, FileName: 'E975433289FBN88UIJ.msg', ImportDate: new Date('2023-02-14T08:30:45Z'), SourceSize: '1.98 MB', Custodian: 'Gabe Lewis', docs: '23,243', size: '2GB'},
  {id: 18, FileName: 'E99297573FBY73UKA.msg', ImportDate: new Date('2023-03-14T08:30:45Z'), SourceSize: '4.21 MB', Custodian: 'Gabe Lewis', docs: '21,243', size: '2GB'},
  {id: 19, FileName: 'Example_file-01.pdf', ImportDate: new Date('2023-12-14T08:30:45Z'), SourceSize: '6.2 MB', Custodian: 'David Wallace', docs: '3,243', size: '1GB'},
  {id: 20, FileName: 'Naming.wpd', ImportDate: new Date('2021-12-14T08:30:45Z'), SourceSize: '7.6 MB', Custodian: 'Roy Anderson', docs: '23,243', size: '2GB'},
  {id: 21, FileName: '6554821.msg', ImportDate: new Date('2021-11-14T08:30:45Z'), SourceSize: '1.9 MB', Custodian: 'Karen Filippelli', docs: '4,243', size: '2GB'},
  {id: 22, FileName: '6554001.msg', ImportDate: new Date('2023-05-14T08:30:45Z'), SourceSize: '5.4 MB', Custodian: 'Karen Filippelli', docs: '23,243', size: '2GB'},
  {id: 23, FileName: '6552903.msg', ImportDate: new Date('2021-04-14T08:30:45Z'), SourceSize: '5.02 MB', Custodian: 'Karen Filippelli', docs: '43,243', size: '4GB'},
  {id: 24, FileName: 'JoBennett_FilePasswordProtected.zip', ImportDate: new Date('2023-12-14T08:30:45Z'), SourceSize: '7.8 MB', Custodian: 'Jo Bennett', docs: '23,243', size: '2GB'},
  {id: 25, FileName: 'Miner_Presentation_032117.ppx', ImportDate: new Date('2023-12-14T08:30:45Z'), SourceSize: '4.91 MB', Custodian: 'Charles Miner', docs: '3,243', size: '2GB'},
  {id: 26, FileName: 'CorruptFile.exe', ImportDate: new Date('2023-12-14T08:30:45Z'), SourceSize: '2.4 MB', Custodian: 'Jay Barmann', docs: '23,243', size: '2GB'},
  {id: 27, FileName: 'tk-220168.nsf', ImportDate: new Date('2022-04-14T08:30:45Z'), SourceSize: '4.4 MB', Custodian: 'Tony Kim', docs: '23,243', size: '2GB'},
  {id: 28, FileName: 'EmployeeList_File2019.doc', ImportDate: new Date('2022-11-14T08:30:45Z'), SourceSize: '3.07 MB', Custodian: 'Benjamin Rojers', docs: '2,243', size: '2GB'},
  {id: 29, FileName: 'bill_williams_001.pst', ImportDate: new Date('2022-10-14T08:30:45Z'), SourceSize: '8.05 MB', Custodian: 'Bill Williams', docs: '4,243', size: '4GB'},
  {id: 30, FileName: 'Invoice_B2756.pdf', ImportDate: new Date('2023-12-14T08:30:45Z'), SourceSize: '5.8 MB', Custodian: 'Kimberly Ricci', docs: '4,243', size: '2GB'}
]

public childGridData =  [
  {
    id: 1,
    connectionId: 1,
    setName: 'Email Threading',
    analysisStatus: 'Completed',
    overlayStatus: 'Completed',
    docs: '24,555', 
    size: 6.56 
  },
  {
    id: 2,
    connectionId: 1,
    setName: 'Email Threading',
    analysisStatus: 'Completed',
    overlayStatus: 'Not Overlaid',
    docs: '24,555', 
    size: 6.56 
  },
  {
    id: 3,
    connectionId: 1,
    setName: 'Email Threading',
    analysisStatus: 'Completed',
    overlayStatus: 'Completed',
    docs: '24,555', 
    size: 6.56 
  },
  {
    id: 4,
    connectionId: 2,
    setName: 'Email Threading',
    analysisStatus: 'Completed',
    overlayStatus: 'Not Overlaid',
    docs: '24,555', 
    size: 6.56 
  }
]

public dataSet = [
  {
    id: 1,
    connectionId: 1,
    setName: 'Relationship Identification', 
    analysisStatus: 'Completed', 
    analysisCompleted: '01-02-2023 11:32 AM', 
    publishStatus: 'Wating to Queue', 
    analyzed: { 
      docs: '12,244,455', 
      size: 36.90 
    }
  },
  {
    id: 2,
    connectionId: 2,
    setName: 'Modified Docs', 
    analysisStatus: 'Queued', 
    analysisCompleted: '01-02-2023 11:32 AM', 
    publishStatus: 'Wating to Queue', 
    analyzed: { 
      docs: '12,244,455', 
      size: 36.90 
    },
  },
  {
    id: 3,
    connectionId: 3,
    setName: 'Rolling Collection Devilery', 
    analysisStatus: 'Completed', 
    analysisCompleted: '01-02-2023 11:32 AM', 
    publishStatus: 'Wating to Queue', 
    analyzed: { 
      docs: '12,244,455', 
      size: 36.90 
    }
  }
];
public childGrid: any;
  @ViewChild('grid') grid;
  @ViewChild('childtemplate') public childtemplate: any;

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService,
    public elm: ElementRef,
    private viewContainerRef?: ViewContainerRef) { }

  ngAfterViewInit() {
    // this.childtemplate.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
    // this.childtemplate.elementRef.nativeElement.propName = 'template';
  }

  ngOnInit(): void {
    this.toolbarOptions = ['Search'];
    this.filterSettings = { type: this.gridFilter };
    const pageSizes = [25, 50, 100, 150, 200, 250, 500, 'All'];
    this.pageSettings = { pageSizes: pageSizes, pageCount: 5,  pageSize: 25 };
    //this.selectOptions = { checkboxOnly: true };
    this.formatoptions = { type: 'dateTime', format: 'M/d/y hh:mm a' }
    this.childGrid = {
      // dataSource: orderDatas,
      dataSource:this.childGridData,
      queryString: 'connectionId',
      
      columns: [
          //{ headerText: 'Set Name', textAlign: 'Center', template: this.childtemplate, width: 200 },
          { field: 'setName', headerText: 'analysisStatus', width: 230 },
          { field: 'analysisStatus', headerText: 'analysisStatus', width: 170 },
          { field: 'overlayStatus', headerText: 'analysisStatus', width: 170 },
          { field: 'docs', headerText: 'docs', width: 120 },
          { field: 'size', headerText: 'size', width: 120 }
      ],
    };
    
    document.addEventListener('click', function(e) {
        console.log(e);
    });

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateGridTemplate());

    //web template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.webGridCode);

    document.addEventListener('click', (e) => {
      const chooser = document.querySelector('.e-ccdlg');
      const target = (e.target as any);
      
      if (target.id == 'columnChooser') {
        this.columnChooserActive = true;
        this.columnChooserActive = true;
        const pos = target.getClientRects()[0];
        const left = ~~pos.left;
        this.grid.openColumnChooser(left);
      } 

      //check if clicking inside of the column chooser, if not, then close the chooser
      if(chooser) {
        if (!chooser.querySelector("." + target.classList[0]) && target.id != 'columnChooser') {
          const columnChooserEle = document.getElementsByClassName('e-ccdlg')[0];
          if (columnChooserEle) {
            this.columnChooserActive = false;
            const colchooser = (columnChooserEle as any).ej2_instances[0];
            colchooser.hide();
          }
        }

        //confirm or cancel buttons within the chooser
        if(target.classList.contains('e-cc_okbtn') || target.classList.contains('e-cc-cnbtn')) {
          this.columnChooserActive = false;
        }
      }
    });

    this.gridViewchildTemplate(this.gridType);
    this.initializTemplate(this.filterSettings.type, this.gridType);
  }

  gridFilterType(type) {
    this.filterSettings = { type: type.value }
    this.initializTemplate(type.value, this.gridType);
  }

  initializTemplate(type, gridType) {
    let search;
    if(gridType == 'standard') {
      search = `
  this.toolbarOptions = ['Search'];`
    } else {
      search = '';
    }
    this.intialize =
`ngOnInit(): void {
  let pageSizes = [25, 50, 100, 150, 200, 250, 500, 'All'];
  this.filterSettings = { type: '${type.value}' };
  this.pageSettings = { pageSizes: pageSizes, pageSize: 25 };${search}
}`
  }

  dataBound() {
    (document.getElementsByClassName('e-constant')[0] as any).innerText = this.grid.pagerModule.pagerObj.pageSize + ' :  ' + 'Items per page';

    
    (document.getElementsByClassName('e-pagenomsg')[0] as any).innerText = 'Showing' + '  ' + this.grid.pagerModule.pagerObj.currentPage + ' -   ' + this.grid.pagerModule.pagerObj.pageSize + '  of' + '  ' + (this.grid.dataSource as any).length;
  }

  onLoad(): void {
    this.grid.childGrid.dataSource = this.childData; // assign data source for child grid.
  }

  actionComplete() {
    this.activeSortingAndFiltering();
  }

  selection() {

  }

  newRow(event) {
    const rows = this.elm.nativeElement.querySelectorAll('.e-row');
    event ? rows[0].classList.add('row-added') : rows[0].classList.remove('row-added');
  }

  refreshGrid() {
    this.isLoading = true;
    setTimeout(() => {
      this.isLoading = false;
    }, 800);
  }

  change(args: ChangeEventArgs) {
    this.pageSettings = { currentPage: args.value };
  }
  
  clearFilters() {
    this.grid.clearFiltering();
    this.grid.clearSorting();
  }

  activeSortingAndFiltering(): void {
    const filterCount = this.grid.filterSettings.columns.length;
    const sortCount = this.grid.sortSettings.columns.length;
    
    if (filterCount > 0 || sortCount > 0) {
      // Nothing is filtered and the only sort is the default, disabled button
      this.clearAllBtnDisabled = false;
    } else {
      this.clearAllBtnDisabled = true;
    }
  }

  gridCode(event) {
    if(event.value == 'standard') {
      this.gridViewchildTemplate(event.value);
      this.initializTemplate(this.filterSettings.type, event.value);
      this.isLoading = true;
      setTimeout(() => {
        this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateGridTemplate());
        this.isLoading = false;
      }, 1000);
    }
    if(event.value == 'multi-select') {
      this.gridViewchildTemplate(event.value);
      this.initializTemplate(this.filterSettings.type, event.value);
      this.isLoading = true;
      setTimeout(() => {
        this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateGridTemplate());
        this.isLoading = false;
      }, 1000);
      
    }
    if(event.value == 'nested') {
      this.gridViewchildTemplate(event.value);
      this.initializTemplate(this.filterSettings.type, event.value);
      this.isLoading = true;
      setTimeout(() => {
        this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateGridTemplate());
        this.isLoading = false;
      }, 1000);
      
    }
    if(event.value == 'groupedHeader') {
      this.gridViewchildTemplate(event.value);
      this.initializTemplate(this.filterSettings.type, event.value);
      this.isLoading = true;
      setTimeout(() => {
        this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateGridTemplate());
        this.isLoading = false;
      }, 1000);
      
    }
    this.showCompressedGrid = false;
    this.rowHightlight = false;
    this.clearAllBtnDisabled = true;
  }

  gridViewchildTemplate(gridType) {
    let tooltbar;
    if(gridType == 'standard') {
      tooltbar = `
public toolbarOptions?: ToolbarItems[];`;
    } else {
      tooltbar = '';
    }
    
    this.gridViewChild = 
    `public pageSettings;
public isLoading = false;
public clearAllBtnDisabled = true;
public columnChooserActive = false;
public filterSettings;${tooltbar}
@ViewChild('grid') grid;`
  }

  compress() {
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateGridTemplate());
  }

  getTotalPages() {
    this.totalPages = Array(Math.round(this.grid.dataSource.length / this.pageSize));
  }

  updateGridTemplate() {
    let grid;
    const compressed = this.showCompressedGrid ? `class="kn-compressed-grid"` : '';
    const isCompressedTextbox =  this.showCompressedGrid ? 'true' : 'false';

    if(this.gridType == 'standard') {
      grid = `  <ejs-grid ${compressed}
    *ngIf="!isLoading"
    #grid
    [dataSource]='data' 
    height="480px"
    allowSorting="true"
    allowPaging="true" 
    allowFiltering="true" 
    showColumnChooser="true"
    [filterSettings]="filterSettings" 
    [pageSettings]='pageSettings'
    (actionComplete)="actionComplete($event)"
    allowResizing="true"
    allowReordering="true"
    [toolbar]='toolbarOptions'>
    <e-columns>
      <e-column field='FileName' minWidth="40" headerText='File name' width=180 clipMode="EllipsisWithTooltip"></e-column>
      <e-column field='ImportDate' minWidth="40" headerText='Import date' width=200 clipMode="EllipsisWithTooltip"></e-column>
      <e-column field='SourceSize' minWidth="40" headerText='Source size' width=200 clipMode="EllipsisWithTooltip"></e-column>
      <e-column field='Custodian' width="200" headerText='Custodian'>
          <ng-template #template let-data>
              <kn-dropdown 
                  [items]="custodian" 
                  [fields]="custodianFields" 
                  [value]="data.Custodian"
                  [compressed]="showCompressedGrid"
                  [placeholder]="'Choose a custodian'" 
                  [name]="'exampledropdown'"
                  (onSelect)="selection($event)">
              </kn-dropdown>
          </ng-template>
      </e-column>
      <e-column 
        field="columnChooser"
        width="130px"
        [showInColumnChooser]="false"
        [allowSorting]="false"
        [allowFiltering]="false"
        [allowReordering]="false"
        freeze="Right"
      >
        <ng-template #headerTemplate let-data></ng-template>
        <ng-template #template let-data>
          <div class="details-panel-btn">
            <kn-button 
              [buttonId]="'details_' + data.id" 
              [buttonType]="'kn-flat kn-btn-sm'" 
              [buttonText]="'more'"
              (btnClick)="detailPanelEvent(data)">
            </kn-button>
          </div>
      </ng-template>
      </e-column>
    </e-columns>
  </ejs-grid>
  <kn-panel 
    #detailPanel
    [sliderContainerElm]="'.kn-grid'"
    [trigger]="panelTrigger"
    [panelWidth]="'250px'"
    [slideDirection]="'right'"
    [panelType]="'slider'"
  >
    <div slot="panel-header-title" >
        <div class="kn-headline-24-heavy">Title</div>
    </div>
    <div slot="panel-header-actions" >
        <i class="fa-regular fa-arrows-rotate kn-icon-sm kn-icon"></i>
    </div>
    <div slot="panel-body">               
        <div class="panel-content">
            <div class="text"><i class="fa-solid fa-certificate kn-icon-sm"></i> {{detailPanelData?.FileName}}</div>
            <div class="text"><i class="fa-solid fa-certificate kn-icon-sm"></i> {{detailPanelData?.ImportDate}}</div>
            <div class="text"><i class="fa-solid fa-certificate kn-icon-sm"></i> {{detailPanelData?.SourceSize}}</div>
        </div>
    </div>
  </kn-panel>`
    }
    if(this.gridType == 'multi-select') {  
      grid = `  <ejs-grid ${compressed}
    *ngIf="!isLoading"
    #grid
    [dataSource]='data' 
    height="480px"
    [ngClass]="{'kn-compressed-grid': showCompressedGrid}"
    allowSorting="true"
    allowPaging="true" 
    allowFiltering="true" 
    showColumnChooser="true"
    [filterSettings]="filterSettings" 
    [pageSettings]='pageSettings'
    [selectionSettings]="selectOptions"
    (actionComplete)="actionComplete($event)"
    allowResizing="true"
    allowReordering="true">
    <e-columns>
      <e-column type="checkbox" minWidth="60px" headerText="Checkbox" width="60px" [showInColumnChooser]="false"></e-column>
      <e-column field='FileName' minWidth="40" headerText='File name' width=160 clipMode="EllipsisWithTooltip">
        <ng-template #headerTemplate let-data>
          <div class="kn-grid-header-multi-line">
            <div>File</div>
            <div>Name</div>
          </div>
        </ng-template>
      </e-column>
      <e-column field='ImportDate' minWidth="40" headerText='Import date' width=200 clipMode="EllipsisWithTooltip"></e-column>
      <e-column field='SourceSize' minWidth="40" headerText='Source size' width=150 clipMode="EllipsisWithTooltip">
        <ng-template #headerTemplate let-data>
          <div class="kn-grid-header-multi-line">
            <div>Source</div>
            <div>Size</div>
          </div>
        </ng-template>
      </e-column>
      <e-column field='Custodian' minWidth="200" headerText='Custodian'>
        <ng-template #template let-data>
          <kn-dropdown  
            [items]="custodian" 
            [fields]="custodianFields" 
            [value]="data.Custodian"
            [compressed]="${isCompressedTextbox}"
            [placeholder]="'Choose a custodian'" 
            [name]="'exampledropdown'"
            (onSelect)="selection($event)">
          </kn-dropdown>
        </ng-template>
      </e-column>
    </e-columns>
  </ejs-grid>`
    }
    if(this.gridType == 'nested') {
      grid =`  <ejs-grid ${compressed}
    *ngIf="!isLoading"
    #grid
    class="kn-grouped-header"
    [dataSource]="dataSet"
    [childGrid]='childGrid'
    height="480px"
    allowSorting="true"
    allowPaging="true" 
    allowFiltering="true" 
    showColumnChooser="true"
    [filterSettings]="filterSettings" 
    [pageSettings]='pageSettings'
    (actionComplete)="actionComplete($event)"
    allowResizing="true"
    allowReordering="true">
    <e-columns>
      <e-column field='setName' headerText='set Name' width='140'></e-column>
      <e-column field='analysisStatus' headerText='analysis Status' width='170'></e-column>
      <e-column field='analysisCompleted' headerText='analysis Completed' width='120' format='yMd' clipMode="EllipsisWithTooltip"></e-column>
      <e-column field='publishStatus' headerText='publish Status' width='120' clipMode="EllipsisWithTooltip"></e-column>
    </e-columns>
  </ejs-grid>`
    }
    if(this.gridType == 'groupedHeader') {
      grid =`  <ejs-grid ${compressed}
    *ngIf="!isLoading"
    #grid
    class="kn-grouped-header"
    [dataSource]='data'
    height="480px"
    allowSorting="true"
    allowPaging="true" 
    allowFiltering="true" 
    showColumnChooser="true"
    [filterSettings]="filterSettings" 
    [pageSettings]='pageSettings'
    (actionComplete)="actionComplete($event)"
    allowResizing="true"
    allowReordering="true">
    <e-columns>
      <e-column field='FileName' minWidth="40" headerText='File name' width=180 clipMode="EllipsisWithTooltip"></e-column>
      <e-column field='ImportDate' minWidth="40" headerText='Import date' width=200 clipMode="EllipsisWithTooltip"></e-column>
      <e-column field='SourceSize' minWidth="40" headerText='Source size' width=170 clipMode="EllipsisWithTooltip"></e-column>
      <e-column field='Analyzed' width="200" headerText='Analyzed' [allowFiltering]="false" [allowSorting]="false">
        <ng-template #headerTemplate let-data>
          <div class="kn-grouped-column">
            <div class="grouped-header-title">Analyzed</div>
            <ul class="grouped-columns">
              <li class="column">
                Docs
              </li>
              <li class="column">
                Size
              </li>
            </ul>
          </div>
        </ng-template>
        <ng-template #template let-data>
          <div class="kn-grouped-column">
            <ul class="grouped-columns">
              <li class="column">
                {{data.docs}}
              </li>
              <li class="column">
                {{data.size}}
              </li>
            </ul>
          </div>
        </ng-template>
      </e-column>
    </e-columns>
  </ejs-grid>`
    }

    return this.gridTemplate =
`<div class="kn-grid">
  <kn-spinner [isLoading]="isLoading"></kn-spinner>
  <div class="kn-grid-nav">
    <div class="inner">
      <div class="item">
        <kn-button 
          [buttonId]="'refreshGridBtn'" 
          [buttonType]="'kn-flat kn-btn-sm'" 
          (btnClick)="refreshGrid()" 
          [buttonText]="'Refresh'" 
          [buttonIconLeft]="'fa-regular fa-arrows-rotate'">
        </kn-button>
      </div>
      <div class="kn-pipe"></div>
      <div class="item">
        <kn-button 
          [buttonId]="'clearAllBtn'" 
          [buttonType]="'kn-flat kn-btn-sm'" 
          (btnClick)="clearFilters()" 
          [buttonText]="'Clear all'" 
          [buttonIconLeft]="'fa-regular fa-filter-slash'" 
          [disabled]="clearAllBtnDisabled">
        </kn-button>
      </div>
      <div class="item">
        <kn-button 
          [buttonId]="'columnChooser'" 
          [active]="columnChooserActive" 
          [buttonType]="'kn-flat kn-btn-sm'" 
          [buttonText]="'Columns'" 
          [buttonIconLeft]="'fa-regular fa-gear'">
        </kn-button>
      </div>
    </div>
  </div>
${grid}
</div>
`
  }

  detailPanelEvent(data) {
    this.panelTrigger = '#details_'+ data.id;
    this.detailPanelData.FileName = data.FileName;
    this.detailPanelData.ImportDate = data.ImportDate;
    this.detailPanelData.SourceSize = data.SourceSize;
  }
  
}
