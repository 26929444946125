import { Component, OnInit } from '@angular/core';
import  packageInfo from '../../../../../package.json';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { AuthService } from '../services/auth.service';

declare const $: any;
@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass']
})
export class HeaderComponent implements OnInit {
  public version = packageInfo.version;
  
  constructor(private GAService: GoogleAnalyticsService, private auth: AuthService) { }

  ngOnInit(): void {
  }

  selectedTheme(event) {
    if(event.button == 2) {
      $('.main-container').addClass('dark');
      $('.main-container').removeClass('light');
    } else {
      $('.main-container').addClass('light');
      $('.main-container').removeClass('dark');
    }
  }

  contactEvent(event) {
    this.GAService.trackEvent('Contact Us', {
      'event_category': 'Engagement',
      'event_label': 'Masthead contact button',
      'value': 'Button clicked'
    });
  }

  logout() {
    this.auth.logout();
  }
}
