import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HtmlFetcherService {

  constructor(private http: HttpClient) { }

  fetchHtml(url: string): Observable<string> {
    // Update the URL to wherever your Node.js server is hosted
    return this.http.get(`http://localhost:3000/fetch-url?url=${encodeURIComponent(url)}`, { responseType: 'text' });
  }
}
