<div class="kn-contextual-menu"
    [ngClass]="{'right-aligned': dropdownRightAligned}"
>
    <div 
        id="{{componentId}}"
        class="kn-dropdown" 
        [ngClass]="
        {
            'show': showDropdown
        }" 
        [ngStyle]="
        {
        'top': topPos, 
        'width': drpdnWidth,
        'height': drpdnHeight,
        'left': rightClick ? leftPos : 'unset' || dropdownRightAligned || !rightClick ? rightAligned : 'unset' 
        }">
        <div class="inner-container" id="dropdown_results_{{componentId}}" (scroll)="navScroll($event)">
           
            <ng-container>
                <!--Default menu-->
                <ng-container *ngFor="let childData of items; let i = index">
                    <div 
                        class="kn-dropdown-item" 
                        id="childDataItem_{{i}}" 
                        (click)="select(childData)" 
                        (mouseover)="itemHover(childData, '#childDataItem_' + i, i, $event, 1);"
                        [ngClass]="
                        {   'has-child2-menu': childData.children,
                            'disabled': childData.disabled
                        }"
                    >
                        <div *ngIf="childData.icon" class="item-icon {{childData.icon}}"></div>
                        <div class="item-value">
                            {{childData.name}}
                        </div>
                        <div *ngIf="childData.meta" class="kn-contextual-menu-meta">{{childData.meta}}</div>
                    
                        <!--Menu 2-->
                        <div 
                            id="knChild2Menu_{{i}}"
                            class="kn-sub-menu kn-child2-menu" 
                            *ngIf="childData.children"
                            [ngStyle]="
                            {
                                'left': child2MenuLeftPos,
                                'top': child2MenuTopPos,
                                'bottom': child2MenuBottomPos
                            }">
                            <div class="inner-container" id="child2_dropdown_results_{{i}}" (scroll)="child2NavScroll($event)">
                                <ng-container *ngFor="let child2Data of childData.children; let z = index">
                                    <div 
                                        class="kn-dropdown-item" 
                                        id="knChild2Menu_{{i}}_item_{{z}}" 
                                        (click)="select(child2Data)"
                                        [ngClass]="
                                        {   'has-child3-menu': child2Data.children,
                                            'disabled': child2Data.disabled
                                        }"
                                        (mouseover)="itemHover(child2Data, '#knChild2Menu_' + i +'_item_' + z, z, $event, 2);"
                                    >
                                        <div *ngIf="child2Data.icon" class="item-icon {{child2Data.icon}}"></div>
                                        <div class="item-value">
                                            {{child2Data.name}}
                                        </div>
                                        <div *ngIf="child2Data.meta" class="kn-contextual-menu-meta">{{child2Data.meta}}</div>

                                       <!--Menu 3-->
                                        <div 
                                            id="knChild3Menu_{{z}}"
                                            class="kn-sub-menu kn-child3-menu" 
                                            *ngIf="child2Data.children"
                                            [ngStyle]="
                                            {
                                                'left': child3MenuLeftPos,
                                                'top': child3MenuTopPos,
                                                'bottom': child3MenuBottomPos
                                            }">
                                            <div class="inner-container" id="child3_dropdown_results_{{z}}" (scroll)="child3NavScroll($event)">
                                                <ng-container *ngFor="let child3Data of child2Data.children; let k = index">
                                                    <div 
                                                        class="kn-dropdown-item" 
                                                        id="knChild3Menu_{{z}}_item_{{k}}" 
                                                        [ngClass]="
                                                        {
                                                            'has-child4-menu': child3Data.children,
                                                            'disabled': child3Data.disabled
                                                        }"
                                                        (click)="select(child3Data)"
                                                        (mouseover)="itemHover(child3Data, '#knChild3Menu_' + z +'_item_' + k, k, $event, 3);"
                                                    >
                                                        <div *ngIf="child3Data.icon" class="item-icon {{child3Data.icon}}"></div>
                                                        <div class="item-value">
                                                            {{child3Data.name}}
                                                        </div>
                                                        <div *ngIf="child3Data.meta" class="kn-contextual-menu-meta">{{child3Data.meta}}</div>

                                                        <!--Menu 4-->
                                                        <div 
                                                            id="knChild4Menu_{{k}}"
                                                            class="kn-sub-menu kn-child4-menu" 
                                                            *ngIf="child3Data.children"
                                                            [ngStyle]="
                                                            {
                                                                'left': child4MenuLeftPos,
                                                                'top': child4MenuTopPos,
                                                                'bottom': child4MenuBottomPos
                                                            }">
                                                            <div class="inner-container" id="child4_dropdown_results_{{k}}" (scroll)="child4NavScroll($event)">
                                                                <ng-container *ngFor="let childData4 of child3Data.children; let l = index">
                                                                    <div 
                                                                        class="kn-dropdown-item" 
                                                                        id="knChild4Menu_{{k}}_item_{{l}}" 
                                                                        [ngClass]="
                                                                        {
                                                                            'disabled': childData4.disabled
                                                                        }"
                                                                        (click)="select(childData4)"
                                                                    >
                                                                        <div *ngIf="childData4.icon" class="item-icon {{childData4.icon}}"></div>
                                                                        <div class="item-value">
                                                                            {{childData4.name}}
                                                                        </div>
                                                                        <div *ngIf="childData4.meta" class="kn-contextual-menu-meta">{{childData4.meta}}</div>
                                                                    </div>
                                                                    <div *ngIf="childData4.divider" class="kn-contextual-menu-divider"></div>
                                                                </ng-container>
                                                            </div>
                                                            <div 
                                                                class="more-items up" 
                                                                [ngClass]="
                                                                {
                                                                    'show': child4MenuUpArrow
                                                                }" 
                                                                id="knChild4Menu_{{k}}_item_{{l}}_down" 
                                                                (mouseenter)="scrollUp('#child4_dropdown_results_' + k)"
                                                                (mouseleave)="resetScrollInterval()">
                                                                <div class="border"></div>
                                                                <i class="fa-regular fa-chevron-up kn-icon-sm"></i>
                                                            </div>
                                                            <div 
                                                                class="more-items down" 
                                                                [ngClass]="
                                                                {
                                                                    'show': child4MenuDownArrow
                                                                }" 
                                                                id="knChild4Menu_{{k}}_item_{{l}}_down" 
                                                                (mouseenter)="scrollDown('#child4_dropdown_results_' + k)"
                                                                (mouseleave)="resetScrollInterval()">
                                                                <div class="border"></div>
                                                                <i class="fa-regular fa-chevron-down kn-icon-sm"></i>
                                                            </div>
                                                        </div>
                                                        <i *ngIf="child3Data.children" class="fa-regular fa-chevron-right kn-icon-sm sub-menu-arrow"></i>
                                                    </div>
                                                    <div *ngIf="child3Data.divider" class="kn-contextual-menu-divider"></div>
                                                </ng-container>
                                            </div>
                                            <div 
                                                class="more-items up" 
                                                [ngClass]="
                                                {
                                                    'show': child3MenuUpArrow
                                                }" 
                                                id="knChild3Menu_{{i}}_item_{{z}}_down" 
                                                (mouseenter)="scrollUp('#child3_dropdown_results_' + z)"
                                                (mouseleave)="resetScrollInterval()">
                                                <div class="border"></div>
                                                <i class="fa-regular fa-chevron-up kn-icon-sm"></i>
                                            </div>
                                            <div 
                                                class="more-items down" 
                                                [ngClass]="
                                                {
                                                    'show': child3MenuDownArrow
                                                }" 
                                                id="knChild3Menu_{{i}}_item_{{z}}_down" 
                                                (mouseenter)="scrollDown('#child3_dropdown_results_' + z)"
                                                (mouseleave)="resetScrollInterval()">
                                                <div class="border"></div>
                                                <i class="fa-regular fa-chevron-down kn-icon-sm"></i>
                                            </div>
                                        </div>
                                       


                                        <i *ngIf="child2Data.children" class="fa-regular fa-chevron-right kn-icon-sm sub-menu-arrow"></i>
                                    </div>

                                    <div *ngIf="child2Data.divider" class="kn-contextual-menu-divider"></div>
                                </ng-container>
                            </div>
                            <div 
                                class="more-items up" 
                                [ngClass]="
                                {
                                    'show': child2MenuUpArrow
                                }" 
                                id="knChild2Menu_{{i}}_Up" 
                                (mouseenter)="scrollUp('#child2_dropdown_results_' + i)"
                                (mouseleave)="resetScrollInterval()">
                                <div class="border"></div>
                                <i class="fa-regular fa-chevron-up kn-icon-sm"></i>
                            </div>
                            <div 
                                class="more-items down" 
                                [ngClass]="
                                {
                                    'show': child2MenuDownArrow
                                }" 
                                id="knChild2Menu_{{i}}_down" 
                                (mouseenter)="scrollDown('#child2_dropdown_results_' + i)"
                                (mouseleave)="resetScrollInterval()">
                                <div class="border"></div>
                                <i class="fa-regular fa-chevron-down kn-icon-sm"></i>
                            </div>
                        </div>
                       

                        <i *ngIf="childData.children" class="fa-regular fa-chevron-right kn-icon-sm sub-menu-arrow"></i>
                    </div>
                    <div *ngIf="childData.divider" class="kn-contextual-menu-divider"></div>
                </ng-container>
            </ng-container>
        </div>
        <div 
            class="more-items up"
            [ngClass]="
            {
                'show': defaultUpArrow && showDropdown
            }" 
            (mouseenter)="scrollUp('#dropdown_results_' + componentId)"
            (mouseleave)="resetScrollInterval()">
            <div class="border"></div>
            <i class="fa-regular fa-chevron-up kn-icon-sm"></i>
        </div>
        <div 
            class="more-items down"
            [ngClass]="
            {
                'show': defaultDownArrow && showDropdown
            }" 
            (mouseenter)="scrollDown('#dropdown_results_' + componentId)"
            (mouseleave)="resetScrollInterval()">
            <div class="border"></div>
            <i class="fa-regular fa-chevron-down kn-icon-sm"></i>
        </div>
        
    </div>
</div>
