<div class="kn-input-contain kn-multi-select" [ngStyle]="{'width': componentWidth}">
    <div class="inner">
        <div 
            class="kn-field-validation"
            [style.left]="dropdownLeftPos" 
            [style.top]="dropdownBottomPos"
            [style.width]="dropdownWidth"
        >
            <kraken-multi-select-validation 
                [customWarning]="customWarning" 
                [customError]="customError || isRequired" 
                [customMess]="customMess">
            </kraken-multi-select-validation>
        </div>
        <!--Input box-->
        <div class="kn-input-wrapper">
            <div class="kn-mulit-select-container" 
                id="{{multiSelectId}}"
                [ngClass]="
                {'focused': showDropdown, 
                'error': customError || isRequired, 
                'warning': customWarning,
                'disabled': disabled,
                'read-only': readOnly}">    
                <div class="kn-multi-select-textbox-display">
                    <span class="display-placeholder" *ngIf="selectedItems == ''">{{displayPlaceholder}}</span>
                    <span class="selected-items" [ngClass]="{'truncate': moreItems && moreItemsCount > 0}">{{selectedItems}}</span>
                    <span *ngIf="moreItems && moreItemsCount > 0" class="more-items" [tooltip]="moreItemsTooltip" [tooltipDirection]="'top'">+{{moreItemsCount}} more</span>
                </div>
                <!--Dropdown icon-->
                <div class="dropdown-icon" 
                    [ngClass]="{'rotate': showDropdown}">
                    <i class="fa-solid fa-chevron-down kn-icon kn-dp-icon kn-icon" aria-hidden="true"></i>
                </div>
            </div>
             <!--Dropdown section-->
            <div class="kn-dropdown" 
                [ngClass]="{'show': showDropdown}"
                [ngStyle]="{
                    'top': topPos, 
                    'width': multiselectDropdownWidth,
                    'left': leftPos ? leftPos : 'unset'}">
                <!--search box-->
                <input 
                    #{{id}} 
                    class="kn-multi-select-searchbox"  
                    [(ngModel)]="searchTerm" 
                    type="text" 
                    (blur)="onBlur()"
                    aria-label="dropdown-search" 
                    (keydown)="searchBoxKeyEvent($event)"
                    [placeholder]="searchboxPlaceholder">
                <i *ngIf="searchTerm != ''" class="fa fa-solid fa-circle-xmark clear-icon kn-icon" (click)="clear()" aria-hidden="true"></i>
                <i *ngIf="searchTerm == ''"class="fa-regular fa-magnifying-glass search-icon" aria-hidden="true"></i>
                <div class="inner-container" [ngStyle]="{'max-height': dropdownHeight}">
                    <!--dropdown list-->
                    <ng-container *ngFor="let key of source; let i = index">
                        <div class="kn-dropdown-item" id="knDropdownItem_{{i}}" (click)="key.selected = !key.selected; selectedRow(key.selected, source[i], i)">
                            <div class="kn-dropdown-checkbox">
                                <div class="kn-checkbox kn-input-contain" [ngClass]="{'disabled': disabled}">
                                    <label class="kn-checkbox-label">
                                        <div class="kn-checkbox-inner">
                                            <input 
                                                #multiselectCheckbox
                                                type="checkbox" 
                                                class="kn-checkbox-input" 
                                                id="checkbox_{{i}}" 
                                                [attr.aria-label]="'multiselect checkbox'"
                                                [checked]="key.selected" 
                                                [(ngModel)]="key.selected" 
                                                name="{{name}}">
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <ng-container *ngIf="!template">
                                <span class="kn-dropdown-item-value">{{key.text}}</span>
                            </ng-container>

                            <!--custom row template-->
                            <ng-container *ngIf="template">
                                <ng-container [ngTemplateOutlet]="customTemplate" [ngTemplateOutletContext]="{data: key.origin}"></ng-container>
                            </ng-container>
                        </div>
                    </ng-container>
                    <div *ngIf="source.length == 0" class="no-results">No match found</div>
                </div>
            </div>
        </div>
        <label class="label" 
            [ngClass]="
                {'show': showDropdown, 
                'error': customError || isRequired, 
                'warning': customWarning, 
                'disabled': disabled,
                'readOnly': readOnly
                }">{{label}}<span *ngIf="optional" class="optional-label">(Optional)</span></label>
    </div>
</div>