import { Component, OnInit, ViewChild } from '@angular/core';
import  packageInfo from '../../../../../kraken-panel/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.sass']
})
export class PanelComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public event1 = true;
  public event2 = true;
  public elementTag = 'kn-panel';
  public triggerButtonTag = 'kn-button';
  public layoutTag = 'div';
  public layoutExample: any;
  public layoutExampleWeb: any;
  public slideDirection = 'left';
  public dropShadow = true;
  public panelHeight;
  public panelType = 'overlay';
  public panelWidth;
  public isLoading = false;
  public showLayout = false;
  public triggerLabel = 'Open panel';
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenPanelModule'};
  public componentId = 'panelComponentId';
  
  public angularComponentOptions: any = [
    { option: '[panelId]=', id: 0, value: '"\'' + this.componentId +'\'"'},
    { option: '[sliderContainerElm]=', id: 0, value: '"\'.panel-container\'"'},
    { option: '[dropShadow]=', id: 0, value: '"' + this.dropShadow +'"'},
    { option: '[slideDirection]=', id: 0, value: '"\'' + this.slideDirection +'\'"'},
    { option: '[panelType]=', id: 0, value: '"\'' + this.panelType +'\'"'},
    { option: '[trigger]=', id: 0, value: '"\'#panelTrigger\'"'}
  ];

  
  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"examplePanelComponent"'},
    { option: 'panel-id=', id: 0, value:  '"'+ this.componentId +'"'},
    { option: 'slider-container-elm=', id: 0, value: '".panel-container"'},
    { option: 'drop-shadow=', id: 0, value: '"' + this.dropShadow +'"'},
    { option: 'slide-direction=', id: 0, value: '"' + this.slideDirection +'"'},
    { option: 'panel-type=', id: 0, value: '"' + this.panelType +'"'},
    { option: 'trigger=', id: 0, value: '"\'#panelTrigger\'"'}
  ];

  public triggerButtonOptions: any = [
    { option: '[buttonId]=', id: 0, value: '"\'panelTrigger\'"'},
    { option: '[buttonType]=', id: 0, value: '"\'kn-primary\'"'},
    { option: '[buttonText]=', id: 0, value: '"\'panel toggle\'"'},
  ]

  public triggerButtonWebOptions: any = [
    { option: 'button-id=', id: 0, value: '"panelTrigger"'},
    { option: 'button-type=', id: 0, value: '"kn-primary"'},
    { option: 'button-text=', id: 0, value: '"panel toggle"'}
  ]

  public layoutOptions: any = [
    { option: 'class=', id: 0, value: '"panel-container"'},
  ]

public exampleInlineLayout =
`<kn-panel 
    [sliderContainerElm]="'.panel-container'"
    [dropShadow]="dropShadow"
    [slideDirection]="'left'"
    [panelType]="'inline'"
    [containerToPush]="'.example-page'"
    (onOpen)="openPanel()"
    (onClose)="closePanel()"
>
    <div slot="panel-header-title" >
      <div class="kn-headline-24-heavy">Title</div>
    </div>
    <div slot="panel-header-actions" >
      <i class="fa-regular fa-arrows-rotate kn-icon-sm kn-icon"></i>
    </div>
    <div slot="panel-body">               
      <div class="panel-content">
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
      </div>
    </div>
  </kn-panel>

  <div class="example-page">
    <div class="inner-container">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam ut porttitor leo a diam sollicitudin tempor. Feugiat scelerisque varius morbi enim nunc faucibus. 
      At risus viverra adipiscing at in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. 
      Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. Congue eu consequat ac felis donec et odio pellentesque diam. Elementum sagittis vitae et leo duis ut diam. Et odio pellentesque diam volutpat commodo sed.</p> 
    </div>
  </div>
</div>`

public exampleInlineLayoutWeb =
`<kn-web-panel 
    slider-container-elm=".panel-container"
    slide-direction="left"
    panel-type="inline"
    container-to-push=".example-page"
>
    <div slot="panel-header-title" >
      <div class="kn-headline-24-heavy">Title</div>
    </div>
    <div slot="panel-header-actions" >
      <i class="fa-regular fa-arrows-rotate kn-icon-sm kn-icon"></i>
    </div>
    <div slot="panel-body">               
      <div class="panel-content">
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
      </div>
    </div>
  </kn-web-panel>

  <div class="example-page">
    <div class="inner-container">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam ut porttitor leo a diam sollicitudin tempor. Feugiat scelerisque varius morbi enim nunc faucibus. At risus viverra adipiscing at in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. Congue eu consequat ac felis donec et odio pellentesque diam. Elementum sagittis vitae et leo duis ut diam. Et odio pellentesque diam volutpat commodo sed.</p> 
    </div>
  </div>
</div>`

public exampleOverlayLayout =
`<kn-panel 
  [sliderContainerElm]="'.panel-container'"
  [dropShadow]="dropShadow"
  [slideDirection]="'left'"
  [trigger]="'#triggerElm'"
  (onOpen)="openPanel()"
  (onClose)="closePanel()"
>
    <div slot="panel-header-title" >
      <div class="kn-headline-24-heavy">Title</div>
    </div>
    <div slot="panel-header-actions" >
      <i class="fa-regular fa-arrows-rotate kn-icon-sm kn-icon"></i>
    </div>
    <div slot="panel-body">               
      <div class="panel-content">
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
      </div>
    </div>
  </kn-panel>

  <div class="example-page">
    <div class="inner-container">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam ut porttitor leo a diam sollicitudin tempor. Feugiat scelerisque varius morbi enim nunc faucibus. At risus viverra adipiscing at in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. Congue eu consequat ac felis donec et odio pellentesque diam. Elementum sagittis vitae et leo duis ut diam. Et odio pellentesque diam volutpat commodo sed.</p> 
      <kn-button [buttonId]="'toggleInlineSlider'" [buttonType]="'kn-primary'" [buttonText]="triggerLabel"></kn-button>
    </div>
  </div>
</div>`

public exampleOverlayLayoutWeb =
`<kn-panel 
  slider-container-elm=".panel-container"
  slide-direction="left"
  trigger="#triggerElm"
>
  <div slot="panel-header-title" >
    <div class="kn-headline-24-heavy">Title</div>
  </div>
  <div slot="panel-header-actions" >
    <i class="fa-regular fa-arrows-rotate kn-icon-sm kn-icon"></i>
  </div>
  <div slot="panel-body">               
    <div class="panel-content">
      <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
      <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
      <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
      <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
      <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
    </div>
  </div>
</kn-panel>

<div class="example-page">
  <div class="inner-container">
      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam ut porttitor leo a diam sollicitudin tempor. Feugiat scelerisque varius morbi enim nunc faucibus. At risus viverra adipiscing at in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. Congue eu consequat ac felis donec et odio pellentesque diam. Elementum sagittis vitae et leo duis ut diam. Et odio pellentesque diam volutpat commodo sed.</p> 
      <kn-button [buttonId]="'toggleInlineSlider'" [buttonType]="'kn-primary'" [buttonText]="triggerLabel"></kn-button>
  </div>
</div>
</div>`

public panelOpenCloseFunction =
`@ViewChild('panel') panel: any; 

this.panel.open();
this.panel.close();`

public initPanel=
`let panel = document.querySelector('#examplePanelComponent');`

public setPanelContent =
`panel.panelTitle =
\`<div class="kn-headline-24-heavy">Title</div>\`

panel.panelActions =
\`<i class="fa-regular fa-arrows-rotate kn-icon-sm kn-icon"></i>\`

panel.panelBody =
\`<div class="panel-content">
  <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
  <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
  <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
  <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
  <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
</div>\``

public manualOpenCloseEvent =
`panel.open = true;
panel.open = false;`

@ViewChild('sidePanel') myPanel: any;

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());

    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer, this.updateTemplate());

    //panel trigger button
    this.layoutExample = this.tempUpdateService.templateUpdate(this.triggerButtonTag, this.triggerButtonOptions, this.angularContainer);
    this.layoutExampleWeb = this.tempUpdateService.templateUpdate(this.triggerButtonTag, this.triggerButtonWebOptions, this.angularContainer);
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer, this.updateTemplate());
  }

  sliderDirectionEvent(event) {
    this.isLoading = true;
    this.slideDirection = event.value;
    setTimeout(() => {
      this.isLoading = false;
    }, 1000);

    if(event.value == 'top' || event.value == 'bottom') {
      let heightProp = this.angularComponentOptions.findIndex((obj => obj.option == '[panelHeight]='));
      if(heightProp == -1) {
        this.controlEvent(true, '[panelHeight]=', event, true);
        heightProp = this.angularComponentOptions.findIndex((obj => obj.option == '[panelHeight]='));
        this.angularComponentOptions[heightProp].value = '"\'max-height\'"';
        this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
        //web
        const webHeightProp = this.webComponentOptions.findIndex((obj => obj.option == 'panel-height='));
        this.webComponentOptions[webHeightProp].value = '"max-height"';
        this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer, this.updateTemplate());
      }
    } else {
      this.controlEvent(false, '[panelHeight]=', event, true);
    }

    //angular
    const propId = this.angularComponentOptions.findIndex((obj => obj.option == '[slideDirection]='));
    this.angularComponentOptions[propId].value = '"\'' + event.value +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
    //web
    const webPropId = this.webComponentOptions.findIndex((obj => obj.option == 'slide-direction='));
    this.webComponentOptions[webPropId].value = '"' + event.value + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer, this.updateTemplate());
  }

  panelTypeEvent(event) {
    this.triggerLabel = "Open panel";
    this.slideDirection = 'left';
    this.isLoading = true;
    this.dropShadow = event.value == 'inline' ? false: this.dropShadow;
    this.panelType = event.value;

    if(event.value == 'inline') {
      this.controlEvent(true, '[containerToPush]=', event, true);
      this.controlEvent(false, '[dropShadow]=', event, true);
      this.controlEvent(false, '[panelHeight]=', event, true);
      this.controlEvent(false, '[trigger]=', event, true);

      const propId = this.angularComponentOptions.findIndex((obj => obj.option == '[containerToPush]='));
      this.angularComponentOptions[propId].value = '"\'.example-page\'"';
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
      //web
      const webPropId = this.webComponentOptions.findIndex((obj => obj.option == 'container-to-push='));
      this.webComponentOptions[webPropId].value = '".example-page"';
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer, this.updateTemplate());
    } else {
      this.controlEvent(false, '[containerToPush]=', event, true);
      this.controlEvent(true, '[trigger]=', event, true);
      const propId = this.angularComponentOptions.findIndex((obj => obj.option == '[trigger]='));
      this.angularComponentOptions[propId].value = '"\'#panelTrigger\'"';
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
      //web
      const webPropId = this.webComponentOptions.findIndex((obj => obj.option == 'trigger='));
      this.webComponentOptions[webPropId].value = '"#panelTrigger"';
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer, this.updateTemplate());
    }

    setTimeout(() => {
      this.isLoading = false;
      //angular
      const propId = this.angularComponentOptions.findIndex((obj => obj.option == '[panelType]='));
      this.angularComponentOptions[propId].value = '"\'' + event.value +'\'"';
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
      //web
      const webPropId = this.webComponentOptions.findIndex((obj => obj.option == 'panel-type='));
      this.webComponentOptions[webPropId].value = '"' + event.value + '"';
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer, this.updateTemplate());
    }, 1000);

    this.showLayout = false;
    this.showPageLayout(false);
  }

  openPanel() {
    this.triggerLabel = "Close panel";
  }

  closePanel() {
    this.triggerLabel = "Open panel";
  }

  dropShadowEvent(event) {
    //angular
    let propId = this.angularComponentOptions.findIndex((obj => obj.option == '[dropShadow]='));
    if(propId == -1) {
      this.controlEvent(true, '[dropShadow]=', event, true);
      propId = this.angularComponentOptions.findIndex((obj => obj.option == '[dropShadow]='));
    }

    this.angularComponentOptions[propId].value = '"' + event +'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
    //web
    const webPropId = this.webComponentOptions.findIndex((obj => obj.option == 'drop-shadow='));
    this.webComponentOptions[webPropId].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer, this.updateTemplate());
  }

  updateTemplate () {
    let panelContent;

    if(this.slideDirection == 'left' || this.slideDirection == 'right') {
      panelContent = 
`<div class="panel-content">
    <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
    <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
    <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
    <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
    <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
  </div>`
    } else {
      panelContent =
        `<div class="panel-content row">
        <div class="panel-col">
            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        </div>
        <div class="panel-col">
            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
        </div>
      </div>`
    }
    const template =
  `<div slot="panel-header-title" >
  <div class="kn-headline-24-heavy">Title</div>
</div>
<div slot="panel-header-actions" >
  <i class="fa-regular fa-arrows-rotate kn-icon-sm kn-icon"></i>
</div>
<div slot="panel-body">               
  ${panelContent}
</div>`
    

return template;
  }

  showPageLayout(event) {
    
    if(event) {
      if(this.panelType == 'inline') {
        //layout
        this.layoutExample = this.tempUpdateService.templateUpdate(this.layoutTag, this.layoutOptions, this.angularContainer, this.exampleInlineLayout, false);
        //this.layoutExampleWeb = this.tempUpdateService.templateUpdate(this.layoutTag, this.layoutOptions, this.angularContainer, this.exampleInlineLayoutWeb, false);
      } else {
        //layout
        this.layoutExample = this.tempUpdateService.templateUpdate(this.layoutTag, this.layoutOptions, this.angularContainer, this.exampleOverlayLayout);
        //this.layoutExampleWeb = this.tempUpdateService.templateUpdate(this.layoutTag, this.layoutOptions, this.angularContainer, this.exampleOverlayLayoutWeb);
      }

      //angular template
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate(), true);
      //web component template
      //this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer, "", true);
    } else {
      if(this.panelType == 'inline') {
        //layout
        this.layoutExample = this.tempUpdateService.templateUpdate(this.triggerButtonTag, this.triggerButtonOptions, this.angularContainer, "", true);
        //this.layoutExampleWeb = this.tempUpdateService.templateUpdate(this.triggerButtonTag, this.triggerButtonWebOptions, this.angularContainer, "", true);
      } else {
        //layout
        this.layoutExample = this.tempUpdateService.templateUpdate(this.triggerButtonTag, this.triggerButtonOptions, this.angularContainer);
        //this.layoutExampleWeb = this.tempUpdateService.templateUpdate(this.triggerButtonTag, this.triggerButtonWebOptions, this.angularContainer);
        //angular template
        this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate(), false);
        //web component template
        //this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer, "", false);
      }
    }
  }
}
