import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-word-use',
  templateUrl: './content-word-use.component.html',
  styleUrls: ['./content-word-use.component.sass']
})
export class ContentWordUseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
