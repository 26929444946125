<app-canvas 
    [js]="true"  
    [ts]="true"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component">
    <div title>Button Group <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <div *ngIf="buttonType == 'icons'">
            <kn-button-group
                [layout]="layout"
                [selectionType]="selection"
                [compressed]="compressed"
                (btnClick)="btnClickEvent($event)"
                >
                    <div class="kn-button-group-container">
                        <kn-button [buttonId]="'exampleButton1'" [buttonType]="buttonTypeSelection"  [buttonIconLeft]="'fa-regular kic icon-ic-fpo'"></kn-button>
                        <kn-button [buttonId]="'exampleButton2'" [buttonType]="buttonTypeSelection"  [buttonIconLeft]="'fa-regular kic icon-ic-fpo'" [disabled]="btnDisabled"></kn-button>
                        <kn-button [buttonId]="'exampleButton3'" [buttonType]="buttonTypeSelection"  [buttonIconLeft]="'fa-regular kic icon-ic-fpo'"></kn-button>
                        <kn-button [buttonId]="'exampleButton4'" [buttonType]="buttonTypeSelection"  [buttonIconLeft]="'fa-regular kic icon-ic-fpo'"></kn-button>
                    </div>
                </kn-button-group>
        </div>
        <div *ngIf="buttonType == 'iconswithtext'">
            <kn-button-group
                [layout]="layout"
                [selectionType]="selection"
                [compressed]="compressed"
                (btnClick)="btnClickEvent($event)"
            >
                <div class="kn-button-group-container">
                    <kn-button [buttonId]="'exampleButton1'" [buttonType]="buttonTypeSelection" [buttonText]="'Button'" [buttonIconLeft]="'fa-regular kic icon-ic-fpo'"></kn-button>
                    <kn-button [buttonId]="'exampleButton2'" [buttonType]="buttonTypeSelection" [buttonText]="'Button'" [buttonIconLeft]="'fa-regular kic icon-ic-fpo'" [disabled]="btnDisabled"></kn-button>
                    <kn-button [buttonId]="'exampleButton3'" [buttonType]="buttonTypeSelection" [buttonText]="'Button'" [buttonIconLeft]="'fa-regular kic icon-ic-fpo'"></kn-button>
                    <kn-button [buttonId]="'exampleButton4'" [buttonType]="buttonTypeSelection" [buttonText]="'Button'" [buttonIconLeft]="'fa-regular kic icon-ic-fpo'"></kn-button>
                </div>
            </kn-button-group>
        </div>

        <div *ngIf="buttonType == 'text'">
            <kn-button-group
                [layout]="layout"
                [selectionType]="selection"
                [compressed]="compressed"
                (btnClick)="btnClickEvent($event)"
            >
                <div class="kn-button-group-container">
                    <kn-button [buttonId]="'exampleButton1'" [buttonType]="buttonTypeSelection" [buttonText]="'Button'" ></kn-button>
                    <kn-button [buttonId]="'exampleButton2'" [buttonType]="buttonTypeSelection" [buttonText]="'Button'" [disabled]="btnDisabled"></kn-button>
                    <kn-button [buttonId]="'exampleButton3'" [buttonType]="buttonTypeSelection" [buttonText]="'Button'" ></kn-button>
                    <kn-button [buttonId]="'exampleButton4'" [buttonType]="buttonTypeSelection" [buttonText]="'Button'" ></kn-button>
                </div>
            </kn-button-group>
        </div>

       <div class="test-area">{{action}}</div>
    </div>
    <div angular>
        
    </div>
    <div typeScript>
        <div *ngIf="showItemsObject">{{itemsObject}}</div>
    </div>
    <div javascript>
        <span class="code-comment">//Initialize the component and include your buttons</span>
       {{webInitButtonGroup}}
       <br/><br/>
       <span class="code-comment">//You can set click events on each button. First create a function to handle the button clicks</span>
       {{buttonGroupWebClickEvent}}
       <br/><br/>
       <span class="code-comment">//Create the context object</span>
       {{webContext}}
       <br/><br/>
       <span class="code-comment">//Add the context.btnClick to each button like this</span>
       {{addButtonClickEvent}}
        <br/><br/>
        <span class="code-comment">//If you choose not to use a template, you can use the "items" property to pass in an object array of buttons.</span>
        <span class="code-comment">//Below is an example of how to structure your data.</span>
       {{webItems}}
    </div>
    <div web></div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">btnClick():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">id:</span> "{{eventId}}"</div>
                    <div class="obj-item"><span class="label">label:</span> "{{eventLabel}}"</div>
                    <div class="obj-item"><span class="label">active: {{eventActive}}</span> </div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-input">
            <kn-dropdown 
                #knInput 
                [label]="'Selection type'" 
                [value]="selectionType" 
                [items]="selectionObj" 
                [fields]="selectionTypeFields" 
                (onSelect)="selectTypeEvent($event)"> 
            </kn-dropdown>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Layout</div>
        <div class="item-row">
            <kn-radio-button 
                [radioId]="'standardLayout'" 
                [value]="'row'" 
                [(ngModel)]="layout" 
                [defaultValue]="layout" 
                [name]="'layout'" 
                [label]="'Horizontal'"
                (onSelect)="layoutEvent($event)">
            </kn-radio-button>
        </div>
        <div class="item-row">
            <kn-radio-button 
                [radioId]="'colLayout'" 
                [value]="'column'" 
                [(ngModel)]="layout" 
                [defaultValue]="layout" 
                [name]="'layout'" 
                [label]="'Vertical'"
                (onSelect)="layoutEvent($event)">
            </kn-radio-button>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Button Variant</div>
        <div class="item-row">
            <kn-radio-button 
                [radioId]="'outlined'" 
                [value]="'kn-tertiary'" 
                [(ngModel)]="buttonClass" 
                [defaultValue]="buttonClass" 
                [name]="'buttonType'" 
                [label]="'Outlined'"
                (onSelect)="buttonTypeEvent($event)">
            </kn-radio-button>
        </div>
        <div class="item-row">
            <kn-radio-button 
                [radioId]="'filled'" 
                [value]="'kn-secondary'" 
                [(ngModel)]="buttonClass" 
                [defaultValue]="buttonClass" 
                [name]="'buttonType'" 
                [label]="'Filled'"
                (onSelect)="buttonTypeEvent($event)">
            </kn-radio-button>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Button Options</div>
        <div class="item-row">
            <kn-radio-button 
                [radioId]="'iconstextonly'" 
                [value]="'text'" 
                [(ngModel)]="buttonType" 
                [defaultValue]="buttonType" 
                [name]="'type'" 
                [label]="'Text only'"
                (onSelect)="buttonContent($event)">
            </kn-radio-button>
        </div>
        <div class="item-row">
            <kn-radio-button 
                [radioId]="'iconstext'" 
                [value]="'iconswithtext'" 
                [(ngModel)]="buttonType" 
                [defaultValue]="buttonType" 
                [name]="'type'" 
                [label]="'Icons with text'"
                (onSelect)="buttonContent($event)">
            </kn-radio-button>
        </div>
        <div class="item-row">
            <kn-radio-button 
                [radioId]="'iconsonly'" 
                [value]="'icons'" 
                [(ngModel)]="buttonType" 
                [defaultValue]="buttonType" 
                [name]="'type'" 
                [label]="'Icon only'"
                (onSelect)="buttonContent($event)">
            </kn-radio-button>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Data object'" 
                (value)="showItemsEvent($event);" 
                [(ngModel)]="showItemsObject"
                name="compressed">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Small size'" 
                (value)="compressedEvent($event);" 
                [(ngModel)]="compressed"
                name="compressed">
            </kn-toggle>
        </div>
        <div class="item-row">
            <div class="toggle-info">
                <kn-toggle 
                    [label]="'Disabled state'" 
                    (value)="disabledBtnEvent()" 
                    [(ngModel)]="btnDisabled"
                    name="dismissible">
                </kn-toggle>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="{{disabledTooltip}}" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <div class="item-row">
            <kn-toggle label="Click event" [(ngModel)]="clickEvent" (value)="buttonGroupClickEvent($event)"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">componentId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"exampleButtonGroup"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">layout</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"column"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">selectionType</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"multi", "single"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">compressed</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">btnClick</div>
                <div class="item"><i>event</i></div>
                <div class="item">{{'{}'}}</div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <button-group-usage></button-group-usage>
    </div>
    <div designSpec>
        <button-group-specs></button-group-specs>
    </div>
    <div accessibility>
        <button-group-accessibility></button-group-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">March 14, 2024</div>
                <div class="item">1.1.0</div>
                <div class="item">
                    <ul>
                        <li>Added "items" property to pass in an object array of button data</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">November 14, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created Button group component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
</app-canvas>