<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Layout Background</div>
        
        <section>
            <div class="kn-headline-24-med">Color for layout background</div>
        </section>

        <section>
            <div class="text">To produce a clean, professional, and highly readable UI, we have chosen a minimalist approach that defines a light color value from the grayscale palette for the base canvas of the application presentation and a white background for the panel and card layout components that display on top of this canvas.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/layout background/KrakenDSM_StyleGuide_Color_LayoutBackground_01.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Modal Section Background</span></div>
            <div class="text"><span class="kn-bold">12. Gray, Lighter:</span> #F0F2F4</div>
            <span class="codesnippet">Theme variable: <pre><code>$gray-lighter</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--gray-lighter</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Canvas Background</span></div>
            <div class="text"><span class="kn-bold">13. Gray, Lightest:</span> #F4F6F7</div>
            <span class="codesnippet">Theme variable: <pre><code>$gray-lightest</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--gray-lightest</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Panel/ Card Background</span></div>
            <div class="text"><span class="kn-bold">14. White:</span> #FFFFFF</div>
            <span class="codesnippet">Theme variable: <pre><code>$white</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--white</code></pre></span>
        </section>
    </div>
</div>