<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">The <span class="kn-bold">Contextual Menu </span> component functions as a sub-menu of options that is typically displayed as a single-select, drop-down list when users interact with it. Users can select from pre-defined actions in relation to an item on the application screen. </div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Single tier </span> </div>
            <div class="text"><span class="kn-bold">2.	Multi tier </span> </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_02.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Label-only </span> </div>
            <div class="text"><span class="kn-bold">2.	Label with icon </span> </div>
            <div class="text"><span class="kn-bold">3.	Label with icon and value </span> </div>
        </section>


        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_03.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Enabled </span> </div>
            <div class="text"><span class="kn-bold">2.	Hover/ focused </span> </div>
            <div class="text"><span class="kn-bold">3.	Disabled </span> </div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_04.png">

        <section>
            <div class="kn-subtitle-16">Single tier</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Icon </span> </div>
            <div class="text"><span class="kn-bold">B.	Menu item label </span> </div>
            <div class="text"><span class="kn-bold">C.	Menu item value </span> </div>
            <div class="text"><span class="kn-bold">D.	Divider line: </span>An option used to differentiate between menu option sections. </div>
            <div class="text"><span class="kn-bold">E.	Menu overflow </span> </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_05.png">

        <section>
            <div class="kn-subtitle-16">Multi tier</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Drill-in chevron icon </span> </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Nested</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_06.png">

        <section>
            <section>
                <div class="text">The contextual menu can be nested, where users access submenus from the initial menu, which gives them access to a range of related actions or functionalities within a specific context.</div>
            </section>

            <section>
                <div class="text">It is recommended to limit the number of submenus to a maximum of two levels in order to maintain simplicity and help users quickly access their desired options without feeling overwhelmed with a complex navigation. If more submenus or options are necessary, consider alternative navigation patterns such as a dropdown menu.</div>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_07.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Positioning</div>

                <div class="text">Submenus are aligned with an offset to horizontally overlap the parent menu and vertically align the submenu item with the parent menu item.</div>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_08.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Drill-in chevron icon</div>

                <div class="text">When a menu item contains a submenu, a drill-in chevron icon appears on the right side of the menu item to show that a submenu is available.</div>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_09.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Label</div>

                <div class="text">A menu item always includes a label that clearly describes the action or option that it represents:</div>
            </section>

            <section>
                <ul class="bullets">
                    <li>Use clear and concise wording. Use short, descriptive labels that clearly convey the purpose of each item. Avoid lengthy or ambiguous text that might confuse users about the intended action.</li>
                    <li>Use action verbs for action-oriented labels to indicate what action is performed when the menu item is selected (for example, “Edit”, “Delete”, “Save,” and so on).</li>
                    <li>Use sentence case in display of the menu item text.</li>
                </ul>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_10.png">
        
        <section>
            <div class="kn-subtitle-16">Icon</div>

            <div class="text">A menu item can include an icon, which is not purely for decoration. Use an icon only when necessary and when it is strongly associated with the label text.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_11.png">

        <section>
            <div class="kn-subtitle-16">Value</div>

            <div class="text">A menu item can include an icon, but not purely for decoration. Use an icon only when necessary and when it has a strong association with the label text.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_12.png">


        <section>
            <section>
                <div class="kn-subtitle-16">Size</div>

                <div class="text">The contextual menu width is variable based on the length of the menu item label (with or without an icon) with the following guidelines:</div>
            </section>

            <section>
                <ul class="bullets">
                    <li>The minimum contextual menu width is <span class="kn-bold">132px </span>(regardless of how few characters are used in the menu item label).</li>
                    <li>The width of the menu is dynamic and is based on the character length of the longest option item listed. The recommendation is to not to exceed <span class="kn-bold">264px</span>.</li>
                    <li>If the label for a menu item exceeds the recommended menu width, ensure the text wraps to a second line instead of truncating text.</li>
                    <li>All levels of contextual menus are not responsive and do not adapt to different screen sizes and content lengths.</li>
                </ul>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_13.png">

        <section>
            <div class="kn-subtitle-16">Menu height and overflow</div>

            <div class="text">The display height (depth) of the contextual menu should not exceed <span class="kn-bold">six menu options or 260px</span>. When more than six menu options are available, an overflow indicator is displayed at the bottom of the menu.</div>
        </section>

        <section>
            <div class="text">This indicator is positioned above the menu item list and is displayed with a white background, gradient blur and a downward-positioned caret icon, which hints to the user that more options are available. When users hover over this section, the contextual menu scrolls downward through the list of menu items. Halfway through scrolling, a second overflow is displayed at the top of the menu, which indicates that users can scroll back to the top of the list.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Gradient blur </span> </div>
            <div class="text"><span class="kn-bold">B.	Overflow background </span> </div>
            <div class="text"><span class="kn-bold">C.	Overflow icon </span> </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_14.png">

        <section>
            <div class="kn-subtitle-16">Ordering of menu options</div>

            <div class="text">Place action options listed in the contextual menu in a logical order by sequence of the user workflow or hierarchy in frequency of potential use.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_15.png">

        <section>
            <div class="kn-subtitle-16">Position</div>

            <div class="text">The position of a menu on the screen affects where and how it appears. By default, submenus open to the right of the parent. However, if sufficient room is not available to display the combination parent and submenus to the right, the menu automatically expands to the left (to avoid being cropped).</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Right side: </span>Display submenus to the right of the parent menu by default.</div>
            <div class="text"><span class="kn-bold">2.	Left side: </span>If the parent menu is displayed in the far-right side of the application and sufficient room is not available to display the combination parent and submenus to the right of it, the menu automatically opens aligned to the left edge of the parent menu instead.</div>
            <div class="text"><span class="kn-bold">3.	Bottom alignment: </span>Display the nested menu bottom aligned to the parent menu when space below the parent menu is limited.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_16.png">

        <section>
            <div class="kn-subtitle-16">Menu section and section dividers</div>

            <div class="text">Menu sections contain groupings of related menu items.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Rounded corners</div>

            <div class="text">Contextual menu containers are displayed with a 4px rounded corner.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Transitions</div>

            <div class="text">Contextual menus use a fade transition pattern to enter and exit the screen.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_17.png">

        <section>
            <div class="kn-subtitle-16">Text overflow</div>

            <div class="text">Wrap text to a second line when the menu item label character length exceeds the maximum menu width.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Menu trigger</div>

            <div class="text">To trigger a contextual menu, use either a clearly labeled action button or an icon button that appears next to the relevant content or via right-clicking (in the context it would be naturally expected.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Dismissal</div>

            <div class="text">The dismissal of contextual menus can be triggered either by the selection of a menu item or by clicking anywhere outside the menu area.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_18.png">

        <section>
            <div class="kn-subtitle-16">Ellipses in menu items</div>

            <div class="text">Avoid using ellipses (…) in the menu item. An ellipsis often implies that the action for a menu item opens in a new view or that users are taken elsewhere.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_19.png">

        <section>
            <div class="kn-subtitle-16">Don't use descriptions in menu items</div>

            <div class="text">Ensure menu items are not overloaded with descriptions.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/contextualmenu/KrakenDSM_Components_ContextualMenu_20.png">
 
        <section>
            <div class="kn-subtitle-16">Don't display multiple open menus</div>

            <div class="text">Allow for only one contextual menu to be opened at a time. When a contextual menu trigger object is selected, ensure any other contextual menus that are currently open are automatically closed.</div>
        </section>
    </div>
</div>