import { Component } from '@angular/core';

@Component({
  selector: 'date-range-picker-specs',
  templateUrl: './date-range-picker-specs.component.html',
  styleUrls: ['./date-range-picker-specs.component.sass']
})
export class DateRangePickerSpecsComponent {
  public component = window.location.protocol + '//' + window.location.host + '/design-specs-measure/date-range-picker/';

  constructor() { }

  ngOnInit(): void {
  }

  clickEvent(route) {
    if(route == 'light') {
      window.open(this.component, "_blank");
    }
  }

}
