import { Component } from '@angular/core';

@Component({
  selector: 'date-range-picker-usage',
  templateUrl: './date-range-picker-usage.component.html',
  styleUrls: ['./date-range-picker-usage.component.sass']
})
export class DateRangePickerUsageComponent {

}
