import { Component, Output, EventEmitter, forwardRef, Input, ViewChild, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, ControlValueAccessor } from "@angular/forms";

@Component({
  selector: 'kn-textarea',
  templateUrl: './kraken-textarea.component.html',
  styleUrls: ['./kraken-textarea.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => KrakenTextareaComponent), 
      multi: true
    }
  ]
})
export class KrakenTextareaComponent implements OnInit, AfterViewInit, ControlValueAccessor {
  @Output() onValueChange = new EventEmitter<any>();
  @Output() onValidate= new EventEmitter<any>();
  @Input() control:FormGroup;
  @Input() label: string;
  @Input() value: any = '';
  @Input() textareaId: string;
  @Input() name: string;
  @Input() setDisabled = false;
  @Input() customError = false;
  @Input() customWarning = false;
  @Input() customMess: string;
  @Input() setRequired = false;
  @Input() setReadOnly = false;
  @Input() setPlaceholder = "";
  @Input() rows: number;
  @Input() cols: number;
  @Input() showCharCount = false;
  @Input() maxChars = 0;
  @Input() maxLength = 0;
  @Input() optional = false;
  @Input() hideErrorMessage = false;

  @ViewChild('textAreaField') textAreaField;

  public charCountErrors = false;
  public textArea: any;
  public textareaLeftPos;
  public textareaBottomPos;
  public textareaWidth;
  
  constructor(private elm: ElementRef) { }

  ngOnInit(): void {
    this.textareaId = this.textareaId ? this.textareaId : 'knTextarea_' + Math.floor(Math.random()*90000) + 10000;
  }

  ngAfterViewInit(): void {
    this.textArea = this.elm.nativeElement.querySelector('#' + this.textareaId);
    
    window.addEventListener('scroll', (e) => {
      this.textareaPosHandler();
    },true);

    window.addEventListener('resize', () => {
      this.textareaPosHandler();
    });

    //check for errors on load of component
    setTimeout(() => {
      this.onValidation();
      this.textareaPosHandler();
    }, 500);
  }

  propagateChange = (_: any) => {};
  public onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(val: any) {
    this.propagateChange(val);
    this.onValueChange.emit(this.value);
    if(this.showCharCount && this.value?.length > this.maxChars) {
      this.charCountErrors = true;
    } else {
      this.charCountErrors = false;
    }
    
    this.onValidation();
  }

  onValidation() {
    let isInvalid;
    if(
      this.textAreaField.control.errors == null && 
      !this.customError && 
      !this.charCountErrors
      ) {
        isInvalid = false;
    } else {
      isInvalid = true;
    }
    
    this.onValidate.emit({invalid: isInvalid});
  }

  clear() {
    this.value = '';
  }

  textareaPosHandler() {
    this.textareaLeftPos = this.textArea.getBoundingClientRect().left + 'px';
    this.textareaBottomPos = this.textArea.getBoundingClientRect().bottom + 'px';
    this.textareaWidth = this.textArea.getBoundingClientRect().width + 'px';
  }
}
