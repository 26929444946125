import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'kn-icon-button',
  templateUrl: 'kraken-icon-button.component.html',
  styleUrls: ['./kraken-icon-button.component.sass']
})
export class KrakenIconButtonComponent implements OnInit {
  @Input() buttonId: string;
  @Input() buttonType: string;
  @Input() icon: string;
  @Input() disabled = false;
  @Input() dropShadow = false;
  @Input() active = false;
  @Output() btnClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
		this.btnClick.emit(
      {
        id: this.buttonId,
        type: this.buttonType
      });
	}

}
