<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Easing</div>
        
        <section>
            <div class="kn-headline-24-med">Easing</div>
        </section>
        
        <section>
            <div class="text">Easing adjusts an animation's rate of change, allowing transitioning elements to speed up and slow down, rather than move at a constant rate.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Types of easing</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Standard easing</span> </div>

            <div class="text">Standard easing subtly brings attention to the end of an animation by taking more time to decelerate than accelerate. Standard easing is the most common form of easing.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Accelerated easing</span> </div>

            <div class="text">Elements exiting a screen use accelerated easing, a transition in which the element starts at rest and ends at peak velocity.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Decelerated easing</span> </div>

            <div class="text">Incoming elements are animated using decelerated easing, where transitions begin at peak velocity (the fastest point in an element's movement) and end at rest.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Transition area</div>
        </section>
        
        <section>
            <div class="text">Transitions covering small areas of the screen have shorter durations than those that traverse larger ones.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Immediate response/ Small moves</div>

            <div class="text">Elements with small transition areas, such as icons and selection controls, have short durations.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/motion/easing/KrakenDSM_StyleGuide_Motion_Easing_01.gif">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Toggle</span></div>
        </section>

        <section>            
            <img class="kn-responsive-img" src="assets/style guide/motion/easing/KrakenDSM_StyleGuide_Motion_Easing_02.gif">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Icon</span></div>
        </section>

        <section>            
            <img class="kn-responsive-img" src="assets/style guide/motion/easing/KrakenDSM_StyleGuide_Motion_Easing_03.gif">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Menu dialog</span></div>
        </section>



        <section>
            <img class="kn-responsive-img" src="assets/style guide/motion/easing/KrakenDSM_StyleGuide_Motion_Easing_04.gif">
        </section>

        <section>
            <div class="kn-subtitle-16">Large moves</div>

            <div class="text">Elements with larger transition areas, such as the details panel, have slightly longer durations.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/style guide/motion/easing/KrakenDSM_StyleGuide_Motion_Easing_05.gif">
        </section>

        <section>
            <div class="kn-subtitle-16">Extended moves</div>

            <div class="text">Animated elements that traverse a large portion of the screen have the longest durations.</div>
        </section>



        <section>
            <div class="kn-headline-24-med">Direction and orientation</div>
        </section>

        <section>
            <div class="text">The direction and orientation determine how elements behave within Kraken.</div>
            <ul>
                <li>Direction gives users the ultimate sense of where an object comes from and where it is going.</li>
                <li>Digital elements use 3-axis directions (x,y,z) to emulate real-life movement.</li>
            </ul>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/motion/easing/KrakenDSM_StyleGuide_Motion_Easing_06.gif">
        </section>

        <section>
            <div class="kn-subtitle-16">Move elements</div>

            <div class="text">In this case, objects are repositioned. Elements enter, exit, or remain on the screen. The direction defines the spatial placement of the element according to its purpose. Elements that come in and out of the screen should follow a consistent pattern.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/motion/easing/KrakenDSM_StyleGuide_Motion_Easing_07.gif">
        </section>

        <section>
            <div class="kn-subtitle-16">Rotate elements</div>

            <div class="text">Elements can rotate as long as this serves a meaningful purpose, and is not just a normal transition for showing content.</div>
        </section>

    </div>
</div>