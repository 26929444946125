<app-canvas 
    [js]="true"
    [isEventTabDisabled]="true" 
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef">
    <div title>Spinner <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <div class="spinner-demo-container">
            <kn-spinner [isLoading]="isLoading" [size]="size" [theme]="theme"></kn-spinner>
        </div>
    </div>
    <div angular>
        
    </div>
    <div web>
        
    </div>
    <div javascript>
        <span class="code-comment">//Create an instance of the spinner</span>
       {{spinnerInstance}}
       <br/><br/>
       <span class="code-comment">//Set the spinner to show</span>
       {{spinnerLoading}}
    </div>
    <div events>
       
    </div>
    <div customproperties>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-radio-button 
                [id]="'spinnerLg'" 
                [label]="'Large'" 
                [value]="'large'" 
                [name]="'spinnerSize'" 
                [(ngModel)]="size" 
                [defaultValue]="size"
                (onSelect)="spinnerSize($event, 'large')">
            </kn-radio-button> 
        </div>
        <div class="item-row">
            <kn-radio-button 
                [id]="'spinnerMd'" 
                [label]="'Default'" 
                [value]="'medium'" 
                [name]="'spinnerSize'" 
                [(ngModel)]="size" 
                [defaultValue]="size"
                (onSelect)="spinnerSize($event, 'medium')">
            </kn-radio-button> 
        </div>
        <div class="item-row">
            <kn-radio-button 
                [id]="'spinnerSm'" 
                [label]="'Small'" 
                [value]="'small'" 
                [name]="'spinnerSize'" 
                [(ngModel)]="size" 
                [defaultValue]="size"
                (onSelect)="spinnerSize($event, 'small')">
            </kn-radio-button> 
        </div>
        <div class="kn-spacer"></div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">isLoading</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">size</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"large", "medium", "small"</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <spinner-usage></spinner-usage>
    </div>
    <div designSpec>
        <spinner-specs></spinner-specs>
    </div>
    <div accessibility >
        <spinner-accessibility></spinner-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">August 15, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed background overlay z-index</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">June 15, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the spinner component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

