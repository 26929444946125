import { Component, OnInit, ElementRef } from '@angular/core';
import { NavigationService } from '../../app/services/navigation.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  constructor(private elm: ElementRef, public nav: NavigationService) { }

  ngOnInit(): void {
    
  }

  navLink(group, subGroup) {
    const accordion = document.querySelector('.kn-accordion');
    this.nav.accordionNavigation(accordion, group, subGroup);
  }

}
