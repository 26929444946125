<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Notifications & Alerts</div>
        
        <section>
            <div class="text">Notification and alert colors should be used to indicate specific states. Green is used to support good and successful confirmation messages. Red is used to emphasize severe system failure and error messages. Orange is used to support uncertain states and warning messages.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/notifications/KrakenDSM_StyleGuide_Color_Notifications_01.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/notifications/KrakenDSM_StyleGuide_Color_Notifications_02.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Passive (Gray)</span></div>
            <div class="text"><span class="kn-bold">04. Ink, Light #434C54 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$ink-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--ink-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Confirmation (Green)</span></div>
            <div class="text"><span class="kn-bold">02. Green, Dark #486111 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$green-dark</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--$green-dark</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Error (Red)</span></div>
            <div class="text"><span class="kn-bold">03. Danger #A50D08 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$knDanger</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-danger</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Warning (Orange)</span></div>
            <div class="text"><span class="kn-bold">03. Secondary #B8551E </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$secondary_one</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--secondary_one</code></pre></span>
        </section>
    </div>
</div>