<app-canvas 
    [js]="true"  
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component"
    [isEventTabDisabled]="true" 
    [ts]="true"
    [htmlCodeLabel]="'app.component.html'">
    <div title>Toast <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-button 
            [buttonId]="'exampleButton'" 
            [buttonType]="'kn-tertiary'" 
            [buttonText]="'show toast'"
            (btnClick)="showToast()">
        </kn-button>
        <kn-toast [compressedMasthead]="compressed"></kn-toast>
    </div>
    <div angular>
        <br/>
        <span class="code-comment">&lt;!-- the toast component only needs to be referenced once, you should place it in the app.component.html for global use</span>
    </div>
    <div typeScript>
        <span class="code-comment">/*import the toast service</span>
        <span class="code-comment">call the service and provide the "type" and "message" as shown below */</span>
        {{toastServiceCode}}
        <br/>
        <br/>
        <span class="code-comment">/*You can add a custom icon as shown below*/</span>
        {{customIcon}}
    </div>
    <div javascript>
        <span class="code-comment">/*the toast component only needs to be referenced once, but you can make unlimited calls to it. 
            <br/>place the toast component in a spot that is globally used by the application
            <br/>you can bind to it as shown below</span>
            <span class="code-comment">NOTE: one limitation with this service, multiple toast messages cannot come in at the "exact" same time, each message needs to be at least 20 thousand milliseconds apart */</span>
        {{webJavascript}}
    </div>
    <div web></div>
    <div events>
        <!-- <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onSuccess():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">id:</span> "{{onSuccessItems?.id}}"</div>
                    <div class="obj-item"><span class="label">label:</span> "{{onSuccessItems?.label}}"</div>
                    <div class="obj-item"><span class="label">subText:</span> "{{onSuccessItems?.subText}}"</div>
                    <div class="obj-item"><span class="label">value:</span> "{{onSuccessItems?.value}}"</div>
                </div>
            </div>
        </div> -->
    </div>
    <div customproperties>
        <div class="item-row">
            <div class="radio-info">
                <kn-radio-button 
                    [radioId]="'toastSuccess'" 
                    [value]="'success'" 
                    [(ngModel)]="toastType" 
                    [defaultValue]="toastType" 
                    [name]="'toastType'" 
                    [label]="'Confirmation variant'"
                    (onSelect)="toastServiceEvent($event.value)">
                </kn-radio-button>
            </div>
        </div>
        <div class="item-row">
            <div class="radio-info">
                <kn-radio-button 
                    [radioId]="'standardRd'" 
                    [value]="'info'" 
                    [(ngModel)]="toastType" 
                    [defaultValue]="toastType" 
                    [name]="'toastType'" 
                    [label]="'Destructive variant'"
                    (onSelect)="toastServiceEvent($event.value)">
                </kn-radio-button>
            </div>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <div class="toggle-info">
                <kn-toggle 
                    [label]="'Compact header'" 
                    (value)="template()" 
                    [(ngModel)]="compressed"
                    name="compressed">
                </kn-toggle>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="{{tooltipCompressed}}" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">compressedMasthead</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>"true"</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <toast-usage></toast-usage>
    </div>
    <div designSpec>
        <toast-specs></toast-specs>
    </div>
    <div accessibility>
        <toast-accessibility></toast-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">November 14, 2023</div>
                <div class="item">1.1.0</div>
                <div class="item">
                    <ul>
                        <li>added ability to add a custom icon</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">October 6, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created Toast component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
</app-canvas>