import { Component, Output, EventEmitter, forwardRef, Input, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, ControlValueAccessor  } from "@angular/forms";

@Component({
  selector: 'kn-textbox',
  templateUrl: './kraken-textbox.component.html',
  styleUrls: ['./kraken-textbox.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => KrakenTextboxComponent), 
      multi: true
    }
  ]
})
export class KrakenTextboxComponent implements OnInit, AfterViewInit, ControlValueAccessor   {
  @Output() onValueChange = new EventEmitter<any>();
  @Output() onValidate= new EventEmitter<any>();
  @Output() onClear= new EventEmitter<any>();
  @Input() control:FormGroup;
  @Input() label: string;
  @Input() value = '';
  @Input() type: string;
  @Input() textboxId: string;
  @Input() name: string;
  @Input() setDisabled = false;
  @Input() emailValidator = false;
  @Input() customError = false;
  @Input() customWarning = false;
  @Input() customMess: string;
  @Input() setRequired = false;
  @Input() setReadOnly = false;
  @Input() setPlaceholder = "";
  @Input() clearIcon = false;
  @Input() showCharCount = false;
  @Input() maxChars = 0;
  @Input() maxLength = 0;
  @Input() minLength = 0;
  @Input() optional = false;
  @Input() compressed = false;
  @Input() password = false;
  @Input() minNumber = null;
  @Input() maxNumber = null;
  @Input() ariaLabel: string;
  @Input() hideErrorMessage = false;
  @Input() numericCharsOnly = false;

  @ViewChild('inputField') inputField;

  public showPassword = false;
  public textbox: any;
  public charCountErrors = false;
  public textboxLeftPos;
  public textboxBottomPos;
  public textboxWidth;

  constructor(private elm: ElementRef) { }

  ngOnInit() {
    this.textboxId = this.textboxId ? this.textboxId : 'knTextbox_' + Math.floor(Math.random()*90000) + 10000;
  }

  ngAfterViewInit(): void {
    this.textbox = this.elm.nativeElement.querySelector('#' + this.textboxId);
  
    window.addEventListener('scroll', (e) => {
      this.textboxPosHandler();
    },true);

    window.addEventListener('resize', () => {
      this.textboxPosHandler();
    });

     //check for errors on load of component
     setTimeout(() => {
      this.textboxPosHandler();
      this.onValidation();
    }, 500);
  }

  propagateChange = (_: any) => {};
  public onTouched: any = () => {};

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(val: any) {
    this.propagateChange(val);
    this.onValueChange.emit(this.value);
    if(this.showCharCount && this.value?.length > this.maxChars) {
      this.charCountErrors = true;
    } else {
      this.charCountErrors = false;
    }

    this.onValidation();
  }

  onValidation() {
    let isInvalid;
    if(
      this.inputField.control.errors == null && 
      !this.customError && 
      !this.charCountErrors
      ) {
        isInvalid = false;
    } else {
      isInvalid = true;
    }
    

    this.onValidate.emit({invalid: isInvalid});
  }

  clear() {
    this.value = "";
    this.onModelChange(this.value);
    this.textbox.focus();
    this.onClear.emit(this.value);
    this.onValidation();
  }

  showHidePasswordVal() {
    this.showPassword = !this.showPassword;
    this.type = this.showPassword ? 'text' : 'password';
  }

  textboxPosHandler() {
    this.textboxLeftPos = this.textbox.getBoundingClientRect().left + 'px';
    this.textboxBottomPos = this.textbox.getBoundingClientRect().bottom + 'px';
    this.textboxWidth = this.textbox.getBoundingClientRect().width + 'px';
  }
}
