<app-canvas 
    [js]="true"  
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component">
    <div title>Progress Indicator <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-progress-indicator 
            [label]="progressLabel" 
            [subText]="progressSubText" 
            [value]="progressValue"
            [error]="progressType == 'error'"
            [inline]="inlineProgressBar"
            [disabled]="progressDisabled"
            [queued]="queued"
            [errorMessage]="errorMessage"
            (onSuccess)="success($event)">
        </kn-progress-indicator>
    </div>
    <div angular>

    </div>
    <div typeScript>
       
    </div>
    <div javascript>
        <span class="code-comment">//Create an instance of the progress indicator and set the value</span>
        {{webInstance}}
    </div>
    <div web></div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onSuccess():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">id:</span> "{{onSuccessItems?.id}}"</div>
                    <div class="obj-item"><span class="label">label:</span> "{{onSuccessItems?.label}}"</div>
                    <div class="obj-item"><span class="label">subText:</span> "{{onSuccessItems?.subText}}"</div>
                    <div class="obj-item"><span class="label">value:</span> "{{onSuccessItems?.value}}"</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-input">
            <kn-textbox 
                [label]="'Label'" 
                [value]="progressLabel" 
                (onValueChange)="label($event)" 
                [(ngModel)]="progressLabel"
                [clearIcon]="true"
                [setDisabled]="inlineProgressBar">
            </kn-textbox>
        </div>
        <div class="action-input">
            <kn-textbox 
                [label]="'Sub text'" 
                [value]="progressSubText" 
                (onValueChange)="subText($event)" 
                [(ngModel)]="progressSubText"
                [clearIcon]="true"
                [setDisabled]="inlineProgressBar">
            </kn-textbox>
        </div>
        <div class="action-input">
            <kn-textbox 
                [label]="'Progress value'" 
                [type]="'number'" 
                [value]="progressValue" 
                [minlength]="0"
                [maxLength]="100"
                (onValueChange)="value($event)" 
                [(ngModel)]="progressValue"
                [clearIcon]="true"
                [setDisabled]="queued">
            </kn-textbox>
        </div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Inline'" 
                (value)="inlineProgressEvent($event)" 
                [(ngModel)]="inlineProgressBar"
                name="inlineProgress">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Show error'" 
                (value)="type($event, 'error')" 
                [(ngModel)]="showError"
                name="progressError"
                [disabled]="showWarning || showSuccess">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Queued state'" 
                (value)="queuedEvent($event);" 
                [(ngModel)]="queued"
                name="inlineProgress">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Disabled'" 
                (value)="controlEvent($event, '[disabled]=', progressDisabled, true);" 
                [(ngModel)]="progressDisabled"
                name="inlineProgress"
                [disabled]="showError || showSuccess">
            </kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">componentId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"progressComponentId"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">label</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Progress indicator label"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">subText</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Helper text"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">value</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>50</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">error</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">errorMessage</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Custom error message here"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">hideValue</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">queued</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onSuccess</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{}'}}</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <progress-indicator-usage></progress-indicator-usage>
    </div>
    <div designSpec>
        <progress-indicator-specs></progress-indicator-specs>
    </div>
    <div accessibility>
        <progress-indicator-accessibility></progress-indicator-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>Added a queued property</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the panel component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
</app-canvas>