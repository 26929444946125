<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text"><span class="kn-bold">Icon Buttons </span> perform actions using interactive icons without labels. They may be displayed flat on the UI or with elevation as a floating action button (FAB). These buttons are best reserved for very simple actions and should always include tooltips on hover to provide more context for the icon. </div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Types/States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/icon-button/KrakenDSM_Components_IconButtons_01a.png">
        
        <section>
            <div class="kn-subtitle-16">Floating action button (FAB)</div>

            <div class="text">The floating action button is a circular button that hovers above the user interface. It serves as a prominent and easily accessible interactive element used to trigger a primary or frequently performed action. Floating action buttons are displayed with a drop shadow. </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Primary: </span>(high emphasis) Used as a call-to-action within the interface layout, drawing user attention to the trigger used to invoke a key action in the primary path of the application user flow. </div>
            <div class="text"><span class="kn-bold">2.	Secondary: </span>(medium emphasis) The secondary action button is less pronounced, visually identifying a trigger used to invoke an action associated with an alternative path of the application user flow. </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/icon-button/KrakenDSM_Components_IconButtons_01b.png">
        
        <section>
            <div class="kn-subtitle-16">Flat icon button</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Primary: </span> (high emphasis) </div>
            <div class="text"><span class="kn-bold">2.	Secondary: </span> (medium emphasis) </div>
            <div class="text"><span class="kn-bold">3.	Flat: </span> (low emphasis)</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/icon-button/KrakenDSM_Components_IconButtons_02.png">

        <section>
            <div class="text"><span class="kn-bold">A.	Icon </span> </div>
            <div class="text"><span class="kn-bold">B.	Touch zone container </span> </div>
        </section>

        <section>
            <div class="kn-headline-24-med">Size</div>
        </section>

        <section>
            <div class="text">Icon buttons come in three different sizes: default, medium and small. Default is primarily reserved for floating action buttons (FAB), while the medium and small sizes are primarily reserved for flat icon buttons. </div>        
        </section>

        <section>
            <div class="text"><span class="kn-bold">Default: </span> The default size has an icon displayed at <span class="kn-bold">20px</span> with a touch zone container of <span class="kn-bold">40px</span>.</div>
            <div class="text"><span class="kn-bold">Medium: </span> The medium size has an icon displayed at <span class="kn-bold">20px</span> with a touch zone container of <span class="kn-bold">32px</span>.</div>
            <div class="text"><span class="kn-bold">Small: </span> The small size has an icon displayed at <span class="kn-bold">16px</span> with a touch zone container of <span class="kn-bold">28px</span>.</div>
        </section>   


        <img class="kn-responsive-img" src="assets/usage guidelines/icon-button/KrakenDSM_Components_IconButtons_03a.png">

        <section>
            <div class="kn-subtitle-16">Floating action button</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Default </span> </div>
            <div class="text"><span class="kn-bold">2.	Small </span> </div>
        </section>
        
        <img class="kn-responsive-img" src="assets/usage guidelines/icon-button/KrakenDSM_Components_IconButtons_03b.png">

        <section>
            <div class="kn-subtitle-16">Flat icon button</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Default </span> </div>
            <div class="text"><span class="kn-bold">2.	Medium </span> </div>
            <div class="text"><span class="kn-bold">3.	Small </span> </div>
        </section>    
            
        <section>
            <div class="kn-headline-24-med">Container</div>
        </section>

        <section>
            <div class="text">Icon buttons are displayed in a circular touch zone container. Floating action buttons (FAB) can be displayed in either a primary (high emphasis) or secondary (medium emphasis) depending on application.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>
        
        <section>
            <div class="kn-subtitle-16">Icon style</div>
            <div class="text">An icon symbol implemented as an icon button should consistently align with the Kraken DS guidelines Iconography. The designer should first consult the design system icon catalog before exploring alternate symbols for the icon button. Alternate symbols considered should be pulled from the Font Awesome Pro (Regular) collection.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Icons metaphor</div>
            <div class="text">The symbol selected for use as an icon button should visually reflect a metaphor consistent with the intended meaning and purpose that the button serves. Obscure symbol analogies lead to user confusion when attempting to conduct a task in the application UI. Keep it simple and don't resist utilizing traditional visual metaphors commonly found in software UI designs, both past and present. In cases where the available symbol may need further clarification for the user, consider including a tooltip that labels the purpose of the button or opting to utilize a flat button with icon so that a text label accompanies it.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/icon-button/KrakenDSM_Components_IconButtons_04.png">

        <section>
            <div class="kn-subtitle-16">Interaction states</div>
            <div class="text">Differentiate the icon button from the mistaken interpretation as a static graphical display element by allocating additional padding between elements in addition to implementing appropriate button interaction states such as on-hover, pressed, and disabled.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/icon-button/KrakenDSM_Components_IconButtons_05.png">

        <section>
            <div class="kn-subtitle-16">Grouping icon buttons</div>
            <div class="text">When more than one icon button is utilized to perform a function impacting shared content or data in the application UI, consider grouping the display of these buttons in a common juxtaposition in the layout container to provide greater ease in the User locating the buttons as well as immediately understanding the range of functions that may be performed on the content or data.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/icon-button/KrakenDSM_Components_IconButtons_06.png">

        <section>
            <div class="kn-subtitle-16">Over reliance on icon buttons</div>
            <div class="text">Strive to be thoughtful and purposeful in the application of icon buttons, leveraging them sparingly if possible. Try to avoid overuse of the icon button in the application UI. In doing so, we risk creating a burden on the user to learn a visual language for the meaning behind multiple symbols.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/icon-button/KrakenDSM_Components_IconButtons_07.png">
        
        <section>
            <div class="kn-subtitle-16">Variance of meaning for some icon buttons</div>
            <div class="text">Once a metaphor for a chosen button icon symbol has been determined, be sure to stick with that single metaphor throughout all instances displaying the same symbol in the application browse experience. Avoid having the Icon Button have different intended meanings for the user in different contexts.</div>
        </section>
    </div>
</div>