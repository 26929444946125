import { Component, OnInit } from '@angular/core';
import  colorDataSet from '../../../services/theme-colors.json';

@Component({
  selector: 'app-color-palettes',
  templateUrl: './color-palettes.component.html',
  styleUrls: ['./color-palettes.component.sass']
})
export class ColorPalettesComponent implements OnInit {
  public colorList: any;
  public gray: any;
  public primary: any;
  public secondary: any;
  public danger: any;
  public blue: any;
  public yellow: any;
  public teal: any;
  public green: any;
  public indigo: any;
  public purple: any;
  constructor() { }

  ngOnInit(): void {
    this.primary = colorDataSet.filter((i) => i.group == 'primary');
    this.gray = colorDataSet.filter((i) => i.group == 'gray');
    this.secondary = colorDataSet.filter((i) => i.group == 'secondary');
    this.danger = colorDataSet.filter((i) => i.group == 'danger');
    this.teal = colorDataSet.filter((i) => i.group == 'teal');
    this.yellow = colorDataSet.filter((i) => i.group == 'yellow');
    this.blue = colorDataSet.filter((i) => i.group == 'blue');
    this.green = colorDataSet.filter((i) => i.group == 'green');
    this.indigo = colorDataSet.filter((i) => i.group == 'indigo');
    this.purple = colorDataSet.filter((i) => i.group == 'purple');
  }

}
