<app-canvas 
    [ts]="true"
    [js]="true" 
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [isWebTabDisabled]="true">
    <div title>Accordion <span class="component-version">v{{componentVersion}}</span></div>
    <div class="accordion-demo" component>
        <kn-accordion 
            [componentId]="'exampleAccordion'"
            [controlLeftAligned]="iconLeftAligned"
            [subTitleInline]="subTitleInline"
            [autoCollapse]="autoCollapse"
            [filledBackground]="filledBackground">
            <kn-accordion-tier-one
                [disabled]="header1Disabled"
                [icon]="headerIcons"
                (onSelect)="selected($event)">
                <div title>Title 1</div>
                <div sub-title>{{subTitleText}}</div>
                <div content>
                    <div class="acc-content">Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                        when an unknown printer took a galley of type and scrambled it to
                        make a type specimen book.
                    </div>
                </div>
            </kn-accordion-tier-one>
            <kn-accordion-tier-one
                [icon]="headerIcons"
                [disabled]="header1Disabled"
                (onSelect)="selected($event)">
                <div title>Title 2</div>
                <div sub-title>{{subTitleText}}</div>
                <div content> 
                    <kn-accordion-tier-two
                        [icon]="headerIcons"
                        [disabled]="header1Disabled"
                        (onSelect)="selected($event)">
                        <div title>Nested title</div>
                        <div sub-title>{{subTitleText}}</div>
                        <div content> 
                            <kn-accordion-tier-three
                                [icon]="headerIcons"
                                [disabled]="header1Disabled"
                                (onSelect)="selected($event)">
                                <div title>Nested title</div>
                                <div sub-title>{{subTitleText}}</div>
                            </kn-accordion-tier-three>
                        </div>
                    </kn-accordion-tier-two>
                </div>
            </kn-accordion-tier-one>
        </kn-accordion>
       
    </div>
    <div angular>
        
    </div>
    <div typeScript>
        <span class="code-comment">//You can open or close all accordions. To do this, import the accordion service and call the "openAll" or "closeAll" methods as shown below</span>
        {{openCloseAllCodeTs}}
    </div>
    <div javascript>
        <span class="code-comment">//Creating the accordion</span>
        {{webJavaScript}}
        <br/><br/>
        <span class="code-comment">//Bind the data to the component's "accordionItems" input</span>
        {{webJavaScript2}}
        <br/><br/>
        <span class="code-comment">//Bind to the component's click event</span>
        {{webClickEvent}}
        <br/><br/>
        <span class="code-comment">//To open all accordions use the follwoing code</span>
        {{openAllCode}}
        <br/><br/>
        <span class="code-comment">//To close all accordions use the follwoing code</span>
        {{closeAllCode}}
    </div>
    <div web></div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onSelect():</div>
                <div class="obj-items">
                    <div class="obj-item teir-1" [ngClass]="{'active': onselectData}"><span class="label">item: <i class="fa-solid fa-caret-right teir-1-caret" (click)="onselectData = !onselectData"></i> </span></div>
                    <div class="sub-items-tier-1" *ngIf="onselectData">
                        <div class="obj-item"><span class="label">id: </span>"{{actionData?.id}}"</div>
                        <div class="obj-item"><span class="label">title: </span>"{{actionData?.title}}"</div>
                        <div class="obj-item"><span class="label">content: </span>"{{actionData?.content}}"</div>
                        <div class="obj-item"><span class="label">icon: </span>"{{actionData?.icon}}"</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle label="Filled background" [(ngModel)]="filledBackground" (value)="controlEvent($event, '[filledBackground]=', 'true', 'bool');"></kn-toggle>
        </div>
        <div class="divider"></div>
        <div class="item-row info-section">
            <kn-toggle label="Add subtitle" [(ngModel)]="showSubTitle" (value)="showSubTitleText($event)"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Subtitle inline" [(ngModel)]="subTitleInline" (value)="controlEvent($event, '[subTitleInline]=', 'true', 'bool');" [disabled]="!subTitle"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Left-aligned control" [(ngModel)]="iconLeftAligned" (value)="controlEvent($event, '[controlLeftAligned]=', 'true', 'bool');"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="With icon" [(ngModel)]="showIcons" (value)="addHeaderIcon()"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Auto collapse" [(ngModel)]="autoCollapse" (value)="setAutoCollapse()"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Add Angular routing" [(ngModel)]="showNGRouting" (value)="routing($event)"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled state" [(ngModel)]="header1Disabled" (value)="disableHeader()"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Click event" [(ngModel)]="showOnSelectEvent" (value)="showClickEvent($event)"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">id</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"exampleAccordion", (can be placed at the component level as well as the header and nested headers)</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">title</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"test 1", (can be placed on the header and nested headers)</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">controlLeftAligned</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">subTitleInline</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">filledBackground</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">autoCollapse</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">width</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"250px"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">ngRouterLink</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>This property utilizes angular router, you can pass a route as a string, "/myroute". It can be used on the header and nested headers</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">disabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onSelect</div>
                <div class="item"><i>object</i></div>
                <div class="item"><i>{{'{}'}}, (returns the details of the selected accordion tier)</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <accordion-usage></accordion-usage>
    </div>
    <div designSpec>
        <accordion-specs></accordion-specs>
    </div>
    <div accessibility>
        <accordion-accessibility></accordion-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Date</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">October 6, 2023</div>
                <div class="item">1.1.0</div>
                <div class="item">
                    <ul>
                        <li>added open all event and close all event</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.5</div>
                <div class="item">
                    <ul>
                        <li>Added addition unit testing after component was published</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.4</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">August 15, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed issue with no data coming back in the "onSelect" event</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">April 5, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>Created accordion component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>
