<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text">The <span class="kn-bold">Search Box </span> offers users a way to explore the platform using keywords. Search can be used as the primary means of discovering content, or as a filter to aid the user in finding content.</div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Type-in search box</span></div>
            <div class="text"><span class="kn-bold">2.	Dropdown single-select</span></div>
            <div class="text"><span class="kn-bold">3.	Dropdown single-select with inline search</span></div>
            <div class="text"><span class="kn-bold">4.	Dropdown multi-select</span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_02.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Enabled</span></div>
            <div class="text"><span class="kn-bold">2.	Disabled</span></div>
            <div class="text"><span class="kn-bold">3.	Focused</span></div>
            <div class="text"><span class="kn-bold">4.	Focused with value and auto-complete menu</span></div>
            <div class="text"><span class="kn-bold">5.	Focused with value and auto-complete menu - single item</span></div>
            <div class="text"><span class="kn-bold">6.	Focused with value and auto-complete menu item hover</span></div>
            <div class="text"><span class="kn-bold">7.	Focused with value and no match found auto-complete menu</span></div>
            <div class="text"><span class="kn-bold">8.	Enabled with value</span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_03a.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Search box field</div> 
            </section>

            <section>
                <div class="text"><span class="kn-bold">A.	Container: </span>Containers improve the discoverability of text fields by creating contrast between the text field and surrounding content.</div>
                <div class="text"><span class="kn-bold">B.	Placeholder text: </span>Placeholder text conveys additional guidance about the input field. The placeholder text is displayed inside the text field.</div>
                <div class="text"><span class="kn-bold">C.	Search trailing icon: </span>A search icon indicates the difference to the user between the standard text field and search field.</div>
            </section>
        </section> 
        
        
        <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_03b.png">
        
        <section>
            <section>
                <div class="kn-subtitle-16">Auto-complete menu</div> 
            </section>

            <section>
                <div class="text"><span class="kn-bold">D.	Value: </span>The input text is text entered by the user. The input text is displayed inside the text field.</div>
                <div class="text"><span class="kn-bold">E.	Clear trailing icon: </span>When selected, the clear icon removes the contents of the field and conducts a new search.</div>
                <div class="text"><span class="kn-bold">F.	Menu option: </span>A choice for the user, shown amongst other choices in a menu.</div>
                <div class="text"><span class="kn-bold">G.	Searched term text: </span>Characters typed into the search box are highlighted in the menu of potential matches, styled in bold.</div>
                <div class="text"><span class="kn-bold">H.	Menu option hover </span></div>
                <div class="text"><span class="kn-bold">I.	No match found: </span>If no search results match the query, a “no results” message is displayed.</div>
            </section>
        </section>
    
        <section>
            <div class="kn-headline-24-med">Assistive elements</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_04.png">

        <section>
            <div class="kn-subtitle-16">Placeholder text</div>
            <div class="text">Use placeholder text to communicate the search scope. Hint text can just be “Search” if the context is very broad or it can be more specific such as “Search matters”.</div>
        </section>
        
        <section>
            <div class="kn-headline-24-med">Icons</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_05.png">

        <section>
            <div class="text"><span class="kn-bold">1. Search trailing icon</span></div>

            <div class="text">The search icon indicates the difference to the user between the standard text field and search field. It is strictly a visual indication of the text box being a search field and functions as an icon button.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">2. Clear trailing icon</span></div>

            <div class="text">The “clear all” button lets users quickly delete all text that has been entered and appears only when input text is present.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Auto-Complete Menu</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_06.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Auto-complete search suggestions</div>

            <div class="text">As the user focuses on the search field and begins to type in characters, potential search matches for the provided characters dynamically list in a dropdown menu. Characters typed into the search box text field are highlighted in the menu of potential matches. This may be implemented for either:</div>
        </section>

        <section>
        <ul class="bullets">
            <li>Typeahead complete: The characters typed into the field match those that are at the beginning of the suggested results term</li>
            <li>Search contains: The characters typed into the field match a character string anywhere within the suggested search results term, not just the beginning</li>
        </ul>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_07.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Asynchronous search query</div>

            <div class="text">If your application is required to query an external data source versus an internal index to produce search result suggestions and is likely to experience latency as a result, the search component may be configured to display the activity indicator within the field to provide feedback to the user that the system is actively processing the characters they've input.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_08.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Query for all possible results</div>

            <div class="text">In addition to the type-ahead suggestion of possible search matches, values keyed into the search field may be submitted to query for a complete list of results by the user tapping the keyboard return/enter key. In the case of a search field with an embedded action button, selecting the button submits the search query. Search results produced are either listed on a search results page or within a data grid layout.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_09.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Search selection displays in the field</div>

            <div class="text">Upon selection of a proposed match from the dropdown menu, the search field updates to display the selected value. Upon selection of a search match or submission of the search field contents, the provided value within the search field persists the user conducts a new search.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_10.png">
        </section>


        <section>
            <div class="kn-subtitle-16">Clear the search field</div>

            <div class="text">Once the search field has become focused and the user types in values, the Search icon transitions to an X icon. To clear the contents of the field and conduct a new search, the user selects the X causing the search field to return to its default state.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_11.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Size</div>
                <div class="text">Search boxes come in two different sizes: default and compressed. The compressed size is reserved for compressed grid rows where users can scan and take action on large amounts of information.</div>
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">1.  Default text box: </span>40px height, 14px text</div>
                <div class="text"><span class="kn-bold">2.  Compressed text box: </span>30px height, 12px text</div>
            </section>
        </section>

        
        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_12.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Display width</div>
                <div class="text">In general, the display width of the search box should reflect the approximate maximum character length for what would be considered a standard representative value entered by the user. When the entered value exceeds the display width of the text box, it should display with truncation (ellipses).</div>
            </section>
        </section>


        <section>
            <div class="kn-subtitle-16">Rounded corners</div>
            <div class="text">Text box containers are displayed with a 4px rounded corner.</div>
        </section>


        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_13.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Long search items</div>
                <div class="text">If the input text value length exceeds the width of the search field, it is scrolled while typing to fit the visible area.</div>
            </section>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/searchbox/KrakenDSM_Components_SearchBox_14.png">

        <section>
            <div class="kn-subtitle-16">Too many options</div>

            <div class="text">Displaying more than four menu options in a dropdown menu is not recommended. The menu becomes more difficult to scan and navigate. When more than four options are available, display a scrollbar.</div>
        </section>
    </div>
</div>