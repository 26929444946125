import { Component, OnInit } from '@angular/core';
import  packageInfo from '../../../../../kraken-button-group/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';

@Component({
  selector: 'app-button-group',
  templateUrl: './button-group.component.html',
  styleUrls: ['./button-group.component.sass']
})
export class ButtonGroupComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public event1 = true;
  public layout = 'row';
  public elementTag = 'kn-button-group';
  public elementWebTag = 'kn-web-button-group';
  public selectionTypeFields = {text: 'name', value: 'type'};
  public selectionObj = [
    {name: 'Single selection', type: 'single'},
    {name: 'Multi-selection', type: 'multi'}
  ]
  public action = "";
  public selectionType = this.selectionObj[0].name;
  public selection = this.selectionObj[0].type;
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenButtonGroupModule'};
  public componentId = 'buttonGroupComponentId';
  public eventId;
  public eventLabel;
  public eventActive;
  public btnDisabled = false;
  public buttonType = 'text';
  public buttonClass = "kn-tertiary";
  public buttonTypeSelection = "kn-tertiary";
  public compressed = false;
  public clickEvent = false;
  public showItemsObject = false;
  public items = [
    {buttonId: 'example1', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'},
    {buttonId: 'example2', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'},
    {buttonId: 'example3', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'},
    {buttonId: 'example4', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'}
  ]
  public disabledTooltip = `NOTE: You can set the [disabled] property on any button. An example of this is shown on the second button when this toggle is on.`
  public angularComponentOptions: any = [
   { option: '[selectionType]=', value: '"\'single\'"'},
   { option: '[layout]=', value: '"\'row\'"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"exampleButtonGroup"'},
    { option: 'selection-type=', id: 0, value: '"single"'},
    { option: 'layout=', id: 0, value: '"row"'}
    
  ];

  public webInitButtonGroup = 
  `let buttonGroup = document.querySelector('#exampleButtonGroup');

buttonGroup.content = 
  \`<div class="kn-button-group-container">
  <kn-button [buttonId]="'exampleButton1'" [buttonType]="'kn-tertiary'"  [buttonText]="'Button'"></kn-button>
  <kn-button [buttonId]="'exampleButton2'" [buttonType]="'kn-tertiary'"  [buttonText]="'Button'"></kn-button>
  <kn-button [buttonId]="'exampleButton3'" [buttonType]="'kn-tertiary'"  [buttonText]="'Button'"></kn-button>
  <kn-button [buttonId]="'exampleButton4'" [buttonType]="'kn-tertiary'"  [buttonText]="'Button'"></kn-button>
</div>\``

public buttonGroupWebClickEvent =
`function btnGroupClickEvent(event) {
  console.log(event);
}`

public webContext =
`buttonGroup.context = {
  btnClick: this.btnGroupClickEvent.bind(this),
}`

public addButtonClickEvent =
`buttonGroup.content = 
\`<div class="kn-button-group-container">
  <kn-button [buttonId]="'exampleButton1'" [buttonType]="'kn-tertiary'"  [buttonText]="'Button'" (btnClick)="context.btnClick($event)"></kn-button>
  <kn-button [buttonId]="'exampleButton2'" [buttonType]="'kn-tertiary'"  [buttonText]="'Button'" (btnClick)="context.btnClick($event)"></kn-button>
  <kn-button [buttonId]="'exampleButton3'" [buttonType]="'kn-tertiary'"  [buttonText]="'Button'" (btnClick)="context.btnClick($event)"></kn-button>
  <kn-button [buttonId]="'exampleButton4'" [buttonType]="'kn-tertiary'"  [buttonText]="'Button'" (btnClick)="context.btnClick($event)"></kn-button>
</div>\``

public webItems = 
`buttonGroup.items = [
  {buttonId: 'example1', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'},
  {buttonId: 'example2', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'},
  {buttonId: 'example3', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'},
  {buttonId: 'example4', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'}
]`

public itemsObject = 
`public items = [
  {buttonId: 'example1', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'},
  {buttonId: 'example2', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'},
  {buttonId: 'example3', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'},
  {buttonId: 'example4', buttonType: 'kn-tertiary', buttonText: 'button', buttonIcon: 'fa-regular kic icon-ic-fpo'}
]`
  public template: string;

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateEvent());
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementWebTag, this.webComponentOptions, this.webContainer);

  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementWebTag, this.webComponentOptions, this.webContainer);
  }

  btnClickEvent(event) {
    this.eventId = event.id;
    this.eventLabel = event.label;
    this.eventActive = event.active;
  }

  selectionTypeEvent(event) {
    this.selection = event.type;
  }

  buttonTypeEvent(event) {
    this.buttonClass = event.value;
    this.buttonTypeSelection = event.value;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateEvent());
  }

  disabledBtnEvent() {
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateEvent());
  }

  buttonContent(event) {
    this.buttonType = event.value;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateEvent());
  }


  templateEvent() {
    const disabled = this.btnDisabled ? ' [disabled]="true"' : "";
    const icon = this.buttonType != 'text' ? ` [buttonIconLeft]="'fa-regular kic icon-ic-fpo'"` : "";
    const label = this.buttonType == 'icons' ? "" : ` [buttonText]="'Button'"`;

    const template = `<div class="kn-button-group-container">
  <kn-button [buttonId]="'exampleButton1'" [buttonType]="'${this.buttonClass}'" ${label}${icon}></kn-button>
  <kn-button [buttonId]="'exampleButton2'" [buttonType]="'${this.buttonClass}'" ${label}${icon}${disabled}></kn-button>
  <kn-button [buttonId]="'exampleButton3'" [buttonType]="'${this.buttonClass}'" ${label}${icon}></kn-button>
  <kn-button [buttonId]="'exampleButton4'" [buttonType]="'${this.buttonClass}'" ${label}${icon}></kn-button>
</div>`

return template;
  }

  selectTypeEvent(event) {
    this.selection = event.type;
    if(this.angularComponentOptions.findIndex((obj => obj.option == '[selectionType]=')) == -1) {
      this.controlEvent(true, '[selectionType]=', true, true);
    }

    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[selectionType]='));
    this.angularComponentOptions[index].option = '[selectionType]=';
    this.angularComponentOptions[index].value = '"\'' + event.type +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateEvent());
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'selection-type='));
    this.webComponentOptions[webIndex].option = 'selection-type=';
    this.webComponentOptions[webIndex].value = '"' + event.type + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementWebTag, this.webComponentOptions, this.webContainer);
  }

  compressedEvent(event) {
    this.compressed = event;
    this.controlEvent(event, '[compressed]=', true, true);
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateEvent());
  }

  layoutEvent(event) {
    if(this.angularComponentOptions.findIndex((obj => obj.option == '[layout]=')) == -1) {
      this.controlEvent(true, '[layout]=', true, true);
    }
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[layout]='));
    this.angularComponentOptions[index].option = '[layout]=';
    this.angularComponentOptions[index].value = '"\'' + event.value +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateEvent());
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'layout='));
    this.webComponentOptions[webIndex].option = 'layout=';
    this.webComponentOptions[webIndex].value = '"' + event.value + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementWebTag, this.webComponentOptions, this.webContainer);
    
  }

  buttonGroupClickEvent(event) {
    this.controlEvent(event, '(btnClick)=', 'clickEvent($event)', false);

    //angular
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateEvent());
  }

  showItemsEvent(event) {
    this.controlEvent(event, '[items]=', 'items', false);

    //angular
    if(event) {
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    } else {
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateEvent());
    }
  }
}
