<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Process</div>
        <section>
            <div class="text">The styles and component patterns represented in the <span class="kn-bold">Kraken DS</span> undergo a rigorous design and production process before being included in the reference product. Such due diligence is comprised of the following:</div>
        </section>

        <section>
            <div class="kn-subtitle-16">1. Component concept research</div>

            <ul>
                <li>“Contact” feedback and requests from the Kraken DS website are reviewed by the team and responded to twice per week. Actionable items are added to the Kraken DS backlog.</li>
                <li>Kraken DS backlog is groomed and prioritized every other week, setting priorities for upcoming component production sprints.</li>
                <li>An audit of all existing use cases for the component exhibited across all Lighthouse products is performed.</li>
                <li>Additional use cases are conceptualized and considered based on foreseeable business need intuited from UX team involvement in current product design.</li>
                <li>Design research is conducted for examples of component best practices across existing Lighthouse products, competitive products, other high-profile design systems, and comparable best-in-class products outside the industry for inspiration.</li>
                <li>All pertinent use cases, variations, component states, and reference examples are detailed in an Azure DevOps UX Work Item for assignment.</li>
            </ul>
        </section>

        <section>
            <div class="kn-subtitle-16">2. Design iteration & review</div>

            <ul>
                <li>Component designs are iterated on and reviewed by the Kraken DS team for critique and feedback.</li>
                <li>Design recommendations are presented to the entire UX team for further feedback during a weekly team critique meeting, putting additional eyes on the design challenges.</li>
                <li>Component design candidates are then shared with the Kraken DS User Community, a group of volunteers across Lighthouse product teams, to offer additional feedback and recommendations.</li>
                <li>Final component designs are scanned for contrast and color blindness accessibility adherence. Further adjustments to the design are incorporated if needed.</li>
            </ul>
        </section>

        <section>
            <div class="kn-subtitle-16">3. Development iteration & review</div>

            <ul>
                <li>Component functionality and styles are developed with strict adherence to the prescribed design specifications.</li>
                <li>A code demonstration screen is developed for the Kraken DS website with corresponding controls to manipulate the component use case, variations, and different states.</li>
                <li>The Kraken DS team reviews component demos for critique and feedback during development iteration.</li>
                <li>Completed component demos are presented to the entire UX team for further feedback during the weekly critique meeting.</li>
                <li>Demo screens for code component candidates are then shared with the Kraken DS User Community to offer additional feedback and recommendations before finalizing.</li>
                <li>Completed code components are scanned for WC3 2.1 and WAI/ARIA accessibility compliance. Code is refactored and edited if needed.</li>
            </ul>
        </section>

        <section>
            <div class="kn-subtitle-16">4. Documentation</div>

            <ul>
                <li>To support component code demonstrations, the Kraken DS team prepares additional reference documentation for both developers and designers:</li>
            </ul>
            <ul>
                <div class="text">⁃	Properties</div>
                <div class="text">⁃	Installation Guidelines</div>
                <div class="text">⁃	Usage Guidelines</div>
                <div class="text">⁃	Design Specifications</div>
            </ul>
            
        </section>

        <section>
            <div class="kn-subtitle-16">5. Testing & QA</div>

            <ul>
                <li>Code demo screens are manually tested by the Kraken DS and broader UX team for alignment with design specifications and demonstration configuration accuracy.These tests are conducted using current versions of both Google Chrome and Microsoft Edge browsers on PC and Mac computer devices.</li>
                <li>Linting is performed on all component code to ensure UI coding best practices have been followed.</li>
                <li>Unit tests are developed and run against UI components, with code coverage minimum of 80%, confirming error-free tests.</li>
                <li>Veracode scanning is conducted to confirm that no security vulnerabilities exist within each UI code component.</li>
                <li>Deque Axe Dev Tools are utilized to test and validate UI component code against WCAG 2.0 AA-AAA visual accessibility and ARIA code accessibility standards.</li>
                <li>NPS packages for each code component are posted to the Azure DevOps Artifacts for component distribution.</li>
                <li>Each finished code component NPS package is then downloaded from Azure DevOps Artifacts and installed in a working copy of the Spectra product project in a separate (sandbox) branch to validate the new component in a real-world application context.</li>
                <li>Completed Web Component code is installed within a sample React application and tested to validate identical rendering and behavior as its Angular component counterpart.</li>
                <li>Supporting documentation content is carefully reviewed for navigation, completeness, accuracy, consistency in editorial style, grammar, and punctuation.</li>
            </ul>
        </section>

        <section>
            <div class="kn-subtitle-16">6. Release</div>

            <ul>
                <li>Release notes are drafted, reviewed by the Kraken DS team, and posted to the Kraken DS website.</li>
                <li>Email is distributed to Kraken DS notification subscribers outlining the contents of the new release and reiterating the release notes.</li>
                <li>Further organization-wide socialization of new Kraken DS releases is provided through a monthly email newsletter and reviewed in pre-scheduled bi-weekly Kraken DS office hour meetings.</li>
            </ul>
        </section>
    </div>
</div>