import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'icon-button-usage',
  templateUrl: './icon-button-usage.component.html',
  styleUrls: ['./icon-button-usage.component.sass']
})
export class IconButtonUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
