import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import  packageInfo from '../../../../../kraken-autocomplete/package.json';

export class keyUp {
  value: string;
}


@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.sass']
})
export class SearchboxComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public webClickEvent;
  public componentId = 'searchboxComponentId';
  public webSetProperties;
  public webRemoveProperties;
  public elementTag = 'kn-autocomplete';
  public name = 'searchbox';
  public placeholder = 'Sports...';
  public showPlaceholder = true;
  public disabled = false;
  public compressed = false;
  public dataArry: string;
  public dataFields: string;
  public dataObj: string;
  public event1 = true;
  public event2 = true;
  public actionEvent;
  public actionData;
  public onselectData = true;
  public onselectOrigin = true;
  public keyUp;
  public selectEvent = false;
  public event3 = true;
  public webDropdownItemsArray: any;
  public webDropdownItemsObj: any;
  public isDropdownWidthCustom = false;
  public dropdownCustomWidth = '';
  public dropdownCustomHeight = '';
  public dropdownRightAligned = false;
  public isDropdownHeightCustom = false;
  public componentVersion = packageInfo.version;
  public initializeComponentWeb: any;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenAutocompleteModule'};
  public fields: object = { text: 'sport', value: 'value'};
  //public data = ['football', 'baseball', 'hockey', 'soccer'];
  public data = [
    {sport: 'football', value: 1, tags: 'football, ball, tackle, gridiron'},
    {sport: 'baseball', value: 1, tags: 'baseball, ball, bat, park'},
    {sport: 'hockey', value: 1, tags: 'hockey, puck, skates'},
    {sport: 'soccer', value: 1, tags: 'soccer, ball, field'},
  ]

  public angularComponentOptions: any = [
    { option: '[searchboxId]=', id: 0, value: '"\'exampleSearchbox\'"'},
    { option: '[data]=', id: 1, value: '"data"'},
    { option: '[fields]=', id: 2, value: '"fields"'},
    { option: '[keyWord]=', id: 3, value: '"\'tags\'"'},
    { option: '[placeholder]=', id: 4, value: '"\'' + this.placeholder + '\'"'},
    { option: '[name]=', id: 5, value: '"\'' + this.name +'\'"'},
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"'+ this.componentId +'"'},
    { option: 'searchbox-id=', id: 0, value: '"exampleSearchbox"'},
    { option: 'key-word=', id: 1, value: '"tags"'},
    { option: 'placeholder=', id: 2, value: '"' + this.placeholder + '"'},
    { option: 'name=', id: 3, value: '"\'' + this.name +'\'"'}
  ];
  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);

    this.initializeComponentWeb = `
let searchbox = document.querySelector("#${this.componentId}");
    `
    this.dataArry = 
`
public data = ['football', 'baseball', 'hockey', 'soccer'];
`
this.dataFields = 
`
public fields: Object = { text: 'sport', value: 'value'};
`
    this.dataObj = 
`
public data = [
  {sport: 'football', value: 1, tags: 'football, ball, tackle, gridiron'},
  {sport: 'baseball', value: 1, tags: 'baseball, ball, bat, park'},
  {sport: 'hockey', value: 1, tags: 'hockey, puck, skates'},
  {sport: 'soccer', value: 1, tags: 'soccer, ball, field'}
]
`
this.webDropdownItemsArray =
`
let searchItems = ['football', 'baseball', 'hockey', 'soccer'];
searchbox.items = searchItems;
`
    
    this.webDropdownItemsObj = 
`
let searchItemsObj = 
[
  {sport: 'football', value: 1, tags: 'football, ball, tackle, gridiron'},
  {sport: 'baseball', value: 1, tags: 'baseball, ball, bat, park'},
  {sport: 'hockey', value: 1, tags: 'hockey, puck, skates'},
  {sport: 'soccer', value: 1, tags: 'soccer, ball, field'}
]

const fields = { text: 'sport', value: 'value'};

searchbox.fields = fields;
searchbox.data = searchItemsObj;
`

this.webClickEvent = 
`
searchbox.addEventListener('onSelect', (event) => console.log(event.detail));
`

this.webSetProperties = 
`
searchbox.setDisabled = true;
`
this.webRemoveProperties = 
`
searchbox.setDisabled = false;
`
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  showHidePlaceholder(event) {
    this.placeholder = event ? this.placeholder : '';
    this.showPlaceholder = event;
    this.controlEvent(event, '[placeholder]=', this.placeholder, true); 
  }

  placeholderValue(event) {
    this.placeholder = event;
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[placeholder]='));
    //angular
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'placeholder='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  onSelect(row) {
    this.actionEvent = row.event;
    this.actionData = row;
  }

  onKeyup(event) {
    this.keyUp = new keyUp();
    this.keyUp.value = event.value
  }

  customDrpdnWidthValue(event) {
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[dropdownWidth]='));
    this.angularComponentOptions[index].option = '[dropdownWidth]=';
    this.angularComponentOptions[index].value = '"\'' + event + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'dropdown-width='));
    this.webComponentOptions[indexWeb].option = 'dropdown-width=';
    this.webComponentOptions[indexWeb].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  customDrpdnWidth(event) {
    if(event) {
      this.controlEvent(true, '[dropdownWidth]=', '' + this.dropdownCustomWidth +'', true);  
    } else{
      this.controlEvent(false, '[dropdownWidth]=', '' + this.dropdownCustomWidth +'', true);
      this.dropdownCustomWidth = '';
    }
  }

  customDrpdnHeight(event) {
    if(event) {
      this.controlEvent(true, '[dropdownHeight]=', '' + this.dropdownCustomHeight +'', true);  
    } else{
      this.controlEvent(false, '[dropdownHeight]=', '' + this.dropdownCustomHeight +'', true);
      this.dropdownCustomHeight = '';
    }
  }

  customDrpdnHeightValue(event) {
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[dropdownHeight]='));
    this.angularComponentOptions[index].option = '[dropdownHeight]=';
    this.angularComponentOptions[index].value = '"\'' + event + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'dropdown-height='));
    this.webComponentOptions[indexWeb].option = 'dropdown-height=';
    this.webComponentOptions[indexWeb].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

}
