import { Component, ChangeDetectorRef } from '@angular/core';
import  packageInfo from '../../../../../kraken-slider/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.sass']
})
export class SliderComponent {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public event1 = true;
  public event2 = true;
  public elementTag = 'kn-slider';
  public multiSlider = false;
  public minRange = 0;
  public maxRange = 100;
  public minValue = 20;
  public maxValue = 80;
  public value = 80;
  public steps = 0;
  public width = 300;
  public rangeThreshold = 1;
  public disabled = false;
  public readOnly = false;
  public showTextbox = true;
  public minEventValue;
  public maxEventValue;
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenSliderModule'};
  public componentId = 'sliderComponentId';
  public sliderLabel = "This is a slider label";
  
  public angularComponentOptions: any = [
     { option: '[label]=', id: 0, value: '"\''+ this.sliderLabel + '\'"'},
     { option: '[minRange]=', id: 0, value: '"'+ this.minRange +'"'},
     { option: '[maxRange]=', id: 0, value: '"'+ this.maxRange +'"'},
     { option: '[minValue]=', id: 0, value: '"'+ this.minValue +'"'},
     { option: '[showTextbox]=', id: 0, value: '"'+ this.showTextbox +'"'}
  ];

  public webComponentOptions: any = [
     { option: 'id=', id: 0, value: '"exampleSlider"'},
     { option: 'label=', id: 0, value: '"'+ this.sliderLabel +'"'},
     { option: 'min-range=', id: 0, value: '"'+ this.minRange +'"'},
     { option: 'max-range=', id: 0, value: '"'+ this.maxRange +'"'},
     { option: 'min-value=', id: 0, value: '"'+ this.minValue +'"'},
     { option: 'show-textbox=', id: 0, value: '"'+ this.showTextbox +'"'}
  ];

  public webSliderEvent =
  `let slider = document.querySelector('#exampleSlider');

slider.addEventListener('minValueEvent', (e)=> {
  console.log(e.detail);
});

slider.addEventListener('maxValueEvent', (e)=> {
  console.log(e.detail);
});`
  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService,
    public ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  labelText(event) {
    this.sliderLabel = event;

    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[label]='));
    this.angularComponentOptions[index].option = '[label]=';
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'label='));
    this.webComponentOptions[webIndex].option = 'label=';
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  stepsEvent(event) {
    this.steps = event;
    if(this.steps > 1) {
      this.showTextbox = false;
      if(this.angularComponentOptions.findIndex((obj => obj.option == '[showTextbox]=')) != -1) {
        this.controlEvent(false, '[showTextbox]=', true, true);
      } else {
        this.controlEvent(false, '[showTextbox]=', true, true);
      }
    }
    
    if(this.angularComponentOptions.findIndex((obj => obj.option == '[steps]=')) == -1) {
      this.controlEvent(true, '[steps]=', true, true);
    }

    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[steps]='));
    this.angularComponentOptions[index].option = '[steps]=';
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'steps='));
    this.webComponentOptions[webIndex].option = 'steps=';
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  thresholdEvent(event) {
    this.rangeThreshold = event;
    
    if(this.angularComponentOptions.findIndex((obj => obj.option == '[rangeThreshold]=')) == -1) {
      this.controlEvent(true, '[rangeThreshold]=', true, true);
    }

    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[rangeThreshold]='));
    this.angularComponentOptions[index].option = '[rangeThreshold]=';
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'range-threshold='));
    this.webComponentOptions[webIndex].option = 'range-threshold=';
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  multiRangeEvent(event) {
   if(!event) {
      this.minValue = 60;
      this.maxValue = 0;
    } else {
      this.minValue = 5;
      this.maxValue = 80;
    }
    
    this.controlEvent(event, '[multiSlider]=', true, true);
    if(event) {
      this.controlEvent(true, '[maxValue]=', true, true);
      //angular
      const index = this.angularComponentOptions.findIndex((obj => obj.option == '[maxValue]='));
      this.angularComponentOptions[index].option = '[maxValue]=';
      this.angularComponentOptions[index].value = '"\'' + this.maxValue +'\'"';
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
      //web
      const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'max-value='));
      this.webComponentOptions[webIndex].option = 'max-value=';
      this.webComponentOptions[webIndex].value = '"' + this.maxValue + '"';
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    } else {
      this.controlEvent(false, '[maxValue]=', true, true);
    }

    this.ref.detectChanges();
  }

  minValueEvent(event) {
    this.minEventValue = event.value;
  }

  maxValueEvent(event) {
    this.maxEventValue = event.value;
  }

  onClear() {
    this.steps = 0;
  }
}
