<app-canvas 
    [ts]="true" 
    [js]="true" 
    [htmlCode]="angularComponentRef"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component">
    <div title>Icon Menu <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <div class="component-group">
            <kn-icon-menu
                [iconId]="'exampleIcon'"
                [disabled]="buttonDisabled"
                [items]="buttonMenuItemsObj" 
                (onSelect)="selectedItem($event)"
                [fields]="btnDropdownFields"
                [dropdownCustomWidth]="dropdownCustomWidth"
                [dropdownCustomHeight]="dropdownCustomHeight"
                [template]="useCustomTemplate">
                <ng-template  #customTemplate let-data="data">
                    <i class="{{data.icon}}"></i> <span class="name">{{data.display}}</span>
                </ng-template>
            </kn-icon-menu>
        </div>
    </div>
    <div angular>
    </div>
    <div typeScript>
        <span class="code-comment">//Dropdown items using Objects, add [fields]="fields" </span>
        {{tsDropdownItemsObj}}
        <span class="code-comment">//Dropdown items using an Array</span>
        {{tsDropdownItemsArray}}
    </div>
    <div javascript>
        <span class="code-comment">//You can choose not to add "items" to the component. You will have to create your own list within the dropdown. To keep the styling of the rows consistant to the DSM, this can be achieved using the code below.</span>
        {{webCustomTemplate}}
        <span class="code-comment">//Dropdown using an object</span>
        {{webDropdownItemsObj}}
        <span class="code-comment">//Dropdown button using a simple array</span>
        {{webDropdownItemsArray}}
        <span class="code-comment">/Event listeners. These cannot be use if using a custom template</span>
        {{webClickEvent}}
        <span class="code-comment">//Setting properties</span>
        {{webSetProperties}}
        <span class="code-comment">//Removing properties</span>
        {{webRemoveProperties}}
    </div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">btnClick():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">active: false</span></div>
                    <div class="obj-item"><span class="label">id:</span> "{{buttonId}}"</div>
                    <div class="obj-item"><span class="label">text:</span> "{{buttonText}}"</div>
                    <div class="obj-item"><span class="label">type:</span> "{{buttonType}}"</div>
                </div>
            </div>
            <div class="event-item" [ngClass]="{'active': event2}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event2 = !event2"></i> 
                <div class="obj">onSelect():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">text:</span> "{{btnSelectText}}"</div>
                    <div class="obj-item"><span class="label">value:</span> "{{btnSelectValue}}"</div>
                </div>
            </div>
        </div>
    </div>
    
    <div customproperties>
        <div class="action-input">
            <kn-textbox [label]="'Button label'" [value]="buttonText" (onValueChange)="textChange($event)" [clearIcon]="true"></kn-textbox>
        </div>
        
        <div class="section-title">Modifiers</div>

        <!--Add left side icon-->
        <div class="item-row">
            <kn-toggle [label]="'Button with leading icon'" (value)="controlEvent($event, '[buttonIconLeft]=', leftBtnIcon, true); isIconEnabled($event)"></kn-toggle>

            <kn-dropdown 
                [template]="true" 
                [id]="leftIconInput" 
                *ngIf="isLeftIconChecked" 
                [value]="iconLeftValue" 
                [selection]="curentSelectedIcon" 
                [items]="iconList"
                [placeholder]="'Select an icon'"
                (onSelect)="selectedIcon($event)">
                <div dropdownTemplate>
                    <ng-template  #customTemplate let-data="data">
                        <i class="{{data.className}}"></i> <span class="icon-name">{{data.title}}</span>
                    </ng-template>
                </div>
            </kn-dropdown>
        </div>
        <div class="item-row">
            <kn-toggle label="Dropdown width" (value)="customDrpdnWidth($event); isDropdownWidthCustom = !isDropdownWidthCustom" ></kn-toggle>
        </div>
        <div class="action-input" *ngIf="isDropdownWidthCustom">
            <div class="input-info">
                <kn-textbox 
                    [label]="'Enter a value'" 
                    [(ngModel)]="dropdownCustomWidth" 
                    [value]="dropdownCustomWidth" 
                    [setDisabled]="" 
                    [setPlaceholder]="'400px'"
                    (onValueChange)="customDrpdnWidthValue($event)" 
                    [clearIcon]="true">
                </kn-textbox>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="This is a CSS width property, you can enter px, %, etc" [tooltipDirection]="'top'"></i>
            </div>
        </div>
        <div class="item-row">
            <kn-toggle label="Dropdown height" (value)="customDrpdnHeight($event); isDropdownHeightCustom = !isDropdownHeightCustom" ></kn-toggle>
        </div>
        <div class="action-input" *ngIf="isDropdownHeightCustom">
            <div class="input-info">
                <kn-textbox 
                    [label]="'Enter a value'" 
                    [(ngModel)]="dropdownCustomHeight" 
                    [value]="dropdownCustomHeight" 
                    [setDisabled]="" 
                    [setPlaceholder]="'400px'"
                    (onValueChange)="customDrpdnHeightValue($event)" 
                    [clearIcon]="true">
                </kn-textbox>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="This is a CSS height property, you can enter px, %, etc" [tooltipDirection]="'top'"></i>
            </div>
        </div>
        <div class="item-row">
            <kn-toggle label="Right align dropdown" [(ngModel)]="dropdownRightAligned"  (value)="controlEvent($event, '[dropdownRightAligned]=', 'true', true);"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Dropdown template" [(ngModel)]="useCustomTemplateNoData" (value)="customTemplateNoData($event)" [disabled]="useCustomTemplate"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Dropdown template with data" [(ngModel)]="useCustomTemplate" (value)="customTemplateEvent($event)" [disabled]="useCustomTemplateNoData"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled state"  (value)="controlEvent($event, '[disabled]=', 'true', 'bool'); buttonDisabled = !buttonDisabled"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Click event"  (value)="controlEvent($event, '(btnClick)=', 'clickEvent($event)', false); btnClickEvent = !btnClickEvent"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Select event"  (value)="controlEvent($event, '(onSelect)=', 'selectedItem($event)', false); selectedItemEvent = !selectedItemEvent"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">buttonType</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"primary"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">buttonId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"btn0"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">buttonText</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Primary button"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">buttonIconLeft</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"fas fa-file-alt"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">contentWidth</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropdownCustomWidth</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"400px"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropdownCustomHeight</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"400px"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">template</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">disabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">items</div>
                <div class="item"><i>{{'{}'}}, {{'[]'}}</i></div>
                <div class="item"><i>array or object (object must include fields property</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">fields</div>
                <div class="item"><i>object</i></div>
                <div class="item"><i>{{'{ text: name, value: id }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">btnClick</div>
                <div class="item"><i>object</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onSelect</div>
                <div class="item"><i>object</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <menu-button-usage></menu-button-usage>
    </div>
    <div designSpec>
        <menu-button-specs></menu-button-specs>
    </div>
    <div accessibility>
        <menu-button-accessibility></menu-button-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>Added addition unit testing after component was published</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">May 5, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - added elevation to the dropdown menu to prevent being hidden by parent elements</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the menu button component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

