<div class="kn-toggle" [ngClass]="{'label-left': labelLeft, 'disabled': disabled}">
    <label class="switch" >
        <input 
        id="{{toggleId}}"
        type="checkbox" 
        [checked]="val" 
        [(ngModel)]="val" 
        [attr.aria-label]="label"
        (ngModelChange)="onModelChange($event)"
        name="{{name}}">
        <span class="slider" [ngClass]="{'disabled': disabled}"></span>
    </label>
    <div class="label">
        <span *ngIf="!customLabel">{{label}}</span>
        <span *ngIf="customLabel">
            <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
        </span>
    </div>
</div>