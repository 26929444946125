<app-canvas 
    [theme]="selectedTheme" 
    [ts]="false" 
    [js]="true" 
    [htmlCode]="angularComponentRef"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component">
    <div title>Icon Button <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-icon-button 
            [buttonId]="buttonId"
            [buttonType]="buttonType" 
            [icon]="icon"
            [disabled]="buttonDisabled"
            [dropShadow]="addDropShadow"
            (onSelect)="selectedItem($event)">
        </kn-icon-button>
    </div>
    <div typeScript></div>
    <div javascript>
        <span class="code-comment">//Initialize the component</span>
        {{initializeWebComponent}}
        <span class="code-comment">//All properties with a boolean value needs to be set in code like this</span>
        {{webSetProperties}}
        <span class="code-comment">//Remove a boolean property like this</span>
        {{webRemoveProperties}}
        <span class="code-comment">//listening for click events</span>
        {{webClickEvent}}
    </div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">btnClick():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">active: false</span></div>
                    <div class="obj-item"><span class="label">id:</span> "{{buttonId}}"</div>
                    <div class="obj-item"><span class="label">type:</span> "{{buttonType}}"</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
       <div class="action-input">
            <kn-dropdown 
                #knInput 
                [label]="'Button Variant'" 
                [value]="selectedButton" 
                [items]="buttonTypes" 
                [fields]="buttonTypeFields" 
                (onSelect)="valueChange($event)"> 
            </kn-dropdown>
        </div>

        <div class="section-title">Modifiers</div>

        <div class="item-row">
            <kn-dropdown 
                [template]="true" 
                [label]="'Choose an icon'"
                [id]="buttonIcon" 
                [value]="selectedIcon" 
                [fields]="buttonIconFields" 
                [items]="iconList"
                (onSelect)="buttonIcon($event)">
                <ng-template  #customTemplate let-data="data">
                    <i class="{{data.className}}"></i> <span class="icon-name">{{data.title}}</span>
                </ng-template>
            </kn-dropdown>
        </div>

        <div class="item-row">
            <kn-toggle label="Drop shadow" [(ngModel)]="addDropShadow" (value)="controlEvent($event, '[dropShadow]=', 'true', true);" [disabled]="disableShadow"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled state"  (value)="controlEvent($event, '[disabled]=', 'true', true); buttonDisabled = !buttonDisabled"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Click event"  (value)="controlEvent($event, '(btnClick)=', 'clickEvent($event)', false); btnClickEvent = !btnClickEvent"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">buttonType</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"primary"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">buttonId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"btn0"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">buttonText</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Primary button"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">icon</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"fas fa-file-alt"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropShadow</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">disabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">btnClick</div>
                <div class="item"><i>object</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <icon-button-usage></icon-button-usage>
    </div>
    <div designSpec>
        <icon-button-specs></icon-button-specs>
    </div>
    <div accessibility>
        <icon-button-accessibility></icon-button-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.1.3</div>
                <div class="item">
                    <ul>
                        <li>Added addition unit testing after component was published</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.1.2</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">August 15, 2023</div>
                <div class="item">1.1.1</div>
                <div class="item">
                    <ul>
                        <li>added an active state</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.1.0</div>
                <div class="item">
                    <ul>
                        <li>added new medium size button class</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">June 15, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - changed the flat version icon color from primary to ink-light on enabled state</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the icon button component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

