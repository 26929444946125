<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text">A <span class="kn-bold">Slider </span> is an input control that enables users to select from a range of pre-defined values by moving (grabbing and sliding) an indicator called a "handle" along a horizontal bar between a minimum and maximum value.</div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/slider/KrakenDSM_Components_Slider_01.png">
        
        <section>
            <div class="text">Continuous sliders offer a smooth and continuous range of values, which allow precise adjustments. Discrete sliders restrict choices to predefined steps, which ensures users select only specific, meaningful values.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Continuous:</span> Selects a value along a subjective range.</div>
            <div class="text"><span class="kn-bold">2.	Discrete:</span> Selects a specific value from a predetermined range. Tick marks indicate available values, where the slider handle "snaps" to these pre-defined steps.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/slider/KrakenDSM_Components_Slider_02.png">

        <section>
            <div class="text">Single handle sliders are suitable for single-value selections, while multi-handle sliders define a range by manipulating two handles. The choice between these types depends on the user interface requirements and the need for single-value or range-based selections in the application.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Single handle:</span> Selects a single value from a continuous or discrete range.</div>
            <div class="text"><span class="kn-bold">2.	Multi handle:</span> Selects a range of values within a specified minimum and maximum range. Range sliders include two handles: one for the start value and another for the end value of the range. Users can adjust both handles independently to define a specific interval within a given range.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/slider/KrakenDSM_Components_Slider_03.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Enabled</span></div>
            <div class="text"><span class="kn-bold">2.	Enabled with focused input value</span></div>
            <div class="text"><span class="kn-bold">3.	Hover</span></div>
            <div class="text"><span class="kn-bold">4.	Error: </span>For continuous sets of values when an input text box is displayed, an error is indicated with an icon and associated text.</div>
            <div class="text"><span class="kn-bold">5.	Read-only: </span>A slider in a read-only state, which is visible to users, and displays its current state and value (but users cannot interact with it).</div>
            <div class="text"><span class="kn-bold">6.	Disabled: </span>A slider in a disabled state, which shows that an input exists (but is not available in that circumstance).</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/slider/KrakenDSM_Components_Slider_04.png">


        <section>
            <div class="text"><span class="kn-bold">A.	Label: </span>Label text that informs users which information is requested for a slider.</div>
            <div class="text"><span class="kn-bold">B.	Fill: </span>The visual representation of the selected range. As users drag the slider handle, the track dynamically fills, which visually indicates the portion of the slider that represents the chosen value or range.</div>
            <div class="text"><span class="kn-bold">C.	Track: </span>Displays the range available for the user to select from.</div>
            <div class="text"><span class="kn-bold">D.	Input value</span></div>
            <div class="text"><span class="kn-bold">E.	Value label</span></div>
            <div class="text"><span class="kn-bold">F.	Ticks: </span>Visual markers or indicators placed along the discrete slider track at specific intervals. Ticks serve as reference points to clarify which values users can select.</div>
            <div class="text"><span class="kn-bold">G.	Handle: </span>A position indicator that moves along the track to change the value.</div>
            <div class="text"><span class="kn-bold">H.	Container</span></div>
            <div class="text"><span class="kn-bold">I.	Tooltip</span></div>
            <div class="text"><span class="kn-bold">J.	Error icon: </span>An error icon displayed with text makes error states clear for colorblind users.</div>
            <div class="text"><span class="kn-bold">K.	Error text: </span>Error messages appear below the slider when errors occur.</div>
        </section> 
        

        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/slider/KrakenDSM_Components_Slider_05.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Label</div>
                <div class="text">Sliders always include a label that gives context about the operation being performed.</div>
            </section>
            
            <section>
                <ul class="bullets">
                    <li>Keep the label short and concise by limiting it to a few words or a single line of text.</li>
                    <li>Use sentence case.</li>
                    <li>Ensure the label is left-aligned.</li>
                </ul>
            </section>
        </section>

        
        <section>
            <section>
                <div class="kn-subtitle-16">Fill</div>
                <div class="text">Fill is the visual representation of the selected value or range within the slider track. As users interact with the slider handle(s) to adjust the selection, the fill dynamically indicates the portion of the currently chosen track. The fill originates from the left side of the track.</div>
            </section>
        </section>
        
        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/slider/KrakenDSM_Components_Slider_06.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Value</div>
                <div class="text">The slider value represents the numeric input that a user chooses on the slider. It indicates the specific point or range selected by the position of the slider handle(s) on the track.</div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">Value label</span></div>
                <div class="text">Sliders always include value labels, which provide additional context to the slider label and communicate the defined numeric range of slider values to the user. Values are displayed below the slider track as the minimum and maximum range.</div>
            </section>

            <section>
                <ul class="bullets">
                    <li>By default, the minimum value starts at 0 and the maximum value is set to 100.</li>
                    <li>Do not use for ranges that extremely large (for example, 1-1000).</li>
                    <li>Do not use for ranges that are very small (for example, 1-3).</li>
                </ul>
            </section>
        
            <img class="kn-responsive-img" src="assets/usage guidelines/slider/KrakenDSM_Components_Slider_07.png">


            <section>
                <div class="text"><span class="kn-bold">Input label</span></div>
                <div class="text">The input value text box allows users to input specific numerical values directly. The input text box is positioned adjacent to the slider for continuous sets of values, which provide an alternative method for users to set precise values by typing them instead of dragging the slider handle.</div>
            </section>
        
            <section>
                <ul class="bullets">
                    <li>For single-handle sliders, the input text box is displayed on the right side of the track.</li>
                    <li>For multi-handle sliders, the input text boxes are displayed on both sides of the slider, which corresponds to the start and end value range.</li>
                    <li>The input value and slider position are in sync. An update to either value updates the other value.</li>
                    <li>By default, numerical values are limited to four characters and are aligned to the center of the text box.</li>
                    <li>If the value ranges from negative to positive, add a plus (+) or minus (-) sign before the value.</li>
                </ul>
            </section>

            <img class="kn-responsive-img" src="assets/usage guidelines/slider/KrakenDSM_Components_Slider_08.png">

            <section>
                <div class="text"><span class="kn-bold">Without an input value</span></div>
                <div class="text">When no input value text box exists, users interact with the slider by dragging the handle. The value is displayed as a tooltip positioned above the focused handle.</div>
            </section>
        </section>


        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/slider/KrakenDSM_Components_Slider_09.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Handle</div>
                <div class="text">The handle is a position indicator that can be moved along the track, which displays the selected value of its position. When sliders display two handles, they indicate the starting and ending values in a range.</div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">Size</span></div>
                <div class="text">By default, the handle is displayed as a <span class="kn-bold">16px</span> circle icon. When hovering and dragging the handle, the circle icon expands in size to <span class="kn-bold">20px</span> and is displayed with an opaque container.</div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">Overlapping handles</span></div>
                <div class="text">When two handles overlap, the selected ranges that these handles represent in a continuous set of values nearly intersect or coincide. The handle representing the start value (left-side) is the primary handle and is displayed above the end value (right-side). The starting value and ending value can never be defined as the same value.</div>
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Width</div>
            <div class="text">The width of a slider can be customized appropriately for its context.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Slider for progress indication</div>
            <div class="text">Do not use the slider component to communicate the status of progress; instead use the progress indicator component.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Do not use a range when choosing a specific value is important</div>
            <div class="text">The slider interaction is not as precise as explicitly typing or choosing an option from a list.</div>
        </section>
    </div>
</div>