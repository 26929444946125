import { Component, Input, Output, EventEmitter, AfterViewInit, OnInit, ElementRef, OnDestroy, ViewEncapsulation, ViewChild } from '@angular/core';

@Component({
  selector: 'kn-panel',
  templateUrl: './kraken-panel.component.html',
  styleUrls: ['./style.min.css'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class KrakenPanelComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() panelId: string;
  @Input() dropShadow = true;
  @Input() trigger: any;
  @Input() panelHeight;
  @Input() panelWidth;
  @Input() sliderContainerElm: string;
  @Input() slideDirection: string;
  @Input() panelType: string;
  @Input() containerToPush: string;
  @Output() onClose = new EventEmitter<boolean>();
  @Output() onOpen = new EventEmitter<boolean>();

  public panelElm: any;
  public sliderContainer: any;
  public panelLeft: any;
  public panelRight: any;
  public panelTop: any;
  public panelBottom: any;
  public slideInLocation: number;
  public toggleSlider = false;
  public pushElm: any;
  public height: string;
  public width: string;
  public minimized = true;
  public inlinePanelOpenWidth: any;
  public panelBody: any;
  public triggerElm: any;
  public isPanelLoaded;

  @ViewChild('panel') panelRef: ElementRef;

  constructor() { }

  ngOnInit() {
    this.panelId = this.panelId ? this.panelId : 'knPanel_' + Math.floor(Math.random()*90000) + 10000;
    this.isPanelLoaded = false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.panelElm = this.panelRef.nativeElement;

      //initialize the component
      if(this.sliderContainerElm) {
        this.sliderInit();
      }

      document.addEventListener('click', this.handleClickOutside.bind(this));
    }, 500);
  }

  handleClickOutside(event) {
    const path = event.composedPath();
    
    // Check if the click was on the trigger
    const clickedOnTrigger = path.some(el => el.matches && el.matches(this.trigger));
    
    if (clickedOnTrigger) {
        this.toggleSlider = !this.toggleSlider;
        this.toggleSlider ? this.open() : this.close();
        return; // Exit early if the click was on a trigger
    }
    
    // Ensure you're only calling contains with valid DOM nodes
    const clickedInsidePanel = path.some(el => 
        el instanceof Node && (el === this.panelElm || this.panelElm.contains(el))
    );
    
    // Close the panel if the click was not inside the panel
    if (!clickedInsidePanel) {
        this.close();
    }
  }

  ngOnDestroy() {
    document.removeEventListener('click', this.handleClickOutside.bind(this));
  }

  close() {
    if(this.slideDirection == 'left') {
      if(this.panelType == 'inline') {
        this.pushElm.classList.add('close');
        this.pushElm.classList.remove('open');
        //change the width to the minimized state
        this.width = '68px';
        this.panelLeft = 0;
        //move the push element back to original state
        this.pushElm.style.marginLeft = '68px';
        //hide panel body
        this.panelBody.style.left = '-' + this.panelBody.getBoundingClientRect().width + 'px';
      } else {
        this.panelRight = 'unset';
        this.panelLeft = -Math.abs(this.panelElm.getBoundingClientRect().width) + 'px';
      }
    } else if(this.slideDirection == 'right') {
      if(this.panelType == 'inline') {
        this.pushElm.classList.add('close');
        this.pushElm.classList.remove('open');
        //change the width to the minimized state
        this.width = '68px';
        this.panelRight = 0;
        //move the push element back to original state
        this.pushElm.style.marginRight = '68px';
        //hide panel body
        this.panelBody.style.right = '-' + this.panelBody.getBoundingClientRect().width + 'px';
      } else {
        this.panelLeft = 'unset';
        this.panelRight = -Math.abs(this.panelElm.getBoundingClientRect().width) + 'px';
      }
    } else if(this.slideDirection == 'top') {
      this.panelTop = -Math.abs(this.panelElm.getBoundingClientRect().height) + 'px';
    } else if(this.slideDirection == 'bottom') {
      this.panelBottom = -Math.abs(this.panelElm.getBoundingClientRect().height) + 'px';
    }
    this.minimized = true;
    this.onClose.emit(true);
    this.toggleSlider = false;
  }

  open() {
    if(this.slideDirection == 'left') {
      if(this.panelType == 'inline') {
        this.pushElm.classList.add('open');
        this.pushElm.classList.remove('close');
        //change the width of the panel to open width
        this.width = this.inlinePanelOpenWidth;
        //move the push element to the right
        this.pushElm.style.marginLeft = this.inlinePanelOpenWidth;
        //show the panel body
        this.panelBody.style.left = 0;
      }
      //move the panel until it is at the edge of the parent
      this.panelLeft = 0;
    } else if(this.slideDirection == 'right') {
      if(this.panelType == 'inline') {
        this.pushElm.classList.add('open');
        this.pushElm.classList.remove('close');
        //change the width of the panel to open width
        this.width = this.inlinePanelOpenWidth;
        //move the push element to the right
        this.pushElm.style.marginRight = this.inlinePanelOpenWidth;
        //show the panel body
        this.panelBody.style.right = 0;
      }
      //move the panel until it is at the edge of the parent
      this.panelRight = 0;
    } else if(this.slideDirection == 'top') {
      this.panelTop = 0;
    } else if(this.slideDirection == 'bottom') {
      this.panelBottom = 0;
    }
    this.minimized = false;
    this.onOpen.emit(true);
    this.toggleSlider = true;
  }

  sliderInit() {
    //update the panel wrapper to display the panel
    this.sliderContainer = document.querySelector(this.sliderContainerElm);
    this.sliderContainer.style.position = "relative";

    //set the width and height of the panel
    if(this.slideDirection == 'left' || this.slideDirection == 'right') {
      this.width = this.panelWidth != undefined ? this.panelWidth : '200px';
      this.height = this.panelHeight != undefined ? this.panelHeight : '100%';
    } else {
      this.width = this.panelWidth != undefined ? this.panelWidth : '100%';
      this.height = this.panelHeight != undefined ? this.panelHeight : '200px';
    }

    //check if the panel will push the content
    if(this.panelType == 'inline') {
      this.panelBody = this.panelElm.querySelector('.kn-panel-body');
      this.inlinePanelOpenWidth = this.width;
      this.pushElm = document.querySelector(this.containerToPush);
      this.pushElm.classList.add('kn-push-container');

      //push the content to the left or right
      if(this.slideDirection == 'left') {
        this.panelBody.style.left = '-' + this.panelBody.getBoundingClientRect().width + 'px';
        this.pushElm.style.marginLeft = '68px';
        this.panelLeft = 0;
        this.panelRight = 'unset';
      } else if(this.slideDirection == 'right') {
        this.panelBody.style.right = '-' + this.panelBody.getBoundingClientRect().width + 'px';
        this.pushElm.style.marginRight = '68px';
        this.panelLeft = 'unset';
        this.panelRight = 0;
      }
      if(this.slideDirection == 'left' || this.slideDirection == 'right') {
        this.width = '68px';
      } else {
        this.height = '68px';
      }
    } else {
      //set the initial state of the slider position
      if(this.slideDirection == 'left') {
        this.sliderContainer.style.overflowX = "hidden";
        this.panelLeft = '-' + this.width;
      } else if(this.slideDirection == 'right') {
        this.sliderContainer.style.overflowX = "hidden";
        this.panelRight = '-' + this.width;
      } else if(this.slideDirection == 'top') {
        this.sliderContainer.style.overflowY = "hidden";
        this.panelTop = '-' + this.width;
      } else if(this.slideDirection == 'bottom') {
        this.sliderContainer.style.overflowY = "hidden";
        this.panelBottom = '-' + this.width;
      }
    }
    
    this.isPanelLoaded = true;
  }

  panelToggleEvent() {
    this.toggleSlider = !this.toggleSlider;
    if(this.toggleSlider) {
      this.open();
    } else {
      this.close();
    }
  }
}
