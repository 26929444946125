import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import { FormGroup, FormControl } from '@angular/forms';
import  packageInfo from '../../../../../kraken-textbox/package.json';

@Component({
  selector: 'app-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.sass']
})
export class TextBoxComponent implements OnInit {
  public exampleForm: FormGroup;
  public disabled = false;
  public buttonName = 'textboxName';
  public label = 'Textbox example';
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public componentId = 'textboxComponentId';
  public webSetProperties;
  public webClickEvent;
  public webRemoveProperties;
  public elementTag = 'kn-textbox';
  public webElementTag = 'kn-web-textbox';
  public emailValidator = false;
  public customError = false;
  public customWarning = false;
  public customMess = "";
  public event1 = true;
  public event2 = true;
  public eventValue = 'Textbox value';
  public exampleText: string;
  public inputRequired = false;
  public readOnly = false;
  public webTextboxDisabled;
  public webTextboxReadonly;
  public placeholder = '';
  public showPlaceholder = false;
  public clearIcon = false;
  public showCounter = false;
  public maxChars = 0;
  public radioError = true;
  public radioWarning = false;
  public addCustomMessage = false;
  public optionalLabel = false;
  public setCustomMessage = false;
  public compressed = false;
  public inputType = 'text';
  public password = false;
  public onValueChange = false;
  public onValidate = false;
  public textboxName: any;
  public input2: any;
  public onClear = false;
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenTextboxModule', 
    form: true};
  public buttonValidation: string;
  public validationTS: string;
  public messageType = '';
  public customDirective = false;
  public hideErrorMessage = false;
  public initializeComponentWeb: any;
  public attributes = [{name: 'emailvalidation', value: ''}, {name: 'validateemail', value: true}]
  public angularComponentOptions: any = [
    { option: '[textboxId]=', id: 0, value: '"\'exampleTextbox\'"'},
    { option: '[label]=', id: 1, value: '"\'' + this.label + '\'"'},
    { option: '[(ngModel)]=', id: 2, value: '"textboxValue"'},
    { option: '[name]=', id: 3, value: '"\'' + this.buttonName +'\'"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"'+ this.componentId +'"'},
    { option: 'textbox-id=', id: 0, value: '"exampleTextbox"'},
    { option: 'label=', id: 1, value: '"' + this.label +'"' },
    { option: 'name=', id: 2, value:  '"exampleTextbox"'}
  ];

  public themes: any = [
    {color:"#F0F2F4 ", name: "Gray Lighter"},
    {color:"#434C54", name: "Ink Light"},
    {color: "#fff", name: "White"}
  ];

  public themeFields: object = { text: 'name', value: 'color'};

  public selectedTheme: string;
  public selectedThemeDisplayName: string;

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {

    this.exampleForm = new FormGroup({
      textboxName: new FormControl('', []),
      email: new FormControl('', [])
    });

    //set theme
    this.selectedTheme = this.themes[2].color;
    this.selectedThemeDisplayName = this.themes[2].name;

    this.buttonValidation =
`
<kn-button [buttonId]="'exampleButton'" [buttonType]="'kn-primary'" [buttonText]="'Submit'" [disabled]="textboxName?.invalid"></kn-button>
`

    this.validationTS = 
`
public exampleForm: FormGroup;
public textboxName: any;

this.exampleForm = new FormGroup({
  textboxName: new FormControl('', [])
});

validate(event: any) {
  this.textboxName = event;
}
`
    this.initializeComponentWeb = `
const textboxElm = document.querySelector('#${this.componentId}');
    `
    this.webClickEvent = 
`
textboxElm.addEventListener('onValueChange', (event) => console.log(event.detail));
`
    this.webSetProperties = 
`
textboxElm.customError = true;
textboxElm.customMess = 'this is an error';
`
    this.webRemoveProperties = 
`
textboxElm.customError = false;
`
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  labelText(event, id) {
    this.label = event;
    
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.id == id));
    this.angularComponentOptions[index].option = '[label]=';
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.id == id));
    this.webComponentOptions[webIndex].option = 'label=';
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  message(event) {
    this.customMess = event;
    
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[customMess]='));
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'custom-mess='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  valueChange(event) {
    this.eventValue = event ? event.value : "Textbox value";
  }

  showHidePlaceholder(event) {
    this.placeholder = event ? this.placeholder : '';
    this.controlEvent(event, '[setPlaceholder]=', this.placeholder, true); 
    this.showPlaceholder = !this.showPlaceholder
  }

  placeholderValue(event) {
    this.placeholder = event;
    
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[setPlaceholder]='));
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'set-placeholder='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  charCount(event) {
    this.maxChars = event;
    
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[maxChars]='));
    this.angularComponentOptions[index].value = '"' + event +'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'max-chars='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  showCharCount(event) {
    this.showCounter = event;
    if(!event) {
      this.maxChars = null;
    } else {
      this.maxChars = 0;
    }
  }

  customMessToggle(event) {
    this.addCustomMessage = event;
    if(!event) {
      this.removeCustomMessage();
    }
  }

  customMessage(event) {
    if(event.value == 'radioError') {
      this.customError = true;
      this.customWarning = false;
      this.controlEvent(false, '[customWarning]=', true, true);  
      this.controlEvent(false, '[customMess]=', '' + this.customMess +'', true);
      this.controlEvent(event, '[customError]=', true, true); 
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    } else if (event.value == 'radioWarning'){
      this.customError = false;
      this.customWarning = true;
      this.controlEvent(false, '[customError]=', true, true); 
      this.controlEvent(false, '[customMess]=', '' + this.customMess +'', true);
      this.controlEvent(event, '[customWarning]=', true, true); 
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    } else {
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    }
  }



  removeCustomMessage() {
    this.messageType = '';
    this.customError = false;
    this.customWarning = false;
    this.controlEvent(false, '[customError]=', true, true); 
    this.controlEvent(false, '[customWarning]=', true, true);  
    this.controlEvent(false, '[customMess]=', true, true); 
  }

  passwordInput(event) {
    this.inputType = event ? 'password' : 'text';
    this.controlEvent(event, '[type]=', this.inputType, true);
    if(event) {
      //angular
      const index = this.angularComponentOptions.findIndex((obj => obj.option == '[type]='));
      this.angularComponentOptions[index].option = '[type]=';
      this.angularComponentOptions[index].value = '"\'' + this.inputType +'\'"';
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
      //web
      const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'type='));
      this.webComponentOptions[webIndex].option = 'type=';
      this.webComponentOptions[webIndex].value = '"' + this.inputType + '"';
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    }
  }


}
