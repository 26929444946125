<div class="canvas-inner-container">
    
    <div class="section-container">

        <section>
            <div class="kn-headline-24-med">{{header}}</div>
        </section>

        <section>
            <div class="text">{{infoText}}</div>
        </section>

        <img class="component-accessibility-img" src="assets/accessibility/textbox/textbox.png">
    
        <section>
            <div class="kn-headline-24-med">Color Blindness</div>
        </section>

        <section>
            <div class="text">Color blindness tests are performed via the Stark plugin for Sketch.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Protanopia</span></div>
            <div class="text">Makes red look more green and less bright. This type is mild and usually doesn’t get in the way of normal activities. Protanopia will make you unable to tell the difference between red and green at all.</div>
        </section>

        <img class="kn-responsive-img" src="assets/accessibility/textbox/Textbox-LightTheme_Protanopia.png">

        <section>
            <div class="text"><span class="kn-bold">Deuteranopia</span></div>
            <div class="text">The most common type of red-green color blindness. It makes green look more red. This type is mild and doesn’t usually get in the way of normal activities. Deuteranopia also makes you unable to tell the difference between red and green at all.</div>
        </section>

        <img class="kn-responsive-img" src="assets/accessibility/textbox/Textbox-LightTheme_Deuteranopia.png">

        <section>
            <div class="text"><span class="kn-bold">Tritanopia</span></div>
            <div class="text">This less common type of color blindness makes it hard to tell the difference between blue and green, and between yellow and red. It makes you unable to tell the difference between blue and green, purple and red, and yellow and pink. It also makes colors look less bright.</div>
        </section>

        <img class="kn-responsive-img" src="assets/accessibility/textbox/Textbox-LightTheme_Tritanopia.png">

        <section>
            <div class="text"><span class="kn-bold">Achromatopsia</span></div>
            <div class="text">A condition characterized by a partial or total absence of color vision. People with complete Achromatopsia cannot perceive any colors; they see only black, white, and shades of gray. Incomplete Achromatopsia is a milder form of the condition that allows some color discrimination.</div>
        </section>

        <img class="kn-responsive-img" src="assets/accessibility/textbox/Textbox-LightTheme_Achromatopsia.png">

    </div>

</div>