<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Validation Errors</div>
        
        <section>
            <div class="kn-headline-24-med">Error messaging</div>
        </section>
        
        <section>
            <img class="kn-responsive-img" src="assets/style guide/content/validation errors/KrakenDSM_StyleGuide_Content_ValidationErrors_01.png">
        </section>

        <section>
            <div class="text">A text box can be marked as having an error to show that a value needs to be entered in order to move forward or that a value that was entered is invalid.</div>
        </section>

        <section>
            <div class="text">Write error messaging in a human-centered way by guiding a user and showing them a solution. Don’t display ambiguous error messages.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Examples</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Matters</div>
            
            <div class="text">Matter name input field: "This field is required"</div>
            <div class="text">Matter name input field: "Special characters not permitted"</div>
            <div class="text">Matter name input field: "Matter name already exists"</div>
            <div class="text">E-Billing ID input field: "E-Billing ID already exists"</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Users</div>
            
            <div class="text">First name input field: "This field is required"</div>
            <div class="text">Last name input field: "This field is required"</div>
            <div class="text">Email input field: "Not a valid email address"</div>
            <div class="text">Email input field: "Email already exists"</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Clients</div>
            
            <div class="text">Client name input field: "This field is required"</div>
            <div class="text">Client name input field: "Special characters not permitted"</div>
            <div class="text">Client ID code input field: "This field is required"</div>
            <div class="text">Client ID code input field: "Client ID Code already exists"</div>
            <div class="text">Client owner email input field: "Email already exists"</div>
            <div class="text">Client contact email input field: "Incorrect email format"</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Matter Details (Processing)</div>
            
            <div class="text">Volume name input field: "This field is required"</div>
            <div class="text">Volume name input field: "Special characters not permitted"</div>
            <div class="text">Volume name input field: "Volume name already exists"</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Matter Details (Exports)</div>
            
            <div class="text">Export volume name input field: "This field is required"</div>
            <div class="text">Export volume name input field: "Special characters not permitted"</div>
            <div class="text">Export volume name input field: "Export name already exists"</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Matter Details (Custodian management)</div>
            
            <div class="text">First name input field: "This field is required"</div>
            <div class="text">First name input field: "Special characters not permitted"</div>
            <div class="text">Last name input field: "This field is required"</div>
            <div class="text">Last name input field: "Special characters not permitted"</div>
            <div class="text">Custodial value input field: "This field is required"</div>
            <div class="text">Custodial value input field: "Special characters not permitted"</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Login</div>
            
            <div class="text">Create password input field: "The password entered does not meet minimum complexity requirements. Please enter a new password"</div>
            <div class="text">Confirm password input field: "Password does not match"</div>
        </section>

    </div>
</div>