import { NgModule } from '@angular/core';
import { KrakenDateRangePickerComponent } from './kraken-date-range-picker.component';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    KrakenDateRangePickerComponent
  ],
  imports: [
    DateRangePickerModule,
    NumericTextBoxModule,
    CommonModule
  ],
  exports: [
    KrakenDateRangePickerComponent
  ]
})
export class KrakenDateRangePickerModule { }
