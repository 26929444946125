<app-canvas 
    [js]="true" 
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef">
    <div title>Radio Button <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-radio-button 
            [radioId]="'radio1'" 
            [value]="false" 
            [name]="'radioGroup'" 
            [customLabel]="customLabelTemp" 
            [label]="label1"
            (onSelect)="radioSelection($event)" 
            [disabled]="radio1Disabled"
            [(ngModel)]="defaultRadioOption"
            [error]="errorEnabled"
            [warning]="warningEnabled"
            [defaultValue]="defaultRadioOption">
            <ng-template #labelTemplate>
                <div class="radio-label">Custom label with <span class="kn-bold">HTML</span></div>
            </ng-template>
        </kn-radio-button>
        <br/>
        <kn-radio-button 
            [radioId]="'radio2'" 
            [value]="true" 
            [name]="'radioGroup'" 
            [label]="label2" 
            (onSelect)="radioSelection($event)" 
            [disabled]="radio1Disabled"
            [(ngModel)]="defaultRadioOption"
            [defaultValue]="defaultRadioOption">
        </kn-radio-button>
    </div>
    <div angular>
        <br/>
        <span class="code-comment">&lt;!--When adding multiple radio buttons, make sure to group them with the same [name] --></span>
        <span class="html-tags">&lt;{{angularComponentRef2.tag}} </span> 
        <ng-container *ngFor="let option of angularComponentRef2.options">
            <span class="html-option"> {{option.option}}</span><span class="html-value">{{option.value}}</span>
        </ng-container>
        <span class="html-tags">&gt;&lt;/{{angularComponentRef2.tag}}&gt;</span> 
    </div>
    <div web>
        {{webComponentRef}}
        <br/><br/>
        {{webComponent2Ref}}
    </div>
    <div javascript>
        <span class="code-comment">//Initialize the component</span>
        {{initializeComponentWeb}}
        <span class="code-comment">//Radio select event</span>
        {{webClickEvent}}
        <span class="code-comment">//All properties with a boolean value needs to be set in code like this</span>
        {{webSetProperties}}
        <span class="code-comment">//Remove a boolean property like this</span>
        {{webRemoveProperties}}
    </div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onSelect():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">id:</span> "{{radioEvent?.id}}"</div>
                    <div class="obj-item"><span class="label">label:</span> "{{radioEvent?.label}}"</div>
                    <div class="obj-item"><span class="label">name:</span> "{{radioEvent?.name}}"</div>
                    <div class="obj-item"><span class="label">value:</span> "{{radioEvent?.value}}"</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-row">
            <kn-textbox [label]="'Radio button label'" [value]="label1" (onValueChange)="labelText($event, 1)" [clearIcon]="true" [setDisabled]="customLabelTemp"></kn-textbox>
            <div class="kn-spacer"></div>
            <kn-textbox [label]="'Radio button label'" [value]="label2" (onValueChange)="labelText($event, 2)" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle label="Custom label (HTML)" [(ngModel)]="customLabelTemp" (value)="customTemplateEvent($event);"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled state" (value)="controlEvent($event, '[disabled]=', true, true); radio1Disabled = !radio1Disabled" [disabled]="errorEnabled || warningEnabled"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Error state" [(ngModel)]="errorEnabled" (value)="controlEvent($event, '[error]=', true, true);" [disabled]="warningEnabled || radio1Disabled"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Warning state" [(ngModel)]="warningEnabled" (value)="controlEvent($event, '[warning]=', true, true);" [disabled]="errorEnabled || radio1Disabled"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Select event" (value)="controlEvent($event, '(onSelect)=', 'selectedRadio($event)', false); radioSelectEvent = !radioSelectEvent"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">radioId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"radioExample"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">name</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"radioGroup"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">label</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Radio example"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customLabel</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">error</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">warning</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">disbled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">defaultValue</div>
                <div class="item"><i>any</i></div>
                <div class="item"><i>boolean, string, number</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onSelect</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <radio-button-usage></radio-button-usage>
    </div>
    <div designSpec>
        <radio-button-specs></radio-button-specs>
    </div>
    <div accessibility>
        <radio-button-accessibility></radio-button-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the radio button component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

