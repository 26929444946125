<div class="page-container" id="iconCatalog">
    <div class="kn-page-title">Iconography</div>
    
    <div class="icon-container">
        <p class="info">
            The following illustrates the catalog of available iconography utilized in the application UI at the size(s) implemented. All icons listed are available in the Kraken UI kit (sketch symbol library).
            
            <br/>This catalog continues to expand as new application requirements introduce the need for additional icons.
        </p>
        <div class="icon-search">
            <kn-autocomplete 
                [id]="'iconInput'"
                [data]="icons" 
                [keyWord]="'keywords'" 
                [fields]="fields" 
                [hideDropdown]="true"
                [placeholder]="'Search icons'"
                (onKeyUp)="onKeyUp($event)"
                (onSelect)="onSelect()"
                (onClear)="clear($event)">
            </kn-autocomplete>

            <div class="icon-count">{{iconCount}} Icon<span *ngIf="iconCount > 1">s</span></div>
        </div>

        <div class="results-container">
            <div class="results">
                <div *ngFor="let icon of iconResults">
                    <div class="item" (click)="showIconModal(icon)">
                        <div class="item-container">
                            <i class="{{icon.className}}"></i>
                            <div class="icon-title">{{icon.title}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</div>

<!--Icon modal-->
<kn-modal [id]="'iconModal'" [title]="iconTitle" (onOpen)="open()" (onClose)="close()">
    <div class="icon-info-container">
        <div class="icon-info">
            <div class="icon-demo">
                <i class="{{className}}" id="icondemo" [ngClass]="{
                    'kn-icon-lg': size == 'kn-icon-lg', 
                    'kn-icon-md': size == 'kn-icon-md',
                    'kn-icon-sm': size == 'kn-icon-sm'
                }"></i>
            </div>
            <div class="icon-spec">
                <div class="item"><label>Class Name:</label><div class="value">{{className}}</div></div>
                <br/>
                <div class="item"><label>Type:</label><div class="value">{{type}}</div></div>
                <div class="item"><label>Added:</label><div class="value">{{added}}</div></div>
                <div class="item"><label>Updated:</label><div class="value">{{updated}}</div></div>
            </div>
        </div>
        <div class="icon-code">
            <div class="size-container">
                <kn-button [buttonType]="'kn-flat kn-btn-sm'" [buttonText]="'Small 16px'" [active]="size == 'kn-icon-sm'" (click)="size = 'kn-icon-sm'"></kn-button>
                <kn-button [buttonType]="'kn-flat kn-btn-sm'" [buttonText]="'Medium 20px'" [active]="size == 'kn-icon-md'" (click)="size = 'kn-icon-md'"></kn-button>
                <kn-button [buttonType]="'kn-flat kn-btn-sm'" [buttonText]="'Large 40px'" [active]="size == 'kn-icon-lg'" (click)="size = 'kn-icon-lg'"></kn-button>
            </div>
            <i class="fa-regular fa-copy copy kn-icon kn-icon-dark" (mouseover)="resetCopyText()" tooltip="{{copyText}}" [tooltipDirection]="'top'" aria-hidden="true" (click)="copy()"></i>
            <kn-tabs [activeTab]="0" [id]="'iconHtmlTabs'" (onSelect)="tabClick($event)">
                <kn-tab tabTitle="HTML">
                    <div class="html">
                        <pre>
                            <code class="code" [ngClass]="{'copied': isCopied}">&lt;i class="{{className}} {{size}}"&gt;&lt;/i&gt;</code>
                        </pre>
                    </div>
                </kn-tab>
                <kn-tab tabTitle="React" *ngIf="type != 'Kraken DS (custom)'">
                    <div class="html">
                        <pre>
                            <code class="code" [ngClass]="{'copied': isCopied}">&lt;FontAwesomeIcon icon="{{className}} {{size}}"/&gt;</code>
                        </pre>
                    </div>
                </kn-tab>
            </kn-tabs>
        </div>
    </div>
</kn-modal>