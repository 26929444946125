
<div class="kn-icon-btn">
    <button 
        id="{{buttonId}}" 
        class="kn-btn kn-btn-icon {{buttonType}}" 
        [ngClass]="{
            'disabled': disabled,
            'dropshadow': dropShadow,
            'active': active
        }"
        aria-label="button icon"
        (click)="onClick()">
        <i class="{{icon}}"></i>
    </button>
</div>
