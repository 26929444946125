<div class="canvas-inner-container">
    
    <div class="section-container">

        <section>
            <div class="kn-headline-24-med">{{header}}</div>
        </section>

        <section>
            <div class="text">{{infoText}}</div>
        </section>

        <div class="kn-alert-warning">
            <i class="fa-solid fa-triangle-exclamation"></i>Please Note: The 5 "critical" errors in the accessibility report are the result of third-party Syncfusion component code of which we have no control and are related to the following accessibility failure:
            <ul>
                <li>ARIA attributes must conform to valid names</li>
            </ul> 
            The single "serious" error is specific to the "CLEAR ALL" data grid control button when displayed in a disabled state, with the following accessibility failure: 
            <ul>
                <li>Elements must have sufficient color contrast</li>
            </ul> 
            This is not considered a true failure as buttons in a disabled state are exempt from meeting the same WCAG 2.1 visual contrast standards as their active, hover, and selected counterpart states.
        </div>

        <img class="component-accessibility-img" src="assets/accessibility/grid/grid.png">
    
        <section>
            <div class="kn-headline-24-med">Color Blindness</div>
        </section>

        <section>
            <div class="text">Color blindness tests are performed via the Stark plugin for Sketch.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Protanopia</span></div>
            <div class="text">Makes red look more green and less bright. This type is mild and usually doesn’t get in the way of normal activities. Protanopia will make you unable to tell the difference between red and green at all.</div>
        </section>

        <img class="kn-responsive-img" src="assets/accessibility/datagrid/DataGrid-LightTheme_Protanopia.png">

        <section>
            <div class="text"><span class="kn-bold">Deuteranopia</span></div>
            <div class="text">The most common type of red-green color blindness. It makes green look more red. This type is mild and doesn’t usually get in the way of normal activities. Deuteranopia also makes you unable to tell the difference between red and green at all.</div>
        </section>

        <img class="kn-responsive-img" src="assets/accessibility/datagrid/DataGrid-LightTheme_Deuteranopia.png">

        <section>
            <div class="text"><span class="kn-bold">Tritanopia</span></div>
            <div class="text">This less common type of color blindness makes it hard to tell the difference between blue and green, and between yellow and red. It makes you unable to tell the difference between blue and green, purple and red, and yellow and pink. It also makes colors look less bright.</div>
        </section>

        <img class="kn-responsive-img" src="assets/accessibility/datagrid/DataGrid-LightTheme_Tritanopia.png">

        <section>
            <div class="text"><span class="kn-bold">Achromatopsia</span></div>
            <div class="text">A condition characterized by a partial or total absence of color vision. People with complete Achromatopsia cannot perceive any colors; they see only black, white, and shades of gray. Incomplete Achromatopsia is a milder form of the condition that allows some color discrimination.</div>
        </section>

        <img class="kn-responsive-img" src="assets/accessibility/datagrid/DataGrid-LightTheme_Achromatopsia.png">

    </div>

</div>
