<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text">The <span class="kn-bold">Spin Box </span> component is a text box that accepts a range of values. It incorporates an incremental/decremental control using a discrete range of integer values from which users can select.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Advantages</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1. Precise numeric input:</span> A spin box component allows users to input numerical values with precision by providing increment and decrement controls, which ensures accurate data entry.</div>
            <div class="text"><span class="kn-bold">2. Relative controls when users don’t know exact values:</span> Since spin boxes are relative controls--they can free users from considering the exact value of the variable they are modifying. Users only need to consider whether they want the value to go up or down (relative to its current state).</div>
            <div class="text"><span class="kn-bold">3. Efficient data adjustment:</span> In scenarios that require quick adjustments to numeric values, a spin box offers a more efficient alternative to manual entry.</div>
            <div class="text"><span class="kn-bold">4. Consistency in input:</span> By limiting the input to numeric values, spin boxes ensure consistency in data formatting, which reduces the risk of input errors and promotes a standardized approach across different users and contexts.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Disadvantages</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1. Limited range handling:</span> Spin box components are less suitable for scenarios where users need to input values outside of the predefined range.</div>
            <div class="text"><span class="kn-bold">2. Reduced speed for large changes:</span> Spin boxes are not suitable for a large number of adjustments (for example, when users need to change a value from 1 to 50).</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Spin box with label</span></div>
            <div class="text"><span class="kn-bold">2.	Spin box without label</span></div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_02.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Vertical - Right aligned</span></div>
            <div class="text"><span class="kn-bold">2.	Vertical - Left aligned</span></div>
            <div class="text"><span class="kn-bold">3.	Horizontal</span></div>
        </section>


        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_03.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Enabled:</span> When enabled, the text box displays a default value.</div>
            <div class="text"><span class="kn-bold">2.	Focused:</span> When a user clicks on the text box, the field becomes focused, which indicates that the user has successfully navigated to the component.</div>
            <div class="text"><span class="kn-bold">3.	Hover - Incremental/decremental control:</span> When a user’s mouse cursor hovers  over the incremental and decremental button controls.</div>
            <div class="text"><span class="kn-bold">4.	Read only:</span> When the user can review, (but not modify) the component. This state removes all interactive functions like the disabled state, but the value passes visual contrast for readability.</div>
            <div class="text"><span class="kn-bold">5.	Disabled: </span> When the user cannot interact with a component and all interactive functions are removed.</div>
            <div class="text"><span class="kn-bold">6.	Disabled - Incremental/decremental control</span></div>
            <div class="text"><span class="kn-bold">7.	Error:</span> When the required input text value is not entered. This state requires a user response before submitting or saving data.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_04.png">


        <section>
            <div class="text"><span class="kn-bold">A.	Container:</span> Containers improve spin box visibility by creating contrast between the spin box and surrounding content. The container consists of the text box component and controls.</div>
            <div class="text"><span class="kn-bold">B.	Label text:</span> Label text informs users about what information is requested for spin box.</div>
            <div class="text"><span class="kn-bold">C.	Numeric input value:</span> The value changes when a user manually enters a value into the field or uses the two-segment control.</div>
            <div class="text"><span class="kn-bold">D.	Incremental/decremental control:</span> Two-segment incremental or decremental icon buttons allowing user to adjust the numeric value.</div>
            <div class="text"><span class="kn-bold">E.	Error icon: </span> Displaying an error icon in association with text makes error states clear for colorblind users.</div>
            <div class="text"><span class="kn-bold">F.	Error text: </span> When an error occurs, error messages are displayed below the spin box container.</div>
        </section> 
        

        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_05.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Label</div>
                <div class="text">Use the label to explain the purpose of the spin box field and type of value that a user must enter. Ensure labels are always visible and positioned above the container and are consistent with the label positioning of other components in your form.</div>
            </section>
            
            <section>
                <ul class="bullets">
                    <li>Label text should always be uppercase.</li>
                    <li>Keep the label short and concise.</li>
                    <li>Ensure the label is left-aligned above the spin box.</li>
                </ul>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_06.png">
        
        <section>
            <section>
                <div class="kn-subtitle-16">Value</div>
                <div class="text">The input text that a user enters. The input text is displayed inside of the text field.</div>
            </section>
        </section>
        
        <section>
            <ul class="bullets">
                <li><span class="kn-bold">Spin boxes are for numerical input only.</span> For non-numeric sets of options, use a list or a single-select dropdown instead.</li>
                <li><span class="kn-bold">Set the most frequently selected value as a stepper default.</span> Use only a value from the predefined options to display as the default.</li>
                <li><span class="kn-bold">Do not leave a value input blank.</span></li>
                <li><span class="kn-bold">Left-align the input text,</span> unless a spin box is displayed in association with another component such as a slider.</li>
                <li><span class="kn-bold">The spin box accepts and displays only whole number values.</span></li>
            </ul>
        </section>


        <section>
            <section>
                <div class="kn-subtitle-16">Controls</div>
            </section>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_07.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Vertical controls</span></div>
                <div class="text">By default, a spin box displays the controls for increasing and decreasing the field value in a vertical stack. The stack can be appended to either the right or left side of the text box.</div>
            </section>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_08.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Horizontal controls</span></div>
                <div class="text">The spin box controls can also be placed horizontally to the left or right of the text box.</div>
            </section>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_09.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Incremental value step</span></div>
                <div class="text">As a user selects the spin box controls, they can increase or decrease the value displayed in the input field by a predefined increment. Manually entered values are automatically rounded up to the nearest defined increment.</div>
            </section>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_10.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Customized value step</span></div>
                <div class="text">An alternate configuration for the spin box defines a custom array of sequential values that may or may not be equally distributed. As the user selects the form controls, they increase or decrease the value displayed in the field by the predefined increment. If the user enters a value manually, the spin box automatically rounds the value up to the nearest defined increment.</div>
            </section>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_11.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Reaching maximum or minimum</span></div>
                <div class="text">The corresponding icon button control is disabled when reaching the predefined maximum or minimum value, which indicates that no further incremental steps are possible in that direction.</div>
            </section>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_12.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Alternative controls</span></div>
                <div class="text">In addition to the default arrow up and arrow down controls, the addition and subtraction operators can also be used.</div>
            </section>
        
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_13.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Error text</span></div>
                <div class="text">A spin box can show an error when a required value is not yet entered or when a value is invalid.</div>
            </section>
        
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_14.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Size</span></div>
                <div class="text">Two spin box sizes are available: default and compressed. The compressed size is reserved for compressed grid rows where users can scan and take action on large amounts of information.</div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">1.	Default spin box:</span> 40px height, 14px text</div>
                <div class="text"><span class="kn-bold">2.	Compressed spin box:</span> 30px height, 12px text</div>
            </section>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_15.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Display width</span></div>
                <div class="text">The display width is dependent on the container in which the spin box is displayed. Ensure the defined minimum and maximum input text values are always displayed in their entirety and are not truncated (ellipses). Spin boxes require a minimum width of<span class="kn-bold"> 84px.</span></div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">Rounded corners</span></div>
                <div class="text">Spin box containers are displayed with a 4px rounded corner.</div>
            </section>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinbox/KrakenDSM_Components_SpinBox_16.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Sliders</span></div>
                <div class="text">In some cases, a spin box linked with another component such as a slider might be appropriate. This specific combination allows both approximate control and the entry of specific values.</div>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Discrete number of predefined values</div>
            <div class="text">Avoid using a spin box when users can only choose from a discrete number of predefined values. For such use cases, consider using the single-select dropdown component instead.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Significant range of increments</div>
            <div class="text">Avoid using a spin box when the range and increment values available to the user must be immediately communicated. For such use cases, consider using the slider component instead.</div>
        </section>
    </div>
</div>