import { NgModule } from '@angular/core';
import { KrakenModalComponent } from './kraken-modal.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [KrakenModalComponent],
  imports: [
    CommonModule
  ],
  exports: [KrakenModalComponent]
})
export class KrakenModalModule { }
