import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'grid-usage',
  templateUrl: './grid-usage.component.html',
  styleUrls: ['./grid-usage.component.sass']
})
export class GridUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
