<app-canvas 
    [ts]="true" 
    [js]="true" 
    [disableCanvasTab]="5" 
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component"
    [reactUrl]="'https://stackblitz.com/edit/github-rqclvg-7eqvfa?embed=1&file=src%2FApp.js'">
    <div title>Single-Select Dropdown <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-dropdown
            [template]="dropdownTemplate" 
            [dropdownId]="componentId"
            [value]="dropdownValue" 
            [fields]="dropdownFields"
            [label]="label"
            [placeholder]="placeholder"
            [items]="items"
            [optional]="optional"
            (onSelect)="valueChange($event)"
            [(ngModel)]="dropdownValue"
            [setRequired]="required"
            [customError]="customError"
            [customWarning]="customWarning"
            [customMess]="customMess"
            [compressed]="compressed"
            [dropdownCustomWidth]="dropdownCustomWidth"
            [dropdownRightAligned]="dropdownRightAligned"
            [width]="dropdownWidth"
            [name]="dropdownName"
            [setReadOnly]="readOnly"
            [setDisabled]="disabled"
            (onValidate)="dropdownField = $event">
            <ng-template  #customTemplate let-data="data">
                <div class="country-item"><span class="country-code">{{data.id}}</span><span class="country">{{data.country}}</span></div>
            </ng-template>
    </kn-dropdown>
    </div>
    <div angular>
        <br/>
        <div *ngIf="onValidate">
            <span class="code-comment">&lt;!--you can bind to the validation event then use it like this --></span>
            <span class="html-option">dropdownField?.invalid</span>
        </div>
    </div>
    <div typeScript>
        <span class="code-comment">//Dropdown items using Objects, add [fields]="fields" </span>
        {{tsDropdownItemsObj}}
        <span class="code-comment">//Dropdown items using an Array</span>
        {{tsDropdownItemsArray}}
        <span class="code-comment">//Disable an item within an object array, you can either set the individual item to disabled in code as shown below, or, if the object has a property named "disabled" and its set to "true"</span>
        {{tsDropdownDisableObjectItem}}
    </div>
    <div javascript>
        <span class="code-comment">//Initialize the component</span>
        {{initializeComponentWeb}}
        <span class="code-comment">//Item select event</span>
        {{webClickEvent}}
        <span class="code-comment">//Dropdown using an object</span>
        {{webDropdownItemsObj}}
        <span class="code-comment">//Dropdown using a simple array</span>
        {{webDropdownItemsArray}}
        <span class="code-comment">//All properties with a boolean value needs to be set in code like this</span>
        {{webSetProperties}}
        <span class="code-comment">//Remove a boolean property like this</span>
        {{webRemoveProperties}}
    </div>
    <div web></div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onSelect():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">id:</span> "{{eventItem?.id}}"</div>
                    <div class="obj-item"><span class="label">country:</span> "{{eventItem?.country}}"</div>
                    <div class="obj-item"><span class="label">code:</span> "{{eventItem?.code}}"</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-row">
            <kn-textbox [label]="'Single-select label'" [value]="label" (onValueChange)="labelText($event)" [clearIcon]="true" [setDisabled]="compressed"></kn-textbox>
            <div class="kn-spacer"></div>
            <kn-checkbox (onSelect)="controlEvent($event.value, '[optional]=', true, true); optional = !optional" [label]="'Optional field'" [disabled]="compressed || required"></kn-checkbox>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle [label]="'Placeholder'" (value)="showHidePlaceholder($event)" [(ngModel)]="showPlaceholder"></kn-toggle>
        </div>
        <div class="action-input" *ngIf="showPlaceholder">
            <kn-textbox [label]="'Placeholder text'" [value]="placeholder" (onValueChange)="placeholderValue($event)" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle label="Dropdown width" (value)="customDrpdnWidth($event); isDropdownWidthCustom = !isDropdownWidthCustom" ></kn-toggle>
        </div>
        <div class="action-input" *ngIf="isDropdownWidthCustom">
            <div class="input-info">
                <kn-textbox 
                    [label]="'Enter a value'" 
                    [(ngModel)]="dropdownCustomWidth" 
                    [value]="dropdownCustomWidth" 
                    [setPlaceholder]="'400px'"
                    (onValueChange)="customDrpdnWidthValue($event)" 
                    [clearIcon]="true">
                </kn-textbox>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="This is a CSS width property, you can enter px, %, etc" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <div class="item-row">
            <kn-toggle label="Dropdown height" (value)="customDrpdnHeight($event); isDropdownHeightCustom = !isDropdownHeightCustom" ></kn-toggle>
        </div>
        <div class="action-input" *ngIf="isDropdownHeightCustom">
            <div class="input-info">
                <kn-textbox 
                    [label]="'Enter a value'" 
                    [(ngModel)]="dropdownCustomHeight" 
                    [value]="dropdownCustomHeight" 
                    [setDisabled]="" 
                    [setPlaceholder]="'400px'"
                    (onValueChange)="customDrpdnHeightValue($event)" 
                    [clearIcon]="true">
                </kn-textbox>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="This is a CSS height property, you can enter px, %, etc" [tooltipDirection]="'top'"></i>
            </div>
        </div>
        <div class="item-row">
            <kn-toggle label="Right-align dropdown" [(ngModel)]="dropdownRightAligned"  (value)="controlEvent($event, '[dropdownRightAligned]=', 'true', true);" [disabled]="onValueChange"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Dropdown template"  (value)="customTemplateEvent($event)" [disabled]="onValueChange"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Compressed field"  (value)="controlEvent($event, '[compressed]=', true, true); compressed = !compressed"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Read only"  (value)="controlEvent($event, '[readOnly]=', true, false); readOnly = !readOnly" [disabled]="disabled"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled state"  (value)="controlEvent($event, '[setDisabled]=', true, false); disabled = !disabled" [disabled]="readOnly"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Error / warning state'" 
                (value)="customMessToggle($event); setCustomMessage = !setCustomMessage" 
                [disabled]="required || readOnly || disabled">
            </kn-toggle>
        </div>
        <div class="item-row" *ngIf="addCustomMessage">
            <kn-radio-button [id]="'errorRadio'" [defaultValue]="messageType" [(ngModel)]="messageType" [value]="'radioError'" [name]="'custom-message'" [label]="'Error'" (onSelect)="customMessage($event);" [disabled]="customError"></kn-radio-button>
        </div>
        <div class="item-row" *ngIf="addCustomMessage">
            <kn-radio-button [id]="'warningRadio'" [defaultValue]="messageType" [(ngModel)]="messageType" [value]="'radioWarning'" [name]="'custom-message'" [label]="'Warning'" (onSelect)="customMessage($event)" [disabled]="customWarning"></kn-radio-button>
        </div>
        <div *ngIf="addCustomMessage" class="action-input">
            <kn-textbox 
                [label]="'Custom message'" 
                [value]="customMess" 
                [setPlaceholder]="'Provide a custom message'"
                [setDisabled]="!addCustomMessage && !inputRequired && !emailValidator || messageType == ''" 
                (onValueChange)="message($event)" 
                [clearIcon]="true">
            </kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Required'" 
                (value)="controlEvent($event, '[setRequired]=', true, true); customMessage($event); required = !required" 
                [disabled]="setCustomMessage || readOnly || disabled || optional">
            </kn-toggle>
        </div>
        <div class="action-input" *ngIf="required">
            <kn-textbox [label]="'Custom message'" [value]="customMess" (onValueChange)="message($event, 1)" [clearIcon]="true"></kn-textbox>
        </div>
        
        <div class="item-row">
            <kn-toggle label="Value change event"  (value)="controlEvent($event, '(onSelect)=', 'valueChange($event)', false); onValueChange = !onValueChange"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Validation event"  (value)="controlEvent($event, '(onValidate)=', 'dropdownField = $event', false); onValidate = !onValidate"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropdownId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"exampleDropdownList"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">items</div>
                <div class="item"><i>object, array</i></div>
                <div class="item"><i>{{'{ }'}}, {{'[]'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">fields</div>
                <div class="item"><i>object</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">template</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">placeholder</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Country"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">label</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Example dropdown"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">value</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"United States"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">name</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"dropdown"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">width</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"250px"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropdownCustomWidth</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"250px", "max-content"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropdownCustomHeight</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"250px", "max-content"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropdownRightAligned</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">optional</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">compressed</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customError</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customWarning</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customMess</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Custom message"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setDisabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setReadOnly</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setRequired</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onSelect</div>
                <div class="item"><i>object</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">Directive</div>
                <div class="item">[(ngModel)]</div>
                <div class="item"><i>two-way binding</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <dropdown-usage></dropdown-usage>
    </div>
    <div designSpec>
        <dropdown-specs></dropdown-specs>
    </div>
    <div accessibility>
        <dropdown-accessibility></dropdown-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">Feburary 9, 2024</div>
                <div class="item">1.0.10</div>
                <div class="item">
                    <ul>
                        <li>bug fix- fixed issue with the error message styling when going to a second line</li>
                        <li>bug fix - fixed dropdown item active hover class, changed color from ink to primary</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">November 30, 2023</div>
                <div class="item">1.0.9</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed issue with updating the items object after the web version has rendered.</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">November 14, 2023</div>
                <div class="item">1.0.8</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed loading items in the dropdown issue for web version</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">October 6, 2023</div>
                <div class="item">1.0.7</div>
                <div class="item">
                    <ul>
                        <li>bug fix - added a slight delay on render for the web version. This will make sure everything has rendered completely</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.6</div>
                <div class="item">
                    <ul>
                        <li>Added addition unit testing after component was published</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.5</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.4</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">June 15, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>Usage Guidlines & Design Specifications - Added case for spinner displayed within dropdown menu</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">May 17, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>bug fix - added elevation to the dropdown menu to prevent being hidden by parent elements </li>
                        <li>bug fix - fixed issue with dropdown focus state when clicking the caret</li>
                        <li>added top positioning of dropdown list (i.e. open upward) if control is positioned close to the bottom of the UI layout</li>
                        <li>Updated the focused trailing flat icon button color styling from #205D86 (Primary) to #434C54 (Ink, Light), indicating to the user that the user can take action to close the menu.</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">April 5, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>Added disabled state for list items</li>
                        <li>Updated dropdown item row height (from 45px to 41px) to standardize all contextual menus</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the dropdown component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

