<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text"><span class="kn-bold">Single-Select Dropdown</span> menu consists of a set of items displayed in rows. Each item is represented as a text label. Use this component when you can only select one option from a list. Selecting an option from the menu closes it.</div>
            </section>
 
        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Enabled</span></div>
            <div class="text"><span class="kn-bold">2.	Enabled with optional label</span></div>
            <div class="text"><span class="kn-bold">3.	Enabled with placeholder text</span></div>
            <div class="text"><span class="kn-bold">4.	Enabled with menu option</span></div>
            <div class="text"><span class="kn-bold">5.	Enabled with truncated menu option</span></div>
            <div class="text"><span class="kn-bold">6.	Disabled with placeholder text</span></div>
            <div class="text"><span class="kn-bold">7.	Disabled with menu option</span></div>
            <div class="text"><span class="kn-bold">8.	Read-only</span></div>
            <div class="text"><span class="kn-bold">9.	Error</span></div>
            <div class="text"><span class="kn-bold">10.	Warning</span></div>
            <div class="text"><span class="kn-bold">11.	Focused</span></div>
            <div class="text"><span class="kn-bold">12.	Focused with selected menu option</span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_02.png">

        <section>
            <div class="kn-subtitle-16">Text field</div> 
            <div class="text">Persists when the dropdown is open or closed.</div>       
        </section>    

        <section>
            <div class="text"><span class="kn-bold">A.  Container: </span> Containers improve the discoverability of text fields by creating contrast between the text field and surrounding content.</div>
            <div class="text"><span class="kn-bold">B.	Label text: </span> Label text is used to inform users as to what information is requested for a text field.</div>
            <div class="text"><span class="kn-bold">C.	Placeholder text (optional): </span> Placeholder text conveys additional guidance about the input field. The placeholder text is displayed inside the text field.</div>
            <div class="text"><span class="kn-bold">D.	Dropdown trailing icon </span></div>
            <div class="text"><span class="kn-bold">E.	Optional label </span></div>
            <div class="text"><span class="kn-bold">F.	Value </span></div>
            <div class="text"><span class="kn-bold">G.	Error/warning icon: </span> Displaying an error/warning icon in association with text makes error/warning states clear for colorblind users.</div>
            <div class="text"><span class="kn-bold">H.	Error/warning text: </span> When an error or warning occurs, error/warning messages are displayed below the text box container.</div>
            <div class="text"><span class="kn-bold">I.	Information icon </span></div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_03.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Menu</div>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">A.  Dropdown trailing icon hover </span></div>
            <div class="text"><span class="kn-bold">B.	Focused dropdown trailing icon </span></div>
            <div class="text"><span class="kn-bold">C.	Menu option: </span>A choice for the user, shown amongst other choices in a menu.</div>
            <div class="text"><span class="kn-bold">D.	Menu option hover </span></div>
            <div class="text"><span class="kn-bold">E.	Selected value </span></div>
            <div class="text"><span class="kn-bold">F.	Selected menu option: </span>When a default menu option is displayed in the text field, the corresponding menu option is displayed in blue.</div>
            <div class="text"><span class="kn-bold">G.	Selected menu option hover </span></div>
            <div class="text"><span class="kn-bold">H.	Disabled menu option: </span>Display actions as disabled when they can only be used sometimes, under certain conditions.</div>
            <div class="text"><span class="kn-bold">I.	Null value menu option: </span>Place options that represent “All” or “None” at the beginning of the list, regardless of the sort order.</div>
            <div class="text"><span class="kn-bold">J.	Selected menu option with loading menu </span></div>
        </section>


        <div class="section-title">Assistive elements</div>

        <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_04.png">

        <section>
            <div class="kn-subtitle-16">1. Labels</div>

            <div class="text">Use the label to explain the purpose of the dropdown field and type of value to be entered by the user. Every dropdown text box should have a label. A dropdown text box without a label is ambiguous and not accessible.</div>
        </section>


        <section>
            <div class="kn-subtitle-16">2. Required or optional</div>

            <div class="text">All dropdown text boxes are required by default and do not require displaying a required attribute in the label. However, when displaying an optional field, they are denoted with text added to the end of the label — “(optional)” —. In the case of using an inline or text box without a label, the “(optional)“ should be denoted within the placeholder text.</div>
        </section>


        <section>
            <div class="kn-subtitle-16">3. Placeholder text</div>

            <div class="text">If the value needs to be provided in a specific format (for e.g. name@domain.com), use placeholder text inside the text box control to describe how the information should be entered, not what the field represents.</div>

            <section>
                <div class="text">Placeholder text should not be editable and should disappear once the user focuses on the text box and begins to type. If the value entered by the user is removed and the text box loses input focus, the hint text should be restored.</div>
            </section>
        </section>


        <section>
            <div class="kn-subtitle-16">4. Error/warning text</div>

            <div class="text">A dropdown text box can be marked as having an error or warning to show that a value needs to be entered to move forward or that a value entered is invalid.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Text box</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_05.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Text box height</span></div>

                <div class="text">Text boxes come in two different sizes: default and compressed. The compressed size is reserved for compressed grid rows where users can scan and take action on large amounts of information. When displaying the compressed size, the label text, character counter and error/warning icon and text is not displayed. Placeholder text is required to help inform users on information that is requested.</div>
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">1.  Default text box: </span>40px height, 14px text</div>
                <div class="text"><span class="kn-bold">2.  Compressed text box: </span>30px height, 12px text</div>
            </section>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_06.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Display width</span></div>

                <div class="text">In general, the display width of the dropdown text box should reflect the approximate maximum character length for what would be considered a standard representative value entered by the user. When the entered value exceeds the display width of the text box, it should display with truncation (ellipses).</div>
            </section>
            
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_07.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Modal</span></div>
                <div class="text">The standard width of text boxes displayed in the body of modal two-column input fields are 264px, while single column width spans 576px.</div>
            </section>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Menu trigger</span></div>

            <div class="text">Allow users to click anywhere in the text field to open the menu, rather than only the dropdown trailing icon.</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_08.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Default dropdown value</span></div>

                <div class="text">Menu options can be marked as default indicating to the user that a selection has been pre-selected. The dropdown text box is displayed with the selected option and denoted as default with — “(Default)” — at the end of the label.</div>
            </section>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Rounded corners</span></div>

            <div class="text">Text box containers are displayed with a 4px rounded corner.</div>
        </section>


        <section>
            <div class="kn-subtitle-16">Menu</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_09.png">
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">Menu height</span></div>

                <div class="text">The single-select dropdown is meant to make large lists easier to search. If you have fewer than three items, use radio buttons.</div>
            </section>

            <section>
                <div class="text">The display height (depth) of the dropdown menu is suggested not to exceed four menu options. When more than four options are available, display a scrollbar with a search text box.</div>
            </section>

            <section>
                <div class="text">The dropdown menu height should never extend beyond the current screen view (i.e., below the fold).</div>
            </section>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_10.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Menu width</span></div>

                <div class="text">The display width of the dropdown menu may exceed that of the dropdown text box it is associated with to accommodate the length of the longest option value.

                    If the menu option value length exceeds the width of the select field, it is automatically truncated in the display once selected and the menu closes.
                    </div>
            </section>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_11.png">
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">Menu options</span></div>
 
                <ul class="bullets">
                    <li>Keep menu items short and concise. If text wrapping becomes a frequent concern, consider revising the text or use alternative UI patterns that will give your content more space.</li>
                    <li>Present dropdown menu options in sentence case. If the dropdown list contains custom names, such as custodians if are entered in title case will display in title case.</li>
                </ul>
            </section>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Ordering of menu options</span></div>

            <div class="text">Sort menu items in logical order, such as placing most common options first, grouping highly related options together, listing items based on size, or using alphabetical order for lists with 12 or more items to make items easier to find (for example, language or country lists). Place options that represent “All” or “None” at the beginning of the list, regardless of the sort order. Select the most relevant or most common choice on the list as a default if it is appropriate for the context. For example, for a language list, you can set the default value to the language in the user’s browser.</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_12.png">
            </section>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Menu positioning</span></div>

            <div class="text"><span class="kn-bold">1.	</span>The default dropdown menu is to be positioned below and align (left-to-right) with the bottom edge of the dropdown text box.</div>
        </section>


        <section>
            <div class="text"><span class="kn-bold">2.	</span>If the dropdown text box is displayed in the far-right side of the UI layout and there is no room to display the dropdown menu to the right of it, the menu will automatically open aligned to the left edge of the dropdown text box instead.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>            
            <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_13.png">

            <section>
                <div class="kn-subtitle-16">Too few options</div>

                <div class="text">When a dropdown menu has less than three options, it lacks an adequate context of up-front information. The user is required to select to see the available options. In this case you should use radio buttons wherein the user is able to immediately scan the number and content of available options without selecting (or typing) anything to reveal this information.</div>
            </section>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_14.png">

            <section>
                <div class="kn-subtitle-16">Too many options</div>
           
                <div class="text">Displaying more than four menu options in a dropdown menu is not recommended. The menu becomes more difficult to scan and navigate.</div>
            </section>
        </section>


        <section>           
            <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_15.png">

            <section>
                <div class="kn-subtitle-16">Performing commands</div>

                <div class="text">Do not use a change of a dropdown list to perform commands, display other windows, or dynamically display other controls related to the selected control as screen readers cannot detect such events.</div>
            </section>
        </section>


        <section>            
            <img class="kn-responsive-img" src="assets/usage guidelines/singleselect-dropdown/KrakenDSM_Components_DropdownSingleSelect_16.png">

            <section>
                <div class="kn-subtitle-16">Write error text that shows a solution</div>

                <div class="text">Write error messaging in a human-centered way by guiding a user and showing them a solution. Don’t display ambiguous error messages.</div>
            </section>
        </section>
    </div>
</div>