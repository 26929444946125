import { NgModule } from '@angular/core';
import { KrakenWebTooltipComponent } from './kraken-web-tooltip.component';
import { KrakenTooltipModule } from '@kraken-ui/kraken-tooltip';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    KrakenWebTooltipComponent
  ],
  imports: [
    CommonModule,
    KrakenTooltipModule
  ],
  exports: [
    KrakenWebTooltipComponent
  ]
})
export class KrakenWebTooltipModule { }
