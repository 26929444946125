import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 
import { CookieService } from 'ngx-cookie-service';

import { KrakenThemeModule } from 'kraken-theme';
import { KrakenAutocompleteModule } from 'kraken-autocomplete';
import { KrakenButtonModule } from 'kraken-button';
import { KrakenTextboxModule } from 'kraken-textbox';
import { KrakenDropdownModule } from 'kraken-dropdown';
import { KrakenMastheadModule} from 'kraken-masthead';
import { ButtonsComponent } from './web-components/buttons/buttons.component';
import { KrakenAccordionModule } from 'kraken-accordion';
import { SideNavComponent } from './side-nav/side-nav.component';
import { HeaderComponent } from './header/header.component';
import { CanvasComponent } from './canvas/canvas.component';
import { KrakenTabsModule} from 'kraken-tabs';
import { KrakenCheckboxModule} from 'kraken-checkbox';
import { TabsComponent } from './web-components/tabs/tabs.component';
import { KrakenToggleModule } from 'kraken-toggle';
import { KrakenModalModule } from 'kraken-modal';
import { DocumentationTemplateComponent } from './canvas/documentation-template/documentation-template.component';
import { KrakenSplitButtonModule } from 'kraken-split-button';
import { TextBoxComponent } from './web-components/text-box/text-box.component';
import { IconCatalogComponent } from './style-guide/iconography/icon-catalog/icon-catalog.component';
import { ColorPalettesComponent } from './style-guide/palettes/color-palettes/color-palettes.component';
import { ButtonUsageComponent } from './web-components/buttons/button-usage/button-usage.component';
import { ButtonSpecsComponent } from './web-components/buttons/button-specs/button-specs.component';
import { SplitButtonComponent } from './web-components/split-button/split-button.component';
import { MenuButtonComponent } from './web-components/menu-button/menu-button.component';
import { IconButtonComponent } from './web-components/icon-button/icon-button.component';
import { KrakenMenuButtonModule } from 'kraken-menu-button';
import { KrakenIconButtonModule } from 'kraken-icon-button';
import { IconButtonSpecsComponent } from './web-components/icon-button/icon-button-specs/icon-button-specs.component';
import { IconButtonUsageComponent } from './web-components/icon-button/icon-button-usage/icon-button-usage.component';
import { MenuButtonUsageComponent } from './web-components/menu-button/menu-button-usage/menu-button-usage.component';
import { MenuButtonSpecsComponent } from './web-components/menu-button/menu-button-specs/menu-button-specs.component';
import { SplitButtonSpecsComponent } from './web-components/split-button/split-button-specs/split-button-specs.component';
import { SplitButtonUsageComponent } from './web-components/split-button/split-button-usage/split-button-usage.component';
import { TextAreaComponent } from './web-components/text-area/text-area.component';
import { TextAreaSpecsComponent } from './web-components/text-area/text-area-specs/text-area-specs.component';
import { TextAreaUsageComponent } from './web-components/text-area/text-area-usage/text-area-usage.component';
import { KrakenTextareaModule} from 'kraken-textarea';
import { KrakenRadioButtonModule } from 'kraken-radio-button';
import { TextBoxSpecsComponent } from './web-components/text-box/text-box-specs/text-box-specs.component';
import { TextBoxUsageComponent } from './web-components/text-box/text-box-usage/text-box-usage.component';
import { MultiSelectComponent } from './web-components/multi-select/multi-select.component';
import { KrakenMultiSelectModule } from 'kraken-multi-select';
import { KrakenTooltipModule } from '@kraken-ui/kraken-tooltip';
import { MultiSelectUsageComponent } from './web-components/multi-select/multi-select-usage/multi-select-usage.component';
import { MultiSelectSpecsComponent } from './web-components/multi-select/multi-select-specs/multi-select-specs.component';
import { RadioButtonComponent } from './web-components/radio-button/radio-button.component';
import { RadioButtonSpecsComponent } from './web-components/radio-button/radio-button-specs/radio-button-specs.component';
import { RadioButtonUsageComponent } from './web-components/radio-button/radio-button-usage/radio-button-usage.component';
import { CheckboxComponent } from './web-components/checkbox/checkbox.component';
import { CheckboxSpecsComponent } from './web-components/checkbox/checkbox-specs/checkbox-specs.component';
import { CheckboxUsageComponent } from './web-components/checkbox/checkbox-usage/checkbox-usage.component';
import { ToolTipComponent } from './web-components/tool-tip/tool-tip.component';
import { ToolTipSpecsComponent } from './web-components/tool-tip/tool-tip-specs/tool-tip-specs.component';
import { ToolTipUsageComponent } from './web-components/tool-tip/tool-tip-usage/tool-tip-usage.component';
import { DropdownComponent } from './web-components/dropdown/dropdown.component';
import { DropdownSpecsComponent } from './web-components/dropdown/dropdown-specs/dropdown-specs.component';
import { DropdownUsageComponent } from './web-components/dropdown/dropdown-usage/dropdown-usage.component';
import { SearchboxComponent } from './web-components/searchbox/searchbox.component';
import { SearchboxSpecsComponent } from './web-components/searchbox/searchbox-specs/searchbox-specs.component';
import { SearchboxUsageComponent } from './web-components/searchbox/searchbox-usage/searchbox-usage.component';
import { ToggleComponent } from './web-components/toggle/toggle.component';
import { ToggleSpecsComponent } from './web-components/toggle/toggle-specs/toggle-specs.component';
import { ToggleUsageComponent } from './web-components/toggle/toggle-usage/toggle-usage.component';
import { DesignPrinciplesComponent } from './getting-started/designers/design-principles/design-principles.component';
import { LayoutBackgroundComponent } from './style-guide/palettes/layout-background/layout-background.component';
import { TypeComponent } from './style-guide/palettes/type/type.component';
import { IconsComponent } from './style-guide/palettes/icons/icons.component';
import { NotificationsComponent } from './style-guide/palettes/notifications/notifications.component';
import { HeadingSubheadingComponent } from './style-guide/typography/heading-subheading/heading-subheading.component';
import { BodyTextComponent } from './style-guide/typography/body-text/body-text.component';
import { FormLabelsComponent } from './style-guide/typography/form-labels/form-labels.component';
import { CapitalizationComponent } from './style-guide/typography/capitalization/capitalization.component';
import { FontsComponent } from './style-guide/typography/fonts/fonts.component';
import { GridLayoutComponent } from './style-guide/grid-layout/grid-layout.component';
import { SpacingComponent } from './style-guide/spacing/spacing.component';
import { ElevationComponent } from './style-guide/elevation/elevation.component';
import { LogoComponent } from './style-guide/logo/logo.component';
import { MotionSpeedComponent } from './style-guide/motion/motion-speed/motion-speed.component';
import { MotionEasingComponent } from './style-guide/motion/motion-easing/motion-easing.component';
import { MotionEffectsComponent } from './style-guide/motion/motion-effects/motion-effects.component';
import { ContentUsageComponent } from './style-guide/content/content-usage/content-usage.component';
import { ContentFormattingComponent } from './style-guide/content/content-formatting/content-formatting.component';
import { ContentNotificationsComponent } from './style-guide/content/content-notifications/content-notifications.component';
import { ContentValidationErrorsComponent } from './style-guide/content/content-validation-errors/content-validation-errors.component';
import { ContentWordUseComponent } from './style-guide/content/content-word-use/content-word-use.component';
import { ContactSubmitComponent } from './support/contact-submit/contact-submit.component';
import { ReleaseNotesComponent } from './support/release-notes/release-notes.component';
import { WhyDsmComponent } from './about/why-dsm/why-dsm.component';
import { EngagingUxComponent } from './about/engaging-ux/engaging-ux.component';
import { ReviewTestingComponent } from './about/review-testing/review-testing.component';
import { AccessibilityComponent } from './about/accessibility/accessibility.component';
import { IntegrationsComponent } from './about/integrations/integrations.component';
import { UiKitComponent } from './getting-started/designers/ui-kit/ui-kit.component';
import { IconStyleComponent } from './style-guide/iconography/icon-style/icon-style.component';
import { GridComponent } from './web-components/grid/grid.component';
import { GridSpecsComponent } from './web-components/grid/grid-specs/grid-specs.component';
import { GridUsageComponent } from './web-components/grid/grid-usage/grid-usage.component';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { HomeComponent } from './home/home.component';
import { CheckboxAccessibilityComponent } from './web-components/checkbox/checkbox-accessibility/checkbox-accessibility.component';
import { DropdownAccessibilityComponent } from './web-components/dropdown/dropdown-accessibility/dropdown-accessibility.component';
import { IconButtonAccessibilityComponent } from './web-components/icon-button/icon-button-accessibility/icon-button-accessibility.component';
import { MenuButtonAccessibilityComponent } from './web-components/menu-button/menu-button-accessibility/menu-button-accessibility.component';
import { MultiSelectAccessibilityComponent } from './web-components/multi-select/multi-select-accessibility/multi-select-accessibility.component';
import { RadioButtonAccessibilityComponent } from './web-components/radio-button/radio-button-accessibility/radio-button-accessibility.component';
import { SearchboxAccessibilityComponent } from './web-components/searchbox/searchbox-accessibility/searchbox-accessibility.component';
import { SplitButtonAccessibilityComponent } from './web-components/split-button/split-button-accessibility/split-button-accessibility.component';
import { TabsAccessibilityComponent } from './web-components/tabs/tabs-accessibility/tabs-accessibility.component';
import { TextAreaAccessibilityComponent } from './web-components/text-area/text-area-accessibility/text-area-accessibility.component';
import { TextBoxAccessibilityComponent } from './web-components/text-box/text-box-accessibility/text-box-accessibility.component';
import { ToggleAccessibilityComponent } from './web-components/toggle/toggle-accessibility/toggle-accessibility.component';
import { ToolTipAccessibilityComponent } from './web-components/tool-tip/tool-tip-accessibility/tool-tip-accessibility.component';
import { GridAccessibilityComponent } from './web-components/grid/grid-accessibility/grid-accessibility.component';
import { ButtonAccessibilityComponent } from './web-components/buttons/button-accessibility/button-accessibility.component';
import { DesignResourcesComponent } from './getting-started/designers/design-resources/design-resources.component';
import { UIFrameworkComponent } from './getting-started/developers/ui-framework/ui-framework.component';
import { DevelopmentResourcesComponent } from './getting-started/developers/development-resources/development-resources.component';
import { ThemingComponent } from './getting-started/developers/theming/theming.component';
import { KrakenWebTooltipModule } from 'kraken-web-tooltip';
import { KrakenIconMenuModule } from 'kraken-icon-menu';
import { IconMenuComponent } from './web-components/icon-menu/icon-menu.component';
import { TabsSpecsComponent } from './web-components/tabs/tabs-specs/tabs-specs.component';
import { TabsUsageComponent } from './web-components/tabs/tabs-usage/tabs-usage.component';
import { AccordionComponent } from './web-components/accordion/accordion.component';
import { AccordionAccessibilityComponent } from './web-components/accordion/accordion-accessibility/accordion-accessibility.component';
import { AccordionSpecsComponent } from './web-components/accordion/accordion-specs/accordion-specs.component';
import { AccordionUsageComponent } from './web-components/accordion/accordion-usage/accordion-usage.component';
import { RoadmapComponent } from './support/roadmap/roadmap.component';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ModalComponent } from './web-components/modal/modal.component';
import { ModalAccessibilityComponent } from './web-components/modal/modal-accessibility/modal-accessibility.component';
import { ModalSpecsComponent } from './web-components/modal/modal-specs/modal-specs.component';
import { ModalUsageComponent } from './web-components/modal/modal-usage/modal-usage.component';
import { KrakenSpinnerModule } from 'kraken-spinner';
import { SpinnerComponent } from './web-components/spinner/spinner.component';
import { SpinnerAccessibilityComponent } from './web-components/spinner/spinner-accessibility/spinner-accessibility.component';
import { SpinnerSpecsComponent } from './web-components/spinner/spinner-specs/spinner-specs.component';
import { SpinnerUsageComponent } from './web-components/spinner/spinner-usage/spinner-usage.component';
import { MastheadComponent } from './web-components/masthead/masthead.component';
import { MastheadAccessibilityComponent } from './web-components/masthead/masthead-accessibility/masthead-accessibility.component';
import { MastheadSpecsComponent } from './web-components/masthead/masthead-specs/masthead-specs.component';
import { MastheadUsageComponent } from './web-components/masthead/masthead-usage/masthead-usage.component';
import { KrakenFooterModule } from 'kraken-footer';
import { FooterComponent } from './web-components/footer/footer.component';
import { FooterSpecsComponent } from './web-components/footer/footer-specs/footer-specs.component';
import { FooterUsageComponent } from './web-components/footer/footer-usage/footer-usage.component';
import { FooterAccessibilityComponent } from './web-components/footer/footer-accessibility/footer-accessibility.component';
import { ContextualMenuComponent } from './web-components/contextual-menu/contextual-menu.component';
import { ContextualMenuAccessibilityComponent } from './web-components/contextual-menu/contextual-menu-accessibility/contextual-menu-accessibility.component';
import { ContextualMenuSpecsComponent } from './web-components/contextual-menu/contextual-menu-specs/contextual-menu-specs.component';
import { ContextualMenuUsageComponent } from './web-components/contextual-menu/contextual-menu-usage/contextual-menu-usage.component';
import { KrakenContextualMenuModule } from 'kraken-contextual-menu';
import { KrakenPanelModule } from 'kraken-panel';
import { PanelComponent } from './web-components/panel/panel.component';
import { PanelAccessibilityComponent } from './web-components/panel/panel-accessibility/panel-accessibility.component';
import { PanelSpecsComponent } from './web-components/panel/panel-specs/panel-specs.component';
import { PanelUsageComponent } from './web-components/panel/panel-usage/panel-usage.component';
import { ProgressIndicatorComponent } from './web-components/progress-indicator/progress-indicator.component';
import { ProgressIndicatorSpecsComponent } from './web-components/progress-indicator/progress-indicator-specs/progress-indicator-specs.component';
import { ProgressIndicatorAccessibilityComponent } from './web-components/progress-indicator/progress-indicator-accessibility/progress-indicator-accessibility.component';
import { ProgressIndicatorUsageComponent } from './web-components/progress-indicator/progress-indicator-usage/progress-indicator-usage.component';
import { KrakenProgressIndicatorModule } from 'kraken-progress-indicator';
import { ToastComponent } from './web-components/toast/toast.component';
import { ToastAccessibilityComponent } from './web-components/toast/toast-accessibility/toast-accessibility.component';
import { ToastSpecsComponent } from './web-components/toast/toast-specs/toast-specs.component';
import { ToastUsageComponent } from './web-components/toast/toast-usage/toast-usage.component';
import { KrakenToastModule } from 'kraken-toast';
import { ChipComponent } from './web-components/chip/chip.component';
import { KrakenChipModule } from 'kraken-chip';
import { ChipAccessibilityComponent } from './web-components/chip/chip-accessibility/chip-accessibility.component';
import { ChipSpecsComponent } from './web-components/chip/chip-specs/chip-specs.component';
import { ChipUsageComponent } from './web-components/chip/chip-usage/chip-usage.component';
import { SliderComponent } from './web-components/slider/slider.component';
import { SliderAccessibilityComponent } from './web-components/slider/slider-accessibility/slider-accessibility.component';
import { SliderSpecsComponent } from './web-components/slider/slider-specs/slider-specs.component';
import { SliderUsageComponent } from './web-components/slider/slider-usage/slider-usage.component';
import { KrakenSliderModule } from 'kraken-slider';
import { ButtonGroupComponent } from './web-components/button-group/button-group.component';
import { ButtonGroupAccessibilityComponent } from './web-components/button-group/button-group-accessibility/button-group-accessibility.component';
import { ButtonGroupSpecsComponent } from './web-components/button-group/button-group-specs/button-group-specs.component';
import { ButtonGroupUsageComponent } from './web-components/button-group/button-group-usage/button-group-usage.component';
import { KrakenButtonGroupModule } from 'kraken-button-group';
import { SpinBoxComponent } from './web-components/spin-box/spin-box.component';
import { SpinBoxAccessibilityComponent } from './web-components/spin-box/spin-box-accessibility/spin-box-accessibility.component';
import { SpinBoxSpecsComponent } from './web-components/spin-box/spin-box-specs/spin-box-specs.component';
import { SpinBoxUsageComponent } from './web-components/spin-box/spin-box-usage/spin-box-usage.component';
import { KrakenSpinBoxModule } from 'kraken-spin-box';
import { DateRangePickerComponent } from './web-components/date-range-picker/date-range-picker.component';
import { DateRangePickerAccessibilityComponent } from './web-components/date-range-picker/date-range-picker-accessibility/date-range-picker-accessibility.component';
import { DateRangePickerSpecsComponent } from './web-components/date-range-picker/date-range-picker-specs/date-range-picker-specs.component';
import { DateRangePickerUsageComponent } from './web-components/date-range-picker/date-range-picker-usage/date-range-picker-usage.component';
import { KrakenDateRangePickerModule } from 'kraken-date-range-picker';
import { DatePickerComponent } from './web-components/date-picker/date-picker.component';
import { DatePickerAccessibilityComponent } from './web-components/date-picker/date-picker-accessibility/date-picker-accessibility.component';
import { DatePickerUsageComponent } from './web-components/date-picker/date-picker-usage/date-picker-usage.component';
import { DatePickerSpecsComponent } from './web-components/date-picker/date-picker-specs/date-picker-specs.component';
import { KrakenDatePickerModule } from 'kraken-date-picker';
import { SpreadsheetComponent } from './web-components/spreadsheet/spreadsheet.component';
import { SpreadsheetAccessibilityComponent } from './web-components/spreadsheet/spreadsheet-accessibility/spreadsheet-accessibility.component';
import { SpreadsheetUsageComponent } from './web-components/spreadsheet/spreadsheet-usage/spreadsheet-usage.component';
import { SpreadsheetSpecsComponent } from './web-components/spreadsheet/spreadsheet-specs/spreadsheet-specs.component';
import { SpreadsheetAllModule } from '@syncfusion/ej2-angular-spreadsheet';
import { AnalyzerComponent } from './analyzer/analyzer.component';
import { TileComponent } from './style-guide/patterns/tile/tile.component';
import { LegendComponent } from './legend/legend/legend.component';
import { LoginComponent } from './login/login.component';

@NgModule({
  declarations: [
    AppComponent,
    ButtonsComponent,
    SideNavComponent,
    HeaderComponent,
    CanvasComponent,
    TabsComponent,
    DocumentationTemplateComponent,
    TextBoxComponent,
    IconCatalogComponent,
    ColorPalettesComponent,
    ButtonAccessibilityComponent,
    ButtonUsageComponent,
    ButtonSpecsComponent,
    SplitButtonComponent,
    MenuButtonComponent,
    IconButtonComponent,
    IconButtonSpecsComponent,
    IconButtonUsageComponent,
    MenuButtonUsageComponent,
    MenuButtonSpecsComponent,
    SplitButtonSpecsComponent,
    SplitButtonUsageComponent,
    TextAreaComponent,
    TextAreaSpecsComponent,
    TextAreaUsageComponent,
    TextBoxSpecsComponent,
    TextBoxUsageComponent,
    MultiSelectComponent,
    MultiSelectUsageComponent,
    MultiSelectSpecsComponent,
    RadioButtonComponent,
    RadioButtonSpecsComponent,
    RadioButtonUsageComponent,
    CheckboxComponent,
    CheckboxSpecsComponent,
    CheckboxUsageComponent,
    ToolTipComponent,
    ToolTipSpecsComponent,
    ToolTipUsageComponent,
    DropdownComponent,
    DropdownSpecsComponent,
    DropdownUsageComponent,
    SearchboxComponent,
    SearchboxSpecsComponent,
    SearchboxUsageComponent,
    ToggleComponent,
    ToggleSpecsComponent,
    ToggleUsageComponent,
    DesignPrinciplesComponent,
    LayoutBackgroundComponent,
    TypeComponent,
    IconsComponent,
    NotificationsComponent,
    HeadingSubheadingComponent,
    BodyTextComponent,
    FormLabelsComponent,
    CapitalizationComponent,
    FontsComponent,
    GridLayoutComponent,
    SpacingComponent,
    ElevationComponent,
    LogoComponent,
    MotionSpeedComponent,
    MotionEasingComponent,
    MotionEffectsComponent,
    ContentUsageComponent,
    ContentFormattingComponent,
    ContentNotificationsComponent,
    ContentValidationErrorsComponent,
    ContentWordUseComponent,
    ContactSubmitComponent,
    ReleaseNotesComponent,
    WhyDsmComponent,
    EngagingUxComponent,
    ReviewTestingComponent,
    AccessibilityComponent,
    IntegrationsComponent,
    UiKitComponent,
    IconStyleComponent,
    GridComponent,
    GridSpecsComponent,
    GridUsageComponent,
    HomeComponent,
    CheckboxAccessibilityComponent,
    DropdownAccessibilityComponent,
    IconButtonAccessibilityComponent,
    MenuButtonAccessibilityComponent,
    MultiSelectAccessibilityComponent,
    RadioButtonAccessibilityComponent,
    SearchboxAccessibilityComponent,
    SplitButtonAccessibilityComponent,
    TabsAccessibilityComponent,
    TextAreaAccessibilityComponent,
    TextBoxAccessibilityComponent,
    ToggleAccessibilityComponent,
    ToolTipAccessibilityComponent,
    GridAccessibilityComponent,
    DesignResourcesComponent,
    UIFrameworkComponent,
    DevelopmentResourcesComponent,
    ThemingComponent,
    IconMenuComponent,
    TabsSpecsComponent,
    TabsUsageComponent,
    AccordionComponent,
    AccordionAccessibilityComponent,
    AccordionSpecsComponent,
    AccordionUsageComponent,
    RoadmapComponent,
    ModalComponent,
    ModalAccessibilityComponent,
    ModalSpecsComponent,
    ModalUsageComponent,
    SpinnerComponent,
    SpinnerAccessibilityComponent,
    SpinnerSpecsComponent,
    SpinnerUsageComponent,
    MastheadComponent,
    MastheadAccessibilityComponent,
    MastheadSpecsComponent,
    MastheadUsageComponent,
    FooterComponent,
    FooterSpecsComponent,
    FooterUsageComponent,
    FooterAccessibilityComponent,
    ContextualMenuComponent,
    ContextualMenuAccessibilityComponent,
    ContextualMenuSpecsComponent,
    ContextualMenuUsageComponent,
    PanelAccessibilityComponent,
    PanelComponent,
    PanelSpecsComponent,
    PanelUsageComponent,
    ProgressIndicatorComponent,
    ProgressIndicatorSpecsComponent,
    ProgressIndicatorAccessibilityComponent,
    ProgressIndicatorUsageComponent,
    ToastComponent,
    ToastAccessibilityComponent,
    ToastSpecsComponent,
    ToastUsageComponent,
    ChipComponent,
    ChipAccessibilityComponent,
    ChipSpecsComponent,
    ChipUsageComponent,
    SliderComponent,
    SliderAccessibilityComponent,
    SliderSpecsComponent,
    SliderUsageComponent,
    ButtonGroupComponent,
    ButtonGroupAccessibilityComponent,
    ButtonGroupSpecsComponent,
    ButtonGroupUsageComponent,
    SpinBoxComponent,
    SpinBoxAccessibilityComponent,
    SpinBoxSpecsComponent,
    SpinBoxUsageComponent,
    DateRangePickerComponent,
    DateRangePickerAccessibilityComponent,
    DateRangePickerSpecsComponent,
    DateRangePickerUsageComponent,
    DatePickerComponent,
    DatePickerAccessibilityComponent,
    DatePickerUsageComponent,
    DatePickerSpecsComponent,
    SpreadsheetComponent,
    SpreadsheetAccessibilityComponent,
    SpreadsheetUsageComponent,
    SpreadsheetSpecsComponent,
    AnalyzerComponent,
    TileComponent,
    LegendComponent,
    LoginComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    KrakenButtonModule,
    KrakenMastheadModule,
    KrakenAccordionModule,
    KrakenTabsModule,
    KrakenToggleModule,
    KrakenTextboxModule,
    FormsModule,
    KrakenDropdownModule,
    KrakenAutocompleteModule,
    KrakenCheckboxModule,
    KrakenModalModule,
    KrakenThemeModule,
    KrakenMenuButtonModule,
    KrakenIconButtonModule,
    KrakenSplitButtonModule,
    KrakenTextareaModule,
    KrakenRadioButtonModule,
    KrakenMultiSelectModule,
    KrakenTooltipModule,
    ReactiveFormsModule,
    KrakenWebTooltipModule,
    GridModule,
    KrakenIconMenuModule,
    NumericTextBoxModule,
    KrakenSpinnerModule,
    KrakenFooterModule,
    KrakenContextualMenuModule,
    KrakenPanelModule,
    KrakenProgressIndicatorModule,
    KrakenToastModule,
    KrakenChipModule,
    KrakenSliderModule,
    KrakenButtonGroupModule,
    KrakenSpinBoxModule,
    KrakenDateRangePickerModule,
    KrakenDatePickerModule,
    SpreadsheetAllModule,
    HttpClientModule
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  entryComponents: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor() {

  }
}
