<app-canvas 
    [js]="true"  
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component">
    <div title>Chip <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-chip 
            [label]="label"
            [alwaysShowTooltip]="hideTooltip"
            [dismissible]="dismissible"
            [customTooltip]="customTooltip"
            [fillColor]="fillCode"
            [compressed]="compressed"
            [disabled]="disabled"
        >
        </kn-chip>

        <!-- <div class="chip-container" >
            <ng-container *ngFor="let item of items; let i = index">
                <div class="chip">
                    <kn-chip 
                        [label]="item.label"
                        [alwaysShowTooltip]="item.hideTooltip"
                        [dismissible]="item.dismissible"
                        [customTooltip]="item.customTooltip"
                        (onDismiss)="close($event, i)"
                    >
                    </kn-chip>
                </div>
            </ng-container>
        </div> -->
        
       
    </div>
    <div angular>
        
    </div>
    <div typeScript>
       
    </div>
    <div javascript>
        <span class="code-comment">//You can bind to the onDismiss event as shown below</span>
        {{dismissWebEvent}}
        <div *ngIf="hideTooltip">
            <span class="code-comment">//You can always show the tool with the following code</span>
            {{alwaysShowTooltipWeb}}
        </div>
    </div>
    <div web></div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onDismiss():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">label:</span> "{{label}}"</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-input">
            <kn-textbox 
                [label]="'Chip Label'" 
                [value]="label" 
                (onValueChange)="labelText($event)" 
                [clearIcon]="true">
            </kn-textbox>
        </div>
        <div class="section-title">Chip type</div>
        <div class="item-row">
            <kn-radio-button 
                [radioId]="'standard'" 
                [value]="'standard'" 
                [(ngModel)]="filled" 
                [defaultValue]="filled" 
                (onSelect)="toggleFilledEvent($event)" 
                [name]="'chipType'" 
                [label]="'Outlined (Default)'">
            </kn-radio-button>
        </div>
        <div class="item-row">
            <kn-radio-button 
                [radioId]="'filledChip'" 
                [value]="'filled'" 
                [(ngModel)]="filled" 
                [defaultValue]="filled" 
                (onSelect)="toggleFilledEvent($event)" 
                [name]="'chipType'" 
                [label]="'Filled'">
            </kn-radio-button>

            <kn-dropdown 
                [template]="true" 
                [id]="fillColorDrpn" 
                *ngIf="filled == 'filled'" 
                [value]="curentFillColor" 
                [fields]="fields"
                [items]="fillColorObj"
                [placeholder]="'Select fill color'"
                (onSelect)="selectFillColorEvent($event)">
                <ng-template  #customTemplate let-data="data">
                    <div class="fill-color-container">
                        <span class="swatch" [ngStyle]="{'background-color': data.hexCode}"></span> 
                        <span class="icon-name">{{data.color}}</span>
                    </div>
                </ng-template>
            </kn-dropdown>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Dismissible'" 
                (value)="controlEvent($event, '[dismissible]=', true, true);" 
                [(ngModel)]="dismissible"
                name="dismissible"
                [disabled]="disabled">
            </kn-toggle>
        </div>
        <div class="item-row">
            <div class="toggle-info">
                <kn-toggle 
                    [label]="'Always show tooltip'" 
                    (value)="controlEvent($event, '[alwaysShowTooltip]=', true, false);" 
                    [(ngModel)]="hideTooltip"
                    name="showtooltip">
                </kn-toggle>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="{{alwaysShowTooltipText}}" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Tooltip text (HTML)'" 
                (value)="customTootipEvent($event)" 
                [(ngModel)]="showCustomTooltip"
                name="dismissible">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Compressed'" 
                (value)="controlEvent($event, '[compressed]=', true, true);" 
                [(ngModel)]="compressed"
                name="compressed">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Disabled state'" 
                (value)="controlEvent($event, '[disabled]=', true, true);disabledEvent($event)" 
                [(ngModel)]="disabled"
                name="compressed">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                label="Dismiss event" 
                [(ngModel)]="dismissEvent" 
                (value)="controlEvent($event, '(onDismiss)=', 'dismissFunction($event)', false);">
            </kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">componentId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"exampleChip"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">fillColor</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"primaryLight"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dismissible</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">hideTooltip</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">compressed</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customTooltip</div>
                <div class="item"><i>string</i></div>
                <div class="item">"&lt;div class="kn-bold">This is a tooltip&lt;/div>"</div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onDismiss</div>
                <div class="item"><i>event</i></div>
                <div class="item">{{'{}'}}</div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <chip-usage></chip-usage>
    </div>
    <div designSpec>
        <chip-specs></chip-specs>
    </div>
    <div accessibility>
        <chip-accessibility></chip-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">March 14, 2024</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>Fixed linting errors</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">October 6, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created Chip component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
</app-canvas>