<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Design Principles</div>
        <section>
        <div class="text">Regardless of what you are building with the <span class="kn-bold">Kraken DS</span>, your goals and outcomes should align with the following design principles:</div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Empowerment </div>
            <div class="text"><span class="kn-bold">(empathetic + user-centric)</span></div>
        </section>

        <section>
            Acknowledge the range and diversity of our product user personas and their unique needs. Allow for flexibility in how users view their data and complete specific tasks to align with and satisfy these needs. Give users the tools they need to understand data and make decisions easily, and without requiring external assistance. 
        </section>

        <section>
            <div class="kn-headline-24-med">Trust </div>
            <div class="text"><span class="kn-bold">(reliable + consistent)</span></div>
        </section>

        <section>
            Foster confidence in the product by conveying system information succinctly, accurately, and truthfully. Establish a uniform user experience that feels consistent and coherent throughout, even when changes occur. Instruct users on how to successfully handle these unexpected situations. Provide a smooth, complete, and highly professional experience that aligns the product user experience and visual design in a manner consistent with company brand attributes. 
        </section>


        <section>
            <div class="kn-headline-24-med">Simplicity </div>
            <div class="text"><span class="kn-bold">(intuitive + usable)</span></div>
        </section>

        <section>
            Create a simple product experience, offsetting the general complexity of eDiscovery practices. Establish focus and understanding at a glance, drawing attention to information of greatest importance while minimizing distractions. Foster clarity in communicating progress, current position within the task workflow, and next steps. Allow for progressive disclosure of additional detail and complexity only as deemed appropriate and necessary by the user. 
        </section>

        <section>
            <div class="kn-headline-24-med">Courage </div>
            <div class="text"><span class="kn-bold">(innovative)</span></div>
        </section>

        <section>
            Ideate on novel approaches to solving user challenges in ways that create value and extend beyond current industry conventions and norms. Test and validate concepts, learning to recognize when to challenge or break established rules. Have the courage to exceed customer expectations and introduce innovative ideas when doing so has the potential to benefit our customers, and our company. 
        </section>
       
    </div>
</div>