import { NgModule } from '@angular/core';
import { KrakenSplitButtonComponent } from './kraken-split-button.component';
import { CommonModule } from '@angular/common';


@NgModule({
  declarations: [KrakenSplitButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [KrakenSplitButtonComponent]
})
export class KrakenSplitButtonModule { }
