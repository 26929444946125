

<login *ngIf="!(isLoggedIn | async)"></login>

<div class="main-container light" *ngIf="isLoggedIn | async">
  <header></header>
  <side-nav></side-nav>
  <div class="canvas-container" id="cnContainer">
    <router-outlet (activate)="onActivate()"></router-outlet>
    <!-- <kn-footer [id]="'mainFooter'" [sticky]="true"></kn-footer> -->
  </div>
</div>


