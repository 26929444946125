<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text">Content <span class="kn-bold"> Tabs </span> are used to navigate between multiple groups of related content, views, or related functions displayed within the same UI container or panel.</div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Types/States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Selected</span></div>
            <div class="text"><span class="kn-bold">2.	Selected hover</span></div>
            <div class="text"><span class="kn-bold">3.	Unselected</span></div>
            <div class="text"><span class="kn-bold">4.	Unselected hover</span></div>
            <div class="text"><span class="kn-bold">5.	Disabled</span></div>
            <div class="text"><span class="kn-bold">6.	Overflow selected</span></div>
            <div class="text"><span class="kn-bold">7.	Overflow selected hover</span></div>
            <div class="text"><span class="kn-bold">8.	Oveflow unselected</span></div>
            <div class="text"><span class="kn-bold">9.	Overflow unselected hover</span></div>
        </section>

      
        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_02.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Tabs</div>      
            </section>

            <div class="text"><span class="kn-bold">A.	Tab label</span></div>
            <div class="text"><span class="kn-bold">B.	Selection indicator</span></div>
            <div class="text"><span class="kn-bold">C.	Panel</span></div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_03.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Tab overflow menu</div>      
            </section>

            <div class="text"><span class="kn-bold">D.	Tab overflow icon button</span></div>
            <div class="text"><span class="kn-bold">E.	Menu option enabled</span></div>
            <div class="text"><span class="kn-bold">F.	Menu option hover</span></div>
            <div class="text"><span class="kn-bold">G.	Menu option disabled</span></div>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_04.png">
            </section>

            <div class="kn-subtitle-16">Tab label</div>
            <div class="text"> Use the tab labels to explain the independent information to be displayed when selecting each tab.</div>

            <ul class="bullets">
                <li>Labels should be single words or succinct phrases to support quick scanning and interpretation that do not exceed 25 characters.</li>
                <li>Use sentence case in the display of the tab label text.</li>
            </ul>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_05.png">
            </section>

            <div class="kn-subtitle-16">Selected state</div>
            <div class="text"> A tab in a selected state shows the current tab open. This is shown with a selection indicator under the text label.</div>

            <section>
                <div class="text"><span class="kn-bold">1.	Selected </span></div>
                <div class="text"><span class="kn-bold">2.	Unselected </span></div>
            </section>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_06.png">
            </section>

            <div class="kn-subtitle-16">Tab size</div>
            <div class="text"> The width of each tab is defined by the length of its text label.</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_07.png">
            </section>

            <div class="kn-subtitle-16">Tab default order</div>
            <div class="text"> By default, tabs should be listed (left to right) in a logical order, either by the sequence of the user workflow or the hierarchy in frequency of potential use. The first tab (on the left) is also selected by default when the panel that hosts the tab menu first loads.</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_08.png">
            </section>

            <div class="kn-subtitle-16">Tab overflow</div>
            <div class="text"> By default, tabs should be listed (left to right) in a logical order, either by the sequence of the user workflow or the hierarchy in frequency of potential use. The first tab (on the left) is also selected by default when the panel that hosts the tab menu first loads.</div>
        
            <section>
                <div class="text"> On selection of the more icon, a contextual menu opens to display the labels for those tabs that will not fit within the width of the panel layout in the order defined by the original configuration of the component.</div>
            </section>
        
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_09.png">
            </section>

            <div class="kn-subtitle-16">Reinstate title</div>
            <div class="text"> When you choose your title, make an obvious association with the tab selected.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_10.png">

        <section>
            <div class="kn-subtitle-16">Long labels</div>

            <div class="text">Avoid using long labels on tabs in support of quick scanning and interpretation. Tab labels should display on a single line and not wrap to a second line.</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_11.png">
        
        <section>
            <div class="kn-subtitle-16">Icons in tabs</div>

            <div class="text">Icons should not be displayed in combination with or in place of tab label text. When it is necessary, however, choose those with the most appropriate visual metaphor to represent the content of the associated tab.</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_12.png">

        <section>
            <div class="kn-subtitle-16">Stacking tabs</div>

            <div class="text">Avoid displaying a number of tabs large enough to tempt stacking (wrapping) them in two or more rows. If several tabs are required, use the classic tab style and consider employing the tab overflow menu.</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/tabs/KrakenDSM_Components_Tabs_13.png">

        <section>
            <div class="kn-subtitle-16">Don’t use tabs for varying levels of importance</div>

            <div class="text">Use tabs to organize sections of equal importance. Groups of content under each tab item should not be of different natures.</div>
        </section>

    </div>
</div>
