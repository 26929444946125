<div class="kn-radio-container" [ngClass]="{'disabled': disabled, 'error': error, 'warning': warning}">
    <input 
        type="radio"
        id="{{radioId}}"
        [attr.name]="name"
        [value]="value"
        [attr.aria-label]="label"
        [(ngModel)]="defaultValue">
    <label for="{{radioId}}" (click)="onModelChange()" ></label>
    <span *ngIf="label != '' && label != undefined || customLabel" class="label-text">
        <span *ngIf="!customLabel">{{label}}</span>
        <span *ngIf="customLabel">
            <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
        </span>
    </span>
</div>