import { NgModule } from '@angular/core';
import { KrakenRadioButtonComponent } from './kraken-radio-button.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [KrakenRadioButtonComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [KrakenRadioButtonComponent]
})
export class KrakenRadioButtonModule { }
