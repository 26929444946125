import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'kn-button',
  templateUrl: './kraken-button.component.html',
  styleUrls: ['./kraken-button.component.sass'],
})
export class KrakenButtonComponent implements OnInit {
  @Input() buttonId: string;
  @Input() buttonText: string;
  @Input() buttonType: string;
  @Input() buttonIconLeft: string;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() active = false;
  @Input() width;
  @Output() btnClick = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
		this.btnClick.emit(
      {
        id: this.buttonId,
        text: this.buttonText,
        type: this.buttonType,
        active: this.active
      });
	}

}
