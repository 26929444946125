import { Component, AfterViewInit, Input, Output, EventEmitter, forwardRef, ContentChild, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor  } from "@angular/forms";

export class Radio {
  id: string;
  name: string;
  value: any;
  label: string;
}
@Component({
  selector: 'kn-radio-button',
  templateUrl: './kraken-radio-button.component.html',
  styles: [
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => KrakenRadioButtonComponent), 
      multi: true
    }
  ]
})
export class KrakenRadioButtonComponent implements AfterViewInit, ControlValueAccessor {
  @Input() radioId: string;
  @Input() name: string;
  @Input() value: any;
  @Input() label: string;
  @Input() disabled = false;
  @Input() defaultValue: any;
  @Input() customLabel = false;
  @Input() error = false;
  @Input() warning = false;
  @Output() onSelect = new EventEmitter<any>();

  @ContentChild(TemplateRef) labelTemplate: TemplateRef<any>;

  constructor() { }

  ngAfterViewInit(): void {
   
  }

  propagateChange = (_: any) => {};
  public onTouched: any = () => {};

  writeValue(obj: any): void {

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange() {
    const radio = new Radio;
    radio.id = this.radioId;
    radio.name = this.name;
    radio.value = this.value;
    radio.label = this.label
    this.onSelect.emit(radio);
    this.propagateChange(this.value)
  }

}
