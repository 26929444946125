import { Component, OnInit } from '@angular/core';
import { ReleaseNotesService } from '../../services/release-notes.service';
@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.sass']
})
export class ReleaseNotesComponent implements OnInit {
  public releaseNotesdata;

  constructor(private releaseNotes: ReleaseNotesService) { }

  ngOnInit(): void {
    this.releaseNotesdata = this.releaseNotes.releaseNotes();
  }

}
