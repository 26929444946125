<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">The <span class="kn-bold">Tooltip</span> is a common graphical user interface element in which, when hovering over a screen element or component, a text box displays information about that elements, such as:</div>
        </section>

        <section>
            <ul class="bullets">
                <li>Definition of a term or abbreviation</li>
                <li>Name or title associated with an icon or graphic in place of a displayed label</li>
                <li>Full text for truncated text displayed in UI</li>
                <li>Brief instructional comment</li>
            </ul>
        </section>

        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Single-line tooltip</span></div>
            <div class="text"><span class="kn-bold">2.	Multi-line tooltip</span></div>
            <div class="text"><span class="kn-bold">3.	Multi-line tooltip with label title</span></div>
            <div class="text"><span class="kn-bold">4.	List tooltip</span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_02.png">

        <section>
            <div class="text"><span class="kn-bold">A.	Container: </span>Contains label text.</div>
            <div class="text"><span class="kn-bold">B.	Label title: </span>Tooltips can come with an optional label title.</div>
            <div class="text"><span class="kn-bold">C.	Label: </span>All tooltips have a label. The label communicates contextual help or information about specific components when a user hovers over or focuses on them.</div>
            <div class="text"><span class="kn-bold">D.	Caret tip: </span>Closely associates the container to the term to be defined. </div>
            <div class="text"><span class="kn-bold">E.	UI trigger </span></div>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_03.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Helper text</div>
           
                <div class="text">Use the tooltip to provide helper text in support of the user’s task versus communicating primary task instruction. Always be as brief and concise as possible. Examples include:</div>
            </section>

            <section>
                <ul class="bullets">
                    <li>Definition of a term or abbreviation</li>
                    <li>Name or title associated with an icon or graphic in place of a displayed label</li>
                    <li>Full text for truncated text displayed in UI</li>
                    <li>Brief instructional comment</li>
                </ul>
            </section>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_04.png">
            </section>

            <div class="kn-subtitle-16">Trigger and closing</div>

            <div class="text">Tooltip displays on hover over the target trigger object. Moving the cursor off the trigger object automatically closes the tooltip.</div>
        </section>


        <section>            
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_05.png">
            </section>

            <section>
            <div class="kn-subtitle-16">Sizing</div>

                <ul class="bullets">
                    <li>Tooltip width is configurable upon implementation; otherwise it is defined by the length of the tooltip text plus the defined padding. <span class="kn-bold">Maximum tooltip width is recommended not to exceed 254px.</span></li>
                    <li>Text within the tooltip container wraps to an additional line when reaching maximum width (plus padding). </li>
                    <li>Height/depth of the container extends based on the number of lines of text (plus padding). </li>
                    <li>Best practice is to limit tooltip text to fit on a single line within the maximum width configuration; otherwise consider using the popover instead.</li>
                </ul>
            </section>    
        </section>


        <section>            
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_06.png">
            </section>

            <div class="kn-subtitle-16">Positioning</div>

            <div class="text">Default orientation of the tooltip is above the trigger object. However, the orientation may dynamically switch as it becomes necessary subject to the location of the associated trigger object in the layout and resizing of the viewport window. As such, the tooltip may display above, below, to the right, or to the left of the trigger object as needed.</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_07.png">
            </section>

            <div class="kn-subtitle-16">Pointer orientation</div>

            <div class="text">Tooltip pointers are center aligned with the bottom, top, right, or sides of the tooltip container. Default pointer position is center aligned with the bottom. However, the position may dynamically switch as it becomes necessary subject to the location of the associated trigger object in the layout and resizing of the viewport window.</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_08.png">
            </section>

            <div class="kn-subtitle-16">Offset</div>

            <div class="text">The offset is the distance between the end of the tip and the target. The default value is <span class="kn-bold">6px</span>.</div>
        </section>


        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_09.png">
            </section>
            
            <div class="kn-subtitle-16">Container padding</div>

            <div class="text">To ensure that the tooltip stays within certain boundaries (e.g., a browser window), define the container padding value to <span class="kn-bold">10px</span>.</div>
        </section>


        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_10.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Overflow count</div>
   
                <div class="text">When displaying information in support of more than one component, the tooltip label is displayed in a stacked list.</div>
            </section>

            <section>    
                <div class="text">For example, when two or more selected values exceed the display width of the text box, the overflow count tooltip displays menu options in a stacked view. When more than five menu options have been selected, menu options are displayed as a list separated by commas.</div>
            </section>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_14.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Use of browser-based tooltips </div>
   
                <div class="text">Browser-based directive tooltips provide an on-hover label associated with icon buttons in the UI wherein no label is displayed by default. </div>
            </section>

            <section>    
                <div class="text">Kraken DS tooltips, however are used when there is additional content to display such as:</div>
            
                <ul class="bullets">
                    <li>Information associated with an information icon.</li>
                    <li>Definition of a term or abbreviation.</li>
                    <li>Full text for truncated text displayed in UI.</li>
                    <li>Full selected options list for values truncated in a multi-select dropdown.</li>
                    <li>Detailed description of error associated with displayed warning or error icon.</li>
                </ul>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_11.png">
            
            <section>
                <div class="kn-subtitle-16">Hiding task critical information</div>

                <div class="text">Do not use tooltips for information vital to complete a task.</div>
            </section>
        </section>

        <section>            
            <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_12.png">
            
            <section>
                <div class="kn-subtitle-16">Overlapping trigger object</div>

                <div class="text">The tooltip should not overlap the trigger object, regardless of where the tooltip is positioned and how that may dynamically switch during the resizing of the viewport window.</div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/tooltip/KrakenDSM_Components_Tooltip_13.png">
            
            <section>
                <div class="kn-subtitle-16">Actions within tooltip</div>

                <div class="text">Do not place actions (triggers, buttons, links, etc.) within tooltips. If an action trigger is required, using a popover instead.</div>
            </section>
        </section>
    </div>
</div>