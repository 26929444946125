import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'panel-specs',
  templateUrl: './panel-specs.component.html',
  styleUrls: ['./panel-specs.component.sass']
})
export class PanelSpecsComponent implements OnInit {
  public component = window.location.protocol + '//' + window.location.host + '/design-specs-measure/panel/';

  constructor() { }

  ngOnInit(): void {
  }

  clickEvent(route) {
    if(route == 'light') {
      window.open(this.component, "_blank");
    }
  }

}
