
<div class="kn-menu-btn" id="{{componentId}}">
    <i id="{{iconId}}" 
        class="kn-icon {{icon}}" 
        [ngClass]="{
            'disabled': disabled,
            'focus': showDropdown,
            'rotate': showDropdown && animateIcon,
            'active': activeOnOpen
            }"
        (click)="onClick()"
        tabindex="0"
        (keydown)="keydown($event)">
    </i>
    <!--Dropdown section-->
    <div 
        class="kn-dropdown" 
        [ngClass]="
        {
            'show': showDropdown, 
            'kn-btn-dropdown-content-width': contentWidth
        }" 
        [ngStyle]="
        {
            'top': topPos, 
            'width': dropdownCustomWidth ? dropdownCustomWidth : width,
            'height': dropdownCustomHeight ? dropdownCustomHeight : height,
            'left': leftPos ? leftPos : 'unset'
        }">
        <!--Object arrays-->
        <ng-container *ngIf="fields">
            <ng-container *ngFor="let key of source">
                <div class="kn-dropdown-item"  (click)="valueChange(key)" [ngClass]="{'disabled': item.origin.disabled}">
                    <div *ngIf="!template">{{key.text}}</div>
                    <!--custom row template-->
                    <div *ngIf="template">
                        <ng-container [ngTemplateOutlet]="customTemplate" [ngTemplateOutletContext]="{data: key.origin}"></ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <!--Non Object arrays-->
        <ng-container *ngIf="!fields">
            <ng-container *ngFor="let key of items">
                <div class="kn-dropdown-item"  (click)="valueChange(key)">
                    <div *ngIf="!template">{{key}}</div>
                    <div *ngIf="template">
                        <ng-container [ngTemplateOutlet]="customTemplate" [ngTemplateOutletContext]="{data: key}"></ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <!--No items provided-->
        <ng-container *ngIf="!items">
            <ng-container [ngTemplateOutlet]="customTemplate"></ng-container>
        </ng-container>
    </div>
</div>
