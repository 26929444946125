<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text">The <span class="kn-bold">Text area </span> is a form element used to display, enter, or edit large quantities (multiple lines) of text. </div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textarea/KrakenDSM_Components_Textarea_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Enabled </span></div>
            <div class="text"><span class="kn-bold">2.	Enabled with optional label </span></div>
            <div class="text"><span class="kn-bold">3.	Enabled with placeholder text </span></div>
            <div class="text"><span class="kn-bold">4.	Enabled with character counter </span></div>
            <div class="text"><span class="kn-bold">5.	Enabled with value </span></div>
            <div class="text"><span class="kn-bold">6.	Focused with value and character counter </span></div>
            <div class="text"><span class="kn-bold">7.	Focused with value overflow </span></div>
            <div class="text"><span class="kn-bold">8.	Read-only </span></div>
            <div class="text"><span class="kn-bold">9.	Read-only with value overflow </span></div>
            <div class="text"><span class="kn-bold">10.	Disabled with placeholder text </span></div>
            <div class="text"><span class="kn-bold">11.	Disabled with value </span></div>
            <div class="text"><span class="kn-bold">12.	Disabled with value overflow </span></div>
            <div class="text"><span class="kn-bold">13.	Error </span></div>
            <div class="text"><span class="kn-bold">14.	Warning </span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textarea/KrakenDSM_Components_Textarea_02.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Text field</div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">A.	Container: </span>Containers improve the discoverability of text fields by creating contrast between the text field and surrounding content.</div>
                <div class="text"><span class="kn-bold">B.	Label text: </span>Label text is used to inform users as to what information is requested for a text field.</div>
                <div class="text"><span class="kn-bold">C.	Placeholder text (optional): </span>Placeholder text conveys additional guidance about the input field. The placeholder text is displayed inside the text field.</div>
                <div class="text"><span class="kn-bold">D.	Optional label </span></div>
                <div class="text"><span class="kn-bold">E.	Value: </span>The input text is text entered by the user. The input text is displayed inside the text field.</div>
                <div class="text"><span class="kn-bold">F.	Character count: </span>The character count is displayed if there is a character or word limit. They display the ratio of characters used out of the total character limit.</div>
                <div class="text"><span class="kn-bold">G.	Scrollbar </span></div>
                <div class="text"><span class="kn-bold">H.	Error/warning icon: </span>Displaying an error/warning icon together with text makes error/warning states clear for colorblind users.</div>
                <div class="text"><span class="kn-bold">I.	Error/warning text: </span>When an error or warning occurs, error/warning messages are displayed below the text box container.</div>
            </section>
        </section>

        <section>
            <div class="kn-headline-24-med">Assistive elements</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textarea/KrakenDSM_Components_Textarea_03.png">

        <section>
            <div class="text"><span class="kn-bold">1. Labels</span> </div>
            <div class="text">Use the label to explain the purpose of the text box field and type of value that should be entered by the user. Text areas should always include a text label. They should be visible and positioned above the container, consistent with label positioning of other components in your form. A text area without a label is ambiguous and not accessible.</div>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">2. Required or optional</span> </div>
            <div class="text">All text areas are required by default and do not require displaying a required attribute in the label. However, when displaying an optional field, they are denoted with text added to the end of the label — “(optional)” —. If using an inline or text box without a label, the "(optional)"" should be denoted within the placeholder text.</div>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">3. Placeholder text</span> </div>
            <div class="text">If the value needs to be provided in a specific format (e.g.: name@domain.com), use placeholder text inside the text box control to describe how the information should be entered, not what the field represents.
                <section>
                Placeholder text should not be editable and should disappear once the user focuses on the text box and begins to type. If the value entered by the user is removed and the text box loses input focus, the hint text should be restored.
                </section> 
            </div>
        </section>   

        <section>
            <div class="text"><span class="kn-bold">4. Character count</span> </div>
            <div class="text">Text areas can display a character count indicator when the length of the text entry needs to be kept under a predefined value. They display the ratio of characters used and the total character limit.</div>
        </section> 
        
        <section>
            <div class="text"><span class="kn-bold">5. Error/warning text</span> </div>
            <div class="text">A text area can be marked as having an error to show that a value needs to be entered in order to move forward or that a value that was entered is invalid.</div>
        </section> 
            
        <section>
            <div class="kn-headline-24-med">Guidelines</div>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/textarea/KrakenDSM_Components_Textarea_04.png">
            </section>

            <div class="kn-subtitle-16">Display width</div>

            <div class="text">In general, the display width of the text box should reflect the approximate maximum character length for what would be considered a standard representative value entered by the user. When the entered value exceeds the display width of the text box, it should display with truncation (ellipses).</div>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/textarea/KrakenDSM_Components_Textarea_05.png">
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Modal</div>
            
            <div class="text">The standard width of text boxes displayed in the body of modal two-column input fields are 264px, while single column width spans across 576px.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Rounded corners</div>

            <div class="text">Text area containers are displayed with a 4px rounded corner.</div>
        </section>

        <section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/textarea/KrakenDSM_Components_Textarea_06.png">
        </section>

        <div class="kn-subtitle-16">Text overflow</div>

        <div class="text">When the length of text is bounded by a predefined value, a character counter indicator is displayed. The character counter is represented by the ratio of characters used out of the total character limit.</div>

            <section>
                <div class="text"><span class="kn-bold">1. </span>When typing and the length of the text entry reaches the predefined value, the user is prevented from typing additional characters.</div>
            </section>    
                
            <section>  
                <div class="text"><span class="kn-bold">2.  </span>When pasting a value greater than the prescribed length of characters allowed, the character counter’s characters used text displays in bold red to subtly indicate an error needs to be corrected before moving forward.</div>
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Text wrapping and scrolling</div>

            <div class="text">Content entered in the text area will wrap onto a new row as it reaches the display width (length) of the field. The text area component does not truncate the content displayed within. Rather, as the number of rows begins to exceed the display height of the field, a scroll bar is introduced. The display and behavior of this scroll bar is subject to and consistent with the user browser defaults.</div>
        
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/textarea/KrakenDSM_Components_Textarea_07.png">
            </section>

            <section>
                <div class="text">When typing into a text area and reaching the end of the field, the cursor should remain as static in the bottom right corner while text above it overflows through the top of the field. When the field loses focus, text should overflow through the bottom of the text area, showing the beginning of the text.</div>
            </section>
        
        </section>

        <section>
            <div class="kn-subtitle-16">Input constraints</div>

            <div class="text">Text areas can accept any type of input or they can be constrained with additional validation and formatting rules, for example, restricting the input to only specific types of characters (such as numbers) or setting a character limit.</div>
            
            <section>
                <div class="text"><span class="kn-bold">When using input constraints:</span></div>
            </section>
            
            <ul class="bullets">
                <li>Apply them only when underlying data is well-defined and unlikely to change.</li>
                <li>Call out what the constraints are using instructional text or hint text (for example, “Do not use the / or & symbols in your input value”.)</li>
            </ul>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/textarea/KrakenDSM_Components_Textarea_09.png">
            
            <section>
                <div class="kn-subtitle-16">Text overflow</div>

                <div class="text">Use hints sparingly and only when additional explanation is needed to reduce visual clutter in busy forms and to make clear what controls still need input. Do not use hints to simply restate the label.</div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/textarea/KrakenDSM_Components_Textarea_10.png">
            
            <section>
                <div class="kn-subtitle-16">Single-line input</div>

                <div class="text">Do not use the text area element when the expected input represents a character count that can fit within a single line. In this case, consider use of the text box form element instead.</div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/textarea/KrakenDSM_Components_Textarea_11.png">
            
            <section>
                <div class="kn-subtitle-16">Horizontal scrolling</div>

                <div class="text">Scrolling within the text area form element should be limited to vertical scroll only. Typing within the text area is to fit within the display width of the form element, automatically wrapping to the next line upon reaching the width threshold.</div>
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Enforcing rigid formatting rules</div>
            
            <div class="text">Before adding validation rules, consider first if data requirements may change in the future or may vary by geography. Try to handle all the most common text formats rather than enforcing rigid formatting rules. For example, phone numbers, addresses, and identifiers can be entered with optional spaces and punctuation, and the capitalization often doesn’t matter.</div>
        </section>
    </div>
</div>