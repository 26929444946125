import { Component, OnInit } from '@angular/core';
import  packageInfo from '../../../../../kraken-masthead/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';

@Component({
  selector: 'app-masthead',
  templateUrl: './masthead.component.html',
  styleUrls: ['./masthead.component.sass']
})
export class MastheadComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public angularComponentRef2;
  public webComponentRef;
  public elementTag = 'kn-masthead';
  public webElementTag = 'kn-web-masthead';
  public compressed = false;
  public leftNav = true;
  public rightNav = true;
  public siteName = true;
  public event1 = true;
  public event2 = true;
  public overflowActive;
  public overflowId;
  public overflowIndex;
  public overflowName;
  public overflowRouterlink;
  public overflowUrl;
  public version = false;
  public mainNavTooltip = `The main navigation section has a built-in overflow menu.<br/><br/>
  As the navigation space is reduced beyond its limits, links are hidden within an overflow menu.`
  public rightNavTooltip = `The masthead right-side consists of two areas: 
  <ul>
    <li>utility navigation</li>
    <li>user profile navigation</li>
  </ul>
  Please note that a vertical divider separates out the two sections.`
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenMastheadModule'
  };
  public componentVersion = packageInfo.version;

  public angularComponentOptions: any = [
    { option: 'id=', id: 0, value: '"\'exampleMasthead\'"'},
    { option: '(onOverflowMenuSelection)=', id: 0, value: '"clickEvent($event)"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 1, value: '"exampleMasthead"' }
  ];

  public webCreateContent =
  `let masthead = document.querySelector('#sampleMasthead');
masthead.logo = \`<img src='KrakenDS_Masthead_ProductLogo_Lighthouse.svg'>\`
masthead.siteName = \`Site name here\`;
masthead.leftnav = 
\`<div class="kn-header-nav">
  <a class="link">Link 1</a>
  <span>:</span>
</div>
<div class="kn-header-nav">
  <a class="link" href="">Link 2</a>
</div>
<div class="kn-header-nav">
  <div class="link">Link 3</div>
</div>
<div class="kn-header-nav">
  <a class="link" href="">Link 4</a>
</div>
<div class="kn-header-nav" id="exampleOverflowLinkId">
  <div class="link">Link 5</div>
</div>\``

  public webOverflowClickEvent =
`masthead.addEventListener('onOverflowMenuSelection', (e) => {
  window.location.href = e.detail.url;
});`

  public overflowClickEvent = 
  `clickEvent(event) {
  if(event.url) {
    window.location.href = event.url;
  }
}`

public helpLinkFunction = 
`function helpBtnClick(e) {
  //some code here...
}`

public webContextExample =
`const rightNavContext = { helpClick: this.helpBtnClick.bind(this)};
masthead.rightNavContext = rightNavContext;`

public rightNavExample = 
`masthead.rightNav = 
\`<div class="kn-pipe"></div>
<i id="helpButton" class="fa-solid fa-circle-question kn-icon kn-icon-md" title="This is a tooltip"></i>
<i title="test tool tip" class="fa-solid fa-bell kn-icon kn-icon-md"></i>
<kn-icon-menu
  [icon]="'fa-solid fa-gear'"
  [dropdownRightAligned]="true"
  [items]="['Link 1','Link 2']"
  [dropdownCustomWidth]="'max-content'">
</kn-icon-menu>
<div class="kn-pipe"></div>
<div class="initials">K N</div>
<kn-icon-menu
  id="test"
  [id]="'initialsDropdown'"
  [icon]="'fa-regular fa-chevron-down'"
  [dropdownRightAligned]="true"
  [items]="['Link 1','Link 2', 'Link 3']"
  [animateIcon]="true"
  [dropdownCustomWidth]="'max-content'">
</kn-icon-menu>\``

  constructor(public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);

  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }

  clickEvent(event) {
    this.overflowActive = event.active;
    this.overflowId = event.id;
    this.overflowIndex = event.index;
    this.overflowName = event.name;
    this.overflowRouterlink = event.routerlink;
    this.overflowUrl = event.url;
  }


  updateTemplate() {
    let template;
    if(this.leftNav && this.rightNav && this.siteName) {
      template = 
    `<div logo>
    <img class="logo" src="../../assets/images/KrakenDS_Masthead_ProductLogo_Lighthouse.svg" routerLink="/">
</div>
<div version>
   //you can include a version here...
</div>
<div leftNav>
  <div class="kn-header-nav">
    <a class="link" routerLink="/masthead" [routerLinkActive]="['active-link']">Link 1</a>
    <span>:</span>
  </div>
  <div class="kn-header-nav">
    <a class="link" href="/#modal">Link 2</a>
  </div>
  <div class="kn-header-nav">
    <div class="link">Link 3</div>
  </div>
  <div class="kn-header-nav">
    <a class="link">Link 4</a>
  </div>
  <div class="kn-header-nav" id="exampleOverflowLinkId">
    <div class="link">Link 5</div>
  </div>
</div>
<div rightNav>
  <div class="kn-pipe"></div>
  <i id="helpButton" class="fa-solid fa-circle-question kn-icon kn-icon-md" tooltip='This is a tooltip' [tooltipDirection]="'top'"></i>
  <i class="fa-solid fa-bell kn-icon kn-icon-md" tooltip='This is a tooltip' [tooltipDirection]="'top'"></i>
  <kn-icon-menu
    [icon]="'fa-solid fa-gear'"
    [dropdownRightAligned]="true"
    [template]="true"
    [dropdownCustomWidth]="'max-content'">
    <ng-template  #customTemplate>
      <div class="kn-dropdown-item">Link 1</div>
      <div class="kn-dropdown-item">Link 2</div>
      <div class="kn-dropdown-item">Link 3</div>
    </ng-template>
  </kn-icon-menu>
  <div class="kn-pipe"></div>
  <div class="initials">K N</div>
  <kn-icon-menu
    [id]="'initialsDropdown'"
    [icon]="'fa-regular fa-chevron-down'"
    [dropdownRightAligned]="true"
    [template]="true"
    [animateIcon]="true"
    [dropdownCustomWidth]="'max-content'">
    <ng-template  #customTemplate>
      <div class="kn-dropdown-item">Link 1</div>
      <div class="kn-dropdown-item">Link 2</div>
    </ng-template>
  </kn-icon-menu>
</div>`
    }
    if(!this.leftNav && this.rightNav && this.siteName) {
      template = 
      `<div logo>
    <img class="logo" src="../../assets/images/KrakenDS_Masthead_ProductLogo_Lighthouse.svg" routerLink="/">
</div>
<div siteName>
   
</div>
<div rightNav>
<div class="kn-pipe"></div>
<i id="helpButton" class="fa-solid fa-circle-question kn-icon kn-icon-md" tooltip='This is a tooltip' [tooltipDirection]="'top'"></i>
<i class="fa-solid fa-bell kn-icon kn-icon-md" tooltip='This is a tooltip' [tooltipDirection]="'top'"></i>
  <kn-icon-menu
    [icon]="'fa-solid fa-gear'"
    [dropdownRightAligned]="true"
    [template]="true"
    [dropdownCustomWidth]="'max-content'">
    <ng-template  #customTemplate>
      <div class="kn-dropdown-item">Link 1</div>
      <div class="kn-dropdown-item">Link 2</div>
      <div class="kn-dropdown-item">Link 3</div>
    </ng-template>
  </kn-icon-menu>
  <div class="kn-pipe"></div>
  <div class="initials">K N</div>
  <kn-icon-menu
    [id]="'initialsDropdown'"
    [icon]="'fa-regular fa-chevron-down'"
    [dropdownRightAligned]="true"
    [template]="true"
    [animateIcon]="true"
    [dropdownCustomWidth]="'max-content'">
    <ng-template  #customTemplate>
      <div class="kn-dropdown-item">Link 1</div>
      <div class="kn-dropdown-item">Link 2</div>
    </ng-template>
  </kn-icon-menu>
</div>`
    }
    if(!this.leftNav && !this.rightNav && this.siteName) {
      template = 
    `<div logo>
    <img class="logo" src="../../assets/images/KrakenDS_Masthead_ProductLogo_Lighthouse.svg" routerLink="/">
</div>
<div siteName>
   
</div>`
    }
    if(this.leftNav && !this.rightNav && this.siteName) {
      template = 
    `<div logo>
    <img class="logo" src="../../assets/images/KrakenDS_Masthead_ProductLogo_Lighthouse.svg" routerLink="/">
</div>
<div siteName>
   
</div>
<div leftNav>
  <div class="kn-header-nav">
    <a class="link" routerLink="/masthead" [routerLinkActive]="['active-link']">Link 1</a>
  </div>
  <div class="kn-header-nav">
    <a class="link" href="/#modal">Link 2</a>
  </div>
  <div class="kn-header-nav">
    <div class="link">Link 3</div>
  </div>
  <div class="kn-header-nav">
    <a class="link">Link 4</a>
  </div>
  <div class="kn-header-nav" id="exampleOverflowLinkId">
    <div class="link">Link 5</div>
  </div>
</div>`
    }

  //angular template
  this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, template);
  return template;
  }

}
