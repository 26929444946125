import { Component, Input } from '@angular/core';

@Component({
  selector: 'kn-tab',
  templateUrl: './kraken-tab.component.html'
})
export class KrakenTabComponent {
  @Input() index: number;
  @Input() id: any;
  @Input('tabTitle') title: string;
  @Input('disabled') disabled = false;
  @Input() active = false;
  @Input() width: string;
  @Input() minWidth: string;
  @Input() maxWidth: string;

}
