import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';

@Component({
  selector: 'chip-accessibility',
  templateUrl: './chip-accessibility.component.html',
  styleUrls: ['./chip-accessibility.component.sass']
})
export class ChipAccessibilityComponent implements OnInit {

  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;
  
  constructor(private templateService: TemplateUpdateService) { }

  ngOnInit(): void {
    
  }

}
