import { Component, OnInit } from '@angular/core';
import  packageInfo from '../../../../../kraken-progress-indicator/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';

@Component({
  selector: 'app-progress-indicator',
  templateUrl: './progress-indicator.component.html',
  styleUrls: ['./progress-indicator.component.sass']
})
export class ProgressIndicatorComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public event1 = true;
  public elementTag = 'kn-progress-indicator';
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenProgressIndicatorModule'};
  public componentId = 'progressComponentId';
  public progressValue = 0;
  public progressType;
  public showSuccess = false;
  public showWarning = false;
  public showError = false;
  public inlineProgressBar = false;
  public progressDisabled = false;
  public progressSubText = 'Helper text';
  public progressLabel = 'Progress indicator label';
  public errorMessage = 'Custom error message here';
  public queued = false;
  public interval: any;
  public onSuccessItems: any = [];
  public angularComponentOptions: any = [
    { option: '[componentId]=', id: 0, value: '"\'' + this.componentId +'\'"'},
    { option: '[label]=', id: 0, value: '"\''+ this.progressLabel +'"'},
    { option: '[subText]=', id: 0, value: '"\''+ this.progressSubText +'"'},
    { option: '[value]=', id: 0, value: '"\''+ this.progressValue +'"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"exampleProgressIndicator"'},
    { option: 'component-id=', id: 0, value:  '"'+ this.componentId +'"'},
    { option: 'label=', id: 0, value: '"'+ this.progressLabel +'"'},
    { option: 'sub-text=', id: 0, value: '"'+ this.progressSubText +'"'},
    { option: 'value=', id: 0, value: '"'+ this.progressValue + '"'}
  ];

  public webInstance =
`
const progressIndicator = document.querySelector('#exampleProgressIndicator');
progressIndicator.value = 0;`


  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);

    setTimeout(() => {
      this.progressAnimation();
    }, 1000);
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  value(event) {
    //angular
    const propId = this.angularComponentOptions.findIndex((obj => obj.option == '[value]='));
    this.angularComponentOptions[propId].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webPropId = this.webComponentOptions.findIndex((obj => obj.option == 'value='));
    this.webComponentOptions[webPropId].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  type(event, type) {
    this.progressType = event ? type : '';
    this.controlEvent(event, '['+ type +']=', event, true);
    this.controlEvent(event, '[errorMessage]=', '\'' + this.errorMessage +'\'', true);
    if(event) {
      //angular
      const propId = this.angularComponentOptions.findIndex((obj => obj.option == '[errorMessage]='));
      this.angularComponentOptions[propId].value = '"\'' + this.errorMessage +'\'"';
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
      //web
      const webPropId = this.webComponentOptions.findIndex((obj => obj.option == 'error-message='));
      this.webComponentOptions[webPropId].value = '"' + this.errorMessage + '"';
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    }
  }

  label(event) {
    //angular
    const propId = this.angularComponentOptions.findIndex((obj => obj.option == '[label]='));
    this.angularComponentOptions[propId].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webPropId = this.webComponentOptions.findIndex((obj => obj.option == 'label='));
    this.webComponentOptions[webPropId].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  subText(event) {
    //angular
    const propId = this.angularComponentOptions.findIndex((obj => obj.option == '[subText]='));
    this.angularComponentOptions[propId].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webPropId = this.webComponentOptions.findIndex((obj => obj.option == 'sub-text='));
    this.webComponentOptions[webPropId].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  success(event) {
    this.onSuccessItems.id = event.id;
    this.onSuccessItems.subText = event.subText;
    this.onSuccessItems.value = event.value;
    this.onSuccessItems.label = event.label;
  }

  progressAnimation() {
    let val = Number(this.progressValue);
      this.interval = setInterval(() => {
        if(val < 100) {
          val += 1
          this.progressValue = val;
          this.value(val);
        } else {
          clearInterval(this.interval);
        }
        
      }, 60);
  }

  inlineProgressEvent(event) {
    this.controlEvent(event, '[inline]=', this.inlineProgressBar, true);
    if(event) {
      this.controlEvent(false, '[label]=', "", true);
      this.controlEvent(false, '[subText]=', "", true);
    }
    else {
      this.controlEvent(true, '[label]=', "", true);
      this.controlEvent(true, '[subText]=', "", true);
      //angular
      const subText = this.angularComponentOptions.findIndex((obj => obj.option == '[subText]='));
      const label = this.angularComponentOptions.findIndex((obj => obj.option == '[label]='));
      this.angularComponentOptions[subText].value = '"\'' + this.progressSubText +'\'"';
      this.angularComponentOptions[label].value = '"\'' + this.progressLabel +'\'"';
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
      //web
      const webSubtext = this.webComponentOptions.findIndex((obj => obj.option == 'sub-text='));
      const webLabel = this.webComponentOptions.findIndex((obj => obj.option == 'label='));
      this.webComponentOptions[webSubtext].value = '"' + this.progressSubText + '"';
      this.webComponentOptions[webLabel].value = '"' + this.progressLabel + '"';
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    }
  }

  queuedEvent(event) {
    clearInterval(this.interval);
    this.progressValue = 0;
    this.controlEvent(event, '[queued]=', this.queued, true);
  }
  

}
