import { NgModule } from '@angular/core';
import { KrakenDropdownComponent } from './kraken-dropdown.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KrakenFieldValidationComponent } from './kraken-field-validation.component';
//import { KrakenDialogModule } from '@kraken-ui/kraken-dialog';
//import { KrakenDialogModule } from 'dist/kraken-dialog/projects';

@NgModule({
  declarations: [KrakenDropdownComponent, KrakenFieldValidationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //KrakenDialogModule
  ],
  exports: [KrakenDropdownComponent, KrakenFieldValidationComponent]
})
export class KrakenDropdownModule { }
