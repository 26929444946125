<div class="kn-autocomplete kn-input-contain no-label" [ngClass]="{'disabled': setDisabled, 'kn-sm' : compressed}">
    <div class="inner">
        <input 
            id="{{searchboxId}}" 
            class="kn-form-control autocomplete" 
            [(ngModel)]="searchTerm" 
            type="{{type}}" 
            [attr.aria-label]="placeholder"
            placeholder="{{placeholder}}" 
            (keyup)="keyUp($event)"
            [disabled]="setDisabled"
            name="{{name}}">
            <i *ngIf="searchTerm != ''" [ngClass]="{'kn-sm': compressed}" class="fa fa-solid fa-circle-xmark clear-icon kn-icon" (click)="clear()" aria-hidden="true"></i>
            <i *ngIf="searchTerm == ''" [ngClass]="{'kn-sm': compressed}" class="fa-regular fa-magnifying-glass search-icon" aria-hidden="true"></i>

        <div 
        class="kn-dropdown kn-autocomplete-dropdown" 
        [ngClass]="
        {
            'show': showDropdown && 
            !hideDropdown 
        }" 
        [ngStyle]="
        {
            'top': topPos, 
            'width': dropdownWidth ? dropdownWidth : width,
            'height': dropdownHeight ? dropdownHeight : height,
            'left': leftPos ? leftPos : 'unset'
        }">
            <!--If object-->
            <ng-container *ngIf="fields">
                <ng-container *ngFor="let key of source; let i = index">
                    <div class="kn-dropdown-item" id="result_{{i}}" (click)="itemSelect(source[i])">
                        <div class="target"></div>
                        <div class="item">{{searchTermHighlight(key.text, i)}}</div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="!fields">
                <ng-container *ngFor="let key of source; let i = index">
                    <!--If array (non-object)-->
                    <div class="kn-dropdown-item" id="result_{{i}}" (click)="itemSelect(key)"><div class="item">{{searchTermHighlight(key, i)}}</div></div>
                </ng-container>
            </ng-container>
            <div *ngIf="source.length == 0 && showDropdown" class="no-results">No match found</div>
        </div>
    </div>
</div>