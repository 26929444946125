<app-canvas  
    [js]="true" 
    [disableCanvasTab]="5" 
    [isEventTabDisabled]="true"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component">
    <div title>Footer <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-footer
            [id]="'footerId'">
            <div *ngIf="rightNav">
                <a id="footerLink1" class="kn-quiet-link" href="https://www.lighthouseglobal.com/privacy-policy" target="_blank">Privacy Policy</a>
                <a id="footerLink2" class="kn-quiet-link" href="https://www.lighthouseglobal.com/spectra-terms/2022" target="_blank">Terms of Use</a>
            </div>
        </kn-footer>
    </div>
    <div angular>
       
    </div>
    <div typeScript>
       
    </div>
    <div javascript>
        <span class="code-comment">//Note: content can only be created for the right side of the footer. Create the content as shown</span>
        {{footerRightnavInstance}}
        <br/><br/>
        <span class="code-comment">//You can bind events to the content as shown below</span>
        {{footerRightNavClickEvent}}
    </div>
    <div web></div>
    <!-- <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onSelect():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">id:</span> "{{eventItem?.id}}"</div>
                    <div class="obj-item"><span class="label">country:</span> "{{eventItem?.country}}"</div>
                    <div class="obj-item"><span class="label">code:</span> "{{eventItem?.code}}"</div>
                </div>
            </div>
        </div>
    </div> -->
    <div customproperties>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle [label]="'Right navigation'" (value)="showRightNav()" [(ngModel)]="rightNav"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle [label]="'Sticky footer'" (value)="stickyFooter()" [(ngModel)]="sticky"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">id</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"footerId"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">sticky</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <footer-usage></footer-usage>
    </div>
    <div designSpec>
        <footer-specs></footer-specs>
    </div>
    <div accessibility>
        <footer-accessibility></footer-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>Added addition unit testing after component was published</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the footer component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

