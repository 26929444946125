<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Accessibility</div>
        <section>
            <div class="text"><span class="kn-bold">Accessible design</span> lets people of all abilities interact with, understand, and navigate our products. Products and web properties must be inclusive and usable to a broad customer population, including those with vision, hearing, cognitive, or motor impairments. Aspects of overall accessibility addressed in the Kraken DS span the following scope:</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Contrast</div>
        </section>

        <section>
            <div class="text">We comply with AAA standard contrast ratios as defined in the <a class="kn-link" href="https://www.w3.org/TR/WCAG21/" target="_blank"> WC3 WCAG 2.1 </a> guidelines. To do this, we choose primary, secondary and extended colors that support usability. This ensures sufficient color contrast between elements so that people with low vision can see and use our products.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">AA</span> - Requires a contrast ratio of at least 4.5:1 for normal text and 3:1 for large text.</div>
            <div class="text"><span class="kn-bold">AAA</span> - Requires a contrast ratio of at least 7:1 for normal text and 4.5:1 for large text.</div>
            <ul>
                <li>Large text is defined as 14 point or larger, and bold.</li>
                <li>WCAG 2.1 requires a contrast ratio of at least 3:1 for graphics and user interface components.</li>
            </ul>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/about/accessibility/KrakenDSM_About_Accessibility_01.png">
        </section>

        <section>
            <div class="text">Contrast accessibility testing is conducted during the design system style and component design iteration processes, leveraging the <a class="kn-link" href="https://www.getstark.co/sketch" target="_blank"> Stark</a> plugin for Sketch (https://www.getstark.co/sketch) to guide our team on any necessary adjustments required to adhere to a WCAG 2.1 AA-AAA standard of compliance. Evidence of our accessibility testing due diligence is recorded under the "Accessibility" tab for each component section on the Kraken DS website.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Color blindness</div>
        </section>

        <section>
            <div class="text">Color blindness poses a unique set of visual design and contrast challenges for the application UI. To accurately identify potential color blindness accessibility issues with the styles and component designs proposed by the Kraken DS, we again leverage the Stark plugin for Sketch during the style and component design processes. Test results are documented under the "Accessibility" tab for each component section on the Kraken DS website.</div>
        </section>

        <section>
            <div class="text">Tests run for accessibility cover the following forms of color blindness:</div>
        </section>

        <section>
            <ul>
                <li><span class="kn-bold">Protanopia:</span> Makes red look more green and less bright. This type is mild and usually doesn’t get in the way of normal activities. Protanopia will make you unable to tell the difference between red and green at all.</li>
                <li><span class="kn-bold">Deuteranopia:</span> The most common type of red-green color blindness. It makes green look more red. This type is mild and doesn’t usually get in the way of normal activities. Deuteranopia also makes you unable to tell the difference between red and green at all.</li>
                <li><span class="kn-bold">Tritanopia:</span> This less-common type of color blindness makes it hard to tell the difference between blue and green, and between yellow and red. It makes you unable to tell the difference between blue and green, purple and red, and yellow and pink. It also makes colors look less bright.</li>
                <li><span class="kn-bold">Achromatopsia:</span> A condition characterized by a partial or total absence of color vision. People with complete Achromatopsia cannot perceive any colors; they see only black, white, and shades of gray. Incomplete Achromatopsia is a milder form of the condition that allows some color discrimination.</li>
            </ul>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/about/accessibility/KrakenDSM_About_Accessibility_02.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/about/accessibility/KrakenDSM_About_Accessibility_03.png">
            <div class="kn-body-12">Tritanopia color blindness test</div>
        </section>

        <section>
            <div class="text">It’s important to note that not all color blindness accessibility issues can be reasonably and adequately addressed in an application UI via color choice and manipulation alone. While adherence to strong contrast ratios in the design assists with color blindness UI interpretation, a more thoughtful approach is often required to support this user population. As such, whenever color is designed as the primary visual cue for the meaning of the component state or notification, it will be accompanied by supporting iconography that reinforces the intended visual message or metaphor without sole reliance on color alone.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/about/accessibility/KrakenDSM_About_Accessibility_04.png">
        </section>

        <section>
            <div class="kn-headline-24-med">Code accessibility</div>
        </section>

        <section>
            <div class="text">In additional to visual accessibility in the presentation of UI styles and components, the Kraken DS adheres to best practices for code accessibility standards. These standards are based on <a class="kn-link" href="https://www.w3.org/TR/WCAG21/" target="_blank"> WCAG 2.1 </a> guidelines and the <a class="kn-link" href="https://www.w3.org/WAI/standards-guidelines/aria/" target="_blank"> WIA-ARIA </a> (Accessible Rich Internet Application) best practices for semantic attributes for assistive technologies. To ensure our components are compliant, the Kraken DS team scans and tests source code utilizing <a class="kn-link" href="https://www.deque.com/axe/devtools/" target="_blank"> axe Dev Tools </a> application produced by Deque Systems (https://www.deque.com/). Evidence of a clean code accessibility report for each of the Kraken DS components may be found posted under the "Accessibility" tab for each component section of the website.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/about/accessibility/KrakenDSM_About_Accessibility_05.png">
        </section>
    </div>
</div>