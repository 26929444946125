<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Form Labels</div>
        
        <section>
            <div class="text"><span class="kn-bold">Label and Value</span> text pairings are represented whenever input data is being captured or displayed in read-only format in the application UI.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/formlabels/KrakenDSM_StyleGuide_Typography_LabelValue_01.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/formlabels/KrakenDSM_StyleGuide_Typography_LabelValue_02.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">1. LABEL TEXT</span></div>
            <div class="text">Font Size: 12px</div>
            <div class="text">Font Weight: Medium</div>
            <div class="text">Decoration: Uppercase</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Enabled:</span> #434C54 (Ink, Light)</div>
            <div class="text"><span class="kn-bold">Focused:</span> #205D86 (Primary)</div>
            <div class="text"><span class="kn-bold">Error:</span> #A50D08 (Danger)</div>
            <div class="text"><span class="kn-bold">Warning:</span> #B8551E (Secondary)</div>
            <div class="text"><span class="kn-bold">Read-Only:</span> #434C54 (Ink, Light)</div>
            <div class="text"><span class="kn-bold">Disabled:</span> #A5B2BD (Gray, Darker)</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/formlabels/KrakenDSM_StyleGuide_Typography_LabelValue_03.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">2. INPUT TEXT</span></div>
            <div class="text">Font Size: 14px</div>
            <div class="text">Font Weight: Book</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Placeholder:</span> #A5B2BD (Gray, Darker), Book Oblique</div>
            <div class="text"><span class="kn-bold">Value:</span> #1F1D21 (Ink)</div>
            <div class="text"><span class="kn-bold">Value Read-Only:</span> #1F1D21 (Ink)</div>
            <div class="text"><span class="kn-bold">Placeholder Disabled:</span> #A5B2BD (Gray, Darker)</div>
            <div class="text"><span class="kn-bold">Value Disabled:</span> #A5B2BD (Gray, Darker)</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/formlabels/KrakenDSM_StyleGuide_Typography_LabelValue_04.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">3. CHARACTER COUNT</span></div>
            <div class="text">Font Size: 12px</div>
            <div class="text">Font Weight: Book</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Enabled:</span> #A5B2BD (Gray, Darker)</div>
            <div class="text"><span class="kn-bold">Text overflow:</span> #A50D08 (Danger) Heavy</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/formlabels/KrakenDSM_StyleGuide_Typography_LabelValue_05.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">4. ERROR/WARNING TEXT</span></div>
            <div class="text">Font Size: 12px</div>
            <div class="text">Font Weight: Book</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Error:</span> #A50D08 (Danger)</div>
            <div class="text"><span class="kn-bold">Warning:</span> #1F1D21 (Ink)</div>
        </section>
    </div>
</div>