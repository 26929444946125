<div class="kn-date-picker" 
    [ngClass]="{
        'disabled': disable,
        'read-only': readonly,
        'kn-focused': focused,
        'kn-sm': compressed,
        'error': error
    }"
    >
    <div class="kn-label" 
        *ngIf="label"
    >
        {{label}}
    </div>
    <ejs-datepicker 
        [min]="min"
        [max]="max"
        (focus)="onFocus($event)"
        [format]='dateFormat'
        placeholder="{{placeholder}}"
        [value]="value"
        (change)= "onChange($event)"
        (open)="open($event)"
        (close)="close($event)"
        (blur)="blur($event)"
    ></ejs-datepicker>
    <div class="error" *ngIf="error && !compressed"><i class="fa-solid fa-triangle-exclamation kn-icon-sm"></i> {{errorMessage}}</div>
</div>