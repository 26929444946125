import { Component } from '@angular/core';

@Component({
  selector: 'lib-kraken-theme',
  template: `
    <p>
      kraken-theme works!
    </p>
  `,
  styles: [
  ]
})
export class KrakenThemeComponent {
}
