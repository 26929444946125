<div class="kn-input-contain" [ngClass]="{'disabled': setDisabled, 'kn-sm' : compressed, 'no-label': !label && !compressed, 'readonly': setReadOnly}">
    <div class="inner">
        <div class="kn-field-validation" 
            [style.left]="textboxLeftPos" 
            [style.top]="textboxBottomPos"
            [style.width]="textboxWidth"
            *ngIf="!setDisabled && !setReadOnly"
        >
            <kraken-field-validation 
                [field]="inputField" 
                [fieldName]="name" 
                [customWarning]="customWarning" 
                [customError]="customError" 
                [customMess]="customMess"
                [compressed]="compressed"
                [hideErrorMessage]="hideErrorMessage">
            </kraken-field-validation>
            <span class="char-count" *ngIf="showCharCount !compressed"><span [ngClass]="{'counter-error': value?.length > maxChars}">{{value?.length || 0}}</span>/{{maxChars}}</span>
        </div>
        <input
            #inputField="ngModel"
            class="kn-form-control" 
            [ngClass]="{'input-error': 
            inputField.invalid && (inputField.dirty || inputField.touched) ||
            customError,
            'input-warning': customWarning}"
            id="{{textboxId}}"
            [(ngModel)]="value" 
            type="{{type}}"  
            (ngModelChange)="onModelChange($event)" 
            (keyup)="onValidation()"
            name="{{name}}"
            autocomplete="off"
            emailValidation 
            validateEmail="{{emailValidator}}"
            numericOnly
            numericChars="{{numericCharsOnly}}"
            [attr.aria-label]="ariaLabel ? ariaLabel : label ? label : setPlaceholder"
            required="{{setRequired}}"
            [readonly]="setReadOnly"
            [disabled]="setDisabled"
            (focus)="onValidation()"
            (focusout)="onValidation()"
            [placeholder]="setPlaceholder"
            [min]="minNumber === null ? null : minNumber"
            [max]="maxNumber === null ? null : maxNumber"
            [attr.maxlength]="maxLength === 0 ? null : maxLength"
            [attr.minlength]="minLength === 0 ? null : minLength">
            <i 
                *ngIf="inputField.value !== '' && 
                inputField.value != undefined && 
                clearIcon &&
                !password" 
                class="fa fa-solid fa-circle-xmark clear-icon kn-icon" 
                (click)="clear()" 
                aria-hidden="true">
            </i>
            <i 
                *ngIf="password" 
                class="kn-icon password-icon fa-regular" 
                [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword, 'disabled': inputField.value == '' || inputField.value == undefined}"
                (click)="showHidePasswordVal()" 
                aria-hidden="true">
            </i>
        <label *ngIf="!compressed" class="label" [ngClass]="{'input-label-error':
            inputField.invalid && (inputField.dirty || inputField.touched) ||
            customError,
            'input-label-warning': customWarning }">{{label}}<span *ngIf="optional && !setRequired" class="optional-label">(Optional)</span></label>
    </div>
</div>