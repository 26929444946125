<div class="page-container home">
    <div class="section-container home-page">
        <section class="logo">
            <img class="kn-responsive-img hero-img" src="assets/images/KrakenDSM_Homepage.png">
        </section>

        <!-- <section>
            <div class="hero-info">Designing an intuitive and visually uniform enterprise software experience can be a monster. Meet our solution, Kraken DS, the official design system for Lighthouse software application development.</div>
        </section> -->
        <div class="sub-links">
            <div class="kn-tile">
                <div class="kn-row">
                    <div class="kn-col">
                        <div class="kn-headline-24">Get Started</div>
                        <div class="section-info">Get to know the basics of Kraken DS – from the UI foundations, framework and principles.</div>
                        <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/getting-started/design-principles">For Designers</div>
                        <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/getting-started/ui-framework">For Developers</div>
                    </div>
                </div>
            </div>

            <div class="kn-tile">
                <div class="kn-row">
                    <div class="kn-col">
                        <div class="kn-headline-24">Explore</div>
                        <div class="section-info">Explore the different UI elements and usage guidelines.</div>
                        <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/action-button">Components</div>
                        <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/icon-catalog">Icon Catalog</div>
                        <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/style-guide/content-usage">Content</div>
                    </div>
                </div>
            </div>

            <div class="kn-tile">
                <div class="kn-row">
                    <div class="kn-col">
                        <div class="kn-headline-24">Release Notes</div>
                        <div class="section-info">See the latest additions, enhancements and fixes to the Kraken DS.</div>
                        <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/support/release-notes">Learn more</div>
                    </div>
                </div>
            </div>
        
            <!-- <div class="section-col">
                <div class="section-title">Get Started</div>
                <div class="section-info">Get to know the basics of Kraken DS – from the UI foundations, framework and principles.</div>
                <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/getting-started/design-principles">For Designers</div>
                <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/getting-started/ui-framework">For Developers</div>
            </div>

            <div class="section-col">
                <div class="section-title">Explore</div>
                <div class="section-info">Explore the different UI elements and usage guidelines.</div>
                <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/action-button">Components</div>
                <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/icon-catalog">Icon Catalog</div>
                <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/style-guide/content-usage">Content</div>
            </div>

            <div class="section-col">
                <div class="section-title">Release Notes</div>
                <div class="section-info">See the latest additions, enhancements and fixes to the Kraken DS.</div>
                <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/support/release-notes">Learn more</div>
            </div> -->
        </div>
    </div>
</div>