<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text"><span class="kn-bold">Text box</span> fields allow users to input, edit, and select text into a UI. Text fields validate input, provide suggestions, and help users fix errors. The text field wrapper component is a complete form control which includes a label, input and helper text. </div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Text box with label: </span> The text box allows users to input custom text entries with a keyboard. Various options can be shown with the field to communicate the input requirements.</div>
            <div class="text"><span class="kn-bold">2.	Text box without label </span></div>
            <div class="text"><span class="kn-bold">3.	Inline text box: </span> Inline text boxes allow users to conveniently make edits to certain fields within a grid. To determine whether a field supports inline editing, hover over the data grid cell and look for the edit icon.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Enabled</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_02.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Without label </span> </div>
            <div class="text"><span class="kn-bold">2.	With optional label </span> </div>
            <div class="text"><span class="kn-bold">3.	With placeholder text </span> </div>
            <div class="text"><span class="kn-bold">4.	With character counter </span> </div>
            <div class="text"><span class="kn-bold">5.	With trailing icon </span> </div>
            <div class="text"><span class="kn-bold">6.	With value </span> </div>
            <div class="text"><span class="kn-bold">7.	With value overflow </span> </div>
            <div class="text"><span class="kn-bold">8.	With tooltip </span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Focused</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_03.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Without label </span> </div>
            <div class="text"><span class="kn-bold">2.	With optional label </span> </div>
            <div class="text"><span class="kn-bold">3.	With placeholder text </span> </div>
            <div class="text"><span class="kn-bold">4.	With character counter </span> </div>
            <div class="text"><span class="kn-bold">5.	With trailing icon </span> </div>
            <div class="text"><span class="kn-bold">6.	With value </span> </div>
            <div class="text"><span class="kn-bold">7.	With value overflow </span> </div>
            <div class="text"><span class="kn-bold">8.	With tooltip </span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Read-Only</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_04.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Without label </span> </div>
            <div class="text"><span class="kn-bold">2.	With label </span> </div>
            <div class="text"><span class="kn-bold">3.	With optional label </span> </div>
            <div class="text"><span class="kn-bold">4.	With trailing icon </span> </div>
            <div class="text"><span class="kn-bold">5.	With value overflow </span> </div>
            <div class="text"><span class="kn-bold">6.	With tooltip </span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Disabled</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_05.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Without label </span> </div>
            <div class="text"><span class="kn-bold">2.	With label </span> </div>
            <div class="text"><span class="kn-bold">3.	With placeholder text </span> </div>
            <div class="text"><span class="kn-bold">4.	With trailing icon </span> </div>
            <div class="text"><span class="kn-bold">5.	With value </span> </div>
            <div class="text"><span class="kn-bold">6.	With value overflow </span> </div>
            <div class="text"><span class="kn-bold">7.	With tooltip </span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Error</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_06.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Error without label </span> </div>
            <div class="text"><span class="kn-bold">2.	Error with label </span> </div>
            <div class="text"><span class="kn-bold">3.	Error with trailing icon </span> </div>
            <div class="text"><span class="kn-bold">4.	Error with value </span> </div>
            <div class="text"><span class="kn-bold">5.	Error with value overflow </span> </div>
            <div class="text"><span class="kn-bold">6.	Error with trailing icon </span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Warning</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_07.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Warning without label </span> </div>
            <div class="text"><span class="kn-bold">2.	Warning with label </span> </div>
            <div class="text"><span class="kn-bold">3.	Warning with trailing icon </span> </div>
            <div class="text"><span class="kn-bold">4.	Warning with value </span> </div>
            <div class="text"><span class="kn-bold">5.	Warning with value overflow </span> </div>
            <div class="text"><span class="kn-bold">6.	Warning with trailing icon </span> </div>
        </section>
    
        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_08.png">

        <section>
            <section>
                <div class="text"><span class="kn-bold">A.	Container: </span>Containers improve the discoverability of text fields by creating contrast between the text field and surrounding content.</div>
                <div class="text"><span class="kn-bold">B.	Label text: </span>Label text is used to inform users as to what information is requested for a text field.</div>
                <div class="text"><span class="kn-bold">C.	Placeholder text (optional): </span>Placeholder text conveys additional guidance about the input field. The placeholder text is displayed inside the text field.</div>
                <div class="text"><span class="kn-bold">D.	Optional label </span></div>
                <div class="text"><span class="kn-bold">E.	Value: </span>The input text is text entered by the user. The input text is displayed inside the text field.</div>
                <div class="text"><span class="kn-bold">F.	Character count: </span>The character count is to be displayed if there is a character or word limit. It displays the ratio of characters used out of the total character limit.</div>
                <div class="text"><span class="kn-bold">G.	Error/warning icon: </span>Displaying an error/warning icon in association with text makes error/warning states clear for colorblind users.</div>
                <div class="text"><span class="kn-bold">H.	Error/warning text: </span>When an error or warning occurs, error/warning messages are displayed below the text box container.</div>
                <div class="text"><span class="kn-bold">I.	Trailing icon: </span>Text box icons can describe valid input methods and can provide affordances to access additional information (such as clearing the content of a field)</div>
                <div class="text"><span class="kn-bold">J.	Information icon </span></div>
            </section>
        </section> 
   
            
        <section>
            <div class="kn-headline-24-med">Assistive elements</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_09.png">

        <section>
            <div class="text"><span class="kn-bold">1. Labels</span> </div>
            <div class="text">Use the label to explain the purpose of the text box field and type of value that should be entered by the user. Labels should always be visible and positioned above the container, consistent with label positioning of other components in your form.</div>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">2. Required or optional</span> </div>
            <div class="text">All text boxes are required by default and do not require displaying a required attribute in the label. However, when displaying an optional field, they are denoted with text added to the end of the label — “(optional)” —. When using an inline or text box without a label, the "(optional)"" should be denoted within the placeholder text.</div>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">3. Placeholder text</span> </div>
            <div class="text">If the value needs to be provided in a specific format (e.g.: name@domain.com), use placeholder text inside the text box control to describe how the information should be entered, not what the field represents.</div>

            <div class="text">Placeholder text should not be editable and should disappear once the user focuses on the text box and begins to type. If the value entered by the user is removed and the text box loses input focus, the hint text should be restored.</div>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">4. Character count</span> </div>
            <div class="text">Text boxes can display a character count indicator when the length of the text entry needs to be kept under a predefined value. It displays the ratio of characters used and the total character limit.</div>
        </section> 

        <section>
            <div class="text"><span class="kn-bold">5. Error/warning text</span> </div>
            <div class="text">A text box can be marked as having an error to show that a value needs to be entered in order to move forward or that a value that was entered is invalid. </div>
        </section> 

        <section>
            <div class="kn-headline-24-med">Icons</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_15.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Password input</div>

            <div class="text">A password text box is a text input that conceals the characters typed into it for the purpose of privacy. A password box looks like a text box, except that it renders placeholder characters in place of the text that has been entered. By default, the password box allows for the user to view their passwords by selecting a reveal icon button.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Enabled with no masked value </span> </div>   
            <div class="text"><span class="kn-bold">2.	Focused with no masked value </span> </div>
            <div class="text"><span class="kn-bold">3.	Focused with masked value </span> </div>  
            <div class="text"><span class="kn-bold">4.	Enabled with masked value </span> </div>
            <div class="text"><span class="kn-bold">5.	Enabled with masked value trailing icon hover </span> </div>
            <div class="text"><span class="kn-bold">6.	Enabled with un-masked value </span> </div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_16.png">
            </section>

            <div class="kn-subtitle-16">Trailing icon</div>

            <div class="text">The “clear all” button lets users quickly delete all text that has been entered and appears only when input text is present.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Focused without value </span> </div>
            <div class="text"><span class="kn-bold">2.	Focused with value </span> </div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_17.png">
            </section>

            <div class="kn-subtitle-16">Contextual help</div>

            <div class="text">A tooltip invoked via hover of the text box in-line information icon button can be used to display supplementary information to the user.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Enabled state </span> </div>
            <div class="text"><span class="kn-bold">2.	Enabled state with hover on information icon button </span> </div>
        </section>
        
        <section>
            <div class="kn-headline-24-med">Guidelines</div>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_10.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Size</div>

                <div class="text">Text boxes come in two different sizes: default and compressed. The compressed size is reserved for compressed grid rows where users can scan and take action on large amounts of information.</div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">1.	Default text box: </span> 40px height, 14px text </div>
                <div class="text"><span class="kn-bold">2.  Compressed text box: </span> 30px height, 12px text</div>
            </section>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_11.png">
            </section>

            <div class="kn-subtitle-16">Display width</div>

            <div class="text">In general, the display width of the text box should reflect the approximate maximum character length for what would be considered a standard representative value entered by the user. When the entered value exceeds the display width of the text box, it should display with truncation (ellipses).</div>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_12.png">
            </section>
        </section>

        <section>            
            <div class="kn-subtitle-16">Modal</div>

            <div class="text">The standard width of text boxes displayed in the body of modal two-column input fields is 264px, while single column width spans 576px.</div>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_13.png">
            </section>        
        </section>

        <section>
            <div class="kn-subtitle-16">Data grid</div>

            <div class="text">Text boxes displayed inline of data grids range in width from 154px to 204px for default size.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Rounded corners</div>

            <div class="text">Text box containers are displayed with a 4px rounded corner.</div>
        </section>

        <section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_14.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Text overflow</div>

            <div class="text">When the length of text is bounded by a predefined value, a character counter indicator is displayed. The character counter is represented by the ratio of characters used out of the total character limit.</div>
        </section>

            <section>
                <div class="text"><span class="kn-bold"> 1.  </span> typing and the length of the text entry reaches the predefined value, the user is prevented from typing additional characters.</div>
            </section>

            <section>    
                <div class="text"><span class="kn-bold">2.  </span> pasting a value greater than the maximum characters allowed, the character counter displays the characters used value in bold red to subtly indicate an error that needs to be corrected before moving forward.</div>
            </section>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_18.png">
            
            <section>
                <div class="kn-subtitle-16">Overuse of hints</div>

                <div class="text">Use hints sparingly and only when additional explanation is needed to reduce visual clutter in busy forms and clarify what controls still need input. Do not use hints to simply restate the label.</div>
            </section>
        </section>

        <section>           
            <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_19.png">
            
            <section>
                <div class="kn-subtitle-16">Multi-line input</div>

                <div class="text">Do not use the text box when the expected input represents a significantly longer character count than the field display width. In this case, consider using the text area form element instead.</div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_20.png">
            
            <section>
                <div class="kn-subtitle-16">Enforcing rigid formatting rules</div>

                <div class="text">Before adding validation rules, consider first if data requirements may change in the future or may vary by geography. Try to handle all the most common text formats rather than enforcing rigid formatting rules. For example, phone numbers, addresses, and identifiers can be entered with optional spaces and punctuation, and capitalization often does.</div>
            </section>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/textbox/KrakenDSM_Components_Textbox_21.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Write error text that shows a solution</div>
            
                <div class="text">Write error messaging in a human-centered way by guiding a user and showing them a solution. Don’t display ambiguous error messages.</div>
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Input constraints</div>

            <div class="text">Text boxes can accept any type of input or be constrained with additional validation and formatting rules, such as restricting the input to only specific types of characters (such as numbers) or setting a character limit.</div>
        </section>    

        <section>
            <div class="text"><span class="kn-bold">When using input constraints:</span></div>

            <div class="text"> 1.  Apply them only when underlying data is well-defined and unlikely to change.</div>                <div class="text">2.  Call out what the constraints are using instructional text or hint text (for example, “Maximum 12 characters”.)</div>
        </section>
    </div>
</div>