<div 
    class="kn-input-message" 
    *ngIf="field && 
        field.invalid && 
        (field.dirty || 
        field.touched || 
        field.errors?.whitespace) || 
        customError &&
        !hideErrorMessage" 
    [ngClass]="{
        'error': errorPlacementFloating, 
        'static-error': !errorPlacementFloating 
    }">
        <div *ngIf="field.errors?.required"><i class="fas fa-exclamation-triangle"></i> <span *ngIf="customMess">{{customMess}}</span> <span *ngIf="!customMess">Field is required</span></div>
        <div *ngIf="field.errors?.maxlength"><i class="fas fa-exclamation-triangle"></i><span *ngIf="customMess">{{customMess}}</span> <span *ngIf="!customMess">Exeeded maximum number of Characters</span></div>
        <div *ngIf="customError"><i class="fas fa-exclamation-triangle"></i>{{customMess}}</div>
</div>

<div class="kn-input-message" [ngClass]="{'warning': customWarning}" *ngIf="customWarning && !hideErrorMessage">
    <div *ngIf="customWarning"><i class="fas fa-exclamation-triangle"></i> <span class="kn-input-message-text">{{customMess}}</span></div>
</div>