import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-heading-subheading',
  templateUrl: './heading-subheading.component.html',
  styleUrls: ['./heading-subheading.component.sass']
})
export class HeadingSubheadingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
