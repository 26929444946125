import { NgModule } from '@angular/core';
import { KrakenDatePickerComponent } from './kraken-date-picker.component';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    KrakenDatePickerComponent
  ],
  imports: [
    DatePickerModule,
    NumericTextBoxModule,
    CommonModule
  ],
  exports: [
    KrakenDatePickerComponent
  ]
})
export class KrakenDatePickerModule { }
