import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import  packageInfo from '../../../../../kraken-dropdown/package.json';

export class ItemObj {
  id: number;
  country: string;
  code: string;
}

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.sass']
})
export class DropdownComponent implements OnInit {
  public dropdownTemplate = false;
  public dropdownValue: any = "";
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public btnClickEvent;
  public componentId = 'dropdownComponentId';
  public webSetProperties;
  public webRemoveProperties;
  public customDropdown;
  public elementTag = 'kn-dropdown';
  public tsDropdownItemsObj;
  public tsDropdownItemsArray;
  public webDropdownItemsObj;
  public webDropdownItemsArray;
  public placeholder = "Choose a country";
  public label = "Country";
  public webClickEvent;
  public optional = false;
  public setCustomMessage = false;
  public addCustomMessage = false;
  public customError = false;
  public customWarning = false;
  public required = false;
  public readOnly = false;
  public disabled = false;
  public showPlaceholder = true;
  public onValueChange = false;
  public dropdownName = 'exampledropdown';
  public customMess = "Custom message";
  public compressed = false;
  public customTemplate = false;
  public templateHtml: string;
  public event1 = true;
  public eventItem: any;
  public dropdownField: any;
  public messageType = '';
  public dropdownCustomWidth = '';
  public dropdownCustomHeight = '';
  public dropdownWidth: string;
  public dropdownRightAligned = false;
  public isDropdownWidthCustom = false;
  public isDropdownHeightCustom = false;
  public tsDropdownDisableObjectItem;
  public tsDropdownDisableArrayItem;
  public tsDropdownDisableArrayItem2;
  public componentVersion = packageInfo.version;
  public initializeComponentWeb: any;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenDropdownModule'};
  public onValidate = false;
  public itemsArray = ['United States', 'Canada', 'German'];
  public items: any = [
    {country:'United States', id: '+0', code: 'US'}, 
    {country:'Canada', id: '+1', code: 'CA'}, 
    {country:'Germany', id: '+49', code: 'DE', disabled: true}
  ];
  public dropdownFields = {text: 'country', value: 'code'};
  public dropdownModel = this.items[1];
  public angularComponentOptions: any = [
    { option: '[dropdownId]=', id: 0, value: '"\'' + this.componentId +'\'"'},
    { option: '[value]=', id: 1, value: '"\'' + this.dropdownValue + '\'"'},
    { option: '[items]=', id: 2, value: '"items"'},
    { option: '[fields]=', id: 3, value: '"fields"'},
    { option: '[(ngModel)]=', id: 3, value: '"dropdownValue"'},
    { option: '[label]=', id: 4, value: '"\''+ this.label +'\'"'},
    { option: '[placeholder]=', id: 5, value: '"\'Choose a country\'"'},
    { option: '[name]=', id: 6, value: '"\''+ this.dropdownName +'\'"'}
  ];

  public angularComponentCustomTempOptions: any = [
    { option: '[dropdownId]=', id: 0, value: '"\'' + this.componentId +'\'"'},
    { option: '[value]=', id: 1, value: '"\'' + this.dropdownValue + '\'"'},
    { option: '[label]=', id: 2, value: '"\''+ this.label +'\'"'},
    { option: '[(ngModel)]=', id: 3, value: '"dropdownValue"'},
    { option: '[placeholder]=', id: 3, value: '"\'Choose a country\'"'},
    { option: '[template]=', id: 4, value: '"\'' + this.label +'\'"'}
  ];

  public webComponentOptions: any = [
    { option: 'dropdown-id=', id: 0, value:  '"'+ this.componentId +'"'},
    { option: 'label=', id: 1, value: '"'+ this.label +'"'},
    { option: 'value=', id: 2, value:  '"' + this.dropdownValue +'"'},
    { option: 'placeholder=', id: 3, value: '"Choose a country"'},
    { option: 'name=', id: 4, value: '"'+ this.dropdownName +'"'}
  ];

  public itemsDisabled = [{ "value": false}, { "value": true}, { "value": true}];

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    //this.dropdownValue = this.items[0].country;
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    this.initializeComponentWeb = `
let dropdownElm = document.querySelector("#${this.componentId}");
    `
    this.tsDropdownItemsObj = 
  `
public fields = {text: 'country', value: 'code'};

public items: any = [
  {country:'United States', id: '+0', code: 'US'}, 
  {country:'Canada', id: '+1', code: 'CA'}, 
  {country:'Germany', id: '+49', code: 'DE', disabled: true}
];
` 
    
    this.tsDropdownItemsArray =
`
public items = ['Untied States', 'Canada', 'Germany'];\n`

    this.webDropdownItemsArray =
`
let dropdownItems = ["United States", "Canada", "Germany"];
dropdownElm.items = dropdownItems;
`
    
    this.webDropdownItemsObj = 
`
let dropdownItemsObj = 
[
  {country:'United States', id: '+0', code: 'US'}, 
  {country:'Canada', id: '+1', code: 'CA'}, 
  {country:'Germany', id: '+49', code: 'DE'}
]

const fields = {text: "country", value: "code"};
dropdownElm.fields = fields;
dropdownElm.items = dropdownItemsObj;
`

this.webClickEvent = 
`
dropdownElm.addEventListener('onSelect', (event) => console.log(event.detail));
`

this.webSetProperties = 
`
dropdownElm.customError = true;
`
this.webRemoveProperties = 
`
dropdownElm.customError = false;
`

this.tsDropdownDisableObjectItem =
`\nthis.items[0].disabled = true;`

  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  valueChange(event) {
    if(this.customDropdown) {
      this.eventItem = new ItemObj;
      this.eventItem.id = event.id;
      this.eventItem.country = event.country;
      this.eventItem.code = event.code;
    } else {
      this.eventItem = event
    }

    
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[value]='));
    //angular
    this.angularComponentOptions[index].value = '"\'' + this.dropdownValue +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'value='));
    this.webComponentOptions[indexWeb].value = '"' + this.dropdownValue + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  labelText(event) {
    this.label = event;
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[label]='));
    this.angularComponentOptions[index].option = '[label]=';
    this.angularComponentOptions[index].value = '"\'' + event + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'label='));
    this.webComponentOptions[indexWeb].option = 'label=';
    this.webComponentOptions[indexWeb].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  customDrpdnWidth(event) {
    if(event) {
      this.controlEvent(true, '[dropdownCustomWidth]=', '' + this.dropdownCustomWidth +'', true);  
    } else{
      this.controlEvent(false, '[dropdownCustomWidth]=', '' + this.dropdownCustomWidth +'', true);
      this.dropdownCustomWidth = '';
    }
  }

  customDrpdnWidthValue(event) {
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[dropdownCustomWidth]='));
    this.angularComponentOptions[index].option = '[dropdownCustomWidth]=';
    this.angularComponentOptions[index].value = '"\'' + event + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'dropdown-custom-width='));
    this.webComponentOptions[indexWeb].option = 'dropdown-custom-width=';
    this.webComponentOptions[indexWeb].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  customDrpdnHeight(event) {
    if(event) {
      this.controlEvent(true, '[dropdownCustomHeight]=', '' + this.dropdownCustomHeight +'', true);  
    } else{
      this.controlEvent(false, '[dropdownCustomHeight]=', '' + this.dropdownCustomHeight +'', true);
      this.dropdownCustomHeight = '';
    }
  }

  customDrpdnHeightValue(event) {
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[dropdownCustomHeight]='));
    this.angularComponentOptions[index].option = '[dropdownCustomHeight]=';
    this.angularComponentOptions[index].value = '"\'' + event + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'dropdown-custom-height='));
    this.webComponentOptions[indexWeb].option = 'dropdown-custom-height=';
    this.webComponentOptions[indexWeb].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  customMessToggle(event) {
    this.addCustomMessage = event;
    if(!event) {
      this.removeCustomMessage();
    }
  }

  customMessage(event) {
    if(event.value == 'radioError') {
      this.customError = true;
      this.customWarning = false;
      this.controlEvent(false, '[customWarning]=', true, true);  
      this.controlEvent(false, '[customMess]=', '' + this.customMess +'', true);
      this.controlEvent(event, '[customError]=', true, true); 
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    } else if (event.value == 'radioWarning'){
      this.customError = false;
      this.customWarning = true;
      this.controlEvent(false, '[customError]=', true, true); 
      this.controlEvent(false, '[customMess]=', '' + this.customMess +'', true);
      this.controlEvent(event, '[customWarning]=', true, true); 
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    } else {
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    }
  }

  removeCustomMessage() {
    this.messageType = "";
    this.customError = false;
    this.customWarning = false;
    this.controlEvent(false, '[customError]=', true, true); 
    this.controlEvent(false, '[customWarning]=', true, true);  
    this.controlEvent(false, '[customMess]=', true, true); 
  }

  message(event) {
    this.customMess = event;
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[customMess]='));
    //angular
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'custom-mess='));
    this.webComponentOptions[indexWeb].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  showHidePlaceholder(event) {
    if(event) {
      this.controlEvent(event, '[placeholder]=', this.placeholder, true); 
      const placeholderText = 'Choose a country';
      this.placeholderValue(placeholderText);
    }else {
      this.placeholder = '';
      this.controlEvent(event, '[placeholder]=', this.placeholder, true); 
    }
  }

  placeholderValue(event) {
    this.placeholder = event;
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[placeholder]='));
    //angular
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'placeholder='));
    this.webComponentOptions[indexWeb].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  customTemplateEvent(event) {
    this.customTemplate = !this.customTemplate;
    this.dropdownTemplate = !this.dropdownTemplate;
    if(event) {
      this.controlEvent(true, '[template]=', 'true', 'bool');
      //custom template
      this.templateHtml = 
`<ng-template  #customTemplate let-data="data">
    <div class="country-item"><span class="country-code">{{data.id}}</span><span class="country">{{data.country}}</span></div>
</ng-template>`
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    } else {
      this.controlEvent(false, '[template]=', 'true', 'bool');
      this.templateHtml = "";
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    }
  }

  buttonIcon(event) {
    this.dropdownValue = event.country;
  }

}
