import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import  packageInfo from '../../../../../kraken-theme/package.json';
import  dateRangePackageInfo from '../../../../../kraken-date-range-picker/package.json';

@Component({
  selector: 'app-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.sass']
})
export class DateRangePickerComponent implements  OnInit{
  public angularComponentRef: any;
  public webContainer;
  public elementTag = 'kn-date-range-picker';
  public webComponentRef;
  public angularContainer;
  public today: Date = new Date();
  public currentYear: number = this.today.getFullYear();
  public currentMonth: number = this.today.getMonth();
  public currentDay: number = this.today.getDate();
  public minDate;//Object = new Date(this.currentYear, this.currentMonth, 15);
  public maxDate;//Object =  new Date(this.currentYear, this.currentMonth+1, 28);
  public placeholder;
  public value =  ""
  public label = "This is a label";
  public compressed = false;
  public onValueChange = false;
  public onValidate = false;
  public event1 = true;
  public event2 = true;
  public isValid = true;
  public required = false;
  public disabled = false;
  public readOnly = false;
  public onSelectItems: any = [];
  public currentDateFormat;
  public dateFormat;
  public dateFormatObj = [
    {format: 'MM/dd/yyyy', display: 'mm/dd/yyyy'},
    {format: 'dd/MM/yyyy', display: 'dd/mm/yyyy'},
    {format: 'yyyy/MM/dd', display: 'yyyy/mm/dd'}
  ];
  public dateFormatFields = {text: 'display', value: 'format'};
  public componentVersion = dateRangePackageInfo.version;
  public component = {
    name: dateRangePackageInfo.name, 
    version: dateRangePackageInfo.version,
    peerDependencies: [dateRangePackageInfo.peerDependencies], 
    module: 'KrakenDateRangePickerModule'};

  public angularComponentOptions: any = [
    { option: '[componentId]=', id: 0, value: '"\'exampleDateRange\'"'},
    { option: '[label]=', id: 0, value: '"\'' + this.label +'\'"'},
  ]

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"exampleDateRange"'},
    { option: 'label=', id: 0, value: '"'+ this.label +'"'},
  ];

  public jsCode = 
  `let dateRange = document.querySelector('#exampleDateRange');
dateRange.value = '03/15/2024 - 04/25/2024';
dateRange.min = '2/20/2024';
dateRange.max = '2/20/2024';`

public tsCode = 
`
public value: string;

constructor() {}

ngOnInit() {
  this.value = '03/15/2024 - 04/25/2024';
}`

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }
    


  ngOnInit() {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

    //web template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);

    //testing only
    // document.addEventListener('click', function(e) {
    //   console.log('test')
    // })

    this.currentDateFormat = this.dateFormatObj[0].display;
    this.dateFormat = this.dateFormatObj[0].format;

    //this.value = '03/15/2024 - 04/25/2024';
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  updateGridTemplate() {

  }

  placeholderEvent(event) {

  }

  minDateEvent(event) {
    this.minDate = this.formatDate(event.value);
    let index = this.angularComponentOptions.findIndex((obj => obj.option == '[min]='));
    if(index == -1) {
      this.controlEvent(true, '[min]=', 'true', true); 
      index = this.angularComponentOptions.findIndex((obj => obj.option == '[min]='));
    } 

    //angular
    this.angularComponentOptions[index].value = '"\'' + this.minDate +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'min='));
    this.webComponentOptions[webIndex].value = '"' + this.minDate + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  maxDateEvent(event) {
    this.maxDate = this.formatDate(event.value);
    let index = this.angularComponentOptions.findIndex((obj => obj.option == '[max]='));
    if(index == -1) {
      this.controlEvent(true, '[max]=', 'true', true); 
      index = this.angularComponentOptions.findIndex((obj => obj.option == '[max]='));
    } 

    //angular
    this.angularComponentOptions[index].value = '"\'' + this.maxDate +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'max='));
    this.webComponentOptions[webIndex].value = '"' + this.maxDate + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  valueChangeEvent(event) {
    this.onSelectItems.daySpan = event.daySpan;
    this.onSelectItems.endDate = event.endDate;
    this.onSelectItems.startDate = event.startDate;
    this.onSelectItems.text = event.text;
    this.onSelectItems.value = event.value;
    //console.log(event);
  }

  labelEvent(event) {
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[label]='));
    //angular
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'label='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  valueEvent(event) {
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[value]='));
    if(index == -1) {
      this.controlEvent(true, '[value]=', 'true', true); 
    } 

    //angular
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'value='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  validateEvent(event) {
    this.isValid = event.invalid;
    console.log(event);
  }

  formatDateEvent(event) {
    this.dateFormat = event.format;
    this.currentDateFormat = event.display;
    let index = this.angularComponentOptions.findIndex((obj => obj.option == '[dateFormat]='));
    if(index == -1) {
      this.controlEvent(true, '[dateFormat]=', 'true', true); 
      index = this.angularComponentOptions.findIndex((obj => obj.option == '[dateFormat]='));
    } 

    //angular
    this.angularComponentOptions[index].value = '"\'' + event.format +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'date-format='));
    this.webComponentOptions[webIndex].value = '"' + event.format + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  formatDate(dateStr) {
    // Parse the input date string
    const date = new Date(dateStr);
  
    // Helper function to add leading zero
    const pad = (num) => num.toString().padStart(2, '0');
  
    // Extract date components
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1); // getMonth() returns 0-11
    const day = pad(date.getDate());
  
    // Format the date based on the specified format
    switch (this.dateFormat) {
      case 'MM/dd/yyyy':
        return `${month}/${day}/${year}`;
      case 'dd/MM/yyyy':
        return `${day}/${month}/${year}`;
      case 'yyyy/MM/dd':
        return `${year}/${month}/${day}`;
      default:
        return "Invalid format specified";
    }
  }

}
