import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';

@Component({
  selector: 'dropdown-accessibility',
  templateUrl: './dropdown-accessibility.component.html',
  styleUrls: ['./dropdown-accessibility.component.sass']
})
export class DropdownAccessibilityComponent implements OnInit {
  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;
  
  constructor(private templateService: TemplateUpdateService) { }

  ngOnInit(): void {
  }

}
