import { 
  Component, 
  ElementRef, 
  Input, 
  QueryList, 
  ContentChildren, 
  Output, 
  EventEmitter, 
  AfterViewInit, 
  OnInit,
  DoCheck,
  OnChanges,
  ViewChild } from '@angular/core';
import { KrakenTabComponent } from './kraken-tab/kraken-tab.component';

@Component({
  selector: 'kn-tabs',
  templateUrl: './kraken-tabs.component.html',
  styleUrls: ['./kraken-tabs.component.sass']
})
export class KrakenTabsComponent implements AfterViewInit, OnInit, DoCheck, OnChanges {
  @Input() activeTab = 0;
  @Input() id: string;
  @Input() disabled: boolean;
  @Input() dropdownCustomWidth: string;
  @Input() dropdownCustomHeight: string;
  @Input() dropdownRightAligned = false;
  @Input() width: string;
  @Input() minWidth: string;
  @Input() maxWidth: string;
  @Input() subTabs = false;
  @Input() web = false;
  @Input() responsiveTabs = false;
  @Output() onSelect = new EventEmitter<any>();
  @ContentChildren(KrakenTabComponent) tabs: QueryList<KrakenTabComponent>;

  public tabsContainer: any;
  public numOfTabs: any;
  public tabsUl: any;
  public tabMenu: any;
  public moreBtn: any;
  public tabMenuContainer: any;
  public stopWidth = 0;
  public hiddenItems = [];
  public topPos: string;
  public drpdnWidth = "max-content";
  public drpdnHeight = 'auto';
  
  public dropdownPosLeft: any;
  public tabLinks;
  public isHiddenTabSelected = false;
  public isHiddenTabDisabled = false;
  public currentContainerWidth: any;
  public timeOut = 0;

  @ViewChild('knTabs') knTabs: any;

  constructor(public element: ElementRef) { }

  ngDoCheck() {
    
    if(this.tabsUl && this.responsiveTabs) {
      const containerSize = this.tabsContainer.offsetWidth;
      if(containerSize != this.currentContainerWidth) {
        this.resize();
      }
    }
  }

  ngOnChanges(changes) {
    if(changes.activeTab) {
      if(!changes.activeTab.firstChange) {
        this.activeTab = changes.activeTab.currentValue;
        this.tabs.forEach((t, i) => {
          if(i == this.activeTab) {
            this.selectTab(t);
          }
        });
      }
    }
  }

  ngOnInit() {
    this.id = this.id ? this.id : 'knTabs_' + Math.floor(Math.random()*90000) + 10000;
  }

  ngAfterViewInit() {
    //slight time out needed for web component to register the dom elements :/
    this.timeOut = this.web ? 500 : 0;
    
    setTimeout(() => {
      this.tabsContainer = this.element.nativeElement.querySelector('#'+ this.id);
      this.tabsUl = this.element.nativeElement.querySelector('#'+ this.id + ' > .kn-tab-header > .kn-tabs');
      this.numOfTabs = this.element.nativeElement.querySelectorAll('#'+ this.id + ' > .kn-tab-header > .kn-tabs .tab-links');
      this.tabLinks = this.element.nativeElement.querySelectorAll('#'+ this.id + ' > .kn-tab-header > .kn-tabs > .tab-links > .link');

      //tab overflow menu container
      this.tabMenuContainer = this.element.nativeElement.querySelector('#'+ this.id + ' > .kn-tab-header > .kn-tabs > .kn-tab-menu-container');

      //tab overflow event 
      window.addEventListener('resize', () => {
        setTimeout(() => {
          this.resize();   
        }, 100);      
      });

      //wait for tabs to load completely before checking tab overflow
      window.addEventListener('load', () => {
        this.resize();  
      });

      // if there is no active tab set, activate the first
      this.tabs.forEach((t, i) => {
        t.id = i;
        if(i == this.activeTab) {
          this.selectTab(t);
        }
      });
    }, this.timeOut);
  }

  itemListEventListener() {
    const itemList = Array.from(this.hiddenItems);
    let index = 0;
    itemList.forEach(i => {
      index = index + 1;
      //i.classList.remove('focus');
      i.setAttribute('tabindex', '' + index + '');
      i.addEventListener('keydown', (e) => {
        this.navigateItemList(e);
      });
    });
  }

  navigateItemList(e){
    const nextElm = (e.currentTarget.nextElementSibling as  HTMLElement);
    const prevElm = (e.currentTarget.previousElementSibling as  HTMLElement);
    const itemList = Array.from(this.hiddenItems);

    if(e.key == "ArrowDown" && nextElm) {
      nextElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });

      nextElm.classList.add('focus');
    } 

    if(e.key == "ArrowUp" && prevElm) {
      prevElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });
      prevElm.classList.add('focus');
    }

    if(e.key == "Enter") {
      e.currentTarget.click();
      e.stopImmediatePropagation();
    }
    e.preventDefault();
  }

  selectTab(tab: KrakenTabComponent){
    if(tab) {
    // deactivate all tabs
    this.tabs.toArray().forEach(tab => tab.active = false);

    // activate the tab the user has clicked on.
      tab.active = true;
    }
  }

  tabClick(tab: any, index, hidden) {
    tab['index'] = index;
    //emit selection
    this.onSelect.emit(tab);
    if(tab) {
      // deactivate all tabs
      this.tabs.toArray().forEach(tab => tab.active = false);

      // activate the tab the user has clicked on.
      tab.active = true;

      //remove all active tab classes if hidden tab is clicked
      if(hidden) {
        this.isHiddenTabSelected = true;
        const hiddenTab: any = this.hiddenItems.filter(i => i === tab);
        //since there are two different data sources for tabs and hidden tabs, we need to set both to active 
        hiddenTab[0].active = true;

        this.tabLinks.forEach(e => {
          e.classList.remove('active-link');
        });
      } else {
        this.isHiddenTabSelected = false;
      }
    }
  }

  keydown(e) {
    if(e.key == 'ArrowDown') {
      const items = this.hiddenItems;
      (items[0] as HTMLElement).focus();
    } else {
      return false;
    }
  }

  resize() {
    const containerWidth = this.tabsContainer.offsetWidth; 
    const moreBtnWidth = 70;
    this.stopWidth = 0;
    this.currentContainerWidth = containerWidth;

    if(containerWidth > 0) {
      this.numOfTabs.forEach((item, i) => {
        //add up the tabs
        this.stopWidth = this.stopWidth += item.offsetWidth;

        if(containerWidth - moreBtnWidth >= this.stopWidth) {
          //unhide tab
          item.classList.remove('hide');

          //check if item already exists as a hidden item
          if(this.containsObject(this.tabs.toArray()[i], this.hiddenItems)) {
              if(item.children[0].classList.contains('active-link')) {
                //remove active flag from the overflow menu tab
                this.isHiddenTabSelected = false;
              }
              //remove tab from menu
              const index = this.hiddenItems.findIndex(z => z.id === this.tabs.toArray()[i].id);
              this.hiddenItems.splice(index, 1);
          }
        
        } else {
          //hide tab
          item.classList.add('hide');
          // //check if item already exists as a hidden item
          if(!this.containsObject(this.tabs.toArray()[i], this.hiddenItems)) {
            this.hiddenItems.push(this.tabs.toArray()[i]);
          } 

          if(item.children[0].classList.contains('active-link')) {
            //remove active flag from the overflow menu tab
            this.isHiddenTabSelected = true;
          }
        }
      });

      this.hiddenItems.sort(function(a, b) { 
        return a.id - b.id
      });

      if(!this.hiddenItems.length) {
        //hide the overflow menu
        this.tabMenuContainer.classList.add('hide');
      }
      else {  
        //show the overflow menu and 
        this.tabMenuContainer.classList.remove('hide');
      }
    }
  }

  containsObject(obj, list) {
    let i;
    for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
            return true;
        }
    }

    return false;
  }
}
