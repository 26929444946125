import { Component } from '@angular/core';

@Component({
  selector: 'spreadsheet-specs',
  templateUrl: './spreadsheet-specs.component.html',
  styleUrls: ['./spreadsheet-specs.component.sass']
})
export class SpreadsheetSpecsComponent {
  public component = window.location.protocol + '//' + window.location.host + '/design-specs-measure/spreadsheet/';
  
  clickEvent(route) {
    if(route == 'light') {
      window.open(this.component, "_blank");
    }
  }
}
