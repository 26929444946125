<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text"><span class="kn-bold">Radio buttons </span> enable the selection of a single option of at least two options from a list. Activation of a radio button therefore rules out the activation of other radio buttons in that group. If you need an unselected state, just add a radio button with a None option. Begin labels with a capital letter.</div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Enabled not selected</span></div>
            <div class="text"><span class="kn-bold">2.	Enabled selected</span></div>
            <div class="text"><span class="kn-bold">3.	Enabled error</span></div>
            <div class="text"><span class="kn-bold">4.	Enabled warning</span></div>
            <div class="text"><span class="kn-bold">5.	Hover not selected</span></div>
            <div class="text"><span class="kn-bold">6.	Hover selected</span></div>
            <div class="text"><span class="kn-bold">7.	Hover error</span></div>
            <div class="text"><span class="kn-bold">8.	Hover warning</span></div>
            <div class="text"><span class="kn-bold">9.	Disabled not selected</span></div>
            <div class="text"><span class="kn-bold">10.	Disabled selected</span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_02.png">

        <section>
            <div class="text"><span class="kn-bold">A.  Group label: </span>Describes the group of options or provides guidance for making a selection.</div>
            <div class="text"><span class="kn-bold">B.	Radio button input: </span>A radio button indicating the appropriate state.</div>
            <div class="text"><span class="kn-bold">C.	Radio button label: </span>Describes the information you want to select or unselect.</div>
            <div class="text"><span class="kn-bold">D.	Container</span></div>
            <div class="text"><span class="kn-bold">E.	Error icon</span></div>
            <div class="text"><span class="kn-bold">F.	Error text</span></div>
        </section>
      
        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_03.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Group labels</div>

                <div class="text">A heading can accompany a group of radio buttons to provide further context or clarity. In some cases, a group of radio buttons may be within a larger group of components that already have a group label.</div>
            </section>
            
            <section>
                <ul class="bullets">
                    <li>Label text should always be uppercase.</li>
                    <li>Use a group label to explain the purpose of the group of radio button options; do not instruct the user to simply “Select one of the following choices”.</li>
                </ul>
            </section>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_04.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Radio button label</div>

                <div class="text">Always pair radio buttons with an adjacent label describing what the radio button selects. As only one radio button can be selected at a time, each choice must have its own item and label.</div>
            </section>
        
            <section>
                <ul class="bullets">
                    <li>Always use clear and concise labels for radio buttons.</li>
                    <li>Labels appear to the right of radio button inputs.</li>
                </ul>
            </section>
        
        </section>

        <section>
            <div class="kn-subtitle-16">Selection area</div>

            <div class="text">Include both the button and the option label in the selection target of the radio button, so the user has a larger target area to interact with.</div>
        </section>


        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_05.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Text overflow</div>

                <div class="text">When a radio button's label is too long for the horizontal space available, it wraps to form another line.</div>
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Radio button group</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Alignment </span> </div>

            <div class="text">Radio button labels are positioned to the right of their inputs, where the radio button inputs are aligned vertically for easier reading.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Ordering </span> </div>

            <div class="text">List the options in a logical order, such as most likely to be selected to least, simplest operation to most complex, or least risk to most. Alphabetical ordering is not recommended because it is language dependent and therefore not localizable.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_06.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Orientation </span> </div>

            <div class="text">Align radio button groups vertically. Horizontal alignment is more difficult to read and localize. When there are multiple sets of option buttons on the form, it can also be challenging to make them align well due to differences in option label lengths. However, there are some scenarios when horizontal alignment may make sense. One example is when you have a set of options that repeat consistently as in a series of yes/no questions.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_07.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Error </span> </div>
 
            <div class="text">Radio button groups can be marked as having an error to show that a selection needs to be made in order to move forward, or that selection that was made is invalid. The error is indicated with an icon and associated text.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_08.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Disabled </span> </div>

            <div class="text">A radio button group in a disabled state shows that a selection exists but is unavailable in that circumstance. This can be used to maintain layout continuity and communicate that an action may become available later.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Option labels </span> </div>

            <div class="text">Focus the option label text on the differences among the options. If all the options have the same introductory text, move that description to the group label.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_09.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">No valid option </span> </div>

            <div class="text">If none of the options is a valid choice, add another option at the bottom of the list to reflect this choice, such as “None” or “Does not apply”.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_10.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Multiple options </span> </div>
  
            <div class="text">If the number of options is five or more, consider using a single-select dropdown list instead.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_11.png">

        <section>
            <div class="kn-subtitle-16">Nesting</div>

            <div class="text">Avoid nesting radio buttons or using radio buttons to select multiple options.</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/radio-button/KrakenDSM_Components_RadioButton_12.png">

        <section>
            <div class="kn-subtitle-16">Performing commands</div>

            <div class="text">Don’t use the selection of a radio button to perform commands, display other windows, or dynamically show or hide other controls related to the selected control, as screen readers cannot detect such events.</div>
        </section>
    </div>
</div>