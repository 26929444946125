<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Heading & Subheading</div>
        
        <section>
            <div class="kn-headline-24-med">Headlines</div>
        </section>
        
        <section>
            <div class="text">Headlines are the largest text on the screen with the greatest emphasis in the visual hierarchy. Headlines name the UI screen, reinforcing the users' understanding of their location within the application. In the typography scale, headlines span from range 1 to 5. The following illustrates the headline typography specifications and contextual reference examples.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/heading subheading/KrakenDSM_StyleGuide_Typography_HeadlineSubheading_01.png">
        </section>
        
        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/heading subheading/KrakenDSM_StyleGuide_Typography_HeadlineSubheading_02.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">HEADLINE - 42</span></div>
            <div class="text">Font size: 42px</div>
            <div class="text">Font weight: Light</div>
            <span class="codesnippet">Class name: <pre><code>.kn-headline-42</code></pre></span>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/heading subheading/KrakenDSM_StyleGuide_Typography_HeadlineSubheading_03.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">HEADLINE - 30</span></div>
            <div class="text">Font size: 30px</div>
            <div class="text">Font weight: Medium</div>
            <span class="codesnippet">Class name: <pre><code>.kn-headline-30</code></pre></span>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/heading subheading/KrakenDSM_StyleGuide_Typography_HeadlineSubheading_04.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">HEADLINE -24</span></div>
            <div class="text">Font size: 24px</div>
            <div class="text">Font weight: Book</div>
            <span class="codesnippet">Class name: <pre><code>.kn-headline-24</code></pre></span>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/heading subheading/KrakenDSM_StyleGuide_Typography_HeadlineSubheading_05.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">HEADLINE - 24 Medium</span></div>
            <div class="text">Font size: 24px</div>
            <div class="text">Font weight: Medium</div>
            <span class="codesnippet">Class name: <pre><code>.kn-headline-24-med</code></pre></span>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/heading subheading/KrakenDSM_StyleGuide_Typography_HeadlineSubheading_06.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">HEADLINE - 24 Heavy</span></div>
            <div class="text">Font size: 24px</div>
            <div class="text">Font weight: Heavy</div>
            <span class="codesnippet">Class name: <pre><code>.kn-headline-24-heavy</code></pre></span>
        </section>


        <section>
            <div class="kn-headline-24-med">Subheading</div>
         </section>

        <section>
            <div class="text">Subheadings, or subtitles, are one step down from Headlines in the typography hierarchy. Still emphasized in their presentation and highly recognizable, the subheading guides the user through the application UI by effectively grouping content. The following illustrates the subtitle typography specifications and contextual reference examples.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/heading subheading/KrakenDSM_StyleGuide_Typography_HeadlineSubheading_07.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">SUBTITLE - 16</span></div>
            <div class="text">Font size: 16px</div>
            <div class="text">Font weight: Medium</div>
            <span class="codesnippet">Class name: <pre><code>.kn-subtitle-16</code></pre></span>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/heading subheading/KrakenDSM_StyleGuide_Typography_HeadlineSubheading_09.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">SUBTITLE - 14</span></div>
            <div class="text">Font size: 14px</div>
            <div class="text">Font weight: Heavy</div>
            <div class="text">Decoration: Uppercase</div>
            <span class="codesnippet">Class name: <pre><code>.kn-subtitle-14</code></pre></span>
        </section>
    </div>
</div>