import { Component, Input, Output, OnInit, EventEmitter, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'kn-date-picker',
  templateUrl: './kraken-date-picker.component.html',
  styles: [
  ]
})
export class KrakenDatePickerComponent {
  @Input() componentId: string;
  @Input() label: string;
  @Input() error = false;
  @Input() min: any;
  @Input() max: any;
  @Input() dateFormat = "MM-dd-yyyy";
  @Input() readonly = false;
  @Input() disable = false;
  @Input() value;
  @Input() compressed = false;
  @Input() required = false;
  @Output() onValueChange = new EventEmitter<any>();
  @Output() onValidate = new EventEmitter<any>();
  
  public input;
  public focused = false;
  public rangeIcon;
  public isOpen = false;
  public invalid = false;
  public errorMessage = "Invalid date";
  public placeholder: string;

  constructor(public elm: ElementRef){}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dateFormat) {
      this.placeholder = this.dateFormat.toLowerCase();
    }
  }

  ngAfterViewInit(): void {
    this.input = this.elm.nativeElement.querySelector('INPUT');
    this.rangeIcon = this.elm.nativeElement.querySelector('.e-range-icon');
    this.placeholder = this.dateFormat.toLowerCase();
  }

  onChange(event) {
    let clearIcon = null;
    if(event.event) {
      if(event.event.srcElement) {
        clearIcon = event.event.srcElement.classList.contains('e-clear-icon');
      }
      if(!this.input.value && clearIcon) {
        this.error = this.required ? true : false;
      }
    } 

    if(this.input.value) {
      this.isValidDate(this.input.value);
    }
    
    this.onValidate.emit({invalid: this.error});
    this.onValueChange.emit(event);
  }

  open(event) {
    this.focused = true;
    this.isOpen = true;
  }

  close(event) {
    this.focused = false;
    this.isOpen = false;
  }

  onFocus(event) {
    this.focused = true;
  }

  blur(event) {
    this.focused = this.isOpen ? true : false;
    this.error = this.required && !this.input.value ? true : false;
    this.errorMessage = this.required && !this.input.value ? 'Field is required' : 'Invalid date';

    if(this.input.value) {
      this.isValidDate(this.input.value);
    }
  }

  isValidDate(dateString) {
    // Initially assume there's an error
    this.error = true;

    // Function to validate the parsed date
    const isValidParsedDate = (year, month, day) => {
        if (year.toString().length !== 4) {
            return false; // Year must have exactly four digits
        }

        const date = new Date(year, month, day);
        return date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
    };

    // Attempt to parse and validate the date according to the provided formats
    const dateParts = dateString.split('/');

    if (dateParts.length === 3) {
        let year, month, day;

        // Try dd/mm/yyyy and mm/dd/yyyy (both have year at the end)
        if (dateParts[2].length === 4) {
            // Assuming mm/dd/yyyy
            month = parseInt(dateParts[0], 10) - 1;
            day = parseInt(dateParts[1], 10);
            year = parseInt(dateParts[2], 10);
            if (isValidParsedDate(year, month, day)) {
                this.error = false;
                return;
            }

            // Reset error for the next format attempt
            this.error = true;

            // Assuming dd/mm/yyyy
            day = parseInt(dateParts[0], 10);
            month = parseInt(dateParts[1], 10) - 1;
            // Year is already parsed
            if (isValidParsedDate(year, month, day)) {
                this.error = false;
                return;
            }
        }

        // Try yyyy/mm/dd
        if (dateParts[0].length === 4) {
            year = parseInt(dateParts[0], 10);
            month = parseInt(dateParts[1], 10) - 1;
            day = parseInt(dateParts[2], 10);
            if (isValidParsedDate(year, month, day)) {
                this.error = false;
                return;
            }
        }
    }
  }
}
