import { Injectable } from '@angular/core';
import  releaseNotesJson from './release-notes.json';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {

  constructor() { }

  releaseNotes() {
    return releaseNotesJson
  }
}