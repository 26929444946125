<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Release Notes</div>
        <section>
            <ng-container *ngFor="let note of releaseNotesdata">
                <div class="release-notes-container">
                    <div class="kn-headline-24 version">{{note.releaseVersion}} - {{note.date}}</div>
                    <ng-container *ngFor="let item of note.notes">
                        <div class="release-note">
                            <div class="kn-subtitle-16 title">{{item.title}}</div>
                            <ng-container *ngFor="let comment of item.comments">
                                <ul class="comment">
                                    <li>
                                        <a *ngIf="comment.link" class="kn-link" [routerLinkActive]="['acc-active-link']" routerLink="{{comment.link}}" [queryParams]="{changelog: comment.changelog}">{{comment.comment}}</a>
                                        <span *ngIf="!comment.link">{{comment.comment}}</span>
                                        <ng-container *ngFor="let bullet of comment.bullets">
                                            <ul class="bullets">
                                                <li class="bullet">
                                                    <a *ngIf="bullet.link" [routerLinkActive]="['acc-active-link']" routerLink="{{bullet.link}}" [queryParams]="{react: bullet.react}" class="kn-link">{{bullet.bullet}}</a>
                                                    <span *ngIf="!bullet.link">{{bullet.bullet}}</span>
                                                </li>
                                            </ul>
                                        </ng-container>
                                    </li>
                                </ul>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </section>
    </div>
</div>