<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text">A <span class="kn-bold">Toggle Switch </span> is a visual toggle control between two mutually exclusive or binary states — on and off. A toggle switch shows that it's on when the accent color is visible and off when the switch appears colorless.</div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/toggleswitch/KrakenDSM_Components_ToggleSwitch_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Enabled not selected</span></div>
            <div class="text"><span class="kn-bold">2.	Enabled selected</span></div>
            <div class="text"><span class="kn-bold">3.	Hover not selected</span></div>
            <div class="text"><span class="kn-bold">4.	Hover selected</span></div>
            <div class="text"><span class="kn-bold">5.	Disabled not selected</span></div>
            <div class="text"><span class="kn-bold">6.	Disabled selected</span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/toggleswitch/KrakenDSM_Components_ToggleSwitch_02.png">

        <section>
            <div class="text"><span class="kn-bold">A.  Handle</span></div>
            <div class="text"><span class="kn-bold">B.	Track</span></div>
            <div class="text"><span class="kn-bold">C.	Toggle switch label</span></div>
            <div class="text"><span class="kn-bold">D.	Container</span></div>
        </section>
    
        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Label</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/toggleswitch/KrakenDSM_Components_ToggleSwitch_03.png">
            </section>

            <section>
                <div class="text">Toggles should always have a label, providing a clear context.</div>
            </section>
            

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/toggleswitch/KrakenDSM_Components_ToggleSwitch_04.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Text overflow</span></div>

                <div class="text">When a toggle label is too long for the horizontal space available, it wraps to form another line.</div>
            </section>


            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/toggleswitch/KrakenDSM_Components_ToggleSwitch_05.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Use sentence case</span></div>

                <div class="text">Labels for toggles are written in sentence case unless they contain words that are branded terms.</div>
            </section>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/toggleswitch/KrakenDSM_Components_ToggleSwitch_06.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Label with description</span></div>
            </section>
        </section>


        <section>
            <div class="kn-subtitle-16">Selection</div>

            <section>
                <div class="text"><span class="kn-bold">Activation of a single setting</span></div>

                <div class="text">Toggles can either be selected or not selected. They cannot be in an indeterminate state. When a toggle represents multiple values that are not identical, the toggle should appear as not selected.</div>
            </section>


            <section>
                <div class="text"><span class="kn-bold">Instantaneous action</span></div>

                <div class="text">Toggle buttons are used for instantaneous actions. Due to this affordance, users should never have to press a button to apply the settings.</div>
            </section>


            <section>
                <div class="text"><span class="kn-bold">Selection area</span></div>

                <div class="text">Make the entire area of the component interactive. Selecting anywhere on the control “toggles” the state. In mobile contexts a directional swipe can also be used.</div>
            </section>
        </section>


        <section>
            <div class="kn-subtitle-16">Triggering additional information</div>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/toggleswitch/KrakenDSM_Components_ToggleSwitch_07.png">
            </section>

            <section>
                <div class="text">Toggles can be used to activate a subset of controls.</div>
            </section>
        </section>


        <section>
            <div class="kn-subtitle-16">Toggle or checkbox?</div>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/toggleswitch/KrakenDSM_Components_ToggleSwitch_08.png">
            </section>

            <section>
                <div class="text">Toggles are best used for communicating activation (e.g., on/off states), while checkboxes are best used for communicating selection (e.g., multiple table rows). Toggles, unlike checkboxes, can’t have an error state.</div>
            </section>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Use for non-binary item selection</div>

            <div class="text">The toggle switch should not be utilized to select items for inclusion from a list. In such instances, a checkbox or radio button is the appropriate component. </div>
        </section>


        <section>
            <div class="kn-subtitle-16">Use for opt-in</div>

            <div class="text">If the user experience calls for the operator to opt in or confirm an intended process to be executed, the appropriate component to use is a checkbox and not the toggle switch.</div>
        </section>
    </div>
</div>