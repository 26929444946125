<app-canvas 
    [theme]="selectedTheme" 
    [ts]="false" 
    [js]="true" 
    [htmlCode]="angularComponentRef.ref"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component"
    [reactUrl]="'https://stackblitz.com/edit/github-rqclvg-vqrfz3?embed=1&file=src%2FApp.js'"
    >
    <div title>Action Button <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-button 
            [buttonId]="buttonId"
            [buttonType]="buttonType" 
            [buttonText]="buttonText" 
            [buttonIconLeft]="iconLeftValue"
            [loading]="buttonLoading"
            [disabled]="buttonDisabled"
            (onSelect)="selectedItem($event)">
        </kn-button>
    </div>
    <div typeScript></div>
    <div javascript>
        <span class="code-comment">//Initialize the component</span>
        {{initializeWebComponent}}
        <span class="code-comment">//Note the addition of id="{{componentId}}" to the component container. This is needed for adding and removing properties and event listeners</span>
        <span class="code-comment">//All properties with a boolean value needs to be set in code like this</span>
        {{webSetProperties}}
        <span class="code-comment">//Remove a boolean property like this</span>
        {{webRemoveProperties}}
        <span class="code-comment">//listening for click events</span>
        {{webClickEvent}}
    </div>

    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">btnClick():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">id:</span> "{{buttonId}}"</div>
                    <div class="obj-item"><span class="label">text:</span> "{{buttonText}}"</div>
                    <div class="obj-item"><span class="label">type:</span> "{{buttonType}}"</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-input">
            <kn-textbox [label]="'Button label'" [value]="buttonText" (onValueChange)="labelText($event)" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="action-input">
            <kn-dropdown 
                #knInput 
                [label]="'Button Variant'" 
                [value]="selectedButton" 
                [items]="buttonTypes" 
                [fields]="buttonTypeFields" 
                (onSelect)="valueChange($event)"> 
            </kn-dropdown>
        </div>

        <div class="section-title">Modifiers</div>

        <!--Add left side icon-->
        <div class="item-row">
            <kn-toggle 
                [label]="'Button with leading icon'" 
                (value)="controlEvent($event, '[buttonIconLeft]=', leftBtnIcon, true); isIconEnabled($event)">
            </kn-toggle>

            <kn-dropdown 
                [template]="true" 
                [id]="leftIconInput" 
                *ngIf="isLeftIconChecked" 
                [value]="iconButtonTitle" 
                [selection]="curentSelectedIcon" 
                [items]="iconList"
                [placeholder]="'Select an icon'"
                (onSelect)="selectedIcon($event)">
                <ng-template  #customTemplate let-data="data">
                    <i class="{{data.className}}"></i> <span class="icon-name">{{data.title}}</span>
                </ng-template>
            </kn-dropdown>
        </div>

        <div class="item-row">
            <kn-toggle label="Loading state" [(ngModel)]="buttonLoading" (value)="controlEvent($event, '[loading]=', 'true', true);" [disabled]="buttonDisabled"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled state" [(ngModel)]="buttonDisabled" (value)="controlEvent($event, '[disabled]=', 'true', true);" [disabled]="buttonLoading"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Click event" [(ngModel)]="btnClickEvent" (value)="controlEvent($event, '(btnClick)=', 'clickEvent($event)', false);"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">buttonType</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"primary"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">buttonId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"btn0"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">buttonText</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Primary button"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">width</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"250px"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">buttonIconLeft</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"fas fa-file-alt"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">disabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">btnClick</div>
                <div class="item"><i>object</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
        </div>
    </div>
    <div doc>
       
            <!-- <div class="kn-bold">In order to download the package, you will need to update or add an .npmrc file in the root of your project. You also will need an auth token for access. The following needs to be added in the file. Replace "YOUR TOKEN HERE" with your auth token. NOTE: This only needs to be done once. If you already have this file and its contents then move on to the next step.</div>
            <pre><code id="npmrc"></code></pre> -->
            <!-- <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/> -->
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
       
    </div>
    <div usage>
        <button-usage></button-usage>
    </div>
    <div designSpec>
        <button-specs></button-specs>
    </div>
    <div accessibility>
        <button-accessibility></button-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">March 14, 2024</div>
                <div class="item">1.1.0</div>
                <div class="item">
                    <ul>
                        <li>added a "width" proerty</li>
                        <li>added a "kn-icon-only" class for buttons with only an icon and no text </li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">October 6, 2023</div>
                <div class="item">1.0.4</div>
                <div class="item">
                    <ul>
                        <li>removed the ::focus state styling from all action buttons</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>Added addition unit testing after component was published</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">August 15, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>Updated the styling for the active and focus states</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the action button component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

