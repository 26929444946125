import { Injectable } from '@angular/core';
import packageInfo from '../../../../../package.json';
import { GoogleAnalyticsService } from './google-analytics.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentationService {
  public formImport: string;
  public copyText = 'Copy code snippet';
  public domain = window.location.protocol + '//' + window.location.host;
  public prodDomain = 'https://kraken.lighthouseglobal.io/';
  public devDomain = 'https://kraken-dsm.platform-tst-aks.lighthouseglobal.io/';

  constructor(public GAService: GoogleAnalyticsService) { }

  register(componentModule, componentPath, form?) {
    let formsModule;
    let formImport;
    let module;
    let importModule;

    if(form) {
      formsModule = ', FormsModule';
      formImport = `import { FormsModule } from '@angular/forms';\n`;
    } else {
      formsModule = '';
      formImport = '';
    }

    if(componentModule) {
      importModule = 
      `import {${componentModule}} from '${componentPath}';
      ${formImport}`
      module = `${componentModule}${formsModule}`
    } else {
      importModule = "";
    }

    document.getElementById('register').innerHTML =
`import { BrowserModule } from '@angular/platform-browser';
${importModule}
@NgModule({
  imports: [ BrowserModule ${module}],
})

export class AppModule { }`

return {}
  }

  styleSheet() {
    document.getElementById('stylesheet').innerHTML = 
  `@import '../node_modules/@kraken-ui/kraken-theme/styles/main'`
  }

  html(html) {
    document.getElementById('html').innerHTML = 
  ""+ html + "\n"
  }

  copy(installCode) {
    navigator.clipboard.writeText(installCode);
   }

   npmrc() {
    document.getElementById('npmrc').innerHTML =
    `
registry=https://lhediscovery.pkgs.visualstudio.com/_packaging/lighthouse-npm/npm/registry/                      
always-auth=true

; begin auth token
//lhediscovery.pkgs.visualstudio.com/_packaging/lighthouse-npm/npm/registry/:username=lhediscovery
//lhediscovery.pkgs.visualstudio.com/_packaging/lighthouse-npm/npm/registry/:_password=[YOUR TOKEN HERE]
//lhediscovery.pkgs.visualstudio.com/_packaging/lighthouse-npm/npm/registry/:email=npm requires email to be set but doesn't use the value
//lhediscovery.pkgs.visualstudio.com/_packaging/lighthouse-npm/npm/:username=lhediscovery
//lhediscovery.pkgs.visualstudio.com/_packaging/lighthouse-npm/npm/:_password=[YOUR TOKEN HERE]
//lhediscovery.pkgs.visualstudio.com/_packaging/lighthouse-npm/npm/:email=npm requires email to be set but doesn't use the value
; end auth token
    `
   }

   downloadWebComponents() {
    let host;
    if(this.domain == this.prodDomain) { // check if prod
      host = this.prodDomain;
    } else {
      host = this.devDomain;
    }
    window.open(host +"web-versions/kraken-web-components-"+ packageInfo.version +".zip");
    this.GAService.trackEvent('download', {
      'event_category': 'Engagement',
      'event_label': 'Web components download',
      'value': host +"web-versions/kraken-web-components-"+ packageInfo.version +".zip"
    });
   }
}
