<div *ngIf="!hideErrorMessage">
    <div *ngIf="field &&
        field.invalid &&
        !compressed &&
        (field.dirty ||
            field.touched ||
            field.errors?.whitespace
        ) ||
        customError"
        [ngClass]="{
        'error': errorPlacementFloating, 
        'static-error': !errorPlacementFloating 
        }">
            <div *ngIf="field.errors?.passwordValid"><i class="fas fa-exclamation-triangle"></i><span *ngIf="customMess">{{customMess}}</span> <span *ngIf="!customMess">The password entered does not meet the minimum complexity requirements. Please enter a new password.</span></div>
            <div *ngIf="field.errors?.passwordConfirm"><i class="fas fa-exclamation-triangle"></i><span *ngIf="customMess">{{customMess}}</span> <span *ngIf="!customMess">The passwords do not match.</span></div>
            <div *ngIf="field.errors?.emailValid"><i class="fas fa-exclamation-triangle"></i><span *ngIf="customMess">{{customMess}}</span> <span *ngIf="!customMess">Incorrect email format</span></div>
            <div *ngIf="field.errors?.required"><i class="fas fa-exclamation-triangle"></i><span *ngIf="customMess">{{customMess}}</span> <span *ngIf="!customMess">Field is required</span></div>
            <div *ngIf="customError"><i class="fas fa-exclamation-triangle"></i>{{customMess}}</div>
    </div>

    <div class="kn-input-message" [ngClass]="{'warning': customWarning}" *ngIf="customWarning && !compressed && !hideErrorMessage">
        <div *ngIf="customWarning"><i class="fas fa-exclamation-triangle"></i> <span class="kn-input-message-text">{{customMess}}</span></div>
    </div>
</div>