<app-canvas 
    [ts]="true" 
    [js]="true" 
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef">
    <div title>Multi-Select Dropdown <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-multi-select 
            [multiSelectId]="'exampleMultiselect'"
            [label]="label"
            [fields]="personFields"
            [data]="personObj"
            [delimiter]="delimiter"
            [keyWord]="'tags'"
            [displayPlaceholder]="displayPlaceholder"
            [searchboxPlaceholder]="searchboxPlaceholder"
            [customError]="customError"
            [customWarning]="customWarning"
            [customMess]="customMess"
            [disabled]="disabled"
            [readOnly]="readOnly"
            [componentWidth]="displayInputWidth"
            (onSelect)="rowSelection($event)"
            [template]="CustomDropdown"
            [dropdownWidth]="dropdownWidth"
            [dropdownHeight]="dropdownHeight"
            [required]="isRequired"
            [optional]="optionalLabel"
            [dropdownRightAligned]="rightAligned"
            (onValidate)="multiSelectInput = $event">
            <ng-template  #customTemplate let-data="data">
                <i class="fa-solid fa-user"></i> <span class="person-name">{{data.name}}</span>
                <span class="role">{{data.role}}</span>
            </ng-template>
        </kn-multi-select>
    </div>
    <div angular></div>
    <div web></div>
    <div typeScript>
        <br/>
        <div *ngIf="onValidate">
            <span class="code-comment">&lt;!--you can bind to the validation event then use it like this --></span>
public multiSelectInput: any;
            <br/>
multiSelectInput?.invalid
            <br/><br/>
        </div>

        <div *ngIf="useKeywords">
            <span class="code-comment">/*Keyword searching using a string array. Example using the "tags" property from the data below.*/</span>
            {{tsKeywords}}
        </div>

        <span class="code-comment">/*Example data with selected fields
By adding a "selected" property to your object, you can predefine "selected" and "unselected" rows*/</span>
        {{tsPersonObj}}
        <br/>
        <div *ngIf="customTemplate">
            <span class="code-comment">/*This component automatically sorts the "checked" rows to the top of the list when the dropdown is opened which changes the state of the "personObj".
The "personObj" will also change based on searching. You can use the (onDataChange) output to capture the changes*/</span>
        </div>
    </div>
    <div javascript>
        <span class="code-comment">/*Initialize the component*/</span>
        {{initializeComponentWeb}}
        <span class="code-comment">/*Declare your object*/</span>
        {{webDropdownItemsObj}}
        <span class="code-comment">/*Item select event*/</span>
        {{webClickEvent}}
        <span class="code-comment">/*Item data change event*/</span>
        {{webDataChangeEvent}}
        <span class="code-comment">/*All properties with a boolean value needs to be set in code like this*/</span>
        {{webSetProperties}}
        <span class="code-comment">/*Remove a boolean property like this*/</span>
        {{webRemoveProperties}}
    </div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onSelect():</div>
                <div class="obj-items">
                    <div class="obj-item teir-1" [ngClass]="{'active': onselectData}"><span class="label">data: <i class="fa-solid fa-caret-right teir-1-caret" (click)="onselectData = !onselectData"></i> </span></div>
                    <div class="sub-items-tier-1"  *ngIf="onselectData">
                        <div class="obj-item teir-2" [ngClass]="{'active': onselectOrigin}"><span class="label">origin: <i class="fa-solid fa-caret-right teir-2-caret" (click)="onselectOrigin = !onselectOrigin"></i> </span></div>
                        <div class="sub-items-tier-2"  *ngIf="onselectOrigin">
                            <div class="obj-item"><span class="label">id: </span>{{actionData?.data.origin.id}}</div>
                            <div class="obj-item"><span class="label">name: </span>"{{actionData?.data.origin.name}}"</div>
                            <div class="obj-item"><span class="label">role: </span>"{{actionData?.data.origin.role}}"</div>
                            <div class="obj-item"><span class="label">tags: </span>"{{actionData?.data.origin.tags}}"</div>
                        </div>
                        <div class="obj-item"><span class="label">selected: {{actionData?.data.selected}}</span></div>
                        <div class="obj-item"><span class="label">text: </span>"{{actionData?.data.text}}"</div>
                        <div class="obj-item"><span class="label">value: {{actionData?.data.value}}</span></div>
                    </div>
                    <div class="obj-item teir-1"[ngClass]="{'active': onEventData}"><span class="label">event: <i class="fa-solid fa-caret-right teir-1-caret" (click)="onEventData = !onEventData"></i> </span></div>
                    <div class="sub-items-tier-1"  *ngIf="onEventData">
                        <div class="obj-item"><span class="label">name: </span>{{actionEvent?.name}}</div>
                        <div class="obj-item"><span class="label">value: </span>"{{actionEvent?.value}}"</div>
                    </div>
                </div>
            </div>

            <div class="event-item" [ngClass]="{'active': event2}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event2 = !event2"></i> 
                <div class="obj">onDataChange():</div>
                <div class="obj-items">
                    <div class="obj-item teir-1" [ngClass]="{'active': onDataChange}"><span class="label">0: <i class="fa-solid fa-caret-right teir-1-caret" (click)="onDataChange = !onDataChange"></i> </span></div>
                    <div class="sub-items-tier-1"  *ngIf="onDataChange">
                        <div class="obj-item"><span class="label">id: </span>{{actionData?.data.origin.id}}</div>
                        <div class="obj-item"><span class="label">name: </span>"{{actionData?.data.origin.name}}"</div>
                        <div class="obj-item"><span class="label">role: </span>"{{actionData?.data.origin.role}}"</div>
                        <div class="obj-item"><span class="label">tags: </span>"{{actionData?.data.origin.tags}}"</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-row">
            <kn-textbox [label]="'Multi-select label'" [value]="label" (onValueChange)="labelText($event, 1)" [clearIcon]="true"></kn-textbox>
            <div class="kn-spacer"></div>
            <kn-checkbox (onSelect)="controlEvent($event.value, '[optional]=', true, true); optionalLabel = !optionalLabel" [label]="'Optional field'"></kn-checkbox>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle [label]="'Display Placeholder'" [(ngModel)]="showDisplayPlaceholder" (value)="showHidePlaceholder($event, 'display'); controlEvent($event, '[displayPlaceholder]=', displayPlaceholder, true);"></kn-toggle>
        </div>
        <div class="action-input" *ngIf="showDisplayPlaceholder">
            <kn-textbox [label]="'Display placeholder text'" [value]="displayPlaceholder" (onValueChange)="placeholderValue($event, '[displayPlaceholder]=')" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle [label]="'Searchbox Placeholder'" [(ngModel)]="showSearchboxPlaceholder" (value)="showHidePlaceholder($event, 'search'); controlEvent($event, '[searchboxPlaceholder]=', searchboxPlaceholder, true);"></kn-toggle>
        </div>
        <div class="action-input" *ngIf="showSearchboxPlaceholder">
            <kn-textbox [label]="'Searchbox placeholder text'" [value]="searchboxPlaceholder" (onValueChange)="placeholderValue($event, '[searchboxPlaceholder]=')" [clearIcon]="true"></kn-textbox>
        </div>
        <!--dropdown width-->
        <div class="item-row">
            <kn-toggle 
                [label]="'Dropdown width'" 
                (value)="controlEvent($event, '[dropdownWidth]=', '', true); 
                showDropdownWidth = !showDropdownWidth; 
                resetDropdownSize();">
            </kn-toggle>
        </div>
        <div class="action-input" *ngIf="showDropdownWidth">
            <div class="input-info">
                <kn-textbox [label]="'Enter value'" [value]="dropdownWidth" (onValueChange)="multiSelectDropdownSize($event, 'width')" [setPlaceholder]="'400px'" [clearIcon]="true"></kn-textbox>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="This is a CSS width property, you can enter px, %, etc" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <!--dropdown height-->
        <div class="item-row">
            <kn-toggle 
                [label]="'Dropdown height'" 
                (value)="controlEvent($event, '[dropdownHeight]=', '', true); 
                showDropdownHeight = !showDropdownHeight; 
                resetDropdownSize();">
            </kn-toggle>
        </div>
        <div class="action-input" *ngIf="showDropdownHeight">
            <div class="input-info">
                <kn-textbox [label]="'Enter value'" [value]="dropdownHeight" (onValueChange)="multiSelectDropdownSize($event, 'height')" [setPlaceholder]="'200px'" [clearIcon]="true"></kn-textbox>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="This is a CSS height property, you can enter px, %, ect" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <div class="item-row">
            <kn-toggle label="Right-aligned dropdown"  (value)="controlEvent($event, '[dropdownRightAligned]=', 'true', true); rightAligned = !rightAligned"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Dropdown template'" 
                (value)="controlEvent($event, '[template]=', true, true);showCustomTemplate()">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Read only"  (value)="controlEvent($event, '[readOnly]=', 'true', true); readOnly = !readOnly" [disabled]="disabled"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled state"  (value)="controlEvent($event, '[disabled]=', 'true', true); disabled = !disabled" [disabled]="readOnly"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Required validation" 
                [(ngModel)]="isRequired" 
                (value)="controlEvent($event, '[required]=', true, true);customMessage($event);" 
                [disabled]="addCustomMessage">
            </kn-toggle>
        </div>
        <div *ngIf="isRequired" class="action-input">
            <kn-textbox 
                [label]="'Custom message'" 
                [value]="customMess" 
                [setPlaceholder]="'Provide a custom message'"
                (onValueChange)="message($event)" 
                [clearIcon]="true">
            </kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle label="Keyword search"  (value)="controlEvent($event, '[keyWord]=', 'keyWords', true); useKeywords = !useKeywords"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle [label]="'Error / warning state'" (value)="customMessToggle($event)" [disabled]="isRequired"></kn-toggle>
        </div>
        <div class="item-row" *ngIf="addCustomMessage">
            <kn-radio-button [id]="'errorRadio'" [defaultValue]="messageType" [(ngModel)]="messageType"  [value]="'radioError'" [name]="'custom-message'" [label]="'Error'" (onSelect)="customMessage($event);" [disabled]="customError"></kn-radio-button>
        </div>
        <div class="item-row" *ngIf="addCustomMessage">
            <kn-radio-button [id]="'warningRadio'" [defaultValue]="messageType" [(ngModel)]="messageType"  [value]="'radioWarning'" [name]="'custom-message'" [label]="'Warning'" (onSelect)="customMessage($event)" [disabled]="customWarning"></kn-radio-button>
        </div>
        <div class="action-input" *ngIf="addCustomMessage">
            <kn-textbox [label]="'Custom message'" [value]="customMess" [setDisabled]="!customError && !customWarning" (onValueChange)="custoMessage($event, 1)" [clearIcon]="true"></kn-textbox>
        </div>
        <!-- <div class="item-row">
            <kn-textbox [label]="'Set delimiter'" [value]="delimiter" (onValueChange)="setDelimiter($event)" [clearIcon]="true"></kn-textbox>
        </div> -->
        
        <div class="item-row">
            <kn-toggle 
                [label]="'Change event'" 
                (value)="controlEvent($event, '(onDataChange)=', 'changeEvent($event)', false)">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Select event'" 
                (value)="controlEvent($event, '(onSelect)=', 'selectEvent($event)', false)">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Validation event" [(ngModel)]="onValidate"  (value)="controlEvent($event, '(onValidate)=', 'multiSelectInput = $event', false);"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">multiSelectId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"multiSelect"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">fields</div>
                <div class="item"><i>Object</i></div>
                <div class="item"><i>{{'{}'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">data</div>
                <div class="item"><i>Object</i></div>
                <div class="item"><i>{{'[{}]'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">template</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">componentWidth</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>'450px'</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">displayPlaceholder</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Select items"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">searchboxPlaceholder</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"search"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">keyWord</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"tags"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">delimiter</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>","</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropdownHeight</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"200px", "max-content", "200%"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropdownWidth</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"200px", "max-content", "200%"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">optional</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">width</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>'100%'</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropdownRightAligned</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">required</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">readOnly</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">disabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customError</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customWarning</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customMess</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"invalid text"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onSelect</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onDataChange</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onDataChange</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{invalid: true}'}}</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <multi-select-usage></multi-select-usage>
    </div>
    <div designSpec>
        <multi-select-specs></multi-select-specs>
    </div>
    <div accessibility>
        <multi-select-accessibility></multi-select-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">Feburary 9, 2024</div>
                <div class="item">1.0.6</div>
                <div class="item">
                    <ul>
                        <li>Bug fix – fixed issue with the error message styling when going to a second line</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">October 6, 2023</div>
                <div class="item">1.0.5</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed selected items not re-ording to the top when clicking the dropdown icon</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.4</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">May 17, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>bug fix - added elevation to the dropdown menu to prevent being hidden by parent elements</li>
                        <li>bug fix - fixed issue with the overflow "more" text always showing</li>
                        <li>Updated the focused trailing flat icon button color styling from #205D86 (Primary) to #434C54 (Ink, Light), indicating to the user that the user can take action to close the menu.</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">April 5, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>Updated menu option row height (from 45px to 41px) to standardize all contextual menus</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the multi select dropdown component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

