<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Icon Styles</div>

        <section>
            <div class="text">The Kraken DS icon library represents a curated selection of iconography available via Font Awesome (Pro), a library of more than 600 scalable vector icons based on CSS.</div>
        </section>
        
        <section>
            <div class="text"><a class="kn-link" href="https://fontawesome.com/">https://fontawesome.com/</a></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Style</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/iconography/icon styling/KrakenDSM_StyleGuide_Iconography_IconStyling_01.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Variations</div>

            <section>
                <div class="text"><span class="kn-bold">1.	Outlined </span></div>
                <div class="text"><span class="kn-bold">2.	Filled </span></div>
            </section>

            <div class="text">Two icon variations are used to communicate information within Lighthouse - Outlined and Filled. Outlined icons are the default style and are used in most use cases. Display a filled version of iconography when further emphasis on an action or primary element is needed. Filled icons are generally accompanied by an outlined version and is often used to represent an action or command that has occurred, such as a filter icon when a data grid column has been filtered.</div>
        </section>
        
        <section>
            <img class="kn-responsive-img" src="assets/style guide/iconography/icon styling/KrakenDSM_StyleGuide_Iconography_IconStyling_03.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Modifiers</div>

            <section>
                <div class="text"><span class="kn-bold">A.	Base: </span>The main concept the icon represents.</div>
                <div class="text"><span class="kn-bold">B.	Modifier/Status: </span>Any element that overlays the base; that is, a modifier that typically represents an action or a status. It modifies the base element by acting as an addition, alteration, or a descriptor.</div>
            </section>
 
            <div class="text">When using icon modifiers alongside a base icon, maintain consistency in style, color, and size to ensure a visually coherent and easily recognizable set of icons. Ensure that the modifiers are meaningful and enhance the base icon's message, providing users with clear and intuitive visual cues.</div>
        </section>

        <section>
            <ul class="bullets">
                <li>Base elements are placed in the center of the icon.</li>
                <li>Modifiers are placed in the bottom right corner of the icon canvas.</li>
                <li>Modifiers are displayed with the filled version.</li>
                <li>With the standard 16px size, the modifier spans a maximum of 50% (8px) of the icon canvas.</li>
                <li>Display a 1px cutout gap between icon elements, which is used to provide space between elements for readability purposes.</li>
            </ul>
        </section>


        <section>
            <div class="kn-headline-24-med">Sizes</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/iconography/icon styling/KrakenDSM_StyleGuide_Iconography_IconStyling_02.png">
        </section>

        <section>
            <div class="text">There are three icon sizes in the Kraken Design System.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">16px (Small)</div>
            <div class="text">The standard size for icons is 16 x 16px. They are displayed in components such as buttons, text fields and toast notifications. </div>
        </section>

        <section>
            <div class="kn-subtitle-16">20px (Medium)</div>
            <div class="text">Medium icons are used sparingly.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">40px (Large)</div>
            <div class="text">Large icons are used for special use cases.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Color</div>
        </section>

        <section>
            <div class="text">Follow the guidelines associated with the component and use case in the <div class="section-item kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/palettes/iconography">Kraken DS Color Guidelines.</div></div>
        </section>
    </div>
</div>