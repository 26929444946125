import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'menu-button-usage',
  templateUrl: './menu-button-usage.component.html',
  styleUrls: ['./menu-button-usage.component.sass']
})
export class MenuButtonUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
