import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KrakenCheckboxComponent } from './kraken-checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KrakenFieldValidationComponent } from './kraken-field-validation.component';
@NgModule({
  declarations: [KrakenCheckboxComponent, KrakenFieldValidationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [KrakenCheckboxComponent, KrakenFieldValidationComponent]
})
export class KrakenCheckboxModule { }
