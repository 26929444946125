<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text">The <span class="kn-bold">Multi-Select Dropdown </span> menu introduces the ability for the user to select more than one option for the field. Upon completion of multiple selections, the field displays a value that describes the number of items the user has selected from the menu.</div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Enabled</span></div>
            <div class="text"><span class="kn-bold">2.	Enabled with optional label</span></div>
            <div class="text"><span class="kn-bold">3.	Enabled with placeholder text</span></div>
            <div class="text"><span class="kn-bold">4.	Enabled with menu option</span></div>
            <div class="text"><span class="kn-bold">5.	Enabled with truncated multiple menu options</span></div>
            <div class="text"><span class="kn-bold">6.	Disabled with placeholder text: </span>A dropdown cannot be clicked, selected, or interacted with if it is disabled.</div>
            <div class="text"><span class="kn-bold">7.	Disabled with menu option</span></div>
            <div class="text"><span class="kn-bold">8.	Disabled with truncated multiple menu options</span></div>
            <div class="text"><span class="kn-bold">9.	Read-only: </span>A dropdown cannot be clicked, selected, or interacted with if it is read-only.</div>
            <div class="text"><span class="kn-bold">10.	Read-only with truncated multiple menu options</span></div>
            <div class="text"><span class="kn-bold">11.	Error: </span>A dropdown can be marked as having an error to show that a value needs to be entered in order to move forward, or that the value was entered is invalid.</div>
            <div class="text"><span class="kn-bold">12.	Error with truncated multiple menu options</span></div>
            <div class="text"><span class="kn-bold">13.	Warning</span></div>
            <div class="text"><span class="kn-bold">14.	Warning with truncated multiple menu options</span></div>
            <div class="text"><span class="kn-bold">15.	Focused</span></div>
            <div class="text"><span class="kn-bold">16.	Focused with single menu option selected</span></div>
            <div class="text"><span class="kn-bold">17.	Focused with multiple menu options selected</span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_02.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Text field</div> 
                <div class="text">Persists when the dropdown is open or closed.</div>       
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">A.	Container: </span>Containers improve the discoverability of text fields by creating contrast between the text field and surrounding content.</div>
                <div class="text"><span class="kn-bold">B.	Label text: </span>Label text is used to inform users as to what information is requested for a text field.</div>
                <div class="text"><span class="kn-bold">C.	Placeholder text (optional): </span>Placeholder text conveys additional guidance about the input field. The placeholder text is displayed inside the text field.</div>
                <div class="text"><span class="kn-bold">D.	Dropdown trailing icon </span></div>
                <div class="text"><span class="kn-bold">E.	Optional label </span></div>
                <div class="text"><span class="kn-bold">F.	Selected menu option(s): </span>The selected menu option(s) shows the option(s) that the user has selected.</div>
                <div class="text"><span class="kn-bold">G.	Selected menu options overflow count </span></div>
                <div class="text"><span class="kn-bold">H.	Error/warning icon: </span>displaying an error/warning icon combined with text makes error/warning states clear for colorblind users.</div>
                <div class="text"><span class="kn-bold">I.	Error/warning text: </span>When an error or warning occurs, error/warning messages are displayed below the text box container.</div>
                <div class="text"><span class="kn-bold">J.	Information icon </span></div>
            </section>
        </section>
      
        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_03a.png">
        </section>

        <section>
            <section>
                <div class="kn-subtitle-16">Menu</div> 
                <div class="text">A list of options to choose from. The menu stays open while options are being selected. The menu closes by clicking outside of the dropdown.</div>       
            </section>

            <section>    
                <div class="text"><span class="kn-bold">A.	Dropdown trailing icon hover </span></div>
                <div class="text"><span class="kn-bold">B.	Focused dropdown trailing icon </span></div>            
            </section>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_03b.png">
        </section>

        <section>
            <section>
                <div class="kn-subtitle-16">Search text box</div> 
                <div class="text">Use search to narrow down a long list of options to find the one you want to select.</div>       
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">C.	Search placeholder text </span></div>
                <div class="text"><span class="kn-bold">D.	Search trailing icon: </span>A search icon indicates the difference to the user between the standard text field and search field.</div>
                <div class="text"><span class="kn-bold">E.	Searched term text: </span>The input text is entered by the user. The input text is displayed inside the text field.</div>
                <div class="text"><span class="kn-bold">F.	Clear trailing icon: </span>After typing text in the field, the clear icon button appears to the far right of the text box. This clears any text that’s been entered in the field.</div>
            </section> 
        </section>
        

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_03c.png">
        </section>

        <section>
            <section>
                <div class="kn-subtitle-16">Searched term menu</div> 
                <div class="text">The menu opens once the user has started typing into the search text box to filter the list of options. The options that start to match your entry remain in the list while other existing options are temporarily removed.</div>       
            </section>

            <section>
                <div class="text"><span class="kn-bold">G.	Menu option: </span>A choice for the user, shown amongst other choices in a menu.</div>
                <div class="text"><span class="kn-bold">H.	No match found </span></div>
            </section>
        </section> 
        

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_03d.png">
        </section>

        <section>
            <section>
                <div class="kn-subtitle-16">Pre-populated menu</div>       
            </section>

            <section>
                <div class="text"><span class="kn-bold">I.	Menu option </span></div>
                <div class="text"><span class="kn-bold">J.	Selected menu option hover </span></div>
                <div class="text"><span class="kn-bold">K.	Disabled menu option: </span>Display actions as disabled when they can only be used sometimes, under certain conditions.</div>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">Assistive elements</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_04.png">

        <section>
            <div class="text"><span class="kn-bold">1. Labels</span> </div>
            <div class="text">Use the label to explain the purpose of the dropdown field and the type of value to be entered by the user. Every dropdown text box should have a label. A dropdown text box without a label is ambiguous and not accessible.</div>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">2. Required or optional</span> </div>
            <div class="text">All dropdown text boxes are required by default and do not require displaying a required attribute in the label. However, when displaying an optional field, they are denoted with text added to the end of the label — “(optional)” —. If using an inline or text box without a label, the “(optional)“ should be denoted within the placeholder text.</div>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">3. Placeholder text</span> </div>
            <div class="text">If the value needs to be provided in a specific format (e.g. name@domain.com), use placeholder text inside the text box control to describe how the information should be entered, not what the field represents.
                <section>
                    Placeholder text should not be editable and should disappear once the user focuses on the text box and begins to type. If the value entered by the user is removed and the text box loses input focus, the hint text should be restored.
                </section> 
            </div>
        </section>   

        <section>
            <div class="text"><span class="kn-bold">4. Error/warning text</span> </div>
            <div class="text">A dropdown text box can be marked as having an error or warning to show that a value needs to be entered in order to move forward or that a value entered is invalid.</div>
        </section>

        <section>
            <div class="section-title">Guidelines</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_05.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Text box height</div>

                <div class="text">Text boxes come in two different sizes: default and compressed. The compressed size is reserved for compressed grid rows where users can scan and take action on large amounts of information. When displaying the compressed size, the label text, character counter, and error/warning icon and text are not displayed. Placeholder text is required to help inform users of information that is requested.</div>
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">1.  Default text box: </span>40px height, 14px text</div>
                <div class="text"><span class="kn-bold">2.  Compressed text box: </span>30px height, 12px text</div>
            </section>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_06.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Display width</div>

                <div class="text">In general, the display width of the dropdown text box should reflect the approximate maximum character length for what would be considered a standard representative value entered by the user. When the selected value exceeds the display width of the text box, it should display with truncation (ellipses).</div>
            </section>
        
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_07.png">
            </section>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Modal </span></div>

            <div class="text">The standard width of text boxes displayed in the body of modal two-column input fields is 264px, while single-column width spans across 576px.</div>
        </section>


        <section>
            <div class="kn-subtitle-16">Menu trigger</div>

            <div class="text">Allow users to click anywhere in the text field to open the menu, rather than only the dropdown trailing icon.</div>        
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_08.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Selected menu options overflow count</div>

                <div class="text">An overflow count is displayed when options selected from the menu exceeds the visible area within the text box. The overflow count indicates the total number of selected options and is displayed as “+1 more”. On hover of the overflow count, a tooltip displays the full text list of selected menu options.</div>
            </section>
            
            <section> 
                <div class="text"><span class="kn-bold">1.	Single-Selection menu option:</span> When a single selected value exceeds the display width of the text box, the overflow count of “+1 more” indicates to the user that the value is partially visible.</div>
                <div class="text"><span class="kn-bold">2.	Multi-Selection menu options:</span> When two or more selected values exceed the display width of the text box, the overflow count will display “+1 more” if the last value is partially visible. Additional selected menu options that are not visible are added to the overflow count number.</div>
                <div class="text"><span class="kn-bold">3.	Tooltip:</span> When up to five menu options have been selected, they are displayed as a stacked list and arranged in alphabetical order.</div>
                <div class="text"><span class="kn-bold">4.	Tooltip with 6+ selected options:</span> When more than five menu options have been selected, menu items are displayed as a list separated with commas.</div>
                <div class="text"><span class="kn-bold">5.	Tooltip maximum character count:</span> When selected options displayed within a tooltip exceed 304 characters, the remaining menu items are truncated.</div>
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Rounded corners</div>

            <div class="text">Text box containers are displayed with a 4px rounded corner.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Menu</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_09.png">
            </section>

            <div class="kn-subtitle-16">Menu height</div>

            <ul class="bullets">
                <li>The display height (depth) of the dropdown menu is suggested not to exceed four menu options. When more than four options are available, display a scrollbar with a search text box.</li>
                <li>For longer lists, consider including a “Select/Deselect all” option as the first option listed to save the user from having to select each option individually.</li>
                <li>The dropdown menu height should never extend beyond the current screen view (i.e., below the fold).</li>
            </ul>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_10.png">
            </section>

            <div class="kn-subtitle-16">Menu width</div>

            <ul class="bullets">
                <li>The display width of the dropdown menu may exceed that of the dropdown text box it is associated with to accommodate the length of the longest option value.</li>
                <li>If the menu option value length exceeds the width of the select field, it is automatically truncated in the display once selected and the menu closes.</li>
            </ul>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_11.png">
            </section>

            <div class="kn-subtitle-16">Menu options</div>

            <ul class="bullets">
                <li>Keep menu items short and concise. If text wrapping becomes a frequent concern, consider revising the text or using alternative UI patterns that will give your content more space.</li>
                <li>Present dropdown menu options in sentence case. If the dropdown list contains custom names, such as custodians, if entered in title case will display in title case.</li>
                <li>Allow users to click anywhere on the menu item to select, rather than only selecting the checkbox.</li>
                <li>Unlike single-select dropdowns, the menu does not close once the user makes selections. As multiple selections are possible, the user needs to click outside of the dropdown to close the menu.</li>
            </ul>
        </section>

        <section>
            <div class="kn-subtitle-16">Ordering of menu options</div>

            <ul class="bullets">
                <li>Sort menu items in a logical order, such as placing most common options first, grouping highly related options together, listing items based on size, or using alphabetical order for lists with 12 or more items to make items easier to find (for example, language or country lists). Place options that represent “All” or “None” at the beginning of the list, regardless of the sort order. Select the most relevant or most common choice on the list as a default if it is appropriate for the context. For example, for a language list, you can set the default value to the language in the user’s browser.</li>
                <li>Selected options shift to the top of the menu in alphanumeric order.</li>
            </ul>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_12.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Multi-column</div>

                <div class="text">Display more information in the dropdown menu by adding an additional menu column.</div>
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Checkbox alignment</div>

            <div class="text">Align related check boxes vertically, not horizontally. Horizontal alignment is harder to read and localize.</div>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_13.png">
            </section>

            <section>
                <div class="kn-subtitle-16">Menu positioning</div>

                <div class="text"><span class="kn-bold">1. </span>  The default dropdown menu is to be positioned below and aligned (left-to-right) with the bottom edge of the dropdown text box.</div>
                <div class="text"><span class="kn-bold">2. </span> the dropdown text box is displayed on the far-right side of the UI layout and there is no room to display the dropdown menu to the right of it, the menu will automatically open aligned to the left edge of the dropdown text box instead.</div>
            </section>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_14.png">

        <section>
            <div class="kn-subtitle-16">Too few options</div>

            <div class="text">When a dropdown menu has less than three options, it lacks an adequate context of up-front information. The user is required to expand the menu to see the available options. In this case, you should use checkboxes so the user can immediately scan the number and content of available options without selecting (or typing) anything to reveal this information.</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_15.png">

        <section>
            <div class="kn-subtitle-16">Too many options</div>

            <div class="text">Displaying more than four menu options in a dropdown menu is not recommended. The menu becomes more difficult to scan and navigate.</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/multiselect-dropdown/KrakenDSM_Components_DropdownMultiSelect_16.png">

        <section>
            <div class="kn-subtitle-16">Write error text that shows a solution</div>

            <div class="text">Write error messaging in a human-centered way by guiding a user and showing them a solution. Don’t display ambiguous error messages.</div>
        </section>
    </div>
</div>