import { Component } from '@angular/core';

@Component({
  selector: 'split-button-specs',
  templateUrl: './split-button-specs.component.html',
  styleUrls: ['./split-button-specs.component.sass']
})
export class SplitButtonSpecsComponent {
  public component = window.location.protocol + '//' + window.location.host + '/design-specs-measure/split-button/';
  
  clickEvent(route) {
    if(route == 'light') {
      window.open(this.component, "_blank");
    }
  }
}
