import { Component, Input, Output, DoCheck, AfterViewInit, ElementRef, OnInit, EventEmitter } from '@angular/core';
import {
  Router
} from '@angular/router';


@Component({
  selector: 'kn-masthead',
  templateUrl: './kraken-masthead.component.html'
})
export class KrakenMastheadComponent implements OnInit, DoCheck, AfterViewInit {
  @Input() style = 'light';
  @Input() compressed = false;
  @Output() onOverflowMenuSelection = new EventEmitter<any>();
 
  public hiddenItems = [];
  public responsiveTabs = true;
  public linksList: any;
  public numOfLinks: any;
  public linksContainer: any;
  public currentContainerWidth: any;
  public linkMenuContainer: any;
  public links: any;
  public id: any;
  public stopWidth = 0;
  public topPos: string;
  public isHiddenLinkSelected = false;
  public linkListObject: any = [];

  constructor(public element: ElementRef, public router: Router) { }

  ngDoCheck() {
    
    if(this.linksList) {
      this.numOfLinks.forEach((i:any) => {
        //set active link
        i.active = this.isActiveLink(i);
      });

      const containerSize = this.linksContainer.offsetWidth;
      if(containerSize != this.currentContainerWidth) {
        this.resize();
      }
    }
  }

  ngOnInit() {
    this.id = this.id ? this.id : 'knMasthead_' + Math.floor(Math.random()*90000) + 10000;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.linksContainer = this.element.nativeElement.querySelector('#'+ this.id + ' .header-masthead-nav');
      this.linksList = this.element.nativeElement.querySelector('#'+ this.id + ' .kn-nav-container div');
      this.numOfLinks = this.element.nativeElement.querySelectorAll('#'+ this.id + ' .kn-header-nav');
      
      //tab overflow menu container
      this.linkMenuContainer = this.element.nativeElement.querySelector('#'+ this.id + ' .kn-masthead-overflow-container');

      //create an reference id for each link
      this.numOfLinks.forEach((i:any, index) => {
        //create an order of the links for sorting
        i.active = this.isActiveLink(i);
        i.index = index;
      });

      //tab overflow event 
      window.addEventListener('resize', () => {
        setTimeout(() => {
          this.resize();   
        }, 100);      
      });
    }, 500);
  }

  resize() {
    const containerWidth = this.linksContainer.getBoundingClientRect().width; 
    const moreBtnWidth = this.linkMenuContainer.getBoundingClientRect().width;
    this.stopWidth = 0;
    this.currentContainerWidth = containerWidth;

    if(containerWidth > 0) {
      this.numOfLinks.forEach((item, i) => {
        //add up the links
        this.stopWidth = this.stopWidth += item.getBoundingClientRect().width;

        if(containerWidth - moreBtnWidth >= this.stopWidth) {
          //unhide links
          item.classList.remove('hide');

          //check if item already exists as a hidden item
          if(this.containsObject(this.numOfLinks[i], this.hiddenItems)) {
              if(item.classList.contains('active-link')) {
                //remove active flag from the overflow menu tab
                this.isHiddenLinkSelected = false;
              }
              //remove tab from menu
              const index = this.hiddenItems.findIndex(z => z.index === this.numOfLinks[i].index);
              this.hiddenItems.splice(index, 1);
              const linkIndex = this.linkListObject.findIndex(z => z.index === this.numOfLinks[i].index);
              this.linkListObject.splice(linkIndex, 1);
          }
        
        } else {
          //hide tab
          item.classList.add('hide');

          // //check if item already exists as a hidden item
          if(!this.containsObject(this.numOfLinks[i], this.hiddenItems)) {
            this.hiddenItems.push(this.numOfLinks[i]);

            //create an object of links for use in the overflow menu
            const itemLink: any = [];
            itemLink.index = this.numOfLinks[i].index;
            itemLink.id = this.numOfLinks[i].id;
            itemLink.name = this.numOfLinks[i].children[0].innerHTML;
            //check for ngRoute
            if(this.numOfLinks[i].children[0].attributes.routerLink) {
              itemLink.routerlink = this.numOfLinks[i].children[0].attributes.routerLink.nodeValue;
            }
            //check for url
            if(this.numOfLinks[i].children[0].attributes.href) {
              itemLink.url = this.numOfLinks[i].children[0].attributes.href.nodeValue;
            }
            
            //set item to active if link matches the current url
            //itemLink.active = this.isActiveLink(itemLink, true);
              
            this.linkListObject.push(itemLink);
          } 

          //set overflow menu to active if active link exist in menu 
          const isActiveLinkInMenu = this.linkListObject.find(i => i.active == true);
          this.isHiddenLinkSelected = isActiveLinkInMenu ? true : false;
        }
      });

      this.linkListObject.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0))
      // this.linkListObject.sort(function(a, b) { 
      //   return a.id - b.id
      // });

      if(!this.hiddenItems.length) {
        //hide the overflow menu
        this.linkMenuContainer.classList.add('hide');
      }
      else {  
        //show the overflow menu and 
        this.linkMenuContainer.classList.remove('hide');
      }
    }
  }

  containsObject(obj, list) {
    let i;
    for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
            return true;
        }
    }

    return false;
  }

  clickEvent(item) {
    if(item.routerlink) {
      this.router.navigate([item.routerlink]);
    }else if(item.url) {
      window.open(item.url);
    }
  }

  //overflow menu selection 
  overflowItemSection(event) {
    this.onOverflowMenuSelection.emit(event);
  }

  isActiveLink(item) {
    const link = item.querySelector('.link');
    const href = location.href;
    const isCurentPage = link.href == href ? true : false;
    if(isCurentPage) {
      link.classList.add('active-link');
      if(this.linkListObject.length > 0) {
        this.linkListObject.forEach(i => {
          if(i.index == item.index) {
            i.active = true;
          }
        });
      }
    } else {
      link.classList.remove('active-link');
      if(this.linkListObject.length > 0) {
        this.linkListObject.forEach(i => {
          if(i.index == item.index) {
            i.active = false;
          }
        });
      }
    }
  }
}


