<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Capitalization</div>
        
        <section>
            <div class="text">To ensure style consistency and keep with industry standards, we have established the following capitalization guidelines for content displayed within a Lighthouse application UI. The use of <span class="kn-bold">ALL CAPS, Title Case</span>, and <span class="kn-bold">Sentence case</span> are defined by the type of typography (Headlines, Labels, Values, etc.)<br/></div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Component labels / Section titles</div>
        </section>

        <section>
            <div class="text">Use: <span class="kn-bold">ALL CAPS</span></div>

        <ul class="bullets">
            <li>Column headers</li>
            <li>Button labels (primary, secondary, tertiary, flat)</li>
            <li>Field input labels (label text)</li>
            <li>Dropdown labels</li>
            <li>Section titles for modals and the details panel</li>
            <li>State labels</li>
        </ul>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/capitalization/KrakenDSM_StyleGuide_Typography_Capitalization_01.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">1. Column headers</span></div>
            <div class="text"><span class="kn-bold">2. Field input labels</span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Headlines / Values / 3rd Party</div>
        </section>

        <section>
            <div class="text">Use: <span class="kn-bold">Title case</span></div>
        
        <ul class="bullets">
            <li>Page names</li>
            <li>Menu navigation items</li>
            <li>Modal titles</li>
            <li>Dropdown values</li>
            <li>Toggle switch label / value (body text)</li>
            <li>Radio button label / value (body text)</li>
            <li>3rd Party items (Relativity Concepts and Clusters, Brainspace Analytics Suite, Microsoft AED)</li>
        </ul>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/capitalization/KrakenDSM_StyleGuide_Typography_Capitalization_02.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">3. Page names</span></div>
            <div class="text"><span class="kn-bold">4. Modal titles</span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Informational / Ghost text / Instructions</div>
        </section>

        <section>
            <div class="text">Use: <span class="kn-bold">Sentence case</span></div>

        <ul class="bullets">
            <li>Input field ghost text</li>
            <li>Instructional text</li>
            <li>Zero state text</li>
            <li>Toast messages</li>
            <li>Status indication</li>
            <li>Pagination text</li>
            <li>Error messages</li>
        </ul>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/capitalization/KrakenDSM_StyleGuide_Typography_Capitalization_03.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">5. Toast messages</span></div>
            <div class="text"><span class="kn-bold">6. Error messages</span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Words always capitalized</div>
        </section>

        <ul class="bullets">
            <li>Spectra</li>
            <li>Relativity</li>
            <li>Brainspace</li>
            <li>M365 or Microsoft</li>
            <li>DeNist</li>
        </ul>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/capitalization/KrakenDSM_StyleGuide_Typography_Capitalization_04.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">7. Always capitalized</span></div>
        </section>
    </div>
</div>