import { NgModule } from '@angular/core';
import { KrakenTextboxComponent } from './kraken-textbox.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EmailValidatorDirective } from './email-validator.directive';
import { NumericOnlyDirective } from './numeric-only.directive';
import { KrakenFieldValidationComponent } from './kraken-field-validation.component';

@NgModule({
  declarations: [
    KrakenTextboxComponent, 
    EmailValidatorDirective, 
    KrakenFieldValidationComponent,
    NumericOnlyDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [EmailValidatorDirective, NumericOnlyDirective],
  exports: [KrakenTextboxComponent, EmailValidatorDirective, KrakenFieldValidationComponent, NumericOnlyDirective]
})
export class KrakenTextboxModule { }
