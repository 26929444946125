import { Component, OnInit, Input, Output, ElementRef, AfterViewInit, OnChanges, EventEmitter } from '@angular/core';

@Component({
  selector: 'kn-slider',
  templateUrl: './kraken-slider.component.html'
})
export class KrakenSliderComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() componentId: string;
  @Input() label: string;
  @Input() minRange: number;
  @Input() maxRange: number;
  @Input() minValue = 0;
  @Input() maxValue = 0;
  @Input() value: number;
  @Input() steps;
  @Input() width;
  @Input() multiSlider = false;
  @Input() showTextbox = true;
  @Input() rangeThreshold = 1;
  @Input() disabled = false;
  @Input() readOnly = false;
  @Input() errorMess: string;
  @Output() minValueEvent = new EventEmitter<any>();
  @Output() maxValueEvent = new EventEmitter<any>();

  public range: any;
  public sliderContainer: any;
  public sliderMin: any;
  public sliderMax: any;
  public minTooltip: any;
  public maxTooltip: any;
  public inputValue: any;
  public progress: any;
  public tickPositions: any =[];
  public maxThumbPosition;
  public minThumbPosition;
  public minRangeInput: any;
  public maxRangeInput: any;
  public minTextboxValue: number;
  public maxTextboxValue: number;
  public minError = false;
  public maxError = false;
  public multiRangeContainer: any;
  public multiRangeInput: any;
  public inputErrorMessage = "Invalid value";
  public hideErrorMess = true;

  constructor(public elm: ElementRef) {}

  ngOnInit(): void {
    this.componentId = this.componentId ? this.componentId : 'knSlider_' + Math.floor(Math.random()*90000) + 10000;
    //set steps
    this.steps = this.steps > 0 ? this.steps : 0;
  }

  ngOnChanges(changes) {
    //disabled 
    if(changes.disabled) {
      if(!changes.disabled.firstChange) {
        this.progressEvent();
      }
    }
    //read only
    if(changes.readOnly) {
      if(!changes.readOnly.firstChange) {
        this.progressEvent();
      }
    }
    //state change
    if(changes.multiSlider ) {
      if(!changes.multiSlider.firstChange ) {
        setTimeout(() => {
          if(changes.multiSlider.currentValue) {
            this.multiSilderInit();
          } else {
            this.singleSliderInit();
          }
        }, 100);
      } 
    }
    //on value changes
    if(changes.minValue) {
      if(!changes.minValue.firstChange) {
        setTimeout(() => {
          if(this.multiSlider) {
            this.multiSilderInit();
          } else {
            this.singleSliderInit();
          }
        }, 100);
      } 
    } else if(changes.maxValue) {
      if(!changes.maxValue.firstChange) {
        setTimeout(() => {
          if(this.multiSlider) {
            this.multiSilderInit();
          } else {
            this.singleSliderInit();
          }
        }, 100);
      } 
    }
  }

  ngAfterViewInit(): void {
    this.sliderContainer = this.elm.nativeElement.querySelector("#" + this.componentId);
    this.hideErrorMess = true;
    
    //wait for component to render
    setTimeout(() => {
      if(this.multiSlider) {
        this.multiSilderInit();
      } else {
        this.singleSliderInit();
      }
    }, 500);
  }

  progressEvent() {
    let fillColor = '#205D86'
    if(this.disabled) {
      fillColor = '#A5B2BD';
    }
    if(this.readOnly) {
      fillColor = '#6A757E';
    }
    const sliderWidth = this.sliderMin.getBoundingClientRect();
    const fillProportion = (this.minValue - this.minRange)  / (this.maxRange - this.minRange);
    this.minThumbPosition = fillProportion * sliderWidth.width;
    this.sliderMin.style.background = `linear-gradient(to right, ${fillColor} ${this.minThumbPosition}px, #D2DBE2 ${this.minThumbPosition}px)`;
    this.minTextboxValue = this.minValue;
    this.minTooltipPosition(this.minValue);
    //emit min value
    this.minValueEvent.emit({value: this.minValue});
  }

  multiSliderProgressEvent() {
    const multiRangeContainer: any = this.sliderContainer.querySelector(".kn-multi-range-container");
    const rangeSelected: any = multiRangeContainer.querySelector(".range-slider > .range-selected");
    
    // Calculate the left and right positions based on thumb values
    const leftPosition = (this.minValue - this.minRange) / (this.maxRange - this.minRange) * 100;
    const rightPosition = (this.maxValue - this.minRange) / (this.maxRange - this.minRange) * 100;

    // Update the styles
    rangeSelected.style.left = leftPosition + "%";
    rangeSelected.style.right = (100 - rightPosition) + "%";

    //emit values
    this.minValueEvent.emit({value: this.minValue});
    this.maxValueEvent.emit({value: this.maxValue});
  }

  minTooltipPosition(value) {
    const thumbPosition = (value - this.minRange) / (this.maxRange - this.minRange) * this.sliderMin.clientWidth;
    this.minTooltip.style.left = `${thumbPosition}px`; // Set the tooltip position in pixels
    this.minTooltip.innerText = value;
  }

  singleSliderInit() {
    this.sliderMin = this.sliderContainer.querySelector(".slider.min");
    this.minTooltip = this.sliderContainer.querySelector(".tooltip.min");
    this.minValue = this.minValue ? this.minValue : this.minRange;
    this.minTextboxValue = this.minValue;
    
    this.sliderMin.addEventListener("mouseout", () => {
      // Hide the tooltip when not hovering over the thumb
      this.minTooltip.style.display = "none";
    });

    this.sliderMin.addEventListener("mouseover", () => {
      // show the tooltip when not hovering over the thumb

      const value = this.sliderMin.value;
      const rect = this.sliderMin.getBoundingClientRect();
      this.minThumbPosition = (value / this.maxRange) * rect.width;
      this.minTooltipPosition(value);
      // Show the tooltip
      this.minTooltip.style.display = "block";
    });

    //this.calculateTickPositions();
    this.progressEvent();
  }

  multiSilderInit() {
    this.multiRangeContainer = this.sliderContainer.querySelector(".kn-multi-range-container");
    this.multiRangeInput  = this.multiRangeContainer.querySelectorAll(".range-input input");
    this.minRangeInput= this.multiRangeContainer.querySelector(".range-input .min");
    this.maxRangeInput = this.multiRangeContainer.querySelector(".range-input .max");
    this.minTooltip = this.multiRangeContainer.querySelector(".tooltip.min");
    this.maxTooltip = this.multiRangeContainer.querySelector(".tooltip.max");

    //set textbox values
    this.minValue = this.minValue ? this.minValue : this.minRange;
    this.maxValue = this.maxValue ? this.maxValue : this.maxRange;

    this.minTextboxValue = this.minValue;
    this.maxTextboxValue = this.maxValue;

    this.multiRangeInput.forEach((input) => {
      input.addEventListener("input", (e) => {
        const minRange = parseInt(this.multiRangeInput[0].value);
        const maxRange = parseInt(this.multiRangeInput[1].value);
        // Do not allow for overlapping thumbs
        if (maxRange - minRange < this.rangeThreshold) {
          if (e.target.className === "min") {
            this.multiRangeInput[0].value = (maxRange - Number(this.rangeThreshold));
          } else {
            this.multiRangeInput[1].value = (minRange + Number(this.rangeThreshold));
          }
        } else {
          if (e.target.className === "min") {
            this.minValue = minRange;
            this.minThumbTooltip();
          } else {
            // Check if the max thumb is approaching the max position
            if (maxRange >= Number(this.multiRangeInput[1].max) ) {
              // Limit the maxRange to the max position
              this.multiRangeInput[1].value = this.multiRangeInput[1].max;
              this.maxValue = maxRange;
              this.maxTooltip.innerText = maxRange;
            } else {
              this.maxValue = maxRange;
              this.maxThumbTooltip();
            }
          }

          this.multiSliderProgressEvent();

          this.minTextboxValue = this.minValue;
          this.maxTextboxValue = this.maxValue;
        }
      });
    });

    
    this.minRangeInput.addEventListener("mouseout", () => {
      // Hide the tooltip when not hovering over the thumb
      this.minTooltip.style.display = "none";
    });

    this.minRangeInput.addEventListener("mouseover", () => {
      this.minThumbTooltip();
    });

    this.maxRangeInput.addEventListener("mouseout", () => {
      // Hide the tooltip when not hovering over the thumb
      this.maxTooltip.style.display = "none";
    });

    this.maxRangeInput.addEventListener("mouseover", () => {
      this.maxThumbTooltip();
    });

    this.multiSliderProgressEvent();
  }

  minTextboxEvent(event) {
    if(this.multiSlider) {
      if(Number(event) >= Number(this.minRange) && Number(event) < Number(this.maxValue)) {
        this.minValue = this.minTextboxValue;
        this.errorMess = !this.minError && !this.maxError ? null : this.inputErrorMessage;
        this.minError = false;
        this.multiSliderProgressEvent();
      } else {
        this.minError = true;
        this.errorMess = this.inputErrorMessage;
      }
    } else {
      if(Number(event) >= Number(this.minRange) && Number(event) <= Number(this.maxRange)) {
        this.minValue = this.minTextboxValue;
        this.errorMess = !this.minError && !this.maxError ? null : this.inputErrorMessage;
        this.minError = false;
        this.progressEvent();
      } else {
        this.minError = true;
        this.errorMess = this.inputErrorMessage;
      }
    }
  }

  maxTextboxEvent(event) {
    if(Number(event) > Number(this.minValue) && Number(event) <= Number(this.maxRange)) {
      this.maxValue = this.maxTextboxValue;
      this.errorMess = !this.minError && !this.maxError ? null : this.inputErrorMessage;
      this.maxError = false;
      this.multiSliderProgressEvent();
    } else {
      this.maxError = true;
      this.errorMess = this.inputErrorMessage;
    }
  }

  maxThumbTooltip() {
    // Calculate the max thumb position and update the tooltip
    const value = this.maxValue;
    const maxThumbPosition = (value - this.minRange) / (this.maxRange - this.minRange) * 100;
    this.maxTooltip.style.left = `${maxThumbPosition}%`;
    this.maxTooltip.innerText = value;
    this.maxTooltip.style.display = "block";
  }

  minThumbTooltip() {
    // Calculate the min thumb position and update the tooltip
    const value = this.minValue;
    const minThumbPosition = (value - this.minRange) / (this.maxRange - this.minRange) * 100;
    this.minTooltip.style.left = `${minThumbPosition}%`;
    this.minTooltip.innerText = value;
    this.minTooltip.style.display = "block";
  }
}

