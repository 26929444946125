import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'accordion-usage',
  templateUrl: './accordion-usage.component.html',
  styleUrls: ['./accordion-usage.component.sass']
})
export class AccordionUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
