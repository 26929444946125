import { Component, OnInit} from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import { FontawesomeService } from '../../services/fontawesome.service';
import { ButtonService } from '../../services/button.service';
import  packageInfo from '../../../../../kraken-button/package.json';

@Component({
  selector: 'buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.sass'],
  
})
export class ButtonsComponent implements OnInit {
  public buttonIconLeft: string;
  public buttonIconRight: string;
  public buttonId = 'exampleButton'
  public source: any;
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public webClickEvent;
  public webSetProperties;
  public webRemoveProperties;
  public componentId = 'exampleButtonComponentId';
  public elementTag = 'kn-button';
  public buttonClicked = false;
  public buttonText = "Button";
  public buttonType = "kn-primary";
  public selectedButton: string;
  public leftBtnIcon = "";
  public rightBtnIcon = "";
  public buttonDisabled = false;
  public buttonLoading = false;
  public isLeftIconChecked = false;
  public isRightIconChecked = false;
  public loading = false;
  public iconLeftValue = "";
  public iconValue = "";
  public event1 = true;
  public event2 = true;
  public buttonMenuItems: any = [{display:'item 1', id: 0}, {display:'item 2', id: 1}, {display:'item 3', id: 2}];
  public buttonMenuFields;
  public buttonDropdown = false;
  public btnClickEvent = false;
  public btnDropdownFields = {text: 'display', value: 'id'};
  public objDropdown;
  public btnSelectText = "item 1";
  public btnSelectValue = "0";
  public buttonTypes;
  public themeFields: object = { text: 'name', value: 'color'};
  public selectedTheme: string;
  public selectedThemeDisplayName: string;
  public iconList: any;
  public icon = "";
  public buttonTypeFields: object = { text: 'name', value: 'type'};
  public iconButtonTitle = '';
  public curentSelectedIcon;
  public initializeWebComponent: any;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenButtonModule'
  };
  public componentVersion = packageInfo.version;
  public angularComponentOptions: any = [
    { option: '[buttonId]=', id: 0, value:  '"\'exampleButton\'"'},
    { option: '[buttonType]=', id: 1, value: '"\'' + this.buttonType + '\'"'},
    { option: '[buttonText]=', id: 2, value: '"\'' + this.buttonText + '\'"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value:  '"'+ this.componentId +'"'},
    { option: 'button-id=', id: 1, value:  '"exampleButton"'},
    { option: 'button-type=', id: 2, value:  '"' + this.buttonType +'"'},
    { option: 'button-text=', id: 3, value: '"' + this.buttonText +'"' }
  ];
  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService,
    public fontawesomeService: FontawesomeService,
    private buttonService: ButtonService) { 
  }

  ngOnInit(): void {
    //set button types
    this.buttonTypes = this.buttonService.buttonTypes(true);
    this.selectedButton = this.buttonTypes[0].name;
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    
    //create icon list
    this.iconList = this.fontawesomeService.iconDataSet();

    this.initializeWebComponent = `let button = document.querySelector('#${this.componentId}');
    `
    this.webClickEvent = 
    `
button.addEventListener('btnClick', (event) => console.log(event.detail));
    `

    this.webSetProperties = 
    `
button.disabled = true;
button.loading = true;
    `

    this.webRemoveProperties =
    `
button.disabled = true;
button.loading = false;
    `

  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  valueChange(event) {
    this.buttonType = event.type;
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[buttonType]='));
    //angular
    this.angularComponentOptions[index].option = '[buttonType]=';
    this.angularComponentOptions[index].value = '"\'' + event.type +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.angularComponentOptions.findIndex((obj => obj.option == 'button-type='));
    this.webComponentOptions[index].option = 'button-type=';
    this.webComponentOptions[index].value = '"' + event.type + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  labelText(event) {
    this.buttonText = event;
    
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[buttonText]='));
    this.angularComponentOptions[index].option = '[buttonText]=';
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'button-text='));
    this.webComponentOptions[webIndex].option = 'button-text=';
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  selectedIcon(item) {
    //angular
    const angularIconProp = '[buttonIconLeft]=';
    const angularIndex = this.angularComponentOptions.findIndex((obj => obj.option == angularIconProp));
    this.angularComponentOptions[angularIndex].value = '"\'' + item.className + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIconProp = 'button-icon-left=';
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == webIconProp));
    this.webComponentOptions[webIndex].value = '\'' + item.className + '\'';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    this.iconLeftValue = item.className;
    this.iconButtonTitle = item.title
    this.curentSelectedIcon = item;
  }

  isIconEnabled(event) {
    this.isLeftIconChecked = event ? event : "";
    this.leftBtnIcon = event ? this.leftBtnIcon : "";
    this.iconLeftValue = event ? this.iconLeftValue : "";
    this.iconButtonTitle = event ? this.iconButtonTitle : "";
  }

  themeSelector(event) {
    this.selectedTheme = event.color;
  }

  selectedItem(event) {
    this.btnSelectText = event.text;
    this.btnSelectValue = event.value;
  }

}


