import { Component, Input, Output, EventEmitter, forwardRef, ContentChild, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";
@Component({
  selector: 'kn-toggle',
  templateUrl: './kraken-toggle.component.html',
  styleUrls: ['./kraken-toggle.component.sass'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => KrakenToggleComponent), multi: true}
  ]
})
export class KrakenToggleComponent {
  @Input() toggleId;
  @Input() label;
  @Input() labelLeft = false;
  @Input() disabled = false;
  @Input() checked = false;
  @Input() val = false;
  @Input() name = "toggleSwitch";
  @Input() customLabel = false;
  @Output() value = new EventEmitter<boolean>();

  @ContentChild(TemplateRef) labelTemplate: TemplateRef<any>;

  propagateChange = (_: any) => {};
  public onTouched: any = () => {};

  writeValue(obj: any): void {
    this.val = obj;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  onModelChange(val: any) {
    this.propagateChange(val);
    this.value.emit(val);
  }
}
