import { NgModule } from '@angular/core';
import { KrakenAutocompleteComponent } from './kraken-autocomplete.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [KrakenAutocompleteComponent],
  imports: [
    CommonModule,
    FormsModule
  ],
  exports: [KrakenAutocompleteComponent]
})
export class KrakenAutocompleteModule { }
