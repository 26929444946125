<div 
    id="{{componentId}}"
    class="kn-progress-indicator"
    [ngClass]="
    {
        'error': error,
        'kn-progress-inline': inline,
        'disabled': disabled
    }"
    >
    <div class="kn-progress-label" *ngIf="label && !inline">{{label}}</div>
    <div class="kn-progress-header kn-body-12">
        <div class="kn-progress-sub-text" *ngIf="subText && !inline">{{subText}}</div>
        <div class="kn-progress-percentage">
            <div class="kn-progress-error" *ngIf="error && !inline"><i class="fa-solid fa-triangle-exclamation kn-icon-sm kn-icon" tooltip='{{errorMessage}}' [tooltipDirection]="'top'"></i> Error</div>
            <span *ngIf="!hideValue && !queued">{{value}}%</span>
            <span *ngIf="!hideValue && queued">- -</span>
        </div>
    </div>
    <div class="kn-progress-container">
        <div class="kn-progress" [ngStyle]="{ width: value + '%' }"></div>
    </div>
</div>