import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';

@Component({
  selector: 'panel-accessibility',
  templateUrl: './panel-accessibility.component.html',
  styleUrls: ['./panel-accessibility.component.sass']
})
export class PanelAccessibilityComponent implements OnInit {
  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;

  constructor(private templateService: TemplateUpdateService) { }

  ngOnInit(): void {
  }

}
