<app-canvas 
    [js]="true" 
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef">
    <div title>Toggle Switch <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-toggle 
            [id]="'exampleToggle'"
            [label]="label" 
            [(ngModel)]="toggleDemo"
            [checked]="toggleDemo"
            [disabled]="toggleDisabled"
            [customLabel]="customLabelTemp"
            [name]="'toggleSwitch'"
            [labelLeft]="labelLeft">
            <ng-template #labelTemplate>
                <div>Custom label with <span class="kn-bold">HTML</span></div>
            </ng-template>
        </kn-toggle>
    </div>
    <div angular>
        
    </div>
    <div web>
       
    </div>
    <div javascript>
        <span class="code-comment">//Initialize the component</span>
        {{initializeComponentWeb}}
        <span class="code-comment">//Value change event</span>
        {{webClickEvent}}
        <span class="code-comment">//All properties with a boolean value needs to be set in code like this</span>
        {{webSetProperties}}
        <span class="code-comment">//Remove a boolean property like this</span>
        {{webRemoveProperties}}
        <span class="code-comment">//You can initially set the toggle using the "val" property</span>
        {{webChecked}}
    </div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">value():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">{{toggleDemo}}</span></div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-row">
            <kn-textbox [label]="'Toggle label'" [value]="label" (onValueChange)="labelText($event)" [clearIcon]="true" [setDisabled]="customLabelTemp"></kn-textbox>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle label="Custom label (HTML)" [(ngModel)]="customLabelTemp" (value)="customTemplateEvent($event);"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled state" (value)="controlEvent($event, '[disabled]=', true, true);" [(ngModel)]="toggleDisabled"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Click event" (value)="controlEvent($event, '(value)=', 'value($event)', false)" [(ngModel)]="valueEvent"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">toggleId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"toggleExample"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">name</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"toggleSwitch"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">label</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Example toggle switch"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customLabel</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">disabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">labelLeft</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">val</div>
                <div class="item"><i>any</i></div>
                <div class="item"><i>true, "this is a value"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">value</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">Directive</div>
                <div class="item">[(ngModel)]</div>
                <div class="item"><i>two-way binding</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <toggle-usage></toggle-usage>
    </div>
    <div designSpec>
        <toggle-specs></toggle-specs>
    </div>
    <div accessibility>
        <toggle-accessibility></toggle-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the toggle button component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

