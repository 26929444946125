<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Usage & Grammar</div>
        
        <section>
            <div class="kn-headline-24-med">Titles and headings</div>
        </section>
        
        <section>
            <div class="text">Titles and headings give the user a visual and contextual queue to where they are and what they can do in that area. The title or heading should be specific, yet brief.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/content/usage grammar/KrakenDSM_StyleGuide_Content_UsageGrammar_01.png">
        </section>

        <section>
            <div class="kn-headline-24-med">Buttons and links</div>
        </section>

        <section>
            <div class="text">The label should be specific to the task. </div>
            <div class="text">It should convey exactly what the user expects will happen when they click it.</div>
                <ul>
                    <li>Use the active imperative form for verb phrases. (verb w/adverb or direct object) such as "Create Matter"</li>
                    <li>Minimize the number of words to make the button easier to scan</li>
                    <li>Avoid technical phrases such as "enable" or "initiate"</li>
                    <li>Consider the context of the page/form/grid. How much do you need to explain?</li>
                </ul> 
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/content/usage grammar/KrakenDSM_StyleGuide_Content_UsageGrammar_02.png">
        </section>

        <section>
            <div class="kn-headline-24-med">Instructional text and callouts</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Verb usage and tense</div>
        </section>

        <section>
            <div class="text">Begin with the objective, followed by the action to take. </div>
        </section>

        <section>
            <div class="text">Instead of instructing a user to take action first, let them decide based on the objective. This helps increase scannability as well.
                <ul>
                    <li>Be consistent with verb tense within a sentence</li>
                    <li>Use present tense when possible</li>
                    <li>Reduce use of auxiliary verbs (have, be, do)</li>
                </ul>
            </div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/content/usage grammar/KrakenDSM_StyleGuide_Content_UsageGrammar_03.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Visually call out optional fields and actions</div>
        </section>

        <section>
            <div class="text">Call out labels with a different font-weight/color treatment in place of describing the type of element (menu or button). </div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/content/usage grammar/KrakenDSM_StyleGuide_Content_UsageGrammar_04.png">
        </section>

        <section>
            <div class="kn-subtitle-16">First Person, Second Person</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Second person </span> (you or your) is the preferred option because it makes users feel like the UI is addressing them directly. </div>
        </section>

        <section>
            <div class="text">"Create your first Matter"</div>
        </section>

        <section>
            <div class="text">Use <span class="kn-bold">first person </span> (I or my) when underscoring the user’s ownership of content or actions. </div>
        </section>

        <section>
            <div class="text">"Reset my password"</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Statuses</div>
        </section>

        <section>
            <div class="text">Use active voice and present tense whenever possible. Avoid passive voice. </div>
            <div class="text">Use ellipses to show statuses in progress. </div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/content/usage grammar/KrakenDSM_StyleGuide_Content_UsageGrammar_05.png">
        </section>

    </div>
</div>