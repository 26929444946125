import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KrakenTabsComponent } from './kraken-tabs.component';
import { KrakenTabComponent } from './kraken-tab/kraken-tab.component';
import { KrakenIconMenuModule } from '@kraken-ui/kraken-icon-menu';


@NgModule({
  declarations: [KrakenTabsComponent, KrakenTabComponent],
  imports: [
    CommonModule,
    KrakenIconMenuModule
  ],
  exports: [KrakenTabsComponent, KrakenTabComponent]
})
export class KrakenTabsModule { }
