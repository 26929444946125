<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Logos</div>
        
        <section>
            <div class="text">Corporate and product logos are stored and maintained by Lighthouse Product Marketing on the <span class="kn-bold">All Crew</span> digital asset portal (powered by Brandfolder). The following listings reference the location of logo image assets on All Crew where they may be downloaded.</div>
        </section>
        
        <section>
            <img class="kn-responsive-img" src="assets/style guide/logos/KrakenDSM_StyleGuide_Logos_01.png">
        </section>

        <section>
            <div class="text">View Assets: <a class="kn-link" href="https://brandfolder.com/lighthouse#!asset/gk6msprk8r3hvqj95773g4c" target="_blank"> https://brandfolder.com/lighthouse#!asset/gk6msprk8r3hvqj95773g4c </a> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Standard logo (Lighthouse Logo Full Color_800px.png)</span></div>
            <div class="text">819 x 202 px</div>
            <div class="text">15KB PNG</div>
            <div class="text">9/18/2020</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">White logo (Lighthouse Logo White_2000px.png)</span></div>
            <div class="text">2208 x 546 px</div>
            <div class="text">30KB PNG</div>
            <div class="text">9/18/2020</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Grey logo (Lighthouse Logo Grey_800px.png)</span></div>
            <div class="text">819 x 202 px</div>
            <div class="text">11KB PNG</div>
            <div class="text">9/18/2020</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Black logo (Lighthouse Logo Black_500px.png)</span></div>
            <div class="text">568 x 140 px</div>
            <div class="text">11KB PNG</div>
            <div class="text">9/18/2020</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/logos/KrakenDSM_StyleGuide_Logos_02.png">
        </section>

        <section>
            <div class="text">View Assets: <a class="kn-link" href="https://brandfolder.com/lighthouse#!asset/gk6msprk8r3hvqj95773g4c" target="_blank"> https://brandfolder.com/lighthouse#!asset/gk6msprk8r3hvqj95773g4c </a> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Standard badge (Lighthouse Badge Full Color_800px.png)</span></div>
            <div class="text">808 x 808 px</div>
            <div class="text">36KB PNG</div>
            <div class="text">9/18/2020</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">White badge (Lighthouse Badge White_131px.png)</span></div>
            <div class="text">131 x 132 px</div>
            <div class="text">6KB PNG</div>
            <div class="text">9/18/2020</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Grey logo (Lighthouse Badge Grey_131px.png)</span></div>
            <div class="text">131 x 132 px</div>
            <div class="text">4KB PNG</div>
            <div class="text">9/18/2020</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/style guide/logos/KrakenDSM_StyleGuide_Logos_03.png">
        </section>

        <section>
            <div class="text">View Assets: <a class="kn-link" href="https://brandfolder.com/lighthouse#!asset/8qntnqgn46w9hw77jk4h4h" target="_blank"> https://brandfolder.com/lighthouse#!asset/8qntnqgn46w9hw77jk4h4h </a> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Standard logo (Spectra Lighthouse Logo_Full Color.png)</span></div>
            <div class="text">1627 x 465 px</div>
            <div class="text">56KB PNG</div>
            <div class="text">11/29/2021</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Reverse logo (Spectra Lighthouse Logo_Reversed.png)</span></div>
            <div class="text">1627 x 465 px</div>
            <div class="text">55KB PNG</div>
            <div class="text">11/29/2021</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/style guide/logos/KrakenDSM_StyleGuide_Logos_04.png">
        </section>

        <section>
            <div class="text">View Assets: <a class="kn-link" href="https://brandfolder.com/lighthouse#!asset/f4rmmwm6763gnnfpcj766tf" target="_blank"> https://brandfolder.com/lighthouse#!asset/f4rmmwm6763gnnfpcj766tf </a> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Standard logo (Prism Lighthouse Logo_Full Color.png)</span></div>
            <div class="text">1504 x 449 px</div>
            <div class="text">44KB PNG</div>
            <div class="text">12/7/2021</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Reverse logo (Prism Lighthouse Logo_Reversed.png)</span></div>
            <div class="text">1504 x 449 px</div>
            <div class="text">43KB PNG</div>
            <div class="text">12/7/2021</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/style guide/logos/KrakenDSM_StyleGuide_Logos_05.png">
        </section>

        <section>
            <div class="text">View Assets: <a class="kn-link" href="https://brandfolder.com/lighthouse#!asset/jnw6kqc469wxknnpqbswxt" target="_blank"> https://brandfolder.com/lighthouse#!asset/jnw6kqc469wxknnpqbswxt </a> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Standard logo (InSite_logo.svg)</span></div>
            <div class="text">4000 x 1388 px</div>
            <div class="text">3KB PNG</div>
            <div class="text">9/30/2020</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/style guide/logos/KrakenDSM_StyleGuide_Logos_06.png">
        </section>

        <section>
            <div class="text">View Assets: <a class="kn-link" href="https://brandfolder.com/lighthouse#!asset/9tkk36jpf7k3rg5436fx6" target="_blank"> https://brandfolder.com/lighthouse#!asset/9tkk36jpf7k3rg5436fx6 </a> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Standard logo (ChatSmart_Logo.png)</span></div>
            <div class="text">231 x 58 px</div>
            <div class="text">12KB PNG</div>
            <div class="text">9/18/2020</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/style guide/logos/KrakenDSM_StyleGuide_Logos_07.png">
        </section>

        <section>
            <div class="text">View Assets: <a class="kn-link" href="https://brandfolder.com/lighthouse#!asset/k52mf3kxzbm5f7h6f2z23f" target="_blank"> https://brandfolder.com/lighthouse#!asset/k52mf3kxzbm5f7h6f2z23f </a> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Standard logo (CloudCompass_logo.png)</span></div>
            <div class="text">327 x 67 px</div>
            <div class="text">15KB PNG</div>
            <div class="text">9/18/2020</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/style guide/logos/KrakenDSM_StyleGuide_Logos_08.png">
        </section>

        <section>
            <div class="text">View Assets: <a class="kn-link" href="https://brandfolder.com/lighthouse#!asset/jm8rrpswqr74cssbqwfvqmk" target="_blank"> https://brandfolder.com/lighthouse#!asset/jm8rrpswqr74cssbqwfvqmk </a> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Standard logo (Navigate_logo_Full Color.png)</span></div>
            <div class="text">754 x 224 px</div>
            <div class="text">15KB PNG</div>
            <div class="text">12/2/2021</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/style guide/logos/KrakenDSM_StyleGuide_Logos_09.png">
        </section>

        <section>
            <div class="text">View Assets: <a class="kn-link" href="https://brandfolder.com/lighthouse#!asset/k7zwm7zh7kvvjxnwvmqs7n" target="_blank"> https://brandfolder.com/lighthouse#!asset/k7zwm7zh7kvvjxnwvmqs7n </a> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Standard logo (PrivSmart_logo.png)</span></div>
            <div class="text">208 x 59 px</div>
            <div class="text">11KB PNG</div>
            <div class="text">9/18/2020</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/style guide/logos/KrakenDSM_StyleGuide_Logos_10.png">
        </section>

        <section>
            <div class="text">View Assets: <a class="kn-link" href="https://brandfolder.com/lighthouse#!asset/2rvfwfs3g5swnrc9sgxrkvxh" target="_blank"> https://brandfolder.com/lighthouse#!asset/2rvfwfs3g5swnrc9sgxrkvxh </a> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Standard logo (ReviewSmart_logo.png)</span></div>
            <div class="text">284 x 58 px</div>
            <div class="text">13KB PNG</div>
            <div class="text">9/18/2020</div>
        </section>

    </div>
</div>