import { Component, Input, HostListener, OnInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.sass']
})
export class LegendComponent implements OnInit, OnDestroy {
  @Input() items;
  @Input() scrollableSectionId;
  currentActiveLink: string | null = null;

  ngOnInit(): void {
    const scrollableSection = document.getElementById(this.scrollableSectionId);
    scrollableSection?.addEventListener('scroll', this.onWindowScroll.bind(this));

    this.currentActiveLink = 'default';
  }
  
  ngOnDestroy(): void {
    const scrollableSection = document.getElementById(this.scrollableSectionId);
    scrollableSection?.removeEventListener('scroll', this.onWindowScroll.bind(this));
  }

  navigate(link: string): void {
    document.getElementById(link)?.scrollIntoView({ behavior: 'smooth' });
  }

  isActive(link: string): boolean {
    return this.currentActiveLink === link;
  }

  onWindowScroll(): void {
    const scrollableSection = document.getElementById(this.scrollableSectionId);
  if (!scrollableSection) return;

  let closestLink = null;
  let closestLinkDistance = null;

  for (const item of this.items) {
    const element = document.getElementById(item.link);
    if (element && scrollableSection) {
      const scrollableTop = scrollableSection.scrollTop;
      const elementTop = element.offsetTop - scrollableSection.offsetTop;
      const distance = Math.abs(elementTop - scrollableTop);

      // Initialize closestLinkDistance if null or update based on closer element
      if (closestLinkDistance === null || distance < closestLinkDistance) {
        closestLinkDistance = distance;
        closestLink = item.link;
      }
    }
  }

  this.currentActiveLink = closestLink;
  }
}