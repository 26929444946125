<app-canvas 
    [ts]="true"
    [js]="true"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [reactUrl]="'https://stackblitz.com/edit/github-rqclvg-1gwzrs?embed=1&file=src%2FApp.js&hideNavigation=1'">
    <div title>Date Picker <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-date-picker
            [min]="minDate"
            [max]="maxDate"
            [value]="value"
            [label]="label"
            [required]="required"
            [dateFormat]="dateFormat"
            [disable]="disabled"
            [readonly]="readOnly"
            [compressed]="compressed"
            (onValueChange)="valueChangeEvent($event)"
            (onValidate)="validateEvent($event)"
        ></kn-date-picker>
    </div>
    <div angular>
        <br/>
        <span class="code-comment">//Note: [dateFormat] property needs month to be passed in capitalized. Per syncfusion docs</span>
    </div>
    <div typeScript>
        {{tsCode}}
    </div>
    <div javascript>
        <span class="code-comment">//You can reference the date range picker using the following code</span>
        {{jsCode}}
    </div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onValueChange():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">event:</span> {{'{ }'}}</div>
                    <div class="obj-item"><span class="label">value:</span> "{{onSelectItems?.value}}"</div>
                </div>
            </div>
            <div class="event-item" [ngClass]="{'active': event2}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event2 = !event2"></i> 
                <div class="obj">onValidate():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">invalid:</span> {{isValid}}</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="section-title">Modifiers</div>
        <div class="kn-spacer"></div>
        <div class="action-input">
            <kn-textbox 
                [label]="'Label'" 
                [value]="label" 
                [(ngModel)]="label"
                (onValueChange)="labelEvent($event)" 
                [clearIcon]="true">
            </kn-textbox>
        </div>
        <div class="action-input">
            <kn-dropdown 
                #knInput 
                [label]="'Date format'" 
                [value]="currentDateFormat" 
                [items]="dateFormatObj" 
                [fields]="dateFormatFields" 
                (onSelect)="formatDateEvent($event)"> 
            </kn-dropdown>
        </div>
        <div class="action-input">
            <kn-date-picker
                [value]="minDate"
                [label]="'Min Date'"
                [dateFormat]="dateFormat"
                (onValueChange)="minDateEvent($event)">
            </kn-date-picker>
        </div>
        <div class="action-input">
            <kn-date-picker
                [value]="maxDate"
                [label]="'Max Date'"
                [dateFormat]="dateFormat"
                (onValueChange)="maxDateEvent($event)">
            </kn-date-picker>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Required'" 
                (value)="controlEvent($event, '[required]=', true, true);" 
                [(ngModel)]="required"
                [disabled]="readOnly || disabled">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Compressed field'" 
                (value)="controlEvent($event, '[compressed]=', true, true);" 
                [(ngModel)]="compressed">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Disabled state'" 
                (value)="controlEvent($event, '[disabled]=', true, true);" 
                [(ngModel)]="disabled"
                [disabled]="readOnly || required">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Read-only'" 
                (value)="controlEvent($event, '[readOnly]=', true, true);" 
                [(ngModel)]="readOnly"
                [disabled]="disabled || required">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Value change event"  (value)="controlEvent($event, '(onValueChange)=', 'onValueChangeEvent($event)', false); onValueChange = !onValueChange"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Validate event"  (value)="controlEvent($event, '(onValidate)=', 'onValidateEvent($event)', false); onValidate = !onValidate"></kn-toggle>
        </div>
    </div>
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">componentId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"dateRangeExample"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">label</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"This is a label"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">value</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"mm/dd/yyyy"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">error</div>
                <div class="item"><i>bool</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dateFormat</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"mm/dd/yyyy"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">required</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">readonly</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">disable</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">compressed</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onValueChange</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onValidate</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
        </div>
    </div>
    <div usage>
        <date-picker-usage></date-picker-usage>
    </div>
    <div designSpec>
        <date-picker-specs></date-picker-specs>
    </div>
    <div accessibility>
        <date-picker-accessibility></date-picker-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Date</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">March 14, 2024</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the Date Picker</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

