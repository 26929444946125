<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">The <span class="kn-bold">Spinner</span> provides clear, visual feedback to the user that the system is working on a request without communicating the duration of the task.</div>
            </section>
 
        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spinner/KrakenDSM_Components_Spinner_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Track: </span>The track visually represents the progress or activity of a process and is presented in a circular shape.</div>
            <div class="text"><span class="kn-bold">2.	Focus</span></div>
            <div class="text"><span class="kn-bold">3.	Scrim: </span>A scrim is a temporary treatment to make content on the surface appear less prominent.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Variants</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spinner/KrakenDSM_Components_Spinner_02.png">

        <section>
            <div class="kn-subtitle-16">Standard variant</div> 
            <div class="text">By default, spinners include a primary blue track that shows the progress.</div>       
        </section>    

        <img class="kn-responsive-img" src="assets/usage guidelines/spinner/KrakenDSM_Components_Spinner_03.png">

        <section>
            <div class="kn-subtitle-16">Over background variant</div> 
            <div class="text">When a spinner is placed on top of a colored background (action buttons, for example), you can reference two color themes: Light and Dark. The Light theme is reserved for lighter-colored backgrounds that retain enough contrast for the track to display legibly with the primary blue track, while the Dark theme is reserved for darker-colored backgrounds that require a white track color.</div>       
        </section> 


        <section>
            <div class="kn-headline-24-med">Use cases</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/spinner/KrakenDSM_Components_Spinner_04.png">
        
        <section>
            <div class="kn-subtitle-16">Screen load or refresh</div> 
            <div class="text">The spinner often serves as the visual indicator that the system is working while users wait for the application screen to load or refresh.</div>       
        </section>
        
        <img class="kn-responsive-img" src="assets/usage guidelines/spinner/KrakenDSM_Components_Spinner_05.png">

        <section>
            <div class="kn-subtitle-16">Component load or refresh</div> 
            <div class="text">Similarly, the spinner can also communicate feedback to users when the system is processing a triggered request that causes a delay in loading or updating a component within the user interface.</div>       
        </section> 

        <img class="kn-responsive-img" src="assets/usage guidelines/spinner/KrakenDSM_Components_Spinner_06.png">

        <section>
            <div class="kn-subtitle-16">Data query</div> 
            <div class="text">Another common example of using the spinner includes executing a database call, search query, or application of a complex filtering configuration. When latency occurs during data transfer and rendering it in the display. The spinner communicates to users that the system is actively processing the request.</div>       
        </section> 


        <section>
            <div class="kn-headline-24-med">General guidelines</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Animation</div> 
            <div class="text">Animation in the spinner consists of a track that rotates clockwise around the circular-shaped focus. The track is displayed as a 65% circle and does not grow to show progress.</div>       
        </section> 

        <section>
            <div class="text">The spinner remains visible until the associated process or activity completes. Once the task is finished, the indicator disappears or transitions into another state, such as a checkmark or a success message.</div>       
        </section> 

        <img class="kn-responsive-img" src="assets/usage guidelines/spinner/KrakenDSM_Components_Spinner_07.png">

        <section>
            <div class="kn-subtitle-16">Positioning</div> 
            <div class="text">The spinner appears during any user action loading. It is most effective when positioned in the exact center of the container (both horizontally and vertically) to attract the user's attention.</div>       
        </section> 

        <img class="kn-responsive-img" src="assets/usage guidelines/spinner/KrakenDSM_Components_Spinner_08.png">

        <section>
            <div class="kn-subtitle-16">Labeling</div> 
            <div class="text">Ensure spinner labels are short, meaningful, and accurate for the pending action. Avoid using jargons and technical language in labels unless absolutely needed for understanding.</div>       
        </section> 

        <section>
            <div class="text"><span class="kn-bold">Do: </span>Loading...</div>
            <div class="text"><span class="kn-bold">Don't: </span>Page loading</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Sizing</div>
        </section>

        <section>
            <div class="text">Spinners come in three sizes: small, default, and large. These are available to fit various contexts. For example, use the small spinner in place of an icon, or use the large spinner for full-page loading.</div>       
        </section> 

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/spinner/KrakenDSM_Components_Spinner_09.png">
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">Small (22 x 22px)</span></div>

                <div class="text">Use the small size within compact spaces; such as the default and small size action buttons.</div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">Default (56 x 56px)</span></div>

                <div class="text">Used with in-page components such as the data grid and panels.</div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">Large (76 x 76px)</span></div>

                <div class="text">Use the large size for global or page-wide use cases.</div>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>            
            <img class="kn-responsive-img" src="assets/usage guidelines/spinner/KrakenDSM_Components_Spinner_10.png">

            <section>
                <div class="kn-subtitle-16">Use with short operations</div>

                <div class="text">If the operation has a specific length and takes less than five (5) seconds to complete, then do not use a spinner. Displaying a spinner for a short duration is distracting and adds little value to the user experience.</div>
            </section>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/spinner/KrakenDSM_Components_Spinner_11.png">

            <section>
                <div class="kn-subtitle-16">Stacking</div>
           
                <div class="text">Do not stack spinners or combine them in close proximity of each other.</div>
            </section>
        </section>
    </div>
</div>