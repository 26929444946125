<app-canvas 
    [theme]="selectedTheme" 
    [js]="true" 
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef">
    <div title>Tabs <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <div class="tab-demo-container" id="tabDemoContainer" [ngClass]="{'responsive-view': isContainerResponsive, 'non-responsive': !isContainerResponsive}">
            <kn-tabs 
                class="example-tabs" 
                [activeTab]="setActiveTab"  
                [minWidth]="'225px'"
                [responsiveTabs]="responsiveTabs"
                (onSelect)="clickEvent($event)">
                <kn-tab [id]="'tab1'" tabTitle="Tab 1">
                    {{content1}}
                </kn-tab>
                <kn-tab [id]="'tab2'" tabTitle="Tab 2" [disabled]="disableTab">
                    {{content2}} 
                </kn-tab>
                <kn-tab [id]="'tab3'" tabTitle="Tab 3">
                    {{content3}} 
                </kn-tab>
                <kn-tab [id]="'tab4'" tabTitle="Tab 4">
                    <div class="img-container"><img class="kn-responsive-img" src="/assets/lighthouse_logo.png"></div>
                </kn-tab>
            </kn-tabs>
        </div>
    </div>
    <div angular></div>
    <div javascript>
        <span class="code-comment">//Initialize the component</span>
        {{initailizeComponentWeb}}
        <span class="code-comment">//Set the tabs object</span>
        {{settingWebTabs}}
        <br/><br/>
        <span class="code-comment">//Setting tab content</span>
        {{settingWebTabContent}}
        <br/><br/>
        <span class="code-comment">//To disable a tab, you can add a disabled property to your list as shown below</span>
        {{disableWebTab2}}
    </div>
    <div web></div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">btnClick():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">active: {{actionEvent.active}}</span></div>
                    <div class="obj-item"><span class="label">id:</span> "{{actionEvent.id}}"</div>
                    <div class="obj-item"><span class="label">index: {{actionEvent.index}}</span></div>
                    <div class="obj-item"><span class="label">title:</span> "{{actionEvent.title}}"</div>
                    <div class="obj-item"><span class="label">width:</span> "{{actionEvent.width}}"</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <div class="action-input">
                <kn-dropdown [label]="'Set active tab'" [items]="tabList" [fields]="tabListFields" [value]="selectedTab" (onSelect)="activeTab($event);"> </kn-dropdown>
            </div>
        </div>
        <div class="item-row info-section">
            <kn-toggle label="Disabled state" [(ngModel)]="disableTab"  (value)="disable($event);" [disabled]="setActiveTab == 1"></kn-toggle>
            <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="This will set Tab 2 as disabled" [tooltipDirection]="'top'"></i>
        </div>
        <div class="item-row">
            <kn-toggle label="Click event" [(ngModel)]="clickEventEnabled"  (value)="controlEvent($event, '(onSelect)=', 'clickEvent($event)', false);"></kn-toggle>
        </div>
        <div class="item-row info-section">
            <kn-toggle label="Responsive tabs"  (value)="controlEvent($event, '[responsiveTabs]=', 'true', true); responsiveEvent($event)"></kn-toggle>
            <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="When toggled on, a resize icon and border will be visible, click and drag the icon to the left, when you let go of the icon you will see the changes" [tooltipDirection]="'top'"></i>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">activteTab</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>1</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">width</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"600px", "100%", "max-content"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">minWidth</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"600px", "100%", "max-content"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">maxWidth</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"600px", "100%", "max-content"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">responsiveTabs</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onSelect</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <tabs-usage></tabs-usage>
    </div>
    <div designSpec>
        <tabs-specs></tabs-specs>
    </div>
    <div accessibility>
        <tabs-accessibility></tabs-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Date</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">August 15, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>removed unsued property</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">May 17, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed an issue with the icon hover effect within the overflow menu</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">April 5, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the tab component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>