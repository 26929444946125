import { Component } from '@angular/core';

@Component({
  selector: 'date-picker-usage',
  templateUrl: './date-picker-usage.component.html',
  styleUrls: ['./date-picker-usage.component.sass']
})
export class DatePickerUsageComponent {

}
