<app-canvas 
    [ts]="true" 
    [js]="true" 
    [disableCanvasTab]="5" 
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component"
    [trigger]="{angular: layoutExample, web: layoutExampleWeb}"
    [reactUrl]="'https://stackblitz.com/edit/github-rqclvg-1tmcoj?embed=1&file=src%2FApp.js'">
    <div title>Panel <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-spinner [size]="'medium'" [isLoading]="isLoading"></kn-spinner>
        <!--Inline slider-->
        <div 
            class="panel-container" 
            *ngIf="slideDirection == 'left' && !isLoading && panelType == 'inline'"
            [ngClass]="{'highlight': showLayout}">
            <kn-panel 
                [sliderContainerElm]="'.panel-container'"
                [dropShadow]="dropShadow"
                [slideDirection]="'left'"
                [panelType]="'inline'"
                [containerToPush]="'.example-page'"
                (onOpen)="openPanel()"
                (onClose)="closePanel()"
            >
                <div slot="panel-header-title" >
                    <div class="kn-headline-24-heavy">Title</div>
                </div>
                <div slot="panel-header-actions" >
                    <i class="kic icon-ic-fpo kn-icon-sm kn-icon"></i>
                </div>
                <div panel-body>               
                    <div class="panel-content">
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                    </div>
                </div>
            </kn-panel>
            
            <div class="example-page">
                <div class="inner-container">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam ut porttitor leo a diam sollicitudin tempor. Feugiat scelerisque varius morbi enim nunc faucibus. At risus viverra adipiscing at in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. Congue eu consequat ac felis donec et odio pellentesque diam. Elementum sagittis vitae et leo duis ut diam. Et odio pellentesque diam volutpat commodo sed.</p> 
                </div>
            </div>
        </div>

        <div 
            class="panel-container" 
            *ngIf="slideDirection == 'right'  && !isLoading && panelType == 'inline'"
            [ngClass]="{'highlight': showLayout}">
            <kn-panel 
                [sliderContainerElm]="'.panel-container'"
                [dropShadow]="dropShadow"
                [slideDirection]="'right'"
                [panelType]="'inline'"
                [containerToPush]="'.example-page'"
                (onOpen)="openPanel()"
                (onClose)="closePanel()"
            >
                <div slot="panel-header-title" >
                    <div class="kn-headline-24-heavy">Title</div>
                </div>
                <div slot="panel-header-actions" >
                    <i class="kic icon-ic-fpo kn-icon-sm kn-icon"></i>
                </div>
                <div slot="panel-body">               
                    <div class="panel-content">
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                    </div>
                </div>
            </kn-panel>
            <div class="example-page">
                <div class="inner-container">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam ut porttitor leo a diam sollicitudin tempor. Feugiat scelerisque varius morbi enim nunc faucibus. At risus viverra adipiscing at in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. Congue eu consequat ac felis donec et odio pellentesque diam. Elementum sagittis vitae et leo duis ut diam. Et odio pellentesque diam volutpat commodo sed.</p> 
                </div>
            </div>
        </div>

        <!--Slider -->
        <div 
            class="panel-container" 
            *ngIf="slideDirection == 'left' && !isLoading && panelType == 'overlay'"
            [ngClass]="{'highlight': showLayout}">
            <kn-panel 
                #sidePanel
                [id]="'examplePanel'"
                [sliderContainerElm]="'.panel-container'"
                [dropShadow]="dropShadow"
                [slideDirection]="'left'"
                [panelType]="'slider'"
                [trigger]="'#toggleInlineSlider'"
                [containerToPush]="'.push-area'"
                (onOpen)="openPanel()"
                (onClose)="closePanel()"
            >   
                <div slot="panel-header-title" >
                    <div class="kn-headline-24-heavy">Title</div>
                </div>
                <div slot="panel-header-actions" >
                    <i class="kic icon-ic-fpo kn-icon-sm"></i>
                </div>
                <div slot="panel-body">               
                    <div class="panel-content">
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                    </div>
                </div>
            </kn-panel>
            
            <div class="example-page">
                <div class="inner-container">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam ut porttitor leo a diam sollicitudin tempor. Feugiat scelerisque varius morbi enim nunc faucibus. At risus viverra adipiscing at in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. Congue eu consequat ac felis donec et odio pellentesque diam. Elementum sagittis vitae et leo duis ut diam. Et odio pellentesque diam volutpat commodo sed.</p> 
                    <kn-button [buttonId]="'toggleInlineSlider'" [buttonType]="'kn-primary'" [buttonText]="triggerLabel"></kn-button>
                </div>
            </div>
        </div>

        <div 
            class="panel-container" 
            *ngIf="slideDirection == 'right'  && !isLoading && panelType == 'overlay'"
            [ngClass]="{'highlight': showLayout}">
            <kn-panel 
                [sliderContainerElm]="'.panel-container'"
                [dropShadow]="dropShadow"
                [slideDirection]="'right'"
                [panelType]="'slider'"
                [trigger]="'#toggleInlineSlider'"
                [containerToPush]="'.push-area'"
                (onOpen)="openPanel()"
                (onClose)="closePanel()"
            >
                <div slot="panel-header-title" >
                    <div class="kn-headline-24-heavy">Title</div>
                </div>
                <div slot="panel-header-actions" >
                    <i class="kic icon-ic-fpo kn-icon-sm"></i>
                </div>
                <div slot="panel-body">               
                    <div class="panel-content">
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                    </div>
                </div>
            </kn-panel>
            
            <div class="example-page">
                <div class="inner-container">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam ut porttitor leo a diam sollicitudin tempor. Feugiat scelerisque varius morbi enim nunc faucibus. At risus viverra adipiscing at in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. Congue eu consequat ac felis donec et odio pellentesque diam. Elementum sagittis vitae et leo duis ut diam. Et odio pellentesque diam volutpat commodo sed.</p> 
                    <kn-button [buttonId]="'toggleInlineSlider'" [buttonType]="'kn-primary'" [buttonText]="triggerLabel"></kn-button>
                </div>
            </div>
        </div>
        <div 
            class="panel-container" 
            *ngIf="slideDirection == 'bottom' && !isLoading && panelType == 'overlay'"
            [ngClass]="{'highlight': showLayout}">
            <kn-panel 
                [sliderContainerElm]="'.panel-container'"
                [dropShadow]="dropShadow"
                [slideDirection]="'bottom'"
                [panelHeight]="'max-content'"
                [panelType]="'slider'"
                [trigger]="'#toggleInlineSlider'"
                [containerToPush]="'.push-area'"
                (onOpen)="openPanel()"
                (onClose)="closePanel()"
            >
                <div slot="panel-header-title" >
                    <div class="kn-headline-24-heavy">Title</div>
                </div>
                <div slot="panel-header-actions" >
                    <i class="kic icon-ic-fpo kn-icon-sm"></i>
                </div>
                <div slot="panel-body">               
                    <div class="panel-content row">
                        <div class="panel-col">
                            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        </div>
                        <div class="panel-col">
                            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        </div>
                    </div>
                </div>
            </kn-panel>
            <div class="example-page">
                <div class="inner-container">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam ut porttitor leo a diam sollicitudin tempor. Feugiat scelerisque varius morbi enim nunc faucibus. At risus viverra adipiscing at in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. Congue eu consequat ac felis donec et odio pellentesque diam. Elementum sagittis vitae et leo duis ut diam. Et odio pellentesque diam volutpat commodo sed.</p> 
                    <kn-button [buttonId]="'toggleInlineSlider'" [buttonType]="'kn-primary'" [buttonText]="triggerLabel"></kn-button>
                </div>
            </div>
        </div>

        <div 
            class="panel-container" 
            *ngIf="slideDirection == 'top'  && !isLoading && panelType == 'overlay'"
            [ngClass]="{'highlight': showLayout}">
            <kn-panel 
                [sliderContainerElm]="'.panel-container'"
                [dropShadow]="dropShadow"
                [slideDirection]="'top'"
                [panelType]="'overlay'"
                [panelHeight]="'max-content'"
                [trigger]="'#toggleInlineSlider'"
                [containerToPush]="'.push-area'"
                (onOpen)="openPanel()"
                (onClose)="closePanel()"
            >
                <div slot="panel-header-title" >
                    <div class="kn-headline-24-heavy">Title</div>
                </div>
                <div slot="panel-header-actions" >
                    <i class="kic icon-ic-fpo kn-icon-sm"></i>
                </div>
                <div slot="panel-body">               
                    <div class="panel-content row">
                        <div class="panel-col">
                            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        </div>
                        <div class="panel-col">
                            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                            <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> Sample label</div>
                        </div>
                    </div>
                </div>
            </kn-panel>
            <div class="example-page">
                <div class="inner-container">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Aliquam ut porttitor leo a diam sollicitudin tempor. Feugiat scelerisque varius morbi enim nunc faucibus. At risus viverra adipiscing at in. Mi in nulla posuere sollicitudin aliquam ultrices sagittis orci. Ac placerat vestibulum lectus mauris ultrices eros in cursus turpis. Nisl pretium fusce id velit ut tortor pretium viverra suspendisse. Congue eu consequat ac felis donec et odio pellentesque diam. Elementum sagittis vitae et leo duis ut diam. Et odio pellentesque diam volutpat commodo sed.</p> 
                    <kn-button [buttonId]="'toggleInlineSlider'" [buttonType]="'kn-primary'" [buttonText]="triggerLabel"></kn-button>
                </div>
            </div>
        </div>
    </div>
    <div angular>

    </div>
    <div typeScript>
        <span class="code-comment">//You do not have to add a trigger, you can use JavaScript to open and close the panel, you will need to create an instance of the panel using ViewChild as shown below.</span>
        <span class="code-comment">//You can manually open the panel by calling "this.panel.open();" and close it by calling "this.panel.close();"</span>
        {{panelOpenCloseFunction}}
    </div>
    <div javascript>
        <span class="code-comment">//Create the component reference</span>
       {{initPanel}}
       <br/>
       <span class="code-comment">//Set the properties</span>
       {{setPanelContent}}
       <br/>
       <br/>
       <span class="code-comment">//You can manually trigger the panel to open and close using the code below</span>
       {{manualOpenCloseEvent}}
    </div>
    <div web></div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onOpen():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">true</span></div>
                </div>
            </div>
            <div class="event-item" [ngClass]="{'active': event2}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event2 = !event2"></i> 
                <div class="obj">onClose():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">true</span></div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Drop shadow'" 
                (value)="dropShadowEvent($event)" 
                [(ngModel)]="dropShadow"
                name="dropshadow"
                [disabled]="panelType == 'inline'">
            </kn-toggle>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Panel Type</div>
        <div class="item-row">
            <kn-radio-button 
            [id]="'type3'" 
            [label]="'Overlay'" 
            [value]="'overlay'" 
            (onSelect)="panelTypeEvent($event)"  
            [(ngModel)]="panelType" 
            [defaultValue]="panelType"
            [name]="'panelTypeGroup'">
            </kn-radio-button> 
            <br/>
            <kn-radio-button 
                [id]="'type2'" 
                [label]="'Inline'" 
                [value]="'inline'" 
                (onSelect)="panelTypeEvent($event)" 
                [defaultValue]="panelType"
                [(ngModel)]="panelType" 
                [name]="'panelTypeGroup'">
            </kn-radio-button> 
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title" id="exampleRightClickZone2">Slide Direction</div>
        <div class="item-row">
            <kn-radio-button 
                [id]="'radio1'" 
                [label]="'Open left'" 
                [value]="'left'" 
                [name]="'radioGroup'" 
                [(ngModel)]="slideDirection" 
                [defaultValue]="slideDirection"
                (onSelect)="sliderDirectionEvent($event)">
            </kn-radio-button> 
            <br/>
            <kn-radio-button 
                [id]="'radio2'" 
                [label]="'Open right'" 
                [value]="'right'" 
                [(ngModel)]="slideDirection" 
                [defaultValue]="slideDirection"
                [name]="'radioGroup'" 
                (onSelect)="sliderDirectionEvent($event)">
            </kn-radio-button> 
            <br/>
            <kn-radio-button 
                [id]="'radio3'" 
                [label]="'Open top'" 
                [value]="'top'" 
                [(ngModel)]="slideDirection" 
                [defaultValue]="slideDirection"
                [name]="'radioGroup'" 
                (onSelect)="sliderDirectionEvent($event)"
                [disabled]="panelType == 'inline'">
            </kn-radio-button> 
            <br/>
            <kn-radio-button 
                [id]="'radio4'" 
                [label]="'Open bottom'" 
                [value]="'bottom'" 
                [(ngModel)]="slideDirection" 
                [defaultValue]="slideDirection"
                [name]="'radioGroup'" 
                (onSelect)="sliderDirectionEvent($event)"
                [disabled]="panelType == 'inline'">
            </kn-radio-button> 
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Page layout</div>
        <div class="item-row">
            <kn-toggle 
                [label]="panelType == 'inline' ? 'Inline layout' : 'Overlay layout'" 
                (value)="showPageLayout($event)" 
                [(ngModel)]="showLayout"
                name="dropshadow">
            </kn-toggle>
            <div class="legend" 
                [ngClass]="{'show': showLayout}">
                <div class="swatch-container">
                    <div class="swatch"></div>
                    <div class="label">.panel-container</div>
                </div>
                <div class="swatch-container">
                    <div class="swatch"></div>
                    <div class="label">.example-page > .inner-container</div>
                </div>
            </div>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">panelId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"examplePanel"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropShadow</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">trigger</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>".trigger-elm", "#triggerElm"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">sliderContainerElm</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>".panel-container", "#panelContainer", "body"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">panelHeight</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"200px", "max-content"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">panelWidth</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"200px", "max-content"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">containerToPush</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>".example-page"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">slideDirection</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"left", "right", "top", "bottom"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">panelType</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"inline", "overlay"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onClose</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onOpen</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <panel-usage></panel-usage>
    </div>
    <div designSpec>
        <panel-specs></panel-specs>
    </div>
    <div accessibility>
        <panel-accessibility></panel-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">Feburary 9, 2024</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>Bug fix – (web version) fixed issue with not allowing custom html attributes to panel content</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Aug 15, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the panel component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
</app-canvas>