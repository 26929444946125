import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kn-spinner',
  templateUrl: './kraken-spinner.component.html'
})
export class KrakenSpinnerComponent implements OnInit {
  @Input() isLoading = false;
  @Input() theme = 'light';
  @Input() size = 'medium';
  
  constructor() { }

  ngOnInit(): void {
  }

}
