import { NgModule } from '@angular/core';
import { KrakenButtonGroupComponent } from './kraken-button-group.component';
import { CommonModule } from '@angular/common';
import { KrakenButtonModule } from '@kraken-ui/kraken-button';

@NgModule({
  declarations: [
    KrakenButtonGroupComponent
  ],
  imports: [
    CommonModule,
    KrakenButtonModule
  ],
  exports: [
    KrakenButtonGroupComponent
  ]
})
export class KrakenButtonGroupModule { }
