import { Component, AfterViewInit, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges } from '@angular/core';

@Component({
  selector: 'kn-contextual-menu',
  templateUrl: './kraken-contextual-menu.component.html',
  styles: [
  ]
})

export class KrakenContextualMenuComponent implements AfterViewInit, OnChanges {
  @Input() componentId: string;
  @Input() triggerId: any;
  @Input() items: any;
  @Input() rightClick = false;
  @Input() dropdownRightAligned = false;
  @Input() menuWidth: string;
  @Output() isOpen = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();

  public showDropdown = false;
  public drpdnHeight = 'auto';
  public drpdnWidth;
  public source: object[] = [];
  public itemList: HTMLElement;
  public popup;
  public dropdownElm;
  public dropdownItemsContainer;
  public rightAligned;
  public knDropdownItemId;
  public triggerElm: any;
  public pointerPosX: number;
  public pointerPosY: number;
  public topPos: string;
  public leftPos: string;
  public defaultDownArrow = false;
  public defaultDownArrowWidth: any;
  public defaultDownArrowTop: any;
  public defaultUpArrow = false;
  public defaultArrowWidth: any;
  public defaultUpArrowTop: any;
  public child2MenuLeftPos: string;
  public child2MenuTopPos: string;
  public child2MenuBottomPos: string;
  public child2DropdownHeight;
  public child2DropdownRight;
  public child2DropdownLocation;
  public child2DropdownBottom;
  public child2MenuDownArrow = false;
  public child2MenuUpArrow = false;
  public child2MenuArrowWidth: any;
  public child2MenuDownArrowTop: any;
  public child2MenuUpArrowTop: any;
  public child3MenuTopPos: string;
  public child3MenuLeftPos: string;
  public child3DropdownHeight;
  public child3DropdownLocation;
  public child3DropdownBottom
  public child3DropdownRight;
  public child3MenuBottomPos;
  public child3MenuDownArrow = false;
  public child3MenuUpArrow = false;
  public child3MenuArrowWidth: any;
  public child3MenuDownArrowTop: any;
  public child3MenuUpArrowTop: any;
  public child4MenuTopPos: string;
  public child4MenuUpArrow = false;
  public child4MenuDownArrow = false;
  public child4MenuArrowWidth: string;
  public child4MenuBottomPos: string;
  public child4MenuLeftPos: string;
  public child4DropdownHeight;
  public child4DropdownBottom;
  public child4DropdownLocation;
  public clickZone: any;
  public scrollDelay = 100;
  public scrollIntervalId: any;
  public menuGap = 4;

  @ViewChild('trigger') trigger: ElementRef;
  constructor(private elm: ElementRef) { }

  ngOnInit() {
    this.componentId = this.componentId ? this.componentId : 'knContextualMenu_' + Math.floor(Math.random()*90000) + 10000;
  }

  ngOnChanges(changes) {
    if(changes.rightClick && !changes.rightClick.firstChange) {
      this.rightClick = changes.rightClick.currentValue;
      if(this.rightClick) {
        this.setRightClick();
      }
    }
  }

  ngAfterViewInit() {
    this.triggerElm = document.querySelector('#' + this.triggerId);
    this.dropdownElm = this.elm.nativeElement.querySelector('#' + this.componentId);
    this.dropdownItemsContainer = this.dropdownElm.querySelector('.inner-container')
    //sub menus
    const  subMenuElmResults = this.elm.nativeElement.querySelector('.kn-sub-menu > .inner-container');
    const  subSubMenuElmResults = this.elm.nativeElement.querySelector('.kn-sub-menu > .kn-sub-sub-menu > .inner-container');

    //Document click event
    document.addEventListener('click', (e) => {
      if (e.target !== this.triggerElm && e.target !== this.dropdownElm) {
        this.showDropdown = false;
        this.isOpen.emit(false);
        e.stopPropagation();
      } else if(e.target == this.triggerElm) {
        if(!this.rightClick) {
          this.showDropdown = !this.showDropdown;
          this.isOpen.emit(this.showDropdown);
          this.popupCalc();
        }
        e.stopPropagation();
      }
    });

    window.addEventListener('scroll', (e) => {
      const elm: any = e.srcElement;
      if(!this.dropdownElm.contains(elm)) {
        this.showDropdown = false;
        this.isOpen.emit(false);
      }
    },true);

    window.addEventListener('resize', () => {
      this.showDropdown = false;
      this.isOpen.emit(false);
    });

    if(this.rightClick) {
      this.setRightClick();
    }
  }

  navScroll(e) {
    if( e.currentTarget.scrollTop > 10 && e.currentTarget.scrollTop < (e.currentTarget.scrollHeight - e.currentTarget.clientHeight)) {
      this.defaultUpArrow = true;
      this.defaultDownArrow = true;
    } if(e.currentTarget.scrollTop == 0)  {
      this.defaultDownArrow = true;
      this.defaultUpArrow = false;
    } if(Math.ceil(e.currentTarget.scrollTop) == (e.currentTarget.scrollHeight - e.currentTarget.clientHeight ))  {
      this.defaultDownArrow = false;
      this.defaultUpArrow = true;
    }
  }

  child2NavScroll(e) {
    if( e.currentTarget.scrollTop > 0 && e.currentTarget.scrollTop < (e.currentTarget.scrollHeight - e.currentTarget.clientHeight)) {
      this.child2MenuUpArrow = true;
      this.child2MenuDownArrow = true;
    } if(e.currentTarget.scrollTop == 0)  {
      this.child2MenuDownArrow = true;
      this.child2MenuUpArrow = false;
    } if(Math.ceil(e.currentTarget.scrollTop) == (e.currentTarget.scrollHeight - e.currentTarget.clientHeight))  {
      this.child2MenuDownArrow = false;
      this.child2MenuUpArrow = true;
    }
  }

  child3NavScroll(e) {
    if( e.currentTarget.scrollTop > 0 && e.currentTarget.scrollTop < (e.currentTarget.scrollHeight - e.currentTarget.clientHeight)) {
      this.child3MenuUpArrow = true;
      this.child3MenuDownArrow = true;
    } if(e.currentTarget.scrollTop == 0)  {
      this.child3MenuDownArrow = true;
      this.child3MenuUpArrow = false;
    } if(Math.ceil(e.currentTarget.scrollTop) == (e.currentTarget.scrollHeight - e.currentTarget.clientHeight))  {
      this.child3MenuDownArrow = false;
      this.child3MenuUpArrow = true;
    }
  }

  child4NavScroll(e) {
    if( e.currentTarget.scrollTop > 0 && e.currentTarget.scrollTop < (e.currentTarget.scrollHeight - e.currentTarget.clientHeight)) {
      this.child4MenuUpArrow = true;
      this.child4MenuDownArrow = true;
    } if(e.currentTarget.scrollTop == 0)  {
      this.child4MenuDownArrow = true;
      this.child4MenuUpArrow = false;
    } if(Math.ceil(e.currentTarget.scrollTop) == (e.currentTarget.scrollHeight - e.currentTarget.clientHeight))  {
      this.child4MenuDownArrow = false;
      this.child4MenuUpArrow = true;
    }
  }
  
  popupCalc() {
    let x;
    let y;
    let triggerWidth;

    //reset the dropdown menu width
    this.drpdnWidth = 'max-content';

    //rest arrow flags upon opeing dropdown
    this.defaultDownArrow = false;
    this.defaultUpArrow = false;

    //change trigger to mouse cursor if right click enabled
    if(this.rightClick) {
      y = this.pointerPosY;
      x = this.pointerPosX;
    } else {
      y = this.triggerElm.getBoundingClientRect().top; 
      x = this.triggerElm.getBoundingClientRect().left;
      triggerWidth = this.triggerElm.getBoundingClientRect().width;
    }

    //Get window dimensions
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;

    //Get default dropdown dimensions
    const dropdownHeight = this.dropdownElm.getBoundingClientRect().height;
    
    //check if default dropdown is too low to the bottom of the window
    if(dropdownHeight + y > windowHeight) {
      this.topPos = (y - dropdownHeight - 10) + 'px';
    } else {
      this.topPos = !this.rightClick ? (this.triggerElm.offsetHeight + y) + 'px' : y + 'px';
    }

    //get default dropdown location
    const defaultMenuWidth = this.dropdownElm.getBoundingClientRect().width < triggerWidth && !this.menuWidth ? triggerWidth : this.dropdownElm.getBoundingClientRect().width;
    const dropdownPos = this.rightClick ? x + defaultMenuWidth : this.triggerElm.getBoundingClientRect().right + defaultMenuWidth;
    
    //assign the new width to the default menu
    this.drpdnWidth = this.menuWidth ? this.menuWidth : defaultMenuWidth + 'px';

    //if default dropdown is scrollable
    if(this.dropdownItemsContainer.scrollHeight > this.dropdownItemsContainer.clientHeight) {
      //down indicator 
      this.dropdownItemsContainer.scrollTop = 0;
      this.defaultDownArrow = true;
    } else {
      this.resetArrowFlags();
    }

    const  child2MenuElm = this.elm.nativeElement.querySelector('.kn-child2-menu');
    const  child3MenuElm = this.elm.nativeElement.querySelector('.kn-child3-menu');
    const  child4MenuElm = this.elm.nativeElement.querySelector('.kn-child4-menu');

    //child 2 menu dropdown dimensions
    if(child2MenuElm) {
      this.child2DropdownHeight = child2MenuElm.getBoundingClientRect().height;
      this.child2DropdownBottom = child2MenuElm.getBoundingClientRect().bottom;
      this.child2DropdownRight = child2MenuElm.getBoundingClientRect().right;
      //add both the default dropdown and the sub dropdown widths together

      this.child2DropdownLocation = child2MenuElm.getBoundingClientRect().width + dropdownPos;
    } else {
      this.child2DropdownLocation = 0;
    }

    //child 3 menu dropdown dimensions
    if(child3MenuElm) {
      this.child3DropdownHeight = child3MenuElm.getBoundingClientRect().height;
      this.child3DropdownBottom = child3MenuElm.getBoundingClientRect().bottom;
      //add all three dropdown widths together
      this.child3DropdownLocation = child3MenuElm.getBoundingClientRect().width + this.child2DropdownLocation;
    } else {
      this.child3DropdownLocation = 0;
    }

    //child 4 menu dropdown dimensions
    if(child4MenuElm) {
      this.child4DropdownHeight = child4MenuElm.getBoundingClientRect().height;
      this.child4DropdownBottom = child4MenuElm.getBoundingClientRect().bottom;
      //add all three dropdown widths together
      this.child4DropdownLocation = child4MenuElm.getBoundingClientRect().width + this.child3DropdownLocation;
    } else {
      this.child4DropdownLocation = 0;
    }

    //check if default dropdown is too far left
    if(windowWidth < (defaultMenuWidth + x) ||
      windowWidth < this.child2DropdownLocation ||
      windowWidth < this.child3DropdownLocation ||
      windowWidth < this.child4DropdownLocation) {
        let rightPos;
        if(this.rightClick) {
          rightPos = Math.abs(defaultMenuWidth - x);
        } else {
          rightPos = Math.abs(defaultMenuWidth - this.triggerElm.getBoundingClientRect().right);
        }
        this.leftPos = rightPos + 'px';
        this.dropdownRightAligned = true;
        this.rightAligned = rightPos + 'px';
      } else {
        this.dropdownRightAligned = false;
        this.rightAligned = "unset";
    }

  }

  setRightClick() {
    this.clickZone = document.getElementById(this.triggerId);
    //Right click event
    this.clickZone.addEventListener('contextmenu', (e) => {
      this.showDropdown = true;
      this.isOpen.emit(true);
      this.topPos = this.pointerPosY + 'px';
      this.leftPos = this.pointerPosX + 'px';
      e.preventDefault();
      this.popupCalc();
    });

    window.addEventListener('mousemove', (e) => {
      this.pointerPosX = e.clientX;
      this.pointerPosY = e.clientY;
    });
  }

  itemHover(item, id, index, event, child) {
    let child2Data;
    let child3Data;
    let child4Data;
    let subMenuHeight;
    let child3MenuHeight;
    let child4MenuHeight;

    const windowHeight = window.innerHeight;

    //Only execute if child data exists
    if(item.children) {
      const elm = this.elm.nativeElement.querySelector(id);
      const elmTop = elm.getBoundingClientRect().top;
      const elmBottom = elm.getBoundingClientRect().bottom;
      const hiddenElms = elm.querySelectorAll('.hide');
      if(hiddenElms.length > 0) {
        hiddenElms.forEach(element => {
          element.classList.remove('hide');
        });
      }

      //Tier 2 menu
      if(child == 1) {
      child2Data = this.elm.nativeElement.querySelector('#knChild2Menu_'+ index);
      if(child2Data) {
        subMenuHeight = child2Data.getBoundingClientRect().height;
        if((subMenuHeight + elmTop) > windowHeight) {
          this.child2MenuTopPos = 'unset';
          this.child2MenuBottomPos = (windowHeight - elmBottom) + 'px'
        } else {
          this.child2MenuBottomPos = 'unset';
          this.child2MenuTopPos = elm.getBoundingClientRect().top + 'px';
        }

        this.child2MenuLeftPos =  this.dropdownRightAligned ?  (Math.abs(elm.getBoundingClientRect().left - child2Data.getBoundingClientRect().width)) + this.menuGap + 'px' : elm.getBoundingClientRect().right - this.menuGap + 'px';
        
        const child2MenuResultsContainer = child2Data.querySelector('.inner-container');
        //when opening the menu reset the view and scroll to the top
        if(!child2Data.contains(event.target)) {
          child2MenuResultsContainer.scrollTop = 0;
        }
        //Check if menu is scrollable
        if(child2MenuResultsContainer.scrollHeight > child2MenuResultsContainer.clientHeight) {
          this.child2MenuArrowWidth = child2Data.clientWidth + 'px';
          if(child2MenuResultsContainer.scrollTop == 0)  {
            this.child2MenuDownArrow = true;
            this.child2MenuUpArrow = false;
          }
        } else {
          this.child2MenuDownArrow = false;
          this.child2MenuUpArrow = false;
        }
      }
    }
    if(child == 2) {
      //Tier 3 menu
      child3Data = this.elm.nativeElement.querySelector('#knChild3Menu_'+ index);
      if(child3Data) {
        child3MenuHeight = child3Data.getBoundingClientRect().height;
        this.child3MenuTopPos = elm.getBoundingClientRect().top + 'px';

        if((child3MenuHeight + elmTop) > windowHeight) {
          this.child3MenuTopPos = 'unset';
          this.child3MenuBottomPos = (windowHeight - elmBottom) + 'px'
        } else {
          this.child3MenuBottomPos = 'unset';
          this.child3MenuTopPos = elm.getBoundingClientRect().top + 'px';
        }
        this.child3MenuLeftPos = this.dropdownRightAligned ? (Math.abs(elm.getBoundingClientRect().left - child3Data.getBoundingClientRect().width)) + this.menuGap + 'px' : elm.getBoundingClientRect().right - this.menuGap + 'px';
        
        const child3MenuResultsContainer = child3Data.querySelector('.inner-container');
        if(child3MenuResultsContainer.scrollHeight > child3MenuResultsContainer.clientHeight) {
          //when opening the menu reset the view and scroll to the top
          if(!child3Data.contains(event.target)) {
            child3MenuResultsContainer.scrollTop = 0;
          }
          this.child3MenuArrowWidth = child3Data.clientWidth + 'px';
          //Check if menu is scrollable
          if(child3MenuResultsContainer.scrollTop == 0)  {
            this.child3MenuDownArrow = true;
            this.child3MenuUpArrow = false;
          } 
        } else {
          this.child3MenuDownArrow = false;
          this.child3MenuUpArrow = false;
        }
      }
    }
    if(child == 3) {
      //Tier 4 menu
      child4Data = this.elm.nativeElement.querySelector('#knChild4Menu_'+ index);
      if(child4Data) {
        child4MenuHeight = child4Data.getBoundingClientRect().height;
        this.child4MenuTopPos = elm.getBoundingClientRect().top + 'px';

        if((child4MenuHeight + elmTop) > windowHeight) {
          this.child4MenuTopPos = 'unset';
          this.child4MenuBottomPos = (windowHeight - elmBottom) + 'px'
        } else {
          this.child4MenuBottomPos = 'unset';
          this.child4MenuTopPos = elm.getBoundingClientRect().top + 'px';
        }
        this.child4MenuLeftPos = this.dropdownRightAligned ? (Math.abs(elm.getBoundingClientRect().left - child4Data.getBoundingClientRect().width)) + this.menuGap + 'px' : elm.getBoundingClientRect().right - this.menuGap + 'px';
        
        const child4MenuResultsContainer = child4Data.querySelector('.inner-container');
        if(child4MenuResultsContainer.scrollHeight > child4MenuResultsContainer.clientHeight) {
          //when opening the menu reset the view and scroll to the top
          if(!child4Data.contains(event.target)) {
            child4MenuResultsContainer.scrollTop = 0;
          }
          this.child4MenuArrowWidth = child4Data.clientWidth + 'px';
          //Check if menu is scrollable
          if(child4MenuResultsContainer.scrollTop == 0)  {
            this.child4MenuDownArrow = true;
            this.child4MenuUpArrow = false;
          } else {
            this.child4MenuDownArrow = false;
            this.child4MenuUpArrow = false;
          }
        }
      }
    }
    } 
  }

  //item selected event
  select(item) {
    //do not emit results when the menu item links to a sub menu
    if(!item.children) {
      this.onSelect.emit(item);
    }

    const menus = this.elm.nativeElement.querySelectorAll('.kn-sub-menu');
    if(menus.length > 0) {
      menus.forEach(element => {
        element.classList.add('hide');
      });
    }
  }

  //set all sub menu scroll arrows flags to false
  resetArrowFlags() {
    this.child2MenuDownArrow = false;
    this.child2MenuUpArrow = false;
    this.child3MenuDownArrow = false;
    this.child3MenuUpArrow = false;
    this.child4MenuDownArrow = false;
    this.child4MenuUpArrow = false;
  }

  //arrow nav up
  scrollUp(id) {
    const elm = this.elm.nativeElement.querySelector(id);
    this.scrollIntervalId = setInterval(() => elm.scrollTop -= 1, 7);
  }

  //arrow nav down
  scrollDown(id) {
    const elm = this.elm.nativeElement.querySelector(id);
    this.scrollIntervalId = setInterval(() => elm.scrollTop += 1, 7);
  }

  //reset arrow nav interval functions
  resetScrollInterval() {
    clearInterval(this.scrollIntervalId);
  }

  //prevent crolling
  noScrolling(e) {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }
}
