import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import { FontawesomeService } from '../../services/fontawesome.service';
import  packageInfo from '../../../../../kraken-tabs/package.json';

export class ActionEvent {
  active: boolean;
  index: number;
  title: string;
  width: string;
}
@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.sass']
})

export class TabsComponent implements OnInit {
  public source: any;
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public elementTag = 'kn-tabs';
  public webElementTag = 'kn-web-tabs';
  public disableTab: number;
  public tab1Name = "Tab 1";
  public tab2Name = "Tab 2";
  public tab3Name = "Tab 3";
  public tab4Name = "Tab 4";
  public webTemplateHtml: string;
  public setActiveTab = 0;
  public activeTabName: string;
  public tabList: any;
  public selectedTab: string;
  public tabListFields: object = { text: 'name', value: 'value'};
  public container;
  public componentRef;
  public content;
  public content1 = `Lorem Ipsum is simply dummy text of the printing and typesetting industry.`;
  public content2 = `Imperdiet dui accumsan sit amet nulla facilisi morbi.`;
  public content3 = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Imperdiet dui accumsan sit amet nulla facilisi morbi.`;
  public clickEventEnabled = false;
  public tabs: any = [];
  public themeFields: object = { text: 'name', value: 'color'};
  public selectedTheme: string;
  public selectedThemeDisplayName: string;
  public componentContent = ``;
  public settingWebTabs;
  public settingWebTabContent;
  public disableWebTab1;
  public disableWebTab2;
  public tabWebSelectTabWeb5;
  public event1 = true;
  public actionEvent;
  public tabDisabled: number;
  public tab1Disabled = false;
  public tab2Disabled = false;
  public tab3Disabled = false;
  public tabDisabledProperty = '';
  public responsiveTabs = false;
  public isContainerResponsive = false;
  public initailizeComponentWeb: any;
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenTabsModule'};
  public angularComponentOptions: any = [
    { option: 'id=', value: "'exampleTabs'"},
    { option: '[activeTab]=', value: '\'' + this.setActiveTab + '\''}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"exampleTabs"'},
    { option: 'active-tab=', id: 1, value: '"' + this.setActiveTab + '"'}
  ];

  public themes: any = [
    {color:"#F0F2F4 ", name: "Gray Lighter"},
    {color:"#434C54", name: "Ink Light"},
    {color: "#fff", name: "White"}
  ];

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService,
    public fontawesomeService: FontawesomeService) { }

  ngOnInit(): void {
    this.actionEvent = ActionEvent;
    
    this.tabList = [
      {id: 1, name: this.tab1Name, value: 0}, 
      {id: 2, name: this.tab2Name, value: 1}, 
      {id: 3, name: this.tab3Name, value: 2},
      {id: 4, name: this.tab4Name, value: 3}
    ];

    this.selectedTab = this.tabList[0].name;
    this.content = 
`<kn-tab tabTitle="Tab 1">
  ${this.content1}
</kn-tab>
<kn-tab tabTitle="Tab2">
  ${this.content2}
</kn-tab>
<kn-tab tabTitle="Tab3">
  ${this.content3}
</kn-tab>
<kn-tab tabTitle="Tab 4">
  <div class="img-container"><img class="kn-responsive-img" src="/assets/lighthouse_logo.png"></div>
</kn-tab>`
    
this.initailizeComponentWeb = `
let tabContainer = document.querySelector('#exampleTabs');
`
    this.settingWebTabs = 
`
let tabObject = 
[
  {'title': 'tab 1'},
  {'title': 'tab 2'},
  {'title': 'tab 3'},
  {'title': 'tab 4'}
]
let knTab = document.querySelector('#exampleTabs kn-tabs');
tabContainer.tabList = tabObject;`;

    this.settingWebTabContent = 
`let tabContent = document.querySelectorAll('#exampleTabs kn-tab .kn-tab-content');
tabContent[0].innerHTML = "Lorem Ipsum is simply dummy text of the printing and typesetting industry.";
tabContent[1].innerHTML = "Imperdiet dui accumsan sit amet nulla facilisi morbi.";
tabContent[2].innerHTML = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Imperdiet dui accumsan sit amet nulla facilisi morbi.";
tabContent[3].innerHTML = "<div class=\\"img-container\\"><img class=\\"kn-responsive-img\\" src=\\"lighthouse_logo.png\\"></div>";`

    this.disableWebTab2 = 
`let tabObject = 
[
  {'title': 'tab 1'},
  {'title': 'tab 2'},
  {'title': 'tab 3', 'disabled': true},
  {'title': 'tab 4'}
]`;

    //set theme
    this.selectedTheme = this.themes[2].color;
    this.selectedThemeDisplayName = this.themes[2].name;

    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.content);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer, this.webTemplateHtml);
    
    setTimeout(() => {
      document.getElementById("tab1").click();
    }, 0);
    
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.content);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  disable(event) {
    this.tabDisabledProperty = '[disabled]="'+ event +'"';
    this.disableTab = event;
    this.tabList[2].disabled = event ? true : false;
    
    this.content = 
`<kn-tab tabTitle="Tab 1">
  ${this.content1}
</kn-tab>
<kn-tab tabTitle="Tab2" ${this.tabDisabledProperty}>
  ${this.content2}
</kn-tab>
<kn-tab tabTitle="Tab3">
  ${this.content3}
</kn-tab>
<kn-tab tabTitle="Tab 4">
  <div class="img-container"><img class="kn-responsive-img" src="/assets/lighthouse_logo.png"></div>
</kn-tab>`
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.content);
  }

  activeTab(event) {
    this.setActiveTab = event.value
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[activeTab]='));
    this.angularComponentOptions[index].value = '"' + event.value +'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.content);

    //web component template
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'active-tab='));
    this.webComponentOptions[webIndex].value = '"' + event.value +'"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }
  clickEvent(event) {
    this.setActiveTab = event.id;
    this.actionEvent = event;
    this.setActiveTab = event.id;
  }

  responsiveEvent(event) {
    const elm = document.getElementById('tabDemoContainer');
    elm.style.width = null;

    if(!event) {
      this.isContainerResponsive = false;
      setTimeout(() => {
        this.responsiveTabs = false;
      }, 500);
    } else {
      this.isContainerResponsive = true;
      this.responsiveTabs = true;
    }

  }
}
