
import { 
  Component, 
  AfterViewInit, 
  Input, 
  ElementRef, 
  ViewChild, 
  Output, 
  EventEmitter,
  ChangeDetectorRef,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'kn-accordion',
  templateUrl: './kraken-accordion.component.html',
  //styleUrls: ['./style.min.css'],
  //encapsulation: ViewEncapsulation.ShadowDom
})
export class KrakenAccordionComponent implements AfterViewInit {
  @Input() componentId: string;
  @Input() autoCollapse = true;
  @Input() controlLeftAligned = false;
  @Input() subTitleInline = false;
  @Input() filledBackground = false;
  @Input() width: string;

  public allInputs;
  public uniqueId: string;

  @ViewChild('knAccordion') knAccordion: any;

  constructor(public element: ElementRef, private ref: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    //a delay is required to gather all the elements
    setTimeout(() => {
    //create uniqueId if client id was not given
    this.uniqueId = this.componentId ? this.componentId : 'knAcc_' + Math.floor(Math.random()*90000) + 10000;

    const accordion = this.knAccordion.nativeElement;
    //group one
    const accordionFirstGroupLabels = accordion.querySelectorAll("[kn-group='first']");
    const accordionFirstGroupInputs = accordion.querySelectorAll("[kn-input-group='first']");
    //group two
    const accordionSecondGroupLabels = accordion.querySelectorAll("[kn-group='second']");
    const accordionSecondGroupInputs = accordion.querySelectorAll("[kn-input-group='second']");
    //group three
    const accordionThreeGroupLabels = accordion.querySelectorAll("[kn-group='third']");
    const accordionThreeGroupInputs = accordion.querySelectorAll("[kn-input-group='third']");
    //all inputs
    this.allInputs = accordion.querySelectorAll('input');
    
    //group one click event handler
    accordionFirstGroupLabels.forEach((e) => {
      e.addEventListener('click', (i) => {
        if(this.autoCollapse) {
          //close all second level accoridons
          this.toggleAllInputs(accordionSecondGroupInputs, false);
          const currentLabel = i.currentTarget.htmlFor;
          if(accordionFirstGroupInputs) {
            accordionFirstGroupInputs.forEach(input => {
              if(input.id != currentLabel) {
                input.checked = false;
              }
            });
          }
        }
      });
    });

    //group two click event handler
    if(accordionSecondGroupLabels) {
      accordionSecondGroupLabels.forEach((e) => {
        e.addEventListener('click', (i) => {
          if(this.autoCollapse) {
            const currentLabel = i.currentTarget.htmlFor;
            if(accordionSecondGroupInputs) {
              accordionSecondGroupInputs.forEach(input => {
                if(input.id != currentLabel) {
                  input.checked = false;
                }
              });
            }
          }
        });
      });
    }

    //group three click event handler
    if(accordionThreeGroupLabels) {
      accordionThreeGroupLabels.forEach((e) => {
        e.addEventListener('click', (i) => {
          if(this.autoCollapse) {
            const currentLabel = i.currentTarget.htmlFor;
            if(accordionThreeGroupInputs) {
              accordionThreeGroupInputs.forEach(input => {
                if(input.id != currentLabel) {
                  input.checked = false;
                }
              });
            }
          }
        });
      });
    }
  }, 200);
  this.ref.detectChanges();
  }

  toggleAllInputs(inputs, toggle) {
    inputs.forEach(i => {
      i.checked = toggle;
    });
  }
}
