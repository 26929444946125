<app-canvas 
    [js]="true"
    [ts]="true"
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [trigger]="{angular: modalButton, web: webModalButton}">
    <div title>Modal <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <div class="demo-modal-buttons" *ngIf="modalType == 'standard'">
            <kn-button 
                [buttonId]="'standardModal'" 
                [buttonType]="'kn-tertiary'" 
                [buttonText]="'Standard modal'" 
                (btnClick)="openModal('modalForm', 'default', 'default', '')">
            </kn-button>
        </div>
        <div class="demo-modal-buttons" *ngIf="modalType == 'warning'">
            <kn-button 
                [buttonId]="'warningModal'" 
                [buttonType]="'kn-primary warning'" 
                [buttonText]="'Warning modal'" 
                (btnClick)="openModal('exampleWarning', 'sm', 'warning', '')">
            </kn-button>
        </div>
        <div class="demo-modal-buttons" *ngIf="modalType == 'danger'">
            <kn-button 
                [buttonId]="'dangerModal'" 
                [buttonType]="'kn-primary error'" 
                [buttonText]="'Destructive modal'" 
                (btnClick)="openModal('exampleDanger', 'sm', 'danger', '')">
            </kn-button>
        </div>
        <div class="demo-modal-buttons" *ngIf="modalType == 'multiStep'">
            <kn-button 
                [buttonId]="'multiSelectModal'" 
                [buttonType]="'kn-tertiary'" 
                [buttonText]="'Multi step modal'" 
                (btnClick)="openModal('multiStepModalExample', 'default', 'default', 'Step \' + step + \' of 2')">
            </kn-button>
        </div>
        <!--Multi size modals-->
        <div class="demo-modal-buttons" *ngIf="modalType == 'multiSize'">
            <kn-button 
                [buttonId]="'multiSizeModalSm'" 
                [buttonType]="'kn-tertiary'" 
                [buttonText]="'small modal'" 
                [active]="modalSize == 'sm'"
                (btnClick)="openModal('exampleMultiSize', 'sm', 'default', '')">
            </kn-button>
            <kn-button 
                [buttonId]="'multiSizeModalDefault'" 
                [buttonType]="'kn-tertiary'" 
                [buttonText]="'default modal'" 
                [active]="modalSize == 'default'"
                (btnClick)="openModal('exampleMultiSize', 'default', 'default', '')">
            </kn-button>
            <kn-button 
                [buttonId]="'multiSizeModalLg'" 
                [buttonType]="'kn-tertiary'" 
                [buttonText]="'Large modal'" 
                [active]="modalSize == 'lg'"
                (btnClick)="openModal('exampleMultiSize', 'lg', 'default', '')">
            </kn-button>
            <kn-button 
                [buttonId]="'multiSizeModalXl'" 
                [buttonType]="'kn-tertiary'" 
                [buttonText]="'X-large modal'" 
                [active]="modalSize == 'xl'"
                (btnClick)="openModal('exampleMultiSize', 'xl', 'default', '')">
            </kn-button>
        </div>
    </div>
    <div angular>
      
    </div>
    <div web>
        
    </div>
    <div typeScript>
        <span class="code-comment">//Import the modal service</span>
        {{imports}}
        <br/><br>
        <span class="code-comment">//Declare service in contructor</span>
        {{constructorRef}}
        <br/><br>
        <span class="code-comment">//Open the modal</span>
        {{openFunction}}
        <br/><br>
        <span class="code-comment">//Close the modal</span>
        {{closeModalFunction}}
        <br/><br>
        <span class="code-comment">//Modal open event, code can be ran when the modal opens. Bind to (onOpen) </span>
        {{modalOpenFunction}}
        <br/><br>
        <span class="code-comment">//Modal close event, code can be ran when the modal closes. Bind to (onClose)</span>
        {{modalCloseFunction}}
    </div>
    <div javascript>
        <span class="code-comment">//Create an instance of the modal</span>
        {{webModalInstance}}
        <br/><br>
        <div *ngIf="">
            <span class="code-comment">//Set the modal steps like this. </span>
            {{webSetSteps}}
            <br/><br>
        </div>
        <span class="code-comment">//Add modal content</span>
        {{webModalContent}}
        <br/><br>
        <span class="code-comment">//Create a modal button instance</span>
        {{webModalButtonInstance}}
        <br/><br>
        <span class="code-comment">//Create a button click event to open the modal</span>
        {{webModalButtonClickEvent}}
        <br/><br>
        <span class="code-comment">//Function to close the modal manually.</span>
        {{webModalCloseFunction}}
        <br/><br>
        <div *ngIf="modalType == 'multiStep'">
            <span class="code-comment">//In order to bind data to a kraken component within the modal template, you need to create a "context" object as shown below. In the modal template above, we can bind to any property on a kraken component. One example is the "modalCloseFunction()" function to the footer's cancel button. In the "context" object, im also binding data to the dropdown component.</span>
            {{bindingTemplateComponents}}
            <br/><br/>
            <span class="code-comment">//You can show and hide each step by binding to the step ids. Then add "prev" and "next" functions. Lastly, add the "prev" and "next" bindings to the context object</span>
            {{webSteps}}
        </div>
    </div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onOpen():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">true</span></div>
                    
                </div>
            </div>
            <div class="event-item" [ngClass]="{'active': event2}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event2 = !event2"></i> 
                <div class="obj">onClose():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">true</span></div>
                    
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="section-title">Modal types</div>
        <div class="item-row">
            <div class="radio-info">
                <kn-radio-button 
                    [radioId]="'multiSizeModal'" 
                    [value]="'multiSize'" 
                    [(ngModel)]="modalType" 
                    [defaultValue]="modalType" 
                    (onSelect)="modalSize = 'default';modalCode($event, '')" 
                    [name]="'modalType'" 
                    [label]="'Modal sizes'">
                </kn-radio-button>
            </div>
        </div>
        <div class="item-row">
            <div class="radio-info">
                <kn-radio-button 
                    [radioId]="'standardRd'" 
                    [value]="'standard'" 
                    [(ngModel)]="modalType" 
                    [defaultValue]="modalType" 
                    (onSelect)="modalSize = '';modalCode($event, '')" 
                    [name]="'modalType'" 
                    [label]="'Standard'">
                </kn-radio-button>
            </div>
        </div>
        <div class="item-row">
            <div class="radio-info">
                <kn-radio-button 
                    [radioId]="'multiStepModal'" 
                    [value]="'multiStep'" 
                    [(ngModel)]="modalType" 
                    [defaultValue]="modalType" 
                    (onSelect)="modalSize = '';modalCode($event, '', 'step')" 
                    [name]="'modalType'" 
                    [label]="'Multi-step variant'">
                </kn-radio-button>
            </div>
        </div>
        <div class="item-row">
            <div class="radio-info">
                <kn-radio-button 
                    [radioId]="'warningModal'" 
                    [value]="'warning'" 
                    [(ngModel)]="modalType" 
                    [defaultValue]="modalType" 
                    (onSelect)="modalSize = 'sm';modalCode($event, 'warning')" 
                    [name]="'modalType'" 
                    [label]="'Warning variant'">
                </kn-radio-button>
            </div>
        </div>
        <div class="item-row">
            <div class="radio-info">
                <kn-radio-button 
                    [radioId]="'dangerModal'" 
                    [value]="'danger'" 
                    [(ngModel)]="modalType" 
                    [defaultValue]="modalType" 
                    (onSelect)="modalSize = 'sm';modalCode($event, 'danger')" 
                    [name]="'modalType'" 
                    [label]="'Destructive variant'">
                </kn-radio-button>
            </div>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle [label]="'Alert banner'" [(ngModel)]="isErrorMessage" (value)="error($event)"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Open event" [(ngModel)]="showOpenEvent" (value)="controlEvent($event, '(open)=', 'onOpen($event)', false);"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Close event" [(ngModel)]="showCloseEvent" (value)="controlEvent($event, '(close)=', 'onClose($event)', false);"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">id</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"exampleModal"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">title</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"'Modal title'"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">type</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"'warning'"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">subText</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"'Step 1 of 2'"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">modalError</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"'error message'"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customWidth</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"'1200px'"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onOpen</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onClose</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>true</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <modal-usage></modal-usage>
    </div>
    <div designSpec>
        <modal-specs></modal-specs>
    </div>
    <div accessibility >
        <modal-accessibility></modal-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">November 14, 2023</div>
                <div class="item">1.1.0</div>
                <div class="item">
                    <ul>
                        <li>added a custom width property</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>Added addition unit testing after component was published</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">June 15, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the modal component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

<!--Standard modal-->
<kn-modal 
    [id]="'modalForm'" 
    [title]="modalHeadline" 
    [size]="'default'"
    (onOpen)="open($event)" 
    (onClose)="modalCloseEvent()"
    [modalError]="modalError">
    <div class="inner-container">
        <div class="kn-tile filled">
            <div class="kn-row">
                <div class="kn-col">
                    <kn-textbox 
                        [textboxId]="'exampleTextbox3'" 
                        [label]="'Label text'" 
                        [(ngModel)]="sTextbox1" 
                        [name]="'textboxName'">
                    </kn-textbox>
                </div>
                <div class="kn-col">
                    <kn-textbox 
                        [textboxId]="'exampleTextbox4'" 
                        [label]="'Label text'" 
                        [(ngModel)]="sTextbox2" 
                        [name]="'textboxName'">
                    </kn-textbox>
                </div>
            </div>
            <div class="kn-row">
                <div class="kn-col">
                    <kn-textbox 
                        [textboxId]="'exampleTextbox5'" 
                        [label]="'Label text'" 
                        [(ngModel)]="sTextbox3" 
                        [name]="'textboxName'">
                    </kn-textbox>
                </div>
                <div class="kn-col">
                    <kn-textbox 
                        [textboxId]="'exampleTextbox6'" 
                        [label]="'Label text'" 
                        [(ngModel)]="sTextbox4" 
                        [name]="'textboxName'">
                    </kn-textbox>
                </div>
            </div>
        </div>
    </div>
    <div class="kn-modal-footer">
        <div class="col-right">
            <kn-button 
                [buttonId]="'cancelButton1'" 
                [buttonType]="'kn-flat'" 
                [buttonText]="'Cancel'" 
                (btnClick)="close('modalForm')">
            </kn-button>
            <kn-button 
                [buttonId]="'actionButton2'" 
                [buttonType]="'kn-primary'" 
                [buttonText]="'Action'">
            </kn-button>
        </div>
    </div>
</kn-modal>

<!--warning modal-->
<kn-modal 
    [id]="'exampleWarning'" 
    [title]="modalHeadline" 
    [type]="'warning'"
    [size]="'sm'"
    (onOpen)="open($event)" 
    (onClose)="modalCloseEvent()"
    [modalError]="modalError">
    <div class="inner-container">
        <div class="kn-modal-info-text">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </div>
    </div>
    <div class="kn-modal-footer">
        <div class="col-right">
            <kn-button 
                [buttonId]="'actionButton'" 
                [buttonType]="'kn-primary warning'" 
                [buttonText]="'Action'">
            </kn-button>
        </div>
    </div>
</kn-modal>

<!--delete modal-->
<kn-modal 
    [id]="'exampleDanger'" 
    [title]="modalHeadline" 
    [type]="'danger'"
    [size]="'sm'"
    (onOpen)="open($event)" 
    (onClose)="modalCloseEvent()"
    [modalError]="modalError">
    <div class="inner-container">
        <div class="kn-modal-info-text">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </div>
        <div class="kn-modal-row">
            <kn-checkbox 
                [id]="'checkboxComponentId'" 
                [checkboxId]="'exampleCheckbox'" 
                [label]="'Yes'" 
                [defaultValue]="checkboxDefault" 
                [(ngModel)]="checkboxDefault" 
                [name]="'checkbox'">
            </kn-checkbox>
        </div>
    </div>
    <div class="kn-modal-footer">
        <div class="col-right">
            <kn-button 
                [buttonId]="'cancelButton'" 
                [buttonType]="'kn-flat'" 
                [buttonText]="'Cancel'" 
                (btnClick)="close('exampleDanger')">
            </kn-button>
            <kn-button 
                [buttonId]="'actionButton'" 
                [buttonType]="'kn-primary error'" 
                [buttonText]="'Action'" 
                [disabled]="!checkboxDefault">
            </kn-button>
        </div>
    </div>
</kn-modal>

<!--multi step modal-->
<kn-modal 
    [id]="'multiStepModalExample'" 
    [title]="modalHeadline" 
    [size]="'default'"
    [subText]="'Step ' + step + ' of 2'"
    (onOpen)="open($event)" 
    (onClose)="modalCloseEvent()"
    [modalError]="modalError">
    <div class="inner-container">
        <!--Step 1-->
        <div *ngIf="step == 1">
            <div class="kn-tile filled">
                <div class="kn-row">
                    <div class="kn-col">
                        <kn-textbox 
                            [textboxId]="'exampleTextbox1'" 
                            [emailValidator]="true" 
                            [label]="'Label text'" 
                            [(ngModel)]="textbox1" 
                            [name]="'textboxName'">
                        </kn-textbox>
                    </div>
                    <div class="kn-col">
                        <kn-dropdown  
                            [value]="'test'"
                            [label]="'Country'" 
                            [placeholder]="'Choose a country'" 
                            [name]="'exampledropdown'">
                        </kn-dropdown>
                    </div>
                </div>
                <div class="kn-row">
                    <div class="kn-col">
                        <kn-textarea 
                            [label]="'Label text'" 
                            [label]="'Textarea example'" 
                            [(ngModel)]="textareaValue" 
                            [name]="'exampleTextarea'">
                        </kn-textarea>
                    </div>
                </div>
            </div>

            <div class="kn-tile filled">
                <div class="kn-row">
                    <div class="kn-col">
                        <kn-radio-button 
                            [radioId]="'radio1ComponentId'" 
                            [value]="true" 
                            [(ngModel)]="defaultRadioOption" 
                            [defaultValue]="defaultRadioOption" 
                            [name]="'radioGroup'" 
                            [label]="'Lorem Ipsum is simply dummy text'">
                        </kn-radio-button>
                        <div class="kn-spacer"></div>
                        <kn-radio-button 
                            [radioId]="'radio2ComponentId'" 
                            [label]="'Contrary to popular belief, Lorem Ipsum is not simply random text.'" 
                            [value]="false" 
                            [(ngModel)]="defaultRadioOption" 
                            [defaultValue]="defaultRadioOption"
                            [name]="'radioGroup'">
                        </kn-radio-button>
                    </div>
                    <div class="kn-col">
                        <kn-toggle 
                            [toggleId]="'toggleComponentId'" 
                            [label]="'Lorem Ipsum is not simply random text'" 
                            [(ngModel)]="toggleValue" 
                            [name]="'toggle'">
                        </kn-toggle>
                        <div class="kn-spacer"></div>
                        <kn-toggle 
                            [toggleId]="'toggleComponent2Id'" 
                            [label]="'All the Lorem Ipsum generators'" 
                            [(ngModel)]="toggle2Value" 
                            [name]="'toggle'">
                        </kn-toggle>
                    </div>
                </div>
            </div>
        </div>
        <!--Step 2-->
        <div *ngIf="step == 2">
            <div class="kn-tile">
                <div class="kn-row ">
                    <div class="kn-col">
                        <div class="kn-subtitle-14">Section 1 Title</div>
                        <div class="row-label">Label 1:</div>
                        <div class="row-label">Label 2:</div>
                        <div class="row-label">Label 3:</div>
                        <div class="row-label">Label 4:</div>
                        <div class="kn-spacer"></div>
                        <div class="kn-subtitle-14">Section 2 Title</div>
                        <div class="row-label">Label 4:</div>
                        <div class="row-item-label">Label 5:</div><div class="row-item-value">It is a long established fact that a reader will be distracted by the readable content. </div>
                    </div>
                    <div class="kn-col">
                        <div class="row-value">Value 1</div>
                        <div class="row-value">Value 2</div>
                        <div class="row-value">Value 3</div>
                        <div class="row-value">Value 4</div>
                        <div class="kn-spacer"></div>
                        <div class="row-value">Lorem Ipsum is simply dummy text</div>
                        <div class="row-item-value">It is a long established fact that a reader will be distracted by the readable content. </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="kn-modal-footer">
        <div class="col-left">
            <kn-button 
                *ngIf="step == 2"
                [buttonId]="'backBtn'" 
                [buttonType]="'kn-tertiary'"
                [buttonText]="'Back'" 
                (btnClick)="prev()">
            </kn-button>
        </div>
        <div class="col-right">
            <kn-button 
                [buttonId]="'cancelButton'" 
                [buttonType]="'kn-flat'" 
                [buttonText]="'Cancel'" 
                (btnClick)="close('multiStepModalExample')">
            </kn-button>
            <kn-button 
                *ngIf="step == 1" 
                [buttonId]="'actionButton'" 
                [buttonType]="'kn-primary'" 
                [buttonText]="'Next'" 
                (btnClick)="next()" 
                [disabled]="false">
            </kn-button>
            <kn-button 
                *ngIf="step == 2" 
                [buttonId]="'actionButton'" 
                [buttonType]="'kn-primary'" 
                [buttonText]="'Create'" 
                (btnClick)="next()" 
                [disabled]="false">
            </kn-button>
        </div>
    </div>
</kn-modal>

<!--modal for multiple sizes-->
<kn-modal 
    [id]="'exampleMultiSize'" 
    [title]="modalHeadline" 
    [size]="modalSize"
    (onOpen)="open($event)" 
    (onClose)="modalCloseEvent()"
    [modalError]="modalError">
    <div class="inner-container">
        <div class="kn-modal-info-text">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
        </div>
    </div>
    <div class="kn-modal-footer">
        <div class="col-right">
            <kn-button 
                [buttonId]="'cancelButton'" 
                [buttonType]="'kn-flat'" 
                [buttonText]="'Cancel'" 
                (btnClick)="close('exampleMultiSize')">
            </kn-button>
            <kn-button 
                [buttonId]="'actionButton'" 
                [buttonType]="'kn-primary'" 
                [buttonText]="'Action'">
            </kn-button>
        </div>
    </div>
</kn-modal>

