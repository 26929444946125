<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text">A <span class="kn-bold">Chip</span> component is a small interactive element used to label, categorize, or highlight specific items or content within an interface. It consists of a contained text label displayed with optional dismiss icon to quickly identify and organize information.</div>
        </section>
       

        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_01.png">
        
        <section>
            <div class="text">Choose the type of chip based on its purpose and author. Does the chip represent an action, communicate information, or filter results? Is its content generated by the product or generated by the user of the product?</div>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">1.	Non dismissible: </span> Non dismissible chips help narrow a user’s intent by presenting dynamically generated suggestions, such as possible responses or search filters. They are displayed as read-only.</div>
            <div class="text"><span class="kn-bold">2.	Dismissible: </span> Dismissible chips allow users to refine search results by allowing them to exclude or include specific preferences in search queries (with each chip representing a filter value currently applied). They are displayed with a dismiss icon positioned to the right of the text label. Dismissible chips do not support editing to change their contents.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_02.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Outlined: </span> Displays each chip with a white background and outlined stroke (default).</div>
            <div class="text"><span class="kn-bold">2.	Filled: </span> Displays the chip with a filled background. This version is reserved only for cases when a higher contrast is needed.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_03.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Enabled </span> </div>
            <div class="text"><span class="kn-bold">2.	Disabled </span>Only the non dismissible chip type is displayed as changes are unable to be performed. </div>
            <div class="text"><span class="kn-bold">3.	Hover </span> </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_04.png">

        <section>
            <div class="text"><span class="kn-bold">A.	Text label: </span> Short, descriptive text describing the chip.</div>
            <div class="text"><span class="kn-bold">B.	Dismiss icon: </span> Chips can be removable. Removable chips have a dismiss icon positioned on the right side of the text label.</div>
            <div class="text"><span class="kn-bold">C.	Container: </span> Primary element that holds content, color, and icons.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Chips versus buttons</div>
            <div class="text">Use chips when representing discrete pieces of information that do not perform an action themselves but provide context or categorization. In contrast, buttons appear consistently and are used to trigger an action.</div>
        </section>

        <section>
            <div class="text">Additionally, chips are smaller in size and are displayed in a pill shape, while buttons are more prominent and displayed in a rectangular shape with rounded corners.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_05.png">

        <section>
            <div class="kn-subtitle-16">Text label</div>
            <div class="text">Text labels are required and should briefly and clearly communicate their purpose to the user. These can represent search terms, filters, or keywords.</div>
        </section>

        <ul class="bullets">
            <li>Do not use punctuation marks such as periods or exclamation points.</li>
            <li>Write chip labels in sentence case.</li>
        </ul>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_06.png">

        <section>
            <div class="kn-subtitle-16">Text overflow</div>
            <div class="text">Text label are truncated when they are too long for the available horizontal space. Ensure that the full text is revealed with a tooltip on hover.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Container</div>
            <div class="text">Chip containers hold the text label and trailing icon. They are displayed with a <span class="kn-bold">13px</span> rounded corner.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_07.png">

        <section>
            <div class="kn-subtitle-16">Size</div>
            <div class="text">Chips come in two different sizes: default and compressed. The compressed size is reserved for displaying chips within specific components.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Default </span></div>
            <div class="text"><span class="kn-bold">2.	Compressed </span></div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_08.png">

        <section>
            <div class="text"><span class="kn-bold">Minimum/maximum sizing</span></div>
            
            <ul class="bullets">
                <li>Non dismissible chips have a maximum width of <span class="kn-bold">322px</span> with a 40 character limit. The minimum size is determined by the width of the displayed characters.</li>
                <li>Dismissible chips have a maximum width of <span class="kn-bold">348px</span> with a 40 character limit. The minimum size is determined by the width of the displayed characters.</li>
            </ul>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_09.png">

        <section>
            <div class="kn-subtitle-16">Chip position and order</div>
            <div class="text">When displaying multiple chips together, place them inline as a row of options (not listed vertically). The order of the chips displayed left to right also reflects the order in which users select them.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_10.png">

        <section>
            <div class="kn-subtitle-16">Chip margins</div>
            <div class="text">Grouped chips are displayed in rows with uniform spacing of <span class="kn-bold">8px</span> between each chip and <span class="kn-bold">14px</span> between each line.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Margins between chips </span></div>
            <div class="text"><span class="kn-bold">B.	Margins betwen each line </span></div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_11.png">

        <section>
            <div class="kn-subtitle-16">Chip group overflow</div>
            <div class="text">Ensure that chip rows do not exceed the horizontal width of the layout column defined for the associated filter field and might wrap to multiple rows as needed.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_12.png">

        <section>
            <div class="kn-subtitle-16">Bulk actions</div>
            <div class="text">In some instances, you can add an action next to a group of chips to easily take an action on the entire group at once. Only reveal actions when more than one chip is displayed.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_13.png">

        <section>
            <div class="kn-subtitle-16">Hovering and dismissing chips</div>
            <div class="text">Chips use interaction targets to differentiate between action elements within the single component. Hovering over the main body of the chip triggers a tooltip when the text label is truncated. The main body target area is determined by the character width.</div>
        </section>

        <section>
            <div class="text">For dismissible chips, hovering and clicking on the dismiss icon removes the chip.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Default chip: </span>A <span class="kn-bold">32px</span> interaction target space is allocated for the close icon.</div>
            <div class="text"><span class="kn-bold">2.	Compressed chip: </span>A <span class="kn-bold">20px</span> interaction target space is allocated for the close icon.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_14.png">

        <section>
            <div class="kn-subtitle-16">Extended color palette</div>
            <div class="text">Use the extended color palette when you need to organize a large amount of content. No specific guidelines apply to which color chip to choose for an experience, however keep the color consistent with the associated category. Using too many colors in certain experiences can introduce a heavy cognitive load on some users.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Integration</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_15.png">

        <section>
            <div class="text"><span class="kn-bold">Please note</span> – Integrating chips within specific components is not currently incorporated into the Kraken Design System.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Inline</div>
            <div class="text">Chips can be integrated within the functionality of specific components such as the multi-select dropdown and the text area component. Ensure chip integrations enhance the parent component functionality and appear as part of the component rather than as a separate entity. Chip functionality maintains a consistent behavior and allows for dynamic sizing.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Faceted search filters</div>
            <div class="text">You can use chips as facets to filter attributes. Using chips in this way helps users refine their search results by excluding or including specific preferences in search queries. Faceted search filter chips are dismissible.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_16.png">

        <section>
            <div class="kn-subtitle-16">Wrapping chip labels</div>
            <div class="text">Display label text on a single line, and do not wrap to multiple lines.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/chip/KrakenDSM_Components_Chip_17.png">

        <section>
            <div class="kn-subtitle-16">Avoid disabling a large group of chips</div>
            <div class="text">In cases where users cannot interact with a large group of chips, consider hiding the group and its greater construct rather than disabling all the individual chips.</div>
        </section>
    </div>
</div>