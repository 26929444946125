<div 
    #panel
    class="kn-panel"
    id="{{panelId}}"
    [ngClass]="
    {
        'horizontal': panelType == 'horizontal',
        'inline-slider': panelType == 'inline',
        'drop-shadow': dropShadow && toggleSlider,
        'kn-slider': sliderContainerElm,
        'left': slideDirection == 'left',
        'right': slideDirection == 'right',
        'top': slideDirection == 'top',
        'bottom': slideDirection == 'bottom',
        'minimized': minimized,
        'maximized': !minimized,
        'hidden': !isPanelLoaded
    }"
    [ngStyle]="
    {
        'left': panelLeft,
        'right': panelRight,
        'top': panelTop,
        'bottom': panelBottom,
        'height': height,
        'width': width
    }"
>
    <div 
        class="kn-panel-header"
        [ngClass]="{'minimized': minimized == true && panelType == 'inline' || minimized == undefined && panelType == 'inline'}"
    >
        <div class="kn-header-title">
            <slot name="panel-header-title"></slot>
        </div>
        <div class="kn-right-col">
            <div class="kn-header-actions" [ngClass]="{'hidden': minimized == true}">
                <slot name="panel-header-actions"></slot>
            </div>
            <i 
                id="{{id}}_close'"
                *ngIf="panelType != 'inline'"
                class="fa-regular fa-xmark kn-icon-sm close-panel kn-icon" 
                (click)="panelToggleEvent()">
            </i>
            <i 
                id="{{id}}_close'"
                *ngIf="panelType == 'inline'"
                [ngClass]="
                {
                    'min-close': !toggleSlider,
                    'max-close': toggleSlider
                }"
                class="fa-regular fa-angles-right close-panel kn-icon" 
                (click)="panelToggleEvent()">
            </i>
        </div>
    </div>
    <div 
        class="kn-panel-body"
        [ngClass]="{'show': minimized != true && minimized != undefined && panelType == 'inline'}">
        <slot name="panel-body"></slot>
    </div>
</div>