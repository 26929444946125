import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import  packageInfo from '../../../../../kraken-checkbox/package.json';

export class Checkbox {
  id: any;
  label: string;
  name: string;
  value: any;
}
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.sass']
})
export class CheckboxComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public webClickEvent;
  public webSetProperties;
  public webRemoveProperties;
  public webErrorMessage;
  public webWarningMessage;
  public componentId = 'checkboxComponentId';
  public elementTag = 'kn-checkbox';
  public label = "Checkbox example";
  public checkboxDisabled = false;
  public checkboxSelectEvent = false;
  public event1 = true;
  public isChecked = false;
  public disabled = false;
  public checkboxEvent;
  public required = false;
  public customError = false;
  public customWarning = false;
  public customMess = "";
  public addCustomMessage = false;
  public setCustomMessage = false;
  public componentVersion = packageInfo.version;
  public checkboxDefault = false;
  public messageType = '';
  public customLabel = false;
  public customLableTemplate: string;
  public customLabelTemp = false;
  public initalizeComponentWeb: any;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenCheckboxModule'};
  public angularComponentOptions: any = [
    { option: '[id]=', id: 0, value: '"\'' + this.componentId +'\'"'},
    { option: '[checkboxId]=', id: 1, value: '"\'exampleCheckbox\'"'},
    { option: '[label]=', id: 2, value: '"\'' + this.label + '\'"'},
    { option: '[defaultValue]=', id: 3, value: '"checkboxDefault"'},
    { option: '[(ngModel)]=', id: 3, value: '"checkboxDefault"'},
    { option: '[name]=', id: 4, value: '"\'checkbox\'"'},
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"' + this.componentId +'"'},
    { option: 'checkbox-id=', id: 1, value: '"exampleCheckbox"'},
    { option: 'label=', id: 2, value: '"' + this.label + '"'},
    { option: 'default-value=', id: 3, value: '"true"'},
    { option: 'name=', id: 4, value: '"checkbox"'}
  ];

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);

    this.initalizeComponentWeb = `
let checkbox = document.querySelector('#checkboxComponentId');
    `
    this.webSetProperties = 
    `
checkbox.disabled = true;
    `

    this.webRemoveProperties =
    `
checkbox.disabled = false;
checkbox.customError = false;
    `
    this.webErrorMessage =
    `
checkbox.customError = true;
checkbox.customMess = 'an error has occurred';
    `
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  labelText(event) {
    this.label = event;
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[label]='));
    this.angularComponentOptions[index].option = '[label]=';
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'label='));
    this.webComponentOptions[webIndex].option = 'label=';
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  checkboxSelection(event) {
    this.checkboxEvent = new Checkbox;
    this.checkboxEvent.id = event.id;
    this.checkboxEvent.name = event.name;
    this.checkboxEvent.value = event.value;
    this.checkboxEvent.label = event.label;
  }

  customMessToggle(event) {
    this.addCustomMessage = event;
    if(!event) {
      this.removeCustomMessage();
    }
  }

  removeCustomMessage() {
    this.messageType = "";
    this.customError = false;
    this.customWarning = false;
    this.controlEvent(false, '[customError]=', true, true); 
    this.controlEvent(false, '[customWarning]=', true, true);  
    this.controlEvent(false, '[customMess]=', true, true); 
  }

  customMessage(event) {
    if(event.value == 'radioError') {
      this.customError = true;
      this.customWarning = false;
      this.controlEvent(false, '[customWarning]=', true, true);  
      this.controlEvent(false, '[customMess]=', '\'' + this.customMess +'\'', true);
      this.controlEvent(event, '[customError]=', true, true); 
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    } else {
      this.customError = false;
      this.customWarning = true;
      this.controlEvent(false, '[customError]=', true, true); 
      this.controlEvent(false, '[customMess]=', '\'' + this.customMess +'\'', true);
      this.controlEvent(event, '[customWarning]=', true, true); 
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    }
  }

  message(event) {
    this.customMess = event;
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[customMess]='));
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'custom-mess='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  customTemplateEvent(event) {
    if(event) {
      this.controlEvent(true, '[customLabel]=', 'true', false);
      this.controlEvent(false, '[label]=', 'true', false);
      //custom template
      this.customLableTemplate = 
`<ng-template #labelTemplate>
  Custom label with <span class="kn-bold">HTML</span>
</ng-template>`
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.customLableTemplate);
    } else {
      this.controlEvent(false, '[customLabel]=', 'false', false);
      this.controlEvent(true, '[label]=', ''+ this.label +'', false);
      this.customLableTemplate = "";
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.customLableTemplate);
    }
  }

}
