import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SitemapService {

  constructor() { }

  siteMapData() {
    const siteMap = [
      {item: 'Buttons', route: '/action-button', tags: 'action,button', group: 3, subGroup: 9},
      {item: 'Tabs', route: '/tabs', tags: 'navigation, tabs, tabbing', group: 3, subGroup: 8},
      {item: 'Textbox', route: '/textbox', tags: 'inputs,textbox,form,forms', group: 3, subGroup: 10},
      {item: 'Design Patterns', route: '', tags: 'design, patterns', group: 3, subGroup: 3},
      {item: 'Icon Catalog', route: '/icon-catalog', tags: 'icons, catalog,fonts', group: 2, subGroup: 4},
      {item: 'Single select dropdown', route: '/dropdown', tags: 'form fields, single select, single, select, dropdown, dropdowns, dropdown list, lists, list', group: 3, subGroup: 10},
      {item: 'Menu button', route: '/menu-button', tags: 'button, menu, lists, dropdown', group: 3, subGroup: 9},
      {item: 'Split button', route: '/split-button', tags: 'button, split, multiple', group: 3, subGroup: 9},
      {item: 'Icon button', route: '/icon-button', tags: 'button, icon, icons, icon button', group: 3, subGroup: 9},
      {item: 'Textarea', route: '/textarea', tags: 'inputs,textarea,forms,form', group: 3, subGroup: 10},
      { item: 'Multi select dropdown', route: '/multi-select', tags: 'inputs,dropdowns,dropdown, forms,form, multi, select', group: 3, subGroup: 10},
      { item: 'Radio button', route: '/radio-button', tags: 'buttons,button,radio, forms,form, select', group: 3, subGroup: 10},
      { item: 'Checkbox', route: '/checkbox', tags: 'checkbox,button, buttons, forms,form, select', group: 3, subGroup: 10},
      { item: 'Tooltip', route: '/tooltip', tags: 'tooltip,tool tip, popup, pop up', group: 3, subGroup: 11},
      { item: 'Modal', route: '/modal', tags: 'modal,dialog, popup, pop up', group: 3, subGroup: 11},
      { item: 'Searchbox', route: '/searchbox', tags: 'search, autocomplete, search box, type ahead', group: 3, subGroup: 10},
      { item: 'Toggle', route: '/toggle', tags: 'toggle, switch, button, form, forms, check', group: 3, subGroup: 10},
      { item: 'Design principles', route: '/getting-started/design-principles', tags: 'design, designers, principles', group: 15, subGroup: 1},
      { item: 'Color palettes', route: '/palettes/color-palettes', tags: 'color, palettes, colors', group: 2, subGroup: 3},
      { item: 'Button palettes', route: '/palettes/buttons', tags: 'color, palettes, colors, buttons, button, primary, tertiary, error, warning', group: 2, subGroup: 3},
      { item: 'Iconography', route: '/palettes/iconography', tags: 'icons, icon, iconography, palettes', group: 2, subGroup: 3},
      { item: 'Typography', route: '/palettes/typography', tags: 'type, typography, fonts, font, text', group: 2, subGroup: 3},
      { item: 'Layout background', route: '/palettes/layout-background', tags: 'layout, background, colors', group: 2, subGroup: 3},
      { item: 'Notifications and Alerts', route: '/palettes/notifications', tags: 'notification, alerts, colors, notify', group: 2, subGroup: 3},
      { item: 'Body text', route: '/typography/body-text', tags: 'body, text, colors', group: 2, subGroup: 2},
      { item: 'Capitalization', route: '/typography/capitalization', tags: 'capitalization, capital', group: 2, subGroup: 2},
      { item: 'Labels and Values', route: '/typography/labels-values', tags: 'capitalization, capital', group: 2, subGroup: 2},
      { item: 'Headings and Subheadings', route: '/typography/headings-subheadings', tags: 'heading, headings, subheadings,sub,', group: 2, subGroup: 2},
      { item: 'Fonts', route: '/typography/fonts', tags: 'fonts, text,', group: 2, subGroup: 2},
      { item: 'Grid layout', route: '/style-guide/grid-layout', tags: 'grid, layout, grid layout', group: 2, subGroup: 0},
      { item: 'Elevation', route: '/style-guide/elevation', tags: 'elevation', group: 2, subGroup: 0},
      { item: 'Spacing', route: '/style-guide/spacing', tags: 'spacing, padding, scaling, scale', group: 2, subGroup: 0},
      { item: 'Logos', route: '/style-guide/logos', tags: 'logo, logos, branding', group: 2, subGroup: 0},
      { item: 'Motion Easing', route: '/style-guide/motion-easing', tags: 'easing, motion, speed, motion easing', group: 2, subGroup: 6},
      { item: 'Motion Speed', route: '/style-guide/motion-speed', tags: 'speed, motion, speed, motion speed', group: 2, subGroup: 6},
      { item: 'Motion Effects', route: '/style-guide/motion-effects', tags: 'effects, motion, effects, motion effects', group: 2, subGroup: 6},
      { item: 'Content Usage', route: '/style-guide/content-usage', tags: 'content, usage, content usage', group: 2, subGroup: 14},
      { item: 'Content Formatting', route: '/style-guide/content-formatting', tags: 'content, formatting, content formatting', group: 2, subGroup: 14},
      { item: 'Content Notifications', route: '/style-guide/content-notifications', tags: 'content, notifications, content notifications', group: 2, subGroup: 14},
      { item: 'Content Validations', route: '/style-guide/content-validation', tags: 'content, validations, content validations', group: 2, subGroup: 14},
      { item: 'Contact', route: '/support/contact', tags: 'contact, support', group: 4, subGroup: 3},
      { item: 'Roadmap', route: '/support/roadmap', tags: 'roadmap, road, map', group: 4, subGroup: 3},
      { item: 'Release Notes', route: '/support/release-notes', tags: 'release, support, notes, release notes', group: 4, subGroup: 3},
      { item: 'Components', route: '/components', tags: 'release, support, notes, release notes', group: 3, subGroup: 0},
      { item: 'Why a Design System?', route: '/about/why-dsm', tags: 'why, dsm, design system, design, system, about', group: 5, subGroup: 0},
      { item: 'Engaging the UX team', route: '/about/engaging-ux', tags: 'engage, UX, ux team, team, contact, about', group: 5, subGroup: 0},
      { item: 'Browser Support', route: '/about/integration', tags: 'usage, integrations, browsers, support, browser support', group: 5, subGroup: 0},
      { item: 'Process', route: '/about/process', tags: 'review, testing, process, about', group: 5, subGroup: 0},
      { item: 'Accessibility', route: '/about/accessibility', tags: 'accessibility, aa, aaa, compliance', group: 5, subGroup: 0},
      { item: 'UI Kit', route: '/getting-started/ui-kit', tags: 'UI, kitt, ui kit, getting started', group: 15, subGroup: 1},
      { item: 'For Developers', route: '/getting-started/for-developers', tags: 'developers, getting started', group: 1, subGroup: 15},
      { item: 'Icon Styling', route: '/icon-styles', tags: 'icons, icon styling, styling, styles', group: 2, subGroup: 4},
      { item: 'Data Grid', route: '/data-grid', tags: 'grid, grids, data, data grid', group: 3, subGroup: 16},
      { item: 'Home', route: '/home', tags: 'home, home page, start', group: 7, subGroup: 0},
      { item: 'Design Resources', route: '/getting-started/design-resources', tags: 'design, resources, design resources, getting started, designers', group: 15, subGroup: 1},
      { item: 'UI Framework', route: '/getting-started/ui-framework', tags: 'developer, framework, UI, UI framework, getting started, frameworks', group: 15, subGroup: 15},
      { item: 'Development Resources', route: '/getting-started/development-resources', tags: 'developer, development, getting started, resources', group: 15, subGroup: 15},
      { item: 'Theming', route: '/getting-started/theming', tags: 'developer, theming, getting started', group: 15, subGroup: 15},
      { item: 'Accordion', route: '/accordion', tags: 'navigation,accordion,nav,form', group: 3, subGroup: 10},
      { item: 'Spinner', route: '/spinner', tags: 'spinner,activity,indicator,loading,loader,notification,notifications', group: 3, subGroup: 12},
      { item: 'Masthead', route: '/masthead', tags: 'header, navigation, masthead, nav, banner', group: 3, subGroup: 8},
      { item: 'Footer', route: '/footer', tags: 'footer, navigation, nav, banner', group: 3, subGroup: 8},
      {item: 'Contextual Menu', route: '/contextual-menu', tags: 'menu,contextual menu,contextual,forms, form, dropdown, dialog, navigation', group: 3, subGroup: 8},
      {item: 'Panel', route: '/panel', tags: 'panel, layout, slider, overlay', group: 3, subGroup: 13},
      { item: 'Progress Indicator', route: '/progress-indicator', tags: 'progress,progress bar,bar,loader,notification,notifications', group: 3, subGroup: 12},
      { item: 'Toast', route: '/toast', tags: 'toast,notification,notifications,communication, action', group: 3, subGroup: 12},
      { item: 'Chip', route: '/chip', tags: 'chip,tag,pill,filter', group: 3, subGroup: 10},
      { item: 'Slider', route: '/slider', tags: 'slider,range, slider,', group: 3, subGroup: 10},
      {item: 'Button Group', route: '/button-group', tags: 'action,button, group, button group', group: 3, subGroup: 9},
      { item: 'Spin Box', route: '/spin-box', tags: 'input, spin box, form, forms', group: 3, subGroup: 10},
      { item: 'Date Range Picker', route: '/date-range-picker', tags: 'date, range, picker, calendar, daterangepicker, date range picker', group: 3, subGroup: 10},
      { item: 'Date Picker', route: '/date-picker', tags: 'date, range, picker, calendar, datepicker, date picker', group: 3, subGroup: 10},
      { item: 'Spreadsheet', route: '/spreadsheet', tags: 'sheet, spreadsheet, excel', group: 3, subGroup: 16},
      { item: 'Tile', route: '/style-guide/tile', tags: 'tile, container, grid layout, pattern', group: 2, subGroup: 0},
    ]

    return siteMap;
  }
}
