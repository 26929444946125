<div class="kn-split-btn">
    <div class="kn-btn-group">
        <button 
            id="{{buttonId}}" 
            class="kn-btn {{buttonType}}" 
            aria-label="buttonText"
            [ngClass]="{
                'disabled':  disabled,
                'focus': active
                }"
                (click)="onClick(1)">
                <i 
                    *ngIf="buttonIcon" 
                    class="{{buttonIcon}} icon-left"></i>
                <span class="btn-text">{{buttonText}}</span>
        </button>
        <button 
            id="{{buttonId}}_arrow" 
            class="kn-btn kn-split-menu-btn {{buttonType}}" 
            aria-label="dropdown arrow"
            [ngClass]="{
                'disabled':  disabled,
                'focus': showDropdown
                }"
            (click)="onClick(2)"
            tabindex="0"
            (keydown)="keydown($event)">
            <i class="fa-solid fa-chevron-down" [ngClass]="{'rotate': showDropdown}"></i>
        </button>
    </div>

    <!--Dropdown section-->
    <div 
        class="kn-dropdown" 
        [ngClass]="
        {
            'show': showDropdown, 
            'kn-btn-dropdown-content-width': contentWidth
        }" 
        [ngStyle]="
        {
            'top': topPos, 
            'width': dropdownCustomWidth ? dropdownCustomWidth : drpdnWidth,
            'height': dropdownCustomHeight ? dropdownCustomHeight : height,
            'left': leftPos ? leftPos : 'unset'
        }">
        <!--Object arrays-->
        <ng-container *ngIf="fields">
            <ng-container *ngFor="let key of source">
                <div class="kn-dropdown-item"  
                    [ngClass]="{'disabled': key.origin.disabled}"
                    (click)="valueChange(key)">
                    <div *ngIf="!template">{{key.text}}</div>
                    <div *ngIf="template">
                        <ng-container [ngTemplateOutlet]="customTemplate" [ngTemplateOutletContext]="{data: key.origin}"></ng-container>
                    </div>
                </div>
                <div *ngIf="key.origin.divider" class="kn-divider"></div>
            </ng-container>
        </ng-container>

        <!--Non Object arrays-->
        <ng-container *ngIf="!fields">
            <ng-container *ngFor="let key of items">
                <div class="kn-dropdown-item"  (click)="valueChange(key)">
                    <div *ngIf="!template">{{key}}</div>
                    <div *ngIf="template">
                        <ng-container [ngTemplateOutlet]="customTemplate" [ngTemplateOutletContext]="{data: key}"></ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <!--No items provided-->
        <ng-container *ngIf="!items">
            <ng-container [ngTemplateOutlet]="customTemplate"></ng-container>
        </ng-container>

    </div>
</div>