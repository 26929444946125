import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';
@Component({
  selector: 'tabs-accessibility',
  templateUrl: './tabs-accessibility.component.html',
  styleUrls: ['./tabs-accessibility.component.sass']
})
export class TabsAccessibilityComponent implements OnInit {
  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;

  constructor(private templateService: TemplateUpdateService) { }

  ngOnInit(): void {
  }

}
