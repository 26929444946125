<div class="kn-checkbox kn-input-contain" [ngClass]="{'disabled': disabled}">
    <div class="inner">
        <div class="kn-field-validation" *ngIf="!disabled && label != '' && customError || customWarning">
            <kraken-field-validation 
                [field]="inputField" 
                [fieldName]="name" 
                [customWarning]="customWarning" 
                [customError]="customError" 
                [customMess]="customMess">
            </kraken-field-validation>
        </div>
        <label class="kn-checkbox-label">
            <div class="kn-checkbox-inner">
                <input 
                    #inputField="ngModel"
                    [ngClass]="{'input-error': 
                        inputField.invalid && (inputField.dirty || inputField.touched) ||
                        customError,
                        'input-warning': customWarning}"
                    type="checkbox" 
                    class="kn-checkbox-input" 
                    id="{{checkboxId}}" 
                    [attr.aria-label]="label ? label : 'kraken checkbox'"
                    (ngModelChange)="select($event)" 
                    [(ngModel)]="defaultValue"
                    (change)="propagateChange(defaultValue)"
                    required="{{required}}"
                    [checked]="defaultValue"
                    name="{{name}}">
            </div>
            <span class="checkbox-label">
                <span *ngIf="!customLabel">{{label}}</span>
                <span *ngIf="customLabel">
                    <ng-container [ngTemplateOutlet]="labelTemplate"></ng-container>
                </span>
            </span>
        </label>
    </div>
</div>