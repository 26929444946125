import { Component, OnInit, Input, Output, ElementRef, EventEmitter, ContentChild, TemplateRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'kn-icon-menu',
  templateUrl: './kraken-icon-menu.component.html',
  styles: [
  ]
})
export class KrakenIconMenuComponent implements OnInit, AfterViewInit {
  @Input() iconId: string;
  @Input() componentId: string;
  @Input() icon = 'fa-regular fa-chevron-down kn-icon-sm';
  @Input() disabled: boolean;
  @Input() active = false;
  @Input() template = false;
  @Input() items: any;
  @Input() fields: any;
  @Input() animateIcon = false;
  @Input() contentWidth = false;
  @Input() drpdnWidth = '100%';
  @Input() dropdownCustomWidth: any;
  @Input() dropdownCustomHeight: any;
  @Input() dropdownLeftAligned = false;
  @Input() activeOnOpen = false;
  @Output() btnClick = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();

  public showDropdown = false;
  public topPos: string;
  public width: string;
  public height = 'auto';
  public source: object[] = [];
  public dropdownElm: HTMLElement;
  public iconElement;
  public rightAligned;
  public leftPos;
  public rightPos;

  @ContentChild(TemplateRef) customTemplate: TemplateRef<any>;

  constructor(private elm: ElementRef) { }


  ngOnInit(): void {
    this.componentId = this.componentId ? this.componentId : 'knIconMenu_' + Math.floor(Math.random()*90000) + 10000;
    this.iconId = this.iconId ? this.iconId : 'knIconMenu_icon' + Math.floor(Math.random()*90000) + 10000;
  }

  ngAfterViewInit() {
    this.iconElement = this.elm.nativeElement.querySelector('#' + this.iconId);
    this.dropdownElm = this.elm.nativeElement.querySelector('#' + this.componentId + ' .kn-dropdown');

    document.addEventListener('click', (e: any) => {
      if (e.target.parentElement != this.iconElement.parentElement && e.target !== this.dropdownElm) {
        this.showDropdown = false;
        e.stopPropagation();
      }
    });

    this.iconElement.addEventListener('click', () => { 
      this.showDropdown = !this.showDropdown;
      this.popupCalc();

      //arrow navigation
      this.itemListEventListener();
    });

    window.addEventListener('scroll', () => {
      this.popupCalc();
    },true);

    window.addEventListener('resize', () => {
      this.popupCalc();
    });

    //prevent timing issue with web component
    setTimeout(() => {
      //check if custom fields are present
      this.fieldExists();
    }, 0);
  }

  fieldExists() {
    for (const key in this.items)
    {
      if(this.fields) {
        
        //check if object array has the required fields
        const indexedItem = this.items[key];
        if (Object.prototype.hasOwnProperty.call(indexedItem, this.fields.text) ) {
          const item : [] = [];
          item['text'] = indexedItem[this.fields.text];
          item['value'] = indexedItem[this.fields.value];
          item['origin'] = indexedItem;
          this.source.push(item);
        } 
      }
    }
  }

  onClick() {
		this.btnClick.emit(
      {
        id: this.iconId,
        icon: this.icon,
        active: this.active
      });
	}

  valueChange(event) {
    this.onSelect.emit(event);
  }

  navigateItemList(e){
    const nextElm = (e.currentTarget.nextElementSibling as  HTMLElement);
    const prevElm = (e.currentTarget.previousElementSibling as  HTMLElement);
    const items = this.dropdownElm.children;
    const itemList = Array.from(items);

    if(e.key == "ArrowDown" && nextElm) {
      nextElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });

      nextElm.classList.add('focus');
    } 

    if(e.key == "ArrowUp" && prevElm) {
      prevElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });
      prevElm.classList.add('focus');
    }

    if(e.key == "Enter") {
      e.currentTarget.click();
      e.stopImmediatePropagation();
    }
    e.preventDefault();
  }

  itemListEventListener() {
    const items = this.dropdownElm.children;
    const itemList = Array.from(items);
    let index = 0;
    itemList.forEach(i => {
      index = index + 1;
      i.classList.remove('focus');
      i.setAttribute('tabindex', '' + index + '');
      i.addEventListener('keydown', (e) => {
        this.navigateItemList(e);
      });
    });
  }

  keydown(e) {
    if(e.key == 'ArrowDown') {
      const items = this.dropdownElm.children;
      (items[0] as HTMLElement).focus();
    }
  }

  popupCalc() {
    const y = this.iconElement.getBoundingClientRect().top; 
    this.drpdnWidth = this.dropdownCustomWidth ? this.dropdownCustomWidth : this.dropdownElm.offsetWidth + 'px'; 
    this.topPos = (this.iconElement.offsetHeight + y) + 'px';

    if(this.dropdownLeftAligned) {
      this.leftPos = this.iconElement.getBoundingClientRect().left + 'px';
    } else {
      this.leftPos = Math.abs(this.iconElement.getBoundingClientRect().right - this.dropdownElm.offsetWidth) + 'px';
    }
  }

}
