import { NgModule } from '@angular/core';
import { KrakenMultiSelectComponent } from './kraken-multi-select.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { KrakenTooltipModule } from '@kraken-ui/kraken-tooltip';
import { KrakenFieldValidationComponent } from './kraken-field-validation.component';

@NgModule({
  declarations: [KrakenMultiSelectComponent, KrakenFieldValidationComponent],
  imports: [
    CommonModule,
    FormsModule,
    KrakenTooltipModule
  ],
  exports: [KrakenMultiSelectComponent, KrakenFieldValidationComponent]
})
export class KrakenMultiSelectModule { }
