import { Component, Input, OnInit, ViewEncapsulation, ElementRef, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DocumentationService } from '../services/documentation.service';
import  themePackageInfo from '../../../../kraken-theme/package.json';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.sass'],
  encapsulation: ViewEncapsulation.None
})
export class CanvasComponent implements OnInit, OnChanges {
  @Input() theme: string;
  @Input() ts: boolean;
  @Input() js: boolean;
  @Input() disableDemoTab: number;
  @Input() disableCanvasTab: number;
  @Input() isWebTabDisabled = false;
  @Input() isEventTabDisabled = false;
  @Input() isAccessibilityTabDisabled = false;
  @Input() isPropertiesTabDisabled = false;
  @Input() htmlCode: any;
  @Input() component: any;
  @Input() angularHtml: any;
  @Input() trigger: any;
  @Input() webHtml: any;
  @Input() htmlCodeLabel = "component.html";
  @Input() reactUrl;

  public hide: boolean;
  public show: boolean;
  public expandComponent: boolean;
  public angularCode = true;
  public domain = window.location.protocol + '//' + window.location.host;
  public isCopied = false;
  public copyText = 'Copy code snippet';
  public headerActiveTab = 0;
  public activeCodeTab = 0;
  public prodDomain = 'https://kraken.lighthouseglobal.io';
  public devDomain = 'https://kraken-dsm.apps.sea.pcfdev.lighthouse.com/';
  public peerDependencies = [];
  public componentId;
  public reactUrlSanitized;
  public showReactEmbed = false;


  constructor(
    private elm: ElementRef, 
    public router: Router, 
    private route: ActivatedRoute,
    public docService: DocumentationService,
    public GAService: GoogleAnalyticsService,
    private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    //get params if any
    this.route.queryParams.subscribe(params => {
      if(params.changelog == 'true') {
        this.headerActiveTab = 6;
      } 
      if(params.react == 'true') {
        this.activeCodeTab = 1;
      }
   });

   if(this.reactUrl) {
    this.showReactEmbed = true;
    this.reactUrlSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(this.reactUrl);
   } else {
    this.showReactEmbed = false;
   }

   this.componentId = this.component.name.substring(this.component.name.lastIndexOf("/") + 1);

   if(this.component.peerDependencies.length > 0) {
    this.component.peerDependencies.forEach((p) => {
      Object.entries(p).forEach(([k,v]) => {
        let value:any = v;
        value = value.replace(/^\D*/g, "");
        //check if package is the theme and replace version with current
        if(k == themePackageInfo.name) {
          value = themePackageInfo.version;
        }
        this.peerDependencies.push({package: k + "@" + value});
      });
    });
    
   }
  }
  
   ngOnChanges(changes): void {
    if(changes.htmlCode) {
      this.htmlCode = changes.htmlCode.currentValue;
    }
  }

   reset() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
        this.router.navigate([currentUrl]);
    });
  }

  copyCredentials(item) {
    navigator.clipboard.writeText(item);
    this.copyText = 'Copied!';
  }

  copy() {
    navigator.clipboard.writeText(`npm install ${this.component.name}@${this.component.version}`);
    this.copyText = 'Copied!';
    this.GAService.trackEvent('Copy Icon', {
      'event_category': 'Engagement',
      'event_label': 'Copied install command',
      'value': this.component.name
    });
  }

  copyDependency(item) {
    navigator.clipboard.writeText(`npm install ` + item);
    this.copyText = 'Copied!';
  }

  copyHTML() {
    navigator.clipboard.writeText(this.angularHtml.componentRef);
    this.copyText = 'Copied!';
  }

  resetCopyText() {
    this.copyText = 'Copy code snippet';
  }

  copyNpmrc() {
    const npmrc = `registry=https://lhediscovery.pkgs.visualstudio.com/_packaging/lighthouse-npm/npm/registry/                      
always-auth=true`
    navigator.clipboard.writeText(npmrc);
    this.copyText = 'Copied!';
  }

  demoPageTabs(event) {
    this.GAService.trackEvent('Demo Page Tabs', {
      'event_category': 'Engagement',
      'event_label': 'Header tab clicked',
      'component_name': this.component.name,
      'value': event.title
    });
  }

  demoCodeTabs(event) {
    this.GAService.trackEvent('Demo Code Tabs', {
      'event_category': 'Engagement',
      'event_label': 'Code tab clicked',
      'component_name': this.component.name,
      'value': event.title
    });
  }
  
}
