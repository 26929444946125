import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'masthead-specs',
  templateUrl: './masthead-specs.component.html',
  styleUrls: ['./masthead-specs.component.sass']
})
export class MastheadSpecsComponent implements OnInit {
  public component = window.location.protocol + '//' + window.location.host + '/design-specs-measure/masthead/';
  constructor() { }

  ngOnInit(): void {
  }

  clickEvent(route) {
    if(route == 'light') {
      window.open(this.component, "_blank");
    }
  }
}
