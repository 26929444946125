import { Component, Input, Output, AfterViewInit, EventEmitter, ElementRef, OnInit, SimpleChanges, ContentChild, TemplateRef} from '@angular/core';

@Component({
  selector: 'kn-multi-select',
  templateUrl: './kraken-multi-select.component.html'
})
export class KrakenMultiSelectComponent implements AfterViewInit, OnInit {
  

  @Input() data: any[] = [];
  @Input() fields: any;
  @Input() template = false;
  @Input() label: string;
  @Input() value: string;
  @Input() multiSelectId: string;
  @Input() displayPlaceholder = "";
  @Input() searchboxPlaceholder = "";
  @Input() readOnly = false;
  @Input() disabled = false;
  @Input() keyWord = "";
  @Input() delimiter = ", ";
  @Input() customError = false;
  @Input() customWarning = false;
  @Input() customMess: string;
  @Input() dropdownHeight = '';
  @Input() dropdownWidth = '';
  @Input() componentWidth = '264px';
  @Input() optional = false;
  @Input() required = false;
  @Input() dropdownRightAligned = false;
  @Output() onDataChange = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<{event: string; data: any}>();
  @Output() onValidate = new EventEmitter<any>();

  public showDropdown = false;  
  public source: any = [];
  public selectionIndex: number;
  public selectedItems = '';
  public rowSelectionArray = [];
  public moreItems = false;
  public moreItemsCount = 0;
  public moreItemsTooltip: any;
  public displayBox: any;
  public dropdownIconElmWidth: number;
  public displayInputWidth;
  public multiselectDropdownWidth;
  public filterData;
  public domEles;
  public listLength = 0;
  public searchbox: any;
  public isFiltering = false;
  public isRequired = false;
  public isTouched = false;
  public inputContainer;
  public leftPos;
  public topPos;
  public dropdownElm;
  public width;
  public dropdownLeftPos;
  public dropdownBottomPos;

  @ContentChild(TemplateRef) customTemplate: TemplateRef<any>;

  //type ahead search
  private _searchTerm = '';
  public dataSearched: any = [];
  get searchTerm(): string {
    return this._searchTerm;
  }
  
  set searchTerm(value: string) {
    const filtered = [];
    this._searchTerm = value;
    this.dataSearched = value ? this.onFiltering(value) : this.filterData;
    this.dataSearched.filter(d => {
      filtered.push(d.origin);
    })
    this.source = this.dataSearched;
    this.onDataChange.emit(filtered);
  }

  constructor(private elm: ElementRef) { }

  ngAfterViewInit(): void {
    this.inputContainer = this.elm.nativeElement.querySelector('.kn-mulit-select-container');
    const input = this.elm.nativeElement.querySelector('.kn-multi-select-textbox-display');
    this.dropdownElm = this.elm.nativeElement.querySelector('.kn-dropdown');
    const dropDownIcon = this.elm.nativeElement.querySelector('.kn-dp-icon');
    this.searchbox = this.elm.nativeElement.querySelector('.kn-multi-select-searchbox');
    this.dropdownIconElmWidth = dropDownIcon.offsetWidth;
    this.displayBox = this.elm.nativeElement.querySelector('.kn-multi-select-textbox-display');

    //if required field
    if(this.required) {
      this.onValidate.emit({invalid: true});
    }

    document.addEventListener('click', (e) => {
      const searchbox = this.searchbox.querySelector('.kn-multi-select-searchbox');
      //this.multiselectDropdownWidth = this.dropdownWidth != '' ? this.dropdownWidth : this.inputContainer.offsetWidth + 'px';
      if (e.target != searchbox && 
        e.target != input && 
        (e.target as HTMLElement).parentElement != input && 
        e.target !== this.dropdownElm && 
        e.target !== dropDownIcon) {
          this.showDropdown = false;
          e.stopPropagation();
        } else {
          this.itemListEventListener();
        }
    });

    input.addEventListener('click', () => { 
      this.showDropdown = !this.showDropdown;
      if(this.showDropdown) {
        this.popupCalc();
      }
      //this.multiselectDropdownWidth = this.dropdownWidth != '' ? this.dropdownWidth : element.querySelector(this.inputbox).offsetWidth + 'px';
      
      this.source = this.source.sort((a, b) =>  {
        return Number(b.selected) - Number(a.selected);
      });

      setTimeout(() => {
        this.searchbox.focus();
      }, 0);
    });

    dropDownIcon.addEventListener('click', () => { 
      this.showDropdown = !this.showDropdown;
      if(this.showDropdown) {
        this.popupCalc();
      }
      
      this.source = this.source.sort((a, b) =>  {
        return Number(b.selected) - Number(a.selected);
      });
      
      this.itemListEventListener();
      setTimeout(() => {
        this.searchbox.focus();
      }, 0);
    });

    this.dropdownElm.addEventListener('click', (e) => { 
      this.showDropdown = true;
      e.stopPropagation();
      //set tabindex of each item list (for arrow key navigation)
      this.itemListEventListener();
    });

    window.addEventListener('scroll', () => {
      this.dropdownPosHandler();
      this.popupCalc();
    },true);

    window.addEventListener('resize', () => {
      this.dropdownPosHandler();
      this.popupCalc();
    });

    setTimeout(() => {
      this.getData();
    }, 100);
  }

  ngOnInit(): void {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.data) {
      if(!changes.data.firstChange) {
        if(this.displayBox) {
          this.displayInputWidth = this.displayBox.offsetWidth;
        }
        //if data source has change reset the component
        this.data = changes.data.currentValue;
        this.selectedItems = '';
        this.moreItemsCount = 0
        this.rowSelectionArray = [];
        this.getData();
      } 
    }
    //if required field
    if(changes.required) {
      if(!changes.required.firstChange) {
        if(!changes.required.currentValue) {
          this.isRequired = false;
          this.onValidate.emit({invalid: false});
        } else {
          this.isRequired = this.selectedItems == '' ? true : false;
          this.onValidate.emit({invalid: this.isRequired});
        }
      }
    }
  }

  getData() {
    //set data on initial load
    this.fieldExists(this.data);

    //clone custom template data 
    this.filterData = this.source;

    //check if data has pre set fields (selected, disabled)
    this.source.filter(d => {
      if (d.selected === true) {
        const event: any = [];
        event.value = true;
        this.selectedRow(event, d);
      }
    });
  }

  onFiltering(value) {
    let results;
    if(this.keyWord) {
      results = this.filterData.filter(i => {
        const keywords = i.origin[this.keyWord];
        const keywordList = keywords.split(",");
        const isKeywordMatch = keywordList.filter(k => k.toLowerCase().indexOf(value.toLowerCase()) !== -1);
        return isKeywordMatch.length > 0 ? true : false;
      });
      
    } else if(this.fields && !this.keyWord) {
      results = this.filterData.filter(k => k.origin[this.fields.text].toLowerCase().indexOf(value.toLowerCase()) !== -1);
    } else {
      results = this.filterData.filter(i => i.toLowerCase().indexOf(value.toLowerCase()) !== -1);
    }

    this.filterData = this.filterData.sort((a, b) =>  {
      return Number(b.selected) - Number(a.selected);
    });

    return results;
  }

  itemListEventListener() {
    const list = document.querySelector('.inner-container');
    const items = list.children;
    const itemList = Array.from(items);
    let index = 0;
    itemList.forEach(i => {
      index = index + 1;
      i.classList.remove('focus');
      i.setAttribute('tabindex', '' + index + '');
      i.addEventListener('keydown', (e) => {
        this.navigateItemList(e);
      });
    });
  }

  //blur event for the display input
  onBlur() {
    if(this.required) {
      this.isRequired = this.selectedItems == '' ? true : false;
      this.onValidate.emit({invalid: this.isRequired});
    }
  }

  //key event listener
  searchBoxKeyEvent(e) {
    if(e.key == "ArrowDown") {
      const list = document.querySelector('.inner-container');
      const items = list.children;
      const itemList = Array.from(items);
      this.navigateItemList(e);
      (itemList[0] as HTMLElement).focus();
    }
  }

  navigateItemList(e){
    const list = document.querySelector('.inner-container');
    const nextElm = (e.currentTarget.nextElementSibling as  HTMLElement);
    const prevElm = (e.currentTarget.previousElementSibling as  HTMLElement);
    const items = list.children;
    const itemList = Array.from(items);

    if(e.key == "ArrowDown" && nextElm) {
      nextElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });
      if(e.currentTarget.classList.contains('kn-multi-select-searchbox')) {
        itemList[0].classList.add('focus');
      } else {
        nextElm.classList.add('focus');
      }
    } 

    if(e.key == "ArrowUp" && prevElm) {
      prevElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });
      prevElm.classList.add('focus');
    }
    if(e.key == "Enter") {
      e.currentTarget.click();
      e.stopImmediatePropagation();
    }
    e.preventDefault();
  }

  valueChange(event, item) {
    if(item) {
      const items = this.elm.nativeElement.querySelectorAll('.kn-dropdown-item');

      items.forEach(i => {
        i.classList.remove('active');
      });
      if(this.template) {
        items[this.selectionIndex].classList.add('active');
      } else {
        event.target.classList.add('active');
      }

      //check if using custom template...if template is used, user has to set own value in code
      if(!this.template) {
        this.value = this.fields ? item[this.fields.text] : item;
      }
      this.onSelect.emit(item);
      
    }
  }

  fieldExists(data) {
    this.source = [];
    for (const key in data)
    {
      //maps fields 
      const indexedItem = data[key];
      const item : any[] = [];
      item['text'] = this.fields ? indexedItem[this.fields.text] : indexedItem;
      item['value'] = this.fields ? indexedItem[this.fields.value] : indexedItem;
      item['origin'] = indexedItem;
      //create a selected property for the check boxes 
      item['selected'] = indexedItem.selected ? true : false;
      this.source.push(item);
      //sort the data with "checked" items at the top
      this.source = this.source.sort((a, b) =>  {
        return Number(b.selected) - Number(a.selected);
      });
    }
  }

  public onCompare(): number {
    return -1;
  }

  customTemplateObjectMatch(obj2) {
    this.data.forEach(obj1 => {
      const isObjSame = Object.keys(obj1).length === Object.keys(obj2).length && Object.keys(obj1).every(p => obj1[p] === obj2[p]);
      if(isObjSame) {
        this.selectionIndex = this.data.findIndex(z => z === obj2);
        this.valueChange('', obj2);
      }
    });
  }

  selectedRow(event, row) {
    let index;
    this.displayInputWidth = this.displayBox.offsetWidth;

    this.onSelect.emit({event: event, data: row});

    if(event) {
      //add selected item
      this.rowSelectionArray.push(row.text);
      index = this.rowSelectionArray.indexOf(row.text);
      //set checkbox "checked"
      this.source.filter(i => {
        if(i == row) {
          i.selected = true;
        }
      });
      this.filterData.filter(i => {
        if(i == row) {
          i.selected = true;
        }
      });
    } else {
      //remove unselected item from array
      index = this.rowSelectionArray.indexOf(row.text);
      this.rowSelectionArray.splice(index, 1);
      //set checkbox "unchecked"
      this.source.filter(i => {
        if(i == row) {
          i.selected = false;
        }
      });
      this.filterData.filter(i => {
        if(i == row) {
          i.selected = false;
        }
      });
    }
    
    //build the display field "string" of selected items with delimiter
    this.selectedItems = this.rowSelectionArray.join(this.delimiter);

    //if required field
    if(this.required) {
      this.isRequired = this.selectedItems == '' ? true : false;
      this.onValidate.emit({invalid: this.isRequired});
    }

    //get width of text in textbox
    const textWidth = this.displayTextWidth(this.selectedItems, '14px Avenir book');
    this.textOverlap(textWidth, this.displayInputWidth, row);
  }

  displayTextWidth(text, font) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
  }

  textOverlap(textWidth, inputWidth, row?) {
    //check if display "string" is longer than the textbox
    const textOverlap = (textWidth) > inputWidth - 115; //115 is the px space for the right padding plus dropdown arrow 

    if(textOverlap) {
      this.moreItems = true;
      this.moreItemsCount = row.selected ? this.moreItemsCount + 1 : this.moreItemsCount - 1;
      if(row.selected) {
        this.selectedItems = this.rowSelectionArray.length == 1 ? row.text : this.selectedItems.replace(row.text + ', ', '');
      }
      //tooltip 
      if(this.rowSelectionArray.length <= 15) {
        this.moreItemsTooltip = this.rowSelectionArray.join('<br/>');
      } else {
        this.moreItemsTooltip = this.rowSelectionArray.join(this.delimiter + ' ');
      }
    } else {
      this.moreItems = false;
      this.moreItemsCount = 0;
    }
  }

  clear() {
    this.searchTerm = '';
  }

  popupCalc() {
    const y = this.inputContainer.getBoundingClientRect().top; 
    this.multiselectDropdownWidth = this.dropdownWidth ? this.dropdownWidth : this.inputContainer.offsetWidth + 'px';
    this.topPos = (this.inputContainer.offsetHeight + y) + 'px';

    if(this.dropdownRightAligned) {
      this.leftPos = Math.abs(this.inputContainer.getBoundingClientRect().right - this.dropdownElm.offsetWidth) + 'px';
    } else {
      this.leftPos = this.inputContainer.getBoundingClientRect().left + 'px';
    }
  }

  dropdownPosHandler() {
    this.dropdownLeftPos = this.inputContainer.getBoundingClientRect().left + 'px';
    this.dropdownBottomPos = this.inputContainer.getBoundingClientRect().bottom + 'px';
    this.dropdownWidth = this.inputContainer.getBoundingClientRect().width + 'px';
  }
}
