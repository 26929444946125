<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">The <span class="kn-bold">Masthead </span> is the top-most element of an application interface, which is persistently displayed across all screens. It includes references such as the name of the application and brand and can also serve as the primary container for key application-wide tools such as navigation, search, notifications, and access to user settings. </div>
            </section>
       
        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <section>
            <div class="text">The masthead consists of four areas: </div>
            <ul class="bullets">
                <li>The <span class="kn-bold">application branding</span>, which displays the product logo. </li>
                <li>The <span class="kn-bold">main navigation</span>, which leads to primary sections within the site. </li>
                <li>The <span class="kn-bold">utility navigation</span>, which consists of a help button and application-wide utilities. </li>
                <li>The <span class="kn-bold">user profile navigation</span>, which displays the user avatar and menu. </li>
            </ul>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_01.png">

        <section>
            <div class="text"><span class="kn-bold">A.	Application branding </span> </div>
            <div class="text"><span class="kn-bold">B.	Main navigation: </span>(optional) </div>
            <div class="text"><span class="kn-bold">C.	Utility navigation: </span>(optional) </div>
            <div class="text"><span class="kn-bold">D.	User profile navigation: </span>(optional) </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_02.png">

        <section>
            <div class="kn-subtitle-16">Display use cases</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Application branding </span> </div>
            <div class="text"><span class="kn-bold">2.	Application branding with version label </span> </div>
            <div class="text"><span class="kn-bold">3.	Application branding and utility navigation </span> </div>
            <div class="text"><span class="kn-bold">4.	Application branding, main navigation and utility navigation </span> </div>
            <div class="text"><span class="kn-bold">5.	Application branding, main navigation and user profile navigation </span> </div>
            <div class="text"><span class="kn-bold">6.	Application branding, main navigation, utility navigation and user profile navigation </span> </div>
        </section>

        <section>
            <div class="kn-headline-24-med">Application branding</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_03.png">

        <section>
            <section>
                <div class="text">Displaying application branding in the masthead anchors all Lighthouse software with a common visual brand. Positioning the brand on far left of the masthead, ensures consistent brand recognition and reinforces the identity of the application. </div>
            </section>

            <section>
                <div class="kn-subtitle-16">Sizing</div>
                <div class="text">To ensure sufficient visibility and brand recognition without appearing disproportionately small or overpowering the overall masthead design, the <span class="kn-bold">minimum height is 46px </span> and <span class="kn-bold">maximum width is 250px </span>. </div>
            </section>
        </section>

        <section>
            <div class="kn-headline-24-med">Main navigation</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_04.png">

        <section>
            <section>
                <div class="text">In certain cases, the main navigation can be optional depending on the context and specific product needs. The main navigation uses the tab component for consistency and familiarity. </div>
            </section>

            <section>
                <ul class="bullets">
                    <li>Ensure that the tab labels are single words or succinct phrases to support quick scanning and interpretation (do not exceed 25 characters).</li>
                    <li>Use sentence case in the display of the tab label text.</li>
                    <li>(Recommended) Limit the number of visible tabs to 5-7 options. This range helps to avoid overcrowding and maintains a clean and organized appearance.</li>
                </ul>
            </section>

            <section>
                <div class="text"><span class="kn-bold">1.	Primary: </span>Represents high-level navigation options.</div>
                <div class="text"><span class="kn-bold">2.	Multi-section: </span>When needing to delineate between two different groups of navigation options, a divider line positioned between the two sections provides visual separation. </div>
                <div class="text"><span class="kn-bold">3.	Secondary: </span>Involves providing additional navigation options or subcategories within a primary navigation section.</div>
                <div class="text"><span class="kn-bold">4.	Overflow: </span>When the main navigation exceeds the available space, the overflow menu provides additional options upon selection.</div>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_05.png">
        
        <section>
            <section>
                <div class="kn-subtitle-16">Primary</div>
                <div class="text">The primary level tab navigation consists of a set of tabs that represent high-level sections within an application. Each tab is labeled and visually distinct, allowing users to easily swith between different sections. </div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">A.	Selected </span> </div>
                <div class="text"><span class="kn-bold">B.	Unselected </span> </div>
                <div class="text"><span class="kn-bold">C.	Selected hover </span> </div>
                <div class="text"><span class="kn-bold">D.	Unselected hover </span> </div>
                <div class="text"><span class="kn-bold">E.	Disabled </span> </div>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_06.png">
        
        <section>
            <section>
                <div class="kn-subtitle-16">Secondary</div>
                <div class="text">The main navigation tab can support a depth of two levels: primary and secondary. Implement a secondary-style tab navigation when more granular or nested navigation options need to be provided within a specific tab category. This is distinguished from the primary-style of tabs, which includes an angled-down caret icon that indicates the trigger of a dropdown menu (positioned to the right of the tab label). </div>
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">A.	Unselected </span> </div>
                <div class="text"><span class="kn-bold">B.	Unselected hover </span> </div>
                <div class="text"><span class="kn-bold">C.	Focused </span> </div>
                <div class="text"><span class="kn-bold">D.	Selected </span> </div>
                <div class="text"><span class="kn-bold">E.	Selected hover </span> </div>
                <div class="text"><span class="kn-bold">E.	Disabled </span> </div>
            </section>
        </section>

        <section>
            <section>
                <div class="kn-subtitle-16">Secondary overflow menu</div>
                <div class="text">Secondary navigation options are displayed through interactive menus associated with the primary option listed with the main navigation. </div>
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">A.	Tab overflow </span> </div>
                <div class="text"><span class="kn-bold">B.	Menu option enabled </span> </div>
                <div class="text"><span class="kn-bold">C.	Menu option hover </span> </div>
                <div class="text"><span class="kn-bold">D.	Menu option disabled </span> </div>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_07.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Overflow</div>
                <div class="text">When the main navigation space does not allow all tabs to be displayed together, use the overflow menu where additional options are revealed when you hover over them. The overflow tab is positioned to the far right of the primary navigation options within the main navigation. </div>
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">A.	Unselected </span> </div>
                <div class="text"><span class="kn-bold">B.	Unselected hover </span> </div>
                <div class="text"><span class="kn-bold">C.	Selected </span> </div>
                <div class="text"><span class="kn-bold">D.	Selected hover </span> </div>
                <div class="text"><span class="kn-bold">E.	Disabled </span> </div>
            </section>
        </section>

        <section>
            <section>
                <div class="kn-subtitle-16">Tab overflow menu</div>
                <div class="text">A menu of hidden primary navigation options is displayed through the interactive menu associated with the overflow tab displayed in the main navigation. </div>
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">A.	Tab overflow </span> </div>
                <div class="text"><span class="kn-bold">B.	Menu option enabled </span> </div>
                <div class="text"><span class="kn-bold">C.	Menu option hover </span> </div>
                <div class="text"><span class="kn-bold">D.	Menu option disabled </span> </div>
            </section>
        </section>

        <section>
            <div class="kn-headline-24-med">Utility navigation</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_08.png">

        <section>
            <section>
                <div class="text">When displaying the utility navigation in the masthead, prioritize essential and frequently used utility options that provide immediate value to the user. Examples include account settings, global search, notification center and help. Position the utility navigation on the right-hand side of the masthead. </div>
            </section>

            <section>
                <div class="text">Ensure that the utility navigation options are easily accessible and clearly distinguishable from the main navigation by using recognizable icons. </div>
            </section>

            <ul class="bullets">
                <li>(Recommended) Limit the number of utility navigation options to 3. </li>
            </ul>
            
            <section>
                <div class="text"><span class="kn-bold">A.	Divider line: </span>Used to differentiate between the main navigation and utility navigation. </div>
                <div class="text"><span class="kn-bold">B.	Utility buttons (flat icon button - medium size) </span> </div>
            </section>
        </section>

        <section>
            <div class="kn-headline-24-med">User profile navigation</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_09.png">

        <section>
            <section>
                <div class="text">When displaying the user profile avatar within the masthead, ensure that it is prominently placed and easily identifiable. Display the avatar positioned on the far right-hand side of the masthead, and used with a consistent size of <span class="kn-bold">44px</span>. </div>
            </section>

            <section>
                <div class="text">Additionally, ensure the avatar displayed with an associated angled-down caret icon, which indicates that a dropdown menu can be expanded to access user-specific actions or settings. Also ensure the user profile avatar does not solely represent the functionality of a click/tap functionality that is used to access the dropdown menu. </div>
            </section>
            
            <section>
                <div class="text"><span class="kn-bold">A.	Divider line:</span> Used to differentiate between the utility navigation and the user profile navigation. </div>
                <div class="text"><span class="kn-bold">B.	Avatar </span> </div>
                <div class="text"><span class="kn-bold">C.	Dropdown menu </span> </div>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_10.png">

            <section>
                <div class="kn-subtitle-16">Size variations</div>
                <div class="text">The masthead comes in two different sizes: default and compressed. The compressed size is reserved for a data-heavy applications where users can scan and take action on large amounts of information. When displaying the compressed size, the application branding, main navigation, utility navigation, and user profile navigation remains at its fixed height (only the background varies in sizing). </div>
            </section>
        
            <section>
                <div class="text"><span class="kn-bold">A.	Default masthead: </span> 80px height </div>
                <div class="text"><span class="kn-bold">B.	Compressed masthead: </span> 60px height </div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_11.png">

            <section>
                <div class="kn-subtitle-16">Responsive breakpoints</div>
                <div class="text">Responsive breakpoints refer to the specific screen widths at which the layout and design of a masthead adapts for optimal viewing and functionality. Individual areas within the masthead collapse according to their behavior guidelines. </div>
            </section>
        
            <section>
                <div class="text"><span class="kn-bold">1.	Large (1440px +) </span> </div>
                <div class="text"><span class="kn-bold">2.	Medium (1280px - 1439px) </span> </div>
                <div class="text"><span class="kn-bold">3.	Small (1024px - 1279px) </span> </div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_12.png">

            <section>
                <div class="kn-subtitle-16">Masthead display gap</div>
                <div class="text">The masthead maintains a display gap between the left and right-side areas. The display gap always remains at <span class="kn-bold">108px </span> and is not related to any responsive breakpoints. </div>
            </section>

            <section>
                <div class="text">This maintains a visual distinction between the left-side areas (application branding and main navigation) and the right-side areas (utility navigation and user profile navigation) and sets the trigger for the main navigation overflow. </div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_13.png">

            <section>
                <div class="kn-subtitle-16">Elevation</div>
                <div class="text">With the standard page-level interactions, the masthead is positioned at the highest level of elevation. </div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_14.png">

            <section>
                <div class="kn-subtitle-16">Stickiness</div>
                <div class="text">Ensure the masthead always remains visible and is fixed at the top of the viewport as users scroll through the content. </div>
            </section>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_15.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Do not change component placement</div>

            <div class="text">Although most pieces of the header are optional, do not change the placement of the components if they are included in your masthead. </div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_16.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Long navigation labels</div>

            <div class="text">Keep main navigation tab labels as succinct as possible-use single words or short phrases that fit within a single line. Ensure the tab labels are displayed-only on a single line (and do not wrap to a second line). </div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_17.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Use of icons in main navigation</div>

            <div class="text">Ensure icons are not be displayed in combination with or in place of the main navigation tab label text. </div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/masthead/KrakenDSM_Components_Masthead_18.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Horizontal scrolling of the main navigation</div>

            <div class="text">Do not require users to scroll through the main navigation to view available options. If the number of navigation options is too extensive to effectively fit in the horizontal space within the masthead, introduce the overflow tab instead. </div>
        </section>
    </div>
</div>