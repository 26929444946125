import { Component, Input, Output, EventEmitter, ElementRef, AfterContentInit, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'kn-split-button',
  templateUrl: `./kraken-split-button.component.html`,
  styleUrls: ['./kraken-split-button.component.sass']
})
export class KrakenSplitButtonComponent implements AfterContentInit {
  @Input() buttonId: string;
  @Input() buttonType: string;
  @Input() buttonText: string;
  @Input() buttonIcon: string;
  @Input() loading: boolean;
  @Input() disabled: boolean;
  @Input() active = false;
  @Input() template = false;
  @Input() items: any;
  @Input() fields: any;
  @Input() contentWidth = false;
  @Input() drpdnWidth = '100%';
  @Input() dropdownCustomWidth: string;
  @Input() dropdownCustomHeight: string;
  @Input() dropdownRightAligned = false;
  @Output() isOpen = new EventEmitter<boolean>();
  @Output() btnClick = new EventEmitter<any>();
  @Output() onSelect = new EventEmitter<any>();

  public showDropdown = false;
  public topPos: string;
  public height: 'auto';
  public source: object[] = [];
  public itemList: HTMLElement;
  public leftPos;
  public dropdownElm;
  public container;
  public button;
  public icon;

  @ContentChild(TemplateRef) customTemplate: TemplateRef<any>;

  constructor(private elm: ElementRef) { }

  ngAfterContentInit(): void {
    this.itemList = this.elm.nativeElement.querySelector('.kn-dropdown');
    this.dropdownElm = this.elm.nativeElement.querySelector('.kn-dropdown');
    this.container = this.elm.nativeElement.querySelector('.kn-btn-group');
    this.button = this.elm.nativeElement.querySelectorAll('BUTTON')[0];
    this.icon = this.elm.nativeElement.querySelectorAll('BUTTON')[1];

    document.addEventListener('click', (e: any) => {
      if (e.target != this.icon && e.target !== this.dropdownElm) {
        this.showDropdown = false;
        this.isOpen.emit(false);
        e.stopPropagation();
      }
    });

    this.icon.addEventListener('click', () => { 
      this.popupCalc();
      this.showDropdown = !this.showDropdown;
      this.isOpen.emit(this.showDropdown);

      //arrow navigation
      this.itemListEventListener();

      window.addEventListener('scroll', () => {
        this.popupCalc();
      },true);
  
      window.addEventListener('resize', () => {
        this.popupCalc();
      });
    });

    //prevent timing issue with web component
    setTimeout(() => {
      //check if custom fields are present
      this.fieldExists();
    }, 0);
  }

  fieldExists() {
    for (const key in this.items)
    {
      if(this.fields) {
        
        //check if object array has the required fields
        const indexedItem = this.items[key];
        if (Object.prototype.hasOwnProperty.call(indexedItem, this.fields.text) ) {
          const item: object[] = [];
          item['text'] = indexedItem[this.fields.text];
          item['value'] = indexedItem[this.fields.value];
          item['origin'] = indexedItem;
          this.source.push(item);
        } 
      }
    }
  }

  valueChange(event) {
    this.onSelect.emit(event);
  }

  onClick(btn: number) {
    if(btn == 1) {
		this.btnClick.emit(
      {
        id: this.buttonId,
        button: 1,
        text: this.buttonText,
        type: this.buttonType,
        active: this.active
      });
    } else {
      this.btnClick.emit(true);
    }
	}

  navigateItemList(e){
    const nextElm = (e.currentTarget.nextElementSibling as  HTMLElement);
    const prevElm = (e.currentTarget.previousElementSibling as  HTMLElement);
    const items = this.itemList.children;
    const itemList = Array.from(items);

    if(e.key == "ArrowDown" && nextElm) {
      nextElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });

      nextElm.classList.add('focus');
    } 

    if(e.key == "ArrowUp" && prevElm) {
      prevElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });
      prevElm.classList.add('focus');
    }

    if(e.key == "Enter") {
      e.currentTarget.click();
      e.stopImmediatePropagation();
    }
    e.preventDefault();
  }

  itemListEventListener() {
    const items = this.itemList.children;
    const itemList = Array.from(items);
    let index = 0;
    itemList.forEach(i => {
      index = index + 1;
      i.classList.remove('focus');
      i.setAttribute('tabindex', '' + index + '');
      i.addEventListener('keydown', (e) => {
        this.navigateItemList(e);
      });
    });
  }

  keydown(e) {
    if(e.key == 'ArrowDown') {
      const items = this.itemList.children;
      (items[0] as HTMLElement).focus();
    }
  }

  popupCalc() {
    const y = this.container.getBoundingClientRect().top; 
    this.topPos = (this.container.offsetHeight + y) + 'px';
    this.drpdnWidth = this.dropdownCustomWidth ? this.dropdownCustomWidth : (this.button.offsetWidth + this.icon.offsetWidth) + 'px'; 
    const bottom = window.innerHeight  - y;
    const dropdownHeight = this.dropdownElm.getBoundingClientRect().height;
    if(bottom < dropdownHeight) {
      this.topPos = (y - dropdownHeight - 10) + 'px';
    } else {
      this.topPos = (this.container.offsetHeight + y) + 'px';
    }
    if(this.dropdownRightAligned) {
      this.leftPos = Math.abs(this.button.getBoundingClientRect().right - this.dropdownElm.offsetWidth) + 'px';
    } else {
      this.leftPos = this.container.getBoundingClientRect().left + 'px';
    }
  }

}
