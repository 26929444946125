<div 
    class="kn-spinner"
    *ngIf="isLoading">
    <div class="kn-spinner-bg"></div>
    <div 
        class="kn-active-loading" 
        [ngClass]="{
            'light': theme == 'light',
            'dark': theme == 'dark',
            'lg': size == 'large',
            'md': size == 'medium',
            'sm': size == 'small'
        }">
    </div>
</div>
