import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import  packageInfo from '../../../../../kraken-multi-select/package.json';

export class OnSelect {
  data: SelectedData;
  event: any;
}

export class SelectedData {
  origin: OriginData;
  selected: boolean;
  text: string;
  value: any;
}

export class OriginData {
  id: number;
  name: string;
  role: string;
  selected: boolean;
  tags: string;
}

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.sass']
})
export class MultiSelectComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public searchboxPlaceholder ='Search';
  public displayPlaceholder = 'Select item(s)'
  public showDisplayPlaceholder = true;
  public keywords = null;
  public label = "Multi select";
  public elementTag = 'kn-multi-select';
  public customMess = "Custom message";
  public customError = false;
  public customWarning = false;
  public event1 = true;
  public event2 = true;
  public eventValue = 'Textbox value';
  public exampleText: string;
  public tsDropdownItemsObj;
  public tsKeywords;
  public useKeywords = false;
  public addCustomMessage = false;
  public showSearchboxPlaceholder = true;
  public disabled = false;
  public readOnly = false;
  public required = false;
  public displayInputWidth = '385px';
  public componentId = 'multiselectComponentId';
  public webSetProperties;
  public webRemoveProperties;
  public webDropdownItemsArray;
  public webDropdownItemsObj;
  public webClickEvent;
  public CustomDropdown = false;
  public personItem: any = [];
  public templateHtml: string;
  public tsPersonObj: string;
  public customTemplateUpdataDataFunction: string;
  public selectionFunction: string;
  public dropdownWidth: any;
  public dropdownHeight: any;
  public showDropdownWidth = false;
  public showDropdownHeight = false;
  public actionEvent;
  public actionData;
  public onselectData = true;
  public onselectOrigin = true;
  public optionalLabel = false;
  public onEventData = true;
  public onDataChange = true;
  public delimiter = ", ";
  public messageType = 'error';
  public isRequired = false;
  public onValidate = false;
  public rightAligned = false;
  public multiSelectInput: any;
  public webDataChangeEvent: string;
  public componentVersion = packageInfo.version;
  public initializeComponentWeb: any;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenMultiSelectModule'};
  public personFields: object = { text: 'name', value: 'id'};

  public personObj = [
    {id: 0, name:"John Doe", role:"Manager", tags: 'john, doe, product, manager'},
    {id: 1, name:"Joe Smith", role:"Developer", tags: 'joe, smith, developer'},
    {id: 2, name:"Rick James", role:"Manager", tags: 'rick, james, manager'},
    {id: 3, name:"Bob Knight", role:"Developer", tags: 'bobby, knight, developer'},
  ]

  public angularComponentOptions: any = [
    { option: '[multiSelectId]=', id: 0, value:  '"\'multiSelect\'"'},
    { option: '[label]=', id: 1, value: '"\'' + this.label + '\'"'},
    { option: '[delimiter]=', id: 1, value: '"\'' + this.delimiter + '\'"'},
    { option: '[componentWidth]=', id: 1, value: '"\'' + this.displayInputWidth + '\'"'},
    { option: '[fields]=', id: 2, value: '"personFields"'},
    { option: '[data]=', id: 3, value: '"personObj"'},
    { option: '[searchboxPlaceholder]=', id: 4, value: '"\'' + this.searchboxPlaceholder + '\'"'},
    { option: '[displayPlaceholder]=', id: 5, value: '"\'' + this.displayPlaceholder + '\'"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value:  '"'+ this.componentId +'"'},
    { option: 'component-id=', id: 1, value:  '"multiSelect"'},
    { option: 'label=', id: 2, value: '"' + this.label + '"'},
    { option: 'delimiter=', id: 1, value: '"' + this.delimiter + '"'},
    { option: 'component-width=', id: 3, value: '"' + this.displayInputWidth + '"'},
    { option: 'searchbox-placeholder=', id: 4, value: '"' + this.searchboxPlaceholder + '"'},
    { option: 'display-placeholder=', id: 5, value: '"' + this.displayPlaceholder + '"'}
  ];

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService,
    public ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);

    this.tsPersonObj = `
public personFields: Object = { text: 'name', value: 'id'};

public personObj = [
  {id: 0, name:"John Doe", role:"Manager", tags: 'john, doe, product, manager', selected: true},
  {id: 1, name:"Joe Smith", role:"Developer", tags: 'joe, smith, developer'},
  {id: 2, name:"Rick James", role:"Manager", tags: 'rick, james, manager'},
  {id: 3, name:"Bob Knight", role:"Developer", tags: 'bobby, knight, developer', selected: true},
]`

    this.tsKeywords = `public keyWords = 'tags';\n`

    this.customTemplateUpdataDataFunction = `
updateData(data) {
  this.personObj = data;
}`

  this.initializeComponentWeb = `
let multiselect = document.querySelector("#${this.componentId}");
  `
    this.webDropdownItemsObj = 
`
let personObj = 
[
  {id: 0, name:"John Doe", role:"Product Manager", tags: 'john, doe, product, manager'},
  {id: 1, name:"Joe Smith", role:"Developer", tags: 'joe, smith, developer'},
  {id: 2, name:"Rick James", role:"IT Manager", tags: 'rick, james, manager'},
  {id: 3, name:"Bobby Knight", role:"Developer", tags: 'bobby, knight, developer', selected: true}
]

const fields = { text: 'name', value: 'id'};
multiselect.fields = fields;
multiselect.data = personObj;
`
this.selectionFunction = `
public personItem: string;

selectedPerson(event, item) {
  let data: any = [];
  data.event = event;
  data.item = item;
  this.personItem = data
}`

this.webClickEvent = 
`
multiselect.addEventListener('onSelect', (event) => console.log(event.detail));
`

this.webDataChangeEvent = 
`
multiselect.addEventListener('onDataChange', (event) => console.log(event.detail));
`

this.webSetProperties = 
`
multiselect.customError = true;
`
this.webRemoveProperties = 
`
multiselect.customError = false;
`
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  labelText(event, id) {
    this.label = event;
    const index = this.angularComponentOptions.findIndex((obj => obj.id == id));
    //angular
    this.angularComponentOptions[index].option = '[label]=';
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    this.webComponentOptions[index].option = 'label=';
    this.webComponentOptions[index].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  custoMessage(event) {
    this.customMess = event;
    const angularIndex = this.angularComponentOptions.findIndex((obj => obj.option == '[customMess]='));
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'custom-mess='));
    //angular
    this.angularComponentOptions[angularIndex].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  showHidePlaceholder(event: any, type) {
    if(type == 'display') {
      this.displayPlaceholder = event ? 'Select item(s)' : '';
    } else if(type == 'search') {
      this.searchboxPlaceholder = event ? 'Search' : '';
    }
  }

  placeholderValue(event, attr) {
    let webAttr;
    if(attr == '[displayPlaceholder]=') {
      webAttr = 'display-placeholder=';
      this.displayPlaceholder = this.showDisplayPlaceholder ?  event : '';
    } else {
      webAttr = 'searchbox-placeholder=';
      this.searchboxPlaceholder = this.showSearchboxPlaceholder ?  event : '';
    }
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == attr));
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == webAttr));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  rowSelection(row) {
    this.actionEvent = row.event;
    this.actionData = row;
  }

  customMessToggle(event) {
    this.addCustomMessage = event;
    if(!event) {
      this.removeCustomMessage();
    }
  }

  delete() {
    this.personObj.splice(0,1);
    this.personObj = [...this.personObj];
  }
  customMessage(event) {
    if(event.value == 'radioError') {
      this.customError = true;
      this.customWarning = false;
      this.controlEvent(false, '[customWarning]=', true, true);  
      this.controlEvent(false, '[customMess]=', '' + this.customMess +'', true);
      this.controlEvent(event, '[customError]=', true, true); 
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    } else if (event.value == 'radioWarning'){
      this.customError = false;
      this.customWarning = true;
      this.controlEvent(false, '[customError]=', true, true); 
      this.controlEvent(false, '[customMess]=', '' + this.customMess +'', true);
      this.controlEvent(event, '[customWarning]=', true, true); 
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    } else {
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    }
  }

  removeCustomMessage() {
    this.customError = false;
    this.customWarning = false;
    this.controlEvent(false, '[customError]=', true, true); 
    this.controlEvent(false, '[customWarning]=', true, true);  
    this.controlEvent(false, '[customMess]=', true, true); 
  }

  selectedPerson(event, item) {
    const data: any = [];
    data.event = event;
    data.item = item;
    this.personItem = data;
  }

  showCustomTemplate() {
    this.CustomDropdown = !this.CustomDropdown;
    if(this.CustomDropdown) {
      //custom template
    this.templateHtml = 
    `<ng-template #customTemplate let-data="data" >
    <i class="fa-solid fa-user"></i> <span class="person-name">{{data.name}}</span>
    <span class="role">{{data.role}}</span>
</ng-template>\n`
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    } else {
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    }
  }

  multiSelectDropdownSize(event, change) {
    const property = change == 'height' ? '[dropdownHeight]=' : '[dropdownWidth]=';
    const webProperty = change == 'height' ? 'dropdown-height=' : 'dropdown-width=';
    change == 'height' ? this.dropdownHeight = event : this.dropdownWidth = event;

    //angular
    const angularIndex = this.angularComponentOptions.findIndex((obj => obj.option == property));
    this.angularComponentOptions[angularIndex].value = '"\'' + event + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == webProperty));
    this.webComponentOptions[webIndex].value = '"\'' + event + '\'"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  resetDropdownSize() {
    if(!this.showDropdownWidth) {
      this.dropdownWidth = '';
    }
    if(!this.showDropdownHeight) {
      this.dropdownHeight = '';
    }
  }

  message(event) {
    this.customMess = event;
    
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[customMess]='));
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'custom-mess='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  // setDelimiter(event) {
  //   const property = '[delimiter]=';
  //   const webProperty = 'delimiter=';
  //   //angular
  //   let angularIndex = this.angularComponentOptions.findIndex((obj => obj.option == property));
  //   this.angularComponentOptions[angularIndex].value = '"' + event + '"';
  //   this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
  //   //web
  //   let webIndex = this.webComponentOptions.findIndex((obj => obj.option == webProperty));
  //   this.webComponentOptions[webIndex].value = '"' + event + '"';
  //   this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  // }
}
