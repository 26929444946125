import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import  packageInfo from '../../../../../kraken-footer/package.json';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public elementTag = 'kn-footer';
  public webElementTag = 'kn-web-footer';
  public rightNav = true;
  public sticky = false;
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenFooterModule'};
  public angularComponentOptions: any = [
    { option: '[id]=', value:  '"\'footerId\'"'},
  ];
  
  public webComponentOptions: any = [
    { option: 'id=', value:  '"footer"'},
  ];

  public footerRightnavInstance = 
  `let footer = document.querySelector('#footer');
footer.rightNav =
\`<a id="footerLink1" class="kn-quiet-link" href="https://www.lighthouseglobal.com/privacy-policy" target="_blank">Privacy Policy</a>
<a id="footerLink2" class="kn-quiet-link" href="https://www.lighthouseglobal.com/spectra-terms/2022" target="_blank">Terms of Use</a>\``

  public footerRightNavClickEvent = 
  `let footerLink1 = document.querySelector('#footerLink1');
footerLink1.addEventListener('click', (e) => {
  //your function code here...
});`

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());

    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }

  updateTemplate() {
    let template;
    if(this.rightNav) {
      template = 
  `<a id="footerLink1" class="kn-quiet-link" href="https://www.lighthouseglobal.com/privacy-policy" target="_blank">Privacy Policy</a>
  <a id="footerLink2" class="kn-quiet-link" href="https://www.lighthouseglobal.com/spectra-terms/2022" target="_blank">Terms of Use</a>`
    } else {
      template = ''
    }

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, template);
    return template;
    }

    showRightNav() {
      this.updateTemplate();
    }

    stickyFooter() {
      this.controlEvent(this.sticky, '[sticky]=', this.sticky, true); 
    }

}
