import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ButtonService {

  constructor() { }

  buttonTypes(allTypes?: boolean, buttons?: any) {
    const buttonTypes = [
        { name: 'Primary default', id: 0, type: 'kn-primary'},
        { name: 'Secondary default', id: 1, type: 'kn-secondary'},
        { name: 'Tertiary default', id: 2, type: 'kn-tertiary'},
        { name: 'Flat default', id: 3, type: 'kn-flat'},
        { name: 'Primary small', id: 4, type: 'kn-primary kn-btn-sm'},
        { name: 'Secondary small', id: 5, type: 'kn-secondary kn-btn-sm'},
        { name: 'Tertiary small', id: 6, type: 'kn-tertiary kn-btn-sm'},
        { name: 'Flat small', id: 7, type: 'kn-flat kn-btn-sm'},
        { name: 'Flat medium', id: 8, type: 'kn-flat kn-btn-md'}
      ];

    const buttonErrorWarnings: object = [
        { name: 'Primary error default', id: 8, type: 'kn-primary error'},
        { name: 'Tertiary error default', id: 9, type: 'kn-tertiary error'},
        { name: 'Primary warning default', id: 10, type: 'kn-primary warning'},
        { name: 'Tertiary warning default', id: 11, type: 'kn-tertiary warning'},
        { name: 'Primary error small', id: 12, type: 'kn-primary kn-btn-sm error'},
        { name: 'Tertiary error small', id: 13, type: 'kn-tertiary kn-btn-sm error'},
        { name: 'Primary warning small', id: 14, type: 'kn-primary kn-btn-sm warning'},
        { name: 'Tertiary warning small', id: 15, type: 'kn-tertiary kn-btn-sm warning'}
    ]

    if(allTypes) {
        Array.prototype.push.apply(buttonTypes,buttonErrorWarnings);
        return buttonTypes;
    } else if(buttons?.length > 0) {
      const filteredButtons = [];
      buttons.forEach(b => {
        buttonTypes.filter(t => {
          if(t.id === b) {
            filteredButtons.push(t);
          }
        })
      });
      return filteredButtons;
    } else {
      return buttonTypes;
    }
  }
}