import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'progress-indicator-usage',
  templateUrl: './progress-indicator-usage.component.html',
  styleUrls: ['./progress-indicator-usage.component.sass']
})
export class ProgressIndicatorUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
