import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'text-box-usage',
  templateUrl: './text-box-usage.component.html',
  styleUrls: ['./text-box-usage.component.sass']
})
export class TextBoxUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
