import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-validation-errors',
  templateUrl: './content-validation-errors.component.html',
  styleUrls: ['./content-validation-errors.component.sass']
})
export class ContentValidationErrorsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
