import { Component, Input, Output, OnInit, EventEmitter, ElementRef, AfterViewInit, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'kn-date-range-picker',
  templateUrl: './kraken-date-range-picker.component.html'
})
export class KrakenDateRangePickerComponent implements AfterViewInit, OnChanges {
  @Input() componentId: string;
  @Input() label: string;
  @Input() error = false;
  @Input() min: any;
  @Input() max: any;
  @Input() dateFormat = "MM-dd-yyyy";
  @Input() readonly = false;
  @Input() disable = false;
  @Input() value;
  @Input() compressed = false;
  @Input() required = false;
  @Output() onValueChange = new EventEmitter<any>();
  @Output() onValidate = new EventEmitter<any>();
  
  public input;
  public focused = false;
  public rangeIcon;
  public isOpen = false;
  public invalid = false;
  public errorMessage = "Invalid date range";
  public placeholder: string;

  constructor(public elm: ElementRef){}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.dateFormat) {
      this.placeholder = this.dateFormat.toLowerCase() + ' - ' + this.dateFormat.toLowerCase();
    }
  }

  ngAfterViewInit(): void {
    this.input = this.elm.nativeElement.querySelector('INPUT');
    this.rangeIcon = this.elm.nativeElement.querySelector('.e-range-icon');
    this.placeholder = this.dateFormat.toLowerCase() + ' - ' + this.dateFormat.toLowerCase();
  }

  onChange(event) {
    let clearIcon = null;
    
    if(event.event.srcElement) {
      clearIcon = event.event.srcElement.classList.contains('e-clear-icon');
    }
    if(!event.text && clearIcon) {
      this.error = this.required ? true : false;
    }
    
    if(event.text) {
      this.compareDates(event.startDate, event.endDate);
    }

    this.onValidate.emit({invalid: this.error});
    this.onValueChange.emit(event);
  }

  open(event) {
    this.focused = true;
    this.isOpen = true;
  }

  close(event) {
    this.focused = false;
    this.isOpen = false;
  }

  onFocus(event) {
    this.focused = true;
  }

  blur(event) {
    this.focused = this.isOpen ? true : false;
    this.error = this.required && !this.input.value ? true : false;
    this.errorMessage = this.required && !this.input.value ? 'Field is required' : 'Invalid date';

    if(this.input.value) {
      this.compareDates(event.model.modelValue[0], event.model.modelValue[1])
    }
  }

  compareDates(minDate, maxDate) {
    // Check if both arguments are Date objects
    if (!(minDate instanceof Date && maxDate instanceof Date)) {
        this.error = true;
    } 

    if(minDate && maxDate) {
      // Convert dates to time for comparison
      const minDateTime = minDate.getTime();
      const maxDateTime = maxDate.getTime();

      // Check if minDate is greater than maxDate
      if (minDateTime > maxDateTime) {
          this.error = true;
      } else if(maxDateTime < minDateTime ) {
        this.error = true;
      } else {
        this.error = false;
      }
    } else {
      this.error = true;
    }
  }
}
