

<div class="kn-page-title"><ng-content select="[title]"></ng-content></div>

<div class="component-box" id="componentTabs">
    <kn-tabs #tabs id="canvasTabs" [activeTab]="headerActiveTab" (onSelect)="demoPageTabs($event)">
        <!--Code demo tab-->
        <kn-tab tabTitle="Code Demo" [id]="'canvasTabs'">
            <div class="container">
                <div class="actions" [ngClass]="{'hide': expandComponent, 'show': !expandComponent}">
                    <ng-content select="[customproperties]"></ng-content>
                </div>
                <div class="code-container" id="{{componentId}}" [ngStyle]="{'background': theme}">
                    <div class="demo-header">
                        <i class="fa fa-refresh refresh-icon kn-icon" (click)="reset()" aria-hidden="true"></i>
                        <i class="fa expand-icon kn-icon" [ngClass]="{'fa-compress': expandComponent, 'fa-expand': !expandComponent}" aria-hidden="true" (click)="expandComponent = !expandComponent"></i>
                    </div>
                    <div class="kn-component" [ngClass]="{'expand': expandComponent}"><ng-content select="[component]"></ng-content></div>
                    <div class="code" [ngClass]="{'hide': expandComponent, 'show': !expandComponent}">
                        <!--Code demo tabs-->
                        <kn-tabs [activeTab]="activeCodeTab" id="canvasCodeTabs" [id]="'canvasCodeTabs'" [disabled]="disableDemoTab" (onSelect)="demoCodeTabs($event)">
                            <!--Angular tab-->
                            <kn-tab tabTitle="Angular">
                                <div class="code-group">
                                    <div class="code-header">
                                        <div class="tag">{{htmlCodeLabel}}</div>
                                        <div class="copyHTML"><i class="fa-regular fa-copy copy kn-icon kn-icon-dark" (mouseover)="resetCopyText()" tooltip="{{copyText}}" [tooltipDirection]="'top'" aria-hidden="true" (click)="copyHTML()"></i></div>
                                    </div>
                                    <div class="angular-code">
                                        <span class="html-code-demo">
                                            <div *ngIf="trigger && trigger.angular.tag">
                                                <span class="html-tags">&lt;{{trigger.angular.tag}}</span>
                                                <ng-container *ngFor="let option of trigger.angular.options">
                                                    <span class="html-option"> {{option.option}}</span><span class="html-value">{{option.value}}</span>
                                                </ng-container>
                                                <span class="html-tags">&gt;</span><pre class="code-content" *ngIf="trigger.angular.content"><code><span class="html-content">{{trigger.angular.content}}</span></code></pre><span class="html-tags">&lt;/{{trigger.angular.tag}}&gt;</span>
                                                <br/><br/>
                                            </div>
                                            <div *ngIf="angularHtml.tag">
                                                <span class="html-tags">&lt;{{angularHtml.tag}}</span>
                                                <ng-container *ngFor="let option of angularHtml.options">
                                                    <span class="html-option"> {{option.option}}</span><span class="html-value">{{option.value}}</span>
                                                </ng-container>
                                                <span class="html-tags">&gt;</span><pre class="code-content" *ngIf="angularHtml.content"><code><span class="html-content">{{angularHtml.content}}</span></code></pre><span class="html-tags">&lt;/{{angularHtml.tag}}&gt;</span>
                                                <ng-content select="[angular]"></ng-content>
                                            </div>
                                        </span>
                                    </div>
                                </div>

                                <div class="divider" *ngIf="ts"></div>

                                <div class="code-group script" *ngIf="ts">
                                    <div class="tag">component.ts</div>
                                    <pre>
                                        <code>
                                            <ng-content select="[typeScript]"></ng-content>
                                        </code>
                                    </pre>
                                </div>
                            </kn-tab>

                            <!--React tab-->
                            <kn-tab tabTitle="React" [disabled]="isWebTabDisabled">
                                <div class="web-download-btn">
                                    <kn-button 
                                        [buttonId]="'webCpmponentsDownloadBtn'" 
                                        [buttonType]="'kn-tertiary'" 
                                        [buttonText]="'Download Web components'"
                                        [buttonIconLeft]="'fa-regular fa-download'"
                                        (click)="docService.downloadWebComponents()">
                                    </kn-button>
                                </div>
                                
                                <embed *ngIf="showReactEmbed" width="100%" height="1000px" [src]="reactUrlSanitized">
                                <div *ngIf="!showReactEmbed" class="coming-soon">Coming soon...</div>
                            </kn-tab>

                            <!--Web tab-->
                            <kn-tab tabTitle="Web" [disabled]="isWebTabDisabled">
                                <div class="web-download-btn">
                                    <kn-button 
                                        [buttonId]="'webCpmponentsDownloadBtn'" 
                                        [buttonType]="'kn-tertiary'" 
                                        [buttonText]="'Download Web components'"
                                        [buttonIconLeft]="'fa-regular fa-download'"
                                        (btnClick)="docService.downloadWebComponents()">
                                    </kn-button>
                                </div>
                                <div class="code-group">
                                    <div class="tag">HTML</div>
                                    <div class="web-code">                             
                                        <span class="html-tags">&lt;!DOCTYPE <span class="html-option">html</span>&gt;</span><br/>
                                        <span class="html-tags">&lt;html <span class="html-option">lang=</span><span class="html-value">&quot;en&quot;</span>&gt;</span><br/>
                                        &nbsp;&nbsp;<span class="html-tags">&lt;head&gt;</span><br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<span class="html-tags">&lt;link <span class="html-option">rel=</span><span class="html-value">"stylesheet" </span> <span class="html-option">href=</span><span class="html-value">"styles.css"</span>&gt;</span><br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<span class="html-tags">&lt;link <span class="html-option">rel=</span><span class="html-value">"stylesheet" </span> <span class="html-option">href=</span><span class="html-value">"css/all.css"</span>&gt;</span><br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<span class="html-tags">&lt;link <span class="html-option">rel=</span><span class="html-value">"stylesheet" </span> <span class="html-option">href=</span><span class="html-value">"custom-icons/style.css"</span>&gt;</span><br/>
                                        &nbsp;&nbsp;&nbsp;&nbsp;<span class="html-tags">&lt;script <span class="html-option">src=</span><span class="html-value">"component-framework.js"</span> <span class="html-option"> async</span>&gt;&lt;/script&gt;</span><br/>
                                        &nbsp;&nbsp;<span class="html-tags">&lt;/head&gt;</span><br/>
                                        &nbsp;&nbsp;<span class="html-tags">&lt;body&gt;</span><br/>
                                        <!--if a trigger is present-->
                                        <div class="html-code-demo" *ngIf="trigger && trigger.web.tag">
                                            <span class="html-tags">&lt;{{trigger.web.tag}}</span>
                                            <ng-container *ngFor="let option of trigger.web.options">
                                                <span class="html-option"> {{option.option}}</span><span class="html-value">{{option.value}}</span>
                                            </ng-container>
                                            <span class="html-tags">&gt;</span><pre class="code-content" *ngIf="trigger.web.content"><code><span class="html-content">{{trigger.web.content}}</span></code></pre><span class="html-tags">&lt;/{{trigger.web.tag}}&gt;</span>
                                        </div>
                                        <p class="html-code-demo" *ngIf="webHtml?.tag">
                                            <span class="html-tags">&lt;{{webHtml?.tag}}</span>
                                            <ng-container *ngFor="let option of webHtml?.options">
                                                <span class="html-option"> {{option.option}}</span><span class="html-value">{{option.value}}</span>
                                            </ng-container>
                                            <span class="html-tags">&gt;</span><span><pre class="code-content" *ngIf="webHtml?.content"><code><span class="html-content">{{webHtml?.content}}</span></code></pre></span><span class="html-tags">&lt;/{{webHtml?.tag}}&gt;</span>
                                        </p>
                                        &nbsp;&nbsp;<span class="html-tags">&lt;/body&gt;</span><br/>
                                        <span class="html-tags">&lt;/html&gt;</span>
                                    </div>
                                </div>
                                <br/>

                                <div class="divider" *ngIf="js"></div>

                                <div class="code-group script" *ngIf="js">
                                    <div class="tag">JavaScript</div>
                                    <pre>
                                        <code>
                                            <ng-content select="[javascript]"></ng-content>
                                        </code>
                                    </pre>
                                </div>
                            </kn-tab>

                            <!--Events tab-->
                            <kn-tab tabTitle="Events" [disabled]="isEventTabDisabled">
                                <ng-content select="[events]"></ng-content>
                            </kn-tab>
                        </kn-tabs>
                    </div>
                </div>
            
            </div>
        </kn-tab>

        <!--Properties tab-->
        <kn-tab tabTitle="Properties" [disabled]="isPropertiesTabDisabled">
            <ng-content select="[props]"></ng-content>
        </kn-tab>

        <!--Installation guide tab-->
        <kn-tab tabTitle="Installation Guide">
            <div class="canvas-inner-container">
                <div class="kn-bold">In order to download component packages, you need to have an .npmrc file in the root of your project. Add the following lines to your .npmrc file.</div>
                <pre><code><span class="html-tags">registry</span><span class="curly-braces">=https://lhediscovery.pkgs.visualstudio.com/_packaging/lighthouse-npm/npm/registry/ <i (mouseover)="resetCopyText()" tooltip="{{copyText}}" [tooltipDirection]="'top'" class="fa-regular fa-copy copy kn-icon kn-icon-dark" aria-hidden="true" (click)="copyNpmrc()"></i></span>                      
<span class="html-tags">always-auth</span><span class="curly-braces">=true</span></code></pre>

<div class="kn-bold">Make sure you have Azure DevOps Auth helper installed. If you do not have it, run the following command.</div>
                <pre><code><span class="curly-braces">npm i vsts-npm-auth <i (mouseover)="resetCopyText()" tooltip="{{copyText}}" [tooltipDirection]="'top'" class="fa-regular fa-copy copy kn-icon kn-icon-dark" aria-hidden="true" (click)="copyCredentials('npm i vsts-npm-auth')"></i></span></code></pre>

<!--Credentials-->
            <div class="kn-bold">Next, you need to run the following command to generate credentials for the feed. Run this command in the same folder as the .npmrc file.<br/>
            Additional information can be found <a class="kn-link" href="https://lhediscovery.visualstudio.com/Lighthouse%20Platform/_artifacts/feed/lighthouse-npm/connect" target="_blank">here</a>. Click on "npm" to find detailed instructions.</div>
                <pre><code><span class="curly-braces">vsts-npm-auth -F -C .npmrc <i (mouseover)="resetCopyText()" tooltip="{{copyText}}" [tooltipDirection]="'top'" class="fa-regular fa-copy copy kn-icon kn-icon-dark" aria-hidden="true" (click)="copyCredentials('vsts-npm-auth -F -C .npmrc')"></i></span></code></pre>
<!--Package dependencies-->
            <span *ngIf="peerDependencies.length">
                <div class="kn-bold">Package dependencies</div>
                <pre><ng-container *ngFor="let item of peerDependencies"><code id="dependencies"><span class="curly-braces">npm install {{item.package}} <i (mouseover)="resetCopyText()" tooltip="{{copyText}}" [tooltipDirection]="'top'" class="fa-regular fa-copy copy kn-icon kn-icon-dark" aria-hidden="true" (click)="copyDependency(item.package)"></i></span></code><br/></ng-container></pre>
            </span>
            <!--Install package-->
            <div *ngIf="component?.install != false">
                <div class="kn-bold">Install the package</div>
                <pre><code id="install"><span class="curly-braces">npm install {{component.name}}@{{component.version}} <i (mouseover)="resetCopyText()" tooltip="{{copyText}}" [tooltipDirection]="'top'" class="fa-regular fa-copy copy kn-icon kn-icon-dark" aria-hidden="true" (click)="copy()"></i></span></code></pre>
            </div>
            <div *ngIf="component?.installWarning">
                <div class="kn-alert-warning">
                    <i class="fa-solid fa-triangle-exclamation"></i><span [innerHtml]="component?.installWarning"></span>
                </div>
                <br/>
            </div>
<span *ngIf="component.module"><pre><code><span class="ts-decorator">import</span> <span class="curly-braces">&#123;</span><span class="html-option"> {{component.module}} <span class="curly-braces">}</span></span> <span class="ts-decorator">from</span> <span class="html-value">'{{component.name}}'</span><span class="curly-braces">;</span>
<span *ngIf="component.form"><span class="ts-decorator">import</span> <span class="curly-braces">&#123;</span><span class="html-option"> FormsModule <span class="curly-braces">}</span></span> <span class="ts-decorator">from</span> <span class="html-value">'@angular/forms'</span><span class="curly-braces">;</span></span>
<span class="curly-braces">@</span><span class="ts-injector">NgModule</span><span class="curly-braces">(&#123;</span>
    <span class="ts-decorator">imports:</span> <span class="curly-braces">[</span> <span class="ts-injector">BrowserModule</span><span class="curly-braces">,</span> <span class="ts-injector">{{component.module}}</span><span *ngIf="component.form"><span class="curly-braces">,</span> <span class="ts-injector">FormsModule</span></span><span class="curly-braces"> ]</span>
<span class="curly-braces">})</span>

<span class="ts-decorator">export</span> <span class="ts-class">class</span> <span class="ts-injector">AppModule</span>  <span class="curly-braces">&#123; }</span></code></pre></span>
            <div class="kn-bold">styles.sass (import the kraken stylesheet)</div>
            <pre><code><span class="ts-decorator">@import</span> <span class="ts-class">'../node_modules/@kraken-ui/kraken-theme/styles/main'</span></code></pre>

</div>
           
        </kn-tab>

        <!--Usage tab-->
        <kn-tab tabTitle="Usage Guidelines">
            <ng-content select="[usage]"></ng-content>
        </kn-tab>

        <!--Design tab-->
        <kn-tab tabTitle="Design Specifications">
            <ng-content select="[designSpec]"></ng-content>
        </kn-tab>

        <!--Accessibility tab-->
        <kn-tab tabTitle="Accessibility" [disabled]="isAccessibilityTabDisabled">
            <ng-content select="[accessibility]"></ng-content>
        </kn-tab>

        <!--Change log tab-->
        <kn-tab tabTitle="Change Log">
            <ng-content select="[changeLog]"></ng-content>
        </kn-tab>
    </kn-tabs>
</div>
