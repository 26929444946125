import { NgModule } from '@angular/core';
import { KrakenToastComponent } from './kraken-toast.component';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    KrakenToastComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule
  ],
  exports: [
    KrakenToastComponent
  ]
})
export class KrakenToastModule { }
