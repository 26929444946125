import { Component } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';
@Component({
  selector: 'split-button-accessibility',
  templateUrl: './split-button-accessibility.component.html',
  styleUrls: ['./split-button-accessibility.component.sass']
})
export class SplitButtonAccessibilityComponent {
  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;

  constructor(private templateService: TemplateUpdateService) { }

}
