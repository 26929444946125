import { AfterViewInit, Component, ElementRef } from '@angular/core';
import { NavigationService } from '../../../app/services/navigation.service';
import { SitemapService } from '../../../app/services/sitemap.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.sass']
})
export class IntegrationsComponent implements AfterViewInit {

  constructor(
    public elm: ElementRef, 
    public nav: NavigationService,
    public siteMapService: SitemapService,
    public router: Router
  ) { }

  ngAfterViewInit(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
    });
}

go() {
  const accordion = document.querySelector('.kn-accordion');
  this.nav.accordionNavigation(accordion, 3, 0);
}

}