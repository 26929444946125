import { Component, Input, Output, ViewChild, OnInit, ElementRef, ChangeDetectorRef, AfterViewInit , EventEmitter } from '@angular/core';

@Component({
  selector: 'kn-accordion-tier-one',
  templateUrl: './kraken-accordion-tier-one.component.html',

})
export class KrakenAccordionTierOneComponent implements OnInit, AfterViewInit  {
  @Input() id: string;
  @Input() showIcon = false;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() subHeader = false;
  @Input() ngRouterLink: string;
  @Input() icon: string;
  @Input() disabled = false;
  @Input() index: number;
  @Output() onSelect = new EventEmitter<(any)>();
  
  @ViewChild('contentContainer') contentContainer: ElementRef;
  @ViewChild('label') labelElm: ElementRef;
  
  public hasContent;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.id = this.id ? this.id : 'knAccordionTierOne_' + Math.floor(Math.random()*90000) + 10000;
  }

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      if(this.contentContainer.nativeElement.children.length > 0) {
        this.hasContent = this.contentContainer.nativeElement.children[0].innerHTML.trim() ? true : false;
        
      } else {
        this.hasContent = false;
      }

      this.cdr.detectChanges();
    });
  }

  select(event) {
    const item: any = {};
    item.id = this.id;
    item.title = this.labelElm.nativeElement.innerText;
    if(this.icon) {
      item.icon = this.icon;
    }
    if(this.contentContainer) {
      item.content = this.contentContainer.nativeElement.innerHTML;
    }
    this.onSelect.emit({event: event, item: item});
  }

}
