<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">A <span class="kn-bold"> Button Group </span> is a linear type of button that organizes two or more buttons within a single, visually cohesive container. These buttons are related and represent different actions or options within a specific context.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Outlined:</span> Displays the button group with a white background and outlined stroke (default).</div>
            <div class="text"><span class="kn-bold">2.	Filled:</span> Displays the button group with a filled background. This version is reserved only for cases when a higher contrast is needed.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_02.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Single selection:</span> Only one option can be selected at a time. Selecting one option deselects all other options.</div>
            <div class="text"><span class="kn-bold">2.	Multi-selection:</span> Multiple options can be selected at a time. Selecting an option toggles between the selected and unselected states.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_03.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Text</span></div>
            <div class="text"><span class="kn-bold">2.	Text with icon</span></div>
            <div class="text"><span class="kn-bold">3.	Icon</span></div>
        </section>


        <section>
            <div class="kn-headline-24-med">States</div>
        </section>
        
        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_04.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Enabled unselected</span></div>
            <div class="text"><span class="kn-bold">2.	Hover</span></div>
            <div class="text"><span class="kn-bold">3.	Pressed</span></div>
            <div class="text"><span class="kn-bold">4.	Selected</span></div>
            <div class="text"><span class="kn-bold">5.	Disabled unselected</span></div>
            <div class="text"><span class="kn-bold">6.	Disabled selected</span></div>
        </section>


        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_05.png">

        <section>
            <div class="text"><span class="kn-bold">A.	Container:</span> Primary element that holds content, color, and icons.</div>
            <div class="text"><span class="kn-bold">B.	Leading icon</span></div>
            <div class="text"><span class="kn-bold">C.	Text label</span></div>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Container</div>
            <div class="text">Button groups can include 2-4 button segments. Each segment is clearly divided and contains label text, an icon, or both.</div>
        </section>

        <section>
            <section>
                <div class="kn-subtitle-16">Label text</div>
                <div class="text">A button text label is the most important element on a button, since it communicates the action that occurs when the user interacts with it. Ensure button labels clearly indicate the action of the button (for example, Download, Sign Up).</div>
            </section>

            <section>
                <ul class="bullets">
                    <li>Use single words or succinct phrases that do not exceed 25 characters to support quick scanning and interpretation.</li>
                    <li>Do not use punctuation marks such as periods or exclamation points.</li>
                    <li>Use only uppercase text.</li>
                </ul>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_06.png">

        <section>
            <div class="kn-subtitle-16">Icons</div>
            <div class="text">Icons can be combined with text labels within buttons to further reinforce the purpose of the button and the action triggered by interacting with it. Icons can also be used instead of text labels in button groups.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_07.png">

        <section>
            <div class="kn-subtitle-16">Single selection</div>
            <div class="text">A button group component with a single selection function allows users to choose only one option from a set of related buttons. When users select one button, any previously selected button within the group is automatically deselected.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_09.png">

        <section>
            <div class="kn-subtitle-16">Multi-selection</div>
            <div class="text">A button group component with a multi-selection function enables users to select multiple options simultaneously from a group of related buttons. Unlike single selection button groups, multi-selection groups allows users to pick several buttons at once, which is suitable when users need to apply multiple filters, apply tags, or perform bulk actions.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_08.png">

        <section>
            <div class="kn-subtitle-16">Number of buttons</div>
            <div class="text">Limit the number of buttons displayed within a button group to four or fewer. If more are required, consider a dropdown menu.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_10.png">

            <section>
                <div class="kn-subtitle-16">Orientation</div>
                <div class="text">A button group can be either oriented horizontally or vertically. When displayed horizontally, the button group appears above the data or content displayed on the screen that it directly impacts. When displayed vertically, the button group appears on the left or right of the data or content that it directly impacts.</div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">1.	Horizontal:</span> By default, a button group is horizontal.</div>
                <div class="text"><span class="kn-bold">2.	Vertical:</span> Use the vertical option when horizontal space is limited.</div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_13.png">

            <section>
                <div class="kn-subtitle-16">Size</div>
                <div class="text">Button groups are available in two different sizes: default and small. Use the small size only to create a hierarchy of importance with the page.</div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">1.	Default button height: 36px</span></div>
                <div class="text"><span class="kn-bold">2.	Small button height: 28px</span></div>
            </section>
        </section>

        <section>
            <section>
                <div class="kn-subtitle-16">Width</div>
                <div class="text">By default, the control for button groups calculates the button width and applies it to all buttons within the group. The button width scales in size with the length of the button label, and the button retains a consistent 16px padding with the vertical boundaries of the button. Button widths are never narrower than their label text.</div>
            </section>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_11.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Horizontal orientation</span></div>
                <div class="text"><span class="kn-bold">1.	Default button width: 132px</span></div>
                <div class="text"><span class="kn-bold">2.	Small button width: 108px</span></div>
            </section>

            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_12.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Vertical orientation</span></div>
                <div class="text"><span class="kn-bold">1.	Default button minimum width: 132px</span></div>
                <div class="text"><span class="kn-bold">2.	Small button minimum width: 132px</span></div>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_14.png">

        <section>
            <div class="kn-subtitle-16">Single-setting scenario</div>
            <div class="text">Button groups are commonly used for turning on settings with multiple options. If you only need a single setting that turns on and off, consider using a toggle switch.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_15.png">

        <section>
            <div class="kn-subtitle-16">Wrapping labels</div>
            <div class="text">Limit labels displayed within the presentation of button groups to a single line and do not wrap to a second line of text.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_16.png">

        <section>
            <div class="kn-subtitle-16">Spanning multiple rows</div>
            <div class="text">Don't allow button groups to wrap onto a new line.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/buttongroup/KrakenDSM_Components_ButtonGroup_17.png">

        <section>
            <div class="kn-subtitle-16">Icons that do not reinforce action</div>
            <div class="text">Only use an icon within a button group if it substantially reinforces the purpose of the trigger by clicking or tapping the button (or when including the icon draws the necessary attention of the user to the presence of the action button in the interface layout as observed in user testing). Avoid using icons in button groups for aesthetic purposes only or in ways that do not intuitively reinforce the action of the button. Never use more than one icon in a button.</div>
        </section>
    </div>
</div>