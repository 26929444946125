import { Component, Input, OnInit, Output, AfterViewInit, ElementRef, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'kn-button-group',
  templateUrl: './kraken-button-group.component.html'
})
export class KrakenButtonGroupComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() componentId: string;
  @Input() layout = 'row';
  @Input() selectionType = 'single';
  @Input() compressed = false;
  @Input() web = false;
  @Input() items = [];
  @Output() btnClick = new EventEmitter<any>();

  public buttons: any;
  public timeout: number;

  constructor(public element: ElementRef) {}

  ngOnChanges(changes) {
   if(changes.compressed) {
    if(!changes.compressed.firstChange) {
      this.buttons.forEach((btn) => {
        if(this.compressed) {
          btn.classList.add('kn-btn-sm');
        }  else {
          btn.classList.remove('kn-btn-sm');
        }
      });
    }
   }
  }

  ngOnInit(): void {
    this.componentId = this.componentId ? this.componentId : 'knButtonGroup_' + Math.floor(Math.random()*90000) + 10000;
    this.timeout  = this.web ? 500 : 0;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      const buttonGroupContainer = this.element.nativeElement.querySelector('#' + this.componentId + ' .kn-button-group-container');
      this.buttons = buttonGroupContainer.querySelectorAll('.kn-btn');
      let active = false;

      this.buttons.forEach((btn) => { 
        if(this.compressed) {
          btn.classList.add('kn-btn-sm');
        }
        btn.addEventListener("click", (e) => {
          if(this.selectionType == 'single') {
            this.buttons.forEach((el) => el.classList.remove('active'));
            e.target.classList.add('active');
          } else {
            e.target.classList.toggle('active');
          }

          active = e.target.classList.contains('active') ? true : false;

          this.btnClick.emit({
            id: e.target.id,
            label: e.target.innerText,
            active: active
          });
        });
      });
  }, this.timeout);
  }
}
