<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Roadmap</div>
        <section>
            <div class="kn-headline-24 version">MAR 2024</div>
        </section>

        <section>
            <div class="text">Data Grid Enhancement - Freeze Column</div>
            <div class="text">Data Grid Enhancement - Search Grid</div>
            <div class="text">Layout Tile</div>
        </section>

        <hr/>

        <section>
            <div class="kn-headline-24 version">APR 2023</div>
        </section>

        <section>
            <div class="text">Masthead Enhancement - Sub-navigation Menu</div>
            <div class="text">Alert Banner</div>
        </section>

        <hr/>

        <section>
            <div class="kn-headline-24 version">MAY 2024</div>
        </section>

        <section>
            <div class="text">Carousel</div>
            <div class="text">Badge</div>
        </section>
    </div>
</div>