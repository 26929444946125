import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-dsm',
  templateUrl: './why-dsm.component.html',
  styleUrls: ['./why-dsm.component.sass']
})
export class WhyDsmComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
