import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KrakenButtonComponent } from './kraken-button.component';



@NgModule({
  declarations: [KrakenButtonComponent],
  imports: [
    CommonModule
  ],
  exports: [KrakenButtonComponent]
})
export class KrakenButtonModule { }
