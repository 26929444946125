import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KrakenIconMenuComponent } from './kraken-icon-menu.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    KrakenIconMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [
    KrakenIconMenuComponent
  ]
})
export class KrakenIconMenuModule { }
