import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'chip-usage',
  templateUrl: './chip-usage.component.html',
  styleUrls: ['./chip-usage.component.sass']
})
export class ChipUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
