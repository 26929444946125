<footer 
    id="{{id}}"
    class="kn-footer"
    [ngClass]="{
        'sticky': sticky
    }">
    <div class="copyright">&copy; {{fullYear}} Lighthouse. All Rights Reserved.</div>
    <div class="right-nav">
        <ng-content></ng-content>
    </div>
</footer>