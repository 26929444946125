import { Component } from '@angular/core';
import  packageInfo from '../../../../../kraken-spin-box/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';

@Component({
  selector: 'app-spin-box',
  templateUrl: './spin-box.component.html',
  styleUrls: ['./spin-box.component.sass']
})
export class SpinBoxComponent {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public event1 = true;
  public event2 = true;
  public elementTag = 'kn-spin-box';
  public id = 'spinBoxId'
  public value = 0;
  public increment = 1;
  public readOnly = false;
  public disabled = false;
  public addLabel = false;
  public maxValue: number;
  public minValue: number;
  public label: string;
  public valueAlignRight;
  public compressed = false;
  public customError;
  public error = false;
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenSpinBoxModule'};
  public componentId = 'spinBoxComponentId';
  public layoutObj: any = [
    { layout: 'vertical right', name: 'Vertical Right' },
    { layout: 'vertical left', name: 'Vertical Left' },
    { layout: 'horizontal', name: 'Horizontal' }
  ]

  public layoutFields = { text: 'name', value: 'layout' }
  public layout = this.layoutObj[0].layout;
  public currentLayout = this.layoutObj[0].name;
  public angularComponentOptions: any = [
     { option: '[componentId]=', id: 0, value: '"\''+ this.id + '\'"'},
     { option: '[value]=', id: 0, value: '"\''+ this.value + '\'"'},
     { option: '[layout]=', id: 0, value: '"\''+ this.layout + '\'"'}
  ];

  public webComponentOptions: any = [
     { option: 'id=', id: 0, value: '"'+ this.id +'"'},
     { option: 'value=', id: 0, value: '"'+ this.value +'"'},
     { option: 'layout=', id: 0, value: '"'+ this.layout +'"'}
  ];

  public customErrorTemplate = `
  public error = false;
  public customError: string;

  customErrorFunction() {
    if(event == 5) {
      this.error = true;
      this.customError = "The value is incorrect";
    } else {
      this.error = false;
    }
  }`

  public jsCustomErrorTemplate = `
  let spinBox = document.getElementById('spinBoxId');
  spinBox.addEventListener('onValueChange', (e)=> {
    if(e.detail == 2) {
      spinBox.hasError = true;
      spinBox.customErrorMessage = "Oops, wrong value";
    } else {
      spinBox.hasError = false;
    }
  });`

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  labelEvent(event) {
    this.label = event;
    if(this.angularComponentOptions.findIndex((obj => obj.option == '[label]=')) == -1) {
      this.controlEvent(true, '[label]=', true, true);
    }
    this.tempUpdateService.updateProperty(this.angularComponentOptions, '[label]=', event);
    this.tempUpdateService.updateProperty(this.webComponentOptions, 'label=', event, true);
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  valueChange(event) {
    this.value = event;
    
    this.tempUpdateService.updateProperty(this.angularComponentOptions, '[value]=', event);
    this.tempUpdateService.updateProperty(this.webComponentOptions, 'value=', event, true);
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    // if(event == 2) {
    //   this.error = true;
    //   this.customError = "The value is incorrect";
    // } else {
    //   this.error = false;
    // }
  }

  onLayoutEvent(event) {
    if(event) {
      this.layout = event.layout;
      this.tempUpdateService.updateProperty(this.angularComponentOptions, '[layout]=', event.layout);
      this.tempUpdateService.updateProperty(this.webComponentOptions, 'layout=', event.layout, true);
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    }
  }

  incrementEvent(event) {
    this.increment = event;
    if(this.angularComponentOptions.findIndex((obj => obj.option == '[increment]=')) == -1) {
      this.controlEvent(true, '[increment]=', true, true);
    }
    this.tempUpdateService.updateProperty(this.angularComponentOptions, '[increment]=', event);
    this.tempUpdateService.updateProperty(this.webComponentOptions, 'increment=', event, true);
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  maxValueEvent(event) {
    this.maxValue = event;
    if(this.angularComponentOptions.findIndex((obj => obj.option == '[maxValue]=')) == -1) {
      this.controlEvent(true, '[maxValue]=', true, true);
    }
    this.tempUpdateService.updateProperty(this.angularComponentOptions, '[maxValue]=', event);
    this.tempUpdateService.updateProperty(this.webComponentOptions, 'max-value=', event, true);
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  minValueEvent(event) {
    this.minValue = event;
    if(this.angularComponentOptions.findIndex((obj => obj.option == '[minValue]=')) == -1) {
      this.controlEvent(true, '[minValue]=', true, true);
    }
    this.tempUpdateService.updateProperty(this.angularComponentOptions, '[minValue]=', event);
    this.tempUpdateService.updateProperty(this.webComponentOptions, 'min-value=', event, true);
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  toggleLabel(event) {
    this.controlEvent(event, '[label]=', '', true);
    this.addLabel = event;
    this.label = event ? this.label : '';
  }
}
