<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Typography</div>
        
        <section>
            <div class="kn-headline-24-med">On light background</div>
        </section>
        
        <section>
            <div class="text">When type is presented on a light value canvas, panel, or card in the UI. It uses a dark value from the grayscale palette other than pure black. A couple of options are available to support the development of visual hierarchy in the screen typography.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/typography/KrakenDSM_StyleGuide_Color_Typography_01.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Primary Light Background</span></div>
            <div class="text"><span class="kn-bold">03. Ink:</span> #1F1D21</div>
            <span class="codesnippet">Theme variable: <pre><code>$ink</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--ink</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Secondary Light Background</span></div>
            <div class="text"><span class="kn-bold">04. Ink, Light:</span> #434C54</div>
            <span class="codesnippet">Theme variable: <pre><code>$ink-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--ink-light</code></pre></span>
        </section>


        <section>
            <div class="kn-headline-24-med">On dark background</div>
        </section>

        <section>
            <div class="text">Alternatively, when type is presented on a dark value panel or card, we use light values from the greyscale palette including white. A couple of options are available to support the development of visual hierarchy in the screen typography.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/typography/KrakenDSM_StyleGuide_Color_Typography_02.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Primary Dark Background</span></div>
            <div class="text"><span class="kn-bold">14. White:</span> #FFFFFF</div>
            <span class="codesnippet">Theme variable: <pre><code>$white</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--white</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Secondary Dark Background</span></div>
            <div class="text"><span class="kn-bold">10. Gray, Light:</span> #E1E6EB</div>
            <span class="codesnippet">Theme variable: <pre><code>$gray-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--gray-light</code></pre></span>
        </section>


        <section>
            <div class="kn-headline-24-med">Disabled, hyperlink, error, and warning</div>
        </section>

        <section>
            <div class="text">Type color for the disabled state, hyperlink, and illustration of an error pull from carefully selected and accessibility tested values from the grayscale, primary, and notification & alert color palettes respectively.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/typography/KrakenDSM_StyleGuide_Color_Typography_03.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Disabled</span></div>
            <div class="text"><span class="kn-bold">07. Gray, Darker:</span> #A5B2BD</div>
            <span class="codesnippet">Theme variable: <pre><code>$gray-darker</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--gray-darker</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Hyperlink</span></div>
            <div class="text"><span class="kn-bold">03. Primary:</span> #205D86</div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Error</span></div>
            <div class="text"><span class="kn-bold">03. Danger:</span> #A50D08</div>
            <span class="codesnippet">Theme variable: <pre><code>$knDanger</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-danger</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Warning</span></div>
            <div class="text"><span class="kn-bold">03. Secondary:</span> #B8551E</div>
            <span class="codesnippet">Theme variable: <pre><code>$secondary_one</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--secondary_one</code></pre></span>
        </section>
    </div>
</div>