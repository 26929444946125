import { Component } from '@angular/core';

@Component({
  selector: 'button-group-usage',
  templateUrl: './button-group-usage.component.html',
  styleUrls: ['./button-group-usage.component.sass']
})
export class ButtonGroupUsageComponent {

}
