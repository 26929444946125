<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Effects</div>

        <section>
            <div class="text">Kraken uses a combination of easing and duration to create animation effects. Here are a few examples of </div>
        </section>

        <section>
            <div class="kn-headline-24-med">Motion effects</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/motion/effects/KrakenDSM_StyleGuide_Motion_Effects_01.gif">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Expand down</span></div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/motion/effects/KrakenDSM_StyleGuide_Motion_Effects_02.gif">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Color & fill</span></div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/motion/effects/KrakenDSM_StyleGuide_Motion_Effects_03.gif">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Fade in</span></div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/motion/effects/KrakenDSM_StyleGuide_Motion_Effects_04.gif">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Slide</span></div>
        </section>


        <section>
            <div class="kn-headline-24-med">Choreography behaviors</div>
        </section>

        <section>
            <div class="text">The choreography behaviors below allow you to optimize your motion design.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/motion/effects/KrakenDSM_StyleGuide_Motion_Effects_05.gif">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Offset</span></div>

            <div class="text">Combining spatial offset and direction allows you to connect an element to the part of the screen it belongs to. </div>
        </section>

        <section>
            <div class="text">Objects can use animated offset to demonstrate their parent group or hierarchy, and to show where they are coming from and where they are going.</div>
        </section>        
    </div>
</div>