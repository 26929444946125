import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-elevation',
  templateUrl: './elevation.component.html',
  styleUrls: ['./elevation.component.sass']
})
export class ElevationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
