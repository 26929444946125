import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ButtonsComponent } from './web-components/buttons/buttons.component';
import { TabsComponent } from './web-components/tabs/tabs.component';
import { IconCatalogComponent } from './style-guide/iconography/icon-catalog/icon-catalog.component';
import { TextBoxComponent } from './web-components/text-box/text-box.component';
import { SplitButtonComponent } from './web-components/split-button/split-button.component';
import { MenuButtonComponent } from './web-components/menu-button/menu-button.component';
import { IconButtonComponent } from './web-components/icon-button/icon-button.component';
import { TextAreaComponent} from './web-components/text-area/text-area.component';
import { MultiSelectComponent} from './web-components/multi-select/multi-select.component';
import { RadioButtonComponent } from './web-components/radio-button/radio-button.component';
import { CheckboxComponent } from './web-components/checkbox/checkbox.component';
import { ToolTipComponent } from './web-components/tool-tip/tool-tip.component';
import { DropdownComponent } from './web-components/dropdown/dropdown.component';
import { SearchboxComponent } from './web-components/searchbox/searchbox.component';
import { ToggleComponent } from './web-components/toggle/toggle.component';
import { DesignPrinciplesComponent } from './getting-started/designers/design-principles/design-principles.component';
import { ColorPalettesComponent } from './style-guide/palettes/color-palettes/color-palettes.component';
import { ButtonsPaletteComponent } from './style-guide/palettes/buttons/buttons.component';
import { IconsComponent } from './style-guide/palettes/icons/icons.component';
import { TypeComponent } from './style-guide/palettes/type/type.component';
import { LayoutBackgroundComponent } from './style-guide/palettes/layout-background/layout-background.component';
import { NotificationsComponent } from './style-guide/palettes/notifications/notifications.component';
import { BodyTextComponent } from './style-guide/typography/body-text/body-text.component';
import { CapitalizationComponent } from './style-guide/typography/capitalization/capitalization.component';
import { FormLabelsComponent } from './style-guide/typography/form-labels/form-labels.component';
import { HeadingSubheadingComponent } from './style-guide/typography/heading-subheading/heading-subheading.component';
import { FontsComponent } from './style-guide/typography/fonts/fonts.component';
import { GridLayoutComponent } from './style-guide/grid-layout/grid-layout.component';
import { ElevationComponent } from './style-guide/elevation/elevation.component';
import { SpacingComponent } from './style-guide/spacing/spacing.component';
import { LogoComponent } from './style-guide/logo/logo.component';
import { MotionEasingComponent } from './style-guide/motion/motion-easing/motion-easing.component';
import { MotionSpeedComponent } from './style-guide/motion/motion-speed/motion-speed.component';
import { MotionEffectsComponent } from './style-guide/motion/motion-effects/motion-effects.component';
import { ContentUsageComponent } from './style-guide/content/content-usage/content-usage.component';
import { ContentFormattingComponent } from './style-guide/content/content-formatting/content-formatting.component';
import { ContentNotificationsComponent } from './style-guide/content/content-notifications/content-notifications.component';
import { ContentValidationErrorsComponent } from './style-guide/content/content-validation-errors/content-validation-errors.component';
import { ContentWordUseComponent } from './style-guide/content/content-word-use/content-word-use.component';
import { ContactSubmitComponent } from './support/contact-submit/contact-submit.component';
import { ReleaseNotesComponent } from './support/release-notes/release-notes.component';
import { WhyDsmComponent } from './about/why-dsm/why-dsm.component';
import { EngagingUxComponent } from './about/engaging-ux/engaging-ux.component';
import { IntegrationsComponent } from './about/integrations/integrations.component';
import { ReviewTestingComponent } from './about/review-testing/review-testing.component';
import { AccessibilityComponent } from './about/accessibility/accessibility.component';
import { UiKitComponent } from './getting-started/designers/ui-kit/ui-kit.component';
import { IconStyleComponent } from './style-guide/iconography/icon-style/icon-style.component';
import { GridComponent } from './web-components/grid/grid.component';
import { HomeComponent } from './home/home.component';
import { DesignResourcesComponent } from './getting-started/designers/design-resources/design-resources.component';
import { UIFrameworkComponent } from './getting-started/developers/ui-framework/ui-framework.component';
import { DevelopmentResourcesComponent } from './getting-started/developers/development-resources/development-resources.component';
import { ThemingComponent } from './getting-started/developers/theming/theming.component';
import { IconMenuComponent } from './web-components/icon-menu/icon-menu.component';
import { AccordionComponent } from './web-components/accordion/accordion.component'; 
import { RoadmapComponent } from './support/roadmap/roadmap.component';
import { ModalComponent } from './web-components/modal/modal.component';
import { SpinnerComponent } from './web-components/spinner/spinner.component';
import { MastheadComponent } from './web-components/masthead/masthead.component';
import { FooterComponent } from './web-components/footer/footer.component';
import { ContextualMenuComponent } from './web-components/contextual-menu/contextual-menu.component';
import { PanelComponent } from './web-components/panel/panel.component';
import { ProgressIndicatorComponent } from './web-components/progress-indicator/progress-indicator.component';
import { ToastComponent } from './web-components/toast/toast.component'; 
import { ChipComponent } from './web-components/chip/chip.component';
import { SliderComponent } from './web-components/slider/slider.component';
import { ButtonGroupComponent } from './web-components/button-group/button-group.component';
import { SpinBoxComponent } from './web-components/spin-box/spin-box.component';
import { DateRangePickerComponent } from './web-components/date-range-picker/date-range-picker.component';
import { DatePickerComponent } from './web-components/date-picker/date-picker.component';
import { SpreadsheetComponent } from './web-components/spreadsheet/spreadsheet.component';
import { AnalyzerComponent } from './analyzer/analyzer.component';
import { TileComponent } from './style-guide/patterns/tile/tile.component';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {path: 'login', component: LoginComponent },
  {path: 'home', component: HomeComponent },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {path: 'action-button', component: ButtonsComponent },
  {path: 'tabs', component: TabsComponent },
  {path: 'icon-catalog', component: IconCatalogComponent },
  {path: 'textbox', component: TextBoxComponent },
  {path: 'split-button', component: SplitButtonComponent },
  {path: 'menu-button', component: MenuButtonComponent },
  {path: 'icon-button', component: IconButtonComponent },
  {path: 'textarea', component: TextAreaComponent },
  {path: 'multi-select', component: MultiSelectComponent },
  {path: 'radio-button', component: RadioButtonComponent },
  {path: 'checkbox', component: CheckboxComponent },
  {path: 'tooltip', component: ToolTipComponent },
  {path: 'dropdown', component: DropdownComponent },
  {path: 'searchbox', component: SearchboxComponent },
  {path: 'toggle', component: ToggleComponent },
  {path: 'getting-started/design-principles', component: DesignPrinciplesComponent },
  {path: 'palettes/color-palettes', component: ColorPalettesComponent },
  {path: 'palettes/buttons', component: ButtonsPaletteComponent },
  {path: 'palettes/iconography', component: IconsComponent },
  {path: 'palettes/typography', component: TypeComponent },
  {path: 'palettes/layout-background', component: LayoutBackgroundComponent },
  {path: 'palettes/notifications', component: NotificationsComponent },
  {path: 'typography/body-text', component: BodyTextComponent },
  {path: 'typography/capitalization', component: CapitalizationComponent },
  {path: 'typography/labels-values', component: FormLabelsComponent },
  {path: 'typography/headings-subheadings', component: HeadingSubheadingComponent },
  {path: 'typography/fonts', component: FontsComponent },
  {path: 'style-guide/grid-layout', component: GridLayoutComponent },
  {path: 'style-guide/elevation', component: ElevationComponent },
  {path: 'style-guide/spacing', component: SpacingComponent },
  {path: 'style-guide/logos', component: LogoComponent },
  {path: 'style-guide/motion-easing', component: MotionEasingComponent },
  {path: 'style-guide/motion-speed', component: MotionSpeedComponent },
  {path: 'style-guide/motion-effects', component: MotionEffectsComponent },
  {path: 'style-guide/content-usage', component: ContentUsageComponent },
  {path: 'style-guide/content-formatting', component: ContentFormattingComponent },
  {path: 'style-guide/content-notifications', component: ContentNotificationsComponent },
  {path: 'style-guide/content-validation', component: ContentValidationErrorsComponent },
  {path: 'style-guide/content-word-use', component: ContentWordUseComponent },
  {path: 'support/contact', component: ContactSubmitComponent },
  {path: 'support/release-notes', component: ReleaseNotesComponent },
  {path: 'about/why-dsm', component: WhyDsmComponent },
  {path: 'about/engaging-ux', component: EngagingUxComponent },
  {path: 'about/integration', component: IntegrationsComponent },
  {path: 'about/process', component: ReviewTestingComponent },
  {path: 'about/accessibility', component: AccessibilityComponent },
  {path: 'getting-started/ui-kit', component: UiKitComponent },
  {path: 'icon-styles', component: IconStyleComponent },
  {path: 'data-grid', component: GridComponent },
  {path: 'getting-started/design-resources', component: DesignResourcesComponent },
  {path: 'getting-started/ui-framework', component: UIFrameworkComponent },
  {path: 'getting-started/development-resources', component: DevelopmentResourcesComponent },
  {path: 'getting-started/theming', component: ThemingComponent },
  //{path: 'icon-menu', component: IconMenuComponent },
  {path: 'accordion', component: AccordionComponent},
  {path: 'support/roadmap', component: RoadmapComponent},
  {path: 'modal', component: ModalComponent},
  {path: 'spinner', component: SpinnerComponent},
  {path: 'masthead', component: MastheadComponent},
  {path: 'footer', component: FooterComponent},
  {path: 'contextual-menu', component: ContextualMenuComponent},
  {path: 'panel', component: PanelComponent},
  {path: 'progress-indicator', component: ProgressIndicatorComponent},
  {path: 'toast', component: ToastComponent},
  {path: 'chip', component: ChipComponent},
  {path: 'slider', component: SliderComponent},
  {path: 'button-group', component: ButtonGroupComponent},
  {path: 'spin-box', component: SpinBoxComponent},
  {path: 'date-range-picker', component: DateRangePickerComponent},
  {path: 'date-picker', component: DatePickerComponent},
  {path: 'spreadsheet', component: SpreadsheetComponent},
  {path: 'analyzer', component: AnalyzerComponent},
  {path: 'style-guide/tile', component: TileComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
