<div class="kn-spin-box"
    [ngClass]="{
        'vertical-right': layout == 'vertical right',
        'vertical-left': layout == 'vertical left',
        'horizontal': layout == 'horizontal',
        'disabled': disabled,
        'read-only': readOnly,
        'value-right': valueAlignRight,
        'compressed': compressed
    }">
    <div class="kn-spin-box-label" *ngIf="label">{{label}}</div>
    <div class="kn-spin-box-container">
        <div class="kn-spin-box-btn-container" *ngIf="layout != 'horizontal'">
            <div class="kn-spin-box-btn up" 
                (mousedown)="startIncrement('up')" 
                (mouseup)="stopIncrement()"
                [ngClass]="{'disabled': disableUp}"
            >
                <i class="fa-solid fa-sort-up kn-icon-sm"></i>
            </div>
            <div class="kn-spin-box-btn down" 
                (mousedown)="startIncrement('down')" 
                (mouseup)="stopIncrement()"
                [ngClass]="{'disabled': disableDown}"
            >
                <i class="fa-solid fa-sort-down kn-icon-sm"></i>
            </div>
        </div>

        <!--down-->
        <div 
            *ngIf="layout == 'horizontal'" 
            class="kn-spin-box-btn down" 
            (mousedown)="startIncrement('down')" 
            (mouseup)="stopIncrement()"
            [ngClass]="{'disabled': disableDown}"
        >
            <i class="fa-solid fa-caret-left kn-icon-sm"></i>
        </div>

        <kn-textbox
            [name]="name" 
            [textboxId]="spinBoxId" 
            [hideErrorMessage]="true"
            [numericCharsOnly]="true"
            [ariaLabel]="'spin box textbox'"
            [setDisabled]="disabled"
            [customError]="error && !disabled && !readOnly"
            [(ngModel)]="value"
            (onValueChange)="valueChangeEvent($event)"
            [setReadOnly]="readOnly"
            [compressed]="compressed"
        ></kn-textbox>

        <!--up-->
        <div 
            *ngIf="layout == 'horizontal'" 
            class="kn-spin-box-btn up" 
            (mousedown)="startIncrement('up')" 
            (mouseup)="stopIncrement()"
            [ngClass]="{'disabled': disableUp}"
        >
            <i class="fa-solid fa-caret-right kn-icon-sm"></i>
        </div>
    </div>
    <div 
        class="error" 
        *ngIf="error && !disabled && !readOnly"
        [style.left]="spinboxLeftPos" 
        [style.top]="spinboxBottomPos"
        [style.width]="spinboxWidth"
    ><i class="fa-solid fa-triangle-exclamation kn-icon-sm"></i> <span class="error-message">{{errorMessage}}</span></div>
</div>