import { Component, OnInit } from '@angular/core';
import { FontawesomeService } from '../../services/fontawesome.service';
import { ButtonService } from '../../services/button.service';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import  packageInfo from '../../../../../kraken-icon-button/package.json';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.sass']
})
export class IconButtonComponent implements OnInit {
  public buttonId = 'exampleButton'
  public icon = "fa-solid fa-circle-check";
  public btnSelectText = "item 1";
  public btnSelectValue = "0";
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public webClickEvent;
  public webSetProperties;
  public webRemoveProperties;
  public componentId = 'exampleIconButtonComponentId';
  public elementTag = 'kn-icon-button';
  public buttonClicked = false;
  public buttonType = "kn-primary";
  public selectedButton: string;
  public selectedIcon = "Circle - Check (Filled)";
  public buttonTypes;
  public selectedTheme: string;
  public selectedThemeDisplayName: string;
  public iconList: any;
  public btnClickEvent = false;
  public buttonDisabled = false;
  public buttonLoading = false;
  public buttonTypeFields:any = { text: 'name', value: 'type'};
  public buttonIconFields: any = { text: 'title', value: 'className'};
  public event1 = true;
  public addDropShadow = false;
  public disableShadow = false;
  public iconChoice;
  public curentSelectedIcon;
  public initializeWebComponent: any;
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenIconButtonModule'};
  public angularComponentOptions: any = [
    { option: '[buttonId]=', id: 0, value:  '"\'exampleButton\'"'},
    { option: '[buttonType]=', id: 1, value: '"\'' + this.buttonType +'\'"'},
    { option: '[icon]=', id: 2, value: '"\'' + this.icon +'\'"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value:  '"' + this.componentId +'"'},
    { option: 'button-id=', id: 1, value:  '"exampleButton"'},
    { option: 'button-type=', id: 2, value:  '"' + this.buttonType +'"'},
    { option: 'icon=', id: 3, value:  '"' + this.icon +'"'}
  ];

  constructor(public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService,
    public fontawesomeService: FontawesomeService,
    private buttonService: ButtonService) { }

  ngOnInit(): void {
    //set button types
    const specificButtonTypes = [0,1,3,4,5,7,8];
    this.buttonTypes = this.buttonService.buttonTypes(false, specificButtonTypes);
    this.selectedButton = this.buttonTypes[0].name;
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    
    //create icon list
    this.iconList = this.fontawesomeService.iconDataSet();

    this.initializeWebComponent = `let button = document.querySelector('#${this.componentId}');
    `
    this.webClickEvent = 
    `
button.addEventListener('btnClick', (event) => console.log(event.detail));
    `

    this.webSetProperties = 
    `
button.disabled = true;
    `

    this.webRemoveProperties =
    `
button.disabled = false;
    `
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  valueChange(event) {
    this.buttonType = event.type;
    if(event.name == 'Flat') {
      this.addDropShadow = false;
    }
    
    this.disableShadow = event.name == 'Flat' ? true : false;

    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[buttonType]='));
    this.angularComponentOptions[index].value = '"\'' + event.type +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'button-type='));
    this.webComponentOptions[webIndex].value = '"' + event.type + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  selectedItem(event) {
    this.btnSelectText = event.text;
    this.btnSelectValue = event.value;
  }

  buttonIcon(icon) {
    this.selectedIcon = icon.title;
    this.icon = icon.className;
    this.curentSelectedIcon = icon;
    //angular
    const propId = this.angularComponentOptions.findIndex((obj => obj.option == '[icon]='));
    this.angularComponentOptions[propId].option = '[icon]=';
    this.angularComponentOptions[propId].value = '"\'' + icon.className +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webPropId = this.webComponentOptions.findIndex((obj => obj.option == 'icon='));
    this.webComponentOptions[webPropId].option = 'icon=';
    this.webComponentOptions[webPropId].value = '"' + icon.className + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

}
