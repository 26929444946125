<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">The <span class="kn-bold">Date Picker </span> component enables the user to enter a specific date value either through text input or by selecting a date from a graphical calendar interface menu.</div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Date picker with label</span> </div>
            <div class="text"><span class="kn-bold">2.	Date picker without label</span> </div>
        </section>


        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_02.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Enabled:</span> When enabled, the text box displays a default value.</div>
            <div class="text"><span class="kn-bold">2.	Enabled with value</span> </div>
            <div class="text"><span class="kn-bold">3.	Disabled:</span> When a user cannot interact with a component and all interactive functions are removed.</div>
            <div class="text"><span class="kn-bold">4.	Disabled with value</span> </div>
            <div class="text"><span class="kn-bold">5.	Read only:</span> When a user can review but not modify the component. This state removes all interactive functions like the disabled state but the value passes visual contrast for readability.</div>
            <div class="text"><span class="kn-bold">6.	Error:</span> When a user does not provide the required input text. This state requires a user response before data can be submitted or saved.</div>
            <div class="text"><span class="kn-bold">7.	Focused:</span> When a user clicks on the text box, the field becomes focused, which indicates that the user successfully navigated to the component.</div>
            <div class="text"><span class="kn-bold">8.	Focused with value</span> </div>
            <div class="text"><span class="kn-bold">9.	Hover:</span> The calendar picker menu is invoked via the calendar trailing icon button.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_03.png">

        <section>
            <div class="kn-subtitle-16">Text field</div>

            <div class="text">Persists when the calendar picker menu is open or closed.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Container:</span> Containers improve text field discoverability by creating contrast between the text field and surrounding content.</div>
            <div class="text"><span class="kn-bold">B.	Label text:</span> Label text informs users about the information to enter into a text field.</div>
            <div class="text"><span class="kn-bold">C.	Placeholder text:</span> Placeholder text conveys guidance about the input field supported date value configuration. The placeholder text is displayed inside the text field.</div>
            <div class="text"><span class="kn-bold">D.	Trailing icon:</span> Button icon styled with the calendar icon, which opens and expands the calendar picker menu.</div>
            <div class="text"><span class="kn-bold">E.	Value:</span> The input text is text entered by the user. The input text is displayed inside the text field.</div>
            <div class="text"><span class="kn-bold">F.	Clear trailing icon:</span> When selected, the clear icon removes the value of the text field.</div>
            <div class="text"><span class="kn-bold">G.	Error icon:</span> Displaying an error icon in association with text makes error states clear for colorblind users.</div>
            <div class="text"><span class="kn-bold">H.	Error message:</span> When an error occurs, error messages are displayed below the text field.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_04.png">

        <section>
            <div class="kn-subtitle-16">Calendar picker menu</div>

            <div class="text">The menu where users can select a date.</div>
        </section>

      <section>
            <div class="text"><span class="kn-bold">Body </span> </div>
            <div class="text"><span class="kn-bold">A.	Month and year controls:</span> Allows users to navigate through past and future time frames.</div>
            <div class="text"><span class="kn-bold">B.	Previous and next controls:</span> Allows users to move forward or backward one month at a time.</div>
            <div class="text"><span class="kn-bold">C.	Year</span> </div>
            <div class="text"><span class="kn-bold">D.	Month:</span> Months of the year.</div>
            <div class="text"><span class="kn-bold">E.	Week day:</span> Days of the week.</div>
            <div class="text"><span class="kn-bold">F.	Day:</span> Days of the month.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Footer </span> </div>
            <div class="text"><span class="kn-bold">G.	Today button</span></div>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_05.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Label</div>

                <div class="text">The label clearly and succinctly explains the purpose of the date picker and provides contextual guidance and formatting when possible. Labels are always visible and positioned above the container, which is consistent with label positioning of other components in your form.</div>
            </section>

            <section>
                <ul class="bullets">
                    <li>Ensure label text is always uppercase.</li>
                    <li>Keep the label short and concise.</li>
                    <li>Ensure the label is left-aligned above the text box.</li>
                </ul>
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Placeholder text</div>

            <div class="text">All date picker text fields must display placeholder text that indicates the supported date format.</div>
        </section>
        
        <section>
            <div class="text">Ensure placeholder text is not editable and cannot disappear once the user focuses on the text box and begins to type. If the value a user enters is removed and the text box loses input focus, the hint text is restored.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Value</div>

            <div class="text">The date picker value represents the numeric input that a user either manually enters or selects via the calendar picker menu.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_06.png">
        
        <section>
            <div class="kn-subtitle-16">Date formats</div>

            <div class="text">The format of the date value entered in this field can be configured to support one of several different display conventions. Choose the most appropriate format that contextually appropriate for the software application, as well as the geographic location and cultural norms of the intended user audience.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_07.png">

        <section>
            <div class="text"><span class="kn-bold">Supported configurations for date value display include:</span></div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	mm/dd/yyyy</span> = 11/01/2023</div>
            <div class="text"><span class="kn-bold">2.	dd/mm/yyyy</span> = 01/11/2023</div>
            <div class="text"><span class="kn-bold">3.	yyyy/mm/dd</span> = 2023/11/01</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Delimiter symbol</span></div>
            <div class="text">The defined delimiter symbol used for the date value entered is a slash “/”.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_08.png">

        <section>
            <div class="text"><span class="kn-bold">Simple date input</span></div>
            <div class="text">The simple date input provides the user with only a text field in which they can manually input a date. Users can enter dates without adding unnecessary interactions that come with the calendar menu. The simple data input is typically used when a user knows the date and preconfigured.</div>
        </section>


        <section>
            <section>
                <div class="kn-subtitle-16">Calendar picker menu</div>

                <div class="text">The calendar picker menu is triggered by selecting the calendar trailing icon button displayed within the text field. By default, the menu shows today’s date when opened, and only one month is displayed at a time.</div>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_09.png">

        <section>
            <div class="text"><span class="kn-bold">Year</span></div>
            <div class="text"><span class="kn-bold">A.	Year in range</span></div>
            <div class="text"><span class="kn-bold">B.	Hover</span></div>
            <div class="text"><span class="kn-bold">C.	Selected</span></div>
            <div class="text"><span class="kn-bold">D.	Disabled</span></div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_10.png">

        <section>
            <div class="text"><span class="kn-bold">Month</span></div>
            <div class="text"><span class="kn-bold">A.	Month in year</span></div>
            <div class="text"><span class="kn-bold">B.	Hover</span></div>
            <div class="text"><span class="kn-bold">C.	Selected</span></div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_11.png">

        <section>
            <div class="text"><span class="kn-bold">Day</span></div>
            <div class="text"><span class="kn-bold">A.	Today's date</span></div>
            <div class="text"><span class="kn-bold">B.	Day in month</span></div>
            <div class="text"><span class="kn-bold">C.	Selected</span></div>
            <div class="text"><span class="kn-bold">D.	Hover</span></div>
            <div class="text"><span class="kn-bold">E.	Day in next/ previous month</span></div>
            <div class="text"><span class="kn-bold">F.	Disabled:</span> Invalid date selections are disabled. This does not restrict the dates that users enter, so validation is necessary.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_12.png">

        <section>
            <div class="text"><span class="kn-bold">Aligning the calendar menu</span></div>
            <div class="text">The calendar opens as a dropdown menu when the user clicks the calendar icon button located next to its text input field. A calendar opens top-right aligned to its assigned text field. The calendar picker menu remains at a fixed size and is not adjustable.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Minimum and maximum dates</span></div>
            <div class="text">To constrain the possible selectable dates in a calendar, a minimum and maximum date can be set. Once set, only the dates that fall within the min/max range can be selected with the dates outside of the range being disabled. Use minimum and maximum dates to help prevent user error.</div>
        </section>


        <section>
            <div class="kn-subtitle-16">Calendar behaviors</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_13.png">

        <section>
            <div class="text"><span class="kn-bold">Opening the calendar</span></div>
            <div class="text">Open the calendar picker menu by clicking the calendar icon on the far-right side of the text field.</div>
        </section>
        
        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_14.png">

        <section>
            <div class="text"><span class="kn-bold">Selecting a date</span></div>
            <div class="text">Selected data manually entering a date in the text input field or by clicking on a date in the calendar menu.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_15.png">

        <section>
            <div class="text"><span class="kn-bold">Next and previous month</span></div>
            <div class="text">Users can navigate between the months in a year by clicking on the chevron icons at the top-right part of the calendar.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_16.png">

        <section>
            <div class="text"><span class="kn-bold">Selecting a year</span></div>
            <div class="text">The current year is selected by default in the calendar. To navigate to another year, users can click on a year in the calendar menu.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_17.png">

        <section>
            <div class="text"><span class="kn-bold">Closing the calendar</span></div>
            <div class="text">Users can close the calendar in one of the following ways:</div>
        </section>

        <section>
            <ul class="bullets">
                <li>Selecting a date in the calendar menu.</li>
                <li>Clicking on the <span class="kn-bold">Today</span> button.</li>
                <li>Clicking anywhere outside of the calendar menu.</li>
                <li>Pressing the keyboard <span class="kn-bold">Escape</span> button.</li>
            </ul>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_18.png">

        <section>
            <div class="kn-subtitle-16">Error text</div>

            <div class="text">A date picker text field can be marked with  an error to show that a value must be entered to move forward or that an entered value is invalid. An error message disappears once the criteria is met.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_19.png">

        <section>
            <div class="kn-subtitle-16">Size</div>

            <div class="text">Two date pickers sizes are available: default and compressed. The compressed size is reserved for compressed grid rows where users can scan and take action on large amounts of information.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Default date picker:</span> 40px height, 14px text</div>
            <div class="text"><span class="kn-bold">2.	Compressed date picker:</span> 30px height, 12px text</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/date-picker/KrakenDSM_Components_DatePicker_20.png">

        <section>
            <div class="kn-subtitle-16">Display width</div>

            <div class="text">The display width is displayed at a fixed size of <span class="kn-bold">264px</span>.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Rounded corners</div>

            <div class="text">Date picker containers are displayed with a 4px rounded corner. </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Unclear date format</div>

            <div class="text">Avoid ambiguity in date formats. Clearly define and communicate the format that users must use when inputting dates.</div>
        </section>
    </div>
</div>