<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Browser Support</div>
        <section>
            <div class="text">Components produced for the <span class="kn-bold">Kraken DS</span> are developed to be supported by the following contemporary web browsers:</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/about/browser support/KrakenDSM_About_GoogleChrome_01.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Google Chrome</span> <a class="kn-link" href="https://www.google.com/chrome/" target="_blank"> v106.0+ </a></div>
        </section>
        
        <section>
            <img class="kn-responsive-img" src="assets/about/browser support/KrakenDSM_About_MicrosoftEdge_01.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Microsoft Edge</span> <a class="kn-link" href="https://www.microsoft.com/en-us/edge" target="_blank"> v106.0+ </a></div>
        </section>   

        <section>
            <div class="text">These browser support specifications are derived from current usage analytics data reported via the <a class="kn-link" href="https://www.pendo.io/" target="_blank"> Pendo </a> platform (https://www.pendo.io/) implemented for the Lighthouse Spectra application. Spectra represents the company’s most prolific customer-facing SaaS application and is an accurate indicator of the browsing environment employed by our current users.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Current browser trends</span></div>
            <div class="text">(per Pendo 90-day reporting for Spectra run January 2023)</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Operating system usage:</span></div>
            <ul>
                <li>Windows = 91.9%</li>
                <li>Max OS X = 6.6%  (not supported)</li>
                <li>iOS = 1.3%  (not supported)</li>
                <li>Android = 0.1%  (not supported)</li>
                <li>Chrome OS = 0.1%  (not supported)</li>
            </ul>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Browser usage:</span></div>
            <ul>
                <li>Chrome = 60%</li>
                <ul>
                    <li>v106.0 = 17.1%</li>
                    <li>v017.0 = 20.3%</li>
                    <li>v108.0 = 18.4%</li>
                </ul>
             
                
                <li>Edge = 35.3%</li>
                <ul>
                    <li>v106.0 = 9.8%</li>
                    <li>v107.0 = 13.5%</li>
                    <li>v108.0 = 10%</li>
                </ul>
                

                <li>Firefox = 2.7%  (not supported)</li>
                <li>Safari = 2.0%  (not supported)</li>
            </ul>
        </section>
    </div>
</div>