<app-canvas 
    [ts]="onValidate" 
    [js]="true" 
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [reactUrl]="'https://stackblitz.com/edit/github-rqclvg-zjglju?embed=1&file=src%2FApp.js'">
    <div title>Text Box <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-textbox 
            [name]="'textboxName'" 
            [textboxId]="'textboxExample'" 
            [label]="label"
            [type]="inputType"
            [setDisabled]="disabled"
            [emailValidator]="emailValidator"
            [customError]="customError"
            [customWarning]="customWarning"
            [customMess]="customMess"
            [(ngModel)]="exampleText"
            [setRequired]="inputRequired"
            (onValueChange)="valueChange($event)"
            (onValidate)="textboxName = $event"
            [setReadOnly]="readOnly"
            [setPlaceholder]="placeholder"
            [clearIcon]="clearIcon"
            [showCharCount]="showCounter"
            [maxChars]="maxChars"
            [optional]="optionalLabel"
            [compressed]="compressed"
            [password]="password"
            [hideErrorMessage]="hideErrorMessage"
            >
        </kn-textbox>
    </div>
    <div angular>
        <br/>
        <span *ngIf="customDirective" class="code-comment">&lt;!--Custom directives can be added directly on the component as shown in the example below.<br/>
        If you want a custom error or warning message to bind with your directive, you can add the following:<br/>
        [customError]="yourInputName.invalid" [customMess]="yourInputName.errors?.errorMsg"--><br/><br/></span>
        
        <span *ngIf="onValidate">
            <span class="code-comment">&lt;!--You can bind directly to the validation event like this--></span>
            <span class="html-option">(onValidate)=</span>"textboxName = $event"
            <br/><br/>
            <span class="code-comment">&lt;!--This example will disabled the submit button when the textarea is invalid. Notice the [disabled] property has the validation check. You can set it in code like the example below --></span>
            <span class="html-tags">&lt;kn-button </span> <span class="html-option">[buttonId]=</span>"'exampleButton'" <span class="html-option">[buttonType]=</span>"'kn-primary'" <span class="html-option">[buttonText]=</span>"'Submit'" <span class="html-option">[disabled]=</span>"textboxName?.invalid" <span class="html-tags">&gt;</span><span class="html-tags">&lt;/kn-button&gt;</span>
        </span>
    </div>
    <div web>{{webComponentRef}}</div>
    <div javascript>
        <span class="code-comment">//Initialize the component</span>
        {{initializeComponentWeb}}
        <span class="code-comment">//Value change event</span>
        {{webClickEvent}}
        <span class="code-comment">//All properties with a boolean value can be set in code like this</span>
        {{webSetProperties}}
        <span class="code-comment">//Remove a boolean property like this</span>
        {{webRemoveProperties}}
    </div>
    <div typeScript>
        <span>{{validationTS}}</span>
    </div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onValueChange():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">{{exampleText}}</span></div>
                </div>
            </div>
            <div class="event-item" [ngClass]="{'active': event2}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event2 = !event2"></i> 
                <div class="obj">onClear():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">{{exampleText}}</span></div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="action-row">
            <kn-textbox [label]="'Textbox label'" [setRequired]="true" [value]="label" (onValueChange)="labelText($event, 1)" [clearIcon]="true" [setDisabled]="compressed"></kn-textbox>
            <div class="kn-spacer"></div>
            <kn-checkbox 
                (onSelect)="controlEvent($event.value, '[optional]=', true, true); optionalLabel = !optionalLabel" 
                [label]="'Optional field'" 
                [disabled]="compressed || label == '' || inputRequired">
            </kn-checkbox>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle [label]="'Placeholder'" (value)="showHidePlaceholder($event)"></kn-toggle>
        </div>
        <div class="action-input" *ngIf="showPlaceholder">
            <kn-textbox [label]="'Placeholder text'" [value]="Placeholder" (onValueChange)="placeholderValue($event)" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle [label]="'Add clear all icon'" [(ngModel)]="clearIcon" (value)="controlEvent($event, '[clearIcon]=', true, true);"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Character counter'" 
                (value)="controlEvent($event, '[showCharCount]=', true, true); controlEvent($event, '[maxChars]=', 0, true); showCharCount($event)"
                [disabled]="readOnly || disabled">
            </kn-toggle>
        </div>
        <div class="action-input" *ngIf="showCounter">
            <kn-textbox [label]="'Enter max characters'"  [type]="'number'" [minNumber]="0" [value]="maxChars" (onValueChange)="charCount($event)" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle label="Password input"  (value)="controlEvent($event, '[password]=', true, true); passwordInput($event); password = !password" [disabled]="emailValidator"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Read only" [(ngModel)]="readOnly" (value)="controlEvent($event, '[setReadOnly]=', 'true', true);" [disabled]="disabled || setCustomMessage || inputRequired"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled state" [(ngModel)]="disabled" (value)="controlEvent($event, '[setDisabled]=', 'true', true);" [disabled]="setCustomMessage || readOnly || inputRequired"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Compressed textbox"  (value)="controlEvent($event, '[compressed]=', 'true', true); compressed = !compressed"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Required validation'" 
                [(ngModel)]="inputRequired"
                (value)="controlEvent($event, '[setRequired]=', true, true);customMessage($event);" 
                [disabled]="setCustomMessage || readOnly || disabled || optionalLabel">
            </kn-toggle>

            <div *ngIf="inputRequired" class="action-input">
                <kn-textbox 
                    [label]="'Custom message'" 
                    [value]="customMess" 
                    [setPlaceholder]="'Provide a custom message'"
                    (onValueChange)="message($event)" 
                    [clearIcon]="true">
                </kn-textbox>
            </div>
        </div>
        <div class="item-row">
            <kn-toggle label="Email validation" (value)="controlEvent($event, '[emailValidator]=', 'true', 'bool'); customMessage($event); emailValidator = !emailValidator" [disabled]="password || addCustomMessage"></kn-toggle>
            <div *ngIf="emailValidator" class="action-input">
                <kn-textbox 
                    [label]="'Custom message'" 
                    [value]="customMess" 
                    [setPlaceholder]="'Provide a custom message'"
                    (onValueChange)="message($event)" 
                    [clearIcon]="true">
                </kn-textbox>
            </div>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Error / warning state'" 
                (value)="customMessToggle($event); setCustomMessage = !setCustomMessage" 
                [disabled]="inputRequired || readOnly || disabled">
            </kn-toggle>
        </div>
        <div class="item-row" *ngIf="addCustomMessage">
            <kn-radio-button [id]="'errorRadio'" [defaultValue]="messageType" [(ngModel)]="messageType" [value]="'radioError'" [name]="'custom-message'" [label]="'Error'" (onSelect)="customMessage($event);" [disabled]="customError"></kn-radio-button>
        </div>
        <div class="item-row" *ngIf="addCustomMessage">
            <kn-radio-button [id]="'warningRadio'" [defaultValue]="messageType" [(ngModel)]="messageType" [value]="'radioWarning'" [name]="'custom-message'" [label]="'Warning'" (onSelect)="customMessage($event)" [disabled]="customWarning"></kn-radio-button>
        </div>
        <div *ngIf="addCustomMessage" class="action-input">
            <kn-textbox 
                [label]="'Custom message'" 
                [value]="customMess" 
                [setPlaceholder]="'Provide a custom message'"
                [setDisabled]="!addCustomMessage && !inputRequired && !emailValidator || messageType == ''" 
                (onValueChange)="message($event)" 
                [clearIcon]="true">
            </kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Hide error message'" 
                (value)="controlEvent($event, '[hideErrorMessage]=', 'true', true); hideErrorMessage = !hideErrorMessage" >
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Custom directive example'" 
                [(ngModel)]="customDirective"
                (value)="controlEvent($event, 'yourCustomDirective','--', false);controlEvent($event, '#yourInputName=', 'ngModel', false);"
                [disabled]="inputRequired || readOnly || disabled">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Value change event"  (value)="controlEvent($event, '(onValueChange)=', 'valueChange($event)', false); onValueChange = !onValueChange"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Clear icon event"  (value)="controlEvent($event, '(onClear)=', 'clearEvent($event)', false); onClear = !onClear"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Validation event"  (value)="controlEvent($event, '(onValidate)=', 'validate($event)', false); onValidate = !onValidate"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">textboxId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"textboxExample"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">name</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"textboxName"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">label</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"textbox example"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">emailValidator</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">clearIcon</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">optional</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">maxLength</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>20</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">minLength</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>20</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">compressed</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">password</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">showCharCount</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">maxChars</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>50</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">numericCharsOnly</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setDisabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setRequired</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setReadOnly</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setPlaceholder</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"Add text here"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customError</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customWarning</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">hideErrorMessage</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customMess</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"invalid text"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onValueChange</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onClear</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">Directive</div>
                <div class="item">[(ngModel)]</div>
                <div class="item"><i>two-way binding</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <text-box-usage></text-box-usage>
    </div>
    <div designSpec>
        <text-box-specs></text-box-specs>
    </div>
    <div accessibility>
        <text-box-accessibility></text-box-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">March 14, 2024</div>
                <div class="item">1.3.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed function that calculates the positioning of the error container</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Feburary 9, 2024</div>
                <div class="item">1.3.0</div>
                <div class="item">
                    <ul>
                        <li>updated the numbersOnly regex to include "." and "-" chars</li>
                        <li>bug fix - fixed issue with the error message styling when going to a second line</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">December 6, 2023</div>
                <div class="item">1.2.2</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed issue with the hideErrorMessage property not hiding the message</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">November 14, 2023</div>
                <div class="item">1.2.1</div>
                <div class="item">
                    <ul>
                        <li>added a property to allow you to hide the error/warning message if needed. This will still display a red outline around the textbox</li>
                        <li>added (directive) property to only allow numberic values</li>
                        <li>added a property for aria-label</li>
                        <li>(Web version) we changed the web component tag from kn-textbox to kn-web-textbox, this was done to avoid conflicts with components at use built in kn-textbox components</li>
                        <li>added a clear icon event. You can now bind to an event when the user clicks the clear icon within the textbox.</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">October 6, 2023</div>
                <div class="item">1.1.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed min / max number length if no values are set</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.1.0</div>
                <div class="item">
                    <ul>
                        <li>added "min" and "max" attributes for when type = "number"</li>
                        <li>bug fix - fixed the clear button functionality, it will now update the model as well as clear the textbox</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">June 15, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - set a height for the validation section to avoid pushing other elements around</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the textbox component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>