import { Component, OnInit, ViewChild } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import  packageInfo from '../../../../../kraken-theme/package.json';
import { 
  SpreadsheetComponent as SpreadSheet, 
  BeforeCellUpdateArgs, 
  BeforePasteEventArgs, 
  CellRenderEventArgs, 
  CellSaveEventArgs, 
  ConditionalFormatEventArgs, 
  getCellIndexes, 
  getRangeAddress, 
  getFormatFromType
} from '@syncfusion/ej2-angular-spreadsheet';
import { SheetModel, UsedRangeModel } from '@syncfusion/ej2-spreadsheet';

@Component({
  selector: 'app-spreadsheet',
  templateUrl: './spreadsheet.component.html',
  styleUrls: ['./spreadsheet.component.sass']
})
export class SpreadsheetComponent implements OnInit {
  @ViewChild('spreadsheet')
  spreadsheetObj: SpreadSheet;
  public currencyFormat: string = getFormatFromType('Currency');

  public angularComponentRef: any;
  public elementTag = 'div';
  public webComponentRef;
  public angularContainer;
  public react = false;
  public language = 'angular';
  public rowCount = 0;
  public template;
  public hideLegend = false;

  public errorBgColor;
  public invalidBgColor;
  public invalidEmailBgColor;
  public fontColor;
  public requiredErrors = 0;
  public duplicateErrors = 0;
  public invalidErrors = 0;
  public invalidEmailErrors = 0;
  public firstNameColumnIndex = 0;
  public lastNameColumnIndex = 1;
  public emailColumnIndex = 2;
  public roleColumnIndex = 3;
  public startDateColumnIndex = 4;
  public firstNameColumnRange: string;
  public lastNameColumnRange: string;
  public emailColumnRange: string;
  public roleColumnRange: string;
  public startDateColumnRange: string;
  public firstRowIdx: number = 0;
  public lastRowIdx: number;
  public columnAIdx: number = 0;
  public columnBIdx: number = 1;
  public columnCIdx: number = 2;
  public columnDIdx: number = 3;
  public regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  public patterns = [
    // Basic SQL injection techniques
    /'/, // Single quote
    /--/, // SQL line comment
    /;/, // Multiple query separator
    /or\s+\d+=\d+/i, // OR condition that is always true
    /union\s+select/i, // UNION SELECT query
    /insert\s+into/i, // INSERT INTO statement
    /select\s+.*\s+from/i, // SELECT FROM statement
    /delete\s+from/i, // DELETE FROM statement
    /update\s+.*\s+set/i, // UPDATE SET statement
    /exec(\s|\()/i, // EXEC statement
    /xp_cmdshell/i, // Command shell execution
    /drop\s+table/i, // DROP TABLE statement
    /create\s+table/i, // CREATE TABLE statement
  ];

  public component = {
    name: 'spreadsheet', 
    version: packageInfo.version,
    install: false,
    peerDependencies: [
      {"@kraken-ui/kraken-theme": packageInfo.version}
    ], 
    module: '',
    installWarning: `This is a third party component provided by Syncfusion, please click <a class="kn-link" href="https://ej2.syncfusion.com/angular/documentation/spreadsheet/getting-started" target="_blank">here</a> for installation instructions`
  };

  public angularComponentOptions: any = [
    { option: 'class=', id: 0, value: '"kn-spreadsheet"'},
  ];

  public imports = 
  `import { Component, OnInit, ViewChild } from '@angular/core';
import { 
  SpreadsheetComponent as SpreadSheet, 
  BeforeCellUpdateArgs, 
  BeforePasteEventArgs, 
  CellRenderEventArgs, 
  CellSaveEventArgs, 
  ConditionalFormatEventArgs, 
  getCellIndexes, 
  getRangeAddress, 
  getFormatFromType
} from '@syncfusion/ej2-angular-spreadsheet';
import { SheetModel, UsedRangeModel } from '@syncfusion/ej2-spreadsheet';`

  public declareVars =
  `public errorBgColor;
public invalidBgColor;
public invalidEmailBgColor;
public fontColor;
public requiredErrors = 0;
public duplicateErrors = 0;
public invalidErrors = 0;
public invalidEmailErrors = 0;
public firstNameColumnIndex = 0;
public lastNameColumnIndex = 1;
public emailColumnIndex = 2;
public roleColumnIndex = 3;
public startDateColumnIndex = 4;
public firstNameColumnRange: string;
public lastNameColumnRange: string;
public emailColumnRange: string;
public roleColumnRange: string;
public startDateColumnRange: string;
public firstRowIdx: number = 0;
public lastRowIdx: number;
public columnAIdx: number = 0;
public columnBIdx: number = 1;
public columnCIdx: number = 2;
public columnDIdx: number = 3;
public regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
public patterns = [
  // Basic SQL injection techniques
  /'/, // Single quote
  /--/, // SQL line comment
  /;/, // Multiple query separator
  /or\s+\d+=\d+/i, // OR condition that is always true
  /union\s+select/i, // UNION SELECT query
  /insert\s+into/i, // INSERT INTO statement
  /select\s+.*\s+from/i, // SELECT FROM statement
  /delete\s+from/i, // DELETE FROM statement
  /update\s+.*\s+set/i, // UPDATE SET statement
  /exec(\s|\()/i, // EXEC statement
  /xp_cmdshell/i, // Command shell execution
  /drop\s+table/i, // DROP TABLE statement
  /create\s+table/i, // CREATE TABLE statement
];

@ViewChild('spreadsheet')
spreadsheetObj: SpreadSheet;`

  public data: Object[] = 
  [ 
    {
      'FIRST NAME': '',
      'LAST NAME': 'Doe',
      'EMAIL': '',
      'ROLE': 'Manager',
      'START DATE': '02/21/2015'
    },
    {
      'FIRST NAME': 'Tom',
      'LAST NAME': 'Jones',
      'EMAIL': 'tomjones@lighthouse.com',
      'ROLE': 'Agent',
      'START DATE': '10/15/2018'
    },
    {
      'FIRST NAME': 'Jennifer',
      'LAST NAME': '',
      'EMAIL': 'jennifierthompson@lighthouse.com',
      'ROLE': 'Manager',
      'START DATE': '03/05/2022'
    },
    {
      'FIRST NAME': 'Rick',
      'LAST NAME': 'James',
      'EMAIL': 'rickjames@lighthouse',
      'ROLE': 'Agent',
      'START DATE': '04/22/2020'
    },
    {
      'FIRST NAME': '',
      'LAST NAME': 'Smith',
      'EMAIL': 'johnsmith@lighthouse.com',
      'ROLE': 'SELECT * FROM users WHERE user_id = \'1\' OR \'1\'=\'1\'',
      'START DATE': '03/23/2016'
    },
    {
      'FIRST NAME': 'John',
      'LAST NAME': 'Smith',
      'EMAIL': 'johnsmith@lighthouse.com',
      'ROLE': 'Manager',
      'START DATE': '03/23/2016'
    }
  ];


  public createdFunction = 
  `created() {
  this.spreadsheetObj.cellFormat({ fontWeight: 'bold', textAlign: 'left' }, 'A1:E1');
  this.rowCountEvent();
  this.getRowIndexes();
}`

  public cellValidationFunction =
  `cellSave(args: CellSaveEventArgs | BeforePasteEventArgs) {
  if ((args as BeforePasteEventArgs).requestType === 'paste') {
    this.getRowIndexes();
  }
  this.rowCountEvent();
}`

  public actionCompleteFunction = 
  `actionComplete(args: any) {
  if (args.action === 'cellSave') {
    let cellIndexes: number[] = getCellIndexes(args.eventArgs.address.split('!')[1]);
    if (cellIndexes[1] === 0 || cellIndexes[1] === 1 || cellIndexes[1] === 2) {
      this.spreadsheetObj.renderModule.refreshSheet();
    }
    this.getRowIndexes();
  }
  else if (args.action === 'cellDelete' || (args.action === 'clipboard' && args.eventArgs.type === 'paste')) {
    this.spreadsheetObj.renderModule.refreshSheet();
  } else if(args.action === 'delete') {
    this.getRowIndexes();
  } else if(args.action === 'insert') {
    this.getRowIndexes();
  }
  this.rowCountEvent();
}`

  public rowCountFunction = 
  `rowCountEvent() {
  let sheet: SheetModel = this.spreadsheetObj.getActiveSheet();
  let rows: UsedRangeModel = sheet.usedRange;
  this.rowCount = rows.rowIndex + 1;
}
`
public getValidationErrorCountCode = 
`getValidationErrorCount(range: string, isColumnAandB, isEmailColumn, isDColumn) {
  let requiredCount: number = 0;
  let invalidEmail: number = 0;
  let invalidCharCount: number = 0;
  let mailCollections: string[] = [];
  let duplicateEmailColl: string[] = [];

  this.spreadsheetObj.getData(range).then((value) => {
    value.forEach((cell, key) => {
      //required
      if (!cell.value && isColumnAandB) {
        requiredCount++;
      }
      //invalid email check
      if (!this.regex.test(cell.value) && isEmailColumn && key != 'C1') {
        invalidEmail++;
      }
      //invalid char count
      for (let pattern of this.patterns) {
        if (pattern.test(cell.value) && isDColumn) {
          invalidCharCount++;
          console.log(cell.value)
          return true;
        }
      }
      if (isEmailColumn) {
        if (cell.value && mailCollections.indexOf(cell.value) > -1) {
          if (duplicateEmailColl.indexOf(cell.value) < 0) {
            duplicateEmailColl.push(cell.value);
          }
        } else if (cell.value) {
          mailCollections.push(cell.value);
        }
      }
    });
    if (isColumnAandB) {
      console.log("Required: " + requiredCount);
      this.requiredErrors = requiredCount;
    } else if (isEmailColumn) {
      console.log("Invalid email: " + invalidEmail);
      console.log("duplicateEmail: " + duplicateEmailColl.length);
      this.invalidEmailErrors = invalidEmail;
      this.duplicateErrors = duplicateEmailColl.length;
    } else if (isDColumn) {
      console.log("Invalid character: " + invalidCharCount);
      this.invalidErrors = invalidCharCount;
    }
  });
}`

public beforeCellRenderCode = 
`beforeCellRender(args: CellRenderEventArgs) {
  if (args.rowIndex !== 0) {
    let lastRowIdx: number = this.spreadsheetObj.getActiveSheet().usedRange.rowIndex;

    //Column A or B
    if ((args.colIndex === 0 || args.colIndex === 1) && args.rowIndex <= lastRowIdx && args.cell) {
      this.requiredValidation(args.cell.value, args.element);
    }

    //Coulmn C
    if (((args.colIndex === 2) && args.rowIndex <= this.spreadsheetObj.getActiveSheet().usedRange.rowIndex && args.cell)) {
      this.emailValidation(args.cell.value, args.element);
    }

    //Coumn D
    if (((args.colIndex === 3) && args.rowIndex <= this.spreadsheetObj.getActiveSheet().usedRange.rowIndex && args.cell)) {
      this.invalidCharacters(args.cell.value, args.element);
    }
  }
}`

public requiredValCode = 
`requiredValidation(value: string, element: HTMLElement) {
  if (!value) {
    element.style.backgroundColor = this.errorBgColor;
    element.style.fontWeight = 'bold';
  }
}`

public emailValCode =
`emailValidation(value, element) {
  let isValid = this.regex.test(value);
  if (!isValid) {
    element.style.backgroundColor = this.invalidEmailBgColor;
    element.style.fontWeight = 'bold';
  }
}`

public invalidCharCode =
`invalidCharacters(value, element) {
  for (let pattern of this.patterns) {
    if (pattern.test(value)) {
      //highlight cell
      element.style.backgroundColor = this.invalidBgColor;
      element.style.fontWeight = 'bold';
    }
  }
}`

public beforeConditionalFormatCode = 
` beforeConditionalFormat(args: ConditionalFormatEventArgs) {
  let currentCell: string = this.spreadsheetObj.getActiveSheet().activeCell;
  if (args.address === currentCell && args.apply) {
    //If the entered value is a duplicate, you can handle it here.
  }
}`

public beforeCellUpdateCode = 
`beforeCellUpdate(args: BeforeCellUpdateArgs) {
  if (args.rowIndex !== this.firstRowIdx) {
    this.lastRowIdx = this.spreadsheetObj.getActiveSheet().usedRange.rowIndex;
    if (args.colIndex === this.columnAIdx || args.colIndex === this.columnBIdx) {
      let columnAAndBRange: string = getRangeAddress([this.firstRowIdx, this.columnAIdx, this.lastRowIdx, this.columnBIdx]);
      this.getValidationErrorCount(columnAAndBRange, true, false, false);
    }
    if (args.colIndex === this.columnCIdx) {
      let emailColumnRange: string = getRangeAddress([this.firstRowIdx, this.columnCIdx, this.lastRowIdx, this.columnCIdx]);
      this.getValidationErrorCount(emailColumnRange, false, true, false);
    }
    if (args.colIndex === this.columnDIdx) {
      let columnDRange: string = getRangeAddress([this.firstRowIdx, this.columnDIdx, this.lastRowIdx, this.columnDIdx]);
      this.getValidationErrorCount(columnDRange, false, false, true);
    }
  }
}`

public rowIndexCode = 
`getRowIndexes(rowIndex?) {
  this.lastRowIdx = this.spreadsheetObj.getActiveSheet().usedRange.rowIndex;
  let columnAAndBRange: string = getRangeAddress([this.firstRowIdx, this.columnAIdx, this.lastRowIdx, this.columnBIdx]);
  let emailColumnRange: string = getRangeAddress([this.firstRowIdx, this.columnCIdx, this.lastRowIdx, this.columnCIdx]);
  let columnDRange: string = getRangeAddress([this.firstRowIdx, this.columnDIdx, this.lastRowIdx, this.columnDIdx]);
  let rangeCollection: string[] = [columnAAndBRange, emailColumnRange, columnDRange];
  for (let idx: number = 0; idx < rangeCollection.length; idx++) {
    this.getValidationErrorCount(rangeCollection[idx], idx === 0, idx === 1, idx === 2);
  };

  //duplicate validation on the email column
  this.spreadsheetObj.conditionalFormat({ type: "Duplicate", cFColor: "YellowFT", range: emailColumnRange});
}`

public sreadsheetData = `
public data: Object[] = 
[ 
  {
    'FIRST NAME': '',
    'LAST NAME': 'Doe',
    'EMAIL': '',
    'ROLE': 'Manager',
    'START DATE': '02/21/2015'
  },
  {
    'FIRST NAME': 'Tom',
    'LAST NAME': 'Jones',
    'EMAIL': 'tomjones@lighthouse.com',
    'ROLE': 'Agent',
    'START DATE': '10/15/2018'
  },
  {
    'FIRST NAME': 'Jennifer',
    'LAST NAME': '',
    'EMAIL': 'jennifierthompson@lighthouse.com',
    'ROLE': 'Manager',
    'START DATE': '03/05/2022'
  },
  {
    'FIRST NAME': 'Rick',
    'LAST NAME': 'James',
    'EMAIL': 'rickjames@lighthouse',
    'ROLE': 'Agent',
    'START DATE': '04/22/2020'
  },
  {
    'FIRST NAME': '',
    'LAST NAME': 'Smith',
    'EMAIL': 'johnsmith@lighthouse.com',
    'ROLE': 'SELECT * FROM users WHERE user_id = \'1\' OR \'1\'=\'1\'',
    'START DATE': '03/23/2016'
  },
  {
    'FIRST NAME': 'John',
    'LAST NAME': 'Smith',
    'EMAIL': 'johnsmith@lighthouse.com',
    'ROLE': 'Manager',
    'START DATE': '03/23/2016'
  }
];`

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.errorBgColor = getComputedStyle(document.documentElement).getPropertyValue('--kn-danger-light').trim();
    this.invalidBgColor = getComputedStyle(document.documentElement).getPropertyValue('--yellow-light').trim();
    this.invalidEmailBgColor = getComputedStyle(document.documentElement).getPropertyValue('--indigo-light').trim();
    this.fontColor = getComputedStyle(document.documentElement).getPropertyValue('--ink').trim();

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateEvent());

    //web template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

    // document.addEventListener('mousedown', () => {
    //   console.log('click')
    // })
  }

  created() {
    this.spreadsheetObj.cellFormat({ fontWeight: 'bold', textAlign: 'left' }, 'A1:E1');
    this.rowCountEvent();
    this.getRowIndexes();
  }

  getValidationErrorCount(range: string, isColumnAandB, isEmailColumn, isDColumn) {
    let requiredCount: number = 0;
    let invalidEmail: number = 0;
    let invalidCharCount: number = 0;
    let mailCollections: string[] = [];
    let duplicateEmailColl: string[] = [];

    this.spreadsheetObj.getData(range).then((value) => {
      value.forEach((cell, key) => {
        //required
        if (!cell.value && isColumnAandB) {
          requiredCount++;
        }
        //invalid email check
        if (!this.regex.test(cell.value) && isEmailColumn && key != 'C1') {
          invalidEmail++;
        }
        //invalid char count
        for (let pattern of this.patterns) {
          if (pattern.test(cell.value) && isDColumn) {
            invalidCharCount++;
            console.log(cell.value)
            return true;
          }
        }
        if (isEmailColumn) {
          if (cell.value && mailCollections.indexOf(cell.value) > -1) {
            if (duplicateEmailColl.indexOf(cell.value) < 0) {
              duplicateEmailColl.push(cell.value);
            }
          } else if (cell.value) {
            mailCollections.push(cell.value);
          }
        }
      });
      if (isColumnAandB) {
        console.log("Required: " + requiredCount);
        this.requiredErrors = requiredCount;
      } else if (isEmailColumn) {
        console.log("Invalid email: " + invalidEmail);
        console.log("duplicateEmail: " + duplicateEmailColl.length);
        this.invalidEmailErrors = invalidEmail;
        this.duplicateErrors = duplicateEmailColl.length;
      } else if (isDColumn) {
        console.log("Invalid character: " + invalidCharCount);
        this.invalidErrors = invalidCharCount;
      }
    });
  }

  beforeCellRender(args: CellRenderEventArgs) {
    if (args.rowIndex !== 0) {
      let lastRowIdx: number = this.spreadsheetObj.getActiveSheet().usedRange.rowIndex;

      //Column A or B
      if ((args.colIndex === 0 || args.colIndex === 1) && args.rowIndex <= lastRowIdx && args.cell) {
        this.requiredValidation(args.cell.value, args.element);
      }

      //Coulmn C
      if (((args.colIndex === 2) && args.rowIndex <= this.spreadsheetObj.getActiveSheet().usedRange.rowIndex && args.cell)) {
        this.emailValidation(args.cell.value, args.element);
      }

      //Coumn D
      if (((args.colIndex === 3) && args.rowIndex <= this.spreadsheetObj.getActiveSheet().usedRange.rowIndex && args.cell)) {
        this.invalidCharacters(args.cell.value, args.element);
      }
    }
  }

  requiredValidation(value: string, element: HTMLElement) {
    if (!value) {
      element.style.backgroundColor = this.errorBgColor;
      element.style.fontWeight = 'bold';
    }
  }

  emailValidation(value, element) {
    let isValid = this.regex.test(value);
    if (!isValid) {
      element.style.backgroundColor = this.invalidEmailBgColor;
      element.style.fontWeight = 'bold';
    }
  }

  invalidCharacters(value, element) {
    for (let pattern of this.patterns) {
      if (pattern.test(value)) {
        //highlight cell
        element.style.backgroundColor = this.invalidBgColor;
        element.style.fontWeight = 'bold';
      }
    }
  }

  beforeCellUpdate(args: BeforeCellUpdateArgs) {
    if (args.rowIndex !== this.firstRowIdx) {
      this.lastRowIdx = this.spreadsheetObj.getActiveSheet().usedRange.rowIndex;
      if (args.colIndex === this.columnAIdx || args.colIndex === this.columnBIdx) {
        let columnAAndBRange: string = getRangeAddress([this.firstRowIdx, this.columnAIdx, this.lastRowIdx, this.columnBIdx]);
        this.getValidationErrorCount(columnAAndBRange, true, false, false);
      }
      if (args.colIndex === this.columnCIdx) {
        let emailColumnRange: string = getRangeAddress([this.firstRowIdx, this.columnCIdx, this.lastRowIdx, this.columnCIdx]);
        this.getValidationErrorCount(emailColumnRange, false, true, false);
      }
      if (args.colIndex === this.columnDIdx) {
        let columnDRange: string = getRangeAddress([this.firstRowIdx, this.columnDIdx, this.lastRowIdx, this.columnDIdx]);
        this.getValidationErrorCount(columnDRange, false, false, true);
      }
    }
  }

  cellSave(args: CellSaveEventArgs | BeforePasteEventArgs) {
    if ((args as BeforePasteEventArgs).requestType === 'paste') {
      this.getRowIndexes();
    }
    this.rowCountEvent();
  }

  actionComplete(args: any) {
    if (args.action === 'cellSave') {
      let cellIndexes: number[] = getCellIndexes(args.eventArgs.address.split('!')[1]);
      if (cellIndexes[1] === 0 || cellIndexes[1] === 1 || cellIndexes[1] === 2) {
        this.spreadsheetObj.renderModule.refreshSheet();
      }
      this.getRowIndexes();
    }
    else if (args.action === 'cellDelete' || (args.action === 'clipboard' && args.eventArgs.type === 'paste')) {
      this.spreadsheetObj.renderModule.refreshSheet();
    } else if(args.action === 'delete') {
      this.getRowIndexes();
    } else if(args.action === 'insert') {
      this.getRowIndexes();
    }
    this.rowCountEvent();
  }

  rowCountEvent() {
    let sheet: SheetModel = this.spreadsheetObj.getActiveSheet();
    let rows: UsedRangeModel = sheet.usedRange;
    this.rowCount = rows.rowIndex;
  }

  getRowIndexes(rowIndex?) {
    this.lastRowIdx = this.spreadsheetObj.getActiveSheet().usedRange.rowIndex;
    let columnAAndBRange: string = getRangeAddress([this.firstRowIdx, this.columnAIdx, this.lastRowIdx, this.columnBIdx]);
    let emailColumnRange: string = getRangeAddress([this.firstRowIdx, this.columnCIdx, this.lastRowIdx, this.columnCIdx]);
    let columnDRange: string = getRangeAddress([this.firstRowIdx, this.columnDIdx, this.lastRowIdx, this.columnDIdx]);
    let rangeCollection: string[] = [columnAAndBRange, emailColumnRange, columnDRange];
    for (let idx: number = 0; idx < rangeCollection.length; idx++) {
      this.getValidationErrorCount(rangeCollection[idx], idx === 0, idx === 1, idx === 2);
    };

    //duplicate validation on the email column
    this.spreadsheetObj.conditionalFormat({ type: "Duplicate", cFColor: "YellowFT", range: emailColumnRange});
  }

  templateEvent() {
    let legend;
    if(this.hideLegend) {
      legend = ``;
    } else {
      legend = `
    <div class="kn-legend">
      <div class="swatch-container error">
        <div class="swatch"></div>
        <div class="text">Required <span class="validation-count" >({{requiredErrors}})</span></div>
      </div>
      <div class="swatch-container duplicate">
          <div class="swatch"></div>
          <div class="text">Has duplicates <span class="validation-count" >({{duplicateErrors}})</span></div>
      </div>
      <div class="swatch-container invalid">
          <div class="swatch"></div>
          <div class="text">Invalid character <span class="validation-count" >({{invalidErrors}})</span></div>
      </div>
      <div class="swatch-container validation-4">
          <div class="swatch"></div>
          <div class="text">Invalid email format <span class="validation-count" >({{invalidEmailErrors}})</span></div>
      </div>
    </div>`
    }
    this.template = 
`<div class="kn-spreadsheet-header">
    <div class="kn-spreadsheet-row-count">
        <div class="kn-label">Rows of data: </div>
        <div class="value">{{rowCount}}</div>
    </div>${legend}
</div>
<ejs-spreadsheet
  #spreadsheet
  (created)="created()"
  (beforeCellRender)="beforeCellRender($event)"
  [showFormulaBar]="false"
  [showRibbon]="false"
  (cellSave)="cellSave($event)"
  (beforeCellUpdate)="beforeCellUpdate($event)"
  (actionComplete)="actionComplete($event)"
  ><e-sheets> 
    <e-sheet> 
      <e-ranges> 
        <e-range [dataSource]='data'></e-range>
      </e-ranges>
      <e-columns>
        <e-column [width]=90></e-column>
        <e-column [width]=100></e-column>
        <e-column [width]=250></e-column>
        <e-column [width]=120></e-column>
        <e-column [width]=130></e-column>
        <e-column [width]=120></e-column>
      </e-columns>
    </e-sheet>
  </e-sheets>
</ejs-spreadsheet>`

return this. template
  }

  hideLegendEvent(event) {
    this.hideLegend = event;

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateEvent());
  }
}
