<app-canvas 
    [js]="true"
    [isEventTabDisabled]="true" 
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef">
    <div title>Tooltip <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <span  class="fa-solid fa-circle-info kn-icon" tooltip="{{label}}" [tooltipDirection]="tooltipDirection" [showTooltip]="showTooltip"></span>
    </div>
    <div angular>
        
    </div>
    <div web>
        
    </div>
    <div javascript>
        <span class="code-comment">/*You can also bind data dynamically using the example below*/</span>
        {{webExample}}
    </div>
    <div events>
       
    </div>
    <div customproperties>
        <div class="section-title">Modifiers</div>
        <div class="action-row">
            <kn-textbox [label]="'tooltip text'" [(ngModel)]="labelNoHtml" [value]="labelNoHtml" (onValueChange)="textChange($event)" [clearIcon]="true" [setDisabled]="isLabelHtml"></kn-textbox>
        </div>
        <div class="kn-spacer"></div>
        <div class="item-row">
            <kn-toggle [label]="'Tooltip text (HTML)'" [(ngModel)]="isLabelHtml" (value)="setHtmlLabel($event);"></kn-toggle>
        </div>
        <div class="item-row">
            <div class="radio-info">
                <kn-toggle [label]="'Hide tooltip'" [(ngModel)]="hideTooltip" (value)="controlEvent($event, '[showTooltip]=', false, true);hideTooltipEvent($event)"></kn-toggle>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="{{hideTooltipText}}" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <div class="item-row">
            <kn-radio-button 
                [id]="'radio1'" 
                [label]="'Top position'" 
                [value]="'top'" 
                [name]="'radioGroup'" 
                [(ngModel)]="tooltipDirection" 
                [defaultValue]="tooltipDirection"
                (onSelect)="direction($event)">
            </kn-radio-button> 
        <br/>
            <kn-radio-button 
                [id]="'radio2'" 
                [label]="'Bottom position'" 
                [value]="'bottom'" 
                [(ngModel)]="tooltipDirection" 
                [defaultValue]="tooltipDirection"
                [name]="'radioGroup'" 
                (onSelect)="direction($event)">
            </kn-radio-button> <br/>
            <kn-radio-button 
                [id]="'radio3'" 
                [label]="'Left position'" 
                [value]="'left'" 
                [(ngModel)]="tooltipDirection" 
                [defaultValue]="tooltipDirection"
                [name]="'radioGroup'" 
                (onSelect)="direction($event)">
            </kn-radio-button> <br/>
            <kn-radio-button 
                [id]="'radio4'" 
                [label]="'Right position'" 
                [value]="'right'" 
                [(ngModel)]="tooltipDirection" 
                [defaultValue]="tooltipDirection"
                [name]="'radioGroup'" 
                (onSelect)="direction($event)">
            </kn-radio-button> 
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">tooltip</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"this is a tooltip"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">tooltipDirection</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"top"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">showTooltip</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <tool-tip-usage></tool-tip-usage>
    </div>
    <div designSpec>
        <tool-tip-specs></tool-tip-specs>
    </div>
    <div accessibility >
        <tool-tip-accessibility></tool-tip-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">October 6, 2023</div>
                <div class="item">1.1.0</div>
                <div class="item">
                    <ul>
                        <li>added an option to show/hide the tooltip</li>
                        <li>bug fix - added word break property to break all. This fixes the issue with a long string with no spaces not breaking to the next line</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the tooltip directive</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

