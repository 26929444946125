<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">The <span class="kn-bold">Panel </span> component is a primary container element used for quickly accessing important controls or information related to content with which users work. The layout is flexible, allowing for the arrangement of elements such as text, buttons and other UI components. </div>
            </section>
       
        <section>
            <div class="kn-headline-24-med">Types </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/panel/KrakenDSM_Components_Panel_01.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Overlay </span> </div>
            <div class="text"><span class="kn-bold">2.	Inline </span> </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Anatomy </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/panel/KrakenDSM_Components_Panel_02.png">

        <section>
            <div class="kn-subtitle-16">Overlay </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Header </span> </div>
            <div class="text"><span class="kn-bold">A.	Panel title: </span> Panels use the heading text style for titles. Titles identifies the panel (usually a title or the name of a selected element).</div>
            <div class="text"><span class="kn-bold">B.	Dismiss icon button: </span> Panels can be dismissed by clicking the close icon in the top right of the panel.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Body: </span> The body area can hold many different components.</div>
            <div class="text"><span class="kn-bold">Footer: </span> The footer has a content area that can hold buttons. It is always divided from the panel body using a 1px horizontal divider.</div>
            <div class="text"><span class="kn-bold">C.	Container </span> </div>
            <div class="text"><span class="kn-bold">D.	Border line: </span> A vertical or horizontal rule displayed only along the outward-facing side of the panel.</div>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">Canvas </span> </div>
            <div class="text"><span class="kn-bold">E.	Tile </span> </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/panel/KrakenDSM_Components_Panel_03.png">

        <section>
            <div class="kn-subtitle-16">Inline </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Header </span> </div>
            <div class="text"><span class="kn-bold">A.	Panel title </span> </div>
            <div class="text"><span class="kn-bold">B.	Collapse icon button </span> </div>
            <div class="text"><span class="kn-bold">C.	Expand icon button </span> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Body </span> </div>
            <div class="text"><span class="kn-bold">D.	Container </span> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Canvas </span> </div>
            <div class="text"><span class="kn-bold">E.	Tile </span> </div>
        </section>


    
        <section>
            <div class="kn-headline-24-med">Overlay panel</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/panel/KrakenDSM_Components_Panel_04.png">

        <section>
            <div class="text"><span>Overlay panels float above the canvas on a raised z-index. Use panel overlay behavior if the content of a panel takes temporary focus. It is displayed in a fixed size and position when opened via a trigger action. The fixed panel has the following attributes: </span> </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/panel/KrakenDSM_Components_Panel_05.png">

        <section>
            <div class="kn-subtitle-16">Position</div>
            
            <div class="text"><span> Positions can be set to anchor on the left, right, top, or bottom of the tile viewport. </span> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1. Left positioned </span> </div>
            <div class="text"><span class="kn-bold">2. Right positioned </span> </div>
            <div class="text"><span class="kn-bold">3. Top positioned </span> </div>
            <div class="text"><span class="kn-bold">4. Bottom positioned </span> </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/panel/KrakenDSM_Components_Panel_06.png">

        <section>
            <div class="kn-subtitle-16">Size</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1. Left/right positioned: </span>The panel spans 100% of the height of the tile viewport with a suggested width of <span class="kn-bold">442px</span>. </div>
            <div class="text"><span class="kn-bold">2. Top/bottom positioned: </span>The panel spans 100% of the width of the tile viewport with a suggested height of <span class="kn-bold">300px</span>. </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Border</div>
           
            <div class="text"><span>By default, overlay panels are displayed with a 1px border only along the outward-facing side of the panel. </span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Trigger</div>
            
            <div class="text"><span>Panel visibility is triggered by an element. </span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Dismissal</div>
            
            <div class="text"><span>Overlay panels can be dismissed either via the dismiss icon button positioned in the top right corner or clicking anywhere outside of the panel container. </span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Elevation</div>
            
            <div class="text"><span>Overlay panels are displayed in the foreground of the visual hierarchy and use a drop shadow to establish depth. </span> </div>
        </section>



        <section>
            <div class="kn-headline-24-med">Inline panel</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/panel/KrakenDSM_Components_Panel_07.png">

        <section>
            <div class="text"><span>Inline panels sit beside the canvas and expand/collapse as a drawer, which inturn resizes the canvas content. Use this option if panel content shares focus with the canvas (for example, when both panel and canvas must be referenced at the same time). The inline panel includes the following attributes: </span> </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/panel/KrakenDSM_Components_Panel_08.png">

        <section>
            <div class="kn-subtitle-16">States</div>
            
            <div class="text"><span>Inline panels have two states: </span> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1. Expand with the panel open: </span>By default, inline panels are displayed in the expanded state. </div>
            <div class="text"><span class="kn-bold">2. Collapsed with the panel closed: </span>When collapsed, the inline panel is displayed with a minimalized state with the body content hidden. </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/panel/KrakenDSM_Components_Panel_09.png">

        <section>
            <div class="kn-subtitle-16">Position</div>
            
            <div class="text"><span>Positions can be set to anchor on the left or right of the canvas.</span> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1. Left </span> </div>
            <div class="text"><span class="kn-bold">2. Right </span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Size</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Expanded: </span>The panel spans the height of the canvas tile(s) with a suggested width of <span class="kn-bold">200px</span>. </div>
            <div class="text"><span class="kn-bold">Collapsed: </span>The display of a collapsed inline panel is suggested to not be less than <span class="kn-bold">68px</span>. </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Border</div>
            
            <div class="text"><span>By default, inline panels are displayed with a <span class="kn-bold">1px </span>border.</span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Expand/collapse trigger</div>
            
            <div class="text"><span>Inline panels can be collapsed to interact with the canvas content.</span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Dismissal</div>
            
            <div class="text"><span>Focusing/clicking outside of an opened/expanded panel does <span class="kn-bold">NOT </span>close a panel. Users must click the collapse icon button to minimize the panel.</span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Elevation</div>
            
            <div class="text"><span>Inline panels are not displayed with an elevation since they are positioned at the same z-index as the canvas.</span> </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Header</div>
            
            <div class="text"><span>The header is docked to the top of its panel. When the content of the panel body overflows, the body content scrolls while the header remains in place.</span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Body</div>
            
            <div class="text"><span>The body area can hold various content ranging from metadata to processing status, which all relates to the specific panel.</span> </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/panel/KrakenDSM_Components_Panel_10.png">

        <section>
            <div class="kn-subtitle-16">Padding</div>
            
            <div class="text"><span>The amount of padding between the boundaries of the panel and the content within is uniform around all four sides and is set to <span class="kn-bold">20px</span>.</span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Corner radius</div>
            
            <div class="text"><span>Panels are displayed with a <span class="kn-bold">4px </span>rounded corner.</span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Animation</div>
            
            <div class="text"><span>When displaying a panel, it should fade in and animate from the set anchored position.</span> </div>
        </section>



        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/panel/KrakenDSM_Components_Panel_11.png">

        <section>
            <div class="kn-subtitle-16">Prefer simple adjustment controls in a panel</div>

            <div class="text">Avoid including controls that require typing text or selecting items to act upon since these actions can require multiple steps.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Don't display multiple open panels</div>

            <div class="text">Allow only one panel to be viewed at a time.</div>
        </section>
    </div>
</div>
