import { Component, Input, AfterViewInit, Output, EventEmitter, forwardRef, ContentChild, TemplateRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, FormGroup, ControlValueAccessor } from "@angular/forms";
export class Checkbox {
  id: string;
  name: string;
  value: any;
  label: string;
}
@Component({
  selector: 'kn-checkbox',
  templateUrl: './kraken-checkbox-component.html',
  styleUrls: ['./kraken-checkbox.component.sass'],
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => KrakenCheckboxComponent), multi: true}
  ]
})
export class KrakenCheckboxComponent implements AfterViewInit, ControlValueAccessor {
  @Input() control:FormGroup;
  @Input() checkboxId: string;
  @Input() label: string;
  @Input() defaultValue: any;
  @Input() disabled = false;
  @Input() name = "checkbox";
  @Input() required = false;
  @Input() customError = false;
  @Input() customWarning = false;
  @Input() customMess;
  @Input() customLabel = false;
  @Output() onSelect = new EventEmitter<any>();
  
  public isChecked = false;

  @ContentChild(TemplateRef) labelTemplate: TemplateRef<any>;
  
  constructor() { }

  ngAfterViewInit(): void {
   
  }

  
  propagateChange = (_: any) => {};
  public onTouched: any = () => {};

  writeValue(obj: any): void {
    
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  select(event) {
    const checkbox = new Checkbox;
    checkbox.id = this.checkboxId;
    checkbox.label = this.label;
    checkbox.name = this.name;
    checkbox.value = event;
    this.onSelect.emit(checkbox);
  }

}
