<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Body Text</div>
        
        <section>
            <div class="text">Body text represents the majority of the typography within the application UI. Its uses are diverse throughout various aspects of content presented in the application. Body text is specified in four size options and is typically used for long-form writing where small text size is most applicable. The following illustrates the body copy typography specifications and contextual reference examples.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/body/KrakenDSM_StyleGuide_Typography_Body_01.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/body/KrakenDSM_StyleGuide_Typography_Body_02.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">BODY</span></div>
            <div class="text">Font size: 14px</div>
            <div class="text">Font weight: Book</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/body/KrakenDSM_StyleGuide_Typography_Body_03.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">BODY - Medium</span></div>
            <div class="text">Font size: 14px</div>
            <div class="text">Font weight: Medium</div>
            <span class="codesnippet">Class name: <pre><code>.kn-body-med</code></pre></span>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/body/KrakenDSM_StyleGuide_Typography_Body_04.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">BODY - Heavy</span></div>
            <div class="text">Font size: 14px</div>
            <div class="text">Font weight: Heavy</div>
            <span class="codesnippet">Class name: <pre><code>.kn-body-heavy</code></pre></span>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/body/KrakenDSM_StyleGuide_Typography_Body_05.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">BODY - 12</span></div>
            <div class="text">Font size: 12px</div>
            <div class="text">Font weight: Book</div>
            <span class="codesnippet">Class name: <pre><code>.kn-body-12</code></pre></span>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/body/KrakenDSM_StyleGuide_Typography_Body_06.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">BODY - 16</span></div>
            <div class="text">Font size: 16px</div>
            <div class="text">Font weight: Book</div>
            <span class="codesnippet">Class name: <pre><code>.kn-body-16</code></pre></span>
        </section>
    </div>
</div>