<app-canvas 
    [js]="true"
    [ts]="true"
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef">
    <div title>Masthead <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-masthead
            [compressed]="compressed"
            (onOverflowMenuSelection)="clickEvent($event)">
            <div logo>
                <img class="logo" src="../../assets/images/KrakenDS_Masthead_ProductLogo_Lighthouse.svg" routerLink="/" alt="logo">
            </div>
            <div version *ngIf="version">
               v1.0.0
            </div>
            <div leftNav *ngIf="leftNav">
                <div class="kn-header-nav">
                    <a class="link" routerLink="/masthead" [routerLinkActive]="['active-link']">Link 1</a>
                    <span>:</span>
                </div>
                <div class="kn-header-nav">
                    <a class="link">Link 2</a>
                </div>
                <div class="kn-header-nav">
                    <div class="link">Link 3</div>
                </div>
                <div class="kn-header-nav">
                    <a class="link">Link 4</a>
                </div>
                <div class="kn-header-nav" id="exampleOverflowLinkId">
                    <div class="link">Link 5</div>
                </div>
            </div>
           
            <div rightNav *ngIf="rightNav">
                <div class="kn-pipe"></div>
                <i id="helpButton" class="fa-solid fa-circle-question kn-icon kn-icon-md" title="this is a tooltip"></i>
                <i class="fa-solid fa-bell kn-icon kn-icon-md" title="this is a tooltip"></i>
                <kn-icon-menu
                    [icon]="'fa-solid fa-gear kn-icon-md'"
                    [dropdownRightAligned]="true"
                    [template]="true"
                    [dropdownCustomWidth]="'max-content'">
                    <ng-template  #customTemplate>
                        <div class="kn-dropdown-item">Link 1</div>
                        <div class="kn-dropdown-item">Link 2</div>
                        <div class="kn-dropdown-item">Link 3</div>
                    </ng-template>
                </kn-icon-menu>
                <div class="kn-pipe"></div>
                <div class="initials">K N</div>
                <kn-icon-menu
                    [id]="'initialsDropdown'"
                    [icon]="'fa-regular fa-chevron-down'"
                    [dropdownRightAligned]="true"
                    [template]="true"
                    [animateIcon]="true"
                    [dropdownCustomWidth]="'max-content'"
                    >
                    <ng-template  #customTemplate>
                        <div class="kn-dropdown-item">Link 1</div>
                        <div class="kn-dropdown-item">Link 2</div>
                    </ng-template>
                </kn-icon-menu>
            </div>
        </kn-masthead>
    </div>
    <div typeScript>
        <span class="code-comment">//Below is an example of how to bind a route to the selected overflow menu item. <br/>You can bind the "clickEvent()" as follows: (onOverflowMenuSelection)="clickEvent($event)". <br/>Please see the "events" tab for a list of available properties. <br/><br/>NOTE: This only applies to the overflow menu items.</span>
        {{overflowClickEvent}}
    </div>
    <div javascript>
        <span class="code-comment">//Create an instance of the masthead and provide the data like this</span>
        {{webCreateContent}}
        <br/><br/>
        <span class="code-comment">//The masthead is designed to be responsive, the "leftnav" has an "overflow" menu. As the browser shrinks, the "leftnav" links will be pushed inside the overflow menu.  You can bind to the overflow menu using the code below</span>
        {{webOverflowClickEvent}}
        <br/><br/>
        <span class="code-comment">//Create content for the right navigation section."</span>
        {{rightNavExample}}
    </div>
    <div events>
        <!-- <span class="code-comment">//shrink the browser in order to see the overflow menu</span> -->
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onOverflowMenuSelection():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">active: {{overflowActive}}</span></div>
                    <div class="obj-item"><span class="label">id:</span> "{{overflowId}}"</div>
                    <div class="obj-item"><span class="label">index: {{overflowIndex}}</span></div>
                    <div class="obj-item"><span class="label">name:</span> "{{overflowName}}"</div>
                    <div *ngIf="overflowRouterlink" class="obj-item"><span class="label">routerlink:</span> "{{overflowRouterlink}}"</div>
                    <div *ngIf="overflowUrl" class="obj-item"><span class="label">url:</span> "{{overflowUrl}}"</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <div class="toggle-info">
                <kn-toggle [label]="'Main navigation'" [(ngModel)]="leftNav" (value)="updateTemplate()"></kn-toggle>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="{{mainNavTooltip}}" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <div class="item-row">
            <div class="toggle-info">
                <kn-toggle [label]="'Right navigation'" [(ngModel)]="rightNav" (value)="updateTemplate()"></kn-toggle>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="{{rightNavTooltip}}" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <div class="item-row">
            <kn-toggle [label]="'Compressed'" [(ngModel)]="compressed" (value)="controlEvent($event, '[compressed]=', 'true', true);"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">style</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"dark", "light"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">compressed</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onOverflowMenuSelection</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <masthead-usage></masthead-usage>
    </div>
    <div designSpec>
        <masthead-specs></masthead-specs>
    </div>
    <div accessibility >
        <masthead-accessibility></masthead-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.4</div>
                <div class="item">
                    <ul>
                        <li>Added addition unit testing after component was published</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">August 15, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>Removed used Input property</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 11, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>Updated dependencies to include Icon Menu 1.0.0</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the masthead component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

