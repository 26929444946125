<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Buttons</div>
        
        <section>
            <div class="kn-headline-24-med">Primary</div>
        </section>
        
        <section>
            <div class="text">Our primary action button, with the greatest prominence in the screen presentation hierarchy, is visually emphasized through the use of a dark (blue) value from our primary color palette.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_01.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_02.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_03.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Enabled</span></div>
            <div class="text">Container: <span class="kn-bold">01. Primary, Darker #1C3C50 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 11.59:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one-darker</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one-darker</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Hover</span></div>
            <div class="text">Container: <span class="kn-bold">03. Primary #205D86 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 7.06:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Focused</span></div>
            <div class="text">Container: <span class="kn-bold">03. Primary #205D86 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 7.06:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Pressed</span></div>
            <div class="text">Container: <span class="kn-bold">04. Primary, Medium Light #4999C9 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AA 3.14:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one-med-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one-med-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Disabled</span></div>
            <div class="text">Container: <span class="kn-bold">10. Gray, Light #E1E6EB </span></div>
            <div class="text">Text: <span class="kn-bold">07. Gray, Darker #A5B2BD </span></div>
            <div class="text">Color contrast: <span class="kn-bold">1.72:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$gray-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--gray-light</code></pre></span>
        </section>

        <section>
            <div class="kn-headline-24-med">Secondary</div>
        </section>

        <section>
            <div class="text">The secondary action button, used in cases where multiple juxtaposition call-to-action triggers are required within a single screen layout, is presented in a slightly lighter value from the primary color palette than the primary action button.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_04.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_05.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_06.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Enabled</span></div>
            <div class="text">Container: <span class="kn-bold">03. Primary #205D86 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 7.06:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Hover</span></div>
            <div class="text">Container: <span class="kn-bold">04. Primary, Medium Light #4999C9 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AA 3.14:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one-med-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one-med-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Focused</span></div>
            <div class="text">Container: <span class="kn-bold">04. Primary, Medium Light #4999C9 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AA 3.14:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one-med-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one-med-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Pressed</span></div>
            <div class="text">Container: <span class="kn-bold">05. Primary, Light #B2D3E7 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">1.57:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Disabled</span></div>
            <div class="text">Container: <span class="kn-bold">10. Gray, Light #E1E6EB </span></div>
            <div class="text">Text: <span class="kn-bold">07. Gray, Darker #A5B2BD </span></div>
            <div class="text">Color contrast: <span class="kn-bold">1.72:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$gray-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--gray-light</code></pre></span>
        </section>

        <section>
            <div class="kn-headline-24-med">Tertiary</div>
        </section>

        <section>
            <div class="text">The next step down in the button visual hierarchy, the tertiary action button, is used for secondary task actions in the UI and presents in a manner that contrasts visually with white fill and primary value border.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_07.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_08.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_09.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Enabled</span></div>
            <div class="text">Container: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Text: <span class="kn-bold">03. Primary #205D86 </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 7.06:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$white</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--white</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Hover</span></div>
            <div class="text">Container: <span class="kn-bold">06. Primary, Lighter #C6DEED </span></div>
            <div class="text">Text: <span class="kn-bold">03. Primary #205D86 </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 5.07:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one-lighter</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one-lighter</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Focused</span></div>
            <div class="text">Container: <span class="kn-bold">06. Primary, Lighter #C6DEED </span></div>
            <div class="text">Text: <span class="kn-bold">03. Primary #205D86 </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 5.07:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one-lighter</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one-lighter</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Pressed</span></div>
            <div class="text">Container: <span class="kn-bold">07. Primary, Lightest #E5F0F7 </span></div>
            <div class="text">Text: <span class="kn-bold">03. Primary #205D86 </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 6.1:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$primary_one-lightest</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--primary_one-lightest</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Disabled</span></div>
            <div class="text">Container: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Text: <span class="kn-bold">07. Gray, Darker #A5B2BD </span></div>
            <div class="text">Color contrast: <span class="kn-bold">2.16:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$white</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--white</code></pre></span>
        </section>

        <section>
            <div class="kn-headline-24-med">Error</div>
        </section>

        <section>
            <div class="text">The following illustrates the application of specified color for action buttons displayed in direct relationship to critical error messaging dialogs and similar system notifications.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_10.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Primary error</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_11.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_12.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Enabled</span></div>
            <div class="text">Container: <span class="kn-bold">03. Danger #A50D08 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 7.89:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$knDanger</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-danger</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Hover</span></div>
            <div class="text">Container: <span class="kn-bold">04. Danger, Medium Light #DD5A56 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AA 3.1:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$knDanger-med-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-danger-med-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Focused</span></div>
            <div class="text">Container: <span class="kn-bold">04. Danger, Medium Light #DD5A56 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AA 3.1:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$knDanger-med-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-danger-med-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Pressed</span></div>
            <div class="text">Container: <span class="kn-bold">05. Danger, Light #FA9A88 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">2.09:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$knDanger-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-danger-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Disabled</span></div>
            <div class="text">Container: <span class="kn-bold">10. Gray, Light #E1E6EB </span></div>
            <div class="text">Text: <span class="kn-bold">07. Gray, Darker #A5B2BD </span></div>
            <div class="text">Color contrast: <span class="kn-bold">1.72:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$gray-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--gray-light</code></pre></span>
        </section>

        <section>
            <div class="kn-subtitle-16">Tertiary error</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_13.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_14.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Enabled</span></div>
            <div class="text">Container: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Text: <span class="kn-bold">03. Danger #A50D08 </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 7.89:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$white</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--white</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Hover</span></div>
            <div class="text">Container: <span class="kn-bold">06. Danger, Lighter #FDE4DD </span></div>
            <div class="text">Text: <span class="kn-bold">03. Danger #A50D08 </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 6.5:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$knDanger-lighter</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-danger-lighter</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Focused</span></div>
            <div class="text">Container: <span class="kn-bold">06. Danger, Lighter #FDE4DD </span></div>
            <div class="text">Text: <span class="kn-bold">03. Danger #A50D08 </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 6.5:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$knDanger-lighter</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-danger-lighter</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Pressed</span></div>
            <div class="text">Container: <span class="kn-bold">07. Danger, Lightest #FDF2EF </span></div>
            <div class="text">Text: <span class="kn-bold">03. Danger #A50D08 </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 7.18:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$knDanger-lightest</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-danger-lightest</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Disabled</span></div>
            <div class="text">Container: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Text: <span class="kn-bold">07. Gray, Darker #A5B2BD </span></div>
            <div class="text">Color contrast: <span class="kn-bold">2.16:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$white</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--white</code></pre></span>
        </section>

        <section>
            <div class="kn-headline-24-med">Warning</div>
        </section>

        <section>
            <div class="text">The following illustrates the application of specified color for action buttons displayed in direct relationship to warning alert messaging dialogs and similar system notifications.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_15.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Primary warning</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_16.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_17.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Enabled</span></div>
            <div class="text">Container: <span class="kn-bold">03. Secondary #B8551E </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 4.82:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$secondary_one</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--secondary_one</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Hover</span></div>
            <div class="text">Container: <span class="kn-bold">04. Secondary, Medium Light #E17A00 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AA 3.1:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$secondary_one-med-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--secondary_one-med-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Focused</span></div>
            <div class="text">Container: <span class="kn-bold">04. Secondary, Medium Light #E17A00 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AA 3.1:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$secondary_one-med-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--secondary_one-med-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Pressed</span></div>
            <div class="text">Container: <span class="kn-bold">05. Secondary, Light #FBCC94 </span></div>
            <div class="text">Text: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Color contrast: <span class="kn-bold">1.48:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$secondary_one-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--secondary_one-light</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Disabled</span></div>
            <div class="text">Container: <span class="kn-bold">10. Gray, Light #E1E6EB </span></div>
            <div class="text">Text: <span class="kn-bold">07. Gray, Darker #A5B2BD </span></div>
            <div class="text">Color contrast: <span class="kn-bold">1.72:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$gray-light</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--gray-light</code></pre></span>
        </section>

        <section>
            <div class="kn-subtitle-16">Tertiary warning</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_18.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/color/buttons/KrakenDSM_StyleGuide_Color_Buttons_19.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Enabled</span></div>
            <div class="text">Container: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Text: <span class="kn-bold">03. Secondary #B8551E </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AAA 4.82:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$white</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--white</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Hover</span></div>
            <div class="text">Container: <span class="kn-bold">06. Secondary, Lighter #FDEAD4 </span></div>
            <div class="text">Text: <span class="kn-bold">03. Secondary #B8551E </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AA 4.1:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$secondary_one-lighter</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--secondary_one-lighter</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Focused</span></div>
            <div class="text">Container: <span class="kn-bold">06. Secondary, Lighter #FDEAD4 </span></div>
            <div class="text">Text: <span class="kn-bold">03. Secondary #B8551E </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AA 4.1:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$secondary_one-lighter</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--secondary_one-lighter</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Pressed</span></div>
            <div class="text">Container: <span class="kn-bold">07. Secondary, Lightest #FEF3E5 </span></div>
            <div class="text">Text: <span class="kn-bold">03. Secondary #B8551E </span></div>
            <div class="text">Color contrast: <span class="kn-bold">AA 4.39:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$secondary_one-lightest</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--secondary_one-lightest</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Disabled</span></div>
            <div class="text">Container: <span class="kn-bold">14. White #FFFFFF </span></div>
            <div class="text">Text: <span class="kn-bold">07. Gray, Darker #A5B2BD </span></div>
            <div class="text">Color contrast: <span class="kn-bold">2.16:1 </span></div>
            <span class="codesnippet">Theme variable: <pre><code>$white</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--white</code></pre></span>
        </section>
    </div>
</div>