import { Component } from '@angular/core';

@Component({
  selector: 'spreadsheet-usage',
  templateUrl: './spreadsheet-usage.component.html',
  styleUrls: ['./spreadsheet-usage.component.sass']
})
export class SpreadsheetUsageComponent {

}
