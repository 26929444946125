<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Fonts</div>
        
        <section>
            <div class="kn-headline-24-med">Typeface</div>
        </section>
        
        <section>
            <div class="text">The <span class="kn-bold">Kraken DS</span> typeface is <span class="kn-bold">Avenir</span>. This sans-serif font is clean, minimalistic, and a very versatile option. Considered one of the world's most famous fonts, Avenir has become a core part of the identity of many of the most popular digital media companies.</div>
        </section>
       
        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/fonts/KrakenDSM_StyleGuide_Typography_Fonts_01.png">
        </section>

        <section>
            <div class="kn-headline-24-med">Weight</div>
        </section>

        <section>
            <div class="text">Weight refers to a font's thickness, which can create a content hierarchy by adding or removing emphasis to fonts of the same size. Kraken DS uses the following font weights:</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/typography/fonts/KrakenDSM_StyleGuide_Typography_Fonts_02.png">
        </section>

        <section>
            <div class="kn-headline-24-med">Color</div>
        </section>

        <section>
            <div class="text">Follow the guidelines associated with the component and use case in the Kraken DS Color Guidelines.</div>
        </section>
    </div>
</div>