<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Engaging the UX team</div>
        <section>
            <div class="text">There are several ways you may engage the UX Team to leverage our expertise and apply it to your project and or feature initiative depending on the current state of progress and your specific need:</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/about/engaging the ux team/KrakenDSM_About_EngagingUXTeam_01.png">
        </section>

        <section>
            <div class="text">Unsure how to design a solution that resonates with the intended user and aligns with their task priorities? The UX Team can assist in conducting user research (quantitative and qualitative) to help validate a hypothesis, uncover user needs and expectations, test the effectiveness of existing solution designs, gather functional requirements, and map user task flows.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/about/engaging the ux team/KrakenDSM_About_EngagingUXTeam_02.png">
        </section>

        <section>
            <div class="text">Already designing a UI solution for your application but want professional input on your chosen direction for the implementation? The UX Team can provide a comprehensive design review and peer critique, offering specific feedback on how to align your design with UX/UI best practices.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/about/engaging the ux team/KrakenDSM_About_EngagingUXTeam_03.png">
        </section>

        <section>
            <div class="text">Need a UX peer to be your sounding board when developing your application UI, or do you require some assistance in adopting Kraken DS? The UX Team can pair one of our resources with your UI developer to work through these design questions and unblock your development workflow. The UX team can also validate the proposed feature designs through lean usability testing. </div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/about/engaging the ux team/KrakenDSM_About_EngagingUXTeam_04.png">
        </section>

        <section>
            <div class="text">No user experience or UI design capabilities on your current product team? No problem. The UX Team can (virtually) embed the necessary skilled resource as a contingent member of your team. The UX resource will be dedicated to fulfilling all UX/UI design tasks and directly collaborating with engineering peers throughout your feature development or project initiative, actively participating in regular standup and planning meetings.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/about/engaging the ux team/KrakenDSM_About_EngagingUXTeam_05.png">
        </section>

        <section>
            <div class="text">Does your application require a component pattern not currently part of Kraken DS? The UX Team can collaborate on designing the needed component based on your functional requirements and then incorporate your coded execution of the component pattern as a contribution to the design system for all other teams to leverage.</div>
        </section>

        <section>
            <div class="text">To engage the UX Team, click on the button below and fill out the inquiry form to provide us with more detail about your project and specific UX need. </div>
        </section>

        <section>
            <kn-button 
            [buttonId]="'contactUs'" 
            [buttonType]="'kn-tertiary kn-btn-sm'" 
            [buttonText]="'Contact US'" 
            [buttonIconLeft]="'fa-solid fa-messages'"
            [routerLinkActive]="['acc-active-link']" 
            routerLink="/support/contact">
        </kn-button>
        </section>
    </div>
</div>