import { NgModule } from '@angular/core';
import { KrakenAccordionComponent } from './kraken-accordion.component';
import { CommonModule } from '@angular/common';
import { KrakenAccordionTierOneComponent } from './kraken-accordion-tier-one/kraken-accordion-tier-one.component';
import { KrakenAccordionTierTwoComponent } from './kraken-accordion-tier-two/kraken-accordion-tier-two.component';
import { RouterModule } from '@angular/router';
import { KrakenAccordionTierThreeComponent } from './kraken-accordion-tier-three/kraken-accordion-tier-three.component';

@NgModule({
  declarations: [KrakenAccordionComponent, KrakenAccordionTierOneComponent, KrakenAccordionTierTwoComponent, KrakenAccordionTierThreeComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [KrakenAccordionComponent, KrakenAccordionTierOneComponent, KrakenAccordionTierTwoComponent, KrakenAccordionTierThreeComponent]
})
export class KrakenAccordionModule { }
