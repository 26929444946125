<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">A <span class="kn-bold">Checkbox </span> is a type of button that lets the user choose between two opposite states, actions, or values. A selected checkbox is considered on when it contains a checkmark and off when it's empty. A title almost always follows a checkbox unless it appears in a checklist. </div>
            </section>
       
        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Enabled not selected </span> </div>
            <div class="text"><span class="kn-bold">2.	Enabled selected </span> </div>
            <div class="text"><span class="kn-bold">3.	Enabled indeterminate </span> </div>
            <div class="text"><span class="kn-bold">4.	Enabled error </span> </div>
            <div class="text"><span class="kn-bold">5.	Enabled warning </span> </div>
            <div class="text"><span class="kn-bold">6.	Hover not selected </span> </div>
            <div class="text"><span class="kn-bold">7.	Hover selected </span> </div>
            <div class="text"><span class="kn-bold">8.	Hover indeterminate </span> </div>
            <div class="text"><span class="kn-bold">9.	Hover error </span> </div>
            <div class="text"><span class="kn-bold">10.	Hover warning </span> </div>
            <div class="text"><span class="kn-bold">11.	Disabled not selected </span> </div>
            <div class="text"><span class="kn-bold">12.	Disabled selected </span> </div>
            <div class="text"><span class="kn-bold">13.	Disabled indeterminate </span> </div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_02.png">

        <section>
            <div class="text"><span class="kn-bold">A.	Group label: </span> Describes the group of options or provides guidance for making a selection. </div>
            <div class="text"><span class="kn-bold">B.	Checkbox input: </span> A radio button indicating the appropriate state. </div>
            <div class="text"><span class="kn-bold">C.	Checkbox label: </span> Describes the information you want to select or unselect. </div>
            <div class="text"><span class="kn-bold">D.	Container </span> </div>
            <div class="text"><span class="kn-bold">E.	Error/warning icon </span> </div>
            <div class="text"><span class="kn-bold">F.	Error/warning text </span> </div>
        </section>

        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Individual choice</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_03.png">

            <section>
                <div class="text">A single check box is used to select an individual choice. The check box label indicates the selected state, whereas the meaning of the cleared state must be the unambiguous opposite of the selected state.</div>
            </section>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_04.png">
            
            <section>
                <div class="text"><span class="kn-bold">Label </span> </div>

                <div class="text">Checkboxes should always have a label. When the label is not defined, a checkbox becomes standalone. Standalone checkboxes are only used when their connection to other components is clear and they give sufficient context.</div>
            </section>
        </section>

        <section>
            <ul class="bullets">
                <li>Always use clear and concise labels for checkboxes.</li>
                <li>Labels appear to the right checkbox inputs.</li>
            </ul>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Click area </span> </div>

            <div class="text">Include both the button and the option label in the selection target of the checkbox, so the user has a larger target area to interact with.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_05.png">
            
            <section>
                <div class="text"><span class="kn-bold">Text overflow </span> </div>

                <div class="text">When a checkbox label is too long for the horizontal space available, it wraps to form another line.</div>
            </section>
        </section>

        <section>
            <div class="kn-subtitle-16">Independent choices (one or more)</div>
        </section>

        <section>
            <div class="text">A group of checkboxes is used to select from a set of choices. Users can select any combination of options in a group of checkboxes or can choose to select none if the field is not required.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_06.png">

            <section>
                <div class="text"><span class="kn-bold">Group label </span> </div>

                <div class="text">A heading can accompany a set of checkboxes to provide further context or clarity. In some cases, a group of checkboxes may be within a larger group of components that already have a group label.</div>
            </section>
        </section>

        <section>
            <ul class="bullets">
                <li>Label text should always be uppercase.</li>
                <li>Use a group label to explain the purpose of the group of checkbox options; do not instruct the user to simply “Select one of the following choices”.</li>
            </ul>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Alignment </span> </div>

            <div class="text">Align checkboxes vertically. Horizontal alignment is more difficult to read and localize. Multiple sets of checkboxes on the form can also be challenging to make them align well due to differences in option label lengths.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Ordering </span> </div>

            <div class="text">List the options in a logical order, such as most likely to be selected to least, simplest operation to most complex, or least risk to most. Alphabetical ordering is not recommended because it is language dependent and therefore not localizable.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_07.png">

            <section>
                <div class="text"><span class="kn-bold">Error/warning </span> </div>

                <div class="text">Checkbox groups can be marked as having an error or warning to show that a selection needs to be made in order to move forward, or that the selection was made is invalid. The error is indicated with an icon and associated text.</div>
            </section>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_08.png">

            <section>
                <div class="text"><span class="kn-bold">Disabled </span> </div>

                <div class="text">A checkbox group in a disabled state shows that a selection exists but is unavailable in that circumstance. This can be used to maintain layout continuity and communicate that an action may become available later.</div>
            </section>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Option labels </span> </div>

            <div class="text">Focus the option label text on the differences among the options. If all the options have the same introductory text, move that description to the group label.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_09.png">

            <section>
                <div class="text"><span class="kn-bold">Multiple options </span> </div>

                <div class="text">If the number of checkbox options is five or more, consider using a multi-select dropdown list instead.</div>
            </section>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_10.png">

            <section>
                <div class="text"><span class="kn-bold">Representing mixed values </span> </div>

                <div class="text">A checkbox usually has two states: selected or not selected. However, indeterminate checkboxes has a third state: neither selected nor not selected. Indeterminate checkboxes are used in the case of controlling a group of checkboxes. When displayed with a label, the label is displayed as “Select All”.</div>
            </section>

            <section>
                <div class="text">When the “Select All” master checkbox is selected, all the set checkboxes are subsequently selected and the master checkbox is displayed with in a selected state. When one or more of the set checkboxes are deselected then the state of the master checkbox cannot be determined and displays the indeterminate state.</div>
            </section>

            <section>
                <div class="text"><span class="kn-bold">1.  With label </span> </div>
                <div class="text"><span class="kn-bold">2.  Standalone </span> </div>
            </section>
        </section>


        <section>
            <div class="kn-headline-24-med">When to use</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_11.png">

            <section>
                <div class="text"><span class="kn-bold">Checkbox or radio button? </span> </div>

                <div class="text">If a user can select only one option from a list, radio buttons should be used instead of checkboxes. Checkboxes allow the user to select multiple items in a vast set, whereas radio buttons allow the user to select only one option. A set of radio buttons should be used to select only a single option from a list of mutually exclusive options.</div>
            </section>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_12.png">

            <section>
                <div class="text"><span class="kn-bold">Checkbox or switch </span> </div>

                <div class="text">Checkboxes are best used for communicating selection (e.g., multiple table rows) while toggle switches are best used for communicating activation (e.g., on/off states). Checkboxes, unlike switches, can have an error state.</div>
            </section>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_13.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Nesting</div>

            <div class="text">Avoid nesting checkboxes.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_14.png">
        </section>

        <section>
            <div class="kn-subtitle-16">More than two states</div>

            <div class="text">Do not use a checkbox when requiring the user to indicate a selection where more than two distinct states are available. Alternatively, use a radio button when the user needs to choose between more than two states. See Radio Button.</div>
        </section>


        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/checkbox/KrakenDSM_Components_Checkbox_15.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Performing commands</div>

            <div class="text">Don’t use the selection of a radio button to perform commands, display other windows, or dynamically show or hide other controls related to the selected control, as screen readers cannot detect such events.</div>
        </section>
    </div>
</div>