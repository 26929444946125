import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KrakenMastheadComponent } from './kraken-masthead.component';
import { KrakenIconMenuModule } from '@kraken-ui/kraken-icon-menu';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [KrakenMastheadComponent],
  imports: [
    CommonModule,
    KrakenIconMenuModule,
    RouterModule
  ],
  exports: [KrakenMastheadComponent]
})
export class KrakenMastheadModule { }
