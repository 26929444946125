import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import  packageInfo from '../../../../../kraken-radio-button/package.json';

export class Radio {
  id: any;
  label: string;
  name: string;
  value: any;
}

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.sass']
})
export class RadioButtonComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public angularComponentRef2;
  public webComponentRef;
  public webComponent2Ref;
  public componentId = 'radio1ComponentId';
  public componentId2 = 'radio2ComponentId';
  public webSetProperties;
  public webRemoveProperties;
  public webClickEvent;
  public elementTag = 'kn-radio-button';
  public radio1Disabled = false;
  public radio2Disabled = false;
  public label1 = "First selection";
  public label2 = "Second selection";
  public event1 = true;
  public radioEvent;
  public customLabelTemp = true;
  public radioSelectEvent = false;
  public customLableTemplate: any;
  public componentVersion = packageInfo.version;
  public initializeComponentWeb: any;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenRadioButtonModule'};
  public errorEnabled = false;
  public warningEnabled = false;
  public radioOptions = [
    {id: 'extracted', value: 'new', label: 'Load extracted text and metadata'},
    {id: 'nativefiles', value: 'old', label: 'Native files'}
  ]
  public defaultRadioOption = false;

  public angularComponentOptions: any = [
    { option: '[radioId]=', id: 0, value: '"\''+ this.componentId +'\'"'},
    { option: '[label]=', id: 2, value: '"\'' + this.label1 + '\'"'},
    { option: '[value]=', id: 3, value: '"true"'},
    { option: '[(ngModel)]=', id: 3, value: '"defaultRadioOption"'},
    { option: '[defaultValue]=', id: 3, value: '"defaultRadioOption"'},
    { option: '[name]=', id: 4, value: '"\'radioGroup\'"'},
  ];

  public angularComponent2Options: any = [
    { option: '[radioId]=', id: 0, value: '"\''+ this.componentId2 +'\'"'},
    { option: '[label]=', id: 2, value: '"\'' + this.label2 + '\'"'},
    { option: '[value]=', id: 3, value: '"false"'},
    { option: '[(ngModel)]=', id: 3, value: '"defaultRadioOption"'},
    { option: '[defaultValue]=', id: 3, value: '"defaultRadioOption"'},
    { option: '[name]=', id: 4, value: '"\'radioGroup\'"'},
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"'+ this.componentId +'"'},
    { option: 'radio-id=', id: 1, value: '"radio1Id"'},
    { option: 'label=', id: 2, value: '"' + this.label1 + '"'},
    { option: 'value=', id: 3, value: '"1"'},
    { option: 'name=', id: 4, value: '"radioGroup"'}
  ];

  public webComponent2Options: any = [
    { option: 'id=', id: 0, value: '"'+ this.componentId2 +'"'},
    { option: 'radio-id=', id: 1, value: '"radio2Id"'},
    { option: 'label=', id: 2, value: '"' + this.label2 + '"'},
    { option: 'value=', id: 3, value: '"2"'},
    { option: 'name=', id: 4, value: '"radioGroup"'}
  ];

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.angularComponentRef2 = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponent2Options, this.angularContainer);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    this.webComponent2Ref = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponent2Options, this.webContainer);

    //show custom lable template 
    this.customTemplateEvent(true);
    this.initializeComponentWeb =`
const radioBtn = document.querySelector('#${this.componentId}');
`
    this.webClickEvent = 
`
radioBtn.addEventListener('onSelect', (event) => console.log(event.detail));
`

    this.webSetProperties = 
`
radioBtn.disabled = true;
`
this.webRemoveProperties = 
`
radioBtn.disabled = true;
`
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer,  this.customLableTemplate);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  radioSelection(event) {
    this.radioEvent = new Radio;
    this.radioEvent.id = event.id;
    this.radioEvent.name = event.name;
    this.radioEvent.value = event.value;
    this.radioEvent.label = event.label;
  }

  labelText(event, checkbox) {
    if(checkbox == 1) {
      this.label1 = event;
      const index = this.angularComponentOptions.findIndex((obj => obj.option == '[label]='));
      //angular
      this.angularComponentOptions[index].option = '[label]=';
      this.angularComponentOptions[index].value = '"\'' + event +'\'"';
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer,  this.customLableTemplate);
      //web
      this.webComponentOptions[index].option = 'label=';
      this.webComponentOptions[index].value = '"' + event + '"';
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    } else {
      const index = this.angularComponent2Options.findIndex((obj => obj.option == '[label]='));
      this.label2 = event;
      //angular
      this.angularComponent2Options[index].option = '[label]=';
      this.angularComponent2Options[index].value = '"\'' + event +'\'"';
      this.angularComponentRef2 = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponent2Options, this.angularContainer,  this.customLableTemplate);
      //web
      this.webComponent2Options[index].option = 'label=';
      this.webComponent2Options[index].value = '"' + event + '"';
      this.webComponent2Ref = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponent2Options, this.webContainer);
    }
  }

  customTemplateEvent(event) {
    if(event) {
      this.controlEvent(true, '[customLabel]=', 'true', false);
      this.controlEvent(false, '[label]=', 'true', false);
      //custom template
      this.customLableTemplate = 
`<ng-template #labelTemplate>
  <div class="radio-label">Custom label with <span class="kn-bold">HTML</span></div>
</ng-template>`
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.customLableTemplate);
    } else {
      this.controlEvent(false, '[customLabel]=', 'false', false);
      this.controlEvent(true, '[label]=', ''+ this.label1 +'', false);
      this.customLableTemplate = "";
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.customLableTemplate);
    }
  }

}
