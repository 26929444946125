<app-canvas 
    [js]="true"  
    [ts]="true"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component">
    <div title>Spin Box <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
       <kn-spin-box 
            (onValueChange)="valueChange($event)" 
            [value]="value"
            [label]="label"
            [increment]="increment"
            [layout]="layout"
            [minValue]="minValue"
            [maxValue]="maxValue"
            [disabled]="disabled"
            [readOnly]="readOnly"
            [compressed]="compressed"
            [valueAlignRight]="valueAlignRight"
            [customErrorMessage]="customError"
            [hasError]="error">
        </kn-spin-box>
    </div>
    <div angular>

    </div>
    <div typeScript>
       <span class="code-comment">//You can add custom error handling by setting the [hasError] and [customErrorMessage] properties. Below is a basic example</span>
       {{customErrorTemplate}}
    </div>
    <div javascript>
        <span class="code-comment">//You can add custom error handling by setting the hasError and customErrorMessage properties. Below is a basic example </span>
       {{jsCustomErrorTemplate}}
    </div>
    <div web></div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">valueChange():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">value:</span> {{value}}</div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
       
        <div class="section-title">Modifiers</div>
        <div class="kn-spacer"></div>
        <div class="action-input">
            <kn-dropdown 
                [label]="'Layout'" 
                [value]="currentLayout" 
                [items]="layoutObj" 
                [fields]="layoutFields" 
                (onSelect)="onLayoutEvent($event)"> 
            </kn-dropdown>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Add a label'" 
                (value)="toggleLabel($event)" 
                [(ngModel)]="addLabel">
            </kn-toggle>
            
            <div *ngIf="addLabel" class="action-input">
                <kn-textbox 
                    [label]="'Label'" 
                    [value]="label" 
                    [setPlaceholder]="'Add a custom label'"
                    (onValueChange)="labelEvent($event)" 
                    [clearIcon]="true">
                </kn-textbox>
            </div>
            <div class="kn-spacer" *ngIf="!addLabel"></div>
        </div>
        <div class="action-input">
            <kn-textbox 
                [label]="'Default Value'" 
                [value]="value" 
                [numericCharsOnly]="true"
                [(ngModel)]="value"
                (onValueChange)="valueChange($event)" 
                [clearIcon]="true">
            </kn-textbox>
        </div>
        <div class="action-input">
            <kn-textbox 
                [label]="'Value Increment'" 
                [value]="increment" 
                [(ngModel)]="increment"
                (onValueChange)="incrementEvent($event)" 
                [numericCharsOnly]="true"
                [clearIcon]="true">
            </kn-textbox>
        </div>
        <div class="action-input">
            <kn-textbox 
                [label]="'Minimum value'" 
                [value]="minValue" 
                [numericCharsOnly]="true"
                [(ngModel)]="minValue"
                (onValueChange)="minValueEvent($event)" 
                [clearIcon]="true">
            </kn-textbox>
        </div>
        <div class="action-input">
            <kn-textbox 
                [label]="'Maximum value'" 
                [value]="maxValue" 
                [numericCharsOnly]="true"
                [(ngModel)]="maxValue"
                (onValueChange)="maxValueEvent($event)" 
                [clearIcon]="true">
            </kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Compressed'" 
                (value)="controlEvent($event, '[compressed]=', true, true);" 
                [(ngModel)]="compressed"
                name="compressed">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Right-align value'" 
                (value)="controlEvent($event, '[valueAlignRight]=', true, true);" 
                [(ngModel)]="valueAlignRight"
                name="rightAlign">
            </kn-toggle>
        </div>
        
        <div class="item-row">
            <kn-toggle 
                [label]="'Disabled state'" 
                (value)="controlEvent($event, '[disabled]=', true, true);" 
                [(ngModel)]="disabled"
                [disabled]="readOnly"
                name="disabled">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Read-only state'" 
                (value)="controlEvent($event, '[readOnly]=', true, true);" 
                [(ngModel)]="readOnly"
                [disabled]="disabled"
                name="disabled">
            </kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">componentId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"spinBoxId"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">label</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"example label"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">layout</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"vetical right", "vetical left", "Horizontal"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">minValue</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>1</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">maxValue</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>10</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">increment</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>5</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">value</div>
                <div class="item"><i>number</i></div>
                <div class="item"><i>0</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">compressed</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">valueAlignRight</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">hasError</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">customErrorMessage</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"This is an error message"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">disabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">readOnly</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">valueChange</div>
                <div class="item"><i>event</i></div>
                <div class="item">{{'{}'}}</div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <spin-box-usage></spin-box-usage>
    </div>
    <div designSpec>
        <spin-box-specs></spin-box-specs>
    </div>
    <div accessibility>
        <spin-box-accessibility></spin-box-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">Feburary 1, 2024</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the Spin box component</li>
                    </ul>
                </div>
            </div>
            
        </div>
    </div>
</app-canvas>