<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text"><span class="kn-bold">Menu Buttons </span> let you immediately perform an action or choose additional options from a dropdown.</div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/menu-button/KrakenDSM_Components_MenuButton_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1.	Primary</span></div>
            <div class="text"><span class="kn-bold">2.	Secondary</span></div>
            <div class="text"><span class="kn-bold">3.	Tertiary</span></div>
            <div class="text"><span class="kn-bold">4.	Flat</span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/menu-button/KrakenDSM_Components_MenuButton_02.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Enabled</span></div>
            <div class="text"><span class="kn-bold">2.	Hover</span></div>
            <div class="text"><span class="kn-bold">3.	Focused</span></div>
            <div class="text"><span class="kn-bold">4.	Pressed</span></div>
            <div class="text"><span class="kn-bold">5.	Disabled</span></div>
        </section>
      
        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/menu-button/KrakenDSM_Components_MenuButton_03a.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Enabled button</div>      
            </section>

            <section>
                <div class="text"><span class="kn-bold">A.	Text label </span></div>
                <div class="text"><span class="kn-bold">B.	Container </span></div>
                <div class="text"><span class="kn-bold">C.	Leading icon </span></div>
                <div class="text"><span class="kn-bold">D.	Dropdown trailing icon </span></div>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/menu-button/KrakenDSM_Components_MenuButton_03b.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Enabled button with menu</div>      
            </section>

            <section>
                <div class="text"><span class="kn-bold">E.	Dropdown trailing icon: </span>When the menu is displayed, the trailing icon switches between the downward-facing chevron to the upward-facing chevron.</div>
            </section>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/menu-button/KrakenDSM_Components_MenuButton_03c.png">

        <section>
            <section>
                <div class="kn-subtitle-16">Menu</div>      
            </section>

            <section>
                <div class="text"><span class="kn-bold">F.	Menu option: </span>A choice for the user, shown amongst other choices in a menu.</div>
                <div class="text"><span class="kn-bold">G.	Menu option hover </span></div>
                <div class="text"><span class="kn-bold">H.	Divider line: </span>An option used to differentiate between menu option sections.</div>
            </section>
        </section>

        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Multiple related actions</div>
            <div class="text"> Use the menu button when multiple related action options exist for a specific function (ex. "Export" as XLS, CSV, or XML).</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/menu-button/KrakenDSM_Components_MenuButton_04.png">

        <section>
            <div class="kn-subtitle-16">Button width</div>

            <div class="text">Button width is variable based on the length of the label (with or without an icon) with the following guidelines:</div>
        
            <ul class="bullets">
                <li>Regardless of how few characters are used in the button label, minimum button width is 132px.</li>
                <li>Button width scales with the length of the button label, retaining a consistent 16px padding with the vertical boundaries of the button.</li>
            </ul>
        </section>

        <section>
            <div class="kn-subtitle-16">Label</div>
        
            <ul class="bullets">
                <li>Use the menu button label to explain the purpose of the trigger invoked by clicking or tapping the button and indicating what will happen (ex. Download, Sign Up).</li>
                <li>Labels should be single words or succinct phrases that do not exceed 25-characters to support quick scanning and interpretation.</li>
                <li>Don't use punctuation marks such as periods or exclamation points.</li>
                <li>Button label text should always be uppercase.</li>
            </ul>
        </section>

        <section>
            <div class="kn-subtitle-16">Icons</div>
            <div class="text">Icons may be used in combination with text labels within menu buttons to further reinforce the purpose of the button and the action triggered by interacting with it.</div>
            
            <ul class="bullets">
                <li>Keep the button text concise: 1 to 2 words with fewer than 25 characters, including spaces</li>
                <li>Don't use punctuation marks such as periods or exclamation points</li>
                <li>Use action words for the label that indicate what will happen (for example, Download or Sign Up)</li>
                <li>Use an icon and text where appropriate to reinforce actions</li>
                <li>Button text should always be in uppercase</li>
            </ul>
        </section>

        <section>
            <div class="kn-subtitle-16">Menu</div>
            <div class="text">Reference the Contextual Menu component for complete usage guidelines. See: Components – Navigation – Contextual Menu.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/menu-button/KrakenDSM_Components_MenuButton_05.png">
        
        <section>
            <div class="kn-subtitle-16">Use for navigation</div>

            <div class="text">The menu button is reserved for performing a specific function and should not be utilized as a mechanism for navigating to an alternate feature area of the application experience.</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/menu-button/KrakenDSM_Components_MenuButton_06.png">

        <section>
            <div class="kn-subtitle-16">Custom button colors</div>

            <div class="text">Align with the standard button styling guidelines when displaying the menu button in the application UI. Avoid introducing alternate button colors that may confuse the application User and increase their cognitive load in assigning meaning to the new color value.</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/menu-button/KrakenDSM_Components_MenuButton_07.png">

        <section>
            <div class="kn-subtitle-16">Wrapping labels</div>

            <div class="text">Labels displayed within the presentation of menu buttons should be limited to a single line and not be allowed to wrap to a second line of text.</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/menu-button/KrakenDSM_Components_MenuButton_08.png">
        
        <section>
            <div class="kn-subtitle-16">Icons that do not reinforce action</div>
            
            <div class="text">Only use an icon within a menu button if it substantially reinforces the explanation of the purpose of the trigger invoked by clicking or tapping the button, and if the inclusion of the icon is proven (through user testing) to draw the necessary attention of the user to the presence of the action button in the interface layout. Avoid using of icons in split buttons for aesthetic purposes only or icons that do not intuitively reinforce the action of the button. Never use more than one icon in a split button.</div>
        </section>
    </div>
</div>