import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-design-principles',
  templateUrl: './design-principles.component.html',
  styleUrls: ['./design-principles.component.sass']
})
export class DesignPrinciplesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
