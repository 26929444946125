import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'tabs-usage',
  templateUrl: './tabs-usage.component.html',
  styleUrls: ['./tabs-usage.component.sass']
})
export class TabsUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
