import { Component } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';
@Component({
  selector: 'button-group-accessibility',
  templateUrl: './button-group-accessibility.component.html',
  styleUrls: ['./button-group-accessibility.component.sass']
})
export class ButtonGroupAccessibilityComponent {
  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;
  constructor(private templateService: TemplateUpdateService) { }
}
