<div class="canvas-inner-container">
    <div class="section-container">
        <section>
        <div class="text">Buttons allow users to perform an action or to navigate to another page. They have multiple styles for various needs, and are ideal for calling attention to whether the user needs to do something to move forward in a flow. Buttons are used primarily for actions, whereas links should be used as navigational elements that are secondary within the interface, such as within body copy.</div>
        </section>
       
        <section>
        <div class="label"><span class="kn-bold">Buttons are commonly used in: </span></div>
        
        <ul class="bullets">
            <li>Forms</li>
            <li>Interactive cards</li>
            <li>Modal windows</li>
            <li>Task bars and toolbars within applications</li>
        </ul>
        </section>

        <section>
            <div class="kn-headline-24-med">Types</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_19.png">
        
        <section>
            <div class="text">Action buttons should always have a label, unless they are only using an icon that is universally understood and accessible. Use an icon only when necessary and when it has a strong association with the label text.</div>
        </section>

        <section>
            <div class="text">The label can be hidden to create an icon-only action button. If the label is hidden, an icon is required, and the label will appear in a tooltip on hover.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Label </span> </div>
            <div class="text"><span class="kn-bold">2.	With label and icon </span> </div>
            <div class="text"><span class="kn-bold">3.	With icon and label hidden </span> </div>
        </section>

        <section>
            <div class="kn-headline-24-med">States</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_01.png">
        
        <section>
            <div class="text"><span class="kn-bold">1. Primary: </span> (high emphasis) Used as a call-to-action within the interface layout, drawing user attention to the trigger used to invoke a key action in the primary path of the application user flow.</div>
            <div class="text"><span class="kn-bold">2. Secondary: </span> (medium emphasis) The secondary action button is less pronounced, visually identifying a trigger used to invoke an action associated with an alternative path of the application user flow.</div>
            <div class="text"><span class="kn-bold">3. Tertiary: </span> (medium emphasis) The tertiary action button is subtle in presentation, visually more prominent than a flat button yet less substantial than a primary or secondary action button.</div>
            <div class="text"><span class="kn-bold">4. Flat: </span> (low emphasis) The flat button is a minimalistic control often used to trigger a utility function (accompanied by an icon) or back out of task workflow.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_02.png">

        <section>
            <div class="text"><span class="kn-bold">5. Error: </span> Actions that could have destructive effects on the user's data.</div>
            <div class="text"><span class="kn-bold">6. Warning: </span> Warning button displays to the user to take action, usually in anticipation of a significant change.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_03.png">

        <section>
            <div class="text"><span class="kn-bold">A. Text label </span></div>
            <div class="text"><span class="kn-bold">B. Container </span></div>
            <div class="text"><span class="kn-bold">C. Leading icon </span></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Spinner</div>
        </section>
        
        <section>
            <div class="text">A spinner can be integrated into a button to express a connection between an interaction and a specific item. They are typically used to express when an interaction, such as clicking a button again, isn't available. Reference the spinner component for complete usage guidelines. </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_04.png">


        <section>
            <div class="text"><span class="kn-bold">1. With label and icon </span> </div>
        <ul class="no-bullets">
            <li><span class="kn-bold">A. Leading icon </span></li>
            <li><span class="kn-bold">B. Spinner: </span> The spinner is displayed in place of the leading icon.</li>
        </ul>
        </section>    
            
        <section>
            <div class="text"><span class="kn-bold">2. Label </span> </div>
        <ul class="no-bullets">
            <li><span class="kn-bold">A. Spinner: </span> Text label is replaced with the spinner while the container maintains the width that it would have if the text label were visible.</li>
        </ul>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_05.png">


        <section>
            <div class="text"><span class="kn-bold">1. Dark background </span> </div>
            <div class="text"><span class="kn-bold">2. Light background </span> </div>
        </section>

        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Text label</div>
            <div class="text">A button's text label is the most important element on a button, as it communicates the action that will be performed when the user interacts with it. Button labels should clearly indicate the action of the button (ex. Download, Sign Up).</div>

            <ul class="bullets">
                <li>Labels should be single words or succinct phrases that do not exceed 25-characters to support quick scanning and interpretation</li>
                <li>Don't use punctuation marks such as periods or exclamation points</li>
                <li>Button label text should always be uppercase</li>
            </ul>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_06.png">

        <section>
            <div class="kn-subtitle-16">Icons</div>
            <div class="text">Icons may be used in combination with text labels within action buttons to further reinforce the purpose of the button and the action triggered by interacting with it.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_07.png">

        <section>
            <div class="kn-subtitle-16">Size</div>
            <div class="text">Buttons come in two different sizes: default and small. The small size is to be used sparingly as a means to create a hierarchy of importance within the page.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Default button height: 36px </span> </div>
            <div class="text"><span class="kn-bold">2.	Small button height: 28px </span> </div>
        </section>


        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_08.png">

        <section>
            <div class="kn-subtitle-16">Minimum/Maximum sizing</div>
            <div class="text">Button width is variable based on the length of the label (with or without an icon) with the following guidelines:</div>
            <ul class="bullets">
                <li>Regardless of how few characters are used in the button label, minimum button width is 132px</li>
                <li>Button width scales with the length of the button label, retaining a consistent 16px padding with the vertical boundaries of the button. A button’s width shouldn’t be narrower than its label text.</li>
            </ul>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1. Default button minimum width: 132px, Default button maximum width: 244px (25 characters) </span> </div>
            <div class="text"><span class="kn-bold">2. Small button minimum width: 108px, Small button maximum width: 206px (25 characters) </span> </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Container</div>
            <div class="text">Button containers hold the text label and icon and are displayed with a 4px rounded corner.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Hierarchy</div>
            <div class="text">It’s important to have a clear strategy regarding the different triggers presented within the application layout screen and how each maps to a primary, alternate (secondary), or supportive (tertiary) path or function within the application user flow. Working from this strategy, align the appropriate execution of the action button to accurately reflect this hierarchy.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_09.png">


        <section>
            <div class="kn-bold">Single, high-emphasis button</div>
            <div class="text">A layout should contain a single high-emphasis button that makes it clear that other buttons have less importance in the hierarchy. This high-emphasis button commands the most attention.</div>
        </section>

        <section>
            <div class="kn-bold">Multiple button emphasis</div>
            <div class="text">Primary, secondary, and tertiary action buttons may be used in combination within a single layout. When combined, they should reflect the hierarchy of user actions that support primary and alternate paths in the application user flow.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Position</div>
            <div class="text">In general, action buttons should be positioned directly below or to the right of the fields, data, or content displayed in the UI that they directly impact. When used in combination, the primary action button should be positioned furthest to the right with successive positioning to the left in order of hierarchy (secondary, then tertiary).</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_10.png">

        <section>
            <div class="text"><span class="kn-bold">1. Page-based buttons </span> </div>
        <ul class="no-bullets">
            <li><span class="kn-bold">A. </span>Displayed in the top-left of the page under the page title with the primary action label is for the first (left-most) button.</li>
        </ul>
        </section>    
            
        <section>
            <div class="text"><span class="kn-bold">2. Grid-based buttons </span> </div>
        <ul class="no-bullets">    
            <li><span class="kn-bold">A. </span>Grid actions: For some specific grids (Imports, Processing, Exports, Custodians), additional grid-specific actions are available. These buttons are displayed above the grid controls, with the primary action label positioned to the furthest right.</li>
            <li><span class="kn-bold">B. </span>Grid controls: Grid controls range from showing and hiding columns, refreshing the data grid and clearing sort/filtering. They are displayed to the top-right of the grid and are associated with the flat button.</li>
        </ul>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_11.png">

        <section>
            <div class="text"><span class="kn-bold">3. Panel-based buttons </span> </div>
        <ul class="no-bullets">    
            <li><span class="kn-bold">A. </span>Displayed at the bottom of the panel. Panels display a maximum of two buttons and are split horizontally with a container that adapts in width. The icon and text label within the button remain centered despite the adapted container dimensions. The primary action label is positioned to the furthest right.</li>
        </ul>
        </section>

        <section>
            <div class="text"><span class="kn-bold">4. Modal-based buttons </span> </div>
        <ul class="no-bullets">    
            <li><span class="kn-bold">A. </span>Displayed at the bottom-right, all dialogs must display at least one button. The primary action label is for the first (right-most) button. It communicates what the button will do with a short, actionable phrase to either describe the next step if selected, or to acknowledge and dismiss the dialog.</li>
        </ul>
        </section>

        <section>
            <div class="kn-subtitle-16">Ordering</div>
            <div class="text">When using multiple buttons such as in the case of dialogs and modal windows, call to action buttons are horizontally positioned with the primary action button right-aligned and positioned to the right of the secondary and tertiary buttons.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_12.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Default actions </span> </div>
        <ul class="no-bullets">    
            <li><span class="kn-bold">A. Primary button: </span>The primary button is positioned to the far right.</li>
            <li><span class="kn-bold">B. Secondary button: </span>If an additional default action is required, the button should be displayed as a secondary action button.</li>
            <li><span class="kn-bold">C. Flat button: </span>Flat action buttons are reserved for destructive actions. Destructive actions are to be displayed on the left of all default actions.</li>
        </ul>
        </section>    
            
        <section>
            <div class="text"><span class="kn-bold">2.	Dismissive action </span> </div>
        <ul class="no-bullets">   
            <li><span class="kn-bold">D. Tertiary button: </span>The dismissive action is positioned on the far left. Dismissive actions return the user to the previous state.</li>
        </ul>
        </section>

        <section>
            <div class="kn-subtitle-16">Padding</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_13.png">

        <section>
            <div class="text"><span class="kn-bold">1. Default buttons maintain a 16px distance when displaying multiple </span> </div>
            <div class="text"><span class="kn-bold">2. Small buttons maintain a 14px distance when displaying multiple</span> </div>
        </section>

        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_14.png">

        <section>
            <div class="kn-subtitle-16">Custom button colors</div>
            <div class="text">For consistency in the presentation of the application UI and underlying visual language it represents, use only the button colors reflected in this design system.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_15.png">

        <section>
            <div class="kn-subtitle-16">Overuse of primary actions</div>
            <div class="text">Limit the use of primary action buttons to only those associated with supporting the primary path of the user flow of the application. Too many primary action buttons displayed in the same interface layout diminishes the visual emphasis of the button as well as making it more difficult for the user to scan the layout.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_16.png">

        <section>
            <div class="kn-subtitle-16">Multiples of the same button hierarchy in a group</div>
            <div class="text">When combining action buttons, avoid using each button hierarchy (primary, secondary, tertiary) more than once within each grouping.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_17.png">


        <section>
            <div class="kn-subtitle-16">Wrapping labels</div>
            <div class="text">Labels displayed within the presentation of action buttons should be limited to a single line and not be allowed to wrap to a second line of text.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/action-buttons/KrakenDSM_Components_ActionButtons_18.png">
        
        <section>
            <div class="kn-subtitle-16">Icons that do not reinforce action</div>
            <div class="text">Only use an icon within an action button if it substantially reinforces the explanation of the purpose of the trigger invoked by clicking or tapping the button, and if the inclusion of the icon is proven (through user testing) to draw the necessary attention of the user to the presence of the action button in the interface layout. Avoid using icons in action buttons for aesthetic purposes only or that do not intuitively reinforce the action of the button. Never use more than one icon in a button.</div>
        </section>
    </div>
</div>