<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">The <span class="kn-bold">Data Grid</span> component contains a set of data that is structured in rows and columns, which allows users to scroll a large amount of items and columns in both directions.</div>
            </section>
 
        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <section>
            <div class="text">A data grid is displayed with two main sections: the grid container and the outer container. The grid container consists of the data grid with an outer border while the outer container includes all grid-related functionality including the grid controls, grid utility actions, alert banners, and tabs navigation.</div>       
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_01.png">
        
        <section>
            <div class="kn-subtitle-16">Outer Container</div> 
            <div class="text"><span class="kn-bold">A.	Tabs navigation:</span> Used to navigate between multiple groups of related content.</div>
            <div class="text"><span class="kn-bold">B.	Alert banner:</span> Alert banners show pressing and time-related messages for importing files and updating Brainspace. They are meant to be noticed and prompt users to take action.</div>
            <div class="text"><span class="kn-bold">C.	Grid Controls:</span> The data grid's global utility actions, allowing users to interact with the data by performing various actions.</div>
            <div class="text"><span class="kn-bold">D.	Grid utility actions</span></div>       
        </section>  
        
        <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_02.png">

        <section>
            <div class="kn-subtitle-16">Grid Container</div>
            <div class="text"><span class="kn-bold">A.	Column Header:</span> Resizes and rearranges columns</div>
            <div class="text"><span class="kn-bold">B.	Header label:</span> Indicates the type of information that is in each column</div>
            <div class="text"><span class="kn-bold">C.	Filter icon button:</span> Add or removes data items from a displayed data set by turning on and off certain predefined attributes</div>
            <div class="text"><span class="kn-bold">D.	Sort icon button:</span> Displays whether information is set to ascending (up arrow) or descending (down arrow)</div>
            <div class="text"><span class="kn-bold">E.	Column divider</span></div>
            <div class="text"><span class="kn-bold">F.	Row:</span> A collection of items, or rows that occupy the main part of the data grid</div>
            <div class="text"><span class="kn-bold">G.	Row cell:</span> The body text or input field, which can contain alphanumeric text or hyperlinks</div>
            <div class="text"><span class="kn-bold">H.	Row divider</span></div>
            <div class="text"><span class="kn-bold">I.	Pagination</span> Indicates the amount of data presented in the grid</div>
        </section>

        <section>
            <div class="kn-headline-24-med">General Guidelines</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Columns and rows</div> 
            <div class="text">The data grid component can configure the number of individual columns and rows to display that comprise the table and layout and contain the structured content.</div>       
        </section>
        
        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_03.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Size variations</div> 
            <div class="text">Data grids are displayed at two fixed size heights: default and large. The default outer container height is <span class="kn-bold">636px</span>, while the large container height is <span class="kn-bold">740px</span>. The grid container is also displayed at a fixed height of <span class="kn-bold">580px</span> (default density) and <span class="kn-bold">440px</span> (compressed density), where in which the only difference between the default and large grid outer containers is whether additional grid-related functionality is displayed.</div>       
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Default grid outer container</span></div>
            <div class="text"><span class="kn-bold">2.	Large grid outer container</span></div>       
        </section> 
        
        <section>
            <div class="kn-subtitle-16">Borders</div> 
            <div class="text">Two borders are displayed in the data grid component: the grid container and the outer container. The grid container frames the data grid, while the outer container serves as a frame for both.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_04.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Density</div> 
            <div class="text">Data Grid rows are are displayed in two different densities: default and compressed. The Row height is determined by the selected density. The default row height is <span class="kn-bold">48px</span>, while the compressed row height is <span class="kn-bold">34px</span>.</div>       
        </section>

        <section>
            <div class="text">Density does not affect column width. Column width is defined in the grid’s configuration, and can be adjusted per column.</div>       
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Default:</span> Used in most cases.</div>
            <div class="text"><span class="kn-bold">2.	Compressed:</span> Use the compressed size when large amounts of information are displayed. Reducing the space between content can help users easily scan and compare information.</div>       
        </section> 

        <section>
            <div class="kn-subtitle-16">Minimum data grid width</div> 
            <div class="text">The minimum width for the data grid outer container is <span class="kn-bold">1024px</span>.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_05.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Content alignment</div> 
            <div class="text">All cells are left-aligned with the column header name.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_06.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Scrolling</div> 
            <div class="text"><span class="kn-bold">A.	Vertical scrolling:</span> Located on the far right of the data grid, the vertical scroll bar allows users to move the data grid viewable area up or down to display additional content.</div>
            <div class="text"><span class="kn-bold">B.	Horizontal scrolling:</span> A grid that contains more columns than can be displayed within the available width for the grid can scroll horizontally.</div>      
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_07.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Corner radius</div> 
            <div class="text">Data grids have a rectangular shape by default, with <span class="kn-bold">4px</span> rounded corners around both the outer container and grid container.</div>       
        </section>

        <section>
            <div class="kn-subtitle-16">Elevation</div> 
            <div class="text">Data grids don't express elevation as they do not move in front of or behind other surfaces.</div>       
        </section>


        <section>
            <div class="kn-headline-24-med">Columns</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Column width</div> 
            <div class="text">Data grids were designed to optimally display all crucial data at the width of <span class="kn-bold">1440px</span> display resolution. Secondary columns are hidden behind the column settings, which helps to maintain a concise grid that does not lose its purpose and legibility.</div>       
        </section>

        <section>
            <div class="text">The width of each column of the data grid is a factor of the number of columns configured for the grid and the width constraints of the panel that contains it. By default, column widths are divided equally within these constraints. Individual column widths can also differ depending on if a custom width is set. If all columns have a set width, then the grid can no longer automatically adapt to the browser space. Consider a minimum of two columns can have a custom width set.</div>       
        </section>

        <section> 
            <div class="text">Keep responsive design in mind when determining how many columns are optimally displayed.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_08.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Non-set width columns</span></div>
            <div class="text">All columns widths are divided equally within the panel that contains them.</div>      
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_09.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Custom width column</span></div>
            <div class="text">Individual column widths differ based on the longest length of content.</div>
        </section>
    
        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_10.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Resizing column width</div> 
            <div class="text">To resize a column, hover the cursor on the column header over the column divider to display the resize cursor. With a click and drag, the column can either be minimized or expanded in size.</div>       
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Resize cursor</span></div>
            <div class="text"><span class="kn-bold">B.	Column divider line</span></div>      
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_11.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Maximum/Minimum column width</div> 
            <div class="text"><span class="kn-bold">1.	Default column width:</span> Suggested range between 180px and 250px</div>
            <div class="text"><span class="kn-bold">2.	Maximum column width:</span> 1368px</div>
            <div class="text"><span class="kn-bold">3.	Minimum column width:</span> 40px</div>   
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_12.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Inline cell actions</div> 
            <div class="text">In some cases, the custom width of columns is determined by the inline cell actions displayed within the cell row. These columns cannot be resized beyond the minimum column width to ensure optimal viewing and interaction of the component.</div>       
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Checkbox:</span> Column width 60px</div>
            <div class="text"><span class="kn-bold">B.	Single-select dropdown:</span> Column width 222px</div>
            <div class="text"><span class="kn-bold">C.	More button:</span> Column width 118px</div>
            <div class="text"><span class="kn-bold">D.	Processing settings:</span> Column width 258px</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_13.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Column header</div> 
            <div class="text">Column headers label and define the type of content to be displayed in the respective column. Headers should be clear, concise and descriptive.</div>       
        </section>

        <section>
            <ul class="bullets">
                <li>Column header label text should always be uppercase</li>
                <li>Don't leave a column header cell blank.</li>
            </ul>
        </section>

        <section>
            <div class="text"><span class="kn-bold">1.	Single-line column header</span></div>
            <div class="text"><span class="kn-bold">2.	Two-line column header</span></div>    
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_14.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Grouped columns</div> 
            <div class="text">Data grid column headers can be formatted to display complex structured data. Headers support the grouping of one or more columns under each stacked header.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_15.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Padding</div> 
            <div class="text">A <span class="kn-bold">20px</span> padding is included on the left side of each header name, and a <span class="kn-bold">10px</span> padding is included on the right side of each filter/sort icon button.</div>       
        </section>

        <section>
            <div class="kn-headline-24-med">Column settings</div> 
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_16.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Filtering</div> 
            <div class="text">Filtering allows users to add or remove data items from a displayed data set by turning on and off certain predefined attributes. The column header can provide a search box, which limits the displayed results based on what a user types. Multiple column filters may be applied to a data grid at the same time.</div>       
        </section>

        <section>
            <div class="text"><span class="kn-bold">Pre-filtered rows</span></div>
            <div class="text"><span class="kn-bold">A.	Filter icon button (Hover state):</span> The filter menu is triggered by the filter icon button</div>  
        </section>

        <section>
            <div class="text"><span class="kn-bold">Filter menu</span> When the filter icon button is triggered, a filter menu prompts the user to perform action which consists of a typeahead search box in addition to a column-specific multi-select list. The available filter options may vary depending on the type of data in the column. When the filter menu is opened, all available filter options are selected on default, which is reflected in the selection of the "Select all" menu option. When the "select all" checkbox is subsequently unselected, all filter options are deselected and the "Filter" button is disabled.</div> 
        </section> 
        
        <section>        
            <div class="text">Once filter options have been selected and the "Filter" button applied, the data grid refreshes to show only the rows that match the selected criteria. Selection of the "Clear" button resets the filter menu to its default state.</div>
        </section>
    
        <section>
            <div class="text"><span class="kn-bold">B.	Search box:</span> The input text is entered by the user. The input text is displayed inside the search box.</div>
            <div class="text"><span class="kn-bold">C.	Filtered menu options</span> The options that start to match your entry remain in the list while other existing options are temporarily removed.</div>    
        </section>

        <section>
            <div class="text"><span class="kn-bold">Filtered rows</span></div>
            <div class="text"><span class="kn-bold">D.	Filter icon button (Filtered):</span> When a filter has been applied, the filter icon button displays an indication.</div>
            <div class="text"><span class="kn-bold">E.	Clear all button:</span> After applying sorting/filtering, users can select the "Clear all button" to revert the data grid back to the original data set.</div>    
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_18.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Sorting</div> 
            <div class="text">Clicking on a column header or sort icon sorts the grid based on the data within (alphabetical, numeric, date, etc.). Clicking again reverses the sort (ascending or descending). To indicate which column is sorted by default, a downward arrow icon is displayed right-aligned of the column header.</div>       
        </section>

        <section>
            <div class="text"><span class="kn-bold">Pre-sorted rows</span></div>
            <div class="text"><span class="kn-bold">A.	Sort icon button (Hover state)</span></div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Sorted rows</span></div>
            <div class="text"><span class="kn-bold">B.	Sort icon button (Sorted)</span></div>
            <div class="text"><span class="kn-bold">C.	Clear all button</span></div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_17.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Sticky header</div> 
            <div class="text">Data grids are displayed with a sticky header. Sticky headers clarify each column’s context when a grid consists of more rows than can be displayed within a fixed height and when vertical scrolling is required to view the entire dataset.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_19.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Re-arranging columns</div> 
        </section>

        <section>
            <div class="text"><span class="kn-bold">Column header grab</span></div>
            <div class="text"><span class="kn-bold">A.	Column header:</span> The background color is lightened when a column header is grabbed.</div>
            <div class="text"><span class="kn-bold">B.	Column highlight:</span> The column background is highlighted with a gray color.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Column grab re-arrange</span></div>
            <div class="text"><span class="kn-bold">C.	Column header:</span> A column header background remains highlighted during interstitial state.</div>
            <div class="text"><span class="kn-bold">D.	Column highlight:</span> The movement of a column highlight follows in parallel with a grab.</div>
            <div class="text"><span class="kn-bold">E.	Column divider line</span></div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Column re-arranged</span></div>
            <div class="text"><span class="kn-bold">F.	Column:</span> A column re-arranged in data grid.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_20.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Pinned columns</div> 
            <div class="text">In some cases, a column can be pinned to the right-side of the grid. Pinned columns and all columns that precede a pinned column remain in view within the defined data grid width.</div>       
        </section>


        <section>
            <div class="kn-headline-24-med">Rows</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_21.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Behavior</div> 
            <div class="text"><span class="kn-bold">1. Row hover:</span> A light blue background is displayed when users hover over a row.</div>
            <div class="text"><span class="kn-bold">2. Row added:</span> When one or more rows are added, those rows are displayed with a light green background color. Rows are displayed in association with toast notifications which appear at the top center of the page, and the added state automatically fades out after a short duration of <span class="kn-bold">5 seconds</span> on the screen.</div>
        </section>



        <section>
            <div class="kn-subtitle-16">Selection</div> 
        </section>

        <section>
            <div class="text"><span class="kn-bold">Selectable rows</span></div>
            <div class="text">Some grids allow users to select rows, from which they can take an action. If a grid has selectable rows, ensure that checkboxes are displayed on the left side of the grid. Additionally, include a master checkbox in the grid header to allow users to quickly select or deselect all of the rows within the grid. When the “select all” master checkbox is selected, all the row checkboxes are subsequently selected and the master checkbox is displayed within a selected state. When the selected master checkbox is reselected, all rows are unselected and the master checkbox is reverted back to it's default enabled not selected state. When one or more of the set checkboxes are deselected then the state of the master checkbox cannot be determined and displays the indeterminate state.</div>       
        </section>

        <section>
            <div class="text">When a row checkbox is selected, the row does not display a background color beyond the hover and added states.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_23.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Cell data</div> 
        </section>

        <section>
            <div class="text">The data grid is flexible: it can display a range of data and or content types pulled from the data source(s) to support the specific business requirements for application use cases. Supported cell data includes:</div>       
        </section>

        <section>
            <div class="text"><span class="kn-bold">Text:</span> Truncated text prompts a tooltip which displays the full row text.</div>
            <div class="text"><span class="kn-bold">Hyperlink:</span> Links allow users to navigate to a different location. The hyperlink is used to call attention to the link.</div>
            <div class="text"><span class="kn-bold">Numeric figures:</span> Numerical data is displayed left-aligned.</div>
            <div class="text"><span class="kn-bold">Icons</span> Iconographic data is displayed center-aligned.</div>
            <div class="text"><span class="kn-bold">Inline input data</span> Inline input-based components are displayed center-aligned.</div>
            <div class="text"><span class="kn-bold">Badges</span> Badge data is displayed left-aligned.</div>
            <div class="text"><span class="kn-bold">Buttons</span> Button components are displayed center-aligned.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Grid status</div> 
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_24.png">
        </section>

        <section>
            <div class="text">A grid can also indicate status via a status indicator that appears within the grid rows. Status indicators can help users identify which rows might require special attention.</div>       
        </section>

        <section>
            <div class="text"><span class="kn-bold">Text-based</span></div>
            <div class="text"><span class="kn-bold">1. Inactive status text</span></div>
            <div class="text"><span class="kn-bold">2. "Creating" text</span></div>
            <div class="text"><span class="kn-bold">3. "Never" text</span></div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Icon/badge-based</span></div>
            <div class="text"><span class="kn-bold">4. Failed</span></div>
            <div class="text"><span class="kn-bold">5. Waiting for retry</span></div>
            <div class="text"><span class="kn-bold">6. Processing status</span></div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_25.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Nesting rows</span></div>
            <div class="text">Where there is a parent/child relationship in the rows of a data set, the data grid can support nesting the child rows under the parent row. Parent rows are collapsed by default and can be expanded to reveal the child rows by clicking the carrot positioned on the far-left column. Child rows fill the width of the grid, and the expanded rows content can be further accessed by scrolling (if necessary).</div>       
        </section>

        <section>
            <ul class="bullets">
                <li>Such as parent rows, child rows may display a range of data and or content types pulled from the data source(s) including text, hyperlinks, and icons. </li>
                <li>The more button inline cell action is reserved for only parent rows.</li>
                <li>Filter and sort functionality does not apply to child rows.</li>
            </ul>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_26.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Truncation</span></div>
            <div class="text">Text that is longer than the column width is truncated with ellipsis. On hover, a tooltip displays the full row text. When columns are resized, any text with ellipses maintains a <span class="kn-bold">16px</span> padding on the right side between columns. Text-based data displayed in full within a cell will not prompt a tooltip on hover.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_27.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Respect vertical alignment</span></div>
            <div class="text">Vertically center all content within the row to offer the right visual balance.</div>       
        </section>

        <section>
            <div class="text"><span class="kn-bold">Representing null values</span></div>
            <div class="text">When a data grid cell is evaluated as a NULL value, it is represented as "- -" instead of displaying a blank field.</div>       
        </section>


        <section>
            <div class="kn-headline-24-med">Pagination</div>
        </section>

        <section>
            <div class="text">Pagination serves as a navigation tool within an application feature or content display that enables users to step through a sequential list of available pages or result sets in either an increasing or decreasing progression. Pagination is also used to skip past numerous pages at a time. It is placed at the bottom of a data grid.</div>       
        </section>

        <section>
            <div class="text">Reference the icon button component for pagination usage guidelines.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_28.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Pagination controls</div>
        </section>
            
        <section>
            <div class="text"><span class="kn-bold">A. First page button:</span> Takes the user back to the first page.</div>
            <div class="text"><span class="kn-bold">B. Previous button:</span> Takes the user to the previous page.</div>
            <div class="text"><span class="kn-bold">C. Enabled selected</span></div>
            <div class="text"><span class="kn-bold">D. Enabled unselected</span></div>
            <div class="text"><span class="kn-bold">E. Ellipses:</span> Indicates that numbers are not in view between the selected page group and last page. Does not invoke any action upon hover state.</div>
            <div class="text"><span class="kn-bold">F. Next button:</span> Takes the user to the next page.</div>
            <div class="text"><span class="kn-bold">G. Last page button:</span> Takes the user to the last page.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Items per page</span></div>
            <div class="text"><span class="kn-bold">H. Capacity control:</span> Determines the number of items per page via the single-select dropdown.</div>
            <div class="text"><span class="kn-bold">I. Items per page count:</span> Shows the number of pages based on the capacity of items per page.</div>
            <div class="text"><span class="kn-bold">J. Display count</span> Describes what range of items are in view up to the total.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Capacity control menu</span></div>
            <div class="text">Shows a predefined list of options for the quantity of rows to display (25, 50, 100, 150, 200, 250, 500).</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_29.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Pagination control states</div> 
        </section>

        <section>
            <div class="text"><span class="kn-bold">1. Disabled</span> Displays as disabled when the data grid experiences an error loading data.</div>
            <div class="text"><span class="kn-bold">2. Single page enabled</span> Displays as disabled when all data is within view based upon the items per page count.</div>
            <div class="text"><span class="kn-bold">3. Up to four pages enabled</span> When there are four or fewer pages in the pagination range, all page numbers are displayed. It is not recommended to display over four pages in the pagination range.</div>
            <div class="text"><span class="kn-bold">4. More than four pages enabled</span> When the user has selected the first page in the pagination range, the first four pages of the range are displayed followed by ellipses.</div>
            <div class="text"><span class="kn-bold">5. Truncation first page enabled</span> When the user has selected the last page in the pagination range, the last four pages of the range are displayed preceded by ellipses.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Grid controls</div>
        </section>

        <section>
            <div class="text">Grid controls are the data grid's global utility actions, allowing users to interact with the data by performing various actions. Grid controls consist of two main sections: primary controls and secondary controls. The primary controls are reserved for primary actions ranging from showing and hiding columns, clearing sort/filtering and exporting data from the data grid. Secondary controls are defined by data grid use cases and needed user tasks which include refreshing the data grid, assigning bulk actions and single/bulk row deletion.</div>       
        </section>

        <section>
            <div class="text">Reference the actions button component for grid control usage guidelines.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_30.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Primary controls</div>
            <div class="text"><span class="kn-bold">A. Clear all button:</span> After applying sorting/filtering, users can select the "Clear all button" to revert the data grid back to the original data set.</div>
            <div class="text"><span class="kn-bold">B. Export button:</span> Users can export the data in an Excel file format. When selected, an xlsx file of the data set is automatically downloaded.</div>
            <div class="text"><span class="kn-bold">C. Column settings button:</span> Enables users to configure data grid column formatting via the column settings menu.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Secondary controls</div>
            <div class="text"><span class="kn-bold">D. Refresh button:</span> Triggers an update of the data displayed in the data grid.</div>
            <div class="text"><span class="kn-bold">E. Assign button: </span> Reserved for bulk actions such as assigning a custodian to multiple imports at once.</div>
            <div class="text"><span class="kn-bold">F. Delete button:</span> To delete single or multiple item rows, use the data grid delete button. The delete functionality is available only for multiple-selection data grids. In this case, the delete button is enabled when the user selects a row(s) via a checkbox.</div>
            <div class="text"><span class="kn-bold">G. Divider line:</span> Used to differentiate between the primary and secondary controls.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_37.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Refresh</div> 
            <div class="text">Use refresh as the action to update the data displayed in the data grid.</div>       
        </section>

        <section>
            <div class="text"><span class="kn-bold">A. Refresh button hover:</span></div>
            <div class="text"><span class="kn-bold">B. Scrim: </span> A scrim is a temporary treatment that can be applied for the purpose of making content on the surface appear less prominent.</div>
            <div class="text"><span class="kn-bold">C. Progress circle:</span> Progress circles show the progression of a system operation in a visual way.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_36.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Column settings menu</div> 
            <div class="text">The column settings menu enables users to configure data grid column formatting. Crucial data columns are optimally displayed in the data grid and are reflected with a selection in the column settings menu. Secondary columns, however are hidden only in the column settings menu and are deselected by default.</div>       
        </section>

        <section>
            <div class="kn-headline-24-med">Grid banners</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_31.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Alert banners</div> 
            <div class="text">Alert banners show pressing and time related messages related to importing files and updating Brainspace. Their purpose is to be noticed and prompt users to take action.</div>       
        </section>

        <section>
            <div class="text"><span class="kn-bold">1. Aspera Import</span></div>
            <div class="text"><span class="kn-bold">2. SFTP Import</span></div>
            <div class="text"><span class="kn-bold">3. e-Billing ID Missing</span></div>
            <div class="text"><span class="kn-bold">4. Update Brainspace</span></div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_32.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Stacking grid banners</div> 
            <div class="text">Multiple import alert banners can be displayed at the same time. New banners appear below in progress /non-dismissed banners. Import alert banners can be intermixed between Aspera/ Client Aspera and SFTP Import when displayed above the data grid.</div>       
        </section>


        <section>
            <div class="kn-headline-24-med">Zero state</div>
        </section>

        <section>
            <div class="text">Zero states are moments in the product where no data is displayed. They are most commonly seen the first time users interact with a data grid.</div>       
        </section>

        <section>
            <div class="text">With just enough contextual guidance, zero states ensure a smooth of experience by communicating what the user sees when they have data, while providing constructive guidance about next steps.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_33.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">A. Data grid outer container</span></div>
            <div class="text"><span class="kn-bold">B. Body:</span> Descriptions briefly communicate the context of why the space is empty and what users need to know to take the next step.</div>
            <div class="text"><span class="kn-bold">C. Emphasized body:</span> In some cases, emphasized text (displayed in bold and uppercase) directs users to a primary action button positioned underneath the copy.</div>
            <div class="text"><span class="kn-bold">D. Button:</span> The primary call to action referenced in the body copy.</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_34.png">
        </section>
        
        <section>
            <div class="kn-subtitle-16">Don't use zebra stripes</div> 
            <div class="text">Avoid using striped alternate rows of content with a darker color in data grids.</div>       
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/data-grid/KrakenDSM_Components_DataGrid_35.png">
        </section>

        <section>
            <div class="kn-subtitle-16">Row dividers</div> 
            <div class="text">Dividers lose their value when overused. To avoid creating unnecessary noise, vertical row dividers are not displayed.</div>       
        </section>
    </div>
</div>