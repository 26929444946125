<div 
    id="{{componentId}}"
    class="kn-button-group"
    [ngClass]="{
        'row': layout == 'row',
        'column': layout == 'column',
        'multi-select': selectionType == 'multi'
    }">
    <ng-content *ngIf="items.length == 0"></ng-content>
    <div class="kn-button-group-container" *ngIf="items.length > 0">
        <kn-button 
            *ngFor="let item of items"
            [buttonId]="item.buttonId" 
            [buttonType]="item.buttonType"  
            [buttonText]="item.buttonText"
            [buttonIconLeft]="item.buttonIcon">
        </kn-button>
    </div>
</div>