<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Spacing & Padding</div>
        
        <section>
            <div class="kn-headline-24-med">Spacing</div>
        </section>
        
        <section>
            <div class="text">Every part of a Lighthouse application UI was designed with intent. This includes the empty space between elements which is based upon on a set of rules, creating relationships and hierarchy on the page.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Creating relationships</span></div>

            <div class="text">Elements in a design that are near one another are seen as being meaningfully related. As more space is added between elements, their perceived relationship weakens. </div>
        </section>

        <section>
            <div class="text">Patterns created through spacing can also create relationships. Elements arranged in the same spacing pattern are seen as related pieces that have equal weight.</div>
        </section>
        
        <section>
            <div class="text">Space can also be used to denote groups of associated information. This creates content sections on a page without having to use lines or other graphical elements as a divider.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Heirarchy</span></div>

            <div class="text">Elements with more spacing around them tend to be perceived as more important than elements with less space around them. </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">White space</span></div>

            <div class="text">Empty space - also known as white space - is the area between design elements and is very vital in design. It can be used to break up sections on a page or to help create focus on specific elements. </div>
        </section>
        
        <section>       
            <div class="text"><span class="kn-bold">There are two types of spaces:</span></div>
            <div class="text"><span class="kn-bold">1. Macrospace</span> - the spaces between the main elements of a page and the space around each part.</div>
            <div class="text"><span class="kn-bold">2. Microspace</span> - the small gaps within an element - line spacing between characters, space between icons, etc.</div>
        </section>
        
        <section>
            <div class="text"><span class="kn-bold">Spacing scale</span></div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/spacing padding/KrakenDSM_StyleGuide_SpacingPadding_01.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/spacing padding/KrakenDSM_StyleGuide_SpacingPadding_02.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/spacing padding/KrakenDSM_StyleGuide_SpacingPadding_03.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/spacing padding/KrakenDSM_StyleGuide_SpacingPadding_04.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/spacing padding/KrakenDSM_StyleGuide_SpacingPadding_05.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/spacing padding/KrakenDSM_StyleGuide_SpacingPadding_06.png">
        </section>

        <section>
            <div class="text"><span class="kn-bold">Spacing - 01</span> - 3px</div>
            <div class="text"><span class="kn-bold">Spacing - 02</span> - 6px</div>
            <div class="text"><span class="kn-bold">Spacing - 03</span> - 8px</div>
            <div class="text"><span class="kn-bold">Spacing - 04</span> - 12px</div>
            <div class="text"><span class="kn-bold">Spacing - 05</span> - 20px</div>
            <div class="text"><span class="kn-bold">Spacing - 06</span> - 30px</div>
            <div class="text"><span class="kn-bold">Spacing - 07</span> - 48px</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Padding</div>
        </section>

        <section>
            <div class="text">Padding refers to the space between UI elements. Padding can be measured both vertically and horizontally and is measured in increments of 4px or 8px.</div>
        </section>
    </div>
</div>