import { Component, ElementRef, OnInit } from '@angular/core';
import { FontawesomeService } from '../../../services/fontawesome.service';
import { KrakenModalService } from 'kraken-modal';

@Component({
  selector: 'app-icon-catalog',
  templateUrl: './icon-catalog.component.html',
  styleUrls: ['./icon-catalog.component.sass']
})
export class IconCatalogComponent implements OnInit {
  public icons: any;
  public fields: object = { text: 'className', value: 'className'};
  public iconResults = [];
  public modalTitle: string;
  public iconTitle: string;
  public className: string;
  public type: string;
  public added: string;
  public updated: string;
  public unicode: string;
  public html = true;
  public size = 'kn-icon-sm';
  public iconCount: number;
  public tab = 'HTML';
  public isCopied = false;
  public copyText = 'Copy code snippet';

  constructor(
    public fontAwesomeService: FontawesomeService, 
    public modalService: KrakenModalService,
    public elm: ElementRef) { }

  ngOnInit(): void {
    this.icons = this.fontAwesomeService.iconDataSet();

    this.iconResults = this.icons;
    this.iconCount = this.iconResults.length;
  }

  onSelect() {
  }

  onKeyUp(event) {
    if(event.value) {
      this.iconResults = event.data;
    } else {
      this.iconResults = this.icons;
    }
    this.iconCount = this.iconResults.length;
  }

  clear(event) {
    this.iconResults = event;
    this.iconCount = this.iconResults.length;
  }

  showIconModal(icon) {
    this.modalTitle = icon.title;
    this.className = icon.className;
    this.iconTitle = icon.title;
    this.type = icon.type;
    this.updated = icon.updated;
    this.added = icon.added;
    this.size = 'kn-icon-sm';
    this.modalService.open('iconModal');
  }
  copy() {
    const size = this.size == undefined || this.size == '' ? '' : ' ' + this.size;
    const code = this.tab == 'HTML' ? '<i class="'+ this.className  + size +'"></i>' : '<FontAwesomeIcon icon="'+ this.className  + size +'"/>'
    navigator.clipboard.writeText(code);
    this.copyText = 'Copied!';
  }

  open() {
  }

  tabClick(event) {
    this.tab = event.title
  }

  close() {
    this.size = '';
    this.html = true;
  }

  resetCopyText() {
    this.copyText = 'Copy code snippet';
  }
}