import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';

@Component({
  selector: 'icon-button-accessibility',
  templateUrl: './icon-button-accessibility.component.html',
  styleUrls: ['./icon-button-accessibility.component.sass']
})
export class IconButtonAccessibilityComponent implements OnInit {
  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;
  
  constructor(private templateService: TemplateUpdateService) { }

  ngOnInit(): void {
  }

}
