import { Component } from '@angular/core';
import { HtmlFetcherService } from '../services/html-fetcher.service'; 

@Component({
  selector: 'app-analyzer',
  templateUrl: './analyzer.component.html',
  styleUrls: ['./analyzer.component.sass']
})
export class AnalyzerComponent {
  urlToScan: string = 'http://localhost:4200/#/analyzer';

  constructor(public htmlFetcherService: HtmlFetcherService) {}

  scanUrl() {
    this.htmlFetcherService.fetchHtml(this.urlToScan).subscribe(data => {
      console.log(data);
      // Process your data here
    }, error => {
      console.error('Error fetching URL:', error);
    });
  }
}
