

<button 
    id="{{buttonId}}" 
    class="kn-btn {{buttonType}}" 
    [ngClass]="{
        'kn-loading': loading, 
        'disabled': disabled,
        'active': active,
        'kn-icon-only': !buttonText && !loading && buttonIconLeft
    }"
    (click)="onClick()"
    [style.width]="width">
    <i *ngIf="buttonIconLeft && !loading" class="{{buttonIconLeft}} icon-left"></i>
    <i *ngIf="loading" class="icon-left"></i>
    <span class="btn-text" *ngIf="(buttonText && buttonIconLeft) || (buttonText && !buttonIconLeft && !loading)">{{buttonText}}</span>
</button>

