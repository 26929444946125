import { Component, OnInit } from '@angular/core';
import  packageInfo from '../../../../../kraken-spinner/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.sass']
})
export class SpinnerComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public angularComponentRef2;
  public webComponentRef;
  public elementTag = 'kn-spinner';
  public webElementTag = 'kn-spinner';
  public componentVersion = packageInfo.version;
  public isLoading = true;
  public size = 'medium';
  public theme = 'light';
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenSpinnerModule'
  };
  public angularComponentOptions: any = [
    { option: '[size]=', id: 2, value: '"\''+ this.size +'\'"'},
    { option: '[isLoading]=', id: 2, value: '"'+ this.isLoading +'"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 1, value: '"spinnerExample"' },
    { option: 'size=', id: 1, value: '"'+ this.size +'"' },
    { option: 'is-loading=', id: 2, value: '"'+ this.isLoading +'"'}
  ];

  public spinnerInstance = 
  `let spinner = document.querySelector('#spinnerExample');`;

  public spinnerLoading = 
  `spinner.isLoading = true;`

  constructor(public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }

  spinnerSize(event, size) {
    this.size = size;
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[size]='));
    this.angularComponentOptions[index].value = '"\'' + event.value +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'size='));
    this.webComponentOptions[webIndex].value = '"' + event.value + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }

  // spinnerTheme(event, theme) {
  //   this.theme = theme;
  //   //angular
  //   let index = this.angularComponentOptions.findIndex((obj => obj.option == '[theme]='));
  //   this.angularComponentOptions[index].value = '"' + event.value +'"';
  //   this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

  //   //web
  //   let webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'theme='));
  //   this.webComponentOptions[webIndex].value = '"' + event.value + '"';
  //   this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  // }


}
