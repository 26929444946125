import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'text-box-specs',
  templateUrl: './text-box-specs.component.html',
  styleUrls: ['./text-box-specs.component.sass']
})
export class TextBoxSpecsComponent implements OnInit {
  public component = window.location.protocol + '//' + window.location.host + '/design-specs-measure/textbox/';
  constructor() { }

  ngOnInit(): void {
  }

  clickEvent(route) {
    if(route == 'light') {
      window.open(this.component, "_blank");
    }
  }
}
