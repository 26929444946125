<app-canvas 
    [ts]="true"
    [js]="true"
    [isEventTabDisabled]="true" 
    [isPropertiesTabDisabled]="true" 
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef">
    <div title>Data Grid <span class="component-version">v1.1.2</span></div>
    <div component>
        <div class="component-group">
            <div class="kn-rim">
                <div class="kn-grid">
                    <kn-spinner [isLoading]="isLoading"></kn-spinner>
                    <div class="kn-grid-nav">
                        <div class="inner">
                            <div class="item">
                                <kn-button [buttonId]="'refreshGridBtn'" [buttonType]="'kn-flat kn-btn-sm'" (btnClick)="refreshGrid()" [buttonText]="'Refresh'" [buttonIconLeft]="'fa-regular fa-arrows-rotate'"></kn-button>
                            </div>
                            <div class="kn-pipe"></div>
                            <div class="item">
                                <kn-button [buttonId]="'clearAllBtn'" [buttonType]="'kn-flat kn-btn-sm'" (btnClick)="clearFilters()" [buttonText]="'Clear all'" [buttonIconLeft]="'fa-regular fa-filter-slash'" [disabled]="clearAllBtnDisabled"></kn-button>
                            </div>
                            <div class="item">
                                <kn-button [buttonId]="'columnChooser'" [active]="columnChooserActive" [buttonType]="'kn-flat kn-btn-sm'" [buttonText]="'Columns'" [buttonIconLeft]="'fa-regular fa-gear'"></kn-button>
                            </div>
                        </div>
                    </div>
                 
                    <ejs-grid 
                        *ngIf="gridType == 'standard' && !isLoading"
                        #grid
                        [dataSource]='data' 
                        height="480px"
                        [ngClass]="{'kn-compressed-grid': showCompressedGrid}"
                        allowSorting="true"
                        allowPaging="true" 
                        allowFiltering="true" 
                        showColumnChooser="true"
                        [filterSettings]="filterSettings" 
                        [pageSettings]='pageSettings'
                        (actionComplete)="actionComplete($event)"
                        allowResizing="true"
                        allowReordering="true"
                        [toolbar]='toolbarOptions'>
                        <e-columns>
                            <e-column field='FileName' minWidth="40" headerText='File name' width=160 clipMode="EllipsisWithTooltip"></e-column>
                            <e-column field='ImportDate' minWidth="40" headerText='Import date' width=200  clipMode="EllipsisWithTooltip"></e-column>
                            <e-column field='SourceSize' minWidth="40" headerText='Source size' width=150 clipMode="EllipsisWithTooltip"></e-column>
                            <e-column field='Custodian' width="200" headerText='Custodian'>
                                <ng-template #template let-data>
                                    <kn-dropdown 
                                        [items]="custodian" 
                                        [fields]="custodianFields" 
                                        [value]="data.Custodian"
                                        [compressed]="showCompressedGrid"
                                        [placeholder]="'Choose a custodian'" 
                                        [name]="'exampledropdown'"
                                        (onSelect)="selection($event)">
                                    </kn-dropdown>
                                </ng-template>
                            </e-column>
                            <e-column 
                                field="columnChooser"
                                width="130px"
                                [showInColumnChooser]="false"
                                [allowSorting]="false"
                                [allowFiltering]="false"
                                [allowReordering]="false"
                                freeze="Right"
                            >
                                <ng-template #headerTemplate let-data></ng-template>
                                <ng-template #template let-data>
                                    <div class="details-panel-btn">
                                        <kn-button 
                                            class="more-btn"
                                            [buttonId]="'details_' + data.id" 
                                            [buttonIconLeft]="'kic icon-ic-details-panel kn-icon-sm'"
                                            [buttonType]="'kn-flat kn-btn-sm'" 
                                            [buttonText]="'more'"
                                            (btnClick)="detailPanelEvent(data)">
                                        </kn-button>
                                    </div>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                    <kn-panel 
                        #detailPanel
                        [sliderContainerElm]="'.kn-grid'"
                        [panelWidth]="'350px'"
                        [panelType]="'overlay'"
                        [dropShadow]="true"
                        [trigger]="panelTrigger"
                        [slideDirection]="'right'"
                    >
                        <div slot="panel-header-title" >
                            <div class="kn-headline-24-heavy">Title</div>
                        </div>
                        <div slot="panel-header-actions" >
                            <i class="fa-regular fa-arrows-rotate kn-icon-sm kn-icon"></i>
                        </div>
                        <div slot="panel-body">               
                            <div class="panel-content">
                                <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> {{detailPanelData?.FileName}}</div>
                                <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> {{detailPanelData?.ImportDate}}</div>
                                <div class="text"><i class="kic icon-ic-fpo kn-icon-sm"></i> {{detailPanelData?.SourceSize}}</div>
                            </div>
                        </div>
                    </kn-panel>
                 
                    <!--row selection grid-->
                    <ejs-grid 
                        *ngIf="gridType == 'multi-select' && !isLoading"
                        #grid
                        [dataSource]='data' 
                        height="480px"
                        [ngClass]="{'kn-compressed-grid': showCompressedGrid}"
                        allowSorting="true"
                        allowPaging="true" 
                        allowFiltering="true" 
                        showColumnChooser="true"
                        [filterSettings]="filterSettings" 
                        [pageSettings]='pageSettings'
                        [selectionSettings]="selectOptions"
                        (actionComplete)="actionComplete($event)"
                        allowResizing="true"
                        allowReordering="true">
                        <e-columns>
                            <e-column type="checkbox" minWidth="60px" headerText="Checkbox" width="60px" [showInColumnChooser]="false"></e-column>
                            <e-column field='FileName' minWidth="40" headerText='File name' width=160 clipMode="EllipsisWithTooltip">
                                <ng-template #headerTemplate let-data>
                                    <div class="kn-grid-header-multi-line">
                                        <div>File</div>
                                        <div>Name</div>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='ImportDate' minWidth="40" headerText='Import date' width=200 clipMode="EllipsisWithTooltip"></e-column>
                            <e-column field='SourceSize' minWidth="40" headerText='Source size' width=150 clipMode="EllipsisWithTooltip">
                                <ng-template #headerTemplate let-data>
                                    <div class="kn-grid-header-multi-line">
                                        <div>Source</div>
                                        <div>Size</div>
                                    </div>
                                </ng-template>
                            </e-column>
                            <e-column field='Custodian' minWidth="200" headerText='Custodian'>
                                <ng-template #template let-data>
                                    <kn-dropdown  
                                        [items]="custodian" 
                                        [fields]="custodianFields" 
                                        [value]="data.Custodian"
                                        [compressed]="showCompressedGrid"
                                        [placeholder]="'Choose a custodian'" 
                                        [name]="'exampledropdown'"
                                        (onSelect)="selection($event)">
                                    </kn-dropdown>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>

                    <!--Nested row grid class="kn-grouped-header"-->
                    <ejs-grid 
                        #grid 
                        *ngIf="gridType == 'nested' && !isLoading"
                        [dataSource]="dataSet"
                        [childGrid]='childGrid'
                        height="480px"
                        [ngClass]="{'kn-compressed-grid': showCompressedGrid}"
                        allowSorting="true"
                        allowPaging="true" 
                        allowFiltering="true" 
                        showColumnChooser="true"
                        [filterSettings]="filterSettings" 
                        [pageSettings]='pageSettings'
                        (actionComplete)="actionComplete($event)"
                        allowResizing="true"
                        allowReordering="true">
                        <e-columns>
                            <e-column field='setName' headerText='set Name' width='140'></e-column>
                            <e-column field='analysisStatus' headerText='analysis Status' width='170'></e-column>
                            <e-column field='analysisCompleted' headerText='analysis Completed' width='120' format='yMd' clipMode="EllipsisWithTooltip"></e-column>
                            <e-column field='publishStatus' headerText='publish Status' width='120' clipMode="EllipsisWithTooltip"></e-column>
                        </e-columns>
                    </ejs-grid>

                    <!--Grouped header column-->
                    <ejs-grid 
                        *ngIf="gridType == 'groupedHeader' && !isLoading"
                        #grid
                        [dataSource]='data' 
                        class="kn-grouped-header"
                        height="480px"
                        [ngClass]="{'kn-compressed-grid': showCompressedGrid}"
                        allowSorting="true"
                        allowPaging="true" 
                        allowFiltering="true" 
                        showColumnChooser="true"
                        [filterSettings]="filterSettings" 
                        [pageSettings]='pageSettings'
                        (actionComplete)="actionComplete($event)"
                        allowResizing="true"
                        allowReordering="true">
                        <e-columns>
                            <e-column field='FileName' minWidth="40" headerText='File name' width=180 clipMode="EllipsisWithTooltip"></e-column>
                            <e-column field='ImportDate' minWidth="40" headerText='Import date' width=200 clipMode="EllipsisWithTooltip"></e-column>
                            <e-column field='SourceSize' minWidth="40" headerText='Source size' width=170 clipMode="EllipsisWithTooltip"></e-column>
                            <e-column field='Analyzed' width="200" headerText='Analyzed' [allowFiltering]="false" [allowSorting]="false">
                                <ng-template #headerTemplate let-data>
                                    <div class="kn-grouped-column">
                                        <div class="grouped-header-title">Analyzed</div>
                                        <ul class="grouped-columns">
                                            <li class="column">
                                                Docs
                                            </li>
                                            <li class="column">
                                                Size
                                            </li>
                                        </ul>
                                    </div>
                                </ng-template>

                                <ng-template #template let-data>
                                    <div class="kn-grouped-column">
                                        <ul class="grouped-columns">
                                            <li class="column">
                                                {{data.docs}}
                                            </li>
                                            <li class="column">
                                                {{data.size}}
                                            </li>
                                        </ul>
                                    </div>
                                </ng-template>
                            </e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>
    </div>
    <div angular>
       
    </div>
    <div typeScript>
        <span class="code-comment">//Import grid services </span>
        {{angularImports}}
        <br/><br/>
        <span class="code-comment">//Add providers</span>
        {{angularProviders}}
        <br/><br/>
        <span class="code-comment">//Declare variables</span>
        {{gridViewChild}}
        <br/><br/>
        <span class="code-comment">//Set grid properties</span>
        {{intialize}}
        <br/><br/>
        <span *ngIf="gridType == 'multi-select'">
            <span class="code-comment">//For checkbox selection only use the code below in ngOnInit(). Clicking on the entire row will not select the row</span>
            {{multiSelectRowSettings}}
            <br/><br/>
        </span>
        <span class="code-comment">//When adding a row detail panel. Create an instance of the panel using ViewChild as shown below.</span>
        <span class="code-comment">//You can manually open the panel by calling "this.detailPanel.open();" and close it by calling "this.detailPanel.close();"</span>
        {{detailPanelFunction}}
        <br/>
        <span class="code-comment">//Example code for adding new row styling</span>
        {{newRowFunction}}
        <br/><br/>
        <span class="code-comment">//Function to reset the grid's sorting and filtering</span>
        {{clearAllFunction}}
        <br/><br/>
        <span class="code-comment">//Function to toggle the "clear all" button's disabled/enabled state</span>
        {{clearAllButtonDisableFunction}}
        <br/><br/>
        <span class="code-comment">//Bind the "activeSortingAndFiltering()" function to the grids "actionComplete" property. (actionComplete)="actionComplete($event)"</span>
        {{clearAllBtnUsage}}
        <br/><br/>
        <span class="code-comment">//Function to refresh the grid. Note: the setTimeout() function can be replaced with api call or another data binding function</span>
        {{refreshGridFunction}}
        <br/><br/>
        <span class="code-comment">//Add this click event listener for the custom column chooser</span>
        {{columnChooser}}
        <span *ngIf="gridType != 'nested'">
            <br/><br/>
            <span class="code-comment">//Sample grid data </span>
            {{gridData}}
        </span>
        <span *ngIf="gridType == 'nested'">
            <br/><br/>
            <span class="code-comment">//Define the child grid</span>
            {{childGridModel}}
            <br/><br/>
            <span class="code-comment">//Sample nested grid data </span>
            {{nestedGridData}}
            <br/><br/>
            <span class="code-comment">//Sample nested child grid data </span>
            {{nestedChildGridData}}
        </span>
    </div>
    <div javascript>
        <span class="code-comment">//Loading spinner</span>
        {{webSpinner}}
        <br/><br/>
        <span class="code-comment">//Example code for adding new row styling</span>
        {{newRowFunction}}
        <br/><br/>
        <span class="code-comment">//Function to reset the grid's sorting and filtering</span>
       {{clearAllFunction}}
       <br/><br/>
       <span class="code-comment">//Function to toggle the "clear all" button's disabled/enabled state</span>
       {{clearAllButtonDisableFunction}}
       <br/><br/>
       <span class="code-comment">//Bind the "activeSortingAndFiltering()" function to the grids "actionComplete" property. (actionComplete)="actionComplete($event)"</span>
       {{clearAllBtnUsage}}
       <br/><br/>
       <span class="code-comment">//Function to refresh the grid. Note: the setTimeout() function can be replaced with api call or another data binding function</span>
       {{refreshGridFunction}}
       <br/><br/>
       <span class="code-comment">//Add this click event listener for the custom column chooser</span>
       {{columnChooser}}
       <br/><br/>
       <span *ngIf="gridType != 'nested'">
            <br/><br/>
            <span class="code-comment">//Sample grid data </span>
            {{gridData}}
        </span>
        <span *ngIf="gridType == 'nested'">
            <br/><br/>
            <span class="code-comment">//Sample child grid data </span>
            {{sampleChildData}}
        </span>
    </div>
    <div customproperties>
        <div class="section-title">Configuration Examples</div>
        <div class="item-row">
            <div class="radio-info">
                <kn-radio-button [radioId]="'standardGrid'" [value]="'standard'" [(ngModel)]="gridType" [defaultValue]="gridType" (onSelect)="gridCode($event)" [name]="'gridGroup'" [label]="'Detail panel grid'"></kn-radio-button>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="{{standardTooltip}}" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <div class="item-row">
            <div class="radio-info">
                <kn-radio-button [radioId]="'multiSelectGrid'" [value]="'multi-select'" [(ngModel)]="gridType" [defaultValue]="gridType" (onSelect)="gridCode($event)" [name]="'gridGroup'" [label]="'Multi-select grid'"></kn-radio-button>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="{{multiSelectTooltip}}" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <div class="item-row">
            <div class="radio-info">
                <kn-radio-button [radioId]="'nestedGrid'" [value]="'nested'" [(ngModel)]="gridType" [defaultValue]="gridType" (onSelect)="gridCode($event)" [name]="'gridGroup'" [label]="'Nested row grid'"></kn-radio-button>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="{{nestedTooltip}}" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <div class="item-row">
            <div class="radio-info">
                <kn-radio-button [radioId]="'groupedHeaderGrid'" [value]="'groupedHeader'" [(ngModel)]="gridType" [defaultValue]="gridType" (onSelect)="gridCode($event)" [name]="'gridGroup'" [label]="'Grouped header grid'"></kn-radio-button>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="{{groupedTooltip}}" [tooltipDirection]="'top'"></i> 
            </div>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle [label]="'Compressed grid'" [(ngModel)]="showCompressedGrid" (value)="compress()" [disabled]="isLoading"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle [label]="'Row highlight'" [(ngModel)]="rowHightlight" (value)="newRow($event)" [disabled]="isLoading"></kn-toggle>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title">Filter types</div>
        <div class="item-row">
            <div class="item-row">
                <kn-radio-button 
                    [radioId]="'standardFilter'" 
                    [value]="'CheckBox'" 
                    [(ngModel)]="gridFilter" 
                    [defaultValue]="gridFilter" 
                    [name]="'filterType'" 
                    [label]="'Standard'"
                    (onSelect)="gridFilterType($event)">
                </kn-radio-button>
                <br/>
                <kn-radio-button 
                    [radioId]="'advancedFilter'" 
                    [value]="'Excel'" 
                    [(ngModel)]="gridFilter" 
                    [defaultValue]="gridFilter" 
                    [name]="'filterType'" 
                    [label]="'Advanced'"
                    (onSelect)="gridFilterType($event)">
                </kn-radio-button>
            </div>
        </div>
        <div class="section-title reference">References</div>
        <img class="kn-responsive-image" src="//cdn.syncfusion.com/documentation/images/Syncfusion_Logo_Header.svg">
        <kn-radio-button [radioId]="'angularRadio'" [value]="'angular'" [(ngModel)]="language" [defaultValue]="language" [name]="'radioGroup'" [label]="'Angular'"></kn-radio-button>
        <br/>
        <kn-radio-button [radioId]="'reactRadio'" [value]="'react'" [(ngModel)]="language" [defaultValue]="language" [name]="'radioGroup'" [label]="'React'"></kn-radio-button>
        <hr>
        <!--Angular links-->
        <div class="references" [ngClass]="{'show': language == 'angular'}">
            <div class="item-row">
                <div class="link-group">
                    <a class="kn-link" href="https://ej2.syncfusion.com/angular/documentation/grid/getting-started" target="_blank">Getting started</a>
                    <a class="kn-link" href="https://ej2.syncfusion.com/angular/documentation/grid/data-binding/data-binding" target="_blank">Data binding</a>
                    <a class="kn-link" href="https://ej2.syncfusion.com/angular/documentation/grid/columns/column-template" target="_blank">Column template</a>
                    <a class="kn-link" href="https://ej2.syncfusion.com/angular/documentation/grid/columns/headers#header-template" target="_blank">Header template</a>
                    <a class="kn-link" href="https://ej2.syncfusion.com/angular/documentation/grid/row/detail-template" target="_blank">Detail template</a>
                    <a class="kn-link" href="https://ej2.syncfusion.com/angular/documentation/grid/selection/check-box-selection" target="_blank">Checkbox selection</a>

                </div>
            </div>
        </div>

        <!--React links-->
        <div class="references" [ngClass]="{'show': language == 'react'}">
            <div class="item-row">
                <div class="link-group">
                    <a class="kn-link" href="https://ej2.syncfusion.com/react/documentation/grid/getting-started" target="_blank">Getting started</a>
                    <a class="kn-link" href="https://ej2.syncfusion.com/react/documentation/grid/data-binding/data-binding" target="_blank">Data binding</a>
                    <a class="kn-link" href="https://ej2.syncfusion.com/react/documentation/grid/columns/column-template" target="_blank">Column template</a>
                    <a class="kn-link" href="https://ej2.syncfusion.com/react/documentation/grid/columns/headers#header-template" target="_blank">Header template</a>
                    <a class="kn-link" href="https://ej2.syncfusion.com/react/documentation/grid/row/detail-template" target="_blank">Detail template</a>
                    <a class="kn-link" href="https://ej2.syncfusion.com/react/documentation/grid/selection/check-box-selection" target="_blank">Checkbox selection</a>
                </div>
            </div>
        </div>
    </div>
    <div usage>
        <grid-usage></grid-usage>
    </div>
    <div designSpec>
        <grid-specs></grid-specs>
    </div>
    <div accessibility>
        <grid-accessibility></grid-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Date</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">December 22, 2023</div>
                <div class="item">1.1.2</div>
                <div class="item">
                    <ul>
                        <li>Added Advanced Filter</li>
                        <li>Added Date Range Filter</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">October 6, 2023</div>
                <div class="item">1.1.1</div>
                <div class="item">
                    <ul>
                        <li>bug fix - fixed issues with sort filter icon background moving on hover when clearing the sort</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.1.0</div>
                <div class="item">
                    <ul>
                        <li>updated row highlight styling for all grid dialogs and the column chooser</li>
                        <li>bug fix - fixed issue with all dialog primary buttons not showing</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">May 17, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the theme for the Data grid</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>

