import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-body-text',
  templateUrl: './body-text.component.html',
  styleUrls: ['./body-text.component.sass']
})
export class BodyTextComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
