<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Word Use</div>
        <div class="section-title">Aa</div>
        
        <section>
            <div class="text"><span class="kn-bold">Assignment</span> – Capitalized when used as a noun referring to the process of assigning a user to a Matter in Spectra.</div>
        </section>

        <div class="section-title">Cc</div>
        
        <section>
            <div class="text"><span class="kn-bold">Custodian </span> – Capitalized when used as a noun.</div>
        </section>

        <div class="section-title">Dd</div>
        
        <section>
            <div class="text"><span class="kn-bold">Data set </span> – two words.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Duplicate </span> – Capitalized when used as a noun. Refers to duplicate documents within a corpus.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Deduplication </span> – Always capitalized. Sometimes abbreviated as a verb, “Dedupe”. Known industry term.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">DeNIST </span> – Uppercase “D”, lowercase “e”, all caps “NIST”. Known industry term. www.nsrl.nist.gov</div>
        </section>

        <div class="section-title">Ee</div>
        
        <section>
            <div class="text"><span class="kn-bold">eDiscovery </span> – Lowercase “e”, uppercase “D” for sentence case, otherwise “ediscovery”. Known industry term.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Export </span> – Capitalized when used as a noun. Refers to a Production Export load file.</div>
        </section>

        <div class="section-title">Li</div>
        
        <section>
            <div class="text"><span class="kn-bold">Imaging </span> – Capitalized when used as a verb or a noun within the context of the process of creating an image (e.g., TIFF) of one or more Native documents as a feature in Spectra.</div>
        </section>

        <div class="section-title">Mm</div>
        
        <section>
            <div class="text"><span class="kn-bold">Matter </span> – Capitalized when used as a noun. </div>
        </section>

        <div class="section-title">Nn</div>
        
        <section>
            <div class="text"><span class="kn-bold">Native </span> – Capitalized when used as a noun. Refers to the original document without modifications (imaging, processing).</div>
        </section>

        <div class="section-title">Rr</div>
        
        <section>
            <div class="text"><span class="kn-bold">Relativity </span> – Always capitalized.</div>
        </section>

        <div class="section-title">Ss</div>
        
        <section>
            <div class="text"><span class="kn-bold">SmartSeries </span> – One word. Capital Ss.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Spectra </span> – Always capitalized.</div>
        </section>

        <div class="section-title">Uu</div>
        
        <section>
            <div class="text"><span class="kn-bold">User </span> – Capitalized when used as a noun.</div>
        </section>

        <div class="section-title">Vv</div>
        
        <section>
            <div class="text"><span class="kn-bold">Volume </span> – Capitalized when used as a noun.</div>
        </section>
    </div>
</div>