import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TemplateUpdateService {
  accessibilityInfoText = 'Scanned and tested source code utilizing axe Dev Tools based on the WCAG 2.1 guidelines and the WIA-ARIA (Accessible Rich Internet Application) best practices for semantic attributes for assistive technologies.'
  accessibilityHeader = 'Color Contrast & Code';
  
  constructor() { }

  templateUpdate(elemTag, options, container, content?, hide?) {
    let opts;
  
    for(const opt in options) {
      if(opts) {
        opts += ' ' + options[opt].option + options[opt].value;
      } else {
        opts = options[opt].option + options[opt].value;
      }
    }
    opts = opts ? opts : "";
    content = content ? content: "";
    let ref = `<${elemTag} ${opts}>${content}</${elemTag}>`;

    if(hide) {
      ref = null;
      content = null;
      elemTag = null;
      options = null;
    }

    return {tag: elemTag, options: options, content: content, componentRef: ref};
  }

  templateProperties(event, angularOptions?, webOptions?, option?, value?, web?) {
      //convert angular property to web format
      let webOption = option.replace(/(?:^|\.?)([A-Z])/g, function (x,y){return "-" + y.toLowerCase()}).replace(/^-/, ""); 
      webOption = webOption.split("[").join("");
      webOption = webOption.split("]").join("");
      if(event) {
        if(web != 'webonly') {
          //angluar
          const angularVal = value == '--' ?  '' : '"' + value + '"';
          // let angularVal = typeof(value) == 'boolean' ?  '"' + value + '"' : '"' + value + '"';
          angularOptions.push({ option: option, value: angularVal });

          //web
          if(web) {
            webOptions.push({ option: webOption, value: '"' + value + '"' });
          }
        } else {
          webOptions.push({ option: webOption, value: '"' + value + '"' });
        }

        return { angularOptions: angularOptions, webOptions: webOptions }
      } else {
        const angularItem = angularOptions.findIndex((u) => {
          return u.option === option;
        });
  
        const webItem = webOptions.findIndex((u) => {
          return u.option === webOption;
        });
  
        if(angularItem !== -1) {
          //angular
          angularOptions.splice(angularItem, 1);
        }
          //web
        if(web && webItem !== -1) {
          webOptions.splice(webItem, 1);
        }

        return { angularOptions: angularOptions, webOptions: webOptions }
      }
  }

  //update a single component property on the component demo (web and angular version)
  updateProperty(options, prop, value, web?) {
    const index = options.findIndex((obj => obj.option == prop));
    options[index].option = prop;
    if(web) {
      options[index].value = '"' + value + '"';
    } else {
      options[index].value = '"\'' + value +'\'"';
    }
  }
}
