import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';
@Component({
  selector: 'radio-button-accessibility',
  templateUrl: './radio-button-accessibility.component.html',
  styleUrls: ['./radio-button-accessibility.component.sass']
})
export class RadioButtonAccessibilityComponent implements OnInit {
  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;

  constructor(private templateService: TemplateUpdateService) { }

  ngOnInit(): void {
  }

}
