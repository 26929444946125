<app-canvas 
    [js]="true" 
    [ts]="true" 
    [htmlCode]="angularComponentRef"
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef">
    <div title>Search Box <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <kn-autocomplete 
            [data]="data" 
            [keyWord]="'tags'" 
            [fields]="fields" 
            [placeholder]="placeholder"
            [compressed]="compressed"
            [setDisabled]="disabled"
            [name]="name"
            [dropdownWidth]="dropdownCustomWidth"
            [dropdownHeight]="dropdownCustomHeight"
            [dropdownRightAligned]="dropdownRightAligned"
            (onSelect)="onSelect($event)"
            (onKeyUp)="onKeyup($event)">
        </kn-autocomplete>
    </div>
    <div angular>
    </div>
    <div web>
    </div>
    <div typeScript>
        <span class="code-comment">//Searchbox using a string array. Note: the "[keyWord]" property must not exist </span>
        {{dataArry}}
        <span class="code-comment">//Searchbox using a object array </span>
        <span class="code-comment">//"fields" object is required </span>
        {{dataFields}}
        <span class="code-comment">//You can add a string property to your object for additional search terms. See the "tags" property below. You will need to specify your property in the component's [keyWord] input</span>
        {{dataObj}}
    </div>
    <div javascript>
        <span class="code-comment">//Initialize the component</span>
        {{initializeComponentWeb}}
        <span class="code-comment">//Searchbox using an object array</span>
        <span class="code-comment">//You can add a string property to your object for additional search terms. See the "tags" property below. You will need to specify your property in the component's "key-word" property</span>
        {{webDropdownItemsObj}}
        <span class="code-comment">//Searchbox using a string array. Note: the "key-word" property must not exist</span>
        {{webDropdownItemsArray}}
        <span class="code-comment">//Item selected event</span>
        {{webClickEvent}}
        <span class="code-comment">//All properties with a boolean value needs to be set in code like this</span>
        {{webSetProperties}}
        <span class="code-comment">//Remove a boolean property like this</span>
        {{webRemoveProperties}}
    </div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onSelect():</div>
                <div class="obj-items">
                    <div class="obj-item teir-1" [ngClass]="{'active': onselectData}"><span class="label">data: <i class="fa-solid fa-caret-right teir-1-caret" (click)="onselectData = !onselectData"></i> </span></div>
                    <div class="sub-items-tier-1"  *ngIf="onselectData">
                        <div class="obj-item teir-2" [ngClass]="{'active': onselectOrigin}"><span class="label">origin: <i class="fa-solid fa-caret-right teir-2-caret" (click)="onselectOrigin = !onselectOrigin"></i> </span></div>
                        <div class="sub-items-tier-2"  *ngIf="onselectOrigin">
                            <div class="obj-item"><span class="label">sport: </span>"{{actionData?.origin.sport}}"</div>
                            <div class="obj-item"><span class="label">role: </span>"{{actionData?.origin.value}}"</div>
                            <div class="obj-item"><span class="label">tags: </span>"{{actionData?.origin.tags}}"</div>
                        </div>
                        <div class="obj-item"><span class="label">text: </span>"{{actionData?.text}}"</div>
                        <div class="obj-item"><span class="label">value: {{actionData?.value}}</span></div>
                    </div>
                </div>
            </div>
            <div class="event-item" [ngClass]="{'active': event2}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event2 = !event2"></i> 
                <div class="obj">onKeyUp():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">data: <i>"returns the rows that match"</i>></span></div>
                    <div class="obj-item"><span class="label">value:</span> "{{keyUp?.value}}"</div>
                </div>
            </div>
            <div class="event-item" [ngClass]="{'active': event3}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event3 = !event3"></i> 
                <div class="obj">onClear():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">data: <i>"returns all rows of data"</i>></span></div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="section-title">Modifiers</div>
        <div class="item-row">
            <kn-toggle [label]="'Placeholder'" (value)="showHidePlaceholder($event)" [(ngModel)]="showPlaceholder"></kn-toggle>
        </div>
        <div class="action-input" *ngIf="showPlaceholder">
            <kn-textbox [label]="'Placeholder text'" [value]="placeholder" (onValueChange)="placeholderValue($event)" [clearIcon]="true"></kn-textbox>
        </div>
        <div class="item-row">
            <kn-toggle label="Dropdown width" (value)="customDrpdnWidth($event); isDropdownWidthCustom = !isDropdownWidthCustom" ></kn-toggle>
        </div>
        <div class="action-input" *ngIf="isDropdownWidthCustom">
            <div class="input-info">
                <kn-textbox 
                    [label]="'Enter a value'" 
                    [(ngModel)]="dropdownCustomWidth" 
                    [value]="dropdownCustomWidth" 
                    [setDisabled]="" 
                    [setPlaceholder]="'400px'"
                    (onValueChange)="customDrpdnWidthValue($event)" 
                    [clearIcon]="true">
                </kn-textbox>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="This is a CSS width property, you can enter px, %, etc" [tooltipDirection]="'top'"></i>
             </div>
        </div>
        <div class="item-row">
            <kn-toggle label="Dropdown height" (value)="customDrpdnHeight($event); isDropdownHeightCustom = !isDropdownHeightCustom" ></kn-toggle>
        </div>
        <div class="action-input" *ngIf="isDropdownHeightCustom">
            <div class="input-info">
                <kn-textbox 
                    [label]="'Enter a value'" 
                    [(ngModel)]="dropdownCustomHeight" 
                    [value]="dropdownCustomHeight" 
                    [setDisabled]="" 
                    [setPlaceholder]="'400px'"
                    (onValueChange)="customDrpdnHeightValue($event)" 
                    [clearIcon]="true">
                </kn-textbox>
                <i class="fa-solid fa-circle-info kn-icon info-icon" tooltip="This is a CSS height property, you can enter px, %, etc" [tooltipDirection]="'top'"></i>
             </div>
        </div>
        <div class="item-row">
            <kn-toggle label="Right align dropdown" [(ngModel)]="dropdownRightAligned"  (value)="controlEvent($event, '[dropdownRightAligned]=', 'true', true);"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Compressed field"  (value)="controlEvent($event, '[compressed]=', 'true', true); compressed = !compressed"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Disabled state"  (value)="controlEvent($event, '[setDisabled]=', 'true', true); disabled = !disabled"></kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle label="Select event" [(ngModel)]="selectEvent"  (value)="controlEvent($event, '(onSelect)=', 'selectedItem($event)', false);"></kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">searchboxId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"exampleSearchbox"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">name</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"searchbox"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">fields</div>
                <div class="item"><i>object</i></div>
                <div class="item"><i>{{'{}'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">data</div>
                <div class="item"><i>object, array</i></div>
                <div class="item"><i>{{'{}'}}, {{'[]'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropdownWidth</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"400px"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">dropdownRightAligned</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">placeholder</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>sports</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">keyWord</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"keyword1, keyword2, keyword3"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">compressed</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">setDisabled</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onSelect</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onKeyUp</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onClear</div>
                <div class="item"><i>event</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <searchbox-usage></searchbox-usage>
    </div>
    <div designSpec>
        <searchbox-specs></searchbox-specs>
    </div>
    <div accessibility>
        <searchbox-accessibility></searchbox-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">March 14, 2024</div>
                <div class="item">1.2.0</div>
                <div class="item">
                    <ul>
                        <li>added funcationality to show the dropdown list if the user clicks in the textbox while a value exists</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Feburary 9, 2024</div>
                <div class="item">1.1.0</div>
                <div class="item">
                    <ul>
                        <li>added the a feature to re-focus the input box after clicking the clear button</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">October 6, 2023</div>
                <div class="item">1.0.6</div>
                <div class="item">
                    <ul>
                        <li>added more unit test</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.5</div>
                <div class="item">
                    <ul>
                        <li>Added addition unit testing after component was published</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.4</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                        <li>bug fix - fixed the click area for the dropdown menu item. The click event was only firing on the "text" section. The click event now covers the entire row container</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">July 7, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>fixed linting errors and warning</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">May 17, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>bug fix - added elevation to the dropdown menu to prevent being hidden by parent elements</li>
                        <li>added top positioning of dropdown list (i.e. open upward) if control is positioned close to the bottom of the UI layout</li>
                        <li>Updated the usage guidelines screens to reflect the menu option row height change from 45px to 41px from Version 1.0.1 release.</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">April 5, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>Updated menu option row height (from 45px to 41px) to standardize all contextual menus</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Mar 1, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the searchbox component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>