import { Component, OnInit } from '@angular/core';
import  packageInfo from '../../../../../kraken-contextual-menu/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';

@Component({
  selector: 'app-contextual-menu',
  templateUrl: './contextual-menu.component.html',
  styleUrls: ['./contextual-menu.component.sass']
})
export class ContextualMenuComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public triggerButton: any;
  public triggerButtonWeb: any;
  public triggerButtonTag = 'kn-icon-button';
  public triggerRightClickTag = 'div'
  public elementTag = 'kn-contextual-menu';
  public rightClick = false;
  public contextualMenuIsOpen = false;
  public componentVersion = packageInfo.version;
  public data: any;
  public addIcons = false;
  public triggerId = 'contextualTrigger';
  public event1 = true;
  public event2 = true;
  public onSelectItems: any = [];
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenContextualMenuModule'};
  public componentId = 'dropdownComponentId';
  
  
  public angularComponentOptions: any = [
    { option: '[componentId]=', id: 0, value: '"\'' + this.componentId +'\'"'},
    { option: '[triggerId]=', id: 2, value: '"\'contextualTrigger\'"'},
    { option: '[items]=', id: 2, value: '"items"'},
    { option: '(isOpen)=', id: 2, value: '"onSelect($event)"'},
    { option: '(onSelect)=', id: 2, value: '"contextualMenuIsOpen = $event"'}
  ];

  public webComponentOptions: any = [
    { option: 'component-id=', id: 0, value:  '"'+ this.componentId +'"'},
    { option: 'trigger-id=', id: 0, value:  '"contextualTrigger"'},
  ];

  public triggerButtonOptions: any = [
    { option: '[buttonId]=', id: 0, value: '"\'contextualTrigger\'"'},
    { option: '[buttonType]=', id: 0, value: '"\'kn-flat kn-btn-sm\'"'},
    { option: '[icon]=', id: 0, value: '"\'fa-solid fa-gear\'"'},
    { option: '[active]=', id: 0, value: '"contextualMenuIsOpen"'}
  ]

  public triggerButtonWebOptions: any = [
    { option: 'button-id=', id: 0, value: '"contextualTrigger"'},
    { option: 'button-type=', id: 0, value: '"kn-flat kn-btn-sm"'},
    { option: 'icon=', id: 0, value: '"fa-solid fa-gear"'}
  ]

  public triggerRightClickOptions: any = [
    { option: 'class=', id: 0, value: '"example-right-click-zone"'},
    { option: 'id=', id: 0, value: '"exampleRightClickTrigger"'},
  ]

  public itemsWithIcons = [
    {
      id: '0',
      name: 'Option 1',
      icon:'kic icon-ic-fpo kn-icon-sm'
    },
    {
      id: '1',
      name: 'Option 2',
      icon:'kic icon-ic-fpo kn-icon-sm',
      children: [
        {
          id: 'item2SubItem1',
          name: 'Sub option 1',
          icon:'kic icon-ic-fpo kn-icon-sm'
        },
        {
          id: 'item2SubItem2',
          name: 'Sub option 2 ',
          icon:'kic icon-ic-fpo kn-icon-sm'
        },
        {
          id: 'item2SubItem3',
          name: 'Sub option 3',
          icon:'kic icon-ic-fpo kn-icon-sm',
          children: [
            {
              id: 'subItem3SubItem1',
              name: 'Sub option 1',
              icon:'kic icon-ic-fpo kn-icon-sm'
            },
            {
              id: 'subItem3SubItem2',
              name: 'Sub option 2',
              icon:'kic icon-ic-fpo kn-icon-sm'
            },
            {
              id: 'subItem3SubItem3',
              name: 'Sub option 3',
              icon:'kic icon-ic-fpo kn-icon-sm',
              disabled: true
            }
          ]
        },
      ]
    },
    {
      id: '2',
      name: 'Option 3',
      icon:'kic icon-ic-fpo kn-icon-sm',
      children: [
        {
          id: 'item3SubItem1',
          name: 'Sub option 1',
          icon:'kic icon-ic-fpo kn-icon-sm'
        }
      ]
    }
  ];

  public items = [
    {
      id: '0',
      name: 'Option 1',
      meta: 'Data'
    },
    {
      id: '1',
      name: 'Option 2',
      meta: 'Data',
      children: [
        {
          id: 'item2SubItem1rere',
          name: 'Sub option 1',
          children: [
            {
              id: 'item2SubItem1ffd',
              name: 'Sub option 1',
            },
            {
              id: 'item2SubItem2',
              name: 'Sub option 2',
              meta: 'Data'
            },
            {
              id: 'item2SubItem2',
              name: 'Sub option 3',
              meta: 'Data',
              divider: true
            },
            {
              id: 'item2SubItem2',
              name: 'Sub option 4',
              meta: 'Data'
            },
            {
              id: 'item2SubItem2',
              name: 'Sub option 5',
              meta: 'Data',
              disabled: true
            },
            {
              id: 'item2SubItem2',
              name: 'Sub option 6',
              meta: 'Data'
            }
          ]
        },
        {
          id: 'item2SubItem2',
          name: 'Sub option 2',
          meta: 'Data'
        },
        {
          id: 'item2SubItem2',
          name: 'Sub option 3',
          meta: 'Data'
        },
        {
          id: 'item2SubItem1',
          name: 'Sub option 4',
        },
        {
          id: 'item2SubItem3',
          name: 'Sub option 5',
          children: [
            {
              id: 'subItem3SubItem1',
              name: 'Sub option 1',
            },
            {
              id: 'subItem3SubItem2',
              name: 'Sub option 2',
              meta: 'Data',
              divider: true
            },
            {
              id: 'subItem3SubItem3',
              name: 'Sub option 3',
              disabled: true
            },
            {
              id: 'subItem3SubItem1',
              name: 'Sub option 4'
            }
          ]
        },
        {
          id: 'item2SubItem1',
          name: 'Sub option 6',
        },
        {
          id: 'item2SubItem1',
          name: 'Sub option 7',
        },
      ]
    },
    {
      id: '2',
      name: 'Option 3',
      children: [
        {
          id: 'item3SubItem1',
          name: 'Sub option 1'
        }
      ]
    },
    {
      id: '2',
      name: 'Option 4',
      children: [
        {
          id: 'item3SubItem1',
          name: 'Sub option 1'
        }
      ]
    }
  ];

  public templateHtml: any;

  public webItemsInstance =
  `
let contextualMenu = document.querySelector('#dropdownComponentId');
contextualMenu.items = [`

  public typeScriptInstance = 
  `public items = [`

  public dataWithIcons =
  `
  {
    id: '0',
    name: 'Item 1',
    icon:'fa-solid fa-circle-dollar'
  },
  {
    id: '1',
    name: 'Item 2 and then some more longer text',
    meta: '',
    icon:'fa-solid fa-circle-dollar',
    children: [
      {
        id: 'item2SubItem1',
        name: 'sub item 1',
        icon:'fa-solid fa-circle-dollar'
      },
      {
        id: 'item2SubItem2',
        name: 'Sub item 2 ',
        icon:'fa-solid fa-circle-dollar'
      },
      {
        id: 'item2SubItem3',
        name: 'Sub item 3',
        icon:'fa-solid fa-circle-dollar',
        children: [
          {
            id: 'subItem3SubItem1',
            name: 'Sub item 1',
            icon:'fa-solid fa-circle-dollar'
          },
          {
            id: 'subItem3SubItem2',
            name: 'Sub item 2',
            icon:'fa-solid fa-circle-dollar'
          },
          {
            id: 'subItem3SubItem3',
            name: 'Sub item 3',
            icon:'fa-solid fa-circle-dollar',
            disabled: true
          }
        ]
      },
    ]
  },
  {
    id: '2',
    name: 'Item 3',
    icon:'fa-solid fa-circle-dollar',
    children: [
      {
        id: 'item3SubItem1',
        name: 'Sub item 1',
        icon:'fa-solid fa-circle-dollar'
      }
    ]
  }
];`

  public dataWithOutIcons = 
  `
  {
    id: '0',
    name: 'Option 1',
    meta: 'Data'
  },
  {
    id: '1',
    name: 'Option 2',
    meta: 'Data',
    children: [
      {
        id: 'item2SubItem1rere',
        name: 'Sub option 1',
        children: [
          {
            id: 'item2SubItem1ffd',
            name: 'Sub option 1',
          },
          {
            id: 'item2SubItem2',
            name: 'Sub option 2',
            meta: 'Data'
          },
          {
            id: 'item2SubItem2',
            name: 'Sub option 3',
            meta: 'Data',
            divider: true
          },
          {
            id: 'item2SubItem2',
            name: 'Sub option 4',
            meta: 'Data'
          },
          {
            id: 'item2SubItem2',
            name: 'Sub option 5',
            meta: 'Data',
            disabled: true
          },
          {
            id: 'item2SubItem2',
            name: 'Sub option 6',
            meta: 'Data'
          }
        ]
      },
      {
        id: 'item2SubItem2',
        name: 'Sub option 2',
        meta: 'Data'
      },
      {
        id: 'item2SubItem2',
        name: 'Sub option 3',
        meta: 'Data'
      },
      {
        id: 'item2SubItem1',
        name: 'Sub option 4',
      },
      {
        id: 'item2SubItem3',
        name: 'Sub option 5',
        children: [
          {
            id: 'subItem3SubItem1',
            name: 'Sub option 1',
          },
          {
            id: 'subItem3SubItem2',
            name: 'Sub option 2',
            meta: 'Data',
            divider: true
          },
          {
            id: 'subItem3SubItem3',
            name: 'Sub option 3',
            disabled: true
          },
          {
            id: 'subItem3SubItem1',
            name: 'Sub option 4'
          }
        ]
      },
      {
        id: 'item2SubItem1',
        name: 'Sub option 6',
      },
      {
        id: 'item2SubItem1',
        name: 'Sub option 7',
      },
    ]
  },
  {
    id: '2',
    name: 'Option 3',
    children: [
      {
        id: 'item3SubItem1',
        name: 'Sub option 1'
      }
    ]
  },
  {
    id: '2',
    name: 'Option 4',
    children: [
      {
        id: 'item3SubItem1',
        name: 'Sub option 1'
      }
    ]
  }
];`

  constructor(
  public tempUpdateService: TemplateUpdateService, 
  public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    this.data = this.items;

    //menu trigger button
    this.triggerButton = this.tempUpdateService.templateUpdate(this.triggerButtonTag, this.triggerButtonOptions, this.angularContainer);
    this.triggerButtonWeb = this.tempUpdateService.templateUpdate(this.triggerButtonTag, this.triggerButtonWebOptions, this.angularContainer);

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);

    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  onSelect(event) {
    this.onSelectItems.id = event.id;
    this.onSelectItems.meta = event.meta;
    this.onSelectItems.name = event.name;
    this.onSelectItems.divider = event.divider;
    this.onSelectItems.icon = event.icon;
  }

  withIcons(event) {
    if(event) {
      this.data = this.itemsWithIcons;
    } else {
      this.data = this. items
    }
  }

  rightClickEvent(event) {
    this.triggerId = 'exampleRightClickTrigger';
    this.controlEvent(event, '[rightClick]=', 'true', true); 

     //angular
     if(!event) {
      // //web
      const webTriggerId = this.webComponentOptions.findIndex((obj => obj.option == 'trigger-id='));
      this.webComponentOptions[webTriggerId].option = 'trigger-id=';
      this.webComponentOptions[webTriggerId].value = '"contextualTrigger"';

      //menu trigger button
      this.triggerButton = this.tempUpdateService.templateUpdate(this.triggerButtonTag, this.triggerButtonOptions, this.angularContainer);
      this.triggerButtonWeb = this.tempUpdateService.templateUpdate(this.triggerButtonTag, this.triggerButtonWebOptions, this.angularContainer);

      //angular template
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    } else {
      //menu trigger button
      this.triggerButton = this.tempUpdateService.templateUpdate(this.triggerRightClickTag, this.triggerRightClickOptions, this.angularContainer);
      this.triggerButtonWeb = this.tempUpdateService.templateUpdate(this.triggerRightClickTag, this.triggerRightClickOptions, this.angularContainer);

    }
     this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }
}
