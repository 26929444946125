import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KrakenToastService {
  public timeout = 5000;

  showToast(toastType: string, toastMsg: string, duration?: number, topPos?: string, customIcon?: string): void { 
    //set the toast container 
    const toastContainer = document.querySelector('.kn-toast-container');

    //create the toast element
    const toast = document.createElement('div'); 

    //set duration
    this.timeout = duration ? duration : this.timeout;

    //set toast properties
    const type = toastType == 'success' ? 'success' : 'info';
    const id = 'knToast_' + Math.floor(Math.random()*90000) + 10000;
    const icon = customIcon ? customIcon : 'fa-solid fa-circle-check';
    toast.classList.add('kn-toast');
    toast.classList.add(type);
    toast.setAttribute('id', id);
    toast.innerHTML =
    `<div class="kn-toast-body">
        <i class="${icon} kn-icon-sm"></i>
        <div class="kn-toast-content">
            ${toastMsg}
        </div>
    </div>`

    //append to the toast container
    toastContainer.appendChild(toast);
    
    //show the toast
    setTimeout(() => {
      toast.classList.add('show');
    }, 100); //show the toast, allow time for the toast to render before applying the fade in effect

    //fade out the toast
    setTimeout(() => {
      toast.classList.remove('show');
    }, this.timeout + 100); //allow a full 5 seconds for displaying after the fading in effect

    //remove the toast from the dom
    setTimeout(() => {
      toast.remove();
    }, this.timeout + 1000); //allow the fadeout effect to complete before removing from the dom
  }  
}
