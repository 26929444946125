import { Component } from '@angular/core';

@Component({
  selector: 'slider-usage',
  templateUrl: './slider-usage.component.html',
  styleUrls: ['./slider-usage.component.sass']
})
export class SliderUsageComponent {

}
