import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class KrakenAccordionService {

  constructor() { }

  openAll(element) {
    const accordion = document.querySelector(element);
    //all inputs
    const allInputs = accordion.querySelectorAll('input');
    //open all 
    allInputs.forEach(i => {
      i.checked = true;
    });
  }

  closeAll(element) {
    const accordion = document.querySelector(element);
    //all inputs
    const allInputs = accordion.querySelectorAll('input');
    //close all 
    allInputs.forEach(i => {
      i.checked = false;
    });
  }
}
