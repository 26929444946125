import { Component, OnInit } from '@angular/core';
import  packageInfo from '../../../../../kraken-accordion/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import { KrakenAccordionService } from 'kraken-accordion';
export class SelectedData {
  id: string;
  title: boolean;
  icon: string;
  showIcon: string;
  content: any;
  subTitle: string;
  subItems: SubItems;
}

export class SubItems {
  id: string;
  title: string;
  content: any;
  subSubItems: SubSubItems;
}

export class SubSubItems {
  id: string;
  title: string;
}
@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.sass']
})
export class AccordionComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public componentVersion = packageInfo.version;
  public elementTag = 'kn-accordion';
  public webElementTag = 'kn-web-accordion';
  public componentRef;
  public content;
  public webTemplateHtml;
  public subTitle = '';
  public iconLeftAligned = false;
  public subTitleInline = false;
  public headerIcons;
  public showIcons = false;
  public autoCollapse = true;
  public showSubTitle = false;
  public subTitleProperty = '';
  public headerIconProperty = '';
  public headerDisabledProperty = '';
  public options = `>`;
  public clickEvent = false;
  public webJavaScript: any;
  public webJavaScript2: any;
  public header1Disabled = false;
  public filledBackground = false;
  public actionData;
  public onSubItems = true;
  public onselectData = true;
  public onEventData = true;
  public event1 = true;
  public webClickEvent;
  public closeAll = false;
  public subTitleText;
  public iconTemplate;
  public subTitleTemplate;
  public subTitleTemplateTierTwo;
  public subTitleTemplateTierThree;
  public subTitleTemplateTierThreeAccTwo;
  public clickEventTemp;
  public showOnSelectEvent = false;
  public showNGRouting = false;
  public ngRouterTemp;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenAccordionModule'};

  public angularComponentOptions: any = [
    { option: '[componentId]=', value: '"\'exampleAccordion\'"'}
  ];

  public webComponentOptions: any = [
    { option: 'component-id=', id: 0, value: '"exampleAccordion"'}
  ];

  public openAllCode = 
  `const allInputs = accordion.querySelectorAll('input');
//open all 
allInputs.forEach(i => {
  i.checked = true;
});`

  public closeAllCode = 
  `const allInputs = accordion.querySelectorAll('input');
//close all 
allInputs.forEach(i => {
  i.checked = false;
});`

public openCloseAllCodeTs = 
  `import { KrakenAccordionService } from 'kraken-accordion';

@Component({
  selector: 'my-component',
  templateUrl: './my-component.component.html',
  styleUrls: ['./my-component.component.sass']
})
export class MyComponent implements OnInit {

  constructor(public accordionService: KrakenAccordionService) { }

  closeAllEvent() {
    this.accordionService.closeAll('#exampleAccordion');
  }

  openAllEvent() {
    this.accordionService.openAll('#exampleAccordion');
  }
});`

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService,
    public accordionService: KrakenAccordionService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    console.log(packageInfo)

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateContent());
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);

    this.webJavaScript = 
`let accordion = document.querySelector("#exampleAccordion");
let accItems = 
[
  {
    "id": "title1", 
    "title": "Title",
    "subTitle":"Subtitle",
    "icon":"fa-solid fa-gear kn-icon-sm",
    "disabled": false,
    "content": \`<div class="acc-content">
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
    when an unknown printer took a galley of type and scrambled it to
    make a type specimen book. 
    </div>\`
  },
  {
    "id": "title2", 
    "title": "Title",
    "subTitle":"Subtitle",
    "icon":"fa-solid fa-gear kn-icon-sm",
    "disabled": false,
    "subHeader": [
        { 
          "id": "subHeader3", 
          "title": "Nested title", 
          "content": \`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s\`, "disabled": false
        },
        { 
            "id": "subHeader4", 
            "title": "Nested title", 
            "disabled": false,
            "content": "<label>Nested title</label>"
        }
    ]
  }
]`

this.webJavaScript2 =
`accordion.accordionItems = accItems;`

this.webClickEvent = 
`
accordion.addEventListener('onSelect', (event) => console.log(event.detail));
`
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateContent());
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }

  updateCode() {
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateContent());
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }
  getOptions() {
    let options = '';
  
    if (this.showIcons) {
      options += `\n    ${this.headerIconProperty}`;
    }
  
    if (this.header1Disabled) {
      options += `\n    ${this.headerDisabledProperty}`;
    }
  
    if (this.clickEventTemp) {
      options = `\n    ${this.clickEventTemp}` + options;
    }

    if (this.ngRouterTemp) {
      options = `\n    ${this.ngRouterTemp}` + options;
    }
  
    return options + '>';
  }

  getSubTitleTemplate(showSubTitle) {
    return showSubTitle ? `\n    <div subTitle>Sub title</div>` : "";
  }

  updateContent() {
    const options = this.getOptions();
    const subTitleTemplate = this.getSubTitleTemplate(this.showSubTitle);
  
    this.content = `
  <kn-accordion-tier-one${options}
    <div title>Title </div>${subTitleTemplate}
    <div content>
      <div class="acc-content">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
        when an unknown printer took a galley of type and scrambled it to
        make a type specimen book. 
      </div>
    </div>
  </kn-accordion-tier-one>
  <kn-accordion-tier-one${options}
    <div title>Title 2</div>${subTitleTemplate}
    <div content>
      <kn-accordion-tier-two${options}
        <div title>Title 2</div>${subTitleTemplate}
        <div content>
          <kn-accordion-tier-three${options}
              <div title>Nested title</div>${subTitleTemplate}
          </kn-accordion-tier-three>
        </div>
      </kn-accordion-tier-two>
      <kn-accordion-tier-two${options}
        <div title>Nested title</div>${subTitleTemplate}
        <div content>
          <kn-accordion-tier-three${options}
            <div title>Nested title</div>${subTitleTemplate}
          </kn-accordion-tier-three>
        </div>
      </kn-accordion-tier-two>
    </div>
  </kn-accordion-tier-one>
  `;
  
    return this.content;
  }

  selected(event) {
    this.actionData = new SelectedData;
    this.actionData = event.item;
  }

  showSubTitleText(event) {
    this.subTitleText = event ? "sub title" : "";
    this.subTitle = this.showSubTitle ? 'Subtitle' : '';
    this.subTitleProperty = this.showSubTitle ? '[subTitle]="\'Subtitle\'"' : '';
    this.updateContent();
    this.updateCode();
  }

  showClickEvent(event) {
    this.clickEventTemp = event ? '(onSelect)="selectEvent($event)"' : "";
    this.updateContent();
    this.updateCode();
  }

  routing(event) {
    this.ngRouterTemp = event ? `[ngRouterLink]="'/yourLinkHere'"` : "";
    this.updateContent();
    this.updateCode();
  }


  addHeaderIcon() {
    this.headerIcons = this.showIcons ? 'fa-solid fa-gear kn-icon-sm' : "";
    this.subTitle = this.showSubTitle ? 'Subtitle' : '';
    this.headerIconProperty = this.showIcons ? '[icon]="\'fa-solid fa-gear kn-icon-sm\'"' : '';
    this.updateContent();
    this.updateCode();
  }

  disableHeader() {
    this.headerDisabledProperty = this.header1Disabled ? '[disabled]="true"' : "";
    this.updateContent();
    this.updateCode();
  }

  setAutoCollapse() {
    if(!this.autoCollapse) {
      this.controlEvent(true, '[autoCollapse]=', 'false', 'bool');
    } else {
      this.controlEvent(false, '[autoCollapse]=', 'false', 'bool');
    }
  }

  closeAllEvent() {
    this.accordionService.closeAll('#exampleAccordion');
  }

  openAllEvent() {
    this.accordionService.openAll('#exampleAccordion');
  }
}
