import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-engaging-ux',
  templateUrl: './engaging-ux.component.html',
  styleUrls: ['./engaging-ux.component.sass']
})
export class EngagingUxComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
