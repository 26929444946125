<div class="page-container theming-page">
    <div class="section-container">
        <div class="kn-page-title">Theming</div>

        <section>
            <div class="text">The Kraken DS employs the practice of "theming" with carefully defined CSS properties to manage and maintain consistent styling across the UI for all applications that utilize the design system. Theming further allows the design system to expand and adapt to new application requirements and evolving brand standards over time.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Theme</div>
        </section>

        <section>
            <div class="text">The Kraken DS currently utilizes a single "Light" theme which sets the standard styles and CSS properties for Lighthouse SaaS application products. Additional themes are taken under consideration dependent on their strategic significance in supporting the business needs of the Lighthouse product portfolio. An example of a possible consideration is an alternate "Dark" theme to further support customer preferences and low-light use environments.</div>
        </section>
        
        <section>
            <div class="text">Kraken DS code components each reference the design system theme for CSS properties necessary to visually render the appropriate styling for the different component attributes and interaction states. It is worth noting the dependent relationship between components and the theme. As Kraken DS components are introduced and existing component versions are extended or enhanced, it will be necessary to upgrade to the most current version of the Kraken DS theme in order to adopt these component upgrades.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Installation</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Angular</div>
            <div class="text">Run the following command in your project</div>
            <pre class="code"><code id="install">npm install {{component.name}}@{{component.version}}</code><i class="fa-regular fa-copy copy kn-icon" (mouseover)="resetText()" tooltip="{{copyText}}" [tooltipDirection]="'top'" (click)="copy()"></i></pre>
        </section>

        <section>
            <div class="text">Import Kraken theme's main stylesheet</div>
            <pre class="code"><code>@import '../node_modules/@kraken-ui/kraken-theme/styles/main'</code></pre>
        </section>
        
        <section>
            <div class="kn-subtitle-16">Web or non Angular use</div>
            <div class="text">Download the Kraken DS web components <span class="kn-link"  (click)="docService.downloadWebComponents()"><span class="kn-bold">here</span></span>.</div>
        </section>
        <section>
            <div class="text">Add the following links to your website's <pre class="inline"><code>&lt;head&gt;</code></pre></div>
<pre class="code"><code>&lt;head&gt;
    &lt;link rel="stylesheet" href="styles.css"&gt;
    &lt;link rel="stylesheet" href="css/all.css"&gt;
    &lt;link rel="stylesheet" href="custom-icons/style.css"&gt;
&lt;/head&gt;</code></pre>
        <div class="text">The theme can be used with or without components installed. The theme by itself provides the following:</div>
        <ul>
            <li>classes</li>
            <li>font families</li>
            <li>icons</li>
            <li>colors</li>
        </ul>
        <div class="text">If you choose to use any of the Kraken components, you will need to add the following script to your website's <pre class="inline"><code>&lt;head&gt;</code></pre></div>
<pre class="code"><code>&lt;head&gt;
    &lt;script src="component-framework.js" async&gt;&lt;/script&gt;
&lt;/head&gt;</code></pre>
        </section>
        <section>
            <div class="kn-subtitle-16">Usage</div>
            <div class="text">Once the theme is installed, you will have access to all colors and classes. The following pages provide useful information about using the Kraken theme.</div>
            <ul>
                <li><div class="kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/palettes/color-palettes">Color Palettes</div></li>
                <li><div class="kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/typography/headings-subheadings">Headings and Subheadings</div></li>
                <li><div class="kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/typography/body-text">Body Text</div></li>
                <li><div class="kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/style-guide/elevation">Elevation</div></li>
                <li><div class="kn-link" [routerLinkActive]="['acc-active-link']" routerLink="/style-guide/motion-speed">Motion (Speed)</div></li>
            </ul>
            
        </section>

        <section>
            <div class="kn-alert-warning">
                <i class="fa-solid fa-triangle-exclamation"></i>Please note: When using this theme the following global properties are present and could cause conflicts with your current styling.
            </div>
            <pre><code>body</code></pre>
            <ul>
                <li>margin: 0</li>
                <li>padding: 0</li>
                <li>font-size: 14px</li>
                <li>font-family: 'Avenir-Book'</li>
                <li>line-height: 23px</li>
            </ul>

            <pre><code>*</code></pre>
            <ul>
                <li>box-sizing: border-box</li>
            </ul>

        </section>
    </div>
</div>