<div class="canvas-inner-container">
    <div class="section-container">
        <section>
            <div class="text">The <span class="kn-bold">Modal </span> is a secondary window that allows users to perform specific actions, asks the user a question, or provides the user with information. A modal is displayed in a (higher) layer over the content of the parent screen from where it was initiated and requires users to complete the task or close the modal before continuing engagement with content in the parent (lower layer) screen. </div>
            </section>
       
        <section>
            <div class="kn-headline-24-med">Anatomy</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_01.png">

        <section>
            <div class="kn-subtitle-16">Header </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Modal title: </span> All dialogs must have a title. Titles appear at the top of the dialog and contain a brief, clear statement. Titles do not wrap to a second line. If long titles occur, always place them as a section title within the content area. </div>
            <div class="text"><span class="kn-bold">B.	Steps label: </span> When the user needs to complete some steps before the task can be completed, display the steps count so that users can maintain the context of their task. </div>
            <div class="text"><span class="kn-bold">C.	Dismiss icon button: </span> Dialogs can be dismissed by clicking the close icon button displayed in the far-right of the modal header. </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_02.png">

        <section>
            <div class="kn-subtitle-16">Body </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Section title: </span> Dialogs can include section titles or descriptions. Descriptions briefly communicate any additional information or context that users need to know so they can make one of the decisions offered by the buttons. </div>
            <div class="text"><span class="kn-bold">B.	Body text </span> </div>
            <div class="text"><span class="kn-bold">C.	Subtitle </span> </div>
            <div class="text"><span class="kn-bold">D.	Section background </span> </div>
            <div class="text"><span class="kn-bold">E.	Container </span> </div>
            <div class="text"><span class="kn-bold">F.	Call-to-action buttons: </span> A dialog must have at least one button. The primary action label is for the first (right-most) button. It communicates what the button will do with a short, actionable phrase to either describe the next step if selected, or to acknowledge and dismiss the dialog. </div>
            <div class="text"><span class="kn-bold">G.	Scrollbar </span> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Scrim: </span> A scrim is a temporary treatment that can be applied to make content on the surface appear less prominent. </div>
        </section>   


        <section>
            <div class="kn-headline-24-med">Variants</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_03.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Single step modal </span> </div>
            <div class="text"><span class="kn-bold">2.	Multi-step modal </span> </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Single step modal</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_04.png">

        <section>
            <div class="text"><span class="kn-bold">1.	Information variant </span> </div>
            <div class="text"><span> Information dialogs communicate important information that users need to acknowledge. </span> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">2.	Destructive variant </span> </div>
            <div class="text"><span> Destructive dialogs are used to confirm an action that will impact a user's data or experience in a potentially negative way, such as deleting a file or contact. This dialog includes a red title background and red primary error button to highlight the destructive action. </span> </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">3.	Warning variant </span> </div>
            <div class="text"><span> Warning dialogs alert the user of a condition that might cause a problem in the future. This dialog includes an orange title background with a primary warning button. </span> </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Multi-step modal</div>
        </section>

        <section>
            <div class="text"><span> For longer tasks, use a multi-step modal dialog to give users a sense of completion and orientation within the focused flow. Multi-step modals consist of more than one step, where each step contains a set of controls and can have their own layout. </span> </div>
        </section>

        <section>
            <div class="text"><span> The main advantage of multi-step modals is to allow users to complete information in focused and digestible sections. Multi-step modals are indicated to the user through displaying a step count in the modal header. </span> </div>
        </section>

        <section>
            <ul class="bullets">
                <li>All modal steps do not need to be displayed in the same size, although it can be more ideal for transitioning.</li>
            </ul>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_05.png">

        <section>
            <div class="kn-subtitle-16">Step 1 </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Step count: </span> Consistently displaying the steps count allows users to maintain the context of their task. </div>
            <div class="text"><span class="kn-bold">B.	Modal title: </span> For a multi-step modal, use the same modal title for consistency. </div>
            <div class="text"><span class="kn-bold">C.	Next button: </span> Display the next button when an additional modal is required to complete the task. The next button is displayed as the primary action label (first right-most button). </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_06.png">

        <section>
            <div class="kn-subtitle-16">Step 2 - Interstitial step(s) </div>
            <div class="text"> Although the interstitial step is a transition between two main steps, it is important to maintain a consistent visual language and purpose to add value to the overall user journey.  </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Step count</span> </div>
            <div class="text"><span class="kn-bold">B.	Reiterated custom text entry: </span> For some specific cases, such as creating Matters, Clients, and Exports, the custom creation name entered from the first step text box and tied with the modal title is reiterated in the interstitial and final steps to help guide the user along the process.</div>
            <div class="text"><span class="kn-bold">C.	Back button: </span> Displayed after the first step, the back button gives the option to navigate back to the previous screen if users change their mind or reconsider their choices without feeling trapped or forced to proceed. The back button is displayed as the tertiary action button and is positioned on the left-side. </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_07.png">

        <section>
            <div class="kn-subtitle-16">Step 3 - Final step </div>
            <div class="text"> The final step is generally reserved as the confirmation/review modal for the user to confirm the tasks completed from the previous steps before submitting.  </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Step count </span> </div>
            <div class="text"><span class="kn-bold">B.	Review section </span> </div>
            <div class="text"><span class="kn-bold">C.	Step completion button </span>  </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Guidelines</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_08.png">
            
            <section>
                <div class="text"><span class="kn-bold">Modal overlay </span> </div>

                <div class="text">A modal contains a transparent background scrim, which partially obscures the content behind the modal. The overlay helps interrupt the on-page experience, which helps users focus on the task outlined in the modal. Ensure the overlay covers the page content and prevents the user from interacting with it until the action is complete or the modal is dismissed.</div>
            </section>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Modal title </span> </div>

            <div class="text">All modal dialogs must have a title. The title appears in the top-left of the dialog and uses a few words to convey the outcome of what happens when a user continues with an action.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_09.png">
            
            <section>
                <div class="text"><span class="kn-bold">Maximum height </span> </div>

                <div class="text">Each modal size has a maximum height of <span class="kn-bold">710px</span> to maintain a proper window ratio. All content must remain visible within the body of the dialog. If you encounter difficulty displaying all information within the modal size restraints, consider using the next modal size up or splitting the content into multiple steps.</div>
            </section>
        </section>

        <section>
            <section>
                <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_10.png">
            </section>

            <section>
                <div class="text"><span class="kn-bold">Scrolling behavior </span> </div>

                <div class="text">Modal dialogs differ in width, whereas the height is determined by the content. Once it reaches a certain threshold, the body content will scroll within the inner container while the header and call-to-action buttons remain fixed in place. </div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_24.png">
        
            <section>
                <div class="text">When displaying data grids, the scroll bar is positioned within the grid container along the far right. Displayed at a fixed height, the data grid header is sticky, allowing the user to maintain visibility and context of grid rows when vertically scrolling. </div>
            </section>
        </section>

        <section>
            <ul class="bullets">
                <li>Try to avoid requiring users to scroll, as it decreases usability.</li>
                <li>Never allow modal content to scroll horizontally; instead use a larger size modal.</li>
            </ul>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_11.png">

            <section>
                <div class="text"><span class="kn-bold">Positioning </span> </div>

                <div class="text">Modal dialogs retain focus until dismissed or users take an action. Ensure modal dialogs are not obscured by other elements or appear partially on screen. For all modal width sizes (small, default and large), modal windows are horizontally centered within the user's browser window and consistently sits <span class="kn-bold"> 100px</span> below the top of the masthead.</div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_12.png">

            <section>
                <div class="text"><span class="kn-bold">Dismissing a modal </span> </div>

                <div class="text">Modal dialogs are persistent until users either click the dismiss icon button displayed in the upper-right of the modal header or initiate an action. Clicking outside the modal area does not automatically close the modal. User must not be allowed to interact with any element on the page outside of that modal dialog.</div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_13.png">

            <section>
                <div class="text"><span class="kn-bold">Loading </span> </div>

                <div class="text">The task completion action happens immediately. If a longer loading period is needed, then ensure a spinner component and overlay scrim appears on the top of the modal body area with content disabled.</div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_14.png">

            <section>
                <div class="text"><span class="kn-bold">Validation </span> </div>

                <div class="text">Validate a user’s entries before the modal is closed. If any entry is invalid, then ensure the modal remains open with the entry marked in an error state that includes an inline error message. The message inform the user about what happened and provides guidance on next steps or possible resolutions.</div>
            </section>
        </section>

        <section>
            <div class="text">Client-side validation occurs as soon as the field loses focus. This helps easily identify the elements that need to be corrected. If the data is not submitted due to server-side issues, then ensure an inline alert banner appears.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Client-side validation error </span> </div>
            <div class="text"><span class="kn-bold">B.	Server-side validation alert banner</span> </div>
        </section>


        <section>
            <div class="kn-headline-24-med">Sizing</div>
        </section>

        <section>
            <div class="text">Modal dialogs are displayed at four static non-responsive sizes: small, default, large, and extra large. Choose a size that works best for the amount of model content you have. Ensure a small modal is used for modals with short messages to avoid long single lines. The default size for complex components, (and large and extra large for special use cases), requires additional space for users to perform necessary and focused actions. No full-screen dialogs are ever used.</div>
        </section>

        <section>
            <div class="text">Each modal size has a max height to maintain a proper window ratio. Ensure all content remains visible within the body of the dialog. If you encounter difficulty displaying all information within the modal size restraints, consider using the next modal size up or splitting the content into multiple steps.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_15.png">

        <section>
            <div class="text"><span class="kn-bold">Small </span> </div>
            <div class="text">480px</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_16.png">

        <section>
            <div class="text"><span class="kn-bold">Default </span> </div>
            <div class="text">656px</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_17.png">

        <section>
            <div class="text"><span class="kn-bold">Large </span> </div>
            <div class="text">750px</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_18.png">

        <section>
            <div class="text"><span class="kn-bold">Extra large </span> </div>
            <div class="text">1040px</div>
        </section>


        <section>
            <div class="kn-headline-24-med">Call-to-action buttons</div>
        </section>

        <section>
            <div class="text">Call-to-action buttons are prominently oriented along the bottom of the modal dialog. Depending on the modal requirements and size, all dialogs must display at least one call-to-action button. The primary action label is for the first (right-most) button. It communicates what the button does with a short, actionable phrase to either describe the next step if selected, or to acknowledge and dismiss the dialog.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Ordering </span> </div>

            <div class="text">When using multiple buttons, (dialogs and modal windows, for example), place call-to-action buttons horizontally and right-aligned with the primary action button, and place them to the right of the secondary and tertiary buttons.</div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_19.png">

        <section>
            <div class="kn-subtitle-16">Single step modal </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Primary action button</span> </div>
            <div class="text"><span class="kn-bold">B.	Flat button </span> </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_20.png">

        <section>
            <div class="kn-subtitle-16">Multi-step modal (three button) </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Primary action button</span> </div>
            <div class="text"><span class="kn-bold">B.	Flat button </span> </div>
            <div class="text"><span class="kn-bold">C.	Tertiary button </span> </div>
        </section>

        <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_21.png">

        <section>
            <div class="kn-subtitle-16">Multi-step modal (four button) </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">A.	Primary action button</span> </div>
            <div class="text"><span class="kn-bold">B.	Secondary action button </span> </div>
            <div class="text"><span class="kn-bold">C.	Flat button </span> </div>
            <div class="text"><span class="kn-bold">D.	Tertiary button </span> </div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_22.png">

            <section>
                <div class="text"><span class="kn-bold">Destructive variant </span> </div>

                <div class="text">The danger modal dialog is a specific modal used for destructive or irreversible actions. In this case, the primary action button is replaced by a primary error button.</div>
            </section>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/usage guidelines/modal/KrakenDSM_Components_Modal_23.png">

            <section>
                <div class="text"><span class="kn-bold">Warning variant </span> </div>

                <div class="text">The warning modal dialog is another specific modal, which alerts users of a condition that might cause a problem in the future. The primary action button is replaced with a primary warning button.</div>
            </section>
        </section>



        <section>
            <div class="kn-headline-24-med">Avoid</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Overuse</div>

            <div class="text">Do not overuse or rely on the use of modals in your user application flow since they tend to cause interruptions when completing tasks and can make applications feel tedious to use.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Launching another modal</div>

            <div class="text">Avoid launching a modal dialog from the actions within another modal dialog.</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Notifications</div>

            <div class="text">Do not use modals to display messages or to show when users complete actions or acknowledge error notifications. Consider using the Notifications component in these cases.</div>
        </section>
    </div>
</div>