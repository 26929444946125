import { Component } from '@angular/core';

@Component({
  selector: 'split-button-usage',
  templateUrl: './split-button-usage.component.html',
  styleUrls: ['./split-button-usage.component.sass']
})
export class SplitButtonUsageComponent {

}
