<app-canvas 
    [ts]="true" 
    [js]="true" 
    [disableCanvasTab]="5" 
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [component]="component"
    [trigger]="{angular: triggerButton, web: triggerButtonWeb}">
    <div title>Contextual Menu <span class="component-version">v{{componentVersion}}</span></div>
    <div component>
        <div *ngIf="rightClick" id="exampleRightClickTrigger" class="click-zone">Right click in here</div>
        <kn-icon-button 
            [hidden]="rightClick" 
            [buttonId]="'contextualTrigger'" 
            [buttonType]="'kn-flat kn-btn-sm'" 
            [icon]="'fa-solid fa-gear'"
            [active]="contextualMenuIsOpen">
        </kn-icon-button>
        <kn-contextual-menu
            [componentId]="'dropdownComponentId'"
            [triggerId]="triggerId"
            [items]="data"
            [rightClick]="rightClick"
            (onSelect)="onSelect($event)"
            (isOpen)="contextualMenuIsOpen = $event">
        </kn-contextual-menu>
    </div>
    <div angular>
        <br/>
        <span *ngIf="!rightClick" class="code-comment">&lt;!--You can use any element as the trigger for the contextual menu. The example above uses the icon button component--></span>

        <span *ngIf="rightClick" class="code-comment">&lt;!--When using the menu for right click, any element can be used as the trigger. Note: you must add the "rightClick" property as shown above--></span>
    </div>
    <div typeScript>
            <span class="code-comment">//This is the data structure for setting up the contextual menu. Note: A second column can be added using the "meta" property</span>
            <span class="code-comment">//You can add an icon by adding the "icon" property. An example is shown from the icon toggle in the modifiers for this demo</span>
            <span class="code-comment">//You can add a divider by adding the "divider" property as shown</span>
            <span class="code-comment">//You can disable any row by adding the "disabled" property and setting it to "true"</span>
            <span class="code-comment">//NOTE: The contextual menu looks for these specific property names, however you can add as many other properties as you like.</span>
        <div *ngIf="!addIcons"> 
            {{typeScriptInstance}}
            {{dataWithOutIcons}}
        </div>
        <div *ngIf="addIcons">
            {{typeScriptInstance}}
            {{dataWithIcons}}
        </div>
    </div>
    <div javascript>
            <span class="code-comment">//This is the data structure for setting up the contextual menu. Note: A second column can be added using the "meta" property</span>
            <span class="code-comment">//You can add an icon by adding the "icon" property. An example is shown from the icon toggle in the modifiers for this demo</span>
            <span class="code-comment">//You can add a divider by adding the "divider" property as shown</span>
            <span class="code-comment">//You can disable any row by adding the "disabled" property and setting it to "true"</span>
            <span class="code-comment">//NOTE: The contextual menu looks for these specific property names, however you can add as many other properties as you like.</span>
        <div *ngIf="!addIcons">
            {{webItemsInstance}}
            {{dataWithOutIcons}}
        </div>
        <div *ngIf="addIcons">
            {{webItemsInstance}}
            {{dataWithIcons}}
        </div>
    </div>
    <div web></div>
    <div events>
        <div class="events">
            <div class="event-item" [ngClass]="{'active': event1}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event1 = !event1"></i> 
                <div class="obj">onSelect():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">id:</span> "{{onSelectItems?.id}}"</div>
                    <div *ngIf="onSelectItems?.meta" class="obj-item"><span class="label">meta:</span> "{{onSelectItems?.meta}}"</div>
                    <div class="obj-item"><span class="label">name:</span> "{{onSelectItems?.name}}"</div>
                    <div *ngIf="onSelectItems?.icon" class="obj-item"><span class="label">icon:</span> "{{onSelectItems?.icon}}"</div>
                    <div *ngIf="onSelectItems?.divider" class="obj-item"><span class="label">divider:</span> "{{onSelectItems?.divider}}"</div>
                </div>
            </div>
            <div class="event-item" [ngClass]="{'active': event2}">
                <i class="fa-solid fa-caret-right event-caret" (click)="event2 = !event2"></i> 
                <div class="obj">isOpen():</div>
                <div class="obj-items">
                    <div class="obj-item"><span class="label">{{contextualMenuIsOpen}}</span></div>
                </div>
            </div>
        </div>
    </div>
    <div customproperties>
        <div class="section-title" id="exampleRightClickZone2">Modifiers</div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Enable right click'" 
                (value)="rightClickEvent($event)" 
                [(ngModel)]="rightClick">
            </kn-toggle>
        </div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Dropdown with icons'" 
                (value)="withIcons($event);" 
                [(ngModel)]="addIcons">
            </kn-toggle>
        </div>
    </div>
    
    <div props>
        <div class="canvas-inner-container">
            <div class="prop-header">
                <div class="item">Decorator</div>
                <div class="item">Property</div>
                <div class="item">Type</div>
                <div class="item">Example Value</div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">componentId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"exampleDropdownList"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">triggerId</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"contextualTrigger"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">menuWidth</div>
                <div class="item"><i>string</i></div>
                <div class="item"><i>"300px", "max-content", "100%"</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">items</div>
                <div class="item"><i>object</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Input</div>
                <div class="item">rightClick</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">onSelect</div>
                <div class="item"><i>object</i></div>
                <div class="item"><i>{{'{ }'}}</i></div>
            </div>
            <div class="prop">
                <div class="item">@Output</div>
                <div class="item">isOpen</div>
                <div class="item"><i>boolean</i></div>
                <div class="item"><i>true</i></div>
            </div>
        </div>
    </div>
    <div doc>
        <div class="canvas-inner-container">
            <div class="kn-bold">Install the package</div>
            <pre><code id="install"></code></pre>
            <br/>
            <div class="kn-bold">app.module.ts</div>
            <pre><code id="register"></code></pre>
            <br/>
            <div class="kn-bold">styles.sass (main style sheet)</div>
            <pre><code id="stylesheet"></code></pre>
            <div class="kn-bold">component.html</div>
            <pre><code id="html"></code></pre>
        </div>
    </div>
    <div usage>
        <contextual-menu-usage></contextual-menu-usage>
    </div>
    <div designSpec>
        <contextual-menu-specs></contextual-menu-specs>
    </div>
    <div accessibility>
        <contextual-menu-accessibility></contextual-menu-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Release</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.4</div>
                <div class="item">
                    <ul>
                        <li>Added addition unit testing after component was published</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">September 7, 2023</div>
                <div class="item">1.0.3</div>
                <div class="item">
                    <ul>
                        <li>Added unit testing with 80% + code coverage</li>
                        <li>Fixed linting errors</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Aug 15, 2023</div>
                <div class="item">1.0.2</div>
                <div class="item">
                    <ul>
                        <li>bug fix - sub menus where exceeding the browser width after adding the "menuWidth" property. Fixed this issue and bumped the version in orderto publish the package</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Aug 15, 2023</div>
                <div class="item">1.0.1</div>
                <div class="item">
                    <ul>
                        <li>Added a "menuWidth" property to the component after it was published. A new version was required to publish the updated package.</li>
                    </ul>
                </div>
            </div>
            <div class="entry">
                <div class="item">Aug 15, 2023</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Created the contextual menu component</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    
</app-canvas>