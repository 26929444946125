<div class="side-nav">
  <div class="site-map-search">
    <kn-autocomplete [data]="siteMap" [keyWord]="'tags'" [fields]="fields" (onSelect)="onSelect($event)"></kn-autocomplete>
  </div>
  <div class="menu">
    <kn-accordion [autoCollapse]="true" #knAccordion>
      <!--Home-->
      <kn-accordion-tier-one 
        [id]="'group-7'"  
        [ngRouterLink]="'home'"
        [title]="'Home'">
        <div title>Home</div>
      </kn-accordion-tier-one>
      <!--About-->
      <kn-accordion-tier-one 
        [id]="'group-5'" >
        <div title>About</div>
        <div content>
          <kn-accordion-tier-two
          [ngRouterLink]="'/about/why-dsm'">
          <div title>Why a Design System?</div>
        </kn-accordion-tier-two>
        <kn-accordion-tier-two
          [ngRouterLink]="'/about/engaging-ux'">
          <div title>Engaging the UX Team</div>
        </kn-accordion-tier-two>
        <kn-accordion-tier-two
          [ngRouterLink]="'/about/process'">
          <div title>Process</div>
        </kn-accordion-tier-two>
        <kn-accordion-tier-two
          [ngRouterLink]="'/about/accessibility'">
          <div title>Accessibility</div>
        </kn-accordion-tier-two>
        <kn-accordion-tier-two
          [ngRouterLink]="'/about/integration'">
          <div title>Browser Support</div>
        </kn-accordion-tier-two>
        </div>
      </kn-accordion-tier-one>
      <!--Getting started-->
      <kn-accordion-tier-one 
        [id]="'group-15'" >
        <div title>Getting Started</div>
          <div content>
            <!--For designers-->
            <kn-accordion-tier-two
              [id]="'sub-group-1'">
              <div title>For Designers</div>
              <div content>
              <kn-accordion-tier-three
                [ngRouterLink]="'/getting-started/design-principles'">
                <div title>Design Principles</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/getting-started/ui-kit'">
                <div title>UI Design Kit</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/getting-started/design-resources'">
                <div title>Design Resources</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
          <!--For developers-->
          <kn-accordion-tier-two
            [id]="'sub-group-15'">
            <div title>For Developers</div>
            <div content>
              <kn-accordion-tier-three
              [ngRouterLink]="'/getting-started/ui-framework'">
              <div title>UI Framework</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/getting-started/development-resources'">
                <div title>Development Resources</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three 
                [ngRouterLink]="'/getting-started/theming'">
                <div title>Theming</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
        </div>
      </kn-accordion-tier-one>
      <!--Style guide-->
      <kn-accordion-tier-one 
        [id]="'group-2'" >
        <div title>Style Guide</div>
        <div content>
          <!--Color-->
          <kn-accordion-tier-two
            [id]="'sub-group-3'">
            <div title>Color</div>
            <div content>
              <kn-accordion-tier-three
                [ngRouterLink]="'/palettes/color-palettes'">
                <div title>Palettes</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/palettes/layout-background'">
                <div title>Layout Background</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/palettes/typography'">
                <div title>Typography</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/palettes/buttons'">
                <div title>Buttons</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/palettes/iconography'">
                <div title>Iconography</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/palettes/notifications'">
                <div title>Notifications & Alerts</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
          <!--Content-->
          <kn-accordion-tier-two
            [id]="'sub-group-14'">
            <div title>Content</div>
            <div content>
              <kn-accordion-tier-three
                [ngRouterLink]="'/style-guide/content-usage'">
                <div title>Usage & Grammar</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/style-guide/content-formatting'">
                <div title>Formatting</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/style-guide/content-notifications'">
                <div title>Notifications</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/style-guide/content-validation'">
                <div title>Validation Errors</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
          <!--Elevation-->
          <kn-accordion-tier-two 
            [ngRouterLink]="'/style-guide/elevation'">
            <div title>Elevation</div>
          </kn-accordion-tier-two>
          <!--Iconography-->
          <kn-accordion-tier-two
            [id]="'sub-group-4'">
            <div title>Iconography</div>
            <div content>
              <kn-accordion-tier-three
                [ngRouterLink]="'/icon-catalog'">
                <div title>Icon Catalog</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/icon-styles'">
                <div title>Icon Styles</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
          <!--Layout grid-->
          <kn-accordion-tier-two
            [ngRouterLink]="'/style-guide/grid-layout'">
            <div title>Layout Grid</div>
          </kn-accordion-tier-two>
          <!--Logos-->
          <kn-accordion-tier-two
            [ngRouterLink]="'/style-guide/logos'">
            <div title>Logos</div>
          </kn-accordion-tier-two>
          <!--Motion-->
          <kn-accordion-tier-two
            [id]="'sub-group-6'">
            <div title>Motion</div>
            <div content>
              <kn-accordion-tier-three
                [ngRouterLink]="'/style-guide/motion-speed'">
                <div title>Speed</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/style-guide/motion-easing'">
                <div title>Easing</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/style-guide/motion-effects'">
                <div title>Effects</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
          <!--Spacing and padding-->
          <kn-accordion-tier-two
          [ngRouterLink]="'/style-guide/spacing'">
          <div title>Spacing & Padding</div>
        </kn-accordion-tier-two>
        <!--Typography-->
        <kn-accordion-tier-two
          [id]="'sub-group-2'">
          <div title>Typography</div>
          <div content>
            <kn-accordion-tier-three
              [ngRouterLink]="'/typography/fonts'">
              <div title>Fonts</div>
            </kn-accordion-tier-three>
            <kn-accordion-tier-three
              [ngRouterLink]="'/typography/headings-subheadings'">
              <div title>Heading & Subheading</div>
            </kn-accordion-tier-three>
            <kn-accordion-tier-three
              [ngRouterLink]="'/typography/body-text'">
              <div title>Body Text</div>
            </kn-accordion-tier-three>
            <kn-accordion-tier-three
              [ngRouterLink]="'/typography/labels-values'">
              <div title>Form Labels</div>
            </kn-accordion-tier-three>
            <kn-accordion-tier-three
              [ngRouterLink]="'/typography/capitalization'">
              <div title>Capitalization</div>
            </kn-accordion-tier-three>
          </div>
        </kn-accordion-tier-two>
        <!--Layout grid-->
        <!-- <kn-accordion-tier-two
          [ngRouterLink]="'/style-guide/tile'">
          <div title>Tile</div>
        </kn-accordion-tier-two> -->
        </div>
      </kn-accordion-tier-one>
      <!--Components-->
      <kn-accordion-tier-one 
        [id]="'group-3'" >
        <div title>Components</div>
        <div content>
          <!--Buttons-->
          <kn-accordion-tier-two
            [id]="'sub-group-9'" >
            <div title>Buttons</div>
            <div content>
              <kn-accordion-tier-three
                [ngRouterLink]="'/action-button'">
                <div title>Action Button</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/button-group'">
                <div title>Button Group</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/icon-button'">
                <div title>Icon Button</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/menu-button'">
                <div title>Menu Button</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three 
                [ngRouterLink]="'/split-button'">
                <div title>Split Button</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
          <!--Dialogs-->
          <kn-accordion-tier-two
            [id]="'sub-group-11'" >
            <div title>Dialogs</div>
            <div content>
              <kn-accordion-tier-three
                [ngRouterLink]="'/modal'">
                <div title>Modal</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/tooltip'">
                <div title>Tooltip</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
          <!--Fields and Controls-->
          <kn-accordion-tier-two
            [id]="'sub-group-10'" >
            <div title>Fields and Controls</div>
            <div content>
              <kn-accordion-tier-three
                [ngRouterLink]="'/accordion'">
                <div title>Accordion</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/checkbox'">
                <div title>Checkbox</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/chip'">
                <div title>Chip</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/date-picker'">
                <div title>Date Picker</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/date-range-picker'">
                <div title>Date Range Picker</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three 
                [ngRouterLink]="'/multi-select'">
                <div title>Multi-Select Dropdown</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/radio-button'">
                <div title>Radio Button</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/searchbox'">
                <div title>Search Box</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/dropdown'">
                <div title>Single-Select Dropdown</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/slider'">
                <div title>Slider</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/spin-box'">
                <div title>Spin Box</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/textarea'">
                <div title>Text Area</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/textbox'">
                <div title>Text Box</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/toggle'">
                <div title>Toggle Switch</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
          <!--Grids-->
          <kn-accordion-tier-two
            [id]="'sub-group-16'" >
            <div title>Grids</div>
            <div content>
              <!--Data Grid-->
              <kn-accordion-tier-three
                [ngRouterLink]="'/data-grid'">
                <div title>Data Grid</div>
              </kn-accordion-tier-three>
              <!--Spreadsheet-->
              <kn-accordion-tier-three
                [ngRouterLink]="'/spreadsheet'">
                <div title>Spreadsheet</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
          <!--Layout-->
          <kn-accordion-tier-two
            [id]="'sub-group-13'" >
            <div title>Layout</div>
            <div content>
              <kn-accordion-tier-three
                [title]="'Panel'" 
                [ngRouterLink]="'/panel'">
                <div title>Panel</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
          <!--Navigation-->
          <kn-accordion-tier-two
            [id]="'sub-group-8'" >
            <div title>Navigation</div>
            <div content>
              <kn-accordion-tier-three
                [ngRouterLink]="'/contextual-menu'">
                <div title>Contextual Menu</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/footer'">
                <div title>Footer</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/masthead'">
                <div title>Masthead</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/tabs'">
                <div title>Tabs</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
          <!--Notifications-->
          <kn-accordion-tier-two
            [id]="'sub-group-12'" >
            <div title>Notifications</div>
            <div content>
              <kn-accordion-tier-three
                [ngRouterLink]="'/progress-indicator'">
                <div title>Progress Indicator</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/toast'">
                <div title>Toast</div>
              </kn-accordion-tier-three>
              <kn-accordion-tier-three
                [ngRouterLink]="'/spinner'">
                <div title>Spinner</div>
              </kn-accordion-tier-three>
            </div>
          </kn-accordion-tier-two>
        </div>
      </kn-accordion-tier-one>
      <!--Support-->
      <kn-accordion-tier-one 
        [id]="'group-4'" >
        <div title>Support</div>
        <div content>
          <!--Navigation-->
          <!-- <kn-accordion-tier-two
            [ngRouterLink]="'/support/contact'">
            <div title>Contact</div>
          </kn-accordion-tier-two> -->
          <kn-accordion-tier-two
            [ngRouterLink]="'/support/release-notes'">
            <div title>Release Notes</div>
          </kn-accordion-tier-two>
          <!-- <kn-accordion-tier-two
            [ngRouterLink]="'/support/roadmap'">
            <div title>Roadmap</div>
          </kn-accordion-tier-two> -->
        </div>
      </kn-accordion-tier-one>
    </kn-accordion>
  </div>
</div>
