
<div class="kn-menu-btn" id="{{componentId}}">
    <button id="{{buttonId}}" class="kn-btn {{buttonType}}" 
        [ngClass]="{
            'kn-loading': loading, 
            'disabled': disabled,
            'focus': showDropdown
            }"
        aria-label="buttonText"
        (click)="onClick()"
        tabindex="0"
        (keydown)="keydown($event)">
        <i 
            *ngIf="buttonIconLeft" 
            class="{{buttonIconLeft}} icon-left"></i>
        <span class="btn-text" *ngIf="buttonText">{{buttonText}}</span>
        <i 
            class=" icon-right fa-regular fa-angle-down"
            [ngClass]="{'rotate': showDropdown}"></i>
    </button>
    <!--Dropdown section-->
    <div 
        class="kn-dropdown" 
        [ngClass]="
        {
            'show': showDropdown, 
            'kn-btn-dropdown-content-width': contentWidth
        }" 
        [ngStyle]="
        {
            'top': topPos, 
            'width': dropdownCustomWidth ? dropdownCustomWidth : width,
            'height': dropdownCustomHeight ? dropdownCustomHeight : height,
            'left': leftPos ? leftPos : 'unset'
        }">
        <!--Object arrays-->
        <ng-container *ngIf="fields">
            <ng-container *ngFor="let key of source">
                <div class="kn-dropdown-item"  
                    [ngClass]="{'disabled': key.origin.disabled}"
                    (click)="valueChange(key)">
                    <div *ngIf="!template">{{key.text}}</div>
                    <!--custom row template-->
                    <div *ngIf="template">
                        <ng-container [ngTemplateOutlet]="customTemplate" [ngTemplateOutletContext]="{data: key.origin}"></ng-container>
                    </div>
                </div>
                <div *ngIf="key.origin.divider" class="kn-divider"></div>
            </ng-container>
        </ng-container>

        <!--Non Object arrays-->
        <ng-container *ngIf="!fields">
            <ng-container *ngFor="let key of items">
                <div class="kn-dropdown-item"  (click)="valueChange(key)">
                    <div *ngIf="!template">{{key}}</div>
                    <div *ngIf="template">
                        <ng-container [ngTemplateOutlet]="customTemplate" [ngTemplateOutletContext]="{data: key}"></ng-container>
                    </div>
                </div>
            </ng-container>
        </ng-container>

        <!--No items provided-->
        <ng-container *ngIf="!items">
            <ng-container [ngTemplateOutlet]="customTemplate"></ng-container>
        </ng-container>
    </div>
</div>
