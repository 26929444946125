<div class="kn-modal">
    <div 
        class="kn-modal-dialog" 
        [ngClass]="{
            'kn-warning-modal': type == 'warning',
            'kn-danger-modal': type == 'danger',
            'kn-wizard-modal': type == 'wizard',
            'modal-lg': size == 'lg',
            'modal-lx': size == 'xl',
            'modal-sm': size == 'sm'
        }"
        [ngStyle]="{'width': customWidth}"
        id="{{id}}" 
        tabindex="-1" 
        role="dialog" 
        aria-hidden="true" 
        data-backdrop="static" 
        data-keyboard="false">
        <div class="modal-header" [ngClass]="{'danger': type == 'danger', 'warning': type == 'warning'}">
            <div class="modal-title">{{title}}</div><div class="header-text">{{subText}}</div>
            <i class="fa fa-solid fa-regular fa-xmark close-modal" (click)="close()"></i>
        </div>
        <div class="kn-modal-error" *ngIf="modalError">
            <div class="error-icon"><i class="fa-solid fa-triangle-exclamation kn-icon-sm"></i></div>
            <div class="error-mess" [innerHTML]="modalError"></div>
        </div>
        <div class="modal-content">
            <ng-content></ng-content>
        </div>
    </div>
</div>