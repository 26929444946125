import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'kn-footer',
  templateUrl: './kraken-footer.component.html'
})
export class KrakenFooterComponent implements OnInit {
  @Input() id: string;
  @Input() sticky = false;
  
  public fullYear;
  constructor() { }

  ngOnInit(): void {
    this.fullYear = new Date().getFullYear();
  }

}
