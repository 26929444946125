<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Speed</div>
        
        <section>
            <div class="text">Adjustments to speed make transitions smooth and responsive.</div>
        </section>

        <section>
            <div class="text">Speed adjustments include <span class="kn-bold">duration</span> (how long a transition lasts) and <span class="kn-bold">easing</span> (acceleration over time). Controlling transitions by adjusting speed helps users understand UI changes without complicating tasks.</div>
        </section>
       
        <section>
            <div class="kn-headline-24-med">Duration</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/motion/speed/KrakenDSM_StyleGuide_Motion_Speed_01.png">
        </section>

        <section>
            <div class="kn-headline-24-med">1.  Micro-motion</div>
        </section>

        <section>
            <div class="text">Seamless transition between states in an interface. Micro-motion is a subtle way to help focus and guide users.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Immediate response (0 - 150 ms)</span></div>
            <div class="text">This duration applies when the user directly manipulates the content. If animation of this type is needed, the device must react instantaneously and the animation should last no longer than 150 ms.
                <ul>
                    <li>Icon</li>
                    <li>Toggle</li>
                </ul>
            </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Small moves (150 ms - 250 ms)</span></div>
            <div class="text">In some cases, the application responds to an interaction so fast that users can lose track of what's happening on the screen. To present the feedback in a more natural way, we show short animations when elements move between positions or states.
                <ul>
                    <li>Buttons</li>
                    <li>Menu Dialog</li>
                </ul>
            </div>
        </section>

        <section>
            <div class="kn-headline-24-med">2.  Macro-motion</div>
        </section>

        <section>
            <div class="text">Macro-motions cover larger amounts of visual ground. This includes the grid, grid panel and toast notifications.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Large moves (250 ms - 500 ms)</span></div>
            <div class="text">If you need to depict a large movement on the screen, use a longer animation duration to avoid fast flashing movements.
                <ul>
                    <li>Details Panel</li>
                    <li>Stats Panel</li>
                </ul>
            </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Extended time moves (5,000 ms - 7,000 ms)</span></div>
            <div class="text">The larger and more complex a component is, the longer animation duration is needed to avoid the user feeling rushed.
                <ul>
                    <li>Toast Notification</li>
                </ul>
            </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Continuous animations</span></div>
            <div class="text">Indeterminate progress circles are used to display when a progress is happening but the time or effort to completion can't be determined. </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Duration 01</span></div>
            <div class="text">100 ms - Immediate Response</div>
            <span class="codesnippet">Theme variable: <pre><code>$kn-transition-100</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-transition-100</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Duration 02</span></div>
            <div class="text">150 ms - Immediate Response</div>
            <span class="codesnippet">Theme variable: <pre><code>$kn-transition-150</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-transition-150</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Duration 03</span></div>
            <div class="text">200 ms - Immediate Response</div>
            <span class="codesnippet">Theme variable: <pre><code>$kn-transition-200</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-transition-200</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Duration 04</span></div>
            <div class="text">250 ms - Immediate Response</div>
            <span class="codesnippet">Theme variable: <pre><code>$kn-transition-250</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-transition-250</code></pre></span>
        </section>
        <section>
            <div class="text"><span class="kn-bold">Duration 05</span></div>
            <div class="text">300 ms - Immediate Response</div>
            <span class="codesnippet">Theme variable: <pre><code>$kn-transition-300</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-transition-300</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Duration 06</span></div>
            <div class="text">400 ms - Immediate Response</div>
            <span class="codesnippet">Theme variable: <pre><code>$kn-transition-400</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-transition-400</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Duration 07</span></div>
            <div class="text">500 ms - Immediate Response</div>
            <span class="codesnippet">Theme variable: <pre><code>$kn-transition-500</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-transition-500</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Duration 08</span></div>
            <div class="text">- 5,000 ms - Extended Time Moves</div>
            <span class="codesnippet">Theme variable: <pre><code>$kn-transition-5s</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-transition-5s</code></pre></span>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Duration 09</span></div>
            <div class="text">- 7,000 ms - Extended Time Moves</div>
            <span class="codesnippet">Theme variable: <pre><code>$kn-transition-7s</code></pre></span>
            <span class="codesnippet">CSS variable: <pre><code>--kn-transition-7s</code></pre></span>
        </section>
    </div>
</div>