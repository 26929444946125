<nav class="kn-tab-container" 
  id="{{id}}"
  [ngStyle]="{
    'width': width,
    'max-width': maxWidth,
    'min-width': minWidth
  }">
  <div class="kn-tab-header" #knTabs>
    <ul class="kn-tabs">
      <li class="tab-links" id="{{tab.id}}" *ngFor="let tab of tabs; let i = index" (click)="tabClick(tab, i, false)" [ngClass]="{'disabled': tab.disabled && !tab.active}">
        <div class="link" tabindex [ngClass]="{'active-link': tab.active}">{{tab.title}}</div>
      </li>

      <!--More items menu-->
      <li class="kn-tab-menu-container" [ngClass]="{'hide': !hiddenItems.length}">
        <div class="link" [ngClass]="{'active-link': isHiddenTabSelected}">
          <kn-icon-menu
            [icon]="'fa-regular fa-ellipsis-vertical'"
            [template]="true"
            [dropdownCustomWidth]="'max-content'">
            <ng-template  #customTemplate>
              <div class="kn-dropdown-item" [ngClass]="{'active': tab.active, 'disabled': tab.disabled}" *ngFor="let tab of hiddenItems; let i = index" (click)="tabClick(tab, i, true)">
                {{tab.title}}
              </div>
            </ng-template>
          </kn-icon-menu>
      </div>
      </li>
    </ul>
  </div>
  <ng-content></ng-content>
</nav>