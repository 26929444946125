import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.sass']
})
export class TileComponent {
  public legendItems =
  [
    {name: "Default", link: 'default'},
    {name: "Filled", link: 'filled'},
    {name: "Outlined (Warning)", link: 'warning'},
    {name: "Outlined (Error)", link: 'error'},
    {name: "Single tile (single column)", link: 'stsc'},
    {name: "Single tile (two column)", link: 'st2c'},
    {name: "Single tile (three column)", link: 'st3c'},
    {name: "Single tile (four column)", link: 'st4c'},
    {name: "Single tile (two column 25% / 75%)", link: 'st2575'},
    {name: "Single tile (mixed)", link: 'stmixed'},
    {name: "Multi tile (inline)", link: 'mtinline'},
    {name: "Multi tile (inline 25% /75%)", link: 'mt2575'},
  ]

  public filled: string = 
  `<div class="kn-tile filled">
  <div class="kn-row">
      <div class="kn-col">
          <kn-textbox 
              [label]="'First name'" 
              [name]="'textboxName'">
          </kn-textbox>
      </div>
      <div class="kn-col">
          <kn-textbox 
              [label]="'Last name'" 
              [name]="'textboxName'">
          </kn-textbox>
      </div>
  </div>
  <div class="kn-row">
      <div class="kn-col">
          <kn-textarea 
              [label]="'Description'" 
              [name]="'exampleTextarea'">
          </kn-textarea>
      </div>
  </div>
</div>`

public default: string = 
  `<div class="kn-tile">
  <div class="kn-row">
      <div class="kn-col">
          <kn-textbox 
              [label]="'First name'" 
              [name]="'textboxName'">
          </kn-textbox>
      </div>
      <div class="kn-col">
          <kn-textbox 
              [label]="'Last name'" 
              [name]="'textboxName'">
          </kn-textbox>
      </div>
  </div>
  <div class="kn-row">
      <div class="kn-col">
          <kn-textarea 
              [label]="'Description'" 
              [name]="'exampleTextarea'">
          </kn-textarea>
      </div>
  </div>
</div>`

public warning: string = 
`<div class="kn-tile warning">
  <div class="kn-row">
      <div class="kn-col">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
      </div>
  </div>
</div>`

public error: string = `<div class="kn-tile error">
  <div class="kn-row">
      <div class="kn-col">
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
      </div>
  </div>
</div>`

public stsc =
`<div class="kn-tile filled">
<div class="kn-row">
    <div class="kn-col"></div>
</div>
</div>`

public st2c =
`<div class="kn-tile filled">
  <div class="kn-row">
      <div class="kn-col"></div>
      <div class="kn-col"></div>
  </div>
  <div class="kn-row">
      <div class="kn-col"></div>
      <div class="kn-col"></div>
  </div>
</div>`

public st3c =
`<div class="kn-tile filled">
  <div class="kn-row">
      <div class="kn-col"></div>
      <div class="kn-col"></div>
      <div class="kn-col"></div>
  </div>
  <div class="kn-row">
      <div class="kn-col"></div>
      <div class="kn-col"></div>
      <div class="kn-col"></div>
  </div>
</div>`

public st4c =
`<div class="kn-tile filled">
  <div class="kn-row">
      <div class="kn-col"></div>
      <div class="kn-col"></div>
      <div class="kn-col"></div>
      <div class="kn-col"></div>
  </div>
  <div class="kn-row">
      <div class="kn-col"></div>
      <div class="kn-col"></div>
      <div class="kn-col"></div>
      <div class="kn-col"></div>
  </div>
</div>`

public st2575 =
`<div class="kn-tile">
  <div class="kn-row">
      <div class="kn-col kn-col-25"></div>
      <div class="kn-col kn-col-75"></div>
  </div>
  <div class="kn-row">
      <div class="kn-col kn-col-25"></div>
      <div class="kn-col kn-col-75"></div>
  </div>
</div>`

public stmixed =
`<div class="kn-tile">
  <div class="kn-row">
      <div class="kn-col"></div>
      <div class="kn-col"></div>
  </div>
  <div class="kn-row">
      <div class="kn-col"></div>
  </div>
</div>`

public mtinline =
`<div class="kn-inline-tile">
  <div class="kn-tile filled">
      <div class="kn-row">
          <div class="kn-col example-col"></div>
      </div>
      <div class="kn-row">
          <div class="kn-col example-col"></div>
      </div>
  </div>
  <div class="kn-tile filled">
      <div class="kn-row">
          <div class="kn-col example-col"></div>
      </div>
      <div class="kn-row">
          <div class="kn-col example-col"></div>
      </div>
  </div>
</div>`

public mt2575 =
`<div class="kn-inline-tile">
  <div class="kn-tile filled kn-tile-25">
      <div class="kn-row">
          <div class="kn-col example-col"></div>
      </div>
      <div class="kn-row">
          <div class="kn-col example-col"></div>
      </div>
  </div>
  <div class="kn-tile filled kn-tile-75">
      <div class="kn-row">
          <div class="kn-col example-col"></div>
      </div>
      <div class="kn-row">
          <div class="kn-col example-col"></div>
      </div>
  </div>
</div>`

  constructor(private el: ElementRef){}

  copyCode(code) {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(code).then(() => {
        console.log('Code copied to clipboard!');
      }, (error) => {
        console.error('Error copying code: ', error);
      });
    } else {
      // Fallback for older browsers or situations where the Clipboard API is not available
      const textarea = document.createElement('textarea');
      textarea.value = code;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      console.log('Code copied to clipboard!');
    }
  }
  
  getCode(code) {
    switch(code) {
      case 'filled':
        this.copyCode(this.filled);
        break;
      case 'default':
        this.copyCode(this.default);
        break;
      case 'warning':
        this.copyCode(this.warning);
        break;
      case 'error':
        this.copyCode(this.error);
        break;
      case 'stsc':
        this.copyCode(this.stsc);
        break;
      case 'st2c':
        this.copyCode(this.st2c);
        break;
      case 'st3c':
          this.copyCode(this.st3c);
          break;
      case 'st4c':
        this.copyCode(this.st4c);
        break;
      case 'st2575':
        this.copyCode(this.st2575);
        break;
      case 'stmixed':
        this.copyCode(this.stmixed);
        break;
      case 'mtinline':
          this.copyCode(this.mtinline);
          break;
      case 'mt2575':
        this.copyCode(this.mt2575);
        break;
    }
    
  }

}
