import { Component } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent {
  username: string = '';
  password: string = '';
  loginError: string = 'Incorrect login';
  error = false;

  constructor(private authService: AuthService, private router: Router) {}

  onLogin(): void {
    const success = this.authService.login(this.username, this.password);
    if (success) {
      // Navigate to a default route upon success
      this.router.navigate(['/home']); // Adjust the route as necessary
    } else {
      // Handle the error (show message to the user, clear the form, etc.)
      this.error = true;
    }
  }
}
