<app-canvas 
    [ts]="true"
    [js]="true"
    [isEventTabDisabled]="true" 
    [isPropertiesTabDisabled]="true" 
    [component]="component"
    [angularHtml]="angularComponentRef"
    [webHtml]="webComponentRef"
    [reactUrl]="'https://stackblitz.com/edit/github-rqclvg-aeedjw?embed=1&file=src%2FApp.js&hideExplorer=1'">
    <div title>Spreadsheet <span class="component-version">v1.0.0</span></div>
    <div component>
        <div class="component-group">
            <div class="kn-spreadsheet">
                <div class="kn-spreadsheet-header">
                    <div class="kn-spreadsheet-row-count">
                        <div class="kn-label">Rows of data: </div>
                        <div class="value">{{rowCount}}</div>
                    </div>
                    <div *ngIf="!hideLegend" class="kn-legend">
                        <div class="swatch-container error">
                            <div class="swatch"></div>
                            <div class="text">Required <span class="validation-count" >({{requiredErrors}})</span></div>
                        </div>
                        <div class="swatch-container duplicate">
                            <div class="swatch"></div>
                            <div class="text">Has duplicates <span class="validation-count" >({{duplicateErrors}})</span></div>
                        </div>
                        <div class="swatch-container invalid">
                            <div class="swatch"></div>
                            <div class="text">Invalid character <span class="validation-count" >({{invalidErrors}})</span></div>
                        </div>
                        <div class="swatch-container validation-4">
                            <div class="swatch"></div>
                            <div class="text">Invalid email format <span class="validation-count" >({{invalidEmailErrors}})</span></div>
                        </div>
                    </div>
                </div>
                <ejs-spreadsheet
                    #spreadsheet
                    (created)="created()"
                    (beforeCellRender)="beforeCellRender($event)"
                    [showFormulaBar]="false"
                    [showRibbon]="false"
                    (cellSave)="cellSave($event)"
                    (beforeCellUpdate)="beforeCellUpdate($event)"
                    (actionComplete)="actionComplete($event)"
                    ><e-sheets> 
                        <e-sheet> 
                            <e-ranges> 
                                <e-range [dataSource]='data'></e-range>
                            </e-ranges>
                            <e-columns>
                                <e-column [width]=90></e-column>
                                <e-column [width]=100></e-column>
                                <e-column [width]=250></e-column>
                                <e-column [width]=120></e-column>
                                <e-column [width]=130></e-column>
                                <e-column [width]=120></e-column>
                            </e-columns>
                        </e-sheet>
                    </e-sheets>
                </ejs-spreadsheet>
            </div>
        </div>
    </div>
    <div angular>
       
    </div>
    <div typeScript>
        <span class="code-comment">//Import the following</span>
        {{imports}}
        <br/><br/>
        <span class="code-comment">//Declare variables</span>
        {{declareVars}}
        <br/><br/>
        <span class="code-comment">//Shreadsheet data</span>
        {{sreadsheetData}}
        <br/><br/>
        <span class="code-comment">//On creation of the spreadsheet, you can set default values. Here we are setting the row count and the header styling</span>
        {{createdFunction}}
        <br/><br/>
        <span class="code-comment">//The following function is used for tracking validation count within the legend</span>
        {{getValidationErrorCountCode}}
        <br/><br/>
        <span class="code-comment">//The following function re-evaluates each row whenever a cell is saved</span>
        {{cellValidationFunction}}
        <br/><br/>
        <span class="code-comment">//The "actionComplete" function is used when checking for specific actions like "paste" or "insert" of a row</span>
        {{actionCompleteFunction}}
        <br/><br/>
        <span class="code-comment">//This function is used to get the row count which is diplayed within the legend</span>
        {{rowCountFunction}}
        <br/><br/>
        <span class="code-comment">//This function is used to check for validation issues on load of the spreadsheet</span>
        {{beforeCellRenderCode}}
        <br/><br/>
        <span class="code-comment">//This function is used to check if a cell is empty</span>
        {{requiredValCode}}
        <br/><br/>
        <span class="code-comment">//This function is used to check for correct email format</span>
        {{emailValCode}}
        <br/><br/>
        <span class="code-comment">//This function is used to check for sql injection (the patterns are delared above)</span>
        {{invalidCharCode}}
        <br/><br/>
        <span class="code-comment">//This function is used to test each column for a specific validation (column "C" is being tested for incoorect email format)</span>
        {{beforeCellUpdateCode}}
        <br/><br/>
        {{rowIndexCode}}
    </div>
    <div javascript>
        
    </div>
    <div customproperties>
        <div class="section-title">Modifiers</div>
        <div class="kn-spacer"></div>
        <div class="item-row">
            <kn-toggle 
                [label]="'Hide legend'" 
                (value)="hideLegendEvent($event)" 
                [(ngModel)]="hideLegend"
                name="legend">
            </kn-toggle>
        </div>
        <div class="kn-spacer"></div>
        <div class="section-title reference">References</div>
        <img class="kn-responsive-image" src="//cdn.syncfusion.com/documentation/images/Syncfusion_Logo_Header.svg">
        <kn-radio-button [radioId]="'angularRadio'" [value]="'angular'" [(ngModel)]="language" [defaultValue]="language" [name]="'radioGroup'" [label]="'Angular'"></kn-radio-button>
        <br/>
        <kn-radio-button [radioId]="'reactRadio'" [value]="'react'" [(ngModel)]="language" [defaultValue]="language" [name]="'radioGroup'" [label]="'React'"></kn-radio-button>
        <hr>
        <!--Angular links-->
        <div class="references" [ngClass]="{'show': language == 'angular'}">
            <div class="item-row">
                <div class="link-group">
                    <a class="kn-link" href="https://ej2.syncfusion.com/angular/documentation/spreadsheet/getting-started" target="_blank">Getting started</a>
                </div>
            </div>
        </div>

        <!--React links-->
        <div class="references" [ngClass]="{'show': language == 'react'}">
            <div class="item-row">
                <div class="link-group">
                    <a class="kn-link" href="https://ej2.syncfusion.com/react/documentation/spreadsheet/getting-started" target="_blank">Getting started</a>
                </div>
            </div>
        </div>
    </div>
    <div usage>
        <spreadsheet-usage></spreadsheet-usage>
    </div>
    <div designSpec>
        <spreadsheet-specs></spreadsheet-specs>
    </div>
    <div accessibility>
        <spreadsheet-accessibility></spreadsheet-accessibility>
    </div>
    <div changeLog>
        <div class="canvas-inner-container">
            <div class="changelog-header">
                <div class="item">Date</div>
                <div class="item">Version</div>
                <div class="item">Notes</div>
            </div>
            <div class="entry">
                <div class="item">March 8, 2024</div>
                <div class="item">1.0.0</div>
                <div class="item">
                    <ul>
                        <li>Added Spreadsheet</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-canvas>
