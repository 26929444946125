import { Component, 
  OnInit,
  Input, 
  Output, 
  AfterViewInit, 
  EventEmitter, 
  ElementRef, 
  forwardRef, 
  ViewChild, 
  ContentChild, 
  TemplateRef, 
  OnChanges, 
  SimpleChanges} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

export class Source {
  text: string;
  value: any;
}
@Component({
  selector: 'kn-dropdown',
  templateUrl: './kraken-dropdown.component.html',
  styleUrls: ['./kraken-dropdown.component.sass'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR, 
      useExisting: forwardRef(() => KrakenDropdownComponent), 
      multi: true
    }
  ]
})
export class KrakenDropdownComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {
  @Input() items: any;
  @Input() fields: any;
  @Input() template = false;
  @Input() label: string;
  @Input() value: any;
  @Input() dropdownId: string;
  @Input() placeholder: string;
  @Input() name: string;
  @Input() optional = false;
  @Input() compressed = false;
  @Input() customError = false;
  @Input() customWarning = false;
  @Input() customMess: string;
  @Input() setDisabled = false;
  @Input() setReadOnly = false;
  @Input() setRequired = false;
  @Input() width = '100%';
  @Input() dropdownCustomWidth: any;
  @Input() dropdownCustomHeight: any;
  @Input() dropdownRightAligned = false;
  @Output() onSelect = new EventEmitter<any>();
  @Output() onValidate= new EventEmitter<any>();
  
  public showDropdown = false;
  public topPos: string;
  public drpdnWidth: any;
  public drpdnHeight = 'auto';
  public source: object[] = [];
  public dropdownElm: HTMLElement;
  public input: any;
  public inputValue: any;
  public popup;
  public rightAligned;
  public knDropdownItemId;
  public dropdownLeftPos;
  public dropdownBottomPos;
  public dropdownWidth;

  @ViewChild('inputField') inputField;
  @ContentChild(TemplateRef) customTemplate: TemplateRef<any>;


  constructor(private elm: ElementRef) { }

  ngOnInit() {
    this.dropdownId = this.dropdownId ? this.dropdownId : 'knDropdown_' + Math.floor(Math.random()*90000) + 10000;
    this.knDropdownItemId = this.dropdownId + '_item';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.input = this.elm.nativeElement.querySelector('.kn-select');
      this.dropdownElm = this.elm.nativeElement.querySelector('.kn-dropdown');
      const dropDownIcon = this.elm.nativeElement.querySelector('.kn-dp-icon');

      //check if custom fields are present
      this.fieldExists();

      document.addEventListener('click', (e) => {
        if (e.target !== this.input && e.target !== this.dropdownElm && e.target !== dropDownIcon) {
            this.showDropdown = false;
            e.stopPropagation();
          }

        //arrow navigation
        this.itemListEventListener();
      });

      this.input.addEventListener('click', () => {
        this.focusDropdownItems();
        this.popupCalc();
        //arrow navigation
        this.itemListEventListener();
      });
      
      if(dropDownIcon) {
        dropDownIcon.addEventListener('click', () => {
        this.focusDropdownItems();
        this.popupCalc();
        //arrow navigation
        this.itemListEventListener();
        });
      }

      this.dropdownPosHandler();

      window.addEventListener('scroll', () => {
        this.dropdownPosHandler();
        this.popupCalc();
      },true);

      window.addEventListener('resize', () => {
        this.dropdownPosHandler();
        this.popupCalc();
      });
    }, 100);
  }

  ngOnChanges(changes): void {
    if(changes.value) {
      this.value = changes.value.currentValue;
    } 
    if (changes.items) {
      this.source = [];
      this.fieldExists();
      setTimeout(() => {
        //check if a value was populated in the input
        if(this.value) {
          const item = changes.items.currentValue.filter(i => i[this.fields.text] == this.value);
          this.valueChange(item[0]);
        }
      }, 0);
    }
  }

  propagateChange = (_: any) => {};
  public onTouched: any = () => {};

  writeValue(obj: any): void {
    
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  focusDropdownItems() {
    this.showDropdown = !this.showDropdown;
    if(this.showDropdown) {
      this.input.focus();
    }
    //set first item as default
    const items = this.elm.nativeElement.querySelectorAll('.kn-dropdown-item');
    items.forEach(i => {
      if(i.textContent === this.value) {
        i.classList.add('active');
      }
    });
  }

  valueChange(item) {
    const drpdownItemElms = this.elm.nativeElement.querySelectorAll('.kn-dropdown-item');
    let index;

    drpdownItemElms.forEach(i => {
      i.classList.remove('active');
    });

    //set active row
    if(this.fields) {
      index = this.items.findIndex(i => i == item);
    } else {
      index = this.items.indexOf(item);
    }
    
    if(index != -1) {
      const elm = this.elm.nativeElement.querySelector('#' + this.dropdownId + '_item_' + index);
      elm.classList.add('active');
      
      //set value
      this.value = this.fields ? item[this.fields.text] : this.items[index];
    }

    this.propagateChange(this.value);
    this.onSelect.emit(item);
    this.onValidation();
  }

  fieldExists() {
    this.source = [];
    for (const key in this.items)
    {
      if(this.fields) {
        //check if object array has the required fields
        const indexedItem = this.items[key];
        if (Object.prototype.hasOwnProperty.call(indexedItem, this.fields.text)) {
          const item : object[] = [];
          item['text'] = indexedItem[this.fields.text];
          item['value'] = indexedItem[this.fields.value];
          item['origin'] = indexedItem;
          this.source.push(item);
        }
      }
    }
  }

  public onCompare(): number {
    return -1;
  }

  keydown(e) {
    if(e.key == 'ArrowDown') {
      const items = this.dropdownElm.children;
      (items[0] as HTMLElement).focus();
    } else {
      return false;
    }
  }

  navigateItemList(e){
    const nextElm = (e.currentTarget.nextElementSibling as  HTMLElement);
    const prevElm = (e.currentTarget.previousElementSibling as  HTMLElement);
    const items = this.dropdownElm.children;
    const itemList = Array.from(items);

    if(e.key == "ArrowDown" && nextElm) {
      nextElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });

      nextElm.classList.add('focus');
    } 

    if(e.key == "ArrowUp" && prevElm) {
      prevElm.focus();
      itemList.forEach(i => {
        i.classList.remove('focus');
      });
      prevElm.classList.add('focus');
    }

    if(e.key == "Enter") {
      e.currentTarget.click();
      e.stopImmediatePropagation();
    }
  
    e.preventDefault();
  }

  itemListEventListener() {
    const items = this.dropdownElm.children;
    const itemList = Array.from(items);
    let index = 0;
    itemList.forEach(i => {
      index = index + 1;
      i.classList.remove('focus');
      i.setAttribute('tabindex', '' + index + '');
      i.addEventListener('keydown', (e) => {
        this.navigateItemList(e);
      });
    });
    //(itemList[0] as any).focus();
  }

  onValidation() {
    let isInvalid;
    if(
      this.inputField.control.errors == null && 
      !this.customError
      ) {
        isInvalid = false;
    } else if(this.inputField.control.errors && this.value) {
      isInvalid = false;
    } else {
      isInvalid = true;
    }
    this.onValidate.emit({invalid: isInvalid});
  }

  popupCalc() {
    const y = this.input.getBoundingClientRect().top; 
    const x = this.input.getBoundingClientRect().right;
    const bottom = window.innerHeight  - y;
    const dropdownHeight = this.dropdownElm.getBoundingClientRect().height;
    if(bottom < dropdownHeight) {
      this.topPos = (y - dropdownHeight - 10) + 'px';
    } else {
      this.topPos = (this.input.offsetHeight + y) + 'px';
    }
    
    this.drpdnWidth = this.dropdownCustomWidth ? this.dropdownCustomWidth : this.input.offsetWidth + 'px'; 

    if(this.dropdownRightAligned && this.dropdownCustomWidth) {
      const width = this.dropdownCustomWidth.replace(/\D+/g, '');
      this.rightAligned = Math.abs(x - width) + 'px';
    } else {
      this.rightAligned = "unset";
    }
  }

  dropdownPosHandler() {
    this.dropdownLeftPos = this.input.getBoundingClientRect().left + 'px';
    this.dropdownBottomPos = this.input.getBoundingClientRect().bottom + 'px';
    this.dropdownWidth = this.input.getBoundingClientRect().width + 'px';
  }

}
