import { Component, OnInit, Input } from '@angular/core';
import { KrakenToastService } from './kraken-toast.service';
@Component({
  selector: 'kn-toast',
  templateUrl: './kraken-toast.component.html'
})
export class KrakenToastComponent implements OnInit {
  public componentId: string;
  @Input() compressedMasthead = false;

  constructor(public toastService: KrakenToastService) { }

  ngOnInit(): void {
    this.componentId = 'knToastContainer_' + Math.floor(Math.random()*90000) + 10000;
  }
}
