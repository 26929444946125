import { Component, OnInit} from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import { FontawesomeService } from '../../services/fontawesome.service';
import  packageInfo from '../../../../../kraken-icon-menu/package.json';

@Component({
  selector: 'app-icon-menu',
  templateUrl: './icon-menu.component.html',
  styleUrls: ['./icon-menu.component.sass']
})
export class IconMenuComponent implements OnInit {
  public buttonIconLeft: string;
  public buttonIconRight: string;
  public buttonId = 'exampleButton'
  public source: any;
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public tsDropdownItemsObj;
  public tsDropdownItemsArray;
  public webDropdownItemsObj;
  public webSetProperties;
  public webRemoveProperties;
  public componentId = 'exampleMenuButtonComponentId';
  public webDropdownItemsArray;
  public webClickEvent;
  public elementTag = 'kn-menu-button';
  public buttonClicked = false;
  public buttonText = "Menu button";
  public buttonType = "kn-primary";
  public leftBtnIcon = "";
  public buttonDisabled = false;
  public buttonLoading = false;
  public isLeftIconChecked = false;
  public isRightIconChecked = false;
  public loading = false;
  public iconRightValue = "";
  public iconLeftValue = "";
  public iconValue = "";
  public event1 = true;
  public event2 = true;
  public buttonMenuDropdownTemplate;
  public useCustomTemplate = false;
  public selectedButton = 'Primary';
  public buttonMenuItemsArry = ['Item Array 1', 'Item Array 2', 'Item Array 3'];
  public buttonMenuItemsObj: any = [
    {display:'item 1', id: 0, icon: 'fa-solid fa-bell'}, 
    {display:'item 2', id: 1, icon: 'fa-solid fa-database'}, 
    {display:'item 3', id: 2, icon: 'fa-regular fa-file-lines'}
  ];
  public buttonMenuFields;
  public buttonDropdown = false;
  public btnClickEvent = false;
  public btnDropdownFields = {text: 'display', value: 'id'};
  public objDropdown;
  public btnSelectText = "item 1";
  public btnSelectValue = "0";
  public selectedItemEvent = false;
  public selectedTheme: string;
  public selectedThemeDisplayName: string;
  public iconList: any;
  public icon = "";
  public buttonTypes;
  public selectedIconClassname;
  public curentSelectedIcon;
  public templateHtml: string;
  public stylingDropdown: string;
  public webCustomTemplate: string;
  public dropdownCustomWidth = '';
  public dropdownCustomHeight = '';
  public dropdownWidth: string;
  public dropdownRightAligned = false;
  public isDropdownWidthCustom = false;
  public isDropdownHeightCustom = false;
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenMenuButtonModule'};
  public useCustomTemplateNoData = true;
  public angularComponentOptions: any = [
    { option: '[buttonId]=', id: 0, value: '"\'exampleMenuButton\'"'},
    { option: '[buttonType]=', id: 1, value: '"\'' + this.buttonType +'\'"'},
    { option: '[buttonText]=', id: 2, value: '"\'' + this.buttonText + '\'"'},
    { option: '[template]=', id: 3, value: '"true"'}
  ];

  public angularComponentCustomTempOptions: any = [
    { option: '[buttonId]=', id: 0, value: '"\'exampleMenuButton\'"'},
    { option: '[buttonType]=', id: 1, value: '"\'' + this.buttonType +'\'"'},
    { option: '[buttonText]=', id: 2, value: '"\'' + this.buttonText + '\'"'},
    { option: '[template]=', id: 3, value: '"\'true\'"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value:  '"' + this.componentId +'"'},
    { option: 'button-id=', id: 1, value:  '"exampleMenuButton"'},
    { option: 'button-type=', id: 2, value:  '"' + this.buttonType +'"'},
    { option: 'button-text=', id: 3, value: '"' + this.buttonText +'"' }
  ];

  public buttonTypeFields: object = { text: 'name', value: 'type'};

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService,
    public fontawesomeService: FontawesomeService) { 
  }

  ngOnInit(): void {
    this.templateHtml = 
      `<ng-template  #customTemplate>
  <div class="kn-dropdown-item">item 1</div>
  <div class="kn-dropdown-item">item 2</div>
  <div class="kn-dropdown-item">item 3</div>
</ng-template>`

    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);

    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    
    //create icon list
    this.iconList = this.fontawesomeService.iconDataSet();
    
    this.webClickEvent = 
`
const menuBtnDropdown = document.querySelector('#${this.componentId}');
menuBtnDropdown.addEventListener('btnClick', (event) => console.log(event.detail));
menuBtnDropdown.addEventListener('onSelect', (event) => console.log(event.detail));
`

    this.tsDropdownItemsObj = 
`
public items = [
  {display:'item obj 1', id: 0, icon: 'fa-solid fa-bell'}, 
  {display:'item obj 2', id: 1, icon: 'fa-solid fa-database'}, 
  {display:'item obj 3', id: 2, icon: 'fa-regular fa-file-lines'}
];
public fields = {text: 'display', value: 'id'};
`
    
    this.tsDropdownItemsArray =
`
public items = ['item 1', 'item 2', 'item 3'];
`
    this.webDropdownItemsObj = 
`
let btnDropdownItemsObj = [{display:"item 1", id: 0}, {display:"item 2", id: 1}, {display:"item 3", id: 2}];
const fields = {text: "display", value: "id"};
let menuBtnDropdown = document.querySelector("#${this.componentId}");
menuBtnDropdown.fields = fields;
menuBtnDropdown.items = btnDropdownItemsObj;
    `

    this.webDropdownItemsArray =
`
let btnDropdownItems = ["item 1", "item 2", "item 3"];
let menuBtnDropdown = document.querySelector("#${this.componentId}");
menuBtnDropdown.items = btnDropdownItems;
`

    this.webSetProperties = 
    `
let menuBtnDropdown = document.querySelector('#${this.componentId}');
menuBtnDropdown.setAttribute("disabled", true);
menuBtnDropdown.setAttribute("loading", true);
    `

    this.webRemoveProperties =
    `
let menuBtnDropdown = document.querySelector('#${this.componentId}');
menuBtnDropdown.removeAttribute("disabled");
menuBtnDropdown.removeAttribute("loading");
    `

    this.stylingDropdown = 
    `
<kn-menu-button [buttonId]="'exampleMenuButton'" [buttonType]="'kn-primary'" [buttonText]="'Menu Button'">
  <ng-template  #customTemplate>
    <div class="kn-dropdown-item">item 1</div>
    <div class="kn-dropdown-item">item 2</div>
    <div class="kn-dropdown-item">item 3</div>
  </ng-template>
</kn-menu-button>
    `

    this.webCustomTemplate = 
    "let menuBtnDropdown = document.querySelector('#exampleMenuButtonComponentId');\n" +
    "let menuBtnDropdownTemplate = menuBtnDropdown.querySelector('.kn-dropdown');\n" +
    "menuBtnDropdownTemplate.innerHTML = `\n" +
    "   <div class='kn-dropdown-item'>item 1</div>\n" +
    "   <div class='kn-dropdown-item'>item 2</div>\n" +
    "   <div class='kn-dropdown-item'>item 3</div>`\n" 

  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  customTemplateEvent(event) {
    if(event) {
      this.controlEvent(true, '[template]=', 'true', 'bool');
      this.controlEvent(true, '[customWebTemplate]=', 'true', 'webonly');
    } else {
      this.controlEvent(false, '[template]=', 'true', 'bool');
      this.controlEvent(false, '[customWebTemplate]=', 'true', 'webonly');
    }

    if(this.useCustomTemplate) {
      //custom template
      this.templateHtml = 
      `<ng-template  #customTemplate let-data="data">
    <i class="{{data.icon}}"></i> <span class="name">{{data.display}}</span>
</ng-template>\n`
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    } else {
      this.templateHtml = "";
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    }
  }

  valueChange(event) {
    this.buttonType = event.type;
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[buttonType]='));
    this.angularComponentOptions[index].value = '"\'' + event.type +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'button-type='));
    this.webComponentOptions[webIndex].value = '"' + event.type + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  textChange(event) {
    this.buttonText = event;
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[buttonText]='));
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'button-text='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  isIconEnabled(event) {
    this.isLeftIconChecked = event;
    this.leftBtnIcon = "";
    this.iconLeftValue = "";
    this.selectedIconClassname = ""
  }

  selectedIcon(item) {
    //angular
    const angularIconProp = '[buttonIconLeft]=';
    const angularIndex = this.angularComponentOptions.findIndex((obj => obj.option == angularIconProp));
    this.angularComponentOptions[angularIndex].value = '"\'' + item.className + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const webIconProp = 'button-icon-left=';
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == webIconProp));
    this.webComponentOptions[webIndex].value = '\'' + item.className + '\'';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    this.curentSelectedIcon = item;
    this.iconLeftValue = item.title;
    this.selectedIconClassname = item.className;
  }

  themeSelector(event) {
    this.selectedTheme = event.color;
    this.selectedThemeDisplayName = event.name;
  }

  selectedItem(event) {
    this.btnSelectText = event.text;
    this.btnSelectValue = event.value;
  }

  customDrpdnWidth(event) {
    if(event) {
      this.controlEvent(true, '[dropdownCustomWidth]=', '' + this.dropdownCustomWidth +'', true);  
    } else{
      this.controlEvent(false, '[dropdownCustomWidth]=', '' + this.dropdownCustomWidth +'', true);
      this.dropdownCustomWidth = '';
    }
  }

  customDrpdnWidthValue(event) {
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[dropdownCustomWidth]='));
    this.angularComponentOptions[index].option = '[dropdownCustomWidth]=';
    this.angularComponentOptions[index].value = '"\'' + event + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'dropdown-custom-width='));
    this.webComponentOptions[indexWeb].option = 'dropdown-custom-width=';
    this.webComponentOptions[indexWeb].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  customDrpdnHeight(event) {
    if(event) {
      this.controlEvent(true, '[dropdownCustomHeight]=', '' + this.dropdownCustomHeight +'', true);  
    } else{
      this.controlEvent(false, '[dropdownCustomHeight]=', '' + this.dropdownCustomHeight +'', true);
      this.dropdownCustomHeight = '';
    }
  }

  customDrpdnHeightValue(event) {
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[dropdownCustomHeight]='));
    this.angularComponentOptions[index].option = '[dropdownCustomHeight]=';
    this.angularComponentOptions[index].value = '"\'' + event + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'dropdown-custom-height='));
    this.webComponentOptions[indexWeb].option = 'dropdown-custom-height=';
    this.webComponentOptions[indexWeb].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  customTemplateNoData(event) {
    if(event) {
      this.controlEvent(true, '[template]=', 'true', 'bool');
      this.controlEvent(true, '[customWebTemplate]=', 'true', 'webonly');
      this.controlEvent(false, '[items]=', 'true', 'bool');
      this.controlEvent(false, '[fields]=', 'true', 'bool');
    } else {
      this.controlEvent(false, '[template]=', 'true', 'bool');
      this.controlEvent(false, '[customWebTemplate]=', 'true', 'webonly');
      this.controlEvent(true, '[items]=', 'true', 'bool');
      this.controlEvent(true, '[fields]=', 'true', 'bool');
    }

    if(event) {
      //custom template
      this.templateHtml = 
      `<ng-template  #customTemplate>
  <div class="kn-dropdown-item">item 1</div>
  <div class="kn-dropdown-item">item 2</div>
  <div class="kn-dropdown-item">item 3</div>
</ng-template>`
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    } else {
      this.templateHtml = "";
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    }
  }
}
