import { NgModule } from '@angular/core';
import { KrakenThemeComponent } from './kraken-theme.component';



@NgModule({
  declarations: [KrakenThemeComponent],
  imports: [
  ],
  exports: [KrakenThemeComponent]
})
export class KrakenThemeModule { }
