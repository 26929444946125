import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { SitemapService} from '../services/sitemap.service';
import { NavigationService } from '../services/navigation.service';
@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.sass']
})
export class SideNavComponent implements OnInit {
  public siteMap: any;
  public fields: object = { text: 'item', value: 'route'};
  public accordion = HTMLElement;
  constructor(
    public router: Router, 
    public siteMapService: SitemapService, 
    public elm: ElementRef,
    public nav: NavigationService) { }

  ngOnInit(): void {
    this.accordion = this.elm.nativeElement.querySelector('.kn-accordion');
    this.siteMap = this.siteMapService.siteMapData();
  }

  onSelect(event) {
    const route = event.origin.route;
    this.router.navigate([route]);
    //open the accordion to the page
    
    this.nav.accordionNavigation(this.accordion, event.origin.group, event.origin.subGroup);
  }
}
