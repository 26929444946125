import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[numericOnly]'
})
export class NumericOnlyDirective {
  @Input() numericChars: string;

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event']) onInput(event: Event): void {
    if (this.numericChars == "true") {
      const inputElement = this.el.nativeElement as HTMLInputElement;
      const inputValue = inputElement.value.replace(/[^\d.-]/g, ''); // Remove non-numeric characters

      // Update the ngModel value
      this.control.control?.setValue(inputValue, { emitEvent: false });

      // Update the input field's value
      inputElement.value = inputValue;
    }
  }
}