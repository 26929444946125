import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'checkbox-usage',
  templateUrl: './checkbox-usage.component.html',
  styleUrls: ['./checkbox-usage.component.sass']
})
export class CheckboxUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
