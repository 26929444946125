import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import { FormGroup } from '@angular/forms';
import  packageInfo from '../../../../../kraken-textarea/package.json';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.sass']
})
export class TextAreaComponent implements OnInit {
  public areasForm: FormGroup;
  public disabled = false;
  public buttonName = 'example';
  public label = 'Textarea example';
  public attributes = [];
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public componentId = 'textareaComponentId';
  public webClickEvent;
  public webSetProperties;
  public webRemoveProperties;
  public elementTag = 'kn-textarea';
  public customError = false;
  public customWarning = false;
  public customMess = "";
  public event1 = true;
  public eventValue = 'Textarea value';
  public exampleText = "";
  public input2 = 'input 2 ';
  public inputRequired = false;
  public readOnly = false;
  public webTextboxDisabled;
  public webTextboxReadonly;
  public placeholder = '';
  public showPlaceholder = false;
  public showCounter = false;
  public maxChars = 0;
  public cols = 50;
  public rows = 5;
  public setSize = false;
  public radioError = true;
  public radioWarning = false;
  public addCustomMessage = false;
  public optionalLabel = false;
  public onValueChange = false;
  public textareaName: any;
  public onValidate = false;
  public buttonValidation: string;
  public validationTS: string;
  public messageType = '';
  public setCustomMessage = false;
  public customDirective = false;
  public componentVersion = packageInfo.version;
  public hideErrorMessage = false;
  public initializeComponentWeb: any;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenTextareaModule', 
    form: true};
  public angularComponentOptions: any = [
    { option: '[textareaId]=', id: 1, value: '"\'exampleTextarea\'"'},
    { option: '[label]=', id: 2, value: '"\'' + this.label + '\'"'},
    { option: '[(ngModel)]=', id: 3, value: '"textareaValue"'},
    { option: '[name]=', id: 4, value: '"\'exampleTextarea\'"'},
    
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"'+ this.componentId +'"'},
    { option: 'textarea-id=', id: 1, value: '"exampleTextarea"'},
    { option: 'label=', id: 1, value: '"' + this.label +'"' },
    { option: 'name=', id: 2, value:  '"exampleTextarea"'}
  ];

  
  public selectedTheme: string;
  public selectedThemeDisplayName: string;

  constructor( public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {

    this.initializeComponentWeb = `
const textareaElm = document.querySelector('#${this.componentId}');
    `
    this.webClickEvent = 
`
textareaElm.addEventListener('onValueChange', (event) => console.log(event.detail));
`

this.webSetProperties = 
`
textboxElm.customError = true;
textboxElm.customMess = 'this is an error';
`
this.webRemoveProperties = 
`
textboxElm.customError = false;
`
this.buttonValidation =
`
<kn-button [buttonId]="'exampleButton'" [buttonType]="'kn-primary'" [buttonText]="'Submit'" [disabled]="textareaName?.invalid"></kn-button>
`

    this.validationTS = 
`
public exampleForm: FormGroup;
public textareaName: any;

this.exampleForm = new FormGroup({
  textareaName: new FormControl('', [])
});

validate(event: any) {
  this.textareaName = event;
}
`

    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  textChange(event, id) {
    this.label = event;
    const index = this.angularComponentOptions.findIndex((obj => obj.id == id));
    //angular
    this.angularComponentOptions[index].option = '[label]=';
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    this.webComponentOptions[index].option = 'label=';
    this.webComponentOptions[index].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  addCustomessage(event) {
    this.customMess = event;
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[customMess]='));
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'custom-mess='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  valueChange(event) {
    this.eventValue = event ? event : "Textbox value";
  }

  showHidePlaceholder(event) {
    this.showPlaceholder = !this.showPlaceholder;
    this.placeholder = this.showPlaceholder ? this.placeholder : '';
    this.controlEvent(event, '[setPlaceholder]=', this.placeholder, true); 
  }
  placeholderValue(event) {
    this.placeholder = event;
    
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[setPlaceholder]='));
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'set-placeholder='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  charCount(event) {
    this.maxChars = event;
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[maxChars]='));
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'max-chars='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  showCharCount(event) {
    this.showCounter = event;
    if(!event) {
      this.maxChars = null;
    } else {
      this.maxChars = 0;
    }
  }

  textareaSize(event, type) {
    let index;
    let webIndex;
    if(type == 'cols') {
      this.cols = event;
      index = this.angularComponentOptions.findIndex((obj => obj.option == '[cols]='));
      webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'cols='));
    } else {
      this.rows = event;
      index = this.angularComponentOptions.findIndex((obj => obj.option == '[rows]='));
      webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'rows='));
    }
    //angular
    this.angularComponentOptions[index].value = '"' + event +'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  customMessToggle(event) {
    this.addCustomMessage = event;
    if(!event) {
      this.removeCustomMessage();
    }
  }

  customMessage(event) {
    if(event.value == 'radioError') {
      this.customError = true;
      this.customWarning = false;
      this.controlEvent(false, '[customWarning]=', true, true);  
      this.controlEvent(false, '[customMess]=', '' + this.customMess +'', true);
      this.controlEvent(event, '[customError]=', true, true); 
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    } else if (event.value == 'radioWarning'){
      this.customError = false;
      this.customWarning = true;
      this.controlEvent(false, '[customError]=', true, true); 
      this.controlEvent(false, '[customMess]=', '' + this.customMess +'', true);
      this.controlEvent(event, '[customWarning]=', true, true); 
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    } else {
      this.controlEvent(event, '[customMess]=', '' + this.customMess +'', true);
    }
  }

  message(event) {
    this.customMess = event;
    
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[customMess]='));
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'custom-mess='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }
  removeCustomMessage() {
    this.messageType = '';
    this.customError = false;
    this.customWarning = false;
    this.controlEvent(false, '[customError]=', true, true); 
    this.controlEvent(false, '[customWarning]=', true, true);  
    this.controlEvent(false, '[customMess]=', true, true); 
  }
}
