<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Layout Grid</div>
        
        <section>
            <div class="kn-headline-24-med">Grid system</div>
        </section>
        
        <section>
            <div class="text">Consistent use of a grid system provides the foundation for harmoniously and consistently positioning elements onscreen. Designing to the grid helps create an experience that facilitates understanding and brings order to the page. </div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Kraken DS</span> uses an <span class="kn-bold">8px baseline grid</span> across mobile and desktop. Every UI element is aligned to the 8px grid to retain balance and proportion across the experience. This means that when designing, we use multiples of 8 in determining the sizing, spacing, and layout of elements on the screen.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/grid layout/KrakenDSM_StyleGuide_LayoutGrid_01.png">
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/grid layout/KrakenDSM_StyleGuide_LayoutGrid_02.png">
        </section>

        <section>
            <div class="kn-headline-24-med">Pixel density</div>
        </section>

        <section>
            <div class="text">Pixel density refers to how many pixels have been squeezed into a physical amount of space (width x height). For example, a device with a resolution of "1024 x 768" has a 1024-pixel width and a 768-pixel height. </div>
        </section>
        
        <section>
            <div class="text">A standard-resolution image has a scale factor of 1.0 and is referred to as an @1x image. High-resolution images have a scale factor of 2.0 or 3.0 and are referred to as @2x and @3x images.</div>
        </section>

        <img class="kn-responsive-img" src="assets/style guide/grid layout/KrakenDSM_StyleGuide_LayoutGrid_03.png">

        <section>
            <div class="kn-headline-24-med">PT & DP</div>
        </section>

        <section>
            <div class="text">Prior to Apple's 2010 iPhone 4 retina display, the standard number of pixels that could fit on a square area of 1 inch was 163 pixels (ppi).  With the introduction of retina displays, an inch of a Retina display could now house 326 pixels. </div>
        </section>

        <section>
            <div class="text">Apple's convention of measuring pixel density is the Point (pt) where 1pt = (ppi of your screen/163)px. Android's measurement unit of pixel density is not based on physical pixels per inch, but rather an assigned unit of measurement called the density-independent pixel (dp) where 160 pixels/inch is the base. 1dp = (ppi of your screen/160)px.</div>
        </section>

        <div class="kn-headline-24-med">Designing at 1x</div>

        <section>
            <div class="text">Use a baseline resolution of @1x when starting a new design. This practice enables a faster workload (using fewer pixels which reduces file size), creates "pixel-perfect" designs that align to the pixel grid, and ensures streamlined scalability when working with mobile applications.</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">Designing in 1x and scaling up </span></div>
        </section>

        <img class="kn-responsive-img" src="assets/style guide/grid layout/KrakenDSM_StyleGuide_LayoutGrid_04.png">

        <section>
            <div class="text"><span class="kn-bold">mdpi (@1x)</span></div>
            <div class="text">Image size: 20 x 20px, Pixel Ratio: 1.0</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">hdpi (@1.5x)</span></div>
            <div class="text">Image size: 30 x 30px, Pixel Ratio: 1.5</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">xhdpi (@2x)</span></div>
            <div class="text">Image size: 40 x 40px, Pixel Ratio: 2.0</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">xxhdpi (@2.5x)</span></div>
            <div class="text">Image size: 50 x 50px, Pixel Ratio: 3.0</div>
        </section>

        <section>
            <div class="text"><span class="kn-bold">xxxhdpi (@3x)</span></div>
            <div class="text">Image size: 60 x 60px, Pixel Ratio: 4.0</div>
        </section>


        <section>
            <div class="text"><span class="kn-bold">Designing in 3x and scaling down </span></div>
        </section>

        <section>
            <div class="text">If you design at 2x or 3x instead, will likely run into problems when scaling up or down to support multiple screens. For example, when designing at 3x for baseline (xxhdpi/300%), you will have to divide by three to achieve a 1x resolution and 1.5 to get 2x resolution. Such scaling can break the pixel grid which causes pixel blur.</div>
        </section>

        <img class="kn-responsive-img" src="assets/style guide/grid layout/KrakenDSM_StyleGuide_LayoutGrid_05.png">

        <section>
            <ul class="bullets">
                <li>Improves data consumption through better legibility</li>
                <li>Drives consistent scalability</li>
                <li>Provides users a more consistent user experience</li>
                <li>Conveys meaning by giving elements a visual hierarchy</li>
            </ul>
        </section>
    </div>
</div>