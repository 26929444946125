<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Formatting</div>
        
        <section>
            <div class="kn-headline-24-med">Date and time</div>
        </section>
        
        <section>
            <div class="text">MMM-DD-YYYY Oct 31, 2017</div>
            <div class="text">12-hour clock 3:30 pm</div>
            <div class="text">Time zone	PST</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Lists</div>
        </section>

        <section>
            <div class="text">Use numbered lists when following the order of items is imperative. </div>
            <div class="text">Use bulleted lists when the order is not important. Avoid multiple levels of bullets. </div>
        </section>

        <section>
            <div class="kn-headline-24-med">Numbers</div>
        </section>

        <section>
            <div class="text">Use numerals in place of words.</div>
        </section>

        <section>
            <div class="text">"5 Users added"</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Capitalization</div>
        </section>

        <section>
            <div class="text">Use title case for title and headings. Do not capitalize prepositions or conjunctions such as "the", "and", and "of". Always capitalize proper nouns.</div>
        </section>

        <section>
            <div class="text">To ensure scannability and readability, the use of all caps should be limited to column headings, buttons, and components that contain no more than 2 or 3 words.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Punctuation</div>
        </section>

        <section>
            <div class="kn-subtitle-16">Periods</div>
        </section>

        <section>
            <div class="text">Do not use periods in bulleted or numbered lists unless there is more than one sentence. </div>
        </section>

        <section>
            <div class="kn-subtitle-16">Commas</div>
        </section>

        <section>
            <div class="text">Use serial commas for three or more items to avoid confusion.</div>
        </section>

        <section>
            <div class="text">For example, without a comma after "tiffs", this could mean my file name is "tiffs and jpegs"</div>
        </section>

        <section>
            <div class="text"><i>"Download my file, tiffs and jpegs."</i></div>
        </section>

        <section>
            <div class="kn-headline-24-med">Contractions</div>
        </section>

        <section>
            <div class="text">Contractions are acceptable to convey a welcoming and friendly tone.</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/content/formatting/KrakenDSM_StyleGuide_Content_Formatting_01.png">
        </section>

        <section>
            <div class="kn-headline-24-med">Hyphens and en dashes</div>
        </section>

        <section>
            <div class="text">Use hyphens to join words (compound adjectives).</div>
            <div class="text">"Non-email document"</div>
        </section>

        <section>
            <div class="text">Use en dashes to show a range (dates, numbers).</div>
            <div class="text">"2017 – 2020"</div>
        </section>

        <section>
            <div class="text">Do not hyphenate brand names or "e-discovery".</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Colons</div>
        </section>

        <section>
            <div class="text">Use only for numerical data points.</div>
            <div class="text">"Promoted to Analytics: 100"</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Ellipses</div>
        </section>

        <section>
            <div class="text">Use sparingly, yet consistently to display jobs in progress within a data grid, in modals, or above progress bars.</div>
        </section>

        <section>
            <div class="kn-headline-24-med">Abbreviations and acronyms</div>
        </section>

        <section>
            <div class="text">If users may not be familiar with an abbreviation or acronym, especially if it is proprietary, spell it out with the acronym in parentheses for the first use, then use the acronym only. This rule applies more to help documentation.</div>
        </section>

        <section>
            <div class="text">Provide a key or tooltip for industry-standard abbreviations within the UI. </div>
            <div class="text">This should be used sparingly for new or proprietary terms. </div>
        </section>

        <section>
            <div class="text">List of acceptable abbreviations and acronyms:</div>
        </section>

        <section>
            <img class="kn-responsive-img" src="assets/style guide/content/formatting/KrakenDSM_StyleGuide_Content_Formatting_02.png">
        </section>

    </div>
</div>