import { NgModule } from '@angular/core';
import { KrakenTextareaComponent } from './kraken-textarea.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { KrakenFieldValidationComponent } from './kraken-field-validation.component';


@NgModule({
  declarations: [KrakenTextareaComponent, KrakenFieldValidationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [KrakenTextareaComponent, KrakenFieldValidationComponent]
})
export class KrakenTextareaModule { }
