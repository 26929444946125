import { Component, OnInit } from '@angular/core';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import { FontawesomeService } from '../../services/fontawesome.service';
import { ButtonService } from '../../services/button.service';
import  packageInfo from '../../../../../kraken-split-button/package.json';

@Component({
  selector: 'app-split-button',
  templateUrl: './split-button.component.html',
  styleUrls: ['./split-button.component.sass']
})
export class SplitButtonComponent implements OnInit {
  public buttonId = 'exampleSplitButton'
  public buttonText = "Split Button";
  public buttonType = "kn-primary";
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public tsDropdownItems;
  public webDropdownItems;
  public webSetProperties;
  public webRemoveProperties;
  public componentId = 'exampleSplitButtonComponentId';
  public webClickEvent;
  public customTemplate = false;
  public elementTag = 'kn-split-button';
  public iconList: any;
  public btnClickEvent = false;
  public buttonTypes;
  public buttonTypeFields: object = { text: 'name', value: 'id'};
  public selectedButton: string;
  public buttonDisabled = false;
  public leftBtnIcon = "";
  public iconLeftValue = "";
  public btnSelectText = "item 1";
  public btnSelectValue = "0";
  public selectedItemEvent = false;
  public event1 = true;
  public event2 = true;
  public webDropdownItemsArray;
  public webDropdownItemsObj;
  public tsDropdownItemsObj;
  public tsDropdownItemsArray;
  public isLeftIconChecked = false;
  public curentSelectedIcon: any;
  public selectedIconClassname;
  public useCustomTemplate = false;
  public stylingDropdown: string;
  public templateHtml;
  public webCustomTemplate: string;
  public dropdownCustomWidth = '';
  public dropdownCustomHeight = '';
  public dropdownWidth: string;
  public dropdownRightAligned = false;
  public isDropdownWidthCustom = false;
  public isDropdownHeightCustom = false;
  public initializeWebComponent = `let splitBtnDropdown = document.querySelector('#exampleSplitButtonComponentId');`
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenSplitButtonModule'};
  public buttonMenuItemsArry = ['Item Array 1', 'Item Array 2', 'Item Array 3'];
  public btnDropdownFields = {text: 'display', value: 'id'};
  public useCustomTemplateNoData = true;
  public btnSelectEvent = false;
  public buttonMenuItemsObj: any = [
    {display:'item 1', id: 0, icon: 'fa-solid fa-bell', disabled: true}, 
    {display:'item 2', id: 1, icon: 'fa-solid fa-database', divider: true}, 
    {display:'item 3', id: 2, icon: 'fa-regular fa-file-lines'}
  ];
  public angularComponentOptions: any = [
    { option: '[buttonId]=', id: 0, value: '"\'exampleButton\'"'},
    { option: '[buttonType]=', id: 1, value: '"\'' + this.buttonType +'\'"'},
    { option: '[template]=', id: 1, value: '"true"'},
    { option: '[buttonText]=', id: 4, value: '"\'' + this.buttonText + '\'"'}
  ];

  public webComponentOptions = [
    { option: 'id=', id: 0, value:  '"' + this.componentId +'"'},
    { option: 'button-id=', id: 1, value: '"' + this.buttonId +'"'},
    { option: 'button-type=', id: 2, value: '"' + this.buttonType +'"'},
    { option: 'button-text=', id: 3, value: '"' + this.buttonText +'"' }
  ];
  
  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService,
    public fontawesomeService: FontawesomeService,
    private buttonService: ButtonService) { 
  }

  ngOnInit(): void {
    //set button types
    const specificButtonTypes = [0,1,2,4,5,6];
    this.buttonTypes = this.buttonService.buttonTypes(false, specificButtonTypes);
    this.selectedButton = this.buttonTypes[0].name;
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    this.templateHtml = 
      `<ng-template  #customTemplate >
      <div class='kn-dropdown-item disabled'>item 1</div>
      <div class="kn-divider"></div>
      <div class='kn-dropdown-item'>item 2</div>
      <div class='kn-dropdown-item'>item 3</div>
  </ng-template>`

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);

    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);

    //create icon list
    this.iconList = this.fontawesomeService.iconDataSet();
    
    this.webClickEvent = 
`
splitBtnDropdown.addEventListener('btnClick', (event) => console.log(event.detail));
splitBtnDropdown.addEventListener('onSelect', (event) => console.log(event.detail));
`
    this.tsDropdownItemsObj = 
`
public items = [
  {display:'item obj 1', id: 0, icon: 'fa-solid fa-bell', disabled: true}, 
  {display:'item obj 2', id: 1, icon: 'fa-solid fa-database', divider: true}, 
  {display:'item obj 3', id: 2, icon: 'fa-regular fa-file-lines'}
];
public fields = {text: 'display', value: 'id'};
`

    this.tsDropdownItemsArray =
`
public items = ['item 1, item 2, item 3];
`

    this.webDropdownItemsObj = 
`
let btnDropdownItemsObj = [{display:"item 1", id: 0, disabled: true}, {display:"item 2", id: 1, divider: true}, {display:"item 3", id: 2}];
const fields = {text: "display", value: "id"};
splitBtnDropdown.fields = fields;
splitBtnDropdown.items = btnDropdownItemsObj;
`

    this.webDropdownItemsArray =
    `
let btnDropdownItems = ["item 1", "item 2", "item 3"];
splitBtnDropdown.items = btnDropdownItems;
    `

    this.webSetProperties = 
    `
    splitBtnDropdown.disabled = true;
    `

    this.webRemoveProperties =
    `
    splitBtnDropdown.disabled = false;
    `

    this.webCustomTemplate = 
`let splitBtnDropdownTemplate = splitBtnDropdown.querySelector('.kn-dropdown');
splitBtnDropdownTemplate.innerHTML = "
<div class='kn-dropdown-item disabled'>item 1</div>
<div class="kn-divider"></div>
<div class='kn-dropdown-item'>item 2</div>
<div class='kn-dropdown-item'>item 3</div>"` 
   }

   controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  removeProp(option, web?) {
    const item = this.angularComponentOptions.findIndex((u) => {
      return u.option === option;
    });
    //angular
    this.angularComponentOptions.splice(item, 1);
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    //web
    if(web != false)
      this.webComponentOptions.splice(item, 1);
      this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  valueChange(event) {
    this.buttonType = event.type;
    
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[buttonType]='));
    this.angularComponentOptions[index].value = '"\'' + event.type +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'button-type='));
    this.webComponentOptions[webIndex].value = '"' + event.type + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  labelText(event) {
    this.buttonText = event;
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[buttonText]='));
    this.angularComponentOptions[index].value = '"\'' + event +'\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == 'button-text='));
    this.webComponentOptions[webIndex].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  isIconEnabled(event) {
    this.isLeftIconChecked = event;
    this.leftBtnIcon = "";
    this.iconLeftValue = "";
    this.selectedIconClassname = ""
  }

  selectedIcon(item) {
    //angular
    const angularIconProp = '[buttonIcon]=';
    const angularIndex = this.angularComponentOptions.findIndex((obj => obj.option == angularIconProp));
    this.angularComponentOptions[angularIndex].value = '"\'' + item.className + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const webIconProp = 'button-icon=';
    const webIndex = this.webComponentOptions.findIndex((obj => obj.option == webIconProp));
    this.webComponentOptions[webIndex].value = '\'' + item.className + '\'';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
    this.curentSelectedIcon = item;
    this.iconLeftValue = item.title;
    this.selectedIconClassname = item.className;
  }

  customTemplateEvent(event) {
    if(event) {
      this.controlEvent(true, '[template]=', 'true', false);
      this.controlEvent(true, '[customWebTemplate]=', 'true', 'webonly');
      
    } else {
      this.controlEvent(false, '[template]=', 'true', false);
      this.controlEvent(false, '[customWebTemplate]=', 'true', 'webonly');
    }

    if(this.useCustomTemplate) {
      //custom template
      this.templateHtml = 
`<ng-template  #customTemplate let-data="data">
    <i class="{{data.icon}}"></i> <span class="name">{{data.display}}</span>
</ng-template>`
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    } else {
      this.templateHtml = "";
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    }
  }

  customTemplateNoDataEvent(event) {
    if(event) {
      this.controlEvent(true, '[template]=', 'true', false);
      this.controlEvent(true, '[customWebTemplate]=', 'true', 'webonly');
      this.controlEvent(false, '[items]=', 'true', 'bool');
      this.controlEvent(false, '[fields]=', 'fields', 'bool');
      
    } else {
      this.controlEvent(false, '[template]=', 'true', false);
      this.controlEvent(false, '[customWebTemplate]=', 'true', 'webonly');
      this.controlEvent(true, '[items]=', 'true', 'bool');
      this.controlEvent(true, '[fields]=', 'fields', 'bool');
    }

    if(event) {
      //custom template
      this.templateHtml = 
      `<ng-template  #customTemplate>
      <div class='kn-dropdown-item disabled'>item 1</div>
      <div class="kn-divider"></div>
      <div class='kn-dropdown-item'>item 2</div>
      <div class='kn-dropdown-item'>item 3</div>
</ng-template>`
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    } else {
      this.templateHtml = "";
      this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer);
    }
  }

  customDrpdnWidth(event) {
    if(event) {
      this.controlEvent(true, '[dropdownCustomWidth]=', '' + this.dropdownCustomWidth + '', true);  
    } else{
      this.controlEvent(false, '[dropdownCustomWidth]=', '' + this.dropdownCustomWidth + '', true);
      this.dropdownCustomWidth = '';
    }
  }

  customDrpdnHeight(event) {
    if(event) {
      this.controlEvent(true, '[dropdownCustomHeight]=', '' + this.dropdownCustomHeight + '', true);  
    } else{
      this.controlEvent(false, '[dropdownCustomHeight]=', '' + this.dropdownCustomHeight + '', true);
      this.dropdownCustomHeight = '';
    }
  }

  customDrpdnWidthValue(event) {
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[dropdownCustomWidth]='));
    this.angularComponentOptions[index].option = '[dropdownCustomWidth]=';
    this.angularComponentOptions[index].value = '"\'' + event + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'dropdown-custom-width='));
    this.webComponentOptions[indexWeb].option = 'dropdown-custom-width=';
    this.webComponentOptions[indexWeb].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  customDrpdnHeightValue(event) {
    //angular
    const index = this.angularComponentOptions.findIndex((obj => obj.option == '[dropdownCustomHeight]='));
    this.angularComponentOptions[index].option = '[dropdownCustomHeight]=';
    this.angularComponentOptions[index].value = '"\'' + event + '\'"';
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.templateHtml);
    //web
    const indexWeb = this.webComponentOptions.findIndex((obj => obj.option == 'dropdown-custom-height='));
    this.webComponentOptions[indexWeb].option = 'dropdown-custom-height=';
    this.webComponentOptions[indexWeb].value = '"' + event + '"';
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.webComponentOptions, this.webContainer);
  }

  selectEvent(event) {
    this.btnSelectText = event.text;
    this.btnSelectValue = event.value;
  }

}



