<div class="page-container tile">
    <div class="section-container">
        <div class="kn-page-title">Tile</div>
        <legend [items]="legendItems" [scrollableSectionId]="'codeExamples'"></legend>
        <kn-tabs  [activeTab]="0">
            <kn-tab tabTitle="Examples">
                <div class="tab-content" id="codeExamples">
        
        <section>
            <div class="text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</div>
        </section>

        <section id="default">
            <div class="kn-headline-24-med">Default</div>
        </section>
       
        <div class="code-example">
            <div class="kn-tile">
                <div class="kn-row">
                    <div class="kn-col">
                        <kn-textbox 
                            [label]="'First name'" 
                            [name]="'textboxName'">
                        </kn-textbox>
                    </div>
                    <div class="kn-col">
                        <kn-textbox 
                            [label]="'Last name'" 
                            [name]="'textboxName'">
                        </kn-textbox>
                    </div>
                </div>
                <div class="kn-row">
                    <div class="kn-col">
                        <kn-textarea 
                            [label]="'Description'" 
                            [name]="'exampleTextarea'">
                        </kn-textarea>
                    </div>
                </div>
            </div>
        </div>
            <div class="code-block">
                <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('default')"></i>
                <pre><code>&lt;div class=&quot;kn-tile&quot;&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;
            &lt;kn-textbox 
                [label]=&quot;'First name'&quot; 
                [name]=&quot;'textboxName'&quot;&gt;
            &lt;/kn-textbox&gt;
        &lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;
            &lt;kn-textbox 
                [label]=&quot;'Last name'&quot; 
                [name]=&quot;'textboxName'&quot;&gt;
            &lt;/kn-textbox&gt;
        &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;
            &lt;kn-textarea 
                [label]=&quot;'Description'&quot; 
                [name]=&quot;'exampleTextarea'&quot;&gt;
            &lt;/kn-textarea&gt;
        &lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
                    </div>

        <div class="kn-spacer"></div>

        <section id="filled">
            <div class="kn-headline-24-med">Filled</div>
        </section>
        
        <div class="code-example">
            <div class="kn-tile filled">
                <div class="kn-row">
                    <div class="kn-col">
                        <kn-textbox 
                            [label]="'First name'" 
                            [name]="'textboxName'">
                        </kn-textbox>
                    </div>
                    <div class="kn-col">
                        <kn-textbox 
                            [label]="'Last name'" 
                            [name]="'textboxName'">
                        </kn-textbox>
                    </div>
                </div>
                <div class="kn-row">
                    <div class="kn-row">
                        <div class="kn-col">
                            <kn-textarea 
                                [label]="'Description'" 
                                [name]="'exampleTextarea'">
                            </kn-textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="code-block">
                <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('filled')"></i>
<pre><code>&lt;div class=&quot;kn-tile filled&quot;&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;
            &lt;kn-textbox 
                [label]=&quot;'First name'&quot; 
                [name]=&quot;'textboxName'&quot;&gt;
            &lt;/kn-textbox&gt;
        &lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;
            &lt;kn-textbox 
                [label]=&quot;'Last name'&quot; 
                [name]=&quot;'textboxName'&quot;&gt;
            &lt;/kn-textbox&gt;
        &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;
            &lt;kn-textarea 
                [label]=&quot;'Description'&quot; 
                [name]=&quot;'exampleTextarea'&quot;&gt;
            &lt;/kn-textarea&gt;
        &lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
                
                    </div>
                    
              

        <div class="kn-spacer"></div>
        <section id="warning">
            <div class="kn-headline-24-med">Outlined (Warning)</div>
        </section>
        
        <div class="code-example">
            <div class="kn-tile warning">
                <div class="kn-row">
                    <div class="kn-col">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                    </div>
                </div>
            </div>
        </div>
        <div class="code-block">
            <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('warning')"></i>
<pre><code>&lt;div class=&quot;kn-tile warning&quot;&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
        &lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
        </div>

        <div class="kn-spacer"></div>
        <section id="error">
            <div class="kn-headline-24-med">Outlined (Error)</div>
        </section>
        
        <div class="code-example">
            <div class="kn-tile error">
                <div class="kn-row">
                    <div class="kn-col">
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
                    </div>
                </div>
            </div>
        </div>
        <div class="code-block">
            <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('error')"></i>
<pre><code>&lt;div class=&quot;kn-tile error&quot;&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book
        &lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
        </div>

    <div class="kn-spacer"></div>
        <section id="stsc">
            <div class="kn-headline-24-med">Single Tile (Single column)</div>
        </section>
        
        <div class="code-example">
            <div class="kn-tile filled">
                <div class="kn-row">
                    <div class="kn-col example-col"></div>
                </div>
                <div class="kn-row">
                    <div class="kn-col example-col"></div>
                </div>
            </div>
        </div>
        <div class="code-block">
            <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('stsc')"></i>
<pre><code>&lt;div class=&quot;kn-tile&quot;&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
        </div>

        <div class="kn-spacer"></div>
        <section id="st2c">
            <div class="kn-headline-24-med">Single Tile (Two column)</div>
        </section>
        
        <div class="code-example">
            <div class="kn-tile filled">
                <div class="kn-row">
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                </div>
                <div class="kn-row">
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                </div>
            </div>
        </div>
        <div class="code-block">
            <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('st2c')"></i>
<pre><code>&lt;div class=&quot;kn-tile&quot;&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
        </div>

        <div class="kn-spacer"></div>
        <section id="st3c">
            <div class="kn-headline-24-med">Single Tile (Three column)</div>
        </section>
        
        <div class="code-example">
            <div class="kn-tile filled">
                <div class="kn-row">
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                </div>
                <div class="kn-row">
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                </div>
            </div>
        </div>
        <div class="code-block">
            <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('st3c')"></i>
<pre><code>&lt;div class=&quot;kn-tile&quot;&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
        </div>

        <div class="kn-spacer"></div>
        <section id="st4c">
            <div class="kn-headline-24-med">Single Tile (Four column)</div>
        </section>
        
        <div class="code-example">
            <div class="kn-tile filled">
                <div class="kn-row">
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                </div>
                <div class="kn-row">
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                </div>
            </div>
        </div>
        <div class="code-block">
            <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('st4c')"></i>
<pre><code>&lt;div class=&quot;kn-tile&quot;&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
        </div>

        <div class="kn-spacer"></div>
        <section id="st2575">
            <div class="kn-headline-24-med">Single Tile (Two column 25% / 75%)</div>
        </section>
        
        <div class="code-example">
            <div class="kn-tile filled">
                <div class="kn-row">
                    <div class="kn-col example-col kn-col-25"></div>
                    <div class="kn-col example-col kn-col-75"></div>
                </div>
                <div class="kn-row">
                    <div class="kn-col example-col kn-col-25"></div>
                    <div class="kn-col example-col kn-col-75"></div>
                </div>
            </div>
        </div>
        <div class="code-block">
            <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('st2575')"></i>
<pre><code>&lt;div class=&quot;kn-tile&quot;&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col kn-col-25&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col kn-col-75&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col kn-col-25&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col kn-col-75&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
        </div>

        <div class="kn-spacer"></div>
        <section id="stmixed">
            <div class="kn-headline-24-med">Single Tile (Mixed)</div>
        </section>
        
        <div class="code-example">
            <div class="kn-tile filled">
                <div class="kn-row">
                    <div class="kn-col example-col"></div>
                    <div class="kn-col example-col"></div>
                </div>
                <div class="kn-row">
                    <div class="kn-col example-col"></div>
                </div>
            </div>
        </div>
        <div class="code-block">
            <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('stmixed')"></i>
<pre><code>&lt;div class=&quot;kn-tile&quot;&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;kn-row&quot;&gt;
        &lt;div class=&quot;kn-col&quot;&gt;&lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
        </div>

        <div class="kn-spacer"></div>
        <section id="mtinline">
            <div class="kn-headline-24-med">Multi Tile (Inline)</div>
        </section>
        
        <div class="code-example">
            <div class="kn-inline-tile">
                <div class="kn-tile filled">
                    <div class="kn-row">
                        <div class="kn-col example-col"></div>
                    </div>
                    <div class="kn-row">
                        <div class="kn-col example-col"></div>
                    </div>
                </div>
                <div class="kn-tile filled">
                    <div class="kn-row">
                        <div class="kn-col example-col"></div>
                    </div>
                    <div class="kn-row">
                        <div class="kn-col example-col"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="code-block">
            <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('mtinline')"></i>
<pre><code>&lt;div class=&quot;kn-inline-tile&quot;&gt;
    &lt;div class=&quot;kn-tile filled&quot;&gt;
        &lt;div class=&quot;kn-row&quot;&gt;
            &lt;div class=&quot;kn-col example-col&quot;&gt;&lt;/div&gt;
        &lt;/div&gt;
        &lt;div class=&quot;kn-row&quot;&gt;
            &lt;div class=&quot;kn-col example-col&quot;&gt;&lt;/div&gt;
        &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;kn-tile filled&quot;&gt;
        &lt;div class=&quot;kn-row&quot;&gt;
            &lt;div class=&quot;kn-col example-col&quot;&gt;&lt;/div&gt;
        &lt;/div&gt;
        &lt;div class=&quot;kn-row&quot;&gt;
            &lt;div class=&quot;kn-col example-col&quot;&gt;&lt;/div&gt;
        &lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
        </div>

        <div class="kn-spacer"></div>
        <section id="mt2575">
            <div class="kn-headline-24-med">Multi Tile (Inline 25% / 75%)</div>
        </section>
        
        <div class="code-example">
            <div class="kn-inline-tile">
                <div class="kn-tile filled kn-tile-25">
                    <div class="kn-row">
                        <div class="kn-col example-col"></div>
                    </div>
                    <div class="kn-row">
                        <div class="kn-col example-col"></div>
                    </div>
                </div>
                <div class="kn-tile filled kn-tile-75">
                    <div class="kn-row">
                        <div class="kn-col example-col"></div>
                    </div>
                    <div class="kn-row">
                        <div class="kn-col example-col"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="code-block">
            <i class="fa-regular fa-copy kn-icon-sm kn-icon kn-icon-dark copy-icon" (click)="getCode('mt2575')"></i>
<pre><code>&lt;div class=&quot;kn-inline-tile&quot;&gt;
    &lt;div class=&quot;kn-tile filled kn-tile-25&quot;&gt;
        &lt;div class=&quot;kn-row&quot;&gt;
            &lt;div class=&quot;kn-col example-col&quot;&gt;&lt;/div&gt;
        &lt;/div&gt;
        &lt;div class=&quot;kn-row&quot;&gt;
            &lt;div class=&quot;kn-col example-col&quot;&gt;&lt;/div&gt;
        &lt;/div&gt;
    &lt;/div&gt;
    &lt;div class=&quot;kn-tile filled kn-tile-75&quot;&gt;
        &lt;div class=&quot;kn-row&quot;&gt;
            &lt;div class=&quot;kn-col example-col&quot;&gt;&lt;/div&gt;
        &lt;/div&gt;
        &lt;div class=&quot;kn-row&quot;&gt;
            &lt;div class=&quot;kn-col example-col&quot;&gt;&lt;/div&gt;
        &lt;/div&gt;
    &lt;/div&gt;
&lt;/div&gt;
</code></pre>
        </div>
        </div>
            </kn-tab>
            <kn-tab tabTitle="Usage">
                Imperdiet dui accumsan sit amet nulla facilisi morbi.
            </kn-tab>
        </kn-tabs>
    </div>
</div>