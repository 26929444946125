import { Component, OnInit } from '@angular/core';
import  packageInfo from '../../../../../kraken-modal/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import { KrakenModalService } from 'kraken-modal';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass']
})
export class ModalComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public angularComponentRef2;
  public webComponentRef;
  public elementTag = 'kn-modal';
  public webElementTag = 'kn-web-modal';
  public componentVersion = packageInfo.version;
  public dropdownValue;
  public modalSize = "default";
  public textbox1 = 'admin@test.com';
  public textbox2 = 'Value 2';
  public sTextbox1 = "Value 1";
  public sTextbox2 = "Value 2";
  public sTextbox3 = "Value 3";
  public sTextbox4 = "Value 4";
  public modalHeadline = 'Modal Headline';
  public checkboxDefault = false;
  public textareaValue = "";
  public defaultRadioOption = false;
  public toggleValue = false;
  public toggle2Value = true;
  public step = 1;
  public template = 'multiSize';
  public modalError = '';
  public modalId = '';
  public modalButtonId = 'exampleModalButton';
  public isErrorMessage = false;
  public showOpenEvent = false;
  public showCloseEvent = false;
  public modalButton;
  public webModalButton;
  public modalButtonTag = 'kn-button'
  public event1 = true;
  public event2 = true;
  public modalButtonOptions: any = [
    { option: '[buttonId]=', value: "\"'" + this.modalButtonId + "'\""},
    { option: '[buttonType]=', value: "\"'kn-tertiary'\""},
    { option: '[buttonText]=', value: "\"'modal trigger'\""},
    { option: '(btnClick)=', value: "\"openModal(\"exampleModal\")\""}
  ]

  public webModalButtonOptions: any = [
    { option: 'id=', value: '"exampleModalBtn"'},
    { option: 'button-id=', value: '"exampleButton"'},
    { option: 'button-type=', value: '"kn-tertiary"'},
    { option: 'button-text=', value: '"modal trigger"'}
  ]

  public component = {
    name: packageInfo.name, 
    version: packageInfo.version, 
    peerDependencies: [packageInfo.peerDependencies],
    module: 'KrakenModalModule'
  };
  public modalTitle = "Example model";
  public modalType = 'multiSize';
  public angularComponentOptions: any = [
    { option: 'id=', value: '"exampleModal"'},
    { option: '[title]=', value: "\"'" + this.modalHeadline + "'\""},
    { option: '[size]=', value: "\"'" + this.modalSize + "'\""},
    { option: '[type]=', value: "\"'" + this.modalType + "'\""},
    { option: '(onClose)=', value: "\"modalCloseEvent($event)\""},
    { option: '(onOpen)=', value: "\"modalOpenEvent($event)\""}
  ];

  public webComponentOptions: any = [
    { option: 'id=', value: '"exampleModal"'},
    { option: 'title=', value: '"' + this.modalHeadline +'"'},
    { option: 'modal-id=', value: '"myModal"'},
    { option: 'size=', value: '"' + this.modalSize +'"'},
    { option: 'type=', value: '"' + this.modalType +'"'}
  ];

  public imports =
  `import { KrakenModalService } from '@kraken-ui/kraken-modal';`
  public constructorRef = 
  `constructor(public modalService: KrakenModalService) { }`
  public openModalFunction = 
  `openModal(modalId: string) {
  this.modalService.open(modalId);
}`
public closeModalFunction = 
`this.modalService.close(modalId);`

public openFunction = 
`this.modalService.open(modalId);`

public modalOpenFunction = 
`modalOpenEvent($event) {
  //code to do something when the modal opens
}`

public modalCloseFunction = 
`modalCloseEvent($event) {
  //code to do something when the modal closes
}`

public webModalInstance = 
`let modal = document.querySelector('#exampleModal');`

public webModalButtonInstance =
`let modalBtn = document.querySelector('#exampleModalBtn');`

public webModalButtonClickEvent =
`modalBtn.addEventListener('btnClick', (e) => {
    modal.open = true;
});`

public webModalCloseFunction = 
`function modalCloseFunction() {
  modal.open = false;
}`
public webModalContext =
`const contextObj = { closeModal: this.modalCloseFunction.bind(this)};
modal.context = contextObj;`
public webModalContent =
`modal.content = 
\`<div class="inner-container">
  <div class="kn-modal-panel">
    <div class="kn-modal-row">
      <div class="kn-textbox-field">
        <kn-textbox 
          [textboxId]="'exampleTextbox3'" 
          [label]="'Label text'" 
          [name]="'textboxName'">
        </kn-textbox>
      </div>
      <div class="kn-textbox-field">
        <kn-textbox 
          [textboxId]="'exampleTextbox4'" 
          [label]="'Label text'" 
          [name]="'textboxName'">
        </kn-textbox>
      </div>
    </div>
    <div class="kn-modal-row">
      <div class="kn-textbox-field">
        <kn-textbox 
          [textboxId]="'exampleTextbox5'" 
          [label]="'Label text'" 
          [name]="'textboxName'">
        </kn-textbox>
      </div>
      <div class="kn-textbox-field">
        <kn-textbox 
          [textboxId]="'exampleTextbox6'" 
          [label]="'Label text'"  
          [name]="'textboxName'">
        </kn-textbox>
      </div>
      </div>
    </div>
  </div>
  <div class="kn-modal-footer">
    <div class="col-right">
      <kn-button 
      [buttonId]="'cancelButton'" 
      [buttonType]="'kn-flat'" 
      [buttonText]="'Cancel'" 
      (btnClick)="context.closeModal()">
      </kn-button>
      <kn-button 
      [buttonId]="'actionButton'" 
      [buttonType]="'kn-primary'" 
      [buttonText]="'Action'">
      </kn-button>
  </div>
</div>\``

public bindingTemplateComponents =
`function dropdownValueChangeEvent(e) {
  console.log(e)
}

modal.context = {
  dropdownFields: {text: 'country', value: 'code'},
  dropdownItems: [
    {country:'United States', id: '+0', code: 'US'}, 
    {country:'Canada', id: '+1', code: 'CA'}, 
    {country:'Germany', id: '+49', code: 'DE', disabled: true}
  ],
  closeModal: this.modalCloseFunction.bind(this),
  dropdownValueChange: this.dropdownValueChangeEvent.bind(this)
};`
  
public webSteps =
`let step1 = document.querySelector('#step1');
step1.style.display = "block";
let step2 = document.querySelector('#step2');
step2.style.display = "none";
document.getElementById("backBtn").style.display = "none";
document.getElementById("createButton").style.display = "none";

function prev(e) {
  step2.style.display = "none";
  step1.style.display = "block";
  document.getElementById("backBtn").style.display = "none";
  document.getElementById("createButton").style.display = "none";
  document.getElementById("nextButton").style.display = "block";
}

function next(e) {
  step1.style.display = "none";
  step2.style.display = "block";
  document.getElementById("backBtn").style.display = "block";
  document.getElementById("createButton").style.display = "block";
  document.getElementById("nextButton").style.display = "none";
}

modal.context = {
  dropdownFields: {text: 'country', value: 'code'},
  dropdownItems: [
    {country:'United States', id: '+0', code: 'US'}, 
    {country:'Canada', id: '+1', code: 'CA'}, 
    {country:'Germany', id: '+49', code: 'DE', disabled: true}
  ],
  closeModal: this.modalCloseFunction.bind(this),
  dropdownValueChange: this.dropdownValueChangeEvent.bind(this),
  prev: this.prev.bind(this),
  next: this.next.bind(this)
};`

public webSetSteps =
`let stepNumber = 1;
modal.subText = \`Step \${stepNumber}\ of 2\`;`

  constructor(
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService,
    public modalService: KrakenModalService,) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');
    const event : any = {}
    event.value = 'multiSize';
    this.modalCode(event, '');

    //modal button demo
    this.modalButton = this.tempUpdateService.templateUpdate(this.modalButtonTag, this.modalButtonOptions, this.angularContainer);
    this.webModalButton = this.tempUpdateService.templateUpdate(this.modalButtonTag, this.webModalButtonOptions, this.webContainer);
    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }

  controlEvent(event, option, value, web?) {
    const options = this.tempUpdateService.templateProperties(event, this.angularComponentOptions, this.webComponentOptions, option, value, web);
    this.angularComponentOptions = options.angularOptions;
    this.webComponentOptions = options.webOptions;
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.updateTemplate());
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.webElementTag, this.webComponentOptions, this.webContainer);
  }

  openModal(modalId, size, type, subText) {
    this.modalSize = size;
    const event: any = {};
    event.value = this.modalType;
    this.modalCode(event, type, subText);
    this.modalService.open(modalId);
  }

  open(event) {
    console.log(event)
  }
  close(modalId: string) {
    this.modalService.close(modalId);
  }

  modalCloseEvent() {
    this.step = 1;
    this.textbox1 = 'admin@test.com';
    this.textbox2 = 'Value 2';
    this.sTextbox1 = "Value 1";
    this.sTextbox2 = "Value 2";
    this.sTextbox3 = "Value 3";
    this.sTextbox4 = "Value 4";
  }

  error(event) {
    this.modalError = event ? `This is an example of an error message. Please try again or contact <a href="#" target="_blank">Spectra Support</a>` : '';
    this.controlEvent(event, '[modalError]=', 'errorMessage', false);
  }

  modalCode(event, type, subtext?) {
    this.template = event.value;
    //size
    if(this.modalSize == 'default' || this.modalSize == '') {
      this.controlEvent(false, '[size]=', '', true);
    } else {
      const ngsize = this.angularComponentOptions.findIndex((obj => obj.option == '[size]='));
      const websize = this.webComponentOptions.findIndex((obj => obj.option == 'size='));
      if(ngsize != -1) {
        this.angularComponentOptions[ngsize].value = '"\'' + this.modalSize + '\'"';
        this.webComponentOptions[websize].value = '"' + this.modalSize + '"';
      }else {
        this.controlEvent(true, '[size]=', '\'' + this.modalSize + '\'', true);
      }
    }
    //type
    if(type == 'default' || type == '') {
      this.controlEvent(false, '[type]=', '', true);
    } else {
      const ngtype = this.angularComponentOptions.findIndex((obj => obj.option == '[type]='));
      const webtype = this.webComponentOptions.findIndex((obj => obj.option == 'type='));
      if(ngtype != -1) {
        this.angularComponentOptions[ngtype].value = '"\'' + type + '\'"';
        this.webComponentOptions[webtype].value = '"' + type + '"';
      }else {
        this.controlEvent(true, '[type]=', '\'' + type + '\'', true);
      }
    }

    //subtitle
    if(subtext == '' || subtext == undefined) {
      this.controlEvent(false, '[subText]=', '', true);
    } else {
      const subtext = "Step ' + step + ' of 2";
      const ngtype = this.angularComponentOptions.findIndex((obj => obj.option == '[subText]='));
      //let webtype = this.webComponentOptions.findIndex((obj => obj.option == 'sub-text='));
      if(ngtype != -1) {
        this.angularComponentOptions[ngtype].value = '"\'' + subtext + '\'"';
        //this.webComponentOptions[webtype].value = '"' + subtext + '"';
      }else {
        this.controlEvent(true, '[subText]=', '\'' + subtext + '\'', false);
      }
    }
  }

  prev() {
    this.step = 1;
  }

  next() {
    this.step = 2;
  }

  updateTemplate() {
    let modal;
    let web;
    if(this.template == 'standard') {
      modal = 
`<div class="inner-container">
    <div class="kn-tile filled">
      <div class="kn-row">
        <div class="kn-col">
          <kn-textbox 
            [textboxId]="'exampleTextbox3'" 
            [label]="'Label text'" 
            [(ngModel)]="sTextbox1" 
            [name]="'textboxName'">
          </kn-textbox>
        </div>
        <div class="kn-col">
          <kn-textbox 
            [textboxId]="'exampleTextbox4'" 
            [label]="'Label text'" 
            [(ngModel)]="sTextbox2" 
            [name]="'textboxName'">
          </kn-textbox>
        </div>
      </div>
      <div class="kn-row">
        <div class="kn-col">
          <kn-textbox 
            [textboxId]="'exampleTextbox5'" 
            [label]="'Label text'" 
            [(ngModel)]="sTextbox3" 
            [name]="'textboxName'">
          </kn-textbox>
        </div>
        <div class="kn-col">
          <kn-textbox 
            [textboxId]="'exampleTextbox6'" 
            [label]="'Label text'" 
            [(ngModel)]="sTextbox4" 
            [name]="'textboxName'">
          </kn-textbox>
        </div>
      </div>
    </div>
  </div>
  <div class="kn-modal-footer">
  <div class="col-right">
    <kn-button 
      [buttonId]="'cancelButton1'" 
      [buttonType]="'kn-flat'" 
      [buttonText]="'Cancel'" 
      (btnClick)="close('modalForm')">
    </kn-button>
    <kn-button 
      [buttonId]="'actionButton2'" 
      [buttonType]="'kn-primary'" 
      [buttonText]="'Action'">
    </kn-button>
  </div>
</div>`
    }
  if(this.template == 'multiStep') {
    modal =
`<div class="inner-container">
<!--Step 1-->
  <div *ngIf="step == 1">
    <div class="kn-container">
      <div class="kn-subtitle-14">Section 1 Title</div>
      <div class="kn-row">
        <kn-textbox 
          [textboxId]="'exampleTextbox1'" 
          [emailValidator]="true" 
          [label]="'Label text'" 
          [(ngModel)]="textbox1" 
          [name]="'textboxName'">
        </kn-textbox>
        <kn-dropdown  
          [value]="context.dropdownItems[0].country"
          [label]="'Country'" 
          [placeholder]="'Choose a country'" 
          [items]="context.dropdownItems"
          [fields]="context.dropdownFields"
          (onSelect)="context.dropdownValueChange($event)"
          [name]="'exampledropdown'">
        </kn-dropdown>
      </div>
      <div class="kn-row">
        <kn-textarea 
          [label]="'Label text'" 
          [label]="'Textarea example'" 
          [(ngModel)]="textareaValue" 
          [name]="'exampleTextarea'">
        </kn-textarea>
      </div>
    </div>
    <div class="kn-container">
      <div class="kn-row">
        <div class="kn-row-col">
          <div class="kn-subtitle-14">Section 2 Title</div>
          <kn-radio-button 
            [radioId]="'radio1ComponentId'" 
            [value]="true" 
            [(ngModel)]="defaultRadioOption" 
            [defaultValue]="defaultRadioOption" 
            [name]="'radioGroup'" 
            [label]="'Lorem Ipsum is simply dummy text'">
          </kn-radio-button>
          <kn-radio-button 
            [radioId]="'radio2ComponentId'" 
            [label]="'Contrary to popular belief, Lorem Ipsum is not simply random text.'" 
            [value]="false" 
            [(ngModel)]="defaultRadioOption" 
            [defaultValue]="defaultRadioOption"
            [name]="'radioGroup'">
          </kn-radio-button>
        </div>
        <div class="kn-row-col">
          <div class="kn-subtitle-14">Section 3 Title</div>
          <kn-toggle 
            [toggleId]="'toggleComponentId'" 
            [label]="'Lorem Ipsum is not simply random text'" 
            [(ngModel)]="toggleValue" 
            [name]="'toggle'">
          </kn-toggle>
          <kn-toggle 
            [toggleId]="'toggleComponent2Id'" 
            [label]="'All the Lorem Ipsum generators'" 
            [(ngModel)]="toggle2Value" 
            [name]="'toggle'">
          </kn-toggle>
        </div>
      </div>
    </div>
  </div>
  <!--Step 2-->
  <div *ngIf="step == 2">
    <div class="kn-container">
      <div class="kn-subtitle-14">Section 1 Title</div>
      <div class="kn-row kn-row-label">
          <div class="row-label">Label 1:</div><div class="row-value">Value 1</div>
      </div>
      <div class="kn-row kn-row-label">
          <div class="row-label">Label 2:</div><div class="row-value">Value 2</div>
      </div>
      <div class="kn-row kn-row-label">
          <div class="row-label">Label 3:</div><div class="row-value">Value 3</div>
      </div>
      <div class="kn-row kn-row-label">
          <div class="row-label">Label 4:</div><div class="row-value">Value 4</div>
      </div>
      <div class="kn-spacer"></div>
      <div class="kn-subtitle-14">Section 2 Title</div>
      <div class="kn-row kn-row-label">
          <div class="row-label">Label 4:</div><div class="row-value">Lorem Ipsum is simply dummy text</div>
      </div>
      <div class="kn-row kn-row-label">
          <div class="row-item-label">Label 5:</div><div class="row-item-value">It is a long established fact that a reader will be distracted by the readable content. </div>
      </div>
    </div>
  </div>
</div>
<div class="kn-modal-footer">
  <div class="col-left">
    <kn-button 
      [buttonId]="'backBtn'" 
      [buttonType]="'kn-tertiary'"
      [buttonText]="'Back'" 
      (btnClick)="prev()">
    </kn-button>
  </div>
  <div class="col-right">
    <kn-button 
      [buttonId]="'cancelButton'" 
      [buttonType]="'kn-flat'" 
      [buttonText]="'Cancel'" 
      (btnClick)="close('multiStepModalExample')">
    </kn-button>
    <kn-button 
      *ngIf="step == 1" 
      [buttonId]="'actionButton'" 
      [buttonType]="'kn-primary'" 
      [buttonText]="'Next'" 
      (btnClick)="next()" 
      [disabled]="false">
    </kn-button>
    <kn-button 
      *ngIf="step == 2" 
      [buttonId]="'actionButton'" 
      [buttonType]="'kn-primary'" 
      [buttonText]="'Create'" 
      (btnClick)="next()" 
      [disabled]="false">
    </kn-button>
  </div>
</div>`
web = `<div class="inner-container">
<div id="step1">
  <div class="kn-container">
    <div class="kn-subtitle-14">Section 1 Title</div>
    <div class="kn-row">
      <kn-textbox 
        [textboxId]="'exampleTextbox1'" 
        [emailValidator]="true" 
        [label]="'Label text'" 
        [name]="'textboxName'">
      </kn-textbox>
      <kn-dropdown  
        [value]="context.dropdownItems[0].country"
        [label]="'Country'" 
        [placeholder]="'Choose a country'" 
        [items]="context.dropdownItems"
        [fields]="context.dropdownFields"
        (onSelect)="context.dropdownValueChange($event)"
        [name]="'exampledropdown'">
      </kn-dropdown>
    </div>
    <div class="kn-row">
      <kn-textarea 
        [label]="'Label text'" 
        [label]="'Textarea example'" 
        [name]="'exampleTextarea'">
      </kn-textarea>
    </div>
  </div>
  <div class="kn-container">
    <div class="kn-row">
      <div class="kn-row-col">
        <div class="kn-subtitle-14">Section 2 Title</div>
        <kn-radio-button 
          [radioId]="'radio1ComponentId'" 
          [value]="true" 
          [defaultValue]="defaultRadioOption" 
          [name]="'radioGroup'" 
          [label]="'Lorem Ipsum is simply dummy text'">
        </kn-radio-button>
        <kn-radio-button 
          [radioId]="'radio2ComponentId'" 
          [label]="'Contrary to popular belief, Lorem Ipsum is not simply random text.'" 
          [value]="false" 
          [defaultValue]="defaultRadioOption"
          [name]="'radioGroup'">
        </kn-radio-button>
      </div>
      <div class="kn-row-col">
        <div class="kn-subtitle-14">Section 3 Title</div>
        <kn-toggle 
          [toggleId]="'toggleComponentId'" 
          [label]="'Lorem Ipsum is not simply random text'" 
          [name]="'toggle'">
        </kn-toggle>
        <kn-toggle 
          [toggleId]="'toggleComponent2Id'" 
          [label]="'All the Lorem Ipsum generators'"  
          [name]="'toggle'">
        </kn-toggle>
      </div>
    </div>
  </div>
</div>

<div id="step2">
  <div class="kn-container">
    <div class="kn-subtitle-14">Section 1 Title</div>
    <div class="kn-row kn-row-label">
        <div class="row-label">Label 1:</div><div class="row-value">Value 1</div>
    </div>
    <div class="kn-row kn-row-label">
        <div class="row-label">Label 2:</div><div class="row-value">Value 2</div>
    </div>
    <div class="kn-row kn-row-label">
        <div class="row-label">Label 3:</div><div class="row-value">Value 3</div>
    </div>
    <div class="kn-row kn-row-label">
        <div class="row-label">Label 4:</div><div class="row-value">Value 4</div>
    </div>
    <div class="kn-spacer"></div>
    <div class="kn-subtitle-14">Section 2 Title</div>
    <div class="kn-row kn-row-label">
        <div class="row-label">Label 4:</div><div class="row-value">Lorem Ipsum is simply dummy text</div>
    </div>
    <div class="kn-row kn-row-label">
        <div class="row-item-label">Label 5:</div><div class="row-item-value">It is a long established fact that a reader will be distracted by the readable content. </div>
    </div>
  </div>
</div>
</div>
<div class="kn-modal-footer">
<div class="col-left">
  <kn-button 
    [buttonId]="'backBtn'" 
    [buttonType]="'kn-tertiary'"
    [buttonText]="'Back'" 
    (btnClick)="context.prev()">
  </kn-button>
</div>
<div class="col-right">
  <kn-button 
    [buttonId]="'cancelButton'" 
    [buttonType]="'kn-flat'" 
    [buttonText]="'Cancel'" 
    (btnClick)="context.closeModal()">
  </kn-button>
  <kn-button 
    [buttonId]="'actionButton'" 
    [buttonType]="'kn-primary'" 
    [buttonText]="'Next'" 
    (btnClick)="context.next()" 
    [disabled]="false">
  </kn-button>
  <kn-button 
    [buttonId]="'actionButton'" 
    [buttonType]="'kn-primary'" 
    [buttonText]="'Create'" 
    [disabled]="false">
  </kn-button>
</div>
</div>`

  }
  if(this.template == 'warning') {
    modal = 
`<div class="inner-container">
  <div class="kn-modal-info-text">
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
  </div>
</div>
<div class="kn-modal-footer">
  <div class="col-right">
    <kn-button 
      [buttonId]="'actionButton'" 
      [buttonType]="'kn-primary warning'" 
      [buttonText]="'Action'">
    </kn-button>
  </div>
</div>`
  }
  if(this.template == 'danger') {
    modal = 
`<div class="inner-container">
  <div class="kn-modal-info-text">
    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
  </div>
  <div class="kn-modal-row">
    <kn-checkbox 
      [id]="'checkboxComponentId'" 
      [checkboxId]="'exampleCheckbox'" 
      [label]="'Yes'" 
      [defaultValue]="checkboxDefault" 
      [(ngModel)]="checkboxDefault" 
      [name]="'checkbox'">
    </kn-checkbox>
  </div>
</div>
<div class="kn-modal-footer">
    <div class="col-right">
        <kn-button 
            [buttonId]="'cancelButton'" 
            [buttonType]="'kn-flat'" 
            [buttonText]="'Cancel'" 
            (btnClick)="close('exampleDanger')">
        </kn-button>
        <kn-button 
            [buttonId]="'actionButton'" 
            [buttonType]="'kn-primary error'" 
            [buttonText]="'Action'" 
            [disabled]="!checkboxDefault">
        </kn-button>
    </div>
</div>`
  }
  if(this.template == 'multiSize') {
    modal = 
  `<div class="inner-container">
    <div class="kn-modal-info-text">
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
    </div>
</div>
<div class="kn-modal-footer">
    <div class="col-right">
        <kn-button 
            [buttonId]="'cancelButton'" 
            [buttonType]="'kn-flat'" 
            [buttonText]="'Cancel'" 
            (btnClick)="close('exampleMultiSize')">
        </kn-button>
        <kn-button 
            [buttonId]="'actionButton'" 
            [buttonType]="'kn-primary error'" 
            [buttonText]="'Action'" 
            [disabled]="!checkboxDefault">
        </kn-button>
    </div>
</div>`
  }
    this.webModalContent = this.template == 'multiStep' ? `modal.content = \`${web}\`` : `modal.content = \`${modal}\``;
    return modal;
  }

}
