import { Component, OnInit, Output, Input, EventEmitter, OnChanges } from '@angular/core';

@Component({
  selector: 'kn-progress-indicator',
  templateUrl: './kraken-progress-indicator.component.html'
})
export class KrakenProgressIndicatorComponent implements OnInit {
  @Input() componentId: string;
  @Input() label: string;
  @Input() subText: string;
  @Input() error = false;
  @Input() value: number;
  @Input() inline = false;
  @Input() disabled = false;
  @Input() errorMessage: string;
  @Input() hideValue = false;
  @Input() queued = false;
  @Output() onSuccess = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.componentId = this.componentId ? this.componentId : 'knProgressIndicator_' + Math.floor(Math.random()*90000) + 10000;
  }

  ngOnChanges(changes) {
    if(changes.value)  {
      if(changes.value.currentValue == 100) {
        this.success();
      }
    }
  }

  success() {
    this.onSuccess.emit(
      {
        id: this.componentId,
        value: this. value,
        label: this.label,
        subText: this.subText
      }
    );
  }

}
