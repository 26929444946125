import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'contextual-menu-usage',
  templateUrl: './contextual-menu-usage.component.html',
  styleUrls: ['./contextual-menu-usage.component.sass']
})
export class ContextualMenuUsageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
