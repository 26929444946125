<div class="kn-input-contain" [ngClass]="{'disabled': setDisabled, 'readonly': setReadOnly}">
    <div class="inner">
        <div class="kn-field-validation" 
            *ngIf="!setDisabled && !setReadOnly"
            [style.left]="textareaLeftPos" 
            [style.top]="textareaBottomPos"
            [style.width]="textareaWidth"
        >
            <kraken-field-validation 
                [field]="textAreaField" 
                [fieldName]="name" 
                [customWarning]="customWarning" 
                [customError]="customError" 
                [customMess]="customMess"
                [hideErrorMessage]="hideErrorMessage">
            </kraken-field-validation>
            <span class="char-count" *ngIf="showCharCount"><span [ngClass]="{'counter-error': value?.length > maxChars}">{{value?.length || 0}}</span>/{{maxChars}}</span>
        </div>
        <textarea
            #textAreaField="ngModel"
            class="kn-form-control" 
            [ngClass]="{
                'input-error': 
                textAreaField.invalid && (textAreaField.dirty || textAreaField.touched) ||
                customError,
                'input-warning': customWarning
            }"
            id="{{textareaId}}"
            rows="{{rows}}"
            cols="{{cols}}"
            [(ngModel)]="value"  
            (ngModelChange)="onModelChange($event)" 
            name="{{name}}"
            [attr.aria-label]="label ? label : setPlaceholder"
            required="{{setRequired}}"
            [attr.maxlength]="maxLength === 0 ? null : maxLength"
            [readonly]="setReadOnly"
            [disabled]="setDisabled"
            [placeholder]="setPlaceholder"
            (blur)="onValidation()">
        </textarea>
            <label class="label" [ngClass]="{'input-label-error':
            textAreaField.invalid && (textAreaField.dirty || textAreaField.touched) ||
            customError,
            'input-label-warning': customWarning }">{{label}}<span *ngIf="optional && !setRequired" class="optional-label">(Optional)</span></label>
    </div>
</div>