<nav 
    #knAccordion
    id="{{uniqueId}}"
    class="kn-accordion" 
    role="navigation" 
    [ngStyle]="{'width': width}"
    [ngClass]="
    {
        'icon-left-aligned': controlLeftAligned,
        'sub-title-inline': subTitleInline,
        'kn-acc-filled-bg': filledBackground
    }">
    
        <ng-content></ng-content>
  </nav>