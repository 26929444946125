<div class="page-container">
    <div class="section-container">
        <div class="kn-page-title">Why a Design System?</div>
        <section>
            <div class="text">There’s a reason so many successful software enterprises are developing and adopting a modern design system. In fact, there are three:</div>
        </section>    
          
        <section> 
            <div class="kn-subtitle-16">1. User-centric focus</div>
    
            <div class="text">UX best practices have been thoroughly researched and baked into the recommended styles, component designs, and usage guidelines, ensuring that these same best practices are reflected in the product experience. </div>
        </section>    
            
        <section> 
            <div class="kn-subtitle-16">2. Brand impression</div>

            <div class="text">Consistency in the caliber of product UI execution across the portfolio conveys a message of professionalism and reliability, which in turn instills a sense of quality and trust in the mind of the customer, leading to greater loyalty and advocacy of the Lighthouse brand.</div>
        </section>

        <section> 
            <div class="kn-subtitle-16">3. Scale and acceleration</div>

            <div class="text">Design systems are leveraged so that product teams don’t have to rethink and continually reinvent the fundamental elements and patterns repeated in much of our software. The UI solution comes together more rapidly freeing up time better spent solving complex engineering challenges and innovating.</div>
        </section>
    </div>
</div>
