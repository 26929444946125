import { Component } from '@angular/core';
import { TemplateUpdateService } from '../../../services/template-update.service';

@Component({
  selector: 'spreadsheet-accessibility',
  templateUrl: './spreadsheet-accessibility.component.html',
  styleUrls: ['./spreadsheet-accessibility.component.sass']
})
export class SpreadsheetAccessibilityComponent {
  infoText = this.templateService.accessibilityInfoText;
  header = this.templateService.accessibilityHeader;

  constructor(private templateService: TemplateUpdateService) { }
}
