import { Injectable } from '@angular/core';

declare var gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {

  constructor() { }

  // Method to track page views
  public trackPageView(url: string, pageTitle?: string, customParams?: object): void {
    if (typeof gtag !== 'undefined') {
      const pageViewParams: { [key: string]: any } = {
        'page_path': url,
        // Optionally, set a static page title, or you can remove this if not needed
        'page_title': 'test'
      };
  
      // Merge custom parameters if provided
      if (customParams) {
        Object.assign(pageViewParams, customParams);
      }
  
      gtag('config', 'G-H3MNW9RR55', pageViewParams);
    }
  }

  // Method to track custom events
  public trackEvent(eventName: string, params: { [key: string]: any }): void {
    if (typeof gtag !== 'undefined') {
      // Ensure params is an object with at least one key-value pair
      if (Object.keys(params).length > 0) {
        gtag('event', eventName, params);
      } else {
        console.error('trackEvent called with invalid params', params);
      }
    }
  }
}
