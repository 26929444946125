import { Component, OnInit } from '@angular/core';
import  packageInfo from '../../../../../kraken-toast/package.json';
import { TemplateUpdateService } from '../../services/template-update.service';
import { DocumentationService } from '../../services/documentation.service';
import { KrakenToastService } from 'kraken-toast';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.sass']
})
export class ToastComponent implements OnInit {
  public angularContainer;
  public webContainer
  public angularComponentRef;
  public webComponentRef;
  public event1 = true;
  public elementTag = 'div';
  public elementWebTag = 'kn-toast';
  public componentVersion = packageInfo.version;
  public component = {
    name: packageInfo.name, 
    version: packageInfo.version,
    peerDependencies: [packageInfo.peerDependencies], 
    module: 'KrakenToastModule'};
  public componentId = 'toastComponentId';
  public button;
  public webButton;
  public modalButtonTag = 'kn-button'
  public toastMessage = `This is a <span class="kn-bold">test toast</span> message`;
  public toastType = 'success';
  public compressed = false;
  public webJavascript;
  public angularComponentOptions: any = [
    { option: 'class=', id: 0, value: '"main"'}
  ];

  public webComponentOptions: any = [
    { option: 'id=', id: 0, value: '"toastExample"'}
  ];
  public tooltipCompressed =
  `If you are using a Kraken masthead and it is set to "compressed", you should choose this option which will position the toast closer to the bottom of the masthead`
  public toastServiceCode;
  public toastTemplate = `<router-outlet></router-outlet>
<kn-toast></kn-toast>`

public customIcon =
`this.toastService.showToast("success", "This is a <span class="kn-bold">test toast</span> message", null, "", "fa-regular fa-bell");`

  constructor(
    public toastService: KrakenToastService,
    public tempUpdateService: TemplateUpdateService, 
    public docService: DocumentationService) { }

  ngOnInit(): void {
    this.angularContainer = document.getElementById('angularCodeSnippet');
    this.webContainer = document.getElementById('webCodeSnippet');

    //angular template
    this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.toastTemplate);
    //web component template
    this.webComponentRef = this.tempUpdateService.templateUpdate(this.elementWebTag, this.webComponentOptions, this.webContainer);
    
    this.toastServiceEvent('success');

    this.template();
  }

  showToast() {
    this.toastService.showToast(this.toastType, this.toastMessage);
  }

  toastServiceEvent(event) {
    this.toastServiceCode = `
import { KrakenToastService } from '@kraken-ui/kraken-toast';
    
export class Component implements OnInit {

  constructor(public toastService: KrakenToastService) { }

  ngOnInit(): void {
    this.toastService.showToast('${event}', '${this.toastMessage}');
  };
}`
  //angular template
  this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.toastTemplate);

  this.webJavascriptTemplate(event);
  }

  template() {
    const compressed = this.compressed ? ' [compressedMasthead]="true"' : '';
    this.toastTemplate = `<router-outlet${compressed}></router-outlet>
<kn-toast></kn-toast>`

//angular template
this.angularComponentRef = this.tempUpdateService.templateUpdate(this.elementTag, this.angularComponentOptions, this.angularContainer, this.toastTemplate);
  }

  webJavascriptTemplate(event) {
  return this.webJavascript =
`let toast = document.getElementById('toastExample');
toast.type = '${event}';
toast.message = '${this.toastMessage}';
toast.show = true;
`
  }
}
