import { NgModule } from '@angular/core';
import { KrakenSpinBoxComponent } from './kraken-spin-box.component';
import { KrakenTextboxModule } from '@kraken-ui/kraken-textbox';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    KrakenSpinBoxComponent
  ],
  imports: [
    KrakenTextboxModule,
    CommonModule,
    FormsModule
  ],
  exports: [
    KrakenSpinBoxComponent
  ]
})
export class KrakenSpinBoxModule { }
